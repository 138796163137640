export const data_guide = [
  {
    title:
      "Top 10 tựa game PC những năm 2000 với đồ họa đỉnh cao làm game thủ mãi nhớ",
    description:
      "Những ấn tượng không thể nào quên với các anh em game thủ 8x 9x một thời.",
    src: "https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-1627718538346379055070.jpg",
    alt: "GAME,GAME HAY,GAME ĐỈNH,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
    category: "games",
    time: "01:20 PM",
    date: "31/07/2021",
    timestamp: "31/07/2021 01:20 PM",
    link: "/top-10-tua-game-pc-nhung-nam-2000-voi-do-hoa-dinh-cao-lam-game-thu-mai-nho",
    component: "page_20210731132037",
  },
];
