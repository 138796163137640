import React from "react";
import "components/page.scss";
import { AdsHorizontal, AdsVertical } from "components/adsMethods";
import {
  RandomFeature,
  RelationNews,
  RelationNewsInPage,
} from "components/methods";

import {
  Content,
  ContentImage,
  FacebookShareButton,
} from "components/content";

import { Helmet } from "react-helmet";



/* ---------------------------------------------------------------- */

/*

 {
"timestamp": '13/08/2021 10:38 PM',
"title": 'Lén lút đi đóng phim 18+, nàng hot girl hoảng hồn khi bị sếp và đồng nghiệp phát hiện và cái kết đầy bất ngờ sau đó',
"description": 'Đây cũng là lý do chính khiến cho cô nàng hot girl quyết định trở thành diễn viên phim 18+ full time.',
"src": '',
"alt": 'HOT GIRL,GÁI XINH,PHIM AV,AV,PHIM 18+,SHIHO FUJIE,CỘNG ĐỒNG MẠNG,',
"category": 'images',
"date": '13/08/2021',
"time": '10:38 PM',
"link": '/len-lut-di-dong-phim-18-nang-hot-girl-hoang-hon-khi-bi-sep-va-dong-nghiep-phat-hien-va-cai-ket-day-bat-ngo-sau-do',
"zcomponent": 'page_20210813223818',
"filepath": './20210813223818-len-lut-di-dong-phim-18-nang-hot-girl-hoang-hon-khi-bi-sep-va-dong-nghiep-phat-hien-va-cai-ket-day-bat-ngo-sau-do.js'
}



<ContentImage
src=""
alt=""
note=""
/>
*/

/* ---------------------------------------------------------------- */
const category = 'images';
const categoryLink = '/images';
const title = 'Lén lút đi đóng phim 18+, nàng hot girl hoảng hồn khi bị sếp và đồng nghiệp phát hiện và cái kết đầy bất ngờ sau đó';
const author = 'Mặt Trứng';
const source = 'Pháp luật và bạn đọc';
const timestamp = '13/08/2021 10:38 PM';
const description = 'Đây cũng là lý do chính khiến cho cô nàng hot girl quyết định trở thành diễn viên phim 18+ full time.';
const link = 'len-lut-di-dong-phim-18-nang-hot-girl-hoang-hon-khi-bi-sep-va-dong-nghiep-phat-hien-va-cai-ket-day-bat-ngo-sau-do';
const tagparam = ["HOT GIRL","GÁI XINH","PHIM AV","AV","PHIM 18+","SHIHO FUJIE","CỘNG ĐỒNG MẠNG",];
const facebookLinkShare =
  "https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fhuyetnguyet.com%2F" +
  link +
  "&layout=button_count&size=small&width=77&height=20&appId";

const updatedTags = tagparam.map((tag) => {
  const link =
    "https://www.google.com/search?q=huyetnguyet+" + tag.replace(" ", "+");
  return (
    <a href={link} className="tag">
      {tag}
    </a>
  );
});

export default function page_20210813223818() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      
      <Content>
        <div className="page">
          <AdsVertical />
          <div className="path">
            <a href="/" className="pathName">
              home
            </a>
            <div className="pathName" style={{ color: "red" }}>
              {">"}
            </div>
            <a href={categoryLink} className="pathName">
              {category}
            </a>
          </div>
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="info">
            <p>
              <div className="author">{author}</div> - Theo {source} | {timestamp}
            </p>
          </div>
          <FacebookShareButton link={facebookLinkShare} />

         <div class="containerContent">
            <AdsVertical />
            <div className="content">
              <div className="contentContainer">
                <p className="description">{description}</p>
                <RelationNews category={category} />
                <div className="contentBody">
                  <div className="contentBodyLeft">

<p>Ngành công nghiệp <strong>AV</strong> Nhật Bản đang phát triển một cách vô cùng mạnh mẽ trong những năm qua, và thậm chí, số lượng tân binh mỗi năm luôn lên tới cả chục nghìn người - đặc biệt là làn sóng đổ bộ của vô số các cô nàng <strong>hot girl</strong> xinh đẹp. Và bên cạnh các cô nàng diễn viên được coi là "chính quy", tức là toàn tâm toàn ý với nghề như Yua Mikami, Yui Hatano, còn có vô số những diễn viên "part time" không kém phần xinh đẹp - những người chỉ coi việc đóng <strong>phim AV</strong> như một công việc phụ mỗi khi có thời gian rảnh. Mà cô nàng <strong>Shiho Fujie</strong> chính là ví dụ cho luận điểm trên, và cũng là nhân vật được nhắc tới trong câu chuyện lần này.</p>
<ContentImage src='https://gamek.mediacdn.vn/133514250583805952/2021/8/11/photo-1-1628650191644363447295-1628650205333343767926.jpg' alt='1, HOT GIRL,GÁI XINH,PHIM AV,AV,PHIM 18+,SHIHO FUJIE,CỘNG ĐỒNG MẠNG,' note='Shiho Fujie - cô nàng hot girl xinh đẹp đang được nhắc tới trong câu chuyện'/>
<p>Xinh đẹp động lòng người với số đo ba vòng 83-59-90, thế nhưng chẳng ai ngờ được rằng <strong>Shiho Fujie</strong> ban đầu chỉ một cô nàng diễn viên part-time. Công việc chính của cô vào giờ hành chính vẫn là nhân viên của một công ty quảng cáo và đối với Shiho, việc đóng <strong>phim 18+</strong> chỉ như một thú vui giải trí, mang tính thỏa mãn đam mê chứ cũng không đặt quá nặng yếu tố tài chính. Tuy nhiên, mọi thứ đã thay đổi chỉ sau một câu chuyện đầy bất ngờ. Cụ thể, toàn bộ đồng nghiệp của Shiho, bao gồm cả sếp của cô nàng đã khám phá ra bức màn bí mật mỗi khi tan làm của cô.</p>
<ContentImage src='https://gamek.mediacdn.vn/133514250583805952/2021/8/11/photo-1-16286513573801771944023.jpg' alt='2, HOT GIRL,GÁI XINH,PHIM AV,AV,PHIM 18+,SHIHO FUJIE,CỘNG ĐỒNG MẠNG,' note='Ít ai biết rằng bên cạnh công việc ở công ty quảng cáo'/>
<ContentImage src='https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-16286514182141154267138.jpg' alt='3, HOT GIRL,GÁI XINH,PHIM AV,AV,PHIM 18+,SHIHO FUJIE,CỘNG ĐỒNG MẠNG,' note='Shiho Fujie còn tranh thủ đóng phim 18+ mỗi khi rảnh rỗi'/>
<p>Theo đó, vào một ngày cũng như bao ngày, Shiho vẫn tới công ty như thường lệ. Tuy nhiên, trong lúc đang chuẩn bị làm việc, Shiho đã cảm thấy rất bất ngờ khi nghe thấy sếp gọi mình vào phòng họp riêng. Những tưởng Shiho đã làm sai chuyện gì, nhưng không, sếp cô mở máy tính lên và màn hình hiển thị một bộ phim người lớn với Shiho là nhân vật chính. "Tôi đã xem qua rồi, giờ chỉ muốn xác nhận. Có phải cô đóng bộ phim này không, có phải cô đang làm nó như một việc part time không?" - người sếp liên tục gặng hỏi Shiho.</p>
<RelationNewsInPage category={category} />
<ContentImage src='https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628651827787178574494.jpg' alt='4, HOT GIRL,GÁI XINH,PHIM AV,AV,PHIM 18+,SHIHO FUJIE,CỘNG ĐỒNG MẠNG,' note='Shiho khiến nhiều người bất ngờ khi chia sẻ lại câu chuyện của mình'/>
<p>Tất nhiên, tới nước này rồi thì Shiho cũng chẳng có lý do gì để giấu diếm thêm nữa. Cô nàng bản tính vốn bộc trực nên cũng trình bày vấn đề rất thẳng, sau đó tự quyết định nghỉ việc. Bản thân Shiho sau đó còn khiến không ít fan phải bật cười khi chia sẻ rằng mặc dù sếp không phản ứng nhưng lại khiến cô liên tưởng khá nhiều tới các kịch bản <strong>phim 18+</strong> "Nếu không muốn tôi nói ra"... Nhưng tất nhiên, đây là cuộc sống thực nên không xuất hiện điều tương tự.</p>
<ContentImage src='https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628652018154667557228.jpg' alt='5, HOT GIRL,GÁI XINH,PHIM AV,AV,PHIM 18+,SHIHO FUJIE,CỘNG ĐỒNG MẠNG,' note='Shiho khiến không ít fan phải bật cười'/>
<p>Còn hiện tại, Shiho đã là một diễn viên <strong>phim 18+</strong> toàn thời gian và cô nàng cũng rất nhanh đã gặt hái cho mình vô số thành công sau khi ra mắt. Nàng <strong>hot girl</strong> chia sẻ nếu như không có sự cố với sếp, bản thân chắc cũng sẽ tự nghỉ việc vì cuộc sống văn phòng thật sự quá chán ngán, tẻ nhạt.</p>


                    </div>
                  <AdsHorizontal />
                </div></div>

                <RandomFeature />

                <div className="source">Source: {source}</div>

                <FacebookShareButton link={facebookLinkShare} />

                <div className="tags">Tags: {updatedTags}</div>
              </div>
            </div>
          </div>
      </Content>
    </>
  );
}
