// eslint-disable-next-line
import AdSense from "react-adsense";
// eslint-disable-next-line
const client = "ca-pub-9670179252828195";
// eslint-disable-next-line
const slot_square = "6478931561";
// eslint-disable-next-line
const slot_horizontal = "1992653961";
// eslint-disable-next-line
const slot_vertical = "5391470746";

export function AdsVertical() {
  return <div className="adsVertical"></div>;
}

export function AdsVerticalHomeContent() {
  return <div className="adsVerticalHome"></div>;
}
export function AdsHorizontal() {
  return (
    <div className="adsHorizontal ">
      <div className="sticky"></div>
    </div>
  );
}

export function AdsFeaturdSection() {
  return <div className="adsFeaturedSection"></div>;
}
