import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  /*<React.StrictMode>
    <HashRouter basename={process.env.PUBLIC_URL}>
      <App />
    </HashRouter>
  </React.StrictMode>,*/
  /*

    */
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </BrowserRouter>,

  /* <HashRouter basename={process.env.PUBLIC_URL}>
    <App />
  </HashRouter>,
*/
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
