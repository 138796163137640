export const data_tech=[{title: "Modder người Nga độ PC thành chiếc xe tăng Stormblade trong Warhammer 40K nặng 20kg, động cơ chạy bằng CPU i9 và RTX 2060",
description: "Mời các bạn chiêm ngưỡng độ đẹp và ngầu của chiếc PC Stormblade trong dòng game Warhammer 40K của một modder người Nga làm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/24/photo-1-1629811963238840550034.jpg',
alt: "DÀN PC,STORMBLADE,MEGOLET,WARHAMMER 40K,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '08:41 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:41 AM',
link: '/modder-nguoi-nga-do-pc-thanh-chiec-xe-tang-stormblade-trong-warhammer-40k-nang-20kg-dong-co-chay-bang-cpu-i9-va-rtx-2060',
component: 'page_20210828084101'},
{title: "Top 4 mẫu laptop giá rẻ tốt nhất dành cho sinh viên mùa tựu trường",
description: "Với phân khúc 10 - 15 triệu, người dùng có thể sở hữu một chiếc laptop cho dân văn phòng, học sinh - sinh viên để học tập và làm việc online tại nhà thời điểm giãn cách xã hội. Dưới đây là top 4 mẫu laptop văn phòng tầm trung tốt nhất tại hệ thống CellphoneS.",
src: 'https://kenh14cdn.com/thumb_w/800/pr/2021/photo-1-1629467890674303829136-0-22-527-866-crop-1629469654988-63765095888195.jpg',
alt: "laptop giá rẻ,mùa tựu trường,dân văn phòng,laptop văn phòng,",
category: 'tech',
time: '11:05 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:05 AM',
link: '/top-4-mau-laptop-gia-re-tot-nhat-danh-cho-sinh-vien-mua-tuu-truong',
component: 'page_20210821110541'},
{title: "Hơn 300.000 thông tin sinh viên Việt Nam bị hacker rao bán",
description: "Thông tin cá nhân của nhiều sinh viên Việt Nam đã bị hacker đánh cắp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-16290169678581550254612.jpg',
alt: "SINH VIÊN VIỆT NAM,THÔNG TIN CÁ NHÂN,HACK,HACKER,NGƯỜI DÙNG VIỆT,",
category: 'tech',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/hon-300000-thong-tin-sinh-vien-viet-nam-bi-hacker-rao-ban',
component: 'page_20210815160549'},
{title: "Thoả sức chiến game tại nhà với bộ ba laptop gaming Acer",
description: "Những mẫu laptop gaming từ Acer chắc chắn sẽ nâng tầm trải nghiệm của bạn khi tận hưởng các tựa game hot hiện nay.",
src: 'https://channel.mediacdn.vn/thumb_w/640/2021/8/14/photo-1-1628918761364726630697.jpg',
alt: "ACER,LAPTOP,",
category: 'tech',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/thoa-suc-chien-game-tai-nha-voi-bo-ba-laptop-gaming-acer',
component: 'page_20210814114035'},
{title: "Mỹ nhân Nhật Bản kể chuyện bị mất việc vì gò bồng đảo quá khủng, tức mình chuyển nghề 18+",
description: "Ngay từ lúc còn đi học, cô nàng Riri Nanatsumori đã sở hữu bộ ngực lớn hơn so với các bạn đồng trang lứa.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/photo-1-16287504068951491005346.jpg',
alt: "RIRI NANATSUMORI,IDOL,",
category: 'tech',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/my-nhan-nhat-ban-ke-chuyen-bi-mat-viec-vi-go-bong-dao-qua-khung-tuc-minh-phai-chuyen-nghe-18',
component: 'page_20210813092540'},
{title: "ViewSonic VX2468-PC-MHD: Màn gaming siêu mượt 165Hz, giá lại còn \"hạt dẻ\"",
description: "Mẫu màn hình gaming mới toanh tới từ ViewSonic, với một thiết kế sáng tạo và độc đáo, đi kèm với hiệu năng ấn tượng. Sản phẩm này được kỳ vọng làm mọi game thủ hài lòng với mức giá vô cùng hợp lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/nested-sequence-0900030624still003-1628771661082495164199.jpg',
alt: "MÀN HÌNH VIEWSONIC,MÀN HÌNH GAMING,VX2468-PC-MHD,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/viewsonic-vx2468-pc-mhd-man-gaming-sieu-muot-165hz-gia-lai-con-hat-de',
component: 'page_20210813092530'},
{title: "Trên tay cặp RAM \"quái thú\" Kingston Fury Renegade RGB 4600, nằm trong top đầu thế giới",
description: "Kingston Fury Renegade RGB 4600 sở hữu hiệu năng vô cùng khủng khiếp dành cho những game thủ hàng đầu và \"dân chơi\" công nghệ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628702578003297069338.jpg',
alt: "RAM QUÁI THÚ,RAM MẠNH NHẤT THẾ GIỚI,LINH KIỆN MÁY TÍNH,RAM,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/tren-tay-cap-ram-quai-thu-kingston-fury-renegade-rgb-4600-nam-trong-top-dau-the-gioi',
component: 'page_20210813092159'},
{title: "Trên tay màn hình gaming VX3268-2KPC-MHD: Cong, to, chơi game siêu đã",
description: "Nếu như bạn đang tìm kiếm một mẫu màn hình gaming cong với kích cỡ lớn, độ phân giải cao nhưng đi kèm với một mức giá hợp lý thì ViewSonic VX3268-2KPC-MHD quả thật là sự lựa chọn siêu phù hợp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/sequence-0300050108still016-16287791555691728394936.png',
alt: "MÀN HÌNH GAMING,VIEWSONIC,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/tren-tay-man-hinh-gaming-vx3268-2kpc-mhd-cong-to-choi-game-sieu-da',
component: 'page_20210812173254'},
{title: "ViewSonic VX2468-PC-MHD: Màn gaming siêu mượt 165Hz, giá lại còn \"hạt dẻ\"",
description: "Mẫu màn hình gaming mới toanh tới từ ViewSonic, với một thiết kế sáng tạo và độc đáo, đi kèm với hiệu năng ấn tượng. Sản phẩm này được kỳ vọng làm mọi game thủ hài lòng với mức giá vô cùng hợp lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/nested-sequence-0900030624still003-1628771661082495164199.jpg',
alt: "MÀN HÌNH VIEWSONIC,MÀN HÌNH GAMING,VX2468-PC-MHD,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/viewsonic-vx2468-pc-mhd-man-gaming-sieu-muot-165hz-gia-lai-con-hat-de',
component: 'page_20210812173217'},
{title: "Cả thanh xuân dùng máy tính, liệu bạn có biết hết những phím tắt \"thần thánh\" này?",
description: "Dùng Windows lâu năm, nhưng có những phím tắt đến bây giờ chúng ta mới biết.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/base64-16286003709001840645289.png',
alt: "bạn có biết?,tổ hợp phím,tiết kiệm thời gian,đa chức năng,ứng dụng windows,",
category: 'tech',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/ca-thanh-xuan-dung-may-tinh-lieu-ban-co-biet-het-nhung-phim-tat-than-thanh-nay',
component: 'page_20210811110000'},
{title: "Trên tay MSI SPATIUM M480, SSD cao cấp dành cho game thủ đích thực",
description: "MSI SPATIUM M480 là mẫu SSD cao cấp với hiệu năng vượt trội.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/photo-1-162833286592113245303.jpg',
alt: "SSD,MSI,SPATIUM M480,Ổ CỨNG SSD,",
category: 'tech',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/tren-tay-msi-spatium-m480-ssd-cao-cap-danh-cho-game-thu-dich-thuc',
component: 'page_20210807154609'},
{title: "6 cách đơn giản để kiểm tra và khắc phục chuột không dây bị đơ",
description: "Hãy thử làm theo những cách này nếu có vấn đề về chuột không dây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-1628180440158944367743.jpg',
alt: "CHUỘT KHÔNG DÂY,CHUỘT MÁY TÍNH,CHUỘT,",
category: 'tech',
time: '09:36 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:36 AM',
link: '/6-cach-don-gian-de-kiem-tra-va-khac-phuc-chuot-khong-day-bi-do',
component: 'page_20210806093650'},
{title: "Thủ thuật đơn giản giúp Google Chrome bớt ngốn RAM",
description: "Cách đơn giản giúp sử dụng Google Chrome bớt ngốn RAM.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/-1628164834349595680420.jpg',
alt: "RAM,GOOGLE CHROME,CHROME,",
category: 'tech',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/thu-thuat-don-gian-giup-google-chrome-bot-ngon-ram',
component: 'page_20210805084714'},
{title: "Wakuoo - Nền tảng chơi Game Mobile trên PC thế hệ mới nhẹ hơn giả lập",
description: "Nếu bạn muốn trải nghiệm Game&APP Android trên máy tính mà không muốn cài giả lập vì lo nặng máy, thì Wakuoo chính là sự lựa chọn không tồi, phù hợp cho máy tính cấu hình thấp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/5-16281344631912005858058.jpg',
alt: "WAKUOO,CHƠI GAME ANDROID TRÊN PC,CHƠI GAME MOBILE TRÊN PC,GIẢ LẬP ANDROID,",
category: 'tech',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/wakuoo-nen-tang-choi-game-mobile-tren-pc-the-he-moi-nhe-hon-gia-lap',
component: 'page_20210805084655'},
{title: "Hướng dẫn reset modem để sóng Wi-fi mạnh hơn, ổn định hơn",
description: "Một phương pháp đơn giản giúp sóng Wi-fi ở nhà bạn luôn ổn định.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/photo-1-1628072573804417190587.jpg',
alt: "MODEM,MODEM WI-FI,SÓNG WI-FI,WI-FI,WIFI,MODEM WIFI,KẾT NỐI INTERNET,",
category: 'tech',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/huong-dan-reset-modem-de-song-wi-fi-manh-hon-on-dinh-hon',
component: 'page_20210804185950'},
{title: "Đòi tiền chuộc EA không trả, rao bán chẳng ai thèm mua, hacker đắng lòng phát miễn phí mã nguồn FIFA 21 lên mạng",
description: "Sau nỗ lực tống tiền EA và rao bán chợ đen thất bại, hacker bất lực phát tán mã nguồn FIFA 21 cho ai dùng thì dùng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-162801406573018726064.jpg',
alt: "FIFA,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,HACKER,DỮ LIỆU,",
category: 'tech',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/doi-tien-chuoc-ea-khong-tra-rao-ban-chang-ai-them-mua-hacker-dang-long-phat-mien-phi-ma-nguon-fifa-21-len-mang',
component: 'page_20210804185739'},
{title: "Tổng hợp 5 cách để giúp PC của bạn đỡ ồn vào đêm khuya",
description: "Vào buổi đêm tĩnh lặng, bạn sẽ thấy dàn PC của mình phát ra những tiếng ồn ào không cần thiết...",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/photo-1-16280086374332119094977.jpg',
alt: "TIẾNG ỒN,GAMING,DÀN PC,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'tech',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/tong-hop-5-cach-de-giup-pc-cua-ban-do-on-vao-dem-khuya',
component: 'page_20210804185714'},
{title: "8 mẹo đơn giản để tăng tín hiệu sóng Wi-fi lên mức tối đa",
description: "Trong những ngày giãn cách xã hội, mạng Wi-fi là một trong những yếu tố quan trọng để học tập, làm việc và giải trí.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/1/photo-1-1627820158564578927919.jpg',
alt: "YẾU TỐ QUAN TRỌNG,TĂNG TỐC MẠNG WI-FI,WI-FI,SÓNG WI-FI,TĂNG TỐC MẠNG,TĂNG TỐC WI-FI,TÍN HIỆU WI-FI,",
category: 'tech',
time: '01:19 AM',
date: '44235',
timestamp: '02/08/2021 01:19 AM',
link: '/8-meo-don-gian-de-tang-tin-hieu-song-wi-fi-len-muc-toi-da',
component: 'page_20210802011917'},
{title: "Nếu không thích MacBook thì đây là 5 mẫu laptop sang chảnh mỏng nhẹ xịn xò không kém cho các nàng",
description: "MacBook sang xịn thật nhưng không phải ai cũng thích dùng, và đây là những gợi ý không kém phần chất lượng trong tầm giá 25 triệu.",
src: 'https://kenh14cdn.com/2020/11/6/frame-285-16046320623751918346486.png',
alt: "laptop,Macbook,webuy,thích thì mua,Vũ trụ ở nhà không la cà,đồ điện tử,đồ công nghệ,",
category: 'tech',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/neu-khong-thich-macbook-thi-day-la-5-mau-laptop-sang-chanh-mong-nhe-xin-xo-khong-kem-cho-cac-nang',
component: 'page_20210729162444'},
{title: "Tựa game tỷ đô do người Việt sản xuất lên sóng truyền hình quốc gia vì mức độ tăng trưởng “khủng\", cộng đồng được dịp nức nở",
description: "Axie Infinity đang trở thành hiện tượng công nghệ toàn cầu khi thu hút hàng trăm nghìn game thủ từ khắp nơi trên thế giới, giá trị vốn hóa lên tới tỷ đô.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/26/photo-1-16272869695311005334810.jpg',
alt: "VTV,game,Axie Infinity,dự án game,2 tỷ usd,Đài truyền hình quốc gia,Nhà sáng lập,công ty công nghệ,nhà phát triển,giá trị vốn hóa,tổng giá trị,tỷ phú công nghệ,",
category: 'tech',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/tua-game-ty-do-do-nguoi-viet-san-xuat-len-song-truyen-hinh-quoc-gia-vi-muc-do-tang-truong-khung-cong-dong-duoc-dip-nuc-no',
component: 'page_20210729162423'},
{title: "Biểu tượng cảm xúc nào nổi tiếng nhất thế giới?",
description: "Phần lớn người dùng emoji đồng ý rằng biểu tượng “cười ra nước mắt” vô cùng thú vị.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/19/photo-1-16266831185991254590428.jpg',
alt: "EMOJI,NỔI TIẾNG NHẤT,NGƯỜI THAM GIA,MẠNG XÃ HỘI,",
category: 'tech',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/bieu-tuong-cam-xuc-nao-noi-tieng-nhat-the-gioi',
component: 'page_20210729162028'},
{title: "Có thể cài Windows 11, game crack và cả Epic Games Store trên Steam Deck",
description: "Steam Deck sẽ tạo ra một cuộc cách mạng mới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-1626587589951893523105.jpg',
alt: "MÁY CHƠI GAME,HỆ ĐIỀU HÀNH,HỆ MÁY CONSOLE,NINTENDO SWITCH,STEAM DECK,",
category: 'tech',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/co-the-cai-windows-11-game-crack-va-ca-epic-games-store-tren-steam-deck',
component: 'page_20210729160609'},
{title: "Bộ giáp Rescue của vợ Iron Man mạnh mẽ tới mức nào trong MCU?",
description: "Bạn có tò mò về sức mạnh của bộ giáp mà Tony Stark chế tạo cho Pepper Potts không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-1626576566449109487457.jpg',
alt: "TONY STARK,IRON MAN,MARVEL,RESCUE,PEPPER POTTS,BỘ GIÁP RESCUE,",
category: 'tech',
time: '04:05 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:05 PM',
link: '/bo-giap-rescue-cua-vo-iron-man-manh-me-toi-muc-nao-trong-mcu',
component: 'page_20210729160539'},
{title: "Đập hộp mainboard ASUS Maximus XIII Extreme: 25 triệu, hàng khủng siêu cấp vô địch",
description: "Chỉ một chiếc mainboard đã có giá cao hơn cả một bộ case hoàn chỉnh của nhiều anh em mất rồi...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/photo-1-162497655243056236696.jpg',
alt: "MAINBOARD,BO MẠCH CHỦ,ASUS,ASUS MAXIMUS XIII EXTREME,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '10:02 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:02 PM',
link: '/dap-hop-mainboard-asus-maximus-xiii-extreme-25-trieu-hang-khung-sieu-cap-vo-dich',
component: 'page_20210629220238'},
{title: "Hướng dẫn cách kiểm tra xem máy tính của bạn có cài được Windows 11 hay không",
description: "Windows 11 sẽ sớm ra mắt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/26/untitled-16246844690631257928528.png',
alt: "GÃ KHỔNG LỒ,CÀI ĐẶT WINDOWS,WINDOWS 11,WINDOWS 10,",
category: 'tech',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/huong-dan-cach-kiem-tra-xem-may-tinh-cua-ban-co-cai-duoc-windows-11-hay-khong',
component: 'page_20210629215227'},
{title: "Lộ cách thức bẻ khóa iPhone của cỗ máy khét tiếng trong giới bảo mật",
description: "Nổi danh trong giới bảo mật vì khả năng bẻ khóa iPhone, đây là lần đầu cách thức hoạt động của GrayKey lộ diện.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/26/photo-1-16247074555841400998645.png',
alt: "IPHONE,BẢO MẬT,BẺ KHÓA,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/lo-cach-thuc-be-khoa-iphone-cua-co-may-khet-tieng-trong-gioi-bao-mat',
component: 'page_20210629215205'},
{title: "Cuộc hành quân \"thần tốc\" trong đêm của chiến sĩ Bắc Giang để nhường doanh trại cho người dân cách ly",
description: "Hàng trăm chiến sĩ của Sư đoàn 325 tại huyện Lục Ngạn (Bắc Giang) đã hành quân vào rừng, dựng hàng chục lán trại dã chiến để nhường doanh trại cho các công nhân ở Bắc Giang cách ly tập trung.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/6/18/dsc5152-16239532159142115958997.jpg',
alt: "",
category: 'tech',
time: '01:03 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:03 PM',
link: '/cuoc-hanh-quan-than-toc-trong-dem-cua-chien-si-bac-giang-de-nhuong-doanh-trai-cho-nguoi-dan-cach-ly',
component: 'page_20210618130328'},
{title: "Đánh giá Samsung 980 - SSD PCIe gen 3 vẫn thể hiện đẳng cấp nhanh \"xé gió\"",
description: "Samsung 980 là phiên bản thấp hơn của chiếc SSD hàng khủng 980 Pro, sử dụng kết nối PCIe gen 4, nhưng với nhu cầu sử dụng phổ thông thì vẫn hết sức ấn tượng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/photo-1-16239370492261108255459.jpg',
alt: "SSD,SAMSUNG,SAMSUNG 980,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '01:02 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:02 PM',
link: '/danh-gia-samsung-980-ssd-pcie-gen-3-van-the-hien-dang-cap-nhanh-xe-gio',
component: 'page_20210618130218'},
{title: "Đào Bitcoin thực sự tiêu tốn năng lượng đến mức nào?",
description: "Đào Bitcoin thực sự tốn năng lượng?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/photo-2-16239487061001019874080.jpg',
alt: "MUA XE HƠI,Ô NHIỄM MÔI TRƯỜNG,GIAO DỊCH BITCOIN,SỬ DỤNG CÔNG NGHỆ,TIÊU THỤ ĐIỆN,",
category: 'tech',
time: '01:02 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:02 PM',
link: '/dao-bitcoin-thuc-su-tieu-ton-nang-luong-den-muc-nao',
component: 'page_20210618130206'},
{title: "Worm là gì? Tại sao chúng lại nguy hiểm với máy tính?",
description: "Worm máy tính là một loại chương trình phần mềm độc hại có chức năng chính là lây nhiễm sang các máy tính khác trong khi vẫn hoạt động trên những hệ thống bị nhiễm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/photo-1-1623999663289177940704.jpg',
alt: "WORM,PC,WORM MÁY TÍNH,HỆ ĐIỀU HÀNH WINDOWS,CHƯƠNG TRÌNH PHẦN MỀM,PHẦN MỀM ĐỘC HẠI,",
category: 'tech',
time: '01:00 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:00 PM',
link: '/worm-la-gi-tai-sao-chung-lai-nguy-hiem-voi-may-tinh',
component: 'page_20210618130029'},
{title: "3 CPU giá rẻ dưới 2 triệu nhưng vẫn chơi game ngon mà không cần card đồ họa",
description: "Những CPU tích hợp nhân đồ họa mạnh đang trở thành cứu cánh cho game thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/photo-1-16226973043441708766718.jpg',
alt: "Card Man Hinh",
category: 'tech',
time: '05:41 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:41:00',
link: '/3-cpu-gia-re-duoi-2-trieu-nhung-van-choi-game-ngon-ma-khong-can-card-do-hoa',
component: 'page_20210603054155'},
]
