export const data_games=[{title: "Top 7 nhân vật ích kỷ nhất Dragon Ball, Thần Hủy Diệt cũng góp mặt, Frieza thế mà chỉ đứng hạng 4",
description: "Tuyến nhân vật trong Dragon Ball rất đang dạng, bên cạnh những anh hùng luôn hết mình bảo vệ trái đất cũng có nhiều cá nhân bị thúc đẩy bởi lòng tham và sự ích kỷ của bản thân.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/28/photo-1-16301371086941660807152.jpg',
alt: "BABY,DRAGON BALL,ZAMASU,ĐỘI TRƯỞNG GINYU,FRIEZA,KID BUU,",
category: 'games',
time: '08:52 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:52 AM',
link: '/top-7-nhan-vat-ich-ky-nhat-dragon-ball-than-huy-diet-cung-gop-mat-frieza-the-ma-chi-dung-hang-4',
component: 'page_20210828085257'},
{title: "One Piece: Điểm độc đáo từ \"Phái vô kiếm\" của Zoro, không thể gây sát thương nhưng vẫn thổi bay được kẻ thù",
description: "Kỹ thuật Mutoryu của Zoro có vẻ như không thể làm tổn thương mục tiêu?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/28/photo-1-163013486630761511058.jpg',
alt: "ZORO,MUTORYU,VÔ THUẬT KIẾM PHÁP,ONE PIECE,DRAGON TWISTER,TATSU MAKI,PHÁI VÔ KIẾM,",
category: 'games',
time: '08:52 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:52 AM',
link: '/one-piece-diem-doc-dao-tu-phai-vo-kiem-cua-zoro-khong-the-gay-sat-thuong-nhung-van-thoi-bay-duoc-ke-thu',
component: 'page_20210828085214'},
{title: "Bí ẩn lớn nhất chưa được tiết lộ trong Pokémon: Ông trùm của đội Hỏa Tiễn là ai?",
description: "Có lẽ ông trùm của đội Hỏa Tiễn Pokémon còn gây tò mò cho fan hâm mộ manga-anime hơn cả ông trùm của Tổ chức Áo đen trong Thám tử Conan",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/28/giovanni-pokemon-header-1630124818537845738155.jpeg',
alt: "KHÁM PHÁ,POKÉMON,ĐỘI HỎA TIỄN,",
category: 'games',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/bi-an-lon-nhat-chua-duoc-tiet-lo-trong-pokemon-ong-trum-cua-doi-hoa-tien-la-ai',
component: 'page_20210828085154'},
{title: "Bùng nổ trào lưu NFT, game thủ Việt cần nắm rõ những yếu tố sau đây trước khi \"làm giàu\"",
description: "Các tựa game NFT nghe qua thì có vẻ đơn giản mà kiếm tiền dễ, nhưng chắc chắn điều này chỉ đúng với những ai thật sự hiểu về nó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-1629689106820390180406.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,BÀI VIẾT ĐÁNG CHÚ Ý,GAME NFT,",
category: 'games',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/bung-no-trao-luu-nft-game-thu-viet-can-nam-ro-nhung-yeu-to-sau-day-truoc-khi-quyet-tam-lam-giau',
component: 'page_20210828085003'},
{title: "Top 5 siêu đạo chích nổi tiếng bậc nhất trong thế giới anime, Kaito Kid vẫn chưa phải là số 1",
description: "Cùng chúng tôi điểm qua 5 nhân vật được cho là những siêu đạo chích nổi tiếng nhất trong thế giới anime dựa trên ý kiến bình chọn của nhiều khán giả.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/27/kai1-1630072791701609201231.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,NHÂN VẬT ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '08:49 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:49 AM',
link: '/top-5-sieu-dao-chich-noi-tieng-bac-nhat-trong-the-gioi-anime-kaito-kid-van-chua-phai-la-so-1',
component: 'page_20210828084920'},
{title: "Những custom map chống cửa từng một thời làm điên đảo làng game Việt, tới giờ chơi lại vẫn thấy hay",
description: "Những map này từng một thời lấy đi không ít thời gian của các game thủ Việt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/21/photo-1-1629527412470825721201.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,BÀI VIẾT ĐÁNG CHÚ Ý,",
category: 'games',
time: '08:48 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:48 AM',
link: '/nhung-custom-map-chong-cua-tung-mot-thoi-lam-dien-dao-lang-game-viet-toi-gio-choi-lai-van-thay-hay',
component: 'page_20210828084824'},
{title: "Các ác xà nổi tiếng trong thần thoại: Orochi thực ra cũng thường thôi!",
description: "Trước các ác xà này, Bát Kỳ Đại Xà Orochi gần nhiên không còn đáng sợ nữa",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/b2e60fbejti61-16299688029501760873260.jpg',
alt: "QUÁI VẬT,ÁC XÀ,THẦN THOẠI,",
category: 'games',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/cac-ac-xa-noi-tieng-trong-than-thoai-orochi-thuc-ra-cung-thuong-thoi',
component: 'page_20210828084530'},
{title: "Tải ngay loạt game mobile offline siêu hấp dẫn dưới đây, đã hay lại còn miễn phí 100%",
description: "Những tựa game này đảm bảo đủ ba tiêu chí “Ngon, Bổ và hoàn toàn Miễn Phí”.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/photo-1-16299532016221939272110.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/tai-ngay-loat-game-mobile-offline-sieu-hap-dan-duoi-day-da-hay-lai-con-mien-phi-100',
component: 'page_20210828084521'},
{title: "Kimetsu no Yaiba: Nezuko là con quỷ duy nhất đeo mõm tre, có phải nó đã giúp cô bé kiềm chế cơn khát máu?",
description: "Đã bao giờ các bạn thắc mắc tại sao sau khi hóa quỷ Nezuko phải đeo ống tre chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/tanjiro-holds-off-frenzied-nezuko-16299660015721702743896.jpg',
alt: "NEZUKO,MÕM TRE,ỐNG TRE,KIMETSU NO YAIBA,CON QUỶ NEZUKO,TANJIRO,",
category: 'games',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/kimetsu-no-yaiba-nezuko-la-con-quy-duy-nhat-deo-mom-tre-co-phai-no-da-giup-co-be-kiem-che-con-khat-mau',
component: 'page_20210828084512'},
{title: "Não Bát Vàng là món thần khí gì mà khiến Ngộ Không kinh hồn bạt vía?",
description: "Não Bát Vàng vừa xuất hiện trong trailer của Black Myth: Wukong (Tôn Ngộ Không: Hắc Thần Thoại).",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/untitled-16299143366252134470419.png',
alt: "TÔN NGỘ KHÔNG,TỀ THIÊN ĐẠI THÁNH,BLACK MYTH: WUKONG,NÃO BÁT VÀNG,NGÔ THỪA ÂN,KIM CÔ BỔNG,",
category: 'games',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/nao-bat-vang-la-mon-than-khi-gi-ma-khien-ngo-khong-kinh-hon-bat-via',
component: 'page_20210828084435'},
{title: "15 tựa game nhập vai \"cày cuốc\" miễn phí trên Steam (P2)",
description: "Nhiều game nhập vai miễn phí đang đợi bạn trên Steam.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/25/photo-1-16299091925421354171177.jpg',
alt: "GAME NHẬP VAI,GAME MIỄN PHÍ,RUNESCAPE,GAME CÀY CUỐC,PHANTASY STAR ONLINE 2,TREE OF SAVIOR,",
category: 'games',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/15-tua-game-nhap-vai-cay-cuoc-mien-phi-tren-steam-p2',
component: 'page_20210828084423'},
{title: "Top 15 tựa game thế giới mở hay nhất trên PC (P.2)",
description: "Các bạn đang muốn kiếm tìm những tựa game PC thế giới mở hay nhất để trải nghiệm cho khuây khỏa? Thì đây là những ứng cử viên không thể hợp lý hơn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/25/photo-1-1629864885974713433190.jpg',
alt: "GAME HAY,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,GAME THẾ GIỚI MỞ,TOP GAME,",
category: 'games',
time: '08:43 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:43 AM',
link: '/top-15-tua-game-the-gioi-mo-hay-nhat-tren-pc-p2',
component: 'page_20210828084326'},
{title: "Top 15 tựa game thế giới mở hay nhất trên PC",
description: "Các bạn đang muốn kiếm tìm những tựa game PC thế giới mở hay nhất để trải nghiệm cho khuây khỏa? Thì đây là những ứng cử viên không thể hợp lý hơn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/24/photo-1-16298134287782048916120.jpg',
alt: "GAME,GAME HAY,GAME ĐỈNH,GAME THẾ GIỚI MỞ,GAME PC,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '08:42 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:42 AM',
link: '/top-15-tua-game-the-gioi-mo-hay-nhat-tren-pc',
component: 'page_20210828084224'},
{title: "So độ \"đạt chuẩn\" giữa khẩu phần ăn của tuyển thủ và “combo\" ngồi net game thủ Việt?",
description: "Ở Việt Nam, chế độ dinh dưỡng tuyển thủ vẫn chưa thấy sự chuyên nghiệp hoá.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-1629729907579189092771.jpg',
alt: "BÀI VIẾT ĐÁNG CHÚ Ý,GAME THỦ,KHẨU PHẦN ĂN,TUYỂN THỦ,COMBO NGỒI NET,",
category: 'games',
time: '08:39 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:39 AM',
link: '/so-do-dat-chuan-giua-khau-phan-an-cua-tuyen-thu-va-combo-ngoi-net-game-thu-viet',
component: 'page_20210828083948'},
{title: "Top 10 tựa game hành động phiêu lưu hay nhất trên PC (P.2)",
description: "Nếu anh em là tín đồ của thể loại game phiêu lưu, hành động, khám phá... thì chắc chắn không thể bỏ qua 10 cái tên vô cùng đình đám này rồi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/23/photo-1-1629660434065250463231.png',
alt: "GAME,GAME ĐỈNH,GAME HAY,TOP GAME,PHIÊU LƯU,GAME HÀNH ĐỘNG,THẾ GIỚI GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '08:37 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:37 AM',
link: '/top-10-tua-game-hanh-dong-phieu-luu-hay-nhat-tren-pc-p2',
component: 'page_20210828083737'},
{title: "Top 10 tựa game hành động phiêu lưu hay nhất trên PC",
description: "Nếu anh em là tín đồ của thể loại game phiêu lưu, hành động, khám phá... thì chắc chắn không thể bỏ qua 10 cái tên vô cùng đình đám này rồi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-1629659807918925258579.jpg',
alt: "GAME HAY,GAME ĐỈNH,PHIÊU LƯU,TOP GAME,HÀNH ĐỘNG,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '08:36 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:36 AM',
link: '/top-10-tua-game-hanh-dong-phieu-luu-hay-nhat-tren-pc',
component: 'page_20210828083619'},
{title: "Loạt game hấp dẫn đưa người chơi vào vai quái vật tàn sát không gớm tay",
description: "Không phải lúc nào vào vai anh hùng hiệp nghĩa cũng thú vị cả, vào vai phản diện thú vị hơn nhiều.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/23/photo-1-162965254098123380770.jpg',
alt: "GAME,CARRION,FRIDAY THE 13TH,AMONG US,",
category: 'games',
time: '08:35 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:35 AM',
link: '/loat-game-hap-dan-dua-nguoi-choi-vao-vai-quai-vat-tan-sat-khong-gom-tay',
component: 'page_20210828083559'},
{title: "Conan và những đứa trẻ \"không được phép lớn lên\" trong thế giới anime/manga",
description: "Mặc dù các mùa thay đổi và nhiều năm trôi qua trong loạt phim hoạt hình, nhưng các nhân vật này dường như không bao giờ già đi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/22/ash-ketchum-in-front-of-alain-kalos-league-from-pokemon-1629612533169112262560.jpg',
alt: "ASH KETCHUM,CONAN,CRAYON SHIN-CHAN,NHÂN VẬT ANIME MÃI KHÔNG LỚN,NHÂN VẬT HOẠT HÌNH,DORAEMON,NOBITA,",
category: 'games',
time: '08:34 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:34 AM',
link: '/conan-va-nhung-dua-tre-khong-duoc-phep-lon-len-trong-the-gioi-anime-manga',
component: 'page_20210828083415'},
{title: "BXH 10 isekai anime tệ nhất thập kỷ, bạn đã \"lỡ xem\" bao nhiêu bộ trong số này? (P.2)",
description: "5 bộ isekai anime đứng đầu BXH chắc chắn sẽ khiến nhiều người ngạc nhiên",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/mom-online-16294522284821028887287.jpeg',
alt: "ANIME,ISEKAI,",
category: 'games',
time: '11:07 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:07 AM',
link: '/bxh-10-isekai-anime-te-nhat-thap-ky-ban-da-lo-xem-bao-nhieu-bo-trong-so-nay-p2',
component: 'page_20210821110706'},
{title: "Dragon Ball Super: Vượt qua Ultra Instinct, Ultra Ego của Vegeta được \"ví von\" giống với một loại sức mạnh \"nghìn năm có một\"",
description: "Trong Dragon Ball Super chap 75, Vegeta đã tiết lộ tên hình dạng mới của mình và nó có tên là Ultra Ego – Bản ngã Tối thượng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/20/anh-1-16294391739021717332258.jpg',
alt: "DRAGON BALL SUPER,VEGETA,ULTRA EGO,ULTRA INSTINCT,BẢN NĂNG VÔ CỰC,BẢN NGÃ TỐI THƯỢNG,SIÊU SAIYAN HUYỀN THOẠI,",
category: 'games',
time: '11:06 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:06 AM',
link: '/dragon-ball-super-vuot-qua-ultra-instinct-ultra-ego-cua-vegeta-duoc-vi-von-giong-voi-mot-loai-suc-manh-nghin-nam-co-mot',
component: 'page_20210821110625'},
{title: "Ai còn nhớ huyền thoại Haikyuu!, điểm lại 15 nhân vật được người hâm mộ vô cùng yêu thích nào!",
description: "Cùng xem nhân vật bạn yêu thích của Haikyuu! có tên trong danh sách này không nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/photo-1-16294438876682085910423.png',
alt: "HAIKYUU!,VUA BÓNG CHUYỀN,NHÂN VẬT HAIKYUU!,HINATA SHOYO,KAGEYAMA TOBIO,",
category: 'games',
time: '09:23 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:23 AM',
link: '/ai-con-nho-huyen-thoai-haikyuu-diem-lai-15-nhan-vat-duoc-nguoi-ham-mo-vo-cung-yeu-thich-nao',
component: 'page_20210820092321'},
{title: "Biết rằng không tốt, tại sao nhiều game thủ Việt vẫn cứ đâm đầu vào \"server lậu\"?",
description: "Không phải ngẫu nhiên mà dù nhận thức được vấn đề, thế nhưng game thủ Việt vẫn rất ưa thích server lậu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-16290080798151868990329.jpg',
alt: "GAME,TIN TỨC GAME,GAME THỦ,BÀI ĐÁNG CHÚ Ý,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/biet-rang-khong-tot-the-nhung-tai-sao-nhieu-game-thu-viet-van-cu-dam-dau-vao-server-lau-the-gioi-ngam-cua-lang-game-viet',
component: 'page_20210820092150'},
{title: "BXH 10 isekai anime tệ nhất thập kỷ, bạn đã \"lỡ xem\" bao nhiêu bộ trong số này? (P.1)",
description: "Trong một thập kỷ vừa qua, isekai anime xuất hiện và gần như càn quét cộng đồng fan manga-anime. Vậy đâu là những bộ phim bị đánh giá dở tệ nhất?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/high-school-prodigies-16292781850941653289036.jpg',
alt: "ISEKAI,BẢNG XẾP HẠNG,ANIME,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/bxh-10-isekai-anime-te-nhat-thap-ky-ban-da-lo-xem-bao-nhieu-bo-trong-so-nay-p1',
component: 'page_20210820092140'},
{title: "6 bộ anime có cái kết gây hoang mang nhất từ trước đến nay",
description: "Không phải mọi manga và anime đều có một cái kết êm đẹp, chiều lòng khán giả.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/confusing-anime-endings-feature-1629252662239440709171.jpg',
alt: "ANIME,MANGA,ENDING,KHÁM PHÁ,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/6-bo-anime-co-cai-ket-gay-hoang-mang-nhat-tu-truoc-den-nay',
component: 'page_20210820092128'},
{title: "18 món đồ chơi quen thuộc mà thế hệ 8x, 9x khắp thế giới đều biết, kể cả Việt Nam luôn",
description: "Hóa ra những món đồ chơi này đều rất nổi tiếng ở trên thế giới, chứ không chỉ ở nước mình đâu nhé!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/20/photo-1-1629436770307546739656.jpg',
alt: "ĐỒ CHƠI,TRẺ NHỎ,TUỔI THƠ,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/18-mon-do-choi-quen-thuoc-ma-the-he-8x-9x-khap-the-gioi-deu-biet-ke-ca-viet-nam-luon',
component: 'page_20210820092118'},
{title: "One Punch Man: Ngoài cú đấm \"một phát chết luôn\" đây là 10 khả năng cực mạnh của Saitama mà không phải ai cũng biết (P1)",
description: "Trong One Punch Man, Saitama được biết đến với những cú đấm cực mạnh, tuy nhiên bên cạnh đó anh cũng sở hữu những khả năng đáng kinh ngạc khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/anh-1-16294330111421917383989.jpg',
alt: "ONE PUNCH MAN,THÁNH PHỒNG SAITAMA,CÚ ĐẤM MỘT PHÁT CHẾT LUÔN,SỨC MẠNH SAITAMA,MANGA NHẬT BẢN,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/one-punch-man-ngoai-cu-dam-mot-phat-chet-luon-day-la-10-kha-nang-cuc-manh-cua-saitama-ma-khong-phai-ai-cung-biet-p1',
component: 'page_20210820092108'},
{title: "Dragon Ball Super: 4 sự thật về Bản Ngã Tối Thượng - sức mạnh mới Vegeta đạt được khi đánh nhau với Granolah",
description: "Nếu Bản Năng Vô Cực của Goku thiên về phòng thủ thì Bản Ngã Tối Thượng của Vegeta mạnh về tấn công.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/19/photo-1-1629366956824601229100.jpg',
alt: "VEGETA,BẢN NGÃ TỐI TƯỢNG,GRANOLAH,ULTRA EGO,SỨC MẠNH MỚI CỦA VEGETA,DRAGON BALL SUPER CHƯƠNG 75,DRAGON BALL SUPER,",
category: 'games',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/dragon-ball-super-4-su-that-ve-ban-nga-toi-thuong-suc-manh-moi-vegeta-dat-duoc-khi-danh-nhau-voi-granolah',
component: 'page_20210819130419'},
{title: "Dragon Ball Super: Đều nâng cao sức mạnh, Fusion Dance và bông tai Potara đâu là phép \"hợp thể\" mạnh hơn?",
description: "Trong Dragon Ball Super có rất nhiều những phương pháp hợp thể mang đến sức mạnh lớn hơn cho người dùng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/19/abg-1-16293436932771911374204.png',
alt: "DRAGON BALL SUPER,FUSION DANCE,BÔNG TAI POTARA,HỢP THỂ,GOKU,VEGETA,GOGETA,VEGITO,",
category: 'games',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/dragon-ball-super-deu-nang-cao-suc-manh-fusion-dance-va-bong-tai-potara-dau-la-phep-hop-the-manh-hon',
component: 'page_20210819130409'},
{title: "Dragon Ball Super 75 tiết lộ tên hình dạng mới của Vegeta, dường như là để \"cà khịa\" Ultra Instinct của Goku",
description: "Vegeta đã tiết lộ tên hình dạng mới của mình trong Dragon Ball Super chap 75.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/19/photo-1-16293438552742024791516.png',
alt: "DRAGON BALL SUPER,DRAGON BALL SUPER CHAP 75,GOKU,VEGETA,ULTRA EGO,ULTRA INSTINCT,GRANOLAH,",
category: 'games',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/dragon-ball-super-75-tiet-lo-ten-hinh-dang-moi-cua-vegeta-duong-nhu-la-de-ca-khia-ultra-instinct-cua-goku',
component: 'page_20210819130400'},
{title: "Những nhân vật \"bán máu\" nổi tiếng trong anime - manga: Càng bầm dập, càng dễ win!",
description: "Đối với những nhân vật sử dụng máu làm vũ khí, vết thương hở có thể coi là chìa khóa đánh bại đối thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/photo-1-162921809458754272033.jpg',
alt: "MANGA,ANIME,",
category: 'games',
time: '01:03 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:03 PM',
link: '/nhung-nhan-vat-ban-mau-noi-tieng-trong-anime-manga-cang-bam-dap-cang-de-win',
component: 'page_20210819130311'},
{title: "Những dự án game blockchain đáng chú ý của người Việt",
description: "Ngoài Axie Infinity, một số dự án game blockchain kiếm tiền đang nhận được sự quan tâm của đông đảo game thủ, dù vẫn còn đang ở những giai đoạn phát triển rất sớm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-3-16292811337451720211475.jpg',
alt: "dự án game,giai đoạn phát triển,lập trình viên,Game nhập vai,nhân vật ảo,nhà phát triển,",
category: 'games',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/nhung-du-an-game-blockchain-dang-chu-y-cua-nguoi-viet',
component: 'page_20210819130158'},
{title: "Rắn Săn Mồi và những tựa game mobile đời đầu tại Việt Nam, giới trẻ ngày nay muốn cũng chẳng tìm lại được bản gốc",
description: "Cái thời chưa có smartphone thì đây chính là những tựa game được chơi nhiều nhất tại Việt Nam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628773009425905671914.jpg',
alt: "GAME THỦ,GAME,GAME MOBILE,TIN TỨC GAME,",
category: 'games',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/ran-san-moi-va-nhung-tua-game-mobile-doi-dau-tai-viet-nam-gioi-tre-ngay-nay-muon-cung-chang-tim-lai-duoc-ban-goc',
component: 'page_20210819130142'},
{title: "Top 10 tựa game indie hay nhất nửa đầu năm 2021",
description: "Game indie luôn có cách để khiến game thủ phải ngạc nhiên.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-16292276014751552938098.png',
alt: "GAME HAY,GAME ĐỈNH,GAME INDIE,GAME,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/top-10-tua-game-indie-hay-nhat-nua-dau-nam-2021',
component: 'page_20210819130135'},
{title: "Cộng đồng game thủ phát sốt trước Tifa tóc ngắn vô cùng quyến rũ",
description: "Một Tifa khác lạ nhưng vẫn cực kỳ xinh đẹp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-1629259505085449191550.jpg',
alt: "CỘNG ĐỒNG GAME,FINAL FANTASY VII,FINAL FANTASY VII REMAKE,NHÂN VẬT NỮ,TIFA,CÔ GÁI XINH ĐẸP,",
category: 'games',
time: '01:00 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:00 PM',
link: '/cong-dong-game-thu-phat-sot-truoc-tifa-toc-ngan-vo-cung-quyen-ru',
component: 'page_20210819130020'},
{title: "Cách build tối ưu của Yoimiya: Nhân vật gây tranh cãi trong cộng đồng Genshin Impact",
description: "Gác lại những tranh cãi trong cộng đồng Genshin Impact, nhân vật Yoimiya có ưu điểm gì và nên được build như thế nào?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/genshin-impact-cach-build-yoimiya-2-16292529626951351367237.jpg',
alt: "GENSHIN IMPACT,GAME,KHÁM PHÁ,",
category: 'games',
time: '01:00 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:00 PM',
link: '/cach-build-toi-uu-cua-yoimiya-nhan-vat-gay-tranh-cai-trong-cong-dong-genshin-impact',
component: 'page_20210819130010'},
{title: "5 tựa anime hành động không thể bỏ lỡ trong nửa đầu năm 2021",
description: "Nửa đầu 2021 là một mùa anime tuyệt vời và không khiến khán giả thất vọng, đặc biệt là ở thể loại hành động/phiêu lưu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/vivy-fluorite-eyes-song-01-02-44-1280x720-16291251280092063486149.png',
alt: "ANIME,HÀNH ĐỘNG,TOKYO REVENGERS,",
category: 'games',
time: '01:00 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:00 PM',
link: '/5-tua-anime-hanh-dong-khong-the-bo-lo-trong-nua-dau-nam-2021',
component: 'page_20210819130002'},
{title: "Top 10 tựa game bắn súng góc nhìn thứ nhất gắn liền với game thủ Việt thế hệ 8x, 9x (P.2)",
description: "Những game đã trở thành huyền thoại tuổi thơ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-16292245183131807925352.png',
alt: "GAME HAY,GAME,GAME ĐỈNH,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/top-10-tua-game-ban-sung-goc-nhin-thu-nhat-gan-lien-voi-game-thu-viet-the-he-8x-9x-p2',
component: 'page_20210819125951'},
{title: "10 game đồ họa đẹp xuất sắc, phong cảnh thiên nhiên siêu hùng vĩ (P2)",
description: "Với những người yêu thích game đồ họa đẹp, đây là những cái tên không nên bỏ qua.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/photo-1-1629213500801682258591.jpg',
alt: "ARMA 3,GAME ĐẸP,GAME ĐỒ HỌA ĐẸP,CHẤT LƯỢNG HÌNH ẢNH,GAME BẮN SÚNG,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/10-game-do-hoa-dep-xuat-sac-phong-canh-thien-nhien-sieu-hung-vi-p2',
component: 'page_20210819125943'},
{title: "Những tựa game chơi đơn dài dằng dặc nhưng ai cũng muốn dài nữa",
description: "Lý do đơn giản cho việc này là do game quá cuốn khiến họ không thể từ bỏ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/17/photo-1-16292170219291431767090.jpg',
alt: "GAME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/nhung-tua-game-choi-don-dai-dang-dac-nhung-ai-cung-muon-dai-nua',
component: 'page_20210819125932'},
{title: "5 cảnh Ecchi trong anime \"nóng bỏng\" nhất nửa đầu 2021, số 2 khiến các fan \"thót tim\" vì quá táo bạo",
description: "Mở đầu năm 2021 với sự xuất hiện của rất nhiều bộ anime Ecchi mới, cùng những cảnh vô cùng nóng bỏng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/anh-1-16291836454901848390812.jpg',
alt: "ANIME ECCHI,ANIME NHẬT BẢN,CẢNH NÓNG,NHÂN VẬT ANIME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/5-canh-ecchi-trong-anime-nong-bong-nhat-nua-dau-2021-so-2-khien-cac-fan-thot-tim-vi-qua-tao-bao',
component: 'page_20210819125920'},
{title: "Khám phá 5 bí mật cực \"dị\" của bán yêu InuYasha: Ngay cả fan cứng chưa chắc đã biết hết!",
description: "Cùng xem và kiểm tra xem mình có biết hết cả 5 bí mật này không bạn nhé!",
src: 'https://www.tvovermind.com/wp-content/uploads/2018/11/2121-inuyasha-the-final-act-26-960x540.jpg',
alt: "KHUYỂN DẠ XOA TRUYỀN KỲ - IP INUYASHA,GAME MOBILE,GAME ONLINE,GAME DI ĐỘNG,INUYASHA,GAME BẢN QUYỀN,ANIME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/kham-pha-5-bi-mat-cuc-di-cua-ban-yeu-inuyasha-ngay-ca-fan-cung-chua-chac-da-biet-het',
component: 'page_20210819125910'},
{title: "So sánh nét vẽ giữa manga và anime \"Có bạn gái, lại thêm bạn gái\", bản gốc đã đỉnh lên hoạt hình lại càng tuyệt hơn",
description: "Anh em có thích motip bắt cá hai tay của bộ anime mới trong mùa hè này \"Có bạn gái, lại thêm bạn gái\" không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16291028703011912979480.jpg',
alt: "ANIME,SO SÁNH ANIME VÀ MANGA,CÓ BẠN GÁI,LẠI THÊM BẠN GÁI,KANOJO MO KANOJO,ANIME MÙA HÈ,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/so-sanh-net-ve-giua-manga-va-anime-co-ban-gai-lai-them-ban-gai-ban-goc-da-dinh-len-hoat-hinh-lai-cang-tuyet-hon',
component: 'page_20210819125901'},
{title: "Dragon Ball Super: Lý do thực sự khiến Grand Zeno lại yêu mến Goku đến như vậy?",
description: "Grand Zeno là một trong những nhân vật quyền lực nhất trong Dragon Ball Super. Là một vị Thần thế nhưng Zeno lại vô cùng yêu thích Goku, ông luôn coi anh như là bạn của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/ah-1-16291008953261460905032.jpg',
alt: "DRAGON BALL SUPER,GIẢI ĐẤU SỨC MẠNH,THẦN HỦY DIỆT,GRAND ZENO,GOKU,",
category: 'games',
time: '12:58 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:58 PM',
link: '/dragon-ball-super-ly-do-thuc-su-khien-grand-zeno-lai-yeu-men-goku-den-nhu-vay',
component: 'page_20210819125852'},
{title: "Kéo hình trong Contra và những thủ thuật khiến tình bạn, anh em trong giới game thủ dễ sứt mẻ nhất mọi thời đại",
description: "Chơi game như thế này thì có ngày \"combat thật\" cũng không chừng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-1628845798165770969488.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,CONTRA,LEFT 4 DEAD,D-DAY,",
category: 'games',
time: '12:57 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:57 PM',
link: '/keo-hinh-trong-contra-va-nhung-thu-thuat-khien-tinh-ban-anh-em-trong-gioi-game-thu-de-sut-me-nhat-moi-thoi-dai',
component: 'page_20210819125720'},
{title: "Top 10 tựa game bắn súng góc nhìn thứ nhất gắn liền với game thủ Việt thế hệ 8x, 9x",
description: "Những game đã trở thành huyền thoại tuổi thơ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-1629122147785572385314.jpg',
alt: "GAME BẮN SÚNG,GAME THỦ VIỆT,GAME THỦ,GAME,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/top-10-tua-game-ban-sung-goc-nhin-thu-nhat-gan-lien-voi-game-thu-viet-the-he-8x-9x',
component: 'page_20210819125603'},
{title: "10 game đồ họa đẹp xuất sắc, phong cảnh thiên nhiên siêu hùng vĩ (P1)",
description: "Bạn sẽ bị hớp hổn bởi những tựa game đồ họa đẹp tuyệt vời này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/16/photo-1-16291136972941823190210.jpg',
alt: "RED DEAD REDEMPTION,GAME ĐỒ HỌA ĐẸP,ĐỒ HỌA ĐẸP,TÁC PHẨM NGHỆ THUẬT,GOD OF WAR,THẾ GIỚI ẢO,FAR CRY 4,MÀN ẢNH RỘNG,",
category: 'games',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/10-game-do-hoa-dep-xuat-sac-phong-canh-thien-nhien-sieu-hung-vi-p1',
component: 'page_20210819125544'},
{title: "Top 5 \"anh hói\" mạnh nhất thế giới anime, chưa ai vượt qua được \"thánh phồng\" Saitama",
description: "Dù không may mắn khi có quả đầu trọc lốc, thế nhưng bù lại 5 nhân vật này lại sở hữu sức mạnh kinh khủng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/anh-1-1629096867117208652779.jpeg',
alt: "ONE PUNCH MAN,SAITAMA,DRAGON BALL,NHÂN VẬT ANIME,NHÂN VẬT HÓI,ANIME NHẬT BẢN,",
category: 'games',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/top-5-anh-hoi-manh-nhat-the-gioi-anime-chua-ai-vuot-qua-duoc-thanh-phong-saitama',
component: 'page_20210819125535'},
{title: "Game thủ thế hệ 8-9x càng già càng lười chơi: Do game ngày xưa quá chất hay thời gian đã bào mòn tất cả?",
description: "Không thể phủ nhận một thực tế rằng, các game thủ thế hệ đầu 8-9x dường như không còn quá hào hứng với các tựa game ngày nay.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-16286774789911054554458.jpg',
alt: "GAME THỦ,TIN TỨC GAME,GAME,",
category: 'games',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/game-thu-the-he-8-9x-cang-gia-cang-luoi-choi-la-do-game-ngay-xua-qua-chat-hay-thoi-gian-da-bao-mon-tat-ca',
component: 'page_20210819125507'},
{title: "Review Free Guy: Phim có chủ đề trò chơi điện tử hay nhất từ trước đến nay?",
description: "Không chỉ là một bộ phim lấy chủ đề trò chơi điện tử, Free Guy thực sự là một bộ phim hay với tính giải trí cao.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16290889280931173430949.jpg',
alt: "TRÒ CHƠI ĐIỆN TỬ,FREE GUY,CỘNG ĐỒNG GAME,CỘNG ĐỒNG GAME THỦ,",
category: 'games',
time: '12:54 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:54 PM',
link: '/review-free-guy-phim-co-chu-de-tro-choi-dien-tu-hay-nhat-tu-truoc-den-nay',
component: 'page_20210819125458'},
{title: "Naruto: Những thông tin và sự kiện quan trọng trong 3 cuộc Đại chiến thế giới Ninja đầu tiên",
description: "Ba cuộc đại chiến Ninja đầu tiên đã cướp đi sinh mạng của rất nhiều người.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-1629093479714282877037.png',
alt: "NARUTO,BORUTO,ĐẠI CHIẾN THẾ GIỚI NINJA,CUỘC CHIẾN NINJA,LÀNG NINJA,",
category: 'games',
time: '12:53 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:53 PM',
link: '/naruto-nhung-thong-tin-va-su-kien-quan-trong-trong-3-cuoc-dai-chien-the-gioi-ninja-dau-tien',
component: 'page_20210819125355'},
{title: "Những Pokémon mà ai cũng biết dù không phải là fan của thương hiệu này",
description: "Một số Pokémon nổi tiếng đến nỗi ngay cả những người không xem thương hiệu này cũng biết đến chúng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16290922423471474035069.jpg',
alt: "PIKACHU,POKÉMON,LUCARIO,PORYGON,POKÉMON NỔI TIẾNG,",
category: 'games',
time: '12:53 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:53 PM',
link: '/nhung-pokemon-ma-ai-cung-biet-du-khong-phai-la-fan-cua-thuong-hieu-nay',
component: 'page_20210819125347'},
{title: "Top 10 con rồng bá đạo nhất trong thế giới game (P.2)",
description: "Cứ nghe đến loài rồng thì hầu hết toàn hàng khủng...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16290530066511307359247.jpg',
alt: "RỒNG,THẾ GIỚI GAME,GAME THỦ,LỊCH SỬ,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '12:52 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:52 PM',
link: '/top-10-con-rong-ba-dao-nhat-trong-the-gioi-game-p2',
component: 'page_20210819125213'},
{title: "Đừng nói Nobita nghèo nữa, nghe giá căn nhà gia đình Nobi ở mà hú hồn luôn!",
description: "Nghiên cứu về nhà đất của một tác giả viết tiểu thuyết tại Nhật Bản khiến người xem bất ngờ về giá trị căn nhà của Nobita.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/photo-1-16290954413781077324210.jpg',
alt: "Nobita,Doraemon,truyện tranh doremon,phim Nhật Bản,Phim hoạt hình,truyện tranh Nhật Bản,",
category: 'games',
time: '12:51 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:51 PM',
link: '/dung-noi-nobita-ngheo-nua-nghe-gia-can-nha-gia-dinh-nobi-o-ma-hu-hon-luon',
component: 'page_20210819125153'},
{title: "Top 10 con rồng bá đạo nhất trong thế giới game",
description: "Cứ nghe đến loài rồng thì hầu hết toàn hàng khủng...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-4-16290513465831410342950.jpg',
alt: "RỒNG,LỊCH SỬ,GAME,LÀNG GAME,THẾ GIỚI GAME,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '04:07 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:07 PM',
link: '/top-10-con-rong-ba-dao-nhat-trong-the-gioi-game',
component: 'page_20210815160733'},
{title: "Những tựa game tưởng là kết thúc có hậu nhưng hóa ra toàn lừa",
description: "Cứ nghĩ kỹ đi là bạn sẽ hiểu vấn đề xảy ra là kỳ quặc và lắm vấn đề hơn nhiều so với tưởng tượng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/15/photo-1-1629044886251622526273.jpg',
alt: "GAME,",
category: 'games',
time: '04:07 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:07 PM',
link: '/nhung-tua-game-tuong-la-ket-thuc-co-hau-nhung-hoa-ra-toan-lua',
component: 'page_20210815160724'},
{title: "Cùng nhìn lại làn sóng chơi game NFT ở Việt Nam, có \"dễ ăn\" như thiên hạ đồn thổi?",
description: "Thay vì chơi game để giải trí, giờ đây làn sóng NFT đang mang tới sức hút rất lớn cho các game thủ muốn làm giàu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628672866860630037694.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,GAME NFT,",
category: 'games',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/cung-nhin-lai-lan-song-choi-game-nft-o-viet-nam-co-de-an-nhu-thien-ha-don-thoi',
component: 'page_20210815160628'},
{title: "One Piece: Đây là 8 \"siêu năng lực\" không đến từ trái ác quỷ, có tới 3 thành viên Mũ Rơm sở hữu kỹ thuật đặc biệt",
description: "Những kỹ thuật này đều rất kỳ diệu đấy nhé!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/15/photo-1-1629019012401268560689.jpg',
alt: "ONE PIECE,MISS GOLDENWEEK,SIÊU NĂNG LỰC TRONG ONE PIECE,NĂNG LỰC KHÔNG ĐẾN TỪ TRÁI ÁC QUỶ,TRÁI ÁC QUỶ,",
category: 'games',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/one-piece-day-la-8-sieu-nang-luc-khong-den-tu-trai-ac-quy-co-toi-3-thanh-vien-mu-rom-so-huu-ky-thuat-dac-biet',
component: 'page_20210815160609'},
{title: "Top 5 nữ giáo viên quyến rũ trong thế giới anime, xem phim mà chỉ ước được cô giáo dạy học",
description: "Cùng điểm qua 5 nữ giáo viên quyến rũ trong thế giới anime mà chúng tôi tổng hợp được nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/hau3-16290103011632025900797.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/top-5-nu-giao-vien-quyen-ru-trong-the-gioi-anime-xem-phim-ma-chi-uoc-duoc-co-giao-day-hoc',
component: 'page_20210815160558'},
{title: "Top 10 Pokémon hệ độc nổi bật qua các thế hệ, Gengar vẫn được chú ý nhất",
description: "Ash đã gặp rất nhiều Pokémon mạnh trong chuyến du hành của mình, bao gồm cả những loài hệ độc.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-1629002882487880472230.jpg',
alt: "BULBASAUR,GENGAR,POKÉMON,POKÉMON HỆ ĐỘC,CÁC LOÀI POKÉMON,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/top-10-pokemon-he-doc-noi-bat-qua-cac-the-he-gengar-van-duoc-chu-y-nhat',
component: 'page_20210815160531'},
{title: "Top 4 vấn đề gây tranh cãi nhiều nhất trong One Piece, nơi tôn vinh những \"thánh cào phím\" và những lần \"gây war\" không ngớt",
description: "Cho đến nay khi một trong bốn vấn đề sau đây trong One Piece được đưa ra bàn luận thì nó luôn tạo ra những cuộc tranh luận không có hồi kết. Cùng chúng tôi điểm qua các vấn đề này nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/14/yp1-16289265719271956823559.png',
alt: "ONE PIECE,FAN ONE PIECE,TRANH CÃI ONE PIECE,CỘNG ĐỒNG ONE PIECE,TỨ HOÀNG ONE PIECE,ĐÔ ĐỐC ONE PIECE,ARC WANO,TỨ HOÀNG KAIDO,TỨ HOÀNG BIG MOM,PHƯỢNG HOÀNG MARCO,EIICHIRO ODA,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/top-4-van-de-gay-tranh-cai-nhieu-nhat-trong-one-piece-noi-ton-vinh-nhung-thanh-cao-phim-va-nhung-lan-gay-war-khong-ngot',
component: 'page_20210815160521'},
{title: "Những trò chơi khiến bạn \"chết nhiều hơn sống\" nhưng cuốn thôi rồi",
description: "Các tựa game này có độ thử thách khá cao và đòi hỏi người chơi phải nắm được quy tắc thì mới có thể sống sót.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/14/photo-1-16289586805401838925611.png',
alt: "GAME,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/nhung-tro-choi-khien-ban-chet-nhieu-hon-song-nhung-cuon-thoi-roi',
component: 'page_20210815160510'},
{title: "Những khung cảnh quen thuộc của game thủ Việt thời còn chưa có Internet, nhìn là thấy cả bầu trời kỷ niệm (p2)",
description: "Những hình ảnh đáng quý gợi về không biết bao nhiêu cảm xúc cho thế hệ các game thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/10/photo-1-1628566717051642727140.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,",
category: 'games',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/nhung-khung-canh-quen-thuoc-cua-game-thu-viet-thoi-con-chua-co-internet-nhin-la-thay-ca-bau-troi-ky-niem-p2',
component: 'page_20210814114051'},
{title: "Nếu mê thể loại hành động thì đây là 10 manga chiến đấu hấp dẫn nhất định nên xem",
description: "Nếu bạn muốn tìm cảm giác mạnh trong những bộ manga chiến đấu, thì 10 cái tên dưới đây sẽ giúp bạn thỏa mãn.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/14/photo-1-16289300476001649657496.jpg',
alt: "DRAGON BALL,MANGA HÀNH ĐỘNG,MANGA CHIẾN ĐẤU,KENGAN ASURA,FLAME OF RECCA,BAKI,",
category: 'games',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/neu-me-the-loai-hanh-dong-thi-day-la-10-manga-chien-dau-hap-dan-nhat-dinh-nen-xem',
component: 'page_20210814114042'},
{title: "Nữ phản diện trong Kamen Rider Revice khiến fan ngây ngất vì nhan sắc trời cho",
description: "Tuy Kamen Rider Revice vẫn chưa ra mắt nhưng nữ diễn viên Yui Asakura đã khiến cho nhiều fan đứng ngồi không yên nhờ nhan sắc trời cho của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/hoi1-16288340857632120564090.jpg',
alt: "BỘ ẢNH COSPLAY,COSPLAY,KAMEN RIDER REVICE,KAMEN RIDER,CHÙM ẢNH COSPLAY,NỮ COSER,",
category: 'games',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/nu-phan-dien-trong-kamen-rider-revice-khien-fan-ngay-ngat-vi-nhan-sac-troi-cho',
component: 'page_20210813092931'},
{title: "Dragon Ball Super: Đều thuộc cấp độ Thần, thế nhưng Ultra Instinct hay Hakai kỹ thuật nào mạnh hơn?",
description: "Trước đó trong chapter 71 của Dragon Ball Super chúng ta đã thấy Beerus và Whis đang cạnh tranh với nhau, nhằm chứng minh Ultra Instinct hay Hakai mạnh hơn. Và đến bây giờ chúng ta tiếp tục thấy cuộc chiến giữa Goku và Vegeta cũng về vấn đề này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/anh-1-16287495909931430819453.jpg',
alt: "DRAGON BALL SUPER,GOKU VS VEGETA,ULTRA INSTINCT,BẢN NĂNG VÔ CỰC,HAKAI,THẦN HỦY DIỆT,THIÊN SỨ,",
category: 'games',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/dragon-ball-super-deu-thuoc-cap-do-than-the-nhung-ultra-instinct-hay-hakai-ky-thuat-nao-manh-hon',
component: 'page_20210813092908'},
{title: "Những nhân vật Anime có khả năng kháng độc tuyệt diệu, có người còn coi độc là thức ăn luôn",
description: "Dù cho trúng phải loại độc gì thì những nhân vật anime này cũng không gục ngã.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-1628823377261838656799.jpg',
alt: "ANIME/MANGA,KHẢ NĂNG MIỄN ĐỘC,NHÂN VẬT ANIME KHÁNG ĐỘC,KHẢ NĂNG KHÁNG ĐỘC,CHẤT ĐỘC TRONG ANIME,",
category: 'games',
time: '09:28 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:28 AM',
link: '/nhung-nhan-vat-anime-co-kha-nang-khang-doc-tuyet-dieu-co-nguoi-con-coi-doc-la-thuc-an-luon',
component: 'page_20210813092840'},
{title: "Cùng nhìn lại lịch sử phát triển của làng game Việt qua các mốc thời gian - Mở đầu cho trào lưu auto trong game, Kiếm Thế có \"công hay tội\"?",
description: "Kiếm Thế có thể coi là tựa game đi tiên phong cho trào lưu game \"mì ăn liền\" sau này ở Việt Nam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-1628312214490603217886.jpg',
alt: "GAME,GAME THỦ,KIẾM THẾ,TIN TỨC GAME,MINH CHÂU TAM QUỐC,",
category: 'games',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/cung-nhin-lai-lich-su-phat-trien-cua-lang-game-viet-qua-cac-moc-thoi-gian-mo-dau-cho-trao-luu-auto-trong-game-kiem-the-co-cong-hay-toi',
component: 'page_20210813092549'},
{title: "One Piece: \"Ác quỷ\" thức tỉnh bên trong Nico Robin chính là khả năng nguy hiểm nhất của băng Mũ Rơm?",
description: "Hình thức mới của Nico Robin trong chapter 1021 thực sự khiến các fan vô cùng phấn khích. Nhiều người cho rằng đây chính là khả năng thức tỉnh nguy hiểm nhất trong băng Mũ Rơm.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/anh-5-1628752426219749165279.jpg',
alt: "ONE PIECE,TRÁI ÁC QUỶ,NICO ROBIN,THỨC TỈNH TRÁI ÁC QUỶ,BLACK MARIA,TOBI ROPPO,",
category: 'games',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/one-piece-ac-quy-thuc-tinh-ben-trong-nico-robin-chinh-la-kha-nang-nguy-hiem-nhat-cua-bang-mu-rom',
component: 'page_20210813092517'},
{title: "Top 5 nhóc tỳ đáng yêu nhất manga, chưa ai vượt qua nổi \"Shin bút chì\" với những câu chuyện cực bựa",
description: "Phải công nhận là em bé và con nít trong thế giới anime/manga thật dễ thương vô cùng. Mắt to tròn lấp lánh, hành động ngây ngô, đôi khi việc tỏ ra già trước tuổi của những đứa nhóc này thật \"đốn tim\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/anh-5-1628667756398797204709.png',
alt: "ANIME NHẬT BẢN,NHÓC TỲ,NHÂN VẬT ANIME,SHIN CẬU BÉ BÚT CHÌ,CHIE CÔ BÉ HẠT TIÊU,NHÓC MARUKO,",
category: 'games',
time: '09:23 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:23 AM',
link: '/top-5-nhoc-ty-dang-yeu-nhat-manga-chua-ai-vuot-qua-noi-shin-but-chi-voi-nhung-cau-chuyen-cuc-bua',
component: 'page_20210813092324'},
{title: "Top 10 tựa game chi phí phát triển không bao nhiêu nhưng gom tiền nhiều như bom tấn (P.2)",
description: "Những siêu phẩm làng game không nhất tiền phải đầu tư tiền tỷ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628711658851477207572.jpg',
alt: "GAME,GAME HAY,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,GAME ĐỈNH,",
category: 'games',
time: '09:22 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:22 AM',
link: '/top-10-tua-game-chi-phi-phat-trien-khong-bao-nhieu-nhung-gom-tien-nhieu-nhu-bom-tan-p2',
component: 'page_20210813092207'},
{title: "Dragon Ball Super: Không phải Zeno, đây mới là người quyền lực đứng đằng sau thống trị đa vũ trụ?",
description: "Trong Dragon Ball Super, thì có vẻ như Zeno không phải là người nắm giữ quyền lực thực sự.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/anh-1-162866537721938394384.jpeg',
alt: "DRAGON BALL SUPER,THẦN HỦY DIỆT,ĐA VŨ TRỤ,KING ZENO,GRAND PRIEST,THIÊN SỨ,",
category: 'games',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/dragon-ball-super-khong-phai-zeno-day-moi-la-nguoi-quyen-luc-dung-dang-sau-thong-tri-da-vu-tru',
component: 'page_20210813092150'},
{title: "Cùng nhìn lại lịch sử phát triển của làng game Việt qua các mốc thời gian: 2005-2009, thời kỳ game online cực thịnh nhưng đi kèm với nhiều định kiến (p2)",
description: "2005 - 2009 có thể coi là quãng thời gian phát triển mạnh mẽ nhất của làng game Việt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/photo-1-16282477392111513328995.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,AUDITION,CAO BỒI KHÔNG GIAN,VÕ LÂM TRUYỀN KỲ,MU ONLINE,PRISON TALE,",
category: 'games',
time: '09:20 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:20 AM',
link: '/cung-nhin-lai-lich-su-phat-trien-cua-lang-game-viet-qua-cac-moc-thoi-gian-2005-2009-thoi-ky-game-online-cuc-thinh-nhung-di-kem-voi-nhieu-dinh-kien-p2',
component: 'page_20210813092031'},
{title: "Tựa game Call of Duty tiếp theo sẽ trở lại với bối cảnh thế chiến thứ hai",
description: "Theo những thông tin bị lộ mới nhất, Activision sẽ một lần nữa đem series game đình đám trở lại thế chiến thứ hai.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-16287848600011503657039.jpg',
alt: "CALL OF DUTY,THẾ CHIẾN THỨ HAI,THÁI BÌNH DƯƠNG,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/tua-game-call-of-duty-tiep-theo-se-tro-lai-voi-boi-canh-the-chien-thu-hai',
component: 'page_20210812173339'},
{title: "Điện tử 4 nút, điện tử xèng và những chiếc máy gợi nhớ về tuổi thơ không thể quên của các game thủ",
description: "Những chiếc máy này chắc chắn đã là một phần tuổi thơ không thể xóa nhòa đối với các game thủ thế hệ 8-9x.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/photo-1-1628488000334741755679.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/dien-tu-4-nut-dien-tu-xeng-va-nhung-chiec-may-goi-nho-ve-tuoi-tho-khong-the-quen-cua-cac-game-thu',
component: 'page_20210812173329'},
{title: "10 game co-op cho bạn làm trùm, có thể \"đồ sát\" tất cả bạn bè cùng chơi (P2)",
description: "Đây đều là những game co-op rất hay mà bạn không nên bỏ qua.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/photo-1-16287855378722059225818.jpg',
alt: "VIRUS NGUY HIỂM,KHU VỰC NGUY HIỂM,VIRUS CHẾT NGƯỜI,GAME HÀNH ĐỘNG,GAME CO-OP,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/10-game-co-op-cho-ban-lam-trum-co-the-do-sat-tat-ca-ban-be-cung-choi-p2',
component: 'page_20210812173310'},
{title: "Dragon Ball Super: Mãn nhãn ngắm \"from hủy diệt\" của Vegeta, hoàng tử không lông mày nhưng vẫn ngầu bá cháy",
description: "Fan Vegeta cuối cùng cũng đợi được đến ngày \"anh Rau\" tỏa sáng rồi!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-162878158453327091717.jpg',
alt: "DRAGON BALL SUPER,DRAGON BALL,VEGETA,SUPER SAIYAN,VEGETA FROM HỦY DIỆT,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/dragon-ball-super-man-nhan-ngam-from-huy-diet-cua-vegeta-hoang-tu-khong-long-may-nhung-van-ngau-ba-chay',
component: 'page_20210812173301'},
{title: "Top 4 bóng hồng \"ngoài lạnh trong nóng\" trong anime: Vẻ ngoài lạnh như băng nhưng vẫn khiến fan \"tan chảy\", đổ ầm ầm",
description: "Đây chính là 4 gương mặt khiến các khán giả hâm mộ anime phải đổ gục vì vẻ ngoài lạnh lùng cùng tính cách trái ngược của mình",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628767919213968256515.jpg',
alt: "GAME MOBILE,GAME ONLINE,GAME DI ĐỘNG,ANIME,KHUYỂN DẠ XOA TRUYỀN KỲ - IP INUYASHA,",
category: 'games',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/top-4-bong-hong-ngoai-lanh-trong-nong-trong-anime-ve-ngoai-lanh-nhu-bang-nhung-van-khien-fan-tan-chay-do-am-am',
component: 'page_20210812173242'},
{title: "Top 10 tựa game chi phí phát triển không bao nhiêu nhưng gom tiền nhiều như bom tấn",
description: "Những siêu phẩm làng game không nhất tiền phải đầu tư tiền tỷ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628619405076633259899.jpg',
alt: "GAME,SIÊU PHẨM,KINH PHÍ THẤP,CỘNG ĐỒNG MẠNG,BOM TẤN,TIN TỨC GAME,",
category: 'games',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/top-10-tua-game-chi-phi-phat-trien-khong-bao-nhieu-nhung-gom-tien-nhieu-nhu-bom-tan',
component: 'page_20210811105730'},
{title: "Dragon Ball Super: Cưới được cô vợ giàu có, vậy Gohan đã làm công việc gì để nuôi sống gia đình?",
description: "Không giống như ông bố Goku phải đi làm nông dân để nuôi sống gia đình, thì Gohan dường như nhàn nhã hơn nhiều.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/10/anh-1-16285785706161476972933.jpg',
alt: "DRAGON BALL,SON GOKU,7 VIÊN NGỌC RỒNG,SON GOHAN,SATAN,CÔNG VIỆC CỦA GOHAN,",
category: 'games',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/dragon-ball-super-cuoi-duoc-co-vo-giau-co-vay-gohan-da-lam-cong-viec-gi-de-nuoi-song-gia-dinh',
component: 'page_20210811105706'},
{title: "300475 hé lộ 5 trận đánh, chiến dịch siêu hùng tráng",
description: "300475 đang trong quá trình phát triển.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/10/photo-1-16285889554181442125226.jpg',
alt: "DỰ ÁN GAME,GAME THUẦN VIỆT,300475,CHIẾN DỊCH HỒ CHÍ MINH,ĐIỆN BIÊN PHỦ TRÊN KHÔNG,GAME LỊCH SỬ,KHÁNG CHIẾN CHỐNG MỸ,",
category: 'games',
time: '10:56 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:56 AM',
link: '/300475-he-lo-5-tran-danh-chien-dich-sieu-hung-trang',
component: 'page_20210811105653'},
{title: "Những điều thú vị xoay quanh Team Rocket, những kẻ tấu hài tội nghiệp trong thế giới Pokémon",
description: "Vai trò của James, Jessie và Meowth thường là tấu hài chứ ít khi làm được trò trống gì.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/photo-1-1628560536284410144980.png',
alt: "POKÉMON,TEAM ROCKET,",
category: 'games',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/nhung-dieu-thu-vi-xoay-quanh-team-rocket-nhung-ke-tau-hai-toi-nghiep-trong-the-gioi-pokemon',
component: 'page_20210811105549'},
{title: "Top 5 cô nàng anime xinh đẹp với khả năng y tế tuyệt vời, đã thế còn có trái tim thiện lương",
description: "Hầu hết họ đều sở hữu khả năng y tế và chữa bệnh tuyệt vời.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/9/anh-1-16284980747111263394186.jpg',
alt: "ANIME,NHÂN VẬT ANIME,NARUTO,ONE PIECE,FAIRY TAIL,KHẢ NĂNG Y TẾ,",
category: 'games',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/top-5-co-nang-anime-xinh-dep-voi-kha-nang-y-te-tuyet-voi-da-the-con-co-trai-tim-thien-luong',
component: 'page_20210811105539'},
{title: "Cùng nhìn lại lịch sử phát triển của làng game Việt qua các mốc thời gian - Gunbound chứ không phải MU mới là tựa game online đầu tiên? (p1)",
description: "Đây cũng là tựa game mà chẳng nhiều người nghĩ tới đã vô tình \"hủy hoại\" làng game Việt như thế đấy.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/photo-1-1628245014196765231890.jpg',
alt: "GAME,GAME THỦ,MU ONLINE,TIN TỨC GAME,VLTK,GUNBOUND,TS ONLINE,",
category: 'games',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/cung-nhin-lai-lich-su-phat-trien-cua-lang-game-viet-qua-cac-moc-thoi-gian-gunbound-chu-khong-phai-mu-moi-la-tua-game-online-dau-tien-p1',
component: 'page_20210811105527'},
{title: "Game thủ tái hiện Thành phố Hồ Chí Minh tươi đẹp trong tựa game Minecraft",
description: "Một tác phẩm để đời của game thủ Minecraft Việt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/photo-1-1628618708382440378325.jpg',
alt: "Thành phố Hồ Chí Minh,Hồ Chí Minh,game,mine craft,",
category: 'games',
time: '10:52 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:52 AM',
link: '/game-thu-tai-hien-thanh-pho-ho-chi-minh-tuoi-dep-trong-tua-game-minecraft',
component: 'page_20210811105208'},
{title: "Dragon Ball Super: Dù Bản năng Vô cực mạnh thế nào thì vẫn không thể thay thế tuyệt chiêu này của Goku",
description: "Mặc dù Dragon Ball liên tục có các chiêu thức mới, nhưng chiêu thức đặc trưng của Goku vẫn là Kamehameha.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/anh-1-1628492049545263907366.jpg',
alt: "DRAGON BALL SUPER,BẢN NĂNG VÔ CỰC,SON GOKU,KAMEHAMEHA,",
category: 'games',
time: '10:55 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:55 AM',
link: '/dragon-ball-super-du-ban-nang-vo-cuc-manh-the-nao-thi-van-khong-the-thay-the-tuyet-chieu-nay-cua-goku',
component: 'page_20210809105544'},
{title: "Những tựa game với cái kết dang dở khiến người chơi cảm thấy tức muốn chết",
description: "Kết không đẹp thì thôi, nhưng kết kiểu khó chịu thế này thì đúng là bực mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/8/photo-1-16284418824842061273504.jpg',
alt: "GAME,",
category: 'games',
time: '10:22 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:22 PM',
link: '/nhung-tua-game-voi-cai-ket-dang-do-khien-nguoi-choi-cam-thay-tuc-muon-chet',
component: 'page_20210808222221'},
{title: "Làng game Việt bấy lâu nay vẫn dậm chân tại chỗ chẳng thể phát triển, nguyên nhân vì đâu?",
description: "Vì đâu mà game Việt vẫn còn đang phải giậm chân tại chỗ với những dự án game mobile đơn giản mà chưa có nổi một cú hit?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-1628156685246444204171.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/lang-game-viet-bay-lau-nay-van-dam-chan-tai-cho-chang-the-phat-trien-nguyen-nhan-vi-dau',
component: 'page_20210808110255'},
{title: "Những nghịch lý ngược đời, bị hiểu sai một cách đầy ngang trái về game trong Avengers và một loạt bộ phim khác (p2)",
description: "Game không phải như trong phim, cứ bấm loạn nút lên là thắng đâu nhé anh em.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/8/screenshot6-16284162234731391199138.png',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/nhung-nghich-ly-nguoc-doi-bi-hieu-sai-mot-cach-day-ngang-trai-ve-game-trong-avengers-va-mot-loat-bo-phim-khac-p2',
component: 'page_20210808110243'},
{title: "Dragon Ball: Hồi sinh cả vũ trụ nhưng Goku và Vegeta lại chưa từng ước chủng tộc Saiyan quay trở lại, lý do vì đâu?",
description: "Nhờ sức mạnh của Rồng thần không ít lần một người chết hay một hành tinh nào đó được hồi sinh quay trở lại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/8/anh-1-16284058255101769228073.jpg',
alt: "DRAGON BALL,7 VIÊN NGỌC RỒNG,RỒNG THẦN,HÀNH TINH SAIYAN,GOKU,VEGETA,HỒI SINH NGƯỜI CHẾT,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/dragon-ball-hoi-sinh-ca-vu-tru-nhung-goku-va-vegeta-lai-chua-tung-uoc-chung-toc-saiyan-quay-tro-lai-ly-do-vi-dau',
component: 'page_20210808110234'},
{title: "[Review] My Time at Portia phiên bản di động: Khi cả thế giới nằm trong tầm tay bạn!",
description: "Trò chơi My Time at Portia đã được ra mắt trên thiết bị di động vào ngày 4 tháng 8 vừa qua.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-162832600068850224543.jpg',
alt: "MY TIME AT PORTIA,REVIEW,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/review-my-time-at-portia-phien-ban-di-dong-khi-ca-the-gioi-nam-trong-tam-tay-ban',
component: 'page_20210808110211'},
{title: "Top 5 game open world có đồ họa đỉnh của chóp, theo bình chọn của game thủ",
description: "Có nhiều tựa game được khen ngợi về đồ họa, nhưng 5 tựa game này có lẽ là kinh điển nhất trong thể loại open world",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/5-800x450-16282376254711350454925.jpg',
alt: "GAME,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/top-5-game-open-world-co-do-hoa-dinh-cua-chop-theo-binh-chon-cua-game-thu',
component: 'page_20210808110202'},
{title: "Top 5 anime cực hấp dẫn khi có những cô nàng nóng bỏng và hot nhất mùa hè 2021",
description: "Các anime hấp dẫn người xem không chỉ bởi nội dung mà nhân vật nữ trong phim cũng là một yếu tố quyết định dẫn đến thành công của bộ phim.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/nh-1-1628321240615429169804.jpg',
alt: "ANIME HÈ 2021,NHÂN VẬT ANIME,NỮ CHÍNH ANIME,TOP ANIME HẤP DẪN,",
category: 'games',
time: '11:01 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:01 AM',
link: '/top-5-anime-cuc-hap-dan-khi-co-nhung-co-nang-nong-bong-va-hot-nhat-mua-he-2021',
component: 'page_20210808110150'},
{title: "Sau khi bật chế độ thần thánh mới, Vegeta chính thức đánh bại Goku trở thành nhân vật được yêu thích nhất Dragon Ball Super",
description: "Trong chapter 74 của Dragon Ball Super chúng ta đã được chứng kiến màn tỏa sáng của Vegeta trước trận đấu với Granolah. Sau khi thấy được sự biến đổi thần thánh mới, một cuộc thăm dò của Viz Media xác nhận rằng Vegeta là nhân vật được yêu thích nhất trong Dragon Ball Super.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/nh-1-16283235562581341148953.jpg',
alt: "DRAGON BALL SUPER,VEGETA,GOKU,THẦN HỦY DIỆT,BẢN NĂNG VÔ CỰC,GOD KI,",
category: 'games',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/sau-khi-bat-che-do-than-thanh-moi-vegeta-chinh-thuc-danh-bai-goku-tro-thanh-nhan-vat-duoc-yeu-thich-nhat-dragon-ball-super',
component: 'page_20210807154628'},
{title: "Những nghịch lý ngược đời, bị hiểu sai một cách đầy ngang trái về game trong Avengers và một loạt bộ phim khác (p1)",
description: "Hai người cùng chơi một tựa game single-player, điều chỉ có thể xuất hiện trên phim.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/screenshot27-1628329462687161582959.png',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/nhung-nghich-ly-nguoc-doi-bi-hieu-sai-mot-cach-day-ngang-trai-ve-game-trong-avengers-va-mot-loat-bo-phim-khac-p1',
component: 'page_20210807154619'},
{title: "8 game có cốt truyện sinh ra để \"lừa\" game thủ (P2)",
description: "Những tựa game này đều có cốt truyện \"hack não\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-1628331883029848668213.jpg',
alt: "LIFE IS STRANGE,CỐT TRUYỆN,GAME CỐT TRUYỆN,ASSASSIN’S CREED 3,",
category: 'games',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/8-game-co-cot-truyen-sinh-ra-de-lua-game-thu-p2',
component: 'page_20210807154600'},
{title: "Những khó khăn mà nhân vật chính trong isekai anime thường gặp phải (P.2)",
description: "Nhân vật chính trong isekai anime có nhiều lợi thế và đặc quyền, nhưng chắc chắn họ cũng gặp không ít khó khăn",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/sword-art-online-war-of-underworld-asuna-1628233175359273668004.jpg',
alt: "KHÁM PHÁ,ANIME,ISEKAI ANIME,",
category: 'games',
time: '03:45 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:45 PM',
link: '/nhung-kho-khan-ma-nhan-vat-chinh-trong-isekai-anime-thuong-gap-phai-p2',
component: 'page_20210807154549'},
{title: "Những bản mod phá game nhất mà game thủ Skyrim không nên cài đặt",
description: "Phần lớn người chơi Skyrim đều thống nhất rằng các bản mod này cực kỳ phá game",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/bgsdecember2020modslcard776x470-1628159608167711147384.jpg',
alt: "KHÁM PHÁ,GAME,OPEN WORLD GAME,SKYRIM,",
category: 'games',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/nhung-ban-mod-pha-game-nhat-ma-game-thu-skyrim-khong-nen-cai-dat',
component: 'page_20210807154455'},
{title: "Dragon Ball: Dù sở hữu sức mạnh khủng nhưng 4 nhân vật này chưa từng chiến thắng ở bất kỳ cuộc chiến nào",
description: "Thật đáng ngạc nhiên khi có một số nhân vật chính trong Dragon Ball lại không có một chiến thắng nào xuyên suốt bộ truyện.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/anh-1-16282352607131499219046.gif',
alt: "DRAGON BALL,NHÂN VẬT DRAGON BALL,SON GOKU,7 VIÊN NGỌC RỒNG,",
category: 'games',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/dragon-ball-du-so-huu-suc-manh-khung-nhung-4-nhan-vat-nay-chua-tung-chien-thang-o-bat-ky-cuoc-chien-nao',
component: 'page_20210807154444'},
{title: "Những trò chơi tìm lại được thành công nhờ game thủ \"mách nước\"",
description: "Nghe lời góp ý từ các fan hâm mộ, những tựa game này dần dần tìm lại được thành công và được yêu thích.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/photo-1-1628259338797394301209.jpg',
alt: "GAME,",
category: 'games',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/nhung-tro-choi-tim-lai-duoc-thanh-cong-nho-game-thu-mach-nuoc',
component: 'page_20210806162439'},
{title: "Nếu cứ lạm dụng Haki liệu những trận chiến trong anime One Piece có đi theo \"vết xe đổ\" của Dragon Ball hay không?",
description: "Thời gian gần đây nhiều khán giả hâm mộ anime One Piece phàn nàn về việc Haki đang được các nhà làm phim khai thác quá đà.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/hai1-16282411964212135704424.jpg',
alt: "ONE PIECE,MANGA ONE PIECE,ANIME ONE PIECE,RORONOA ZORO,LUFFY VS LUCCI,EIICHIRO ODA,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,NHỮNG NGƯỜI SỞ HỮU HAKI BÁ VƯƠNG,TỨ HOÀNG KAIDO,TỨ HOÀNG BIG MOM,KAIDO VÀ BIG MOM,",
category: 'games',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/neu-cu-lam-dung-haki-lieu-nhung-tran-chien-trong-anime-one-piece-co-di-theo-vet-xe-do-cua-dragon-ball-hay-khong',
component: 'page_20210806162430'},
{title: "Toxic, hack, không dùng tiếng Anh và những lý do khiến game thủ Việt thường xuyên phải chịu những điều tiếng tại các tựa game global",
description: "Không phải ngẫu nhiên mà game thủ Việt thường để lại ấn tượng không quá tích cực với bạn bè thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280537716561210499790.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,",
category: 'games',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/toxic-hack-khong-dung-tieng-anh-va-nhung-ly-do-khien-game-thu-viet-thuong-xuyen-phai-chiu-nhung-dieu-tieng-tai-cac-tua-game-global',
component: 'page_20210806162421'},
{title: "Thích PK nhưng lại lên án việc \"đập tiền\" và những nghịch lý đầy thượng đẳng tồn tại bấy lâu nay của game thủ Việt",
description: "Thế mới thấy các game thủ Việt thượng đẳng như thế nào.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-16278822524001697710181.jpg',
alt: "GAME,TIN TỨC GAME,GAME THỦ,",
category: 'games',
time: '09:39 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:39 AM',
link: '/thich-pk-nhung-lai-len-an-viec-dap-tien-choi-game-va-nhung-nghich-ly-day-thuong-dang-ton-tai-bay-lau-nay-cua-cac-game-thu-viet',
component: 'page_20210806093912'},
{title: "Thử ngay tựa game Business Tour - tựa game Monopoly hot nhất tuần qua được Độ Mixi và đồng bọn yêu thích",
description: "Thể loại cờ tỷ phú đã không còn quá xa lạ đối với anh em game thủ từ trước tới nay. Tuy vậy, Business Tour vẫn mang tới một sức hút mạnh mẽ khi kết nối được bạn bè để cùng vạch ra những chiến lược tư duy đỉnh cao.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/-16282395595731630100420.jpg',
alt: "BUSINESS TOUR,",
category: 'games',
time: '09:39 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:39 AM',
link: '/thu-ngay-tua-game-business-tour-tua-game-monopoly-hot-nhat-tuan-qua-duoc-do-mixi-va-dong-bon-yeu-thich',
component: 'page_20210806093904'},
{title: "Top 4 trận đấu tay không mãn nhãn nhất trong thế giới anime, hấp dẫn còn hơn cả Dragon Ball",
description: "Bốn trận đấu tay không sau đây sẽ mang lại cho các bạn những cảm xúc chân thật hơn trong các bộ anime.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/hl1-1628233738698725479211.gif',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '09:38 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:38 AM',
link: '/top-4-tran-dau-tay-khong-man-nhan-nhat-trong-the-gioi-anime-hap-dan-con-hon-ca-dragon-ball',
component: 'page_20210806093855'},
{title: "4 nhân vật bị fan One Piece nghi ngờ là hậu duệ của Rocks D. Xebec",
description: "Có giả thuyết cho rằng Shanks chính là hậu duệ của Rocks D. Xebec.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/photo-1-162824203958082222118.jpg',
alt: "ONE PIECE,GOL D. ROGER,HẬU DUỆ CỦA ROCKS D. XEBEC,SHANKS,BUGGY,MARSHALL D. TEACH,",
category: 'games',
time: '09:38 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:38 AM',
link: '/4-nhan-vat-bi-fan-one-piece-nghi-ngo-la-hau-due-cua-rocks-d-xebec',
component: 'page_20210806093846'},
{title: "8 game có cốt truyện sinh ra để \"lừa\" game thủ (P1)",
description: "Những tựa game này có cốt truyện hết sức \"hại não\" và game thủ sẽ bị lừa nhiều lần khi chơi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/photo-1-16282515535541214055067.png',
alt: "DOKI DOKI LITERATURE CLUB,HEAVY RAIN,FARCRY 3,CONTRA,",
category: 'games',
time: '09:38 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:38 AM',
link: '/8-game-co-cot-truyen-sinh-ra-de-lua-game-thu-p1',
component: 'page_20210806093837'},
{title: "Dragon Ball Super: Những sự thật đáng kinh ngạc về trạng thái Super Saiyan God mà không phải ai cũng biết",
description: "Super Saiyan God là 1 trạng thái cực mạnh xuất hiện trong Dragon Ball Super.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/abg-1-16281455824961943368232.jpg',
alt: "DRAGON BALL,SUPER SAIYAN GOD,THẦN HỦY DIỆT,HÀNH TINH VEGETA,7 VIÊN NGỌC RỒNG,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/dragon-ball-super-nhung-su-that-dang-kinh-ngac-ve-trang-thai-super-saiyan-god-ma-khong-phai-ai-cung-biet',
component: 'page_20210806093748'},
{title: "Những khó khăn mà nhân vật chính trong isekai anime thường gặp phải (P.1)",
description: "Không phải lúc nào các nhân vật chính trong isekai anime cũng có cuộc sống suôn sẻ sau khi chuyển sinh",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/azusa-child-form-with-pills-1628152715274674158216.jpg',
alt: "ISEKAI,MANGA ANIME,ANIME,ISEKAI ANIME,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/nhung-kho-khan-ma-nhan-vat-chinh-trong-isekai-anime-thuong-gap-phai-p1',
component: 'page_20210806093740'},
{title: "Nếu kéo về \"late game\" liệu Broly có thể đánh bại Jiren nếu 2 người đụng độ trong Dragon Ball Super?",
description: "Đó là câu hỏi mà nhiều fan của Dragon Ball Super quan tâm khi mà Broly và Jiren đều là những chiến binh rất mạnh.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/ku2-1628161887430142217655.jpg',
alt: "ANIME DRAGON BALL SUPER,MANGA DRAGON BALL SUPER,MOVIE DRAGON BALL SUPER: BROLY,MANGA DRAGON BALL SUPER,SON GOKU,HỢP THỂ GOGETA,ANIME DRAGON BALL SUPER: BROLY,GOKU VS JIREN,JIREN VS BROLY,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/neu-keo-ve-late-game-lieu-broly-co-the-danh-bai-jiren-neu-2-nguoi-dung-do-trong-dragon-ball-super',
component: 'page_20210806093715'},
{title: "Loạt game được đánh giá cao vì \"fame to\" rồi không đạt tới kỳ vọng",
description: "Nhận được nhiều lời khen cũng không phải điều gì quá tích cực đối với các nhà phát triển.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-1628171339462307298922.jpg',
alt: "GAME,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/loat-game-duoc-danh-gia-cao-vi-fame-to-roi-khong-dat-toi-ky-vong',
component: 'page_20210806093707'},
{title: "Làn sóng game NFT nở rộ, thời của người chơi hệ \"cày cuốc\" ở Việt Nam đã tới nhưng liệu lúc này game có còn để vui?",
description: "Các tựa game NFT đa số đều chỉ phục vụ mục đích kiếm tiền của các game thủ thay vì tận hưởng niềm vui như trước.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/29/photo-1-16275550158302101498565.png',
alt: "TIN TỨC GAME,GAME THỦ,GAME,GAME NFT,",
category: 'games',
time: '08:49 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:49 AM',
link: '/lan-song-game-nft-no-ro-thoi-cua-nguoi-choi-he-cay-cuoc-o-viet-nam-da-toi-nhung-lieu-luc-nay-game-co-con-de-vui',
component: 'page_20210805084922'},
{title: "Giật mình khi thấy dàn gái xinh Ghibli đều béo ú sau thời gian dài ở nhà chống dịch, rồi ai cũng sẽ bị \"ngải heo\" quật thôi",
description: "Các fan hâm mộ truyện tranh luôn là những người cực kỳ sáng tạo và đôi khi các nhân vật hoạt hình nổi tiếng được họ \"chế\" lại thành bộ dạng cực \"dị\" mà bạn khó có thể tưởng tượng được.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/photo-1-162813241659122418386.jpg',
alt: "OẠT HÌNH GHIBLI,NHÂN VẬT GHIBLI,NHÂN VẬT GHIBLI BÉO Ú,CHIHIRO,",
category: 'games',
time: '08:48 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:48 AM',
link: '/giat-minh-khi-thay-dan-gai-xinh-ghibli-deu-beo-u-sau-thoi-gian-dai-o-nha-chong-dich-roi-ai-cung-se-bi-ngai-heo-quat-thoi',
component: 'page_20210805084816'},
{title: "Battlefield 2042 lộ cấu hình chính thức, CPU Core i7 4790, 16GB RAM và RTX 2060",
description: "Cấu hình Battlefield 2042 khá nặng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-16281361355451053647608.jpg',
alt: "LỘ CẤU HÌNH,CHIẾN DỊCH QUẢNG BÁ,BATTLEFIELD 2042,CARD ĐỒ HỌA,CẤU HÌNH TỐI THIỂU,GAME THỦ VIỆT,",
category: 'games',
time: '08:48 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:48 AM',
link: '/battlefield-2042-lo-cau-hinh-chinh-thuc-cpu-core-i7-4790-16gb-ram-va-rtx-2060',
component: 'page_20210805084806'},
{title: "So sánh sức mạnh của Super Saiyan 4 Gogeta và Super Saiyan Blue Gogeta, 2 \"lưỡng long nhất thể\" mạnh nhất thế giới Dragon Ball",
description: "Liệu sự hợp nhất nào của Gogeta là đỉnh cao của cuộc chiến trong thế giới Dragon Ball?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/anh-1-16280620138171675379246.png',
alt: "DRAGON BALL,7 VIÊN NGỌC RỒNG,HỢP THỂ GOGETA,SUPER SAIYAN 4,DRAGON BALL GT,DRAGON BALL SUPER,SUPER SAIYAN BLUE,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/so-sanh-suc-manh-cua-super-saiyan-4-gogeta-va-super-saiyan-blue-gogeta-2-luong-long-nhat-the-manh-nhat-the-gioi-dragon-ball',
component: 'page_20210805084759'},
{title: "Những điểm yếu ngớ ngẩn mà khán giả khó chấp nhận ở 10 phản diện anime đình đám (P.1)",
description: "Một số phản diện trong anime vô cùng tiềm năng, nhưng lại bị đánh bại theo cách không thể ngờ đến vì điểm yếu ngớ ngẩn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/princess-tutu-duck-as-a-duck-dancing-1628064431920805535622.jpg',
alt: "KHÁM PHÁ,MANGA,ANIME,HOẠT HÌNH NHẬT BẢN,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/nhung-diem-yeu-ngo-ngan-ma-khan-gia-kho-chap-nhan-o-10-phan-dien-anime-dinh-dam-p1',
component: 'page_20210805084750'},
{title: "Đừng \"nhìn mặt mà bắt hình dong\", 5 nhân vật anime này mạnh hơn rất nhiều so với vẻ ngoài \"ngáo ngơ\" của mình",
description: "Không giống như bề ngoài của mình, một số nhân vật trong anime sở hữu sức mạnh vô cùng kinh khủng khiến nhiều người phải e sợ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/anh-1-16280589606261873536026.jpg',
alt: "ANIME,NHÂN VẬT ANIME,DRAGON BALL,ONE PIECE,BLACK CLOVER,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/dung-nhin-mat-ma-bat-hinh-dong-5-nhan-vat-anime-nay-manh-hon-rat-nhieu-so-voi-ve-ngoai-ngao-ngo-cua-minh',
component: 'page_20210805084741'},
{title: "11 thông tin thú vị xung quanh Goku trong Dragon Ball: chưa bao giờ đánh bại Vegeta, cũng chẳng phải người mạnh nhất",
description: "Goku vẫn còn tiềm năng phát triển mạnh mẽ hơn trong tương lai.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-16281580913081068309699.jpg',
alt: "GOKU,DRAGON BALL,THÔNG TIN VỀ GOKU,BẢN NĂNG VÔ CỰC,KỸ THUẬT CỦA GOKU,TÊN THẬT CỦA GOKU,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/11-thong-tin-thu-vi-xung-quanh-goku-trong-dragon-ball-chua-bao-gio-danh-bai-vegeta-cung-chang-phai-nguoi-manh-nhat',
component: 'page_20210805084706'},
{title: "Xuất hiện thanh niên siêu nhọ, ngồi nhà chơi game mà vẫn bị sét đánh… qua tay cầm",
description: "Cày game đang hăng thì bị sét đánh trúng khiến cho thanh niên game thủ bị giật điện thông qua tay cầm, rất may là vẫn an toàn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-1628087795258881430866.jpg',
alt: "",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/xuat-hien-thanh-nien-sieu-nho-ngoi-nha-choi-game-ma-van-bi-set-danh-qua-tay-cam',
component: 'page_20210805084643'},
{title: "Tổng hợp những trái ác quỷ Zoan thần thoại đã xuất hiện trong One Piece, ai cũng bá đạo ngoại trừ người này?",
description: "Được xem là loại trái ác quỷ hiếm nhất, cùng chúng tôi điểm qua những người sở hữu sức mạnh đáng sợ trong One Piece này nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/ky1-1628082681430236186284.png',
alt: "ONE PIECE,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,TỨ HOÀNG KAIDO,PHƯỢNG HOÀNG MARCO,NĂNG LỰC ÁC QUỶ YAMATO,TRÁI ÁC QUỶ,ZOAN THẦN THOẠI,THỦY SƯ SENGOKU,",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/tong-hop-nhung-trai-ac-quy-zoan-than-thoai-da-xuat-hien-trong-one-piece-ai-cung-ba-dao-ngoai-tru-nguoi-nay',
component: 'page_20210805084633'},
{title: "Đấu Trường Chân Lý: 3 đội hình xoay quanh Yasuo ở mùa 5.5 mà fan của \"đấng\" không thể bỏ qua",
description: "Nếu game thủ Đấu Trường Chân Lý sở hữu Yasuo từ sớm thì có thể hướng tới 1 trong 3 đội hình dưới đây.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/ma-su-16280628718901700424253.jpg',
alt: "ĐẤU TRƯỜNG CHÂN LÝ,CẨM NANG ĐẤU TRƯỜNG CHÂN LÝ,RIOT GAMES,GAME,RIOT,TIN GAME,YASUO,LEE SIN,RIVEN,DRAVEN,RELL,JAX,KALISTA,",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/dau-truong-chan-ly-3-doi-hinh-xoay-quanh-yasuo-o-mua-55-ma-fan-cua-dang-khong-the-bo-qua',
component: 'page_20210805084622'},
{title: "Những tựa game còn chưa ra mắt đã biết là thất bại",
description: "Công bằng mà nói thì các tựa game này có chất lượng không đến nỗi tệ, thậm chí là khá tốt nhưng cuối cùng vẫn \"hỏng việc\" vì những sai lầm khác nhau.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280898648651601284701.jpg',
alt: "GAME,",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/nhung-tua-game-con-chua-ra-mat-da-biet-la-that-bai',
component: 'page_20210805084609'},
{title: "Top 6 nhân loại mạnh nhất trong Attack on Titan, Mikasa Ackerman thế mà chỉ đứng hạng 3",
description: "Top 3 của danh sách này đã bị thống trị bởi tộc nhân Ackerman.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280705862911029149739.jpg',
alt: "ATTACK ON TITAN,TITAN,ERWIN SMITH,GABI,MIKE ZACHARIAS,NHÂN LOẠI MẠNH NHẤT ATTACK ON TITAN,",
category: 'games',
time: '07:00 PM',
date: '04/08/2021',
timestamp: '04/08/2021 07:00 PM',
link: '/top-6-nhan-loai-manh-nhat-trong-attack-on-titan-mikasa-ackerman-the-ma-chi-dung-hang-3',
component: 'page_20210804190000'},
{title: "One Punch Man: Chỉ với 5 chiêu thức bình thường này mà Thánh Phồng Saitama trở nên \"vô đối\"",
description: "Ngoài kĩ năng tối thượng \"Đấm thường nghiêm túc\", anh hùng One Punch Man của chúng ta còn chiêu gì nữa? Cùng chúng tôi điểm qua những chiêu thức của Thánh Phồng Saitama nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/hp1-1628050012532853990835.jpg',
alt: "MANGA ONE PUNCH MAN,TÁC GIẢ ONE PUNCH MAN,MỸ NHÂN ONE PUNCH MAN,FAN ART ONE PUNCH MAN,ANIME ONE PUNCH MAN,FAN ONE PUNCH MAN,CỘNG ĐỒNG ONE PUNCH MAN,THÁNH PHỒNG SAITAMA,BÍ MẬT VỀ SỨC MẠNH CỦA SAITAMA,THẦY TRÒ SAITAMA VS GENOS,",
category: 'games',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/one-punch-man-chi-voi-5-chieu-thuc-binh-thuong-nay-ma-thanh-phong-saitama-tro-nen-vo-doi',
component: 'page_20210804185942'},
{title: "Pokémon: Câu chuyện đau lòng mà không phải ai cũng biết về quá khứ của Jessie, hot girl Team Rocket",
description: "Jessie của Team Rocket nổi tiếng với bản chất mưu mô và hay cạnh tranh, thế nhưng đằng sau đó là cô cũng là một người vô cùng đáng thương.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/anh-1-1627986987887728224467.jpg',
alt: "POKÉMON,TEAM ROCKET,JESSIE,PIKACHU,QUÁ KHỨ TEAM ROCKET,ANIME NHẬT BẢN,",
category: 'games',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/pokemon-cau-chuyen-dau-long-ma-khong-phai-ai-cung-biet-ve-qua-khu-cua-jessie-hot-girl-team-rocket',
component: 'page_20210804185900'},
{title: "Dragon Ball Super: Tại sao Whis và các thiên thần khác không bao giờ trực tiếp chiến đấu?",
description: "Là một trong những sinh vật mạnh mẽ nhất trong Dragon Ball Super, Whis có thể giải quyết mọi vấn đề mà các anh hùng của chúng ta từng gặp phải - vậy tại sao anh ấy lại không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280475963551265237932.jpeg',
alt: "WHIS,THIÊN SỨ,DRAGON BALL SUPER,THIÊN THẦN,THẦN HỦY DIỆT,",
category: 'games',
time: '06:58 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:58 PM',
link: '/dragon-ball-super-tai-sao-whis-va-cac-thien-than-khac-khong-bao-gio-truc-tiep-chien-dau',
component: 'page_20210804185848'},
{title: "VCS từ ngày \"ra ở riêng\", trở thành khu vực lớn: Gặt hái được gì ngoài hai chữ \"thụt lùi\"?",
description: "Việc trở thành một khu vực độc lập vào năm 2018 tưởng như sẽ là cột mốc mới cho sự phát triển của VCS...",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/-16280085373611997724014.jpg',
alt: "LIÊN MINH HUYỀN THOẠI,LMHT,GAM,VCS,GARENA,MSI,CKTG,GAME,TIN TỨC GAME,TIN GAME,TIN LMHT,TIN LOL,",
category: 'games',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/vcs-tu-ngay-ra-o-rieng-tro-thanh-khu-vuc-lon-gat-hai-duoc-gi-ngoai-hai-chu-thut-lui',
component: 'page_20210804185730'},
{title: "Những game hậu bản dở thậm chí dở hơn cả phần trước",
description: "Không phải lúc nào làm ra phần sau thì cũng ăn khách như phần trước được cả.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/photo-1-1628006300469786762825.jpg',
alt: "GAME,",
category: 'games',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/nhung-phan-hau-ban-do-den-muc-lam-hong-ca-danh-tieng-game-truoc',
component: 'page_20210804185722'},
{title: "Top 7 manga mới nhất của Shonen Jump được độc giả đánh giá cao, cái tên nào cũng \"bánh cuốn\" cả",
description: "Trong số rất nhiều tựa truyện đã được thêm vào năm 2021, đây là 7 bộ truyện tranh Shonen Jump mới hay nhất.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/nh-1-16278903561831983263743.jpg',
alt: "MANGA SHOUNEN,WEEKLY SHONEN JUMP,MANGA NHẬT BẢN,MANGA MỚI,",
category: 'games',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/top-7-manga-moi-nhat-cua-shonen-jump-duoc-doc-gia-danh-gia-cao-cai-ten-nao-cung-banh-cuon-ca',
component: 'page_20210803111342'},
{title: "One Piece: Là sự phụ của Zoro, liệu Mắt Diều Hâu có đủ sức để đánh bại Kaido?",
description: "Đây là câu hỏi mà nhiều fan One Piece thắc mắc khi mà Zoro đã có thể chém được Kaido thì với sức mạnh của mình liệu ông thầy Mắt Diều Hâu sẽ làm được gì Tứ Hoàng này hay không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/ha1-1627968691684392808561.jpg',
alt: "ONE PIECE,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,TRUYỆN ONE PIECE,TỨ HOÀNG KAIDO,ARC WANO,ZORO VÀ MIHAWK,DRACULE MIHAWK,MẮT DIỀU HÂU MIHAWK,RORONOA ZORO,HAKI BÁ VƯƠNG,TỨ HOÀNG SHANKS,",
category: 'games',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/one-piece-la-su-phu-cua-zoro-lieu-mat-dieu-hau-co-du-suc-de-danh-bai-kaido',
component: 'page_20210803111333'},
{title: "Khi \"tâm hồn nặng trĩu\" của nữ thần sắc đẹp trong Record Of Ragnarok trở thành vựa muối chế ảnh, tấm nào nhìn cũng sướng nhức mắt",
description: "Xem loạt ảnh chế theo nữ thần sắc đẹp trong Record Of Ragnarok dưới đây mà các fan đều phải tấm tắc khen quá sáng tạo.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/2/-162790902781713007764.jpg',
alt: "RECORD OF RAGNAROK,APHRODITE,NỮ THẦN SẮC ĐẸP,ẢNH CHẾ APHRODITE,MEME APHRODITE,",
category: 'games',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/khi-tam-hon-nang-triu-cua-nu-than-sac-dep-trong-record-of-ragnarok-tro-thanh-vua-muoi-che-anh-tam-nao-nhin-cung-suong-nhuc-mat',
component: 'page_20210803111324'},
{title: "Nở rộ các dự án chơi game kiếm tiền ăn theo Axie Infinity",
description: "Dù phần nhiều là dự án trên giấy, vô số đồng tiền ảo của các dự án game blockchain (hay game kiếm ra tiền) đã mọc lên như nấm hút hàng triệu USD của những nhà đầu tư mạo hiểm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/photo-1-16279711334191957670293.jpg',
alt: "AXIE INFINITY,GAME,NFT,",
category: 'games',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/no-ro-cac-du-an-choi-game-kiem-tien-an-theo-axie-infinity',
component: 'page_20210803111304'},
{title: "Bao năm qua, người chơi Counter-Strike 1.1 bị “lừa” bởi bí mật vũ khí trong game, đa số chẳng hề hay biết",
description: "Có một sự thật khó thú vị mà bao năm nay, game thủ Counter-Strike phiên bản 1.1 bị qua mặt mà không hề hay biết.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-1627922207809764016793.jpg',
alt: "COUNTER-STRIKE 1.1,CS:GO,COUNTER-STRIKE,COUNTER STRIKE,COUNTER STRIKE 1.1,TIN TỨC GAME,",
category: 'games',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/bao-nam-qua-nguoi-choi-counter-strike-11-bi-lua-boi-bi-mat-vu-khi-trong-game-da-so-chang-he-hay-biet',
component: 'page_20210803111232'},
{title: "Một game thủ khiến cộng đồng trầm trồ khi tự mình đưa tất cả tướng LMHT thành bài ma thuật Yu-Gi-Oh!",
description: "Game thủ LMHT đã phải tốn tới 3 năm để hoàn thành bộ sưu tập kể trên.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/photo-1-1627953614281910334369.jpg',
alt: "LIÊN MINH HUYỀN THOẠI,LMHT,RIOT GAMES,GAME,RIOT,TIN GAME,YU-GI-OH!,YUGI OH,TIN TỨC GAME,GAME THỦ,CỘNG ĐỒNG MẠNG,GAME THỦ LMHT,",
category: 'games',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/mot-game-thu-khien-cong-dong-tram-tro-khi-tu-minh-dua-tat-ca-tuong-lmht-thanh-bai-ma-thuat-yu-gi-oh',
component: 'page_20210803111225'},
{title: "One Piece: Những bằng chứng rõ ràng nhất cho thấy Shanks có một đứa con, nhưng \"ông chú\" Tóc Đỏ lại bỏ bê đi \"giải cứu thế giới\"",
description: "Là một trong những nhân vật quan trọng trong One Piece, nhưng những thông tin về Shanks này lại rất ít được tiết lộ, đặc biệt là về đời tư của anh.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/avata-1627804270384233611899.jpg',
alt: "SHANKS TÓC ĐỎ,TỨ HOÀNG,SHANKS CÓ CON,LUFFY MŨ RƠM,CON CỦA MAKINO,ONE PIECE,",
category: 'games',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/one-piece-nhung-bang-chung-ro-rang-nhat-cho-thay-shanks-co-mot-dua-con-nhung-ong-chu-toc-do-lai-bo-be-di-giai-cuu-the-gioi',
component: 'page_20210803111216'},
{title: "5 nhân vật \"siêu phụ\" nhưng lại có vai trò \"siêu quan trọng\", làm ảnh hưởng lớn đến cốt truyện anime/manga",
description: "Nếu không có họ thì chắc chắn câu chuyện sẽ đi theo một hướng hoàn toàn khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/anh-1-16278854979831663757585.jpg',
alt: "ONE PIECE,NARUTO,MY HERO ACADEMIA,ANIME,DRAGON BALL,KIMETSU NO YAIBA,ANIME NHẬT BẢN,NHÂN VẬT PHỤ,",
category: 'games',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/5-nhan-vat-sieu-phu-nhung-lai-co-vai-tro-sieu-quan-trong-lam-anh-huong-lon-den-cot-truyen-anime-manga',
component: 'page_20210803111152'},
{title: "Cấu hình máy tính như nào để chơi được Bless Unleashed, game nhập vai trực tuyến miễn phí hot nhất Steam?",
description: "Bless Unleashed là game miễn phí cực hot trên Steam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-1627918275629617952262.jpeg',
alt: "CẤU HÌNH MÁY TÍNH,GAME NHẬP VAI,GAME MIỄN PHÍ,BLESS UNLEASHED,STEAM,GAME MMORPG,FREE TO PLAY,",
category: 'games',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/cau-hinh-may-tinh-nhu-nao-de-choi-duoc-bless-unleashed-game-nhap-vai-truc-tuyen-mien-phi-hot-nhat-steam',
component: 'page_20210803111127'},
{title: "One Punch Man: \"Thánh Phồng\" Saitama suýt chút nữa đã rất cô độc vì quá mạnh nếu không có người này?",
description: "Có một câu hỏi mà nhiều độc giả One Punch Man thắc mắc đó là nhân vật nào là người tin tưởng Saitama nhất?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/hi1-1627898258668285855636.jpg',
alt: "MANGA ONE PUNCH MAN,TÁC GIẢ ONE PUNCH MAN,MỸ NHÂN ONE PUNCH MAN,ANIME ONE PUNCH MAN,KING ONE PUNCH MAN,FAN ONE PUNCH MAN,CỘNG ĐỒNG ONE PUNCH MAN,THÁNH PHỒNG SAITAMA,HỌC TRÒ SAITAMA,THẦY TRÒ SAITAMA VS GENOS,FUBUKI VS BANG,",
category: 'games',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/one-punch-man-thanh-phong-saitama-suyt-chut-nua-da-rat-co-doc-vi-qua-manh-neu-khong-co-nguoi-nay',
component: 'page_20210803111112'},
{title: "Top 7 nhân vật có bộ não thông minh nhất trong thế giới anime, Thám Tử Lừng Danh Conan chỉ xếp cuối cùng",
description: "Bảy nhân vật sau đây được cho là sở hữu những bộ não thông minh nhất trong thế giới anime.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/cu1-16278104439231846267790.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '11:10 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:10 AM',
link: '/top-7-nhan-vat-co-bo-nao-thong-minh-nhat-trong-the-gioi-anime-tham-tu-lung-danh-conan-chi-xep-cuoi-cung',
component: 'page_20210803111005'},
{title: "Khai thật đi! Bạn đã từng đập chuột, bàn phím vì các nhiệm vụ huyền thoại, khó đến mức “phát điên” này chưa?",
description: "Có lẽ trong series GTA thì đây xứng đáng là chuỗi nhiệm vụ khó bậc nhất khiến người chơi vô cùng ức chế.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-1627836247715331530341.jpg',
alt: "GTA,GTA VICE CITY,GTA SAN ANDREAS,TIN TỨC GAME,DEMOLITION MAN,",
category: 'games',
time: '01:22 AM',
date: '44235',
timestamp: '02/08/2021 01:22 AM',
link: '/khai-that-di-ban-da-tung-dap-chuot-ban-phim-vi-cac-nhiem-vu-huyen-thoai-kho-den-muc-phat-dien-nay-chua',
component: 'page_20210802012229'},
{title: "Đồng coin Axie Infinity rớt giá thảm hại, game thủ Việt náo loạn vì \"đu đỉnh\"",
description: "Game thủ Axie Infinity Việt Nam đang lo lắng vì giá tiền ảo của game liên tục giảm.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/2/3-16278792827231259436558-1627879302333166739249.png',
alt: "GAME THỦ VIỆT,GAME NFT,AXIE INFINITY,TIỀN ẢO,SLP,AXS,SLP COIN,",
category: 'games',
time: '01:22 AM',
date: '44235',
timestamp: '02/08/2021 01:22 AM',
link: '/dong-coin-axie-infinity-rot-gia-tham-hai-game-thu-viet-nao-loan-vi-du-dinh',
component: 'page_20210802012221'},
{title: "Thương hiệu thời trang Louis Vuitton chuẩn bị ra mắt game NFT điện thoại",
description: "Không chỉ lấn sân sang ngành game, thương hiệu thời trang của Pháp cũng sẽ sử dụng luôn cả NFT.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-16278756721141711018649.jpg',
alt: "THƯƠNG HIỆU THỜI TRANG,GAME NFT,NFT,TIỀN ẢO,BLOCKCHAIN,TRÒ CHƠI ĐIỆN TỬ,",
category: 'games',
time: '01:22 AM',
date: '44235',
timestamp: '02/08/2021 01:22 AM',
link: '/thuong-hieu-thoi-trang-louis-vuitton-chuan-bi-ra-mat-game-nft-dien-thoai',
component: 'page_20210802012212'},
{title: "12 game NFT có thể kiếm tiền \"ngon\" như Axie Infinity (Phần 2)",
description: "Mời các bạn tiếp tục đến với series những tựa game NFT hot nhất thị trường.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-16278323277561900075355.jpg',
alt: "THẾ GIỚI ẢO,GAME NFT,CHƠI GAME KIẾM TIỀN,PLAY TO EARN,THẾ GIỚI MỞ,TIỀN ẢO,",
category: 'games',
time: '01:21 AM',
date: '44235',
timestamp: '02/08/2021 01:21 AM',
link: '/12-game-nft-co-the-kiem-tien-ngon-nhu-axie-infinity-phan-2',
component: 'page_20210802012138'},
{title: "One Piece: Biến thành động vật và 3 đặc điểm của trái ác quỷ hệ Zoan khác hẳn những hệ khác",
description: "Người sở hữu năng lực trái ác quỷ hệ Zoan có khả năng biến thành một loài động vật, kèm theo đó là các đặc điểm cũng như khả năng của loài vật đó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-1627800104680345133960.jpg',
alt: "ONE PIECE,TRÁI ÁC QUỶ,TRÁI ZOAN,ZOAN CỔ ĐẠI,LOẠI TRÁI ÁC QUỶ,",
category: 'games',
time: '01:19 AM',
date: '44235',
timestamp: '02/08/2021 01:19 AM',
link: '/one-piece-bien-thanh-dong-vat-va-3-dac-diem-cua-trai-ac-quy-he-zoan-khac-han-nhung-he-khac',
component: 'page_20210802011936'},
{title: "Top 10 Rồng thần \"ngầu\" nhất Dragon Ball: Con bay trên trời, con đi dưới đất, con dùng hẳn máy thời gian để \"săn lùng\" (P1)",
description: "Dragon Ball xuất hiện vô số phiên bản Rồng thần, mỗi con đều có những hình dạng và sức mạnh đặc biệt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/anh-1-16278005780421511752096.jpg',
alt: "DRAGON BALL,7 VIÊN NGỌC RỒNG,RỒNG THẦN,SERIES DRAGON BALL,",
category: 'games',
time: '01:19 AM',
date: '44235',
timestamp: '02/08/2021 01:19 AM',
link: '/top-10-rong-than-ngau-nhat-dragon-ball-con-bay-tren-troi-con-di-duoi-dat-con-dung-han-may-thoi-gian-de-san-lung-p1',
component: 'page_20210802011906'},
{title: "Cày phim ngày dịch, top 5 anime người máy - mecha siêu hấp dẫn sau đây sẽ kiến bạn hài lòng",
description: "Cùng chúng tôi đến với 5 anime người máy - mecha cực đỉnh giúp bạn giải trí trong những ngày ở nhà chống dịch này nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/any1-16277975881851921103740.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,GAME ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/cay-phim-ngay-dich-top-5-anime-nguoi-may-mecha-sieu-hap-dan-sau-day-se-kien-ban-hai-long',
component: 'page_20210802011854'},
{title: "Giật mình khi thấy các mỹ nhân anime bất ngờ đi độ ngực, ai cũng có tâm hồn ngồn ngộn nhức mắt",
description: "Dù phụ nữ có da trắng đến cỡ nào, da mặt đẹp và dáng chuẩn đến bao nhiêu thì trong mắt đàn ông, vòng 1 vẫn là điểm họ dừng mắt đầu tiên và lâu nhất.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-16277383453661718633477.jpg',
alt: "ANIME,MỸ NHÂN ANIME,VÒNG 1,TÂM HỒN PHỤ NỮ,MỸ NHÂN NGỰC KHỦNG,FAN ART,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/giat-minh-khi-thay-cac-my-nhan-anime-bat-ngo-di-do-nguc-ai-cung-co-tam-hon-ngon-ngon-nhuc-mat',
component: 'page_20210802011846'},
{title: "Những điều ngớ ngẩn game thủ thường gặp trong open world game",
description: "Dù open world game được xây dựng với mục tiêu mô phỏng lại thế giới thực càng giống càng tốt, nhưng vẫn có những chi tiết cực kỳ ngớ ngẩn tồn tại trong phần lớn các tựa game.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/29/-16275505649941752679599.jpg',
alt: "KHÁM PHÁ,GAME OPEN WORLD,GAME ONLINE,TRÒ CHƠI ĐIỆN TỬ,GIẢI TRÍ,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/nhung-dieu-ngo-ngan-game-thu-thuong-gap-trong-open-world-game',
component: 'page_20210802011814'},
{title: "Top 10 tựa game PC những năm 2000 với đồ họa đỉnh cao làm game thủ mãi nhớ (P.2)",
description: "Những ấn tượng không thể nào quên với các anh em game thủ 8x 9x một thời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-1627755900498830370556.png',
alt: "GAME,GAME HAY,GAME ĐỈNH,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,ĐỒ HỌA GAME,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/top-10-tua-game-pc-nhung-nam-2000-voi-do-hoa-dinh-cao-lam-game-thu-mai-nho-p2',
component: 'page_20210802011803'},
{title: "12 game NFT có thể kiếm tiền \"ngon\" như Axie Infinity (Phần 1)",
description: "Game NFT đã khởi đầu cho xu hướng chơi game kiếm tiền đang thịnh hành trên thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-16277432005911663617292.jpg',
alt: "AXIE INFINITY,GAME NFT,CHƠI GAME KIẾM TIỀN,PLAY TO EARN,TIỀN ẢO,AXS,SLP,",
category: 'games',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/12-game-nft-co-the-kiem-tien-ngon-nhu-axie-infinity-phan-1',
component: 'page_20210802011748'},
{title: "Tại sao các huấn luyện viên chỉ dùng 6 Pokémon mà thôi?",
description: "Con số 6 được coi là chuẩn hóa với các huấn luyện viên Pokémon kể từ đầu khi mới khai sinh ra game cho tới thời điểm hiện tại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/31/photo-1-16277492905702081276162.png',
alt: "POKÉMON,",
category: 'games',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/tai-sao-cac-huan-luyen-vien-chi-dung-6-pokemon-ma-thoi',
component: 'page_20210802011740'},
{title: "Top 10 tựa game PC những năm 2000 với đồ họa đỉnh cao làm game thủ mãi nhớ",
description: "Những ấn tượng không thể nào quên với các anh em game thủ 8x 9x một thời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-1627718538346379055070.jpg',
alt: "GAME,GAME HAY,GAME ĐỈNH,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '01:20 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:20 PM',
link: '/top-10-tua-game-pc-nhung-nam-2000-voi-do-hoa-dinh-cao-lam-game-thu-mai-nho',
component: 'page_20210731132037'},
{title: "Giải phẫu cơ thể của Thần hủy diệt Beerus: Mèo mà không có lông và những bí mật kỳ lạ gây sốc khác",
description: "Trong Dragon Ball Super, Beerus là Thần hủy diệt của Vũ trụ 7. Ông là người vô cùng mạnh mẽ, sở hữu sức mạnh hủy diệt đáng sợ khiến ai cũng phải dè chừng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/31/anh-1-16277170034351938628634.png',
alt: "DRAGON BALL SUPER,THẦN HỦY DIỆT,VŨ TRỤ 7,BEERUS,SỨC MẠNH HỦY DIỆT,THIÊN SỨ WHIS,",
category: 'games',
time: '01:20 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:20 PM',
link: '/giai-phau-co-the-cua-than-huy-diet-beerus-meo-ma-khong-co-long-va-nhung-bi-mat-ky-la-gay-soc-khac',
component: 'page_20210731132029'},
{title: "Pikachu và những người bạn thân nhất của Satoshi trong các series Pokémon",
description: "Kể từ khi ra mắt, loạt Pokémon đã đưa người xem đắm chìm vào những cuộc phiêu lưu kỳ thú của nhân vật chính Satoshi trong thế giới rộng lớn. Và trong cuộc hành trình ấy chắc hẳn là sẽ không thể thiếu những người bạn thân thiết, cùng nhau vượt qua mọi gian nan, khó khăn.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/31/photo-1-16277092247282014576557.jpg',
alt: "PIKACHU,SATOSHI,POKÉMON,BẠN THÂN CỦA SATOSHI,",
category: 'games',
time: '01:19 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:19 PM',
link: '/pikachu-va-nhung-nguoi-ban-than-nhat-cua-satoshi-trong-cac-series-pokemon',
component: 'page_20210731131958'},
{title: "Những tựa game open world nhẹ tựa lông hồng cho game thủ quẩy trên PC RAM 2-3GB",
description: "RAM khiêm tốn không phải là vấn đề lớn với các game thủ nếu chơi các tựa game open world này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/the-elder-scrolls-v-skyrim-full-crack-16276392897441598181578.jpg',
alt: "GAME,OPEN WORLD,",
category: 'games',
time: '01:19 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:19 PM',
link: '/nhung-tua-game-open-world-nhe-tua-long-hong-cho-game-thu-quay-tren-pc-ram-2-3gb',
component: 'page_20210731131912'},
{title: "“Nước đến bàn phím vẫn không nhảy\", game thủ mặc kệ dòng lũ, xắn quần ngồi đánh quái",
description: "Những hình ảnh game thủ Philippines ngồi “cày” game mặc cho dòng nước lũ dâng lên với tốc độ nhanh chóng đang thu hút sự chú ý của cộng đồng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/29/photo-1-16275517364501564578952.jpg',
alt: "GAME THỦ,CÀY GAME,PHILIPPINES,",
category: 'games',
time: '12:19 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:19 PM',
link: '/nuoc-den-ban-phim-van-khong-nhay-game-thu-mac-ke-dong-lu-xan-quan-ngoi-danh-quai',
component: 'page_20210730121934'},
{title: "Top game cấu hình nhẹ nhưng lại sở hữu đồ họa đỉnh cao",
description: "Game có đồ họa đẹp không thiếu nhưng game vừa đẹp vừa nhẹ thì lại không nhiều.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/photo-1-1627581743584424295397.png',
alt: "CỘNG ĐỒNG MẠNG,GAME HAY,GAME ĐỈNH,TIN TỨC GAME,ĐỒ HỌA ĐẸP,MÁY YẾU,CẤU HÌNH THẤP,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/top-game-cau-hinh-nhe-nhung-lai-so-huu-do-hoa-dinh-cao',
component: 'page_20210730121855'},
{title: "Top game cấu hình nhẹ nhưng lại sở hữu đồ họa đỉnh cao (P.2)",
description: "Game có đồ họa đẹp không thiếu nhưng game vừa đẹp vừa nhẹ thì lại không nhiều.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/photo-1-16276148883661382259834.jpg',
alt: "GAME HAY,GAME NHẸ,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/top-game-cau-hinh-nhe-nhung-lai-so-huu-do-hoa-dinh-cao-p2',
component: 'page_20210730121846'},
{title: "Hòa mình cùng Thế vận hội 2020 với những bộ anime thể thao nổi tiếng",
description: "Thế vận hội Tokyo 2020 cuối cùng cũng đang diễn ra tại Nhật Bản và khán giả có thể cùng học cùng chơi với một số anime thể thao sau.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/photo-1-162758385936526249098.jpg',
alt: "ANIME,OLYMPIC,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/hoa-minh-cung-the-van-hoi-2020-voi-nhung-bo-anime-the-thao-noi-tieng',
component: 'page_20210730121828'},
{title: "Tải miễn phí bom tấn siêu anh hùng Marvel Avengers trên Steam",
description: "Tải Marvel Avengers và chiến thôi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/30/photo-1-1627620857366227458726.jpg',
alt: "SIÊU ANH HÙNG,GAME PHIÊU LƯU,MARVEL’S AVENGERS,GAME MIỄN PHÍ,GAME SIÊU ANH HÙNG,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/tai-mien-phi-bom-tan-sieu-anh-hung-marvels-avengers-tren-steam',
component: 'page_20210730121818'},
{title: "5 anime có cái kết đẹp nhất do cư dân mạng Nhật Bản bình chọn: Kimetsu no Yaiba cũng có chân",
description: "Theo một khảo sát dành cho netizen Nhật, đây là những bộ anime shonen mà theo họ là có cái kết đẹp nhất.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/inuyashaanime11715041280x0-372f9970-162763048698727674113.jpeg',
alt: "ANIME,",
category: 'games',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/5-anime-co-cai-ket-dep-nhat-do-cu-dan-mang-nhat-ban-binh-chon-kimetsu-no-yaiba-cung-co-chan',
component: 'page_20210730121742'},
{title: "Dragon Ball Super: Bản chất sức mạnh hủy diệt của Hakai, thứ có thể giúp Vegeta đánh bại \"Kẻ sống sót\" Granolah",
description: "Hakai là sức mạnh của các vị Thần Hủy Diệt và dường như đã được Vegeta sử dụng trong chap mới đây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/anh-1-1627628436774114725314.jpg',
alt: "DRAGON BALL SUPER,VEGETA,HOÀNG TỬ SAIYAN,THẦN HỦY DIỆT,HAKAI,SỨC MẠNH HỦY DIỆT,GRANOLAH,CHIẾN BINH MẠNH NHẤT VŨ TRỤ,",
category: 'games',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/dragon-ball-super-ban-chat-suc-manh-huy-diet-cua-hakai-thu-co-the-giup-vegeta-danh-bai-ke-song-sot-granolah',
component: 'page_20210730121731'},
{title: "Những khung cảnh quen thuộc của game thủ Việt thời còn chưa có Internet, nhìn là thấy cả bầu trời kỷ niệm (p1)",
description: "Những hình ảnh đáng quý gợi về không biết bao nhiêu cảm xúc cho thế hệ các game thủ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/26/photo-1-1627302216562611661450.jpg',
alt: "TIN TỨC GAME,GAME THỦ,GAME,",
category: 'games',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/nhung-khung-canh-quen-thuoc-cua-game-thu-viet-thoi-con-chua-co-internet-nhin-la-thay-ca-bau-troi-ky-niem-p1',
component: 'page_20210730121708'},
{title: "Những tựa game 18+ không phù hợp để chơi trước mặt nhiều người",
description: "Có quá nhiều cảnh bạo lực và tình dục, những cảnh này không phù hợp để bạn trình chiếu và chơi trước mặt mọi người cho lắm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/28/photo-1-1627486426479237341960.jpg',
alt: "GAME,",
category: 'games',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/nhung-tua-game-18-khong-phu-hop-de-choi-truoc-mat-nhieu-nguoi',
component: 'page_20210729162648'},
{title: "Loạt Pokémon được lấy cảm hứng từ những con quái vật truyền thuyết",
description: "Thế giới Pokémon có rất nhiều điều thú vị, và nó bao gồm cả nguồn gốc của các loài Pokémon.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/28/photo-1-16274085038071368152910.jpg',
alt: "POKÉMON,TRUYỀN THUYẾT,",
category: 'games',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/loat-pokemon-duoc-lay-cam-hung-tu-truyen-thuyet-nhat-ban',
component: 'page_20210729162629'},
{title: "20 game giả lập cho bạn làm đủ nghề trong xã hội, từ thợ sửa ống nước đến chàng lái taxi (P1)",
description: "Những game giả lập này sẽ cho phép bạn làm rất nhiều nghề trong xã hội.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/26/photo-1-1627315208858588438104.jpg',
alt: "GAME GIẢ LẬP,SỬA ỐNG NƯỚC,GAME MÔ PHỎNG,TÀI XẾ XE TẢI,EURO TRUCK SIMULATOR 2,STARDEW VALLEY,",
category: 'games',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/20-game-gia-lap-cho-ban-lam-du-nghe-trong-xa-hoi-tu-tho-sua-ong-nuoc-den-chang-lai-taxi-p1',
component: 'page_20210729162610'},
{title: "Đạt 2,5 tỷ đô, Axie Infinity - trò chơi do Việt Nam sản xuất trở thành game NFT đắt giá nhất mọi thời đại",
description: "Axie Infinity trở thành niềm tự hào của làng game Việt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/26/photo-1-16272953989991650537087.png',
alt: "AXIE INFINITY,GAME VIỆT,TỶ ĐÔ,BITCOIN,TIỀN ẢO,CRYPTO,CÔNG NGHỆ VIỆT NAM,",
category: 'games',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/dat-25-ty-do-axie-infinity-tro-choi-do-viet-nam-san-xuat-tro-thanh-game-nft-dat-gia-nhat-moi-thoi-dai',
component: 'page_20210729162530'},
{title: "Cách nhận 12 Pokémon miễn phí trong Pokémon Unite (Phần 2)",
description: "Hãy nhanh tay tham gia Pokémon Unite để nhận được nhiều phần quà miễn phí giá trị.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/26/photo-1-16272752477791676071344.jpg',
alt: "HUẤN LUYỆN VIÊN,POKÉMON,POKÉMON UNITE,MOBA POKÉMON,MOBA POKEMON,MOBA POKÉMON UNITE,",
category: 'games',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/cach-nhan-12-pokemon-mien-phi-trong-pokemon-unite-phan-2',
component: 'page_20210729162514'},
{title: "Top 20 nhân vật có tiền truy nã cao nhất One Piece tính tới thời điểm hiện tại, Luffy còn thua 6 người này?",
description: "Cùng chúng tôi điểm qua 20 nhân vật có tiền truy nã cao nhất trong One Piece tính cho tới thời điểm hiện tại nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/25/gu1-16271926625831676502001.jpg',
alt: "ONE PIECE,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,TRUY NÃ ONE PIECE,LUFFY MŨ RƠM,TỨ HOÀNG KAIDO,TỨ HOÀNG BIG MOM,ARC WANO,TỨ HOÀNG SHANKS,GOL D. ROGER,PHƯỢNG HOÀNG MARCO,",
category: 'games',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/top-20-nhan-vat-co-tien-truy-na-cao-nhat-one-piece-tinh-toi-thoi-diem-hien-tai-luffy-con-thua-6-nguoi-nay',
component: 'page_20210729162410'},
{title: "Những Pokémon khiến người chơi phải đỏ mắt truy lùng qua từng phiên bản",
description: "Mỗi lần tìm chúng khó hơn mò kim đáy bể chỉ vì các nhà thiét kế tìm cách chơi khó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/24/photo-1-16271432927312095336961.jpg',
alt: "POKÉMON,",
category: 'games',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/nhung-pokemon-phai-do-mat-truy-lung-qua-tung-phien-ban',
component: 'page_20210729162352'},
{title: "Game thủ thấy gì khi đi hết map các game open world đình đám? (P.1)",
description: "Dù bạn tin hay không, các nhà phát triển game open world có đến 1001 cách ngăn bạn đến được rìa thế giới.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/21/-16268588209211899282210.jpg',
alt: "OPEN WORLD,GAME,",
category: 'games',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/game-thu-thay-gi-khi-di-het-map-cac-game-open-world-dinh-dam-p1',
component: 'page_20210729162324'},
{title: "Dragon Ball Super: 5 người Saiyan đã có thể biến thành Super Saiyan God, số 4 là trường hợp đặc biệt",
description: "Không phải chỉ mình Goku mới có thể biến thành Super Saiyan God.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/20/photo-1-16267746209691819938455.jpg',
alt: "SUPER SAIYAN GOD,DRAGON BALL SUPER,SAIYAN,VEGETA,TRUNKS XENO,THẦN CỦA NGƯỜI SAIYAN,BLACK GOKU,",
category: 'games',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/dragon-ball-super-5-nguoi-saiyan-da-co-the-bien-thanh-super-saiyan-god-so-4-la-truong-hop-dac-biet',
component: 'page_20210729162156'},
{title: "Những cái chết lãng xẹt bậc nhất trong thế giới trò chơi điện tử",
description: "Không phải cái chết nào cũng vĩ đại như bạn nghĩ đâu, còn có vô số lý do khiến cho game thủ tức anh ách cho đến khi biết nguyên nhân.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/20/photo-1-16267868025941909041185.png',
alt: "GAME,",
category: 'games',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/nhung-cai-chet-lang-xet-bac-nhat-trong-the-gioi-tro-choi-dien-tu',
component: 'page_20210729162146'},
{title: "Top 10 lời đồn “huyền thoại” dù hư cấu nhưng nhiều game thủ vẫn tin sái cổ (P.2)",
description: "Những cú lừa cực mạnh nhưng anh em nào cũng ít nhất một lần dính trap...",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/19/photo-1-16267104636641078128704.png',
alt: "GAME,GAME HAY,BÍ ẨN,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,GAME THỦ,",
category: 'games',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/top-10-loi-don-huyen-thoai-du-hu-cau-nhung-nhieu-game-thu-van-tin-sai-co-p2',
component: 'page_20210729162047'},
{title: "Top 10 Pokémon khiến con người cảm thấy sợ hãi nhất, nhiều loài đáng sợ ngay từ vẻ ngoài",
description: "Con người và Pokémon hầu hết cùng tồn tại một cách hòa hợp, nhưng những Pokémon nguy hiểm này có thể làm hại con người nếu không được kiểm soát.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-16266038127731600690029.jpg',
alt: "POKÉMON,POKÉMON KHIẾN CON NGƯỜI SỢ,MEWTWO,GENGAR,ALAKAZAM,COFAGRIGUS,HYDREIGON,",
category: 'games',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/top-10-pokemon-khien-con-nguoi-cam-thay-so-hai-nhat-nhieu-loai-dang-so-ngay-tu-ve-ngoai',
component: 'page_20210729162017'},
{title: "Cổ điển nhưng không lỗi thời, Timing Hero vẫn thu hút hàng triệu game thủ thử sức trên toàn thế giới",
description: "Gạt đi định kiến về những tựa game trắng đen lỗi thời, Timing Hero sẽ là lựa chọn hoàn hảo cho những pha hành động mãn nhãn trong thời điểm hiện tại.",
src: 'https://lh6.googleusercontent.com/YWHfqxRBujPr2kf9S6VFggV1_2Ds-TaO89iDF31b1oCAWavC7yuiQGfWQjx0RS8dlqDOXcfuYWVITLD8KKmX8F9iezCS0NOB4YtP0aMCNAOapuOKeNVuEPD6rXTAWZzgjrY_91v3',
alt: "TIMIING HERO,TIN TỨC GAME,",
category: 'games',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/co-dien-nhung-khong-loi-thoi-timing-hero-van-thu-hut-hang-trieu-game-thu-thu-suc-tren-toan-the-gioi',
component: 'page_20210729162000'},
{title: "Trang trại 3.800 máy PS4 Slim hoá ra không phải để đào coin, mà dùng để \"cày\" vật phẩm trong game FIFA 21",
description: "PS4 có thể kiếm tiền, nhưng không bằng cách khai thác tiền điện tử.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/19/photo-1-1626707442669514919282.png',
alt: "COIN,CỘNG ĐỒNG MẠNG,PS4 SLIM,TIN TỨC GAME,PS4,",
category: 'games',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/trang-trai-3800-may-ps4-slim-hoa-ra-khong-phai-de-dao-coin-ma-dung-de-cay-vat-pham-trong-game-fifa-21',
component: 'page_20210729161920'},
{title: "Sau Thám Tử Đã Chết các fan anime thích thú với dàn harem trong \"Có Bạn Gái Lại Thêm Bạn Gái\", bắt cá 2 tay có khi lại hay",
description: "Thêm một siêu phẩm anime nữa được nhiều khán giả yêu thích với dàn harem vô cùng ấn tượng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/17/rui3-1626497074652125518877.png',
alt: "RENT-A-GIRL-FRIEND,CÓ BẠN GÁI LẠI THÊM BẠN GÁI,HAREM,ANIME MỚI,DIAMOND THUÊ CHIZURU MIZUHARA,KAZUYA KINOSHITA,",
category: 'games',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/sau-tham-tu-da-chet-cac-fan-anime-thich-thu-voi-dan-harem-trong-co-ban-gai-lai-them-ban-gai-bat-ca-2-tay-co-khi-lai-hay',
component: 'page_20210729161845'},
{title: "Những trò chơi khiến game thủ giật mình vì \"hút máu\" quá dữ dội",
description: "Những pha hút máu này xứng đáng được kể tới vì chúng được sinh ra đúng với mục đích \"hút máu\" người chơi tới tận giọt cuối cùng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/17/photo-1-1626467721491353955828.jpg',
alt: "GAME,HÚT MÁU,",
category: 'games',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/nhung-tro-choi-khien-game-thu-giat-minh-vi-hut-mau-qua-du-doi',
component: 'page_20210729161825'},
{title: "Tham quan bản đồ các “lò” đào tạo game thủ, “thiên đường” cho 500 anh em là đây chứ đâu!",
description: "Nhiều quốc gia châu Á đặt niềm tin rằng, Esports sẽ phát triển trong thời gian tới và game thủ là ngành nghề cần được đào tạo kỹ lưỡng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/16/-1626452062656620490724.jpg',
alt: "GAME THỦ,TRƯỜNG HỌC,ESPORTS,",
category: 'games',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/tham-quan-ban-do-cac-lo-dao-tao-game-thu-thien-duong-cho-500-anh-em-la-day-chu-dau',
component: 'page_20210729161815'},
{title: "Chơi game hay, dạy học giỏi, các nữ giáo viên trẻ khiến anh em game thủ tới tấp muốn đăng ký lớp phụ đạo",
description: "Thừa các yếu tố để thu hút cánh mày râu, các nữ giáo viên xinh đẹp sở hữu lượt fan “khủng\".",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/10/photo-1-16259322309741565601941.jpg',
alt: "GIÁO VIÊN,CHƠI GAME,STREAMER,GAME THỦ,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/choi-game-hay-day-hoc-gioi-cac-nu-giao-vien-tre-khien-anh-em-game-thu-toi-tap-muon-dang-ky-lop-phu-dao',
component: 'page_20210729161710'},
{title: "Loạt game Pokémon hay nhất trong mỗi thế hệ, cái tên nào khi được xướng lên cũng khiến fan mê mệt",
description: "Trải qua tám thế hệ trò chơi điện tử, người hâm mộ Pokémon đã có thể chơi một số tựa game tuyệt vời, nhưng đâu mới là cái tên hay nhất từ mỗi thế hệ?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/10/photo-1-16258922973121614617511.jpg',
alt: "POKÉMON,POKÉMON GOLD,POKÉMON YELLOW,GAME POKÉMON,CÁC THẾ HỆ POKÉMON,POKÉMON EMERALD,POKÉMON PLATINUM,",
category: 'games',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/loat-game-pokemon-hay-nhat-trong-moi-the-he-cai-ten-nao-khi-duoc-xuong-len-cung-khien-fan-me-met',
component: 'page_20210729161701'},
{title: "6 nhân vật đã chết không phải do bị giết trong One Piece, số 2 là trường hợp rất đặc biệt",
description: "Không phải cái chết nào trong One Piece cũng do bị giết đâu nhé!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-16257367946381870341655.jpg',
alt: "ONE PIECE,CÁI CHẾT ONE PIECE,NHÂN VẬT ONE PIECE CHẾT VÌ BỊ BỆNH,PORTGAS D. ROUGE,VINSMOKE SORA,",
category: 'games',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/6-nhan-vat-da-chet-khong-phai-do-bi-giet-trong-one-piece-so-2-la-truong-hop-rat-dac-biet',
component: 'page_20210729161522'},
{title: "Game thủ mobile gây sốc khi mạnh tay rút hầu bao chi số tiền “kỷ lục” trong 6 tháng đầu năm",
description: "Dù trong thời kỳ dịch bệnh lũng đoạn, hoành hành, game thủ mobile vẫn chứng minh mình là người chịu chơi nhất khi mạnh tay chi số tiền khủng vào game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/7/photo-1-16256531735491340483581.jpg',
alt: "GAME THỦ,GAME MOBILE,DOANH THU,",
category: 'games',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/game-thu-mobile-gay-soc-khi-manh-tay-rut-hau-bao-chi-so-tien-ky-luc-trong-6-thang-dau-nam',
component: 'page_20210729161437'},
{title: "Những chú chó ghi dấu ấn đậm nhất trong làng game thế giới, ai chơi qua cũng đều phải nhớ mãi",
description: "Những chú chó có cả tốt và xấu này đã từng khuynh đảo làng game thế giới.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/6/screenshot10-1625559357592580298764.png',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/nhung-chu-cho-ghi-dau-an-dam-nhat-trong-lang-game-the-gioi-ai-choi-qua-cung-deu-phai-nho-mai',
component: 'page_20210729161400'},
{title: "Những vũ khí đời thực được buff quá đà trong game",
description: "Các vũ khí này thực sự rất mạnh trong game, khiến cho người chơi phải tự hỏi xem đời thực có thật sự mạnh như thế này không.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/6/photo-1-1625590321049993105138.jpg',
alt: "GAME,ĐỜI THỰC,",
category: 'games',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/nhung-vu-khi-doi-thuc-duoc-buff-qua-da-trong-game',
component: 'page_20210729161340'},
{title: "Những vũ khí nổi danh từng xuất hiện trong thế giới trò chơi điện tử",
description: "Không chỉ mạnh nhất, bá nhất, chúng còn là những cái tên đình đám được nhiều người chơi game biết tới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/4/photo-1-16254123885261530926387.jpg',
alt: "VŨ KHÍ,GAME,",
category: 'games',
time: '04:12 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:12 PM',
link: '/nhung-vu-khi-noi-danh-tung-xuat-hien-trong-the-gioi-tro-choi-dien-tu',
component: 'page_20210729161240'},
{title: "Loạt ending khiến người chơi ức chế trong các game đa lựa chọn",
description: "Có những bad-ending trong các tựa game nhiều lựa chọn, và cũng có cả những worst-ending chỉ để người chơi \"tức ói máu\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/4/photo-1-1625370979166885029656.jpg',
alt: "GAME,",
category: 'games',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/loat-ending-khien-nguoi-choi-uc-che-trong-cac-game-da-lua-chon',
component: 'page_20210729161058'},
{title: "4 game bom tấn cực đỉnh sẽ ra mắt trong tháng 7/2021",
description: "Tháng 7 này sẽ chào đón nhiều game bom tấn đỉnh cao.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/4/photo-1-16253820610221131739331.jpg',
alt: "GAME BOM TẤN,THỜI CHIẾN QUỐC,ĐẠI TƯỚNG QUÂN,LIÊN MINH HUYỀN THOẠI,CÔNG THỨC 1,",
category: 'games',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/4-game-bom-tan-cuc-dinh-se-ra-mat-trong-thang-7-2021',
component: 'page_20210729161038'},
{title: "Loạt Pokémon có nhiều đất diễn ở phần 1 nhưng lại biến mất trong thế hệ sau",
description: "Không phải chúng yếu đi, chỉ đơn giản là các Pokémon ngày nay mạnh hơn mà thôi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/1/photo-1-16251525412681096304439.png',
alt: "POKÉMON,",
category: 'games',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/loat-pokemon-co-nhieu-dat-dien-o-phan-1-nhung-lai-bien-mat-trong-the-he-sau',
component: 'page_20210729161017'},
{title: "Những trò chơi đặc biệt được mệnh danh \"sách giáo khoa\" cho cả làng game",
description: "Những trò chơi này đã góp phần xây dựng thể loại game trở nên chuẩn chỉ hơn, qua đó trở thành \"tiêu chuẩn sách giáo khoa\" cho việc làm game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/30/photo-1-16250681605831005129831.jpg',
alt: "GAME,THỂ LOẠI,",
category: 'games',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/nhung-tro-choi-dac-biet-gop-phan-dinh-hinh-ca-the-loai-game',
component: 'page_20210729160913'},
{title: "Những tựa game open-world bị ghẻ lạnh một cách oan uổng",
description: "Vì những lý do mà không ai hiểu nổi, các tựa game open-world này đã chìm vào quên lãng, dù chính người chơi của chúng đều khẳng định chất lượng tuyệt vời.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/30/-1625042149365447372853.jpg',
alt: "KHÁM PHÁ,OPEN-WORLD GAME,GIẢI TRÍ,TRÒ CHƠI ĐIỆN TỬ,THẾ GIỚI MỞ,",
category: 'games',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/nhung-tua-game-open-world-bi-ghe-lanh-mot-cach-oan-uong',
component: 'page_20210729160835'},
{title: "Top 10 bộ anime hành động huyền thoại, những tượng đài khó bị xô đổ trong làng phim hoạt hình Nhật Bản",
description: "Bạn đã xem hết những bộ anime huyền thoại này chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/photo-1-16249608398691027219202.jpg',
alt: "ANIME,ANIME SHONEN,ANIME HÀNH ĐỘNG,DRAGON BALL Z,NARUTO,ANIME HUYỀN THOẠI,ANIME HAY,ONE PIECE,",
category: 'games',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/top-10-bo-anime-hanh-dong-huyen-thoai-nhung-tuong-dai-kho-bi-xo-do-trong-lang-phim-hoat-hinh-nhat-ban',
component: 'page_20210729160800'},
{title: "Những vũ khí vô hại nhất trong thế giới game, cẩn thận khi dùng không còn mỗi cái nịt",
description: "Trò chơi điện tử thỉnh thoảng lại đẻ ra một vài những thứ vũ khí không đâu, và đây là một vài thứ ăn hại nhất trong số chúng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/29/-1624984287291322107325.jpg',
alt: "GAME,VŨ KHÍ,VÔ DỤNG,",
category: 'games',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/nhung-vu-khi-vo-hai-nhat-trong-the-gioi-game-can-than-khi-dung-khong-con-moi-cai-nit',
component: 'page_20210729160739'},
{title: "Steam Deck: 10 triệu mua máy cầm tay chiến mọi loại game bom tấn, liệu anh em có tin vào sản phẩm thần kì này không?",
description: "Mới đây Valve đã nhá hàng sản phẩm máy chơi game “cây nhà lá vườn” do chính công ty sản xuất, đáp ứng mọi nhu cầu của game thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/17/photo-1-16264933757941782750761.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,STEAM,",
category: 'games',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/steam-deck-10-trieu-mua-may-cam-tay-chien-moi-loai-game-bom-tan-lieu-anh-em-co-tin-vao-san-pham-than-ki-nay-khong',
component: 'page_20210729160628'},
{title: "Mê mẩn những chiếc \"mặt nạ máy móc\" của dàn nhân vật anime, cảm giác vừa ngầu vừa an toàn",
description: "Đeo mặt nạ kín và dầy cộp như này thì khỏi lo virus làm phiền nữa nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/17/photo-1-1626495933255607981801.jpg',
alt: "MẶT NẠ,FAN ART,NHÂN VẬT ANIME,MẶT NẠ MÁY MÓC,",
category: 'games',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/me-man-nhung-chiec-mat-na-may-moc-cua-dan-nhan-vat-anime-cam-giac-vua-ngau-vua-an-toan',
component: 'page_20210729160619'},
{title: "Sự thật về Nobita: \"Anh chàng hậu đậu\" có thể ghi tên vào \"kỷ lục Guinness\" nhờ khả năng đặc biệt này",
description: "Có thể bạn không nhận ra, Nobita có một khả năng đặc biệt khiến nhiều người phải kinh ngạc.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/25/anh-1-16246037787701447091743.jpg',
alt: "NOBITA,DOAREMON,MANGA NHẬT BẢN,SỰ THẬT VỀ NOBITA,",
category: 'games',
time: '10:04 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:04 PM',
link: '/su-that-ve-nobita-anh-chang-hau-dau-co-the-ghi-ten-vao-ky-luc-guinness-nho-kha-nang-dac-biet-nay',
component: 'page_20210629220415'},
{title: "Thần lừa lọc Loki đã bị trừng phạt thảm khốc thế nào sau khi sát hại thần quang minh Balder trong thần thoại Bắc Âu?",
description: "Loki bị trói lên một phiến đá bằng bộ lòng của con trai hắn, ngày qua ngày hắn chịu sự dày vò khổ sở để trả giá cho tội lỗi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/25/processedsamloki-1624593509307320096817.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI BẮC ÂU,THẦN THOẠI,LOKI,THẦN LỪA LỌC LOKI,",
category: 'games',
time: '10:04 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:04 PM',
link: '/than-lua-loc-loki-da-bi-trung-phat-tham-khoc-the-nao-sau-khi-sat-hai-than-quang-minh-balder-trong-than-thoai-bac-au',
component: 'page_20210629220405'},
{title: "Hiker Games chia sẻ những hình ảnh đầu tiên về Quân đội Việt Nam trong 300475",
description: "300475 đang phát triển rất tốt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/photo-1-1624943482294517114223.jpg',
alt: "HÌNH ẢNH ĐẦU TIÊN,QUÂN ĐỘI VIỆT NAM,NHÀ PHÁT TRIỂN,GAME THUẦN VIỆT,",
category: 'games',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/hiker-games-chia-se-nhung-hinh-anh-dau-tien-ve-quan-doi-viet-nam-trong-300475',
component: 'page_20210629220356'},
{title: "Thích thú với loạt meme Aphrodite \"nâng ngực\" trong anime Record Of Ragnarok, càng ngắm càng mát vào ngày hè",
description: "Cùng đến với loạt ảnh meme của nữ thần sắc đẹp Aphrodite trong anime Shuumatsu No Valkyrie - Record Of Ragnarok nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/aou1-162494151203817476037.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,GAME ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/thich-thu-voi-loat-meme-aphrodite-nang-nguc-trong-anime-record-of-ragnarok-cang-ngam-cang-mat-vao-ngay-he',
component: 'page_20210629220308'},
{title: "Những con trùm đòi hỏi game thủ \"dùng não\" mới có thể vượt qua",
description: "Không phải lúc nào bạo lực cũng sẽ là phương án xử lý chính xác cho được.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/28/photo-1-1624813965384935059512.jpg',
alt: "GAME,TRÙM,BOSS,",
category: 'games',
time: '10:00 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:00 PM',
link: '/nhung-con-trum-doi-hoi-game-thu-dung-nao-moi-co-the-vuot-qua',
component: 'page_20210629220000'},
{title: "Tìm hiểu vì sao anh chàng đầu trọc Krillin ngày càng \"out meta\" trong Dragon Ball?",
description: "Là một trong những nhân vật xuất hiện lâu đời trong Dragon Ball, tuy nhiên Krillin ngày càng trở nên yếu đuối và \"vô dụng\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/ki1-16247679904461224239578.jpg',
alt: "NHÂN VẬT DRAGON BALL,MANGA DRAGON BALL,ANIME DRAGON BALL,KRILLIN,SON GOKU,ANDROID 18,",
category: 'games',
time: '09:55 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:55 PM',
link: '/tim-hieu-vi-sao-anh-chang-dau-troc-krillin-ngay-cang-out-meta-trong-dragon-ball',
component: 'page_20210629215515'},
{title: "Top 10 bộ anime trở nên đặc biệt bởi có nhân vật chính cô đơn, phớt lờ xã hội",
description: "Liệu bạn có yêu thích những nhân vật chính anime kiểu này không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/photo-1-1624774703038458926019.jpg',
alt: "KUROKI TOMOKO,ANIME,NHÂN VẬT CHÍNH CÔ ĐƠN,SAIKI KUSUO NO PSI-NAN,SAIKI KUSUO,HANDA-KUN,GOBLIN SLAYER,HYOUKA,",
category: 'games',
time: '09:55 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:55 PM',
link: '/top-10-bo-anime-tro-nen-dac-biet-boi-co-nhan-vat-chinh-co-don-phot-lo-xa-hoi',
component: 'page_20210629215506'},
{title: "Dragon Ball: Những điều ước có sức ảnh hưởng lớn nhất series, đặc biệt số 4 đã thay đổi toàn bộ vũ trụ",
description: "Trong series Dragon Ball có rất nhiều điều ước đã được thực hiện. Tuy nhiên, 4 điều này có thể là tác động mạnh nhất thay đổi toàn bộ cốt truyện.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/anh-1-16247820993851392773325.jpg',
alt: "DRAGON BALL,ĐIỀU ƯỚC,VŨ TRỤ,THẦN HỦY DIỆT,RỒNG THẦN,GIẢI ĐẤU QUYỀN LỰC,",
category: 'games',
time: '09:54 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:54 PM',
link: '/dragon-ball-nhung-dieu-uoc-co-suc-anh-huong-lon-nhat-series-dac-biet-so-4-da-thay-doi-toan-bo-vu-tru',
component: 'page_20210629215457'},
{title: "Top 5 tân binh anime hấp dẫn nhất mùa hè 2021, cái tên nào khiến bạn ấn tượng nhất?",
description: "5 cái tên sau đây được xem là những tân binh anime đáng xem nhất hè 2021.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/and1-16247787631831417625429.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '09:54 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:54 PM',
link: '/top-5-tan-binh-anime-hap-dan-nhat-mua-he-2021-cai-ten-nao-khien-ban-an-tuong-nhat',
component: 'page_20210629215449'},
{title: "Top 10 tựa game open-world hay nhất dành cho điện thoại Android do game thủ bình chọn (P.1)",
description: "Những tựa game này được tạo ra cho người dùng Android với trải nghiệm cực kỳ mới lạ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/24/-16245505692101933197214.jpg',
alt: "KHÁM PHÁ,GAME,GAME ONLINE,OPEN-WORLD GAME,GAME THẾ GIỚI MỞ,ANDROID,",
category: 'games',
time: '09:54 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:54 PM',
link: '/top-10-tua-game-open-world-hay-nhat-danh-cho-dien-thoai-android-do-game-thu-binh-chon-p1',
component: 'page_20210629215439'},
{title: "Top 4 anime tệ nhất mùa xuân 2021, toàn tên tuổi nổi tiếng nhưng chất lượng thì như hạch",
description: "Ngoài những thành công của Tokyo Revengers hay Cạo Râu Xong Tôi Nhặt Gái Về Nhà thì 4 anime sau đây được xem là gây ra thất vọng nhất mùa xuân 2021",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/26/anp1-16246840029101637187802.jpeg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/top-4-anime-te-nhat-mua-xuan-2021-toan-ten-tuoi-noi-tieng-nhung-chat-luong-thi-nhu-hach',
component: 'page_20210629215217'},
{title: "Top những game offline hấp dẫn nhất trên di động dành cho game thủ “đứt cáp” hay hết sạch 4G để online",
description: "Nếu rơi vào các tình huống mất mạng, đứt cáp hoặc không còn dữ liệu 4G để online thì game thủ không nên bỏ qua các tựa game dưới đây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/23/photo-1-1624465989685829439736.jpg',
alt: "TOP GAME MOBILE,TOP GAME,TOP GAME HAY NHẤT,TOP GAME MOBILE HAY NHẤT,LIMBO,GAME OFFLINE,TOP GAME OFFLINE,TOP GAME OFFLINE HAY NHẤT,GAME MOBILE OFFLINE,TOP GAME MOBILE OFFLINE,",
category: 'games',
time: '09:50 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:50 PM',
link: '/top-nhung-game-offline-hap-dan-nhat-tren-di-dong-danh-cho-game-thu-dut-cap-hay-het-sach-4g-de-online',
component: 'page_20210629215009'},
{title: "8 gia đình \"có số má\" nhất thế giới anime, xứng danh \"cha ông mạnh, con cháu cũng phải mạnh\"",
description: "Theo bạn đâu là gia đình mạnh nhất trong thế giới anime?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/23/photo-1-16244438362121929745127.jpg',
alt: "GIA ĐÌNH ANIME,DRAGON BALL,KENGAN ASHURA,FULLMETAL ALCHEMIST,BAKI,GIA ĐÌNH JOESTAR,",
category: 'games',
time: '09:49 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:49 PM',
link: '/8-gia-dinh-co-so-ma-nhat-the-gioi-anime-xung-danh-cha-ong-manh-con-chau-cung-phai-manh',
component: 'page_20210629214910'},
{title: "Ragnarok – Trận chiến tận thế trong thần thoại Bắc Âu diễn ra như thế nào?",
description: "Theo truyền thuyết về Ragnarok, thế giới sẽ lại nổi lên một lần nữa, các vị thần còn sống sót sẽ gặp nhau và thế giới được tái sinh nhờ hai con người sót lại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/22/ragnarok-digital-16243352134241137803023.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,RAGNAROK,THẦN THOẠI BẮC ÂU,LỊCH SỬ,VĂN HÓA,LOKI,",
category: 'games',
time: '09:48 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:48 PM',
link: '/ragnarok-tran-chien-tan-the-trong-than-thoai-bac-au-dien-ra-nhu-the-nao',
component: 'page_20210629214836'},
{title: "Conan: Điểm nhanh 5 anh chàng đa tài thuộc \"Hội bạn thân ở Học viện Cảnh sát\", đáng tiếc chỉ 1 người còn sống",
description: "Hội bạn thân ở Học viện Cảnh sát gồm có Jinpei Matsuda, Rei Furuya, Wataru Date, Hiromitsu Morofushi và Kenji Hagiwara.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/22/photo-1-16243361326392054330609.jpg',
alt: "HAGIWARA KENJI,HỌC VIỆN CẢNH SÁT,WILD POLICE STORY,THÁM TỬ LỪNG DANH CONAN,DATE WATARU,MATSUDA JINPEI,",
category: 'games',
time: '09:48 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:48 PM',
link: '/conan-diem-nhanh-5-anh-chang-da-tai-thuoc-hoi-ban-than-o-hoc-vien-canh-sat-dang-tiec-chi-1-nguoi-con-song',
component: 'page_20210629214826'},
{title: "Pokémon có thể giao tiếp với con người bằng cách nào? Nói chuyện, tâm linh, trực giác,... đều được cả",
description: "Bạn đã bao giờ thắc mắc Pokémon có thể giao tiếp với con người bằng cách nào hay chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/19/photo-1-162409752103114445468.jpg',
alt: "POKÉMON,POKÉMON GIAO TIẾP VỚI CON NGƯỜI,POKÉMON NÓI CHUYỆN,MEOWTH,MEOWTH NÓI CHUYỆN,",
category: 'games',
time: '09:46 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:46 PM',
link: '/pokemon-co-the-giao-tiep-voi-con-nguoi-bang-cach-nao-noi-chuyen-tam-linh-truc-giac-deu-duoc-ca',
component: 'page_20210629214607'},
{title: "Top 10 võ tướng nổi tiếng và đáng sợ nhất trong lịch sử Tam Quốc",
description: "Những người như Lã Bố, Mã Siêu, Triệu Vân, Quan Vũ... đều chiếm được địa vị nhất định trong thời Tam Quốc nhờ vào võ công cao cường của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/19/photo-1-16241154185281758165110.jpg',
alt: "TAM QUỐC,VÕ TƯỚNG,TRIỆU VÂN,QUAN VŨ,TRƯƠNG PHI,LÃ BỐ,",
category: 'games',
time: '09:45 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:45 PM',
link: '/top-10-vo-tuong-noi-tieng-va-dang-so-nhat-trong-lich-su-tam-quoc',
component: 'page_20210629214503'},
{title: "Lá bài Rồng Trắng Mắt Xanh trị giá nghìn đô trong Yu-Gi-Oh! được mua hết sạch trong ngày đầu ra mắt",
description: "Điều này thêm một lần nữa chứng minh được sức hút của trò chơi cũng như tựa manga huyền thoại Yu-Gi-Oh!.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/19/bo1-16241186006802089246700.jpg',
alt: "GAME YU-GI-OH!,YU-GI-OH! MANGA,YU-GI-OH! TRADING CARD GAME,TRÒ CHƠI YU-GI-OH! TRADING CARD GAME,VUA TRÒ CHƠI YUGI,THẦN BÀI YUGI,MUTO YUGI,THẺ BÀI YUGI OH,",
category: 'games',
time: '09:44 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:44 PM',
link: '/la-bai-rong-trang-mat-xanh-tri-gia-nghin-do-trong-yu-gi-oh-duoc-mua-het-sach-trong-ngay-dau-ra-mat',
component: 'page_20210629214434'},
{title: "Điểm mặt 6 đứa con của \"Thần Điêu Đại Bịp\" Loki, đứa là quái vật kẻ thì chấp cả Thor",
description: "Với giới tính linh hoạt cùng khả năng thiên biến vạn hóa thành vô số hình dạng, Loki đã có tất cả 6 người con vô cùng \"quái dị\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/20/fe6-1624165713993278620488.jpg',
alt: "THẦN LỪA LỌC LOKI,BOX LOKI,TRAILER LOKI,LADY LOKI,PHẢN DIỆN LOKI,BIẾN THỂ CỦA LOKI,SERIES LOKI,NHÂN VẬT MỚI TRONG LOKI,BIẾN THỂ LOKI,",
category: 'games',
time: '09:44 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:44 PM',
link: '/diem-mat-6-dua-con-cua-than-dieu-dai-bip-loki-dua-la-quai-vat-ke-thi-chap-ca-thor',
component: 'page_20210629214415'},
{title: "Điểm mặt 4 manga bán chạy nhất nửa đầu năm 2021 tại Nhật Bản hưởng lợi từ phần chuyển thể anime xuất sắc",
description: "Mới đây nhất thì Oricon công bố đã công top 10 manga bán chạy nhất nửa đầu năm 2021 tại Nhật Bản. Bốn cái tên đứng đầu đều được cho là hưởng lợi từ phần chuyển thể anime vô cùng thành công.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/anu1-16239218593091146716359.jpg',
alt: "TOP MANGA,NHÂN VẬT MANGA,LỄ HỘI MANGA,TAM QUỐC MANGA,ANIME - MANGA,SHOP ANIME - MANGA,SHOP MANGA,ÔNG TỔ MANGA,GAME MANGA,ĐẠI CHIẾN MANGA,",
category: 'games',
time: '01:02 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:02 PM',
link: '/diem-mat-4-manga-ban-chay-nhat-nua-dau-nam-2021-tai-nhat-ban-huong-loi-tu-phan-chuyen-the-anime-xuat-sac',
component: 'page_20210618130226'},
{title: "Top 8 bộ truyện isekai có nội dung khó đỡ làm mưa làm gió năm 2021 (P.2)",
description: "Để tìm được nét độc đáo riêng giữa thị trường truyện isekai đang nở rộ, tác giả của những bộ truyện này đã quyết định nghĩ các ý tưởng siêu dị.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/the-karate-master-fights-truck-kun-16239215523831281995025.jpg',
alt: "ISEKAI,MANGA,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/top-8-bo-truyen-isekai-co-noi-dung-kho-do-lam-mua-lam-gio-nam-2021-p2',
component: 'page_20210618130157'},
{title: "Bản đồ trong open-world game có thể rộng đến mức nào? Những tiết lộ khiến game thủ sửng sốt",
description: "Đâu là giới hạn của thế giới trong trò chơi open-world? Đôi khi game thủ sẽ phải giật mình khi biết được sự thật này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/16/-16238526557041274300704.jpg',
alt: "KHÁM PHÁ,GAME ONLINE,GAME,TRÒ CHƠI ĐIỆN TỬ,OPEN-WORLD GAME,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/ban-do-trong-open-world-game-co-the-rong-den-muc-nao-nhung-tiet-lo-khien-game-thu-sung-sot',
component: 'page_20210618130145'},
{title: "Dragon Ball Super: Các fan cho rằng Ultra Instinct của Goku đã \"out meta\" khi dễ dàng bị Granola hạ gục",
description: "Dragon Ball Super chap 73 chứng kiến cảnh Goku kích hoạt Ultra Instinct để đối đầu với Granola nhưng vẫn bị chiến binh mạnh nhất vũ trụ đánh gục.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/go1-16239900225751533435803.jpg',
alt: "ANIME DRAGON BALL SUPER,MANGA DRAGON BALL SUPER,DRAGON BALL SUPER CHAP 73,SON GOKU,KẺ SỐNG SÓT GRANOLA,GOKU VS GRANOLA,CON MẮT CỦA GRANOLAH,PERFECT ULTRA INSTINCT,ULTRA INSTINCT,THIÊN SỨ WHIS,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/dragon-ball-super-cac-cho-rang-ultra-instinct-cua-goku-da-out-meta-khi-de-dang-bi-granola-ha-guc',
component: 'page_20210618130116'},
{title: "Nổi tiếng không kém One Piece thế nhưng 5 anime sau đây lại bị \"hắt hủi\" tại Việt Nam, toàn những cái tên lạ hoắc",
description: "Nổi tiếng không kém gì Pokémon, One Piece, Conan tại đất nước Nhật Bản thế nhưng 5 series anime sau đây lại không được quan tâm tại Việt Nam, thậm chí có những cái tên mà chẳng ai biết đến.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/ani1-16239912086061456127846.jpeg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,ĐÁNH GIÁ ANIME,REVIEW ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/noi-tieng-khong-kem-one-piece-the-nhung-5-anime-sau-day-lai-bi-hat-hui-tai-viet-nam-toan-nhung-cai-ten-la-hoac',
component: 'page_20210618130105'},
{title: "Top 10 anime đang phát sóng được đánh giá cao nhất hiện nay theo MyAnimeList",
description: "Anime mùa xuân năm nay không có nhiều tác phẩm ấn tượng bằng năm ngoái nhưng vẫn có những cái tên rất tuyệt vời cho bạn thưởng thức.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/odd-taxi-16240043903291086406425.jpg',
alt: "ANIME,TOP ANIME,ANIME HAY,ODD TAXI,TOKYO REVENGERS,THÁM TỬ LỪNG DANH CONAN,MEGALO BOX 2,",
category: 'games',
time: '01:00 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:00 PM',
link: '/top-10-anime-dang-phat-song-duoc-danh-gia-cao-nhat-hien-nay-theo-myanimelist',
component: 'page_20210618130016'},
{title: "Đây là 10 ý tưởng thú vị về Pokémon mà fan có thể chưa biết, cái tên ban đầu cũng rất cầu kỳ",
description: "Trước khi có tên chính thức là Pokémon, bạn có biết thương hiệu này được gọi là gì không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/photo-1-16239870877751018865523.jpg',
alt: "POKÉMON,CAPSULE MONSTERS,POLIWHIRL,GENGAR,PIKACHU,",
category: 'games',
time: '12:59 PM',
date: '18/06/2021',
timestamp: '18/06/2021 12:59 PM',
link: '/day-la-10-y-tuong-thu-vi-ve-pokemon-ma-fan-co-the-chua-biet-cai-ten-ban-dau-cung-rat-cau-ky',
component: 'page_20210618125919'},
{title: "Top 10 phản diện đáng nhớ trong phim hoạt hình Pokémon (P.2)",
description: "Trong anime, rất nhiều nhân vật phản diện tỏa sáng hơn so với những gì chúng được thể hiện trong game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/photo-1-1624034373653677183133.jpg',
alt: "POKÉMON,PHẢN DIỆN,",
category: 'games',
time: '12:59 PM',
date: '18/06/2021',
timestamp: '18/06/2021 12:59 PM',
link: '/top-10-phan-dien-dang-nho-trong-phim-hoat-hinh-pokemon-p2',
component: 'page_20210618125907'},
{title: "Top 10 phản diện đáng nhớ trong phim hoạt hình Pokémon (P.1)",
description: "Trong anime, rất nhiều nhân vật phản diện tỏa sáng hơn so với những gì chúng được thể hiện trong game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/photo-1-1623947652010101484898.jpg',
alt: "POKÉMON,PHẢN DIỆN,",
category: 'games',
time: '12:55 PM',
date: '18/06/2021',
timestamp: '18/06/2021 12:55 PM',
link: '/top-10-phan-dien-dang-nho-trong-phim-hoat-hinh-pokemon-p1',
component: 'page_20210618125537'},
{title: "Top 7 manga bóng đá giúp bạn giải trí trong thời gian chờ đợi vòng loại World Cup 2022 cuối cùng của đội tuyển Việt Nam",
description: "Tiếp bước Tsubasa, Itto, Fantasista vang danh một thời, 7 manga bóng đá sau đây là những siêu phẩm mà các fan của môn thể thao vua không nên bỏ qua trong khi chờ đợi vòng loại World Cup 2022 cuối cùng của đội tuyển Việt Nam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/ma1-16239080157491600788916.png',
alt: "Top Manga, Nhan Vat Manga, Le Hoi Manga, Anime Manga, Shop Manga, Game Manga, Shop Anime Manga, ",
category: 'games',
time: '02:07 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:07 PM',
link: '/top-7-manga-bong-da-giup-ban-giai-tri-trong-thoi-gian-cho-doi-vong-loai-world-cup-2022-cuoi-cung-cua-doi-tuyen-viet-nam',
component: 'page_20210617140743'},
{title: "Những nhân vật đáng yêu bậc nhất được nhiều game thủ ưa thích",
description: "Không ít các tựa game đình đám sở hữu hình ảnh nhân vật được nhân cách hóa với những đường nét ngộ nghĩnh, đáng yêu để tạo sự gần gũi, yêu thích.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/17/photo-1-16238729975221756858983.jpg',
alt: "Game, Nhan Vat, ",
category: 'games',
time: '02:04 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:04 PM',
link: '/nhung-nhan-vat-dang-yeu-bac-nhat-duoc-nhieu-game-thu-ua-thich',
component: 'page_20210617140409'},
{title: "Top game “kinh điển” hay nhất trên Mobile, có một phần Final Fantasy huyền thoại",
description: "Nếu bạn đang tìm kiếm những tựa game thuộc thể loại “kinh điển” trên nền tảng di động thì đừng bỏ qua những cái tên dưới đây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/16/photo-1-16238616599652048581583.jpg',
alt: "Top Game Mobile, Top Game, Tin Tuc Game, Final Fantasy, Final Fantasy Vii, Android, Ios, Titan Quest, ",
category: 'games',
time: '02:03 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:03 PM',
link: '/top-game-kinh-dien-hay-nhat-tren-mobile-co-mot-phan-final-fantasy-huyen-thoai',
component: 'page_20210617140357'},
{title: "Top 6 trò chơi open-world được game thủ bầu chọn hay nhất mọi thời đại",
description: "Game open-world (thế giới mở) là thể loại game được thiết kế theo cách để người chơi có thể đi lại trong một thế giới ảo và khá tự do trong việc quyết định khi nào hoàn thành các nhiệm vụ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/15/-162374721916734234660.jpg',
alt: "Kham Pha, Game Online, Game, Tro Choi Dien Tu, Gia Tri, ",
category: 'games',
time: '11:33 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:33 AM',
link: '/top-6-tro-choi-open-world-duoc-game-thu-bau-chon-hay-nhat-moi-thoi-dai',
component: 'page_20210616113318'},
{title: "Những tựa game bạo lực 18+ khiến người chơi phải tránh xa vì quá đáng sợ",
description: "Mức độ bạo lực của các tựa game dãn nhãn không dành cho người dưới 18 tuổi này có thể khiến không ít người phải sửng sốt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/14/i-medieval-ii-total-war-digital-16236590681821075933505.jpg',
alt: "Game, games, ",
category: 'games',
time: '11:31 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:31 AM',
link: '/nhung-tua-game-bao-luc-18-khien-nguoi-choi-phai-tranh-xa-vi-qua-dang-so',
component: 'page_20210616113102'},
{title: "Top 10 nữ thần trong game khiến bạn tin vào tình yêu đích thực",
description: "Đắm chìm trong cả thế giới ảo lẫn nhan sắc của các vị nữ thần...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/14/photo-1-16236834372831875923547.jpg',
alt: "Game, Nu Than, Cong Dong Mang, Game Dinh, Tin Tuc Game, ",
category: 'games',
time: '11:30 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:30 AM',
link: '/top-10-nu-than-trong-game-khien-ban-tin-vao-tinh-yeu-dich-thuc',
component: 'page_20210616113051'},
{title: "Cốt truyên đầy bi kịch ít người biết tới của Contra - tựa game quốc dân của thế hệ 8-9x",
description: "Rất nhiều người biết tới Contra, nhưng không phải ai cũng biết được cốt truyện bi kịch của nó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/13/photo-1-16235858486381724759341.jpg',
alt: "Game, Tin Tuc Game, Game Thu, ",
category: 'games',
time: '11:30 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:30 AM',
link: '/cot-truyen-day-bi-kich-it-nguoi-biet-toi-cua-contra-tua-game-quoc-dan-cua-the-he-8-9x',
component: 'page_20210616113041'},
{title: "Những tựa game hay tuyệt nhưng khiến game thủ phát cáu vì cái kết vớ vẩn",
description: "Các tựa game đình đám này từng được game thủ đánh giá rất chất lượng, thế nhưng cái kết của chúng khiến tất cả đều cảm thấy khó chịu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/4863-1623403239959306092727.jpg',
alt: "Game, The Legend Of Zelda Majoras Mask, Super Mario Bros 2, ",
category: 'games',
time: '09:28 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:28 AM',
link: '/nhung-tua-game-hay-tuyet-nhung-khien-game-thu-phat-cau-vi-cai-ket-vo-van',
component: 'page_20210614092810'},
{title: "Bên cạnh DOTA, D-DAY, đây chính là những custom map huyền thoại một thời của Warcraft 3 mà hiếm ai không biết tới (p1)",
description: "Bên cạnh hai cái tên siêu đỉnh ở trên, Warcraft 3 vẫn còn vô số những custom map thú vị khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/9/photo-1-16232297806691593115037.jpg',
alt: "Game, Game Thu, Tin Tuc Game, Warcraft 3, ",
category: 'games',
time: '09:27 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:27 AM',
link: '/ben-canh-dota-d-day-day-chinh-la-nhung-custom-map-huyen-thoai-mot-thoi-cua-warcraft-3-ma-hiem-ai-khong-biet-toi-p1',
component: 'page_20210614092757'},
{title: "Những Pokémon được thiết kế dựa trên nhân vật có thật",
description: "Pokémon được lấy cảm hứng rất nhiều từ đời thực, trong đó có cả những người thực sự tồn tại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/12/photo-1-1623514660251583445982.png',
alt: "Hitmonlee, Hitmonchan, Pokemon, Snorlax, ",
category: 'games',
time: '09:27 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:27 AM',
link: '/nhung-pokemon-duoc-thiet-ke-dua-tren-nhan-vat-co-that',
component: 'page_20210614092742'},
{title: "Top 10 tựa game hậu tận thế với bản đồ bạt ngàn cho bạn thỏa sức tung hoành (P.2)",
description: "Những tựa game cho anh em tha hồ giải trí.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/12/photo-1-16234995858851322117157.jpg',
alt: "Cong Dong Mang, Tin Tuc Game, Game, Game Thu, Sinh Ton, The Gioi Mo, ",
category: 'games',
time: '09:27 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:27 AM',
link: '/top-10-tua-game-hau-tan-the-voi-ban-do-bat-ngan-cho-ban-thoa-suc-tung-hoanh-p2',
component: 'page_20210614092729'},
{title: "10 game chiến thuật cho bạn làm thống soái, chỉ huy hàng trăm vạn quân (Phần 2)",
description: "Game chiến thuật luôn có sức hút kỳ lạ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/12/photo-1-16235122911981082464026.png',
alt: "Game Chien Thuat, Xay Dung Can Cu, The Ky 18, Tang Lop Quy Toc, Age Of Empires, ",
category: 'games',
time: '09:24 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:24 AM',
link: '/10-game-chien-thuat-cho-ban-lam-thong-soai-chi-huy-hang-tram-van-quan-phan-2',
component: 'page_20210614092454'},
{title: "Top 10 tựa game hậu tận thế với bản đồ bạt ngàn cho bạn thỏa sức tung hoành",
description: "Những tựa game cho anh em tha hồ giải trí.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/12/photo-1-16234965349261716902608.jpg',
alt: "Game, Cong Dong Mang, The Gioi Mo, Tin Tuc Game, Game Hay, ",
category: 'games',
time: '09:18 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:18 AM',
link: '/top-10-tua-game-hau-tan-the-voi-ban-do-bat-ngan-cho-ban-thoa-suc-tung-hoanh',
component: 'page_20210614091850'},
{title: "Những tựa game hay tuyệt nhưng khiến game thủ phát cáu vì cái kết vớ vẩn",
description: "Các tựa game đình đám này từng được game thủ đánh giá rất chất lượng, thế nhưng cái kết của chúng khiến tất cả đều cảm thấy khó chịu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/4863-1623403239959306092727.jpg',
alt: "Game, The Legend Of Zelda Majoras Mask, Super Mario Bros 2, ",
category: 'games',
time: '01:28 PM',
date: '11/06/2021',
timestamp: '11/06/2021 01:28 PM',
link: '/nhung-tua-game-hay-tuyet-nhung-khien-game-thu-phat-cau-vi-cai-ket-vo-van',
component: 'page_20210611132820'},
{title: "Bên cạnh DOTA, D-DAY, đây chính là những custom map huyền thoại một thời của Warcraft 3 mà hiếm ai không biết tới (p1)",
description: "Bên cạnh hai cái tên siêu đỉnh ở trên, Warcraft 3 vẫn còn vô số những custom map thú vị khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/9/photo-1-16232297806691593115037.jpg',
alt: "Game, Game Thu, Tin Tuc Game, Warcraft 3, ",
category: 'games',
time: '01:28 PM',
date: '11/06/2021',
timestamp: '11/06/2021 01:28 PM',
link: '/ben-canh-dota-d-day-day-chinh-la-nhung-custom-map-huyen-thoai-mot-thoi-cua-warcraft-3-ma-hiem-ai-khong-biet-toi-p1',
component: 'page_20210611132807'},
{title: "Game \"tập làm nông dân\" My Time At Portia sắp có mặt trên nền tảng di dộng",
description: "Một phiên bản vui tươi và mới lạ hơn so với bản gốc trên PC của trò chơi My Time At Portia.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/photo-1-16233453346472028627908.jpg',
alt: "My Time In Portia, Game Mobile, Ios, Android, ",
category: 'games',
time: '01:11 AM',
date: '11/06/2021',
timestamp: '11/06/2021 01:11 AM',
link: '/game-tap-lam-nong-dan-my-time-at-portia-sap-co-mat-tren-nen-tang-di-dong',
component: 'page_20210611011115'},
{title: "Xuất hiện tựa game bắn khủng long, sinh tồn trong thời tiền sử cực cuốn, hứa hẹn sẽ là siêu phẩm trong thời gian tới đây",
description: "Mặc dù mới đang ở bước Early Access, thế nhưng tựa game này đã nhận được rất nhiều những bình luận tích cực.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-1623147814364791725203.jpg',
alt: "Game, Game Thu, Tin Tuc Game, Second Exhibition, ",
category: 'games',
time: '01:18 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:18 PM',
link: '/xuat-hien-tua-game-ban-khung-long-sinh-ton-trong-thoi-tien-su-cuc-cuon-hua-hen-se-la-sieu-pham-trong-thoi-gian-toi-day',
component: 'page_20210610131850'},
{title: "10 game chiến thuật cho bạn làm thống soái, chỉ huy hàng trăm vạn quân (Phần 1)",
description: "Những tựa game chiến thuật luôn có sức cuốn hút kỳ lạ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/10/photo-1-1623342880158183739885.jpg',
alt: "Game Chien Thuat, Chien Tranh The Gioi, Company Of Heroes 2, Hearts Of Iron Iv, Total War Three Kingdoms, ",
category: 'games',
time: '01:18 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:18 PM',
link: '/10-game-chien-thuat-cho-ban-lam-thong-soai-chi-huy-hang-tram-van-quan-phan-1',
component: 'page_20210610131836'},
{title: "Vì sao Pokémon huyền thoại Mewtwo dùng hang Cerulean làm nhà?",
description: "Không nhiều nơi trên thế giới Pokemon có đủ sự phù hợp để Mewtwo có thể gọi là nhà.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/photo-1-16233447817771943324158.png',
alt: "Pokemon, Mewtwo, ",
category: 'games',
time: '01:18 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:18 PM',
link: '/vi-sao-pokemon-huyen-thoai-mewtwo-dung-cerulean-lam-nha-cua-no',
component: 'page_20210610131823'},
{title: "Top 5 mẹo giúp dàn PC yêu quý mát mẻ quanh năm",
description: "Nếu một ngày đẹp trời bạn thấy PC mình quá nóng thì đây sẽ là vài viết bạn nên đọc. Hy vọng nó cho bạn vài mẹo để giữ gìn chiếc PC yêu quý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/9/photo-1-1623254349277489354549.jpg',
alt: "Meo, Thu Thuat, Dan Pc, Cong Dong Mang, Tin Tuc Game, ",
category: 'games',
time: '09:25 PM',
date: '09/06/2021',
timestamp: '09/06/2021 09:25 PM',
link: '/top-5-meo-giup-dan-pc-yeu-quy-mat-me-quanh-nam',
component: 'page_20210609212527'},
{title: "Khó tin, game đỉnh của người Việt nhận mưa lời khen từ quốc tế, \"thèm khát\" 1 lần game mở miễn phí như này",
description: "Có những tựa game mà ngay cả cộng đồng game thủ cũng ít người biết được đó là sản phẩm do người Việt làm ra.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/10/-1623296014399440917318.jpg',
alt: "Shadow Of Death, App Store, Game Mien Phi, Tin Tuc Game, ",
category: 'games',
time: '09:25 PM',
date: '09/06/2021',
timestamp: '09/06/2021 09:25 PM',
link: '/game-dinh-cua-nguoi-viet-dang-mien-phi-tren-app-store-game-thu-nuoc-ngoai-het-loi-khen-ngoi',
component: 'page_20210609212503'},
{title: "Loạt các vũ khí trấn phái của các võ tướng trong Dynasty Warriors",
description: "Phương thiên họa kích hay bát xà mâu có lẽ đã rất quen thuộc với những người hâm mộ dòng game Tam Quốc.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/9/photo-1-1623236614015201115630.jpg',
alt: "Vu Khi, Tam Quoc, Dynasty Warriors, ",
category: 'games',
time: '09:42 AM',
date: '09/06/2021',
timestamp: '09/06/2021 09:42 AM',
link: '/loat-cac-vu-khi-tran-phai-cua-cac-vo-tuong-trong-dynasty-warriors',
component: 'page_20210609094259'},
{title: "Top 10 game bóng đá hay nhất cho anh em \"quẩy\" quên sầu",
description: "Hòa cùng không khí bóng đá ngập tràn, mời anh em thưởng thức những tựa game đỉnh nhất trong làng túc cầu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-16231625058421802311440.png',
alt: "Game Bong Da, Game Dinh, Game Hay, Tin Tuc Game, Cong Dong Mang, ",
category: 'games',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/top-10-game-bong-da-hay-nhat-cho-anh-em-quay-quen-sau',
component: 'page_20210608140437'},
{title: "SBTC Esports công bố đội hình tham dự VCS Mùa Hè 2021, Thầy Giáo Ba phán câu xanh rời: Trụ hạng được là hay rồi",
description: "Mất đi dàn sao của mình, SBTC Esports có vẻ như chỉ đặt một mục tiêu khiêm tốn tại VCS Mùa Hè 2021.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-1623169862035949007145.jpg',
alt: "Lien Minh Huyen Thoai, Sbtc Esports, games, Tin Game, Game, Lmht, Tin Tuc Game, Tin Lmht, Tin Lol, Vcs Mua He 2021, Vcs, Thay Giao Ba, ",
category: 'games',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/sbtc-esports-cong-bo-doi-hinh-tham-du-vcs-mua-he-2021-thay-giao-ba-phan-cau-xanh-roi-tru-hang-duoc-la-hay-roi',
component: 'page_20210608140425'},
{title: "Đấu Trường Chân Lý: Những đội hình bá đạo đầu mùa nhưng sử dụng bây giờ là \"tự hủy cực mạnh\"",
description: "Đây là những đội hình hồi đầu mùa 5 Đấu Trường Chân Lý đã tỏ ra vô cùng mạnh nhưng sử dụng ở hiện tại sẽ chỉ tụt rank không phanh.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/-1623132507124323700766.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Game, Tin Tuc Game, Tin Dtcl, Tin Tft, Tin Game, Riot Games, Riot, ",
category: 'games',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/dau-truong-chan-ly-nhung-doi-hinh-ba-dao-dau-mua-nhung-su-dung-bay-gio-la-tu-huy-cuc-manh',
component: 'page_20210608140412'},
{title: "Đấu Trường Chân Lý: Leo rank dễ dàng hơn với mẹo \"xoay trang bị\" cho chủ lực từ kỳ thủ Thách Đấu",
description: "Việc lựa chọn trang bị cho chủ lực phù hợp với từng trận đấu là yếu tố cực kỳ quan trọng trong Đấu Trường Chân Lý.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/8/draven-162312351755518684537.png',
alt: "Dau Truong Chan Ly, Cam Nang Dau Truong Chan Ly, Riot Games, Riot, Game, Tin Game, Draven, Vo Cuc Kiem, Mordekaiser, Hecarim, ",
category: 'games',
time: '2:03 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:03 PM',
link: '/dau-truong-chan-ly-leo-rank-de-dang-hon-voi-meo-xoay-trang-bi-cho-chu-luc-tu-ky-thu-thach-dau',
component: 'page_20210608140340'},
{title: "Đấu Trường Chân Lý: 3 đội hình bỗng nhiên được tin dùng trở lại nhờ khả năng khắc chế \"meta Thây Ma\"",
description: "Những đội hình dưới đây sẽ giúp game thủ Đấu Trường Chân Lý hạ gục những team-comp xoay quanh Thây Ma khá đơn giản.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/yasuo-16231430857111318751965.png',
alt: "Dau Truong Chan Ly, Cam Nang Dau Truong Chan Ly, Riot Games, Game, Riot, Tin Game, Mordekaiser, Trundle, Yasuo, Karma, Riven, ",
category: 'games',
time: '2:03 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:03 PM',
link: '/dau-truong-chan-ly-3-doi-hinh-bong-nhien-duoc-tin-dung-tro-lai-nho-kha-nang-khac-che-meta-thay-ma',
component: 'page_20210608140326'},
{title: "Đấu Trường Chân Lý: Tộc Thây Ma và Kayle nhận những nerf vô cùng nặng nề từ Riot tại bản 11.12",
description: "Khả năng lao vào tuyến sau của Thây Ma Biến Dị gần như sẽ bị loại bỏ khỏi Đấu Trường Chân Lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/4/photo-1-16227952697041628641001.jpg',
alt: "Dau Truong Chan Ly, Riot Games, Riot, Game, Tin Game, Riven, Kayle, Brand, Karma, Katarina, Leblanc, ",
category: 'games',
time: '10:40 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:40 AM',
link: '/dau-truong-chan-ly-toc-thay-ma-va-kayle-nhan-nhung-nerf-vo-cung-nang-ne-tu-riot-tai-ban-1112',
component: 'page_20210607104018'},
{title: "Game Mobile của người Việt làm ra đứng top 1 BXH cổng game nổi tiếng Trung Quốc, vượt qua cả ĐTCL Mobile",
description: "Mới đây, tựa game Mobile Fanmade của Việt Nam đã xuất sắc đứng vị trí top 1 bảng xếp hạng cổng game nổi tiếng của Trung Quốc.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/7/photo-1-1623048377886367284082.jpg',
alt: "Hang Rong Mobile, Hang Rong Online, Hang Rong, Vng, Dau Truong Chan Ly Mobile, Dtcl Mobile, Game, Tin Tuc Game, ",
category: 'games',
time: '10:34 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:34 AM',
link: '/game-mobile-fanmade-cua-viet-nam-dung-top-1-bxh-cong-game-noi-tieng-trung-quoc-vuot-qua-ca-dtcl-mobile',
component: 'page_20210607103429'},
{title: "Đấu Trường Chân Lý: 3 combo hoàn hảo giữa các quân cờ, item giúp game thủ hạ gục đối phương dễ dàng",
description: "Game thủ Đấu Trường Chân Lý có thể áp dụng những combo dưới đây để leo rank một cách vô cùng hiệu quả.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/4/28/-16195984600291477540285.jpg',
alt: "Dau Truong Chan Ly, Cam Nang Dau Truong Chan Ly, Riot Games, Riot, Game, Tin Game, Kennen, Ziggs, Thresh, Syndra, Warwick, ",
category: 'games',
time: '12:36 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:36 AM',
link: '/dau-truong-chan-ly-3-combo-hoan-hao-giua-cac-quan-co-item-giup-game-thu-ha-guc-doi-phuong-de-dang',
component: 'page_20210607003603'},
{title: "Đấu Trường Chân Lý: Không phải điều tốt, Kho Vũ Khí khả năng sẽ \"con dao hai lưỡi\" đối với game thủ",
description: "Chế độ Kho Vũ Khí nhiều khả năng sẽ khiến các game thủ “ôm hận” nếu không sử dụng hợp lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/7/-16230105223551699469966.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Kho Vu Khi, Riot Games, Riot, Tin Game, Tin Tuc Game, Tin Tft, Tin Dtcl, Tft, ",
category: 'games',
time: '12:35 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:35 AM',
link: '/dau-truong-chan-ly-khong-phai-dieu-tot-kho-vu-khi-kha-nang-se-con-dao-hai-luoi-doi-voi-game-thu',
component: 'page_20210607003553'},
{title: "LMHT: Cố gắng sửa lỗi của phép Trừng Phạt nhưng Riot Games lại vô tình tạo ra thêm 3 bug mới",
description: "Cộng đồng LMHT cũng phải lắc đầu ngán ngẩm với pha \"chữa lợn lành thành lợn què\" này của Riot Games.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/1/21/photo-1-16101246037061748195876-1611197465456703819752.jpg',
alt: "Lien Minh Huyen Thoai, Lmht, Riot Games, Riot, Game, Tin Game, Trung Phat, Loi Lmht, Tin Lmht, Tin Lol, Viego, ",
category: 'games',
time: '12:35 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:35 AM',
link: '/lmht-co-gang-sua-loi-cua-phep-trung-phat-nhung-riot-games-lai-vo-tinh-tao-ra-them-3-bug-moi',
component: 'page_20210607003527'},
{title: "Cộng đồng Steam phát sốt với game sinh tồn mới, toàn điểm 9 với 10, lại còn miễn phí 100%",
description: "Game sinh tồn Muck đang trở thành hiện tượng trên Steam.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/7/photo-1-1623043993514384383939.jpg',
alt: "Muck, Game Sinh Ton, Cau Hinh Toi Thieu, He Dieu Hanh, ",
category: 'games',
time: '12:33 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:33 AM',
link: '/cong-dong-steam-phat-sot-voi-game-sinh-ton-moi-toan-diem-9-voi-10-lai-con-mien-phi-100',
component: 'page_20210607003313'},
{title: "Hiker Games chính thức hé lộ giá bán gói donate từng phiên bản của tựa game 300475, bản rẻ nhất là 1.000.000 VND",
description: "Game thủ hãy chuẩn bị tiền để mua 300475.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/6/photo-1-1622912847306429666092.jpg',
alt: "Nha Phat Trien, Game Thuan Viet, Chien Dich Ho Chi Minh, 300475, Cong Dong Game Thu, ",
category: 'games',
time: '08:47 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:47 AM',
link: '/hiker-games-chinh-thuc-he-lo-gia-tung-phien-ban-cua-tua-game-300475-ban-re-nhat-chi-tu-500000-vnd',
component: 'page_20210606084722'},
{title: "LMHT xuất hiện hình thức gian lận xếp hạng mới, cộng đồng cho rằng nguyên nhân tới từ lỗ hổng client",
description: "Nhiều game thủ LMHT cũng xác nhận rằng hình thức gian lận này đang rất phổ biến ở mức rank Kim Cương - Cao Thủ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/6/photo-1-16229481139211967180953.jpg',
alt: "Lien Minh Huyen Thoai, Lmht, Riot Games, Riot, Game, Tin Game, Gian Lan Xep Hang, Client Lmht, Cong Dong Mang, ",
category: 'games',
time: '08:47 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:47 AM',
link: '/lmht-xuat-hien-hinh-thuc-gian-lan-xep-hang-moi-cong-dong-cho-rang-nguyen-nhan-toi-tu-lo-hong-client',
component: 'page_20210606084710'},
{title: "Đấu Trường Chân Lý: Mẹo hay từ Thách Đấu giúp chủ lực của bạn né hết các kỹ năng diện rộng nguy hiểm",
description: "Game thủ Đấu Trường Chân Lý có thể áp dụng ngay lập tức những cách sắp xếp đội hình dưới đây.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/5/volibear-16228629609371320914625.png',
alt: "Dau Truong Chan Ly, Riot Games, Cam Nang Dau Truong Chan Ly, Game, Riot, Tin Game, Teemo, Karma, Kennen, Volibear, Sett, ",
category: 'games',
time: '09:06 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:06 AM',
link: '/dau-truong-chan-ly-meo-hay-tu-thach-dau-giup-chu-luc-cua-ban-ne-het-cac-ky-nang-dien-rong-nguy-hiem',
component: 'page_20210605190617'},
{title: "Đấu Trường Chân Lý: Riot Mortdog gợi ý việc kết hợp từ nhiều mùa khiến game thủ \"phát cuồng\"",
description: "Sẽ khó có thể tượng tượng những quân cờ vốn đã “bá đạo” ở mùa 5 sẽ như thế nào nếu được mang những ưu điểm từ các Tộc/Hệ của chúng ở các mùa trước.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/5/-1622870886726486206593.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Riot Games, Tft, Riot, Tin Game, Tin Dtcl, ",
category: 'games',
time: '09:06 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:06 AM',
link: '/dau-truong-chan-ly-riot-mortdog-goi-y-viec-ket-hop-tu-nhieu-mua-khien-game-thu-phat-cuong',
component: 'page_20210605090601'},
{title: "“CS:GO Mobile” tiến hành thử nghiệm, đã có trên Google Play, game thủ nghi vấn ông lớn này phát hành tại VN?",
description: "Mới đây, một Fanpage tại Việt Nam đã đăng tải thông tin về tựa game được cho là rất giống với CS:GO và dự đoán một ông lớn sẽ phát hành.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/5/photo-1-16228904622902071607147.jpg',
alt: "Csgo, Csgo Mobile, The Origin Mission, Garena, Vng, Tin Tuc Game, Valve, ",
category: 'games',
time: '09:05 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:05 AM',
link: '/csgo-mobile-tien-hanh-thu-nghiem-da-co-tren-google-play-game-thu-nghi-van-ong-lon-nay-phat-hanh-tai-vn',
component: 'page_20210605090549'},
{title: "Ninja Gaiden chính thức trở lại vào tuần sau dành cho các game thủ đam mê \"chặt chém\"",
description: "Ninja Gaiden đã trở lại với ba tựa game dự kiến sẽ làm khó người chơi vào tháng 6 năm nay.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/5/photo-1-16228335521911854152638.jpg',
alt: "Ninja Gaiden, Koei Tecmo, Ninja Gaiden Master Collection, ",
category: 'games',
time: '09:04 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:04 AM',
link: '/ninja-gaiden-chinh-thuc-tro-lai-vao-tuan-sau-danh-cho-cac-game-thu-dam-me-chat-chem',
component: 'page_20210605090405'},
{title: "Valorant liên tục \"đạt đỉnh\", Riot hé lộ ra mắt phiên bản Mobile khiến game thủ phấn khích",
description: "Trung bình trong 1 tháng, số lượng người Valorant đã đạt tới đỉnh 14 triệu người chơi. Không chỉ dừng lại ở đó, Riot còn đang chuẩn bị mang Valorant lên điện thoại.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/4/photo-1-162279923339857968698.jpg',
alt: "Valorant, Riot, Tin Tuc Game, Cong Dong Mang, Valorant Mobile, ",
category: 'games',
time: '09:01 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:01 AM',
link: '/valorant-lien-tuc-dat-dinh-riot-he-lo-ra-mat-phien-ban-mobile-khien-game-thu-phan-khich',
component: 'page_20210605090159'},
{title: "Đấu Trường Chân Lý: Leo top là dễ với những đội hình tập hợp những \"Wonder Woman\" cực mạnh ở mùa 5",
description: "Nếu đã cảm thấy chán những công thức đội hình truyền thống, bạn hoàn toàn có thể trải nghiệm thử thách mới với những lối chơi tiêu chuẩn của riêng mình.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/4/-16227560516681426978234.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Doi Hinh Dtcl, Game, Tin Game, Tin Tuc Game, Tin Dtcl, Tft, Cam Nang Dtcl, ",
category: 'games',
time: '04:43 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:43:00',
link: '/dau-truong-chan-ly-leo-top-la-de-voi-nhung-doi-hinh-tap-hop-nhung-wonder-woman-cuc-manh-o-mua-5',
component: 'page_20210604044331'},
{title: "2 game miễn phí cho PlayStation 4 trong tháng 6",
description: "Với 2 trò chơi này, game thủ PS4 sẽ thoải mái giải trí trong những ngày nghỉ hè dài sắp tới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/photo-1-1622718503123169428884.jpg',
alt: "PlayStation",
category: 'games',
time: '07:00 PM',
date: '03/06/2021',
timestamp: '03/06/2021 19:00:00',
link: '/2-game-mien-phi-cho-playstation-4-trong-thang-6',
component: 'page_202106030700'},
{title: "Những tựa game hay nhất trong 10 năm qua theo bình chọn của game thủ",
description: "Hãy cùng các game thủ nhìn lại quãng đường 10 năm qua và điểm tên những tựa game được đánh giá hay nhất trong giai đoạn này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/2/photo-1-16226095087411103493431.jpg',
alt: "Game",
category: 'games',
time: '05:43 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:43:00',
link: '/nhung-tua-game-hay-nhat-trong-10-nam-qua-theo-binh-chon-cua-game-thu',
component: 'page_20210603054326'},
{title: "Hé lộ kết cấu cốt truyện của game thuần Việt 300475",
description: "300475 đang trong quá trình phát triển đúng dự kiến.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/photo-1-1622708652103288680148.jpg',
alt: "300475",
category: 'games',
time: '05:40 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:40:00',
link: '/he-lo-ket-cau-cot-truyen-cua-game-thuan-viet-300475',
component: 'page_20210603054034'},
{title: "Tải ngay game Turn-Based cực đỉnh Warhammer Quest: Silver Tower, miễn phí 100%",
description: "Warhammer Quest: Silver Tower là cái tên không thể bỏ qua với người yêu thích thể loại chiến thuật theo lượt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/3/photo-1-1622716375224530270223.jpg',
alt: "Warhammer Quest Silver Tower",
category: 'games',
time: '05:37 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:37:00',
link: '/tai-ngay-game-turn-based-cuc-dinh-warhammer-quest-silver-tower-mien-phi-100',
component: 'page_20210603053727'},
{title: "Đấu Trường Chân Lý: Học hỏi những mẹo độc khi chơi Sett \"One Punch Man\" từ kỳ thủ top 8 Thách Đấu",
description: "Những mẹo dưới đây sẽ giúp game thủ Đấu Trường Chân Lý leo rank hiệu quả hơn với việc chơi xoay quanh Sett.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/2/sett-1622624730224552537391.jpg',
alt: "Đấu Trường Chân Lý",
category: 'games',
time: '11:59 PM',
date: '02/06/2021',
timestamp: '02/06/2021 23:59:00',
link: '/dau-truong-chan-ly-hoc-hoi-nhung-meo-doc-khi-choi-sett-one-punch-man-tu-ky-thu-top-8-thach-dau',
component: 'page_202106021159'},
{title: "Hợp tác hãng xe McLaren, PUBG Mobile tung event hấp dẫn, siêu tốc bậc nhất làng game tháng 6",
description: "Có thể nói cơ hội trải nghiệm siêu xe McLaren ngoài đời chưa bao giờ dễ đến thế đối với cộng đồng game thủ PUBG Mobile trong mùa hè này.",
src: 'https://channel.mediacdn.vn/thumb_w/640/2021/6/2/photo-1-1622618650933658080991.jpg',
alt: "PUBG Mobile",
category: 'games',
time: '03:30 AM',
date: '02/06/2021',
timestamp: '02/06/2021 03:30:00',
link: '/hop-tac-hang-xe-mclaren-pubg-mobile-tung-event-hap-dan-sieu-toc-bac-nhat-lang-game-thang-6',
component: 'page_202106020330'},
]
