export const dataFeatured=[{title: "Tai tiếng là vậy, thế nhưng Amouranth - nữ streamer gợi cảm nhất thế giới từng gánh chịu những \"tiêu cực\" như thế này đây",
description: "Amouranth xứng đáng được coi là nữ streamer gợi cảm bậc nhất thế giới ở thời điểm hiện tại.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/28/photo-1-1630147468983542067572.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,STREAMER,NỮ STREAMER,AMOURANTH,",
category: 'images',
time: '08:53 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:53 AM',
link: '/tai-tieng-la-vay-the-nhung-amouranth-nu-streamer-goi-cam-nhat-the-gioi-tung-ganh-chiu-nhung-tieu-cuc-nhu-the-nay-day',
component: 'page_20210828085306'},
{title: "Top 7 nhân vật ích kỷ nhất Dragon Ball, Thần Hủy Diệt cũng góp mặt, Frieza thế mà chỉ đứng hạng 4",
description: "Tuyến nhân vật trong Dragon Ball rất đang dạng, bên cạnh những anh hùng luôn hết mình bảo vệ trái đất cũng có nhiều cá nhân bị thúc đẩy bởi lòng tham và sự ích kỷ của bản thân.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/28/photo-1-16301371086941660807152.jpg',
alt: "BABY,DRAGON BALL,ZAMASU,ĐỘI TRƯỞNG GINYU,FRIEZA,KID BUU,",
category: 'games',
time: '08:52 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:52 AM',
link: '/top-7-nhan-vat-ich-ky-nhat-dragon-ball-than-huy-diet-cung-gop-mat-frieza-the-ma-chi-dung-hang-4',
component: 'page_20210828085257'},
{title: "Nữ streamer xinh đẹp chia sẻ quan điểm gây sốc: \"Không có bạn thân khác giới đâu, rồi cũng XXX thôi\"",
description: "Góc nhìn của cô nàng streamer khiến cho không ít người xem phải ngạc nhiên vì độ thẳng thắn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/25/screenshot13-1629861951138743645937.png',
alt: "GÁI XINH,HOT GIRL,STREAMER,NỮ STREAMER,CỘNG ĐỒNG MẠNG,HWA-JEONG,",
category: 'images',
time: '08:52 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:52 AM',
link: '/nu-streamer-xinh-dep-chia-se-quan-diem-gay-soc-khong-co-ban-than-khac-gioi-dau-roi-cung-xxx-thoi',
component: 'page_20210828085231'},
]
export const dataContent=[{title: "One Piece: Điểm độc đáo từ \"Phái vô kiếm\" của Zoro, không thể gây sát thương nhưng vẫn thổi bay được kẻ thù",
description: "Kỹ thuật Mutoryu của Zoro có vẻ như không thể làm tổn thương mục tiêu?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/28/photo-1-163013486630761511058.jpg',
alt: "ZORO,MUTORYU,VÔ THUẬT KIẾM PHÁP,ONE PIECE,DRAGON TWISTER,TATSU MAKI,PHÁI VÔ KIẾM,",
category: 'games',
time: '08:52 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:52 AM',
link: '/one-piece-diem-doc-dao-tu-phai-vo-kiem-cua-zoro-khong-the-gay-sat-thuong-nhung-van-thoi-bay-duoc-ke-thu',
component: 'page_20210828085214'},
{title: "Bí ẩn lớn nhất chưa được tiết lộ trong Pokémon: Ông trùm của đội Hỏa Tiễn là ai?",
description: "Có lẽ ông trùm của đội Hỏa Tiễn Pokémon còn gây tò mò cho fan hâm mộ manga-anime hơn cả ông trùm của Tổ chức Áo đen trong Thám tử Conan",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/28/giovanni-pokemon-header-1630124818537845738155.jpeg',
alt: "KHÁM PHÁ,POKÉMON,ĐỘI HỎA TIỄN,",
category: 'games',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/bi-an-lon-nhat-chua-duoc-tiet-lo-trong-pokemon-ong-trum-cua-doi-hoa-tien-la-ai',
component: 'page_20210828085154'},
{title: "4 nàng Thái tử phi của màn ảnh Hoa ngữ: Bành Tiểu Nhiễm - Bạch Lộc khổ hết phần thiên hạ, trùm cuối lẳng lơ không ai bằng",
description: "Đây đều là những nàng Thái tử phi tuyệt sắc của màn ảnh Hoa ngữ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/24046443911759036162424096988002265811029896n-1630078071902378160743.jpg',
alt: "Bạch Lộc,Bành Tiểu Nhiễm,Trương Thiên Ái,Lý Khải Hinh,Trường An Như Cố (2021),Trường An Như Cố,Thái Tử Phi Thăng Chức Ký (2015),Đông Cung (2019),Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/4-nang-thai-tu-phi-cua-man-anh-hoa-ngu-banh-tieu-nhiem-bach-loc-kho-het-phan-thien-ha-trum-cuoi-lang-lo-khong-ai-bang',
component: 'page_20210828085141'},
{title: "Hôm trước bị soi lộ \"bé mỡ\" khi mặc corset, nay Địch Lệ Nhiệt Ba có màn phục thù ngầu đét, kín đáo mà xịn mịn hơn nhiều",
description: "Style lần này của Địch Lệ Nhiệt Ba trông xịn xò hơn hẳn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/28/23374400812384769432478543129952877572888388n-1630153654584493135419.jpg',
alt: "Địch Lệ Nhiệt Ba,sao Trung,sao Hoa ngữ,star style,corset,",
category: 'stars',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/hom-truoc-bi-soi-lo-be-mo-khi-mac-corset-nay-dich-le-nhiet-ba-co-man-phuc-thu-ngau-det-kin-dao-ma-xin-min-hon-nhieu',
component: 'page_20210828085124'},
{title: "10 cặp đôi lệch tuổi vẫn chemistry bùng nổ ở phim Hàn: Sốc nhất là Yoona với \"ông chú\" hơn 21 tuổi",
description: "Chênh lệch 10 - 12 tuổi đã là gì, một số cặp đôi phim Hàn còn lệch nhau tận 20 tuổi luôn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-1630141592063467129612.jpg',
alt: "kang ha neul,Kim Go Eun,ji chang wook,song seung hun,kim yoo jung,lee sun kyun,nam goong min,lee byung hun,Uhm Jung Hwa,Phim Hàn Quốc,",
category: 'stars',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/10-cap-doi-lech-tuoi-van-chemistry-bung-no-o-phim-han-soc-nhat-la-yoona-voi-ong-chu-hon-21-tuoi',
component: 'page_20210828085108'},
{title: "Người đàn ông nhặt được loại \"thần dược\" Tần Thủy Hoàng cất công tìm kiếm, quan chức địa phương lập tức đến nhà",
description: "Được mệnh danh là \"thịt cây\", loại dược thảo này được săn lùng với giá cao ngất ngưởng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/17/photo-1-16291914182371902590336.jpg',
alt: "vật thể lạ,trường sinh bất lão,",
category: 'news',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/nguoi-dan-ong-nhat-duoc-loai-than-duoc-tan-thuy-hoang-cat-cong-tim-kiem-quan-chuc-dia-phuong-lap-tuc-den-nha',
component: 'page_20210828085054'},
{title: "Bắt được “quái vật” không đầu không đuôi, ông lão bất ngờ vì lời chuyên gia: Đừng động tới, đó là bảo vật Tần Thủy Hoàng tìm kiếm!",
description: "“Quái vật” mà lão Dương bắt được có gì đáng sợ đến thế? Tại sao lại liên quan đến Tần Thủy Hoàng?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-16301428360372019310079.jpg',
alt: "Tần Thủy Hoàng,Sông Dương Tử,dây ruy băng,người hàng xóm,phát hiện ra,thời cổ đại,gốc tự do,tế bào gốc,phòng chống ung thư,",
category: 'news',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/bat-duoc-quai-vat-khong-dau-khong-duoi-ong-lao-bat-ngo-vi-loi-chuyen-gia-dung-dong-toi-do-la-bao-vat-tan-thuy-hoang-tim-kiem',
component: 'page_20210828085039'},
{title: "Thêm mì khô vị bò gà của Công ty Thiên Hương bị thu hồi ở Na Uy",
description: "Bộ Y tế vừa nhận được cảnh báo của Na Uy về sản phẩm mì khô vị bò gà của Công ty Thiên Hương bị thu hồi do có chứa chất ethylene oxide.",
src: 'https://afamilycdn.com/150157425591193600/2021/8/28/mi-an-lien-2-1630162263085167513131.jpg',
alt: "mì ăn liền,an toàn thực phẩm,tin nóng xã hội,",
category: 'news',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/them-mi-kho-vi-bo-ga-cua-cong-ty-thien-huong-bi-thu-hoi-o-na-uy',
component: 'page_20210828085030'},
{title: "8 ý tưởng biến ban công nhàm chán thành góc chill mùa dịch, cái nào cũng chất khỏi bàn",
description: "Đảm bảo tân trang xong bạn sẽ muốn \"làm tổ\" ở ban công mãi cho mà xem.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-1630139239385988586212.jpeg',
alt: "ban công,decor nhà,",
category: 'life',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/8-y-tuong-bien-ban-cong-nham-chan-thanh-goc-chill-mua-dich-cai-nao-cung-chat-khoi-ban',
component: 'page_20210828085014'},
{title: "Bùng nổ trào lưu NFT, game thủ Việt cần nắm rõ những yếu tố sau đây trước khi \"làm giàu\"",
description: "Các tựa game NFT nghe qua thì có vẻ đơn giản mà kiếm tiền dễ, nhưng chắc chắn điều này chỉ đúng với những ai thật sự hiểu về nó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-1629689106820390180406.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,BÀI VIẾT ĐÁNG CHÚ Ý,GAME NFT,",
category: 'games',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/bung-no-trao-luu-nft-game-thu-viet-can-nam-ro-nhung-yeu-to-sau-day-truoc-khi-quyet-tam-lam-giau',
component: 'page_20210828085003'},
{title: "Lăn lộn trên sóng rồi tự vạch áo cho người xem ngực, nữ streamer còn tự tin tuyên bố đang thả rông gây sốc",
description: "Khó mà đỡ nổi với độ phản cảm của cô nàng streamer này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/27/photo-1-16300466678291828865987.jpg',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,STREAMER,NỮ STREAMER,HAN-KYUNG,",
category: 'images',
time: '08:49 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:49 AM',
link: '/lan-lon-tren-song-roi-tu-vach-ao-cho-nguoi-xem-nguc-nu-streamer-con-tu-tin-tuyen-bo-dang-tha-rong-gay-soc',
component: 'page_20210828084932'},
{title: "Top 5 siêu đạo chích nổi tiếng bậc nhất trong thế giới anime, Kaito Kid vẫn chưa phải là số 1",
description: "Cùng chúng tôi điểm qua 5 nhân vật được cho là những siêu đạo chích nổi tiếng nhất trong thế giới anime dựa trên ý kiến bình chọn của nhiều khán giả.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/27/kai1-1630072791701609201231.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,NHÂN VẬT ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '08:49 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:49 AM',
link: '/top-5-sieu-dao-chich-noi-tieng-bac-nhat-trong-the-gioi-anime-kaito-kid-van-chua-phai-la-so-1',
component: 'page_20210828084920'},
{title: "Bộ Công thương xác minh thông tin mì Hảo Hảo bị Ireland thu hồi vì chứa chất cấm",
description: "Bộ Công thương cho hay, đang khẩn trương xác minh thông tin liên quan đến cảnh báo của Cơ quan An toàn thực phẩm Ireland về sản phẩm mì Hảo Hảo và miến Good của Acecook.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-16301136604021718582260.jpeg',
alt: "Bộ Công Thương,an toàn thực phẩm,mì ăn liền,Acecook Việt Nam,",
category: 'news',
time: '08:49 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:49 AM',
link: '/bo-cong-thuong-xac-minh-thong-tin-mi-hao-hao-bi-ireland-thu-hoi-vi-chua-chat-cam',
component: 'page_20210828084908'},
{title: "Mì Hảo Hảo bị Ireland thu hồi vì chứa chất có thể gây ung thư, Acecook Việt Nam nói gì?",
description: "Theo Cơ quan An toàn Thực phẩm Ireland, việc tiêu thụ các sản phẩm nhiễm Ethylene Oxide tuy không gây nguy hiểm cấp tính cho sức khỏe nhưng có thể gây ung thư nếu thường xuyên sử dụng trong thời gian dài. Do đó, người tiêu dùng cần hạn chế việc tiếp xúc với chất này.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/photo-1-16300763210751304648625.png',
alt: "tin nóng xã hội,Mì Hảo Hảo bị Ireland thu hồi,Mì Hảo Hảo chứa chất độc hại,",
category: 'news',
time: '08:48 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:48 AM',
link: '/mi-hao-hao-bi-ireland-thu-hoi-vi-chua-chat-co-the-gay-ung-thu-acecook-viet-nam-noi-gi',
component: 'page_20210828084852'},
{title: "Sốc tận óc với loạt gameshow đậm chất 18+ của Nhật Bản: Cởi đồ, khỏa thân ngay trên sóng truyền hình!",
description: "Nhiều gameshow Nhật Bản khiến khán giả phải đỏ mặt vì những tình tiết cực kỳ nhạy cảm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/photo-1-16300606420182064502388.jpg',
alt: "show nhật bản,tv show,gameshow 18+,Cao Thủ Cởi Đồ,Be Cute Or Get Pie,gây tranh cãi,nhạy cảm,sex,The Bum Game,Underessing Her With Your Eyes,Boiling Bath,",
category: 'stars',
time: '08:48 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:48 AM',
link: '/soc-tan-oc-voi-loat-gameshow-dam-chat-18-cua-nhat-ban-coi-do-khoa-than-ngay-tren-song-truyen-hinh',
component: 'page_20210828084837'},
{title: "Những custom map chống cửa từng một thời làm điên đảo làng game Việt, tới giờ chơi lại vẫn thấy hay",
description: "Những map này từng một thời lấy đi không ít thời gian của các game thủ Việt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/21/photo-1-1629527412470825721201.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,BÀI VIẾT ĐÁNG CHÚ Ý,",
category: 'games',
time: '08:48 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:48 AM',
link: '/nhung-custom-map-chong-cua-tung-mot-thoi-lam-dien-dao-lang-game-viet-toi-gio-choi-lai-van-thay-hay',
component: 'page_20210828084824'},
{title: "Triệu Vy bị \"phong sát\", nữ thần học đường trùng tên bỗng chốc được được tìm kiếm ráo riết",
description: "Cô nữ sinh Triệu Vy đình đám giới học đường, hiện đang chuẩn bị bước vào cuộc sống sinh viên năm 2 của một trường đại học ở TP.HCM.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/27/photo-1-1630061573861854895731.jpg',
alt: "HOT GIRL,HỌC ĐƯỜNG,TRIỆU VY,",
category: 'images',
time: '08:47 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:47 AM',
link: '/trieu-vy-bi-phong-sat-nu-than-hoc-duong-trung-ten-bong-choc-duoc-duoc-tim-kiem-rao-riet',
component: 'page_20210828084757'},
{title: "Chuyển hướng sang nghề bán nội y, bikini, nữ streamer xinh đẹp lên sóng tự cởi, lấy thân mình làm người mẫu để quảng cáo",
description: "Cô nàng streamer này có vẻ rất mát tay trong việc kinh doanh.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/21/photo-1-16295386686741460626838.jpg',
alt: "GÁI XINH,CỘNG ĐỒNG MẠNG,HOT GIRL,STREAMER,NỮ STREAMER,PARK MIN-JUNG,",
category: 'images',
time: '08:47 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:47 AM',
link: '/chuyen-huong-sang-nghe-ban-noi-y-bikini-nu-streamer-xinh-dep-len-song-tu-coi-lay-than-minh-lam-nguoi-mau-de-quang-cao',
component: 'page_20210828084734'},
{title: "Xuất hiện một nữ streamer Liên Quân khá bạo, tay vén áo đến sát “vòng 1” để xuất hiện khi livestream",
description: "Việc nhiều nữ streamer lựa chọn cách xuất hiện gợi cảm và sexy là điều không hề hiếm gặp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/27/16300347209321645670780-16300347338122089667155.jpg',
alt: "LIÊN QUÂN MOBILE,LIÊN QUÂN,STREAMER,GÁI XINH,LIVESTREAM,STREAM,CĐM,CỘNG ĐỒNG MẠNG,MẠNG XÃ HỘI,MXH,",
category: 'images',
time: '08:47 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:47 AM',
link: '/xuat-hien-mot-nu-streamer-lien-quan-kha-bao-tay-ven-ao-den-sat-vong-1-de-xuat-hien-khi-livestream',
component: 'page_20210828084714'},
{title: "Đúng là chỉ Jennie (BLACKPINK) làm được: Đang hóa \"bánh bèo\" trong trẻo, 5s sau sexy \"xịt máu\" vì lộ vòng 1 khủng trắng nõn",
description: "Vẻ đẹp ngây thơ pha lẫn với gợi cảm của Jennie (BLACKPINK) chính là lý do khiến fan u mê cô \"không lối thoát\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/e9tu-cowyaepq1u-16300567539681572243994.jpg',
alt: "jennie,Jennie (BlackPink),Blackpink,sao Hàn,nhan sắc sao Hàn,nhan sắc Jennie,blackpink summer diary 2021,idol Kpop,",
category: 'stars',
time: '08:47 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:47 AM',
link: '/dung-la-chi-jennie-blackpink-lam-duoc-dang-hoa-banh-beo-trong-treo-5s-sau-sexy-xit-mau-vi-lo-vong-1-khung-trang-non',
component: 'page_20210828084702'},
{title: "Sau bao năm bị chê để tóc mái không hợp, Jennie giờ đã phục thù thành công, thậm chí còn sang xịn không tưởng",
description: "Từng bị chê không hợp tóc mái, Jennie giờ đã chứng minh không có kiểu tóc gì cô không \"cân\" được.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/27/teaser-jennie-chinh-thuc-cong-bo-tro-lai-voi-toc-mai-van-cuc-ngau-d39dbc26-1630051282037281814165.jpeg',
alt: "jennie,star style,sao Hàn,tóc mái,tóc của sao,kiểu tóc,",
category: 'stars',
time: '08:46 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:46 AM',
link: '/sau-bao-nam-bi-che-de-toc-mai-khong-hop-jennie-gio-da-phuc-thu-thanh-cong-tham-chi-con-sang-xin-khong-tuong',
component: 'page_20210828084654'},
{title: "Sau tất cả scandal tai tiếng, streamer Chi Củ Cải xuất hiện như thiên thần, an yên chào đón tuổi mới",
description: "Nữ streamer lựa chọn một bộ váy trắng thanh khiết đón sinh nhật giản dị giữa mùa dịch.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/photo-3-163007302287994575510.png',
alt: "streamer,Vũ Ngọc Kim Chi,hot girl Hải Phòng,ảnh nhạy cảm,",
category: 'images',
time: '08:46 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:46 AM',
link: '/sau-tat-ca-scandal-tai-tieng-streamer-chi-cu-cai-xuat-hien-nhu-thien-than-an-yen-chao-don-tuoi-moi',
component: 'page_20210828084633'},
{title: "Loạt các mỹ nhân 18+ mới nổi đang \"leo rank\" đáng chú ý trong năm 2021",
description: "Các cái tên mới đã thu hút được ít nhiều sự chú ý của fan hâm mộ trong năm 2021 này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/25/meisakawakita2244376278807775391992017841227022585023356n-16298845681751525343476.jpg',
alt: "MINA KITANO,KANAN AMAMIYA,MEISA KAWAKITA,IDOL,",
category: 'images',
time: '08:46 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:46 AM',
link: '/loat-cac-my-nhan-18-moi-noi-dang-leo-rank-dang-chu-y-trong-nam-2021',
component: 'page_20210828084607'},
{title: "Loạt các mỹ nhân 18+ mới nổi đang \"leo rank\" đáng chú ý trong năm 2021 (P.2)",
description: "Các cái tên mới đã thu hút được ít nhiều sự chú ý của fan hâm mộ trong năm 2021 này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/-1629964673701503504772.jpg',
alt: "MINAMO,UMI YATSUGAKE,MOMOJIRI KANAME,IDOL,",
category: 'images',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/loat-cac-my-nhan-18-moi-noi-dang-leo-rank-dang-chu-y-trong-nam-2021-p2',
component: 'page_20210828084539'},
{title: "Các ác xà nổi tiếng trong thần thoại: Orochi thực ra cũng thường thôi!",
description: "Trước các ác xà này, Bát Kỳ Đại Xà Orochi gần nhiên không còn đáng sợ nữa",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/b2e60fbejti61-16299688029501760873260.jpg',
alt: "QUÁI VẬT,ÁC XÀ,THẦN THOẠI,",
category: 'games',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/cac-ac-xa-noi-tieng-trong-than-thoai-orochi-thuc-ra-cung-thuong-thoi',
component: 'page_20210828084530'},
{title: "Tải ngay loạt game mobile offline siêu hấp dẫn dưới đây, đã hay lại còn miễn phí 100%",
description: "Những tựa game này đảm bảo đủ ba tiêu chí “Ngon, Bổ và hoàn toàn Miễn Phí”.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/photo-1-16299532016221939272110.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/tai-ngay-loat-game-mobile-offline-sieu-hap-dan-duoi-day-da-hay-lai-con-mien-phi-100',
component: 'page_20210828084521'},
{title: "Kimetsu no Yaiba: Nezuko là con quỷ duy nhất đeo mõm tre, có phải nó đã giúp cô bé kiềm chế cơn khát máu?",
description: "Đã bao giờ các bạn thắc mắc tại sao sau khi hóa quỷ Nezuko phải đeo ống tre chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/tanjiro-holds-off-frenzied-nezuko-16299660015721702743896.jpg',
alt: "NEZUKO,MÕM TRE,ỐNG TRE,KIMETSU NO YAIBA,CON QUỶ NEZUKO,TANJIRO,",
category: 'games',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/kimetsu-no-yaiba-nezuko-la-con-quy-duy-nhat-deo-mom-tre-co-phai-no-da-giup-co-be-kiem-che-con-khat-mau',
component: 'page_20210828084512'},
{title: "Phim Việt có 1 loạt trang phục hớ hênh phản cảm, người xem thấy mà tức!",
description: "Không rõ vì ý đồ của stylist hay gì mà nhiều diễn viên phim Việt lại chọn áo quần rất phản cảm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/26/o-mat-truoc-nhung-kieu-trang-phuc-ho-henh-trong-phim-viet-khan-gia-xem-cung-phai-buc-xuc-thanh-bi-ng-i-ph-n-x-1629900656-675-width600height337-1629951892879832787625.jpeg',
alt: "phim Việt,phản cảm,lố lăng,hớ hênh,thời trang trong phim,Thanh Bi,Phi Thanh Vân,Liên Tít,",
category: 'stars',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/phim-viet-co-1-loat-trang-phuc-ho-henh-phan-cam-nguoi-xem-thay-ma-tuc',
component: 'page_20210828084503'},
{title: "Jennie thân hình chuẩn nhất, nhưng Lisa mới là thành viên body sang nhất BLACKPINK và đây là lý do!",
description: "Đến netizen khó tính nhất cũng không thể chê bai body cực phẩm của Lisa (BLACKPINK).",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/26/lalalalisam2405567842054567149545175623479004395616732n-16299425978411956553044.jpg',
alt: "lisa,lisa (blackpink),Blackpink,jennie,Jennie (BlackPink),body sao Hàn,idol Kpop,sao Hàn,",
category: 'stars',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/jennie-than-hinh-chuan-nhat-nhung-lisa-moi-la-thanh-vien-body-sang-nhat-blackpink-va-day-la-ly-do',
component: 'page_20210828084453'},
{title: "Não Bát Vàng là món thần khí gì mà khiến Ngộ Không kinh hồn bạt vía?",
description: "Não Bát Vàng vừa xuất hiện trong trailer của Black Myth: Wukong (Tôn Ngộ Không: Hắc Thần Thoại).",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/26/untitled-16299143366252134470419.png',
alt: "TÔN NGỘ KHÔNG,TỀ THIÊN ĐẠI THÁNH,BLACK MYTH: WUKONG,NÃO BÁT VÀNG,NGÔ THỪA ÂN,KIM CÔ BỔNG,",
category: 'games',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/nao-bat-vang-la-mon-than-khi-gi-ma-khien-ngo-khong-kinh-hon-bat-via',
component: 'page_20210828084435'},
{title: "15 tựa game nhập vai \"cày cuốc\" miễn phí trên Steam (P2)",
description: "Nhiều game nhập vai miễn phí đang đợi bạn trên Steam.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/25/photo-1-16299091925421354171177.jpg',
alt: "GAME NHẬP VAI,GAME MIỄN PHÍ,RUNESCAPE,GAME CÀY CUỐC,PHANTASY STAR ONLINE 2,TREE OF SAVIOR,",
category: 'games',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/15-tua-game-nhap-vai-cay-cuoc-mien-phi-tren-steam-p2',
component: 'page_20210828084423'},
{title: "Triệu Lệ Dĩnh và 2 lần gặp sự cố trang phục: Lúc tạo ra khoảnh khắc đẹp thổn thức, lúc bị \"ném đá\" không thương tiếc",
description: "Có thể nói đây là 2 sự cố trang phục đáng nhớ nhất của Triệu Lệ Dĩnh.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/25/photo-1-1618590780257433604819-1629897644752946975497.jpg',
alt: "triệu lệ dĩnh,sao Trung,sao Hoa ngữ,star style,trang sức,phụ kiện,sự cố trang phục,",
category: 'stars',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/trieu-le-dinh-va-2-lan-gap-su-co-trang-phuc-luc-tao-ra-khoanh-khac-dep-thon-thuc-luc-bi-nem-da-khong-thuong-tiec',
component: 'page_20210828084415'},
{title: "Ăn mừng T1 vào Chung kết LCK, nữ streamer \"siêu vòng 1\" kiêm bạn gái Canna lại chiêu đãi fan màn vũ đạo bốc lửa",
description: "Màn ăn mừng \"cháy quá cháy\" của cô nàng streamer khiến fan LCK được một phen phấn khích.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/25/photo-1-1629893331616434606806.jpg',
alt: "LIÊN MINH HUYỀN THOẠI,GUL-HYE,CANNA,GAME,TIN GAME,T1 CANNA,TIN TỨC GAME,TIN LMHT,TIN LOL,T1,LCK,",
category: 'images',
time: '08:43 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:43 AM',
link: '/an-mung-t1-vao-chung-ket-lck-nu-streamer-sieu-vong-1-kiem-ban-gai-canna-lai-chieu-dai-fan-man-vu-dao-boc-lua',
component: 'page_20210828084335'},
{title: "Top 15 tựa game thế giới mở hay nhất trên PC (P.2)",
description: "Các bạn đang muốn kiếm tìm những tựa game PC thế giới mở hay nhất để trải nghiệm cho khuây khỏa? Thì đây là những ứng cử viên không thể hợp lý hơn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/25/photo-1-1629864885974713433190.jpg',
alt: "GAME HAY,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,GAME THẾ GIỚI MỞ,TOP GAME,",
category: 'games',
time: '08:43 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:43 AM',
link: '/top-15-tua-game-the-gioi-mo-hay-nhat-tren-pc-p2',
component: 'page_20210828084326'},
{title: "Khoe ảnh 18+ quá sexy, bạn gái mai mối của ViruSs bị nghi ngờ nâng cấp vòng 3",
description: "Đây không phải lần đầu tiên hot girl được mai mối với ViruSs bị đồn can thiệp dao kéo.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/25/photo-1-1629866594960135006910.jpg',
alt: "VIRUSS,BẠN GÁI,SUNNA,HOT GIRL,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '08:43 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:43 AM',
link: '/khoe-anh-18-qua-sexy-ban-gai-mai-moi-cua-viruss-bi-nghi-ngo-nang-cap-vong-3',
component: 'page_20210828084304'},
{title: "Ra mắt tác phẩm kỷ niệm hơn 3 tiếng, Yua Mikami vẫn thua xa đàn em vừa xấu vừa kém gợi cảm",
description: "Yua Mikami thường được đánh giá là hot girl top đầu, thế nhưng những bộ phim của cô nàng chưa hẳn đã có doanh thu quá vượt trội.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/screenshot12-1629430799020653400506.png',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,YUA MIKAMI,PHIM AV,AV,PHIM 18+,",
category: 'images',
time: '08:42 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:42 AM',
link: '/ra-mat-tac-pham-ky-niem-hon-3-tieng-yua-mikami-van-thua-xa-dan-em-vua-xau-vua-kem-goi-cam',
component: 'page_20210828084235'},
{title: "Top 15 tựa game thế giới mở hay nhất trên PC",
description: "Các bạn đang muốn kiếm tìm những tựa game PC thế giới mở hay nhất để trải nghiệm cho khuây khỏa? Thì đây là những ứng cử viên không thể hợp lý hơn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/24/photo-1-16298134287782048916120.jpg',
alt: "GAME,GAME HAY,GAME ĐỈNH,GAME THẾ GIỚI MỞ,GAME PC,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '08:42 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:42 AM',
link: '/top-15-tua-game-the-gioi-mo-hay-nhat-tren-pc',
component: 'page_20210828084224'},
{title: "Bà Tưng khoe đi hái rau nhưng đập vào mắt người xem là vòng 1 phồn thực đẫy đà",
description: "Dân mạng lại tiếp tục được dịp chứng kiến màn khoe khéo vòng 1 của bà Tưng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/25/2298330403814295366774143821396552448236996n-1629894357160964238107.jpeg',
alt: "Hiện tượng mạng,lê thị huyền anh,bà tưng,sexy,khoe vòng 1,",
category: 'images',
time: '08:41 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:41 AM',
link: '/ba-tung-khoe-di-hai-rau-nhung-dap-vao-mat-nguoi-xem-la-vong-1-phon-thuc-day-da',
component: 'page_20210828084146'},
{title: "Bức tranh ma quái “càng xem càng rợn” trong Bảo tàng Quốc gia Trung Quốc: Phóng to 3 lần hậu thế mới hiểu lý do!",
description: "Nhiều khách tham quan thú nhận họ cảm thấy rợn người khi nhìn thẳng vào bức họa “Nhòm cửa thấy quỷ” trong Bảo tàng Quốc gia Trung Quốc.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/24/photo-1-16297990658001810637179.png',
alt: "Quốc gia Trung Quốc,khách tham quan,Quảng trường Thiên An Môn,Thiên An Môn,tác phẩm nghệ thuật,Lịch sử Trung Quốc,Viện bảo tàng,thiếu nữ xinh đẹp,ô cửa sổ,người đàn ông,liêu trai chí dị,độc giả Việt Nam,thói hư tật xấu,bồ tùng linh,cô gái xinh đẹp,",
category: 'news',
time: '08:41 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:41 AM',
link: '/buc-tranh-ma-quai-cang-xem-cang-ron-trong-bao-tang-quoc-gia-trung-quoc-phong-to-3-lan-hau-the-moi-hieu-ly-do',
component: 'page_20210828084129'},
{title: "Vượt sâu Yua Mikami, tân binh sinh năm 1999 trở thành cái tên 18+ ăn khách nhất trong năm 2021",
description: "Sau 3 tháng kể từ ngày trở lại, Saika Kawakita vẫn tiếp tục \"kiến tạo\" nên những kỷ lục mới.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/24/photo-1-1629789465491316997920.jpeg',
alt: "SAIKA KAWAKITA,IDOL,",
category: 'images',
time: '08:41 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:41 AM',
link: '/vuot-sau-yua-mikami-tan-binh-sinh-nam-1999-tro-thanh-cai-ten-18-an-khach-nhat-trong-nam-2021',
component: 'page_20210828084112'},
{title: "Modder người Nga độ PC thành chiếc xe tăng Stormblade trong Warhammer 40K nặng 20kg, động cơ chạy bằng CPU i9 và RTX 2060",
description: "Mời các bạn chiêm ngưỡng độ đẹp và ngầu của chiếc PC Stormblade trong dòng game Warhammer 40K của một modder người Nga làm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/24/photo-1-1629811963238840550034.jpg',
alt: "DÀN PC,STORMBLADE,MEGOLET,WARHAMMER 40K,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '08:41 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:41 AM',
link: '/modder-nguoi-nga-do-pc-thanh-chiec-xe-tang-stormblade-trong-warhammer-40k-nang-20kg-dong-co-chay-bang-cpu-i9-va-rtx-2060',
component: 'page_20210828084101'},
{title: "Show thực tế sao nữ Hàn muốn né nhất: Sơ hở là lộ mặt mộc nhận không ra!",
description: "Khi tham gia Law Of The Jungle, các sao nữ Hàn Quốc bắt buộc không được trang điểm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/24/photo-9-16297896883171699901946.png',
alt: "Mặt mộc,không trang điểm,sao Hàn,idol,Kpop,hani,TWICE,exid,gfriend,Law of the Jungle,",
category: 'stars',
time: '08:40 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:40 AM',
link: '/show-thuc-te-sao-nu-han-muon-ne-nhat-so-ho-la-lo-mat-moc-nhan-khong-ra',
component: 'page_20210828084046'},
{title: "Cứ ngọt ngào mọi lúc thế này, có gọi Joy (Red Velvet) là \"crush quốc dân\" cũng chẳng sai!",
description: "Style của Joy ngày càng biến hoá ngọt ngào, tươi mới. Phải chăng đây là điểm then chốt khiến Crush sa vào lưới tình?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/24/photo-1-16297900783842105719095.jpg',
alt: "Joy (Red Velvet),joy hẹn hò crush,sao Hàn,star style,sao mặc đẹp,tips mặc đẹp,mặc đẹp như sao Hàn,red velvet,váy hoa,váy caro,",
category: 'stars',
time: '08:40 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:40 AM',
link: '/cu-ngot-ngao-moi-luc-the-nay-co-goi-joy-red-velvet-la-crush-quoc-dan-cung-chang-sai',
component: 'page_20210828084034'},
{title: "Lên sóng với hình tượng cô giáo gợi cảm, rồi lại xuất hiện \"bàn tay hư\" ở điểm nhạy cảm, nữ streamer ngây thơ khiến fan tá hỏa",
description: "Hình ảnh của cô nàng streamer đang thu hút được sự chú ý của rất nhiều người.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/17/photo-1-16291975215552135591686.jpg',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,NỮ STREAMER,STREAMER,HAN-KYUNG,",
category: 'images',
time: '08:39 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:39 AM',
link: '/len-song-voi-hinh-tuong-co-giao-goi-cam-roi-lai-xuat-hien-ban-tay-hu-o-diem-nhay-cam-nu-streamer-ngay-tho-khien-fan-ta-hoa',
component: 'page_20210828083957'},
{title: "So độ \"đạt chuẩn\" giữa khẩu phần ăn của tuyển thủ và “combo\" ngồi net game thủ Việt?",
description: "Ở Việt Nam, chế độ dinh dưỡng tuyển thủ vẫn chưa thấy sự chuyên nghiệp hoá.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-1629729907579189092771.jpg',
alt: "BÀI VIẾT ĐÁNG CHÚ Ý,GAME THỦ,KHẨU PHẦN ĂN,TUYỂN THỦ,COMBO NGỒI NET,",
category: 'games',
time: '08:39 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:39 AM',
link: '/so-do-dat-chuan-giua-khau-phan-an-cua-tuyen-thu-va-combo-ngoi-net-game-thu-viet',
component: 'page_20210828083948'},
{title: "Ngất ngây trước vẻ đẹp của các nhân vật Genshin Impact phiên bản đời thật",
description: "Một vẻ đẹp lạ lùng đến từ các nhân vật Genshin Impact.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-16297299772701609973263.jpg',
alt: "GENSHIN IMPACT,NHÀ PHÁT TRIỂN,KHO ỨNG DỤNG,TẠO HÌNH NHÂN VẬT,",
category: 'images',
time: '08:39 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:39 AM',
link: '/ngat-ngay-truoc-ve-dep-cua-cac-nhan-vat-genshin-impact-phien-ban-doi-that',
component: 'page_20210828083908'},
{title: "Ai là người đã cấm các Eternals không được giúp Avengers đánh bại Thanos?",
description: "Khi đến Trái Đất từ 7000 năm trước, Eternals đã được \"bề trên\" ra lệnh không được can thiệp vào những mâu thuẫn của nhân loại, trừ khi những mâu thuẫn đó có liên quan đến Deviants. Vậy ai đủ khả năng khiến cho các Eternal hùng mạnh phải nghe lời răm rắp như vậy?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-16297279484901831118499.jpg',
alt: "ETERNALS,THANOS,AVENGERS: INFINITY WAR,AVENGERS: ENDGAME,DEVIANTS,MARVEL,",
category: 'news',
time: '08:38 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:38 AM',
link: '/ai-la-nguoi-da-cam-cac-eternals-khong-duoc-giup-avengers-danh-bai-thanos',
component: 'page_20210828083854'},
{title: "Bị kẻ gian rao bán cả nghìn ảnh 18+, nàng hot girl \"bay màu\" luôn tài khoản MXH hàng trăm nghìn follow",
description: "Thậm chí sau khi nàng hot girl này lập lại tài khoản mới thì nó cũng bị xóa.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/23/photo-1-16297003717821152294862-16297003818761868140100.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '08:38 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:38 AM',
link: '/bi-ke-gian-rao-ban-ca-nghin-anh-18-nang-hot-girl-bay-mau-luon-tai-khoan-mxh-hang-tram-nghin-follow',
component: 'page_20210828083814'},
{title: "Làm clip Lookbook, khoe nội y và bikini trên sóng với định dạng 4K siêu nét, nữ YouTuber khiến fan \"mất máu\" vì quá gợi cảm",
description: "Bảo sao mà lượng subs cũng như view của cô nàng YouTuber cứ thế lên vèo vèo.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/16/photo-1-16290847282491807549886.jpg',
alt: "GÁI XINH,HOT GIRL,YOUTUBER,CỘNG ĐỒNG MẠNG,NỮ YOUTUBER,GGOTBBANG,",
category: 'images',
time: '08:37 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:37 AM',
link: '/lam-clip-lookbook-khoe-noi-y-va-bikini-tren-song-voi-dinh-dang-4k-sieu-net-nu-youtuber-khien-fan-mat-mau-vi-qua-goi-cam',
component: 'page_20210828083750'},
{title: "Top 10 tựa game hành động phiêu lưu hay nhất trên PC (P.2)",
description: "Nếu anh em là tín đồ của thể loại game phiêu lưu, hành động, khám phá... thì chắc chắn không thể bỏ qua 10 cái tên vô cùng đình đám này rồi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/23/photo-1-1629660434065250463231.png',
alt: "GAME,GAME ĐỈNH,GAME HAY,TOP GAME,PHIÊU LƯU,GAME HÀNH ĐỘNG,THẾ GIỚI GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '08:37 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:37 AM',
link: '/top-10-tua-game-hanh-dong-phieu-luu-hay-nhat-tren-pc-p2',
component: 'page_20210828083737'},
{title: "Nhảy quá \"căng\" trên sóng, nữ streamer gặp sự cố tụt áo, lộ luôn cả \"chấm huyền bí\" khiến cộng đồng mạng tranh cãi dữ dội",
description: "Rốt cục thì lỗi do cô nàng streamer hay chiếc áo phản chủ?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/19/99e3b5495cd52b0d15-16293526073241216977222.jpg',
alt: "GÁI XINH,HOT GIRL,STREAMER,NỮ STREAMER,CHEONGJU,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '08:37 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:37 AM',
link: '/nhay-qua-cang-tren-song-nu-streamer-gap-su-co-tut-ao-lo-luon-ca-cham-huyen-bi-khien-cong-dong-mang-tranh-cai-du-doi',
component: 'page_20210828083706'},
{title: "Ngôi biệt thự đẹp tựa như bước ra từ thước phim điện ảnh khiến dân mạng trầm trồ không ngớt",
description: "Căn nhà được xây với tổng diện tích 250m2, gồm 3 phòng ngủ, với kiến trúc mang phong cách Bắc Mỹ: Phóng khoáng, mạnh mẽ và hoang dã khiến ai nhìn vào cũng phải xuýt xoa.",
src: 'https://kenh14cdn.com/thumb_w/800/pr/2021/collage-1629710248346833549437-0-0-599-958-crop-1629710256340-63765334214461.jpg',
alt: "Biệt thự đẹp,phim điện ảnh,đáp ứng nhu cầu,",
category: 'life',
time: '08:36 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:36 AM',
link: '/ngoi-biet-thu-dep-tua-nhu-buoc-ra-tu-thuoc-phim-dien-anh-khien-dan-mang-tram-tro-khong-ngot',
component: 'page_20210828083638'},
{title: "Top 10 tựa game hành động phiêu lưu hay nhất trên PC",
description: "Nếu anh em là tín đồ của thể loại game phiêu lưu, hành động, khám phá... thì chắc chắn không thể bỏ qua 10 cái tên vô cùng đình đám này rồi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-1629659807918925258579.jpg',
alt: "GAME HAY,GAME ĐỈNH,PHIÊU LƯU,TOP GAME,HÀNH ĐỘNG,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '08:36 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:36 AM',
link: '/top-10-tua-game-hanh-dong-phieu-luu-hay-nhat-tren-pc',
component: 'page_20210828083619'},
{title: "Loạt game hấp dẫn đưa người chơi vào vai quái vật tàn sát không gớm tay",
description: "Không phải lúc nào vào vai anh hùng hiệp nghĩa cũng thú vị cả, vào vai phản diện thú vị hơn nhiều.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/23/photo-1-162965254098123380770.jpg',
alt: "GAME,CARRION,FRIDAY THE 13TH,AMONG US,",
category: 'games',
time: '08:35 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:35 AM',
link: '/loat-game-hap-dan-dua-nguoi-choi-vao-vai-quai-vat-tan-sat-khong-gom-tay',
component: 'page_20210828083559'},
{title: "Mỹ nhân Hoa ngữ đóng cảnh hôn khi chưa tròn 18: Trịnh Sảng mãnh liệt suýt gãy mũi, cô cuối gây sốc vì mới 12 tuổi!",
description: "Trịnh Sảng thực hiện cảnh hôn đầu đời khi mới 17 tuổi, thế nhưng đó chưa phải \"kỷ lục\" trẻ nhất Cbiz.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/22/photo-1-1629602143047901615631.jpeg',
alt: "Trịnh Sảng,Lý Lan Địch,Tưởng Y Y,ngô lỗi,Tiếu ngạo giang hồ,Thiên Dục,Lý Tiểu Lộ,Cùng Ngắm Mưa Sao Băng,Bí Quả,cảnh hôn,phim Hoa ngữ,phim trung quốc,Phim truyền hình,phim điện ảnh,Trương Hàn,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '08:35 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:35 AM',
link: '/my-nhan-hoa-ngu-dong-canh-hon-khi-chua-tron-18-trinh-sang-manh-liet-suyt-gay-mui-co-cuoi-gay-soc-vi-moi-12-tuoi',
component: 'page_20210828083544'},
{title: "Giữa nghi vấn \"toang\" với đại gia Đức Huy, gái đẹp 2k2 đăng ảnh sexy tột bậc nhưng netizen chỉ chú ý một điểm lạ",
description: "Phải chăng đây chính là điểm khiến Cẩm Đan phải đăng đi đăng lại đến 2 lần dù chỉ có một bức ảnh?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/22/ncamdan1932881402576444861371641449628682681795234n-16296462880161937188610.jpg',
alt: "đại gia Đức Huy,cẩm đan,hẹn hò,tình tin đồn,gái đẹp,chỉnh ảnh,Photoshop,",
category: 'images',
time: '08:35 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:35 AM',
link: '/giua-nghi-van-toang-voi-dai-gia-duc-huy-gai-dep-2k2-dang-anh-sexy-tot-bac-nhung-netizen-chi-chu-y-mot-diem-la',
component: 'page_20210828083519'},
{title: "Bị anti mỉa mai vòng một \"xệ\" tới rốn, hot girl Hải Dương tức tối vén áo, phản pháo ngược lại bình luận khiếm nhã",
description: "Cô nàng hot girl này đang là tâm điểm sau liên tiếp những tình huống gây tranh cãi trong thời gian gần đây.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/22/photo-1-16296080475871428709858.jpg',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,VÕ THỊ THU TRANG,",
category: 'images',
time: '08:34 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:34 AM',
link: '/bi-anti-mia-mai-vong-mot-xe-toi-ron-hot-girl-hai-duong-tuc-toi-ven-ao-phan-phao-nguoc-lai-binh-luan-khiem-nha',
component: 'page_20210828083452'},
{title: "Khoe cơ thể ướt át tại bãi biển trên sóng, nữ streamer nóng bỏng ngay lập tức nhận án cấm kênh",
description: "Đây có thể nói là pha câu view \"đi vào lòng đất\" của nữ streamer này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/22/photo-1-16296047438531544690358.jpg',
alt: "GÁI XINH,HOT GIRL,STREAMER,NỮ STREAMER,",
category: 'images',
time: '08:34 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:34 AM',
link: '/khoe-co-the-uot-at-tai-bai-bien-tren-song-nu-streamer-nong-bong-ngay-lap-tuc-nhan-an-cam-kenh',
component: 'page_20210828083426'},
{title: "Conan và những đứa trẻ \"không được phép lớn lên\" trong thế giới anime/manga",
description: "Mặc dù các mùa thay đổi và nhiều năm trôi qua trong loạt phim hoạt hình, nhưng các nhân vật này dường như không bao giờ già đi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/22/ash-ketchum-in-front-of-alain-kalos-league-from-pokemon-1629612533169112262560.jpg',
alt: "ASH KETCHUM,CONAN,CRAYON SHIN-CHAN,NHÂN VẬT ANIME MÃI KHÔNG LỚN,NHÂN VẬT HOẠT HÌNH,DORAEMON,NOBITA,",
category: 'games',
time: '08:34 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:34 AM',
link: '/conan-va-nhung-dua-tre-khong-duoc-phep-lon-len-trong-the-gioi-anime-manga',
component: 'page_20210828083415'},
]
export const dataContent02=[{title: "Cận cảnh bếp Hoàng Cầm của bộ đội Việt Nam: Há hốc mồm vì độ sáng tạo, hiệu quả cực kỳ đáng kinh ngạc",
description: "Chắc hẳn chúng ta đã từng nhiều lần nghe nhắc tới bếp Hoàng Cầm cùng công dụng \"kỳ diệu\" của nó. Thế nhưng đã bao giờ bạn tận mắt chứng kiến sự hoạt động của công cụ đặc biệt này chưa?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/22/1-16296246911821388079108.jpeg',
alt: "bếp Hoàng Câm,bộ đội,nấu bằng bếp Hoàng Cầm,bộ đội Việt Nam,Bộ đội căng mình giúp dân chống dịch,",
category: 'news',
time: '08:33 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:33 AM',
link: '/can-canh-bep-hoang-cam-cua-bo-doi-viet-nam-ha-hoc-mom-vi-do-sang-tao-hieu-qua-cuc-ky-dang-kinh-ngac',
component: 'page_20210828083339'},
{title: "7 nàng ma nữ Thiện Nữ U Hồn đẹp xuất chúng: Nhiệt Ba - Lưu Diệc Phi đều lép vế trước \"tường thành nhan sắc Hong Kong\"",
description: "Lưu Diệc Phi có khí chất thần tiên nhưng khi đóng vai ma nữ Nhiếp Tiểu Thiến lại thua xa đàn chị đình đám này.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/18/2c21baf2187e6dd577825d0b15c373b0-162927355140225107415.jpeg',
alt: "Nhiếp Tiểu Thiến,Lưu Diệc Phi,thiện nữ u hồn,Dương Mịch,trương gia nghê,Vương Tổ Hiền,Từ Hy Viên,Chung Sở Hy,Địch Lệ Nhiệt Ba,Tân Thiện Nữ U Hồn,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '08:33 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:33 AM',
link: '/7-nang-ma-nu-thien-nu-u-hon-dep-xuat-chung-nhiet-ba-luu-diec-phi-deu-lep-ve-truoc-tuong-thanh-nhan-sac-hong-kong',
component: 'page_20210828083327'},
{title: "Triệu Lệ Dĩnh hôm nay trẻ thế, nhìn như thiếu nữ cấp 3 chứ ai nghĩ đã U40 rồi!",
description: "Triệu Lệ Dĩnh khiến fan mừng điên lên bởi vẻ ngoài quá mức trẻ trung, xinh xắn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/22/2403249703660049818212931320835913294549647n-1629621502768229377509.jpg',
alt: "star style,sao mặc gì,high teen,triệu lệ dĩnh,sao Cbiz,nữ diễn viên Trung Quốc,happy camp,",
category: 'stars',
time: '08:33 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:33 AM',
link: '/trieu-le-dinh-hom-nay-tre-the-nhin-nhu-thieu-nu-cap-3-chu-ai-nghi-da-u40-roi',
component: 'page_20210828083313'},
{title: "“Mù tịt\" chuyên môn nhưng lại là “nữ thần\" nức tiếng cộng đồng game Việt, top gái xinh sở hữu bí thuật gì?",
description: "Không biết chơi game, các hot girl này vẫn chiếm trọn được tình cảm anh em game thủ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/21/photo-1-16295469292511817028554.jpg',
alt: "HOT GIRL,GAME,GÁI XINH,BÀI VIẾT ĐÁNG CHÚ Ý,",
category: 'images',
time: '11:07 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:07 AM',
link: '/mu-tit-chuyen-mon-nhung-lai-la-nu-than-nuc-tieng-cong-dong-game-viet-top-gai-xinh-so-huu-bi-thuat-gi',
component: 'page_20210821110715'},
{title: "BXH 10 isekai anime tệ nhất thập kỷ, bạn đã \"lỡ xem\" bao nhiêu bộ trong số này? (P.2)",
description: "5 bộ isekai anime đứng đầu BXH chắc chắn sẽ khiến nhiều người ngạc nhiên",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/mom-online-16294522284821028887287.jpeg',
alt: "ANIME,ISEKAI,",
category: 'games',
time: '11:07 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:07 AM',
link: '/bxh-10-isekai-anime-te-nhat-thap-ky-ban-da-lo-xem-bao-nhieu-bo-trong-so-nay-p2',
component: 'page_20210821110706'},
{title: "Cố tình cúi thấp, khoe vòng một như chực \"nhảy ra\" trước camera, nữ streamer xinh đẹp tăng cả nghìn follow chỉ sau một khoảnh khắc",
description: "Một trong những cách thức để các streamer có thể nổi tiếng nhanh là đây chứ đâu.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/13/photo-1-1628825695681423156569.jpg',
alt: "GÁI XINH,HOT GIRL,STREAMER,CỘNG ĐỒNG MẠNG,NỮ STREAMER,SALLY,",
category: 'images',
time: '11:06 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:06 AM',
link: '/co-tinh-cui-thap-khoe-vong-mot-nhu-chuc-nhay-ra-truoc-camera-nu-streamer-xinh-dep-tang-ca-nghin-follow-chi-sau-mot-khoanh-khac',
component: 'page_20210821110637'},
{title: "Dragon Ball Super: Vượt qua Ultra Instinct, Ultra Ego của Vegeta được \"ví von\" giống với một loại sức mạnh \"nghìn năm có một\"",
description: "Trong Dragon Ball Super chap 75, Vegeta đã tiết lộ tên hình dạng mới của mình và nó có tên là Ultra Ego – Bản ngã Tối thượng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/20/anh-1-16294391739021717332258.jpg',
alt: "DRAGON BALL SUPER,VEGETA,ULTRA EGO,ULTRA INSTINCT,BẢN NĂNG VÔ CỰC,BẢN NGÃ TỐI THƯỢNG,SIÊU SAIYAN HUYỀN THOẠI,",
category: 'games',
time: '11:06 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:06 AM',
link: '/dragon-ball-super-vuot-qua-ultra-instinct-ultra-ego-cua-vegeta-duoc-vi-von-giong-voi-mot-loai-suc-manh-nghin-nam-co-mot',
component: 'page_20210821110625'},
{title: "99 ngôi mộ của mỹ nhân trong lăng Tần Thủy Hoàng hé lộ sự thật kinh hoàng về tục tuẫn táng người sống",
description: "Tần Thủy Hoàng đương nhiên là người có trí tuệ anh minh nhưng cũng là người 10 phần mê tín. Ông sinh tiền đã đưa tất cả những mỹ nữ trong thiên hạ về lấp đầy hậu cung. Nhưng sau khi Tần Thủy Hoàng qua đời, những người này toàn bộ cũng biến mất. Sử sách ghi lại bọn họ đều bị tuẫn táng, sự thật là thế nào?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/20/-1629453055644601345863.jpg',
alt: "Tần Thủy Hoàng,Lăng mộ Tần Thủy Hoàng,tài liệu lịch sử,mộ tập thể,",
category: 'news',
time: '11:06 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:06 AM',
link: '/99-ngoi-mo-cua-my-nhan-trong-lang-tan-thuy-hoang-he-lo-su-that-kinh-hoang-ve-tuc-tuan-tang-nguoi-song',
component: 'page_20210821110607'},
{title: "Loạt nụ hôn \"cướp đồ ăn\" trên màn ảnh Hoa ngữ: Triệu Lộ Tư còn đáng yêu chán, qua đến màn cướp mì thì \"quay xe\" gấp",
description: "Hình Phi, Thành Nghị, Triệu Lộ Tư... đều đã từng thực hiện nụ hôn \"trao đổi thức ăn\" với bạn diễn.",
src: 'https://t.vietgiaitri.com/2020/8/10/4-canh-hon-ngot-ngao-cop-mac-lang-tieu-tong-uy-long-tiem-tiem-dam-tung-van-trong-lay-danh-nghia-nguoi-nha-159-5196671.jpg',
alt: "Phim truyền hình,phim trung quốc,phim truyền hình trung quốc,Hình Phi,thành nghị,Trương Dư Hi,Triệu Lộ Tư,Trần Thiên Thiên Trong Lời Đồn (2020),",
category: 'stars',
time: '11:05 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:05 AM',
link: '/loat-nu-hon-cuop-do-an-tren-man-anh-hoa-ngu-dan-tinh-khong-me-chi-thay-mat-ve-sinh',
component: 'page_20210821110554'},
{title: "Top 4 mẫu laptop giá rẻ tốt nhất dành cho sinh viên mùa tựu trường",
description: "Với phân khúc 10 - 15 triệu, người dùng có thể sở hữu một chiếc laptop cho dân văn phòng, học sinh - sinh viên để học tập và làm việc online tại nhà thời điểm giãn cách xã hội. Dưới đây là top 4 mẫu laptop văn phòng tầm trung tốt nhất tại hệ thống CellphoneS.",
src: 'https://kenh14cdn.com/thumb_w/800/pr/2021/photo-1-1629467890674303829136-0-22-527-866-crop-1629469654988-63765095888195.jpg',
alt: "laptop giá rẻ,mùa tựu trường,dân văn phòng,laptop văn phòng,",
category: 'tech',
time: '11:05 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:05 AM',
link: '/top-4-mau-laptop-gia-re-tot-nhat-danh-cho-sinh-vien-mua-tuu-truong',
component: 'page_20210821110541'},
{title: "Idol Hàn cũng từng khốn đốn vì loạt quần, váy \"hư hỏng\": Người lộ vòng 3, người bị gắn với cái danh \"thời trang đóng bỉm\"",
description: "Đây đều là những trang phục trình diễn khiến người xem cảm thấy khó chịu vì hở hang quá đà của idol Hàn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/19/d4borosvuaex3zg-1629377406092535904504.jpg',
alt: "Kpop,idol,sao Hàn,star style,trang phục trình diễn,phản cảm,nhạy cảm,váy ngắn,quần ngắn,quần bảo hộ,",
category: 'stars',
time: '09:23 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:23 AM',
link: '/idol-han-cung-tung-khon-don-vi-loat-quan-vay-hu-hong-nguoi-lo-vong-3-nguoi-bi-gan-voi-cai-danh-thoi-trang-dong-bim',
component: 'page_20210820092333'},
{title: "Ai còn nhớ huyền thoại Haikyuu!, điểm lại 15 nhân vật được người hâm mộ vô cùng yêu thích nào!",
description: "Cùng xem nhân vật bạn yêu thích của Haikyuu! có tên trong danh sách này không nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/photo-1-16294438876682085910423.png',
alt: "HAIKYUU!,VUA BÓNG CHUYỀN,NHÂN VẬT HAIKYUU!,HINATA SHOYO,KAGEYAMA TOBIO,",
category: 'games',
time: '09:23 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:23 AM',
link: '/ai-con-nho-huyen-thoai-haikyuu-diem-lai-15-nhan-vat-duoc-nguoi-ham-mo-vo-cung-yeu-thich-nao',
component: 'page_20210820092321'},
{title: "Record Of Ragnarok: Lại thêm một bộ ảnh cosplay \"nữ thần sắc đẹp\" Aphrodite không bao giờ khiến anh em thất vọng",
description: "Xem ngay loạt ảnh cosplay \"nữ thần sắc đẹp\" Aphrodite ở dưới đây để có những ngày ở nhà chống dịch không nhàm chán nhé anh em!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/photo-1-1629440700077532207374.jpg',
alt: "APHRODITE,COSPLAY,COSPLAY APHRODITE,RECORD OF RAGNAROK,SHUUMATSU NO VALKYRIE,NỮ THẦN SẮC ĐẸP APHRODITE,COSER,",
category: 'images',
time: '09:23 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:23 AM',
link: '/record-of-ragnarok-lai-them-mot-bo-anh-cosplay-nu-than-sac-dep-aphrodite-khong-bao-gio-khien-anh-em-that-vong',
component: 'page_20210820092307'},
{title: "Hội mỹ nhân tự tử vẫn đẹp nao lòng trên phim Trung: Tiểu Phong quá kinh điển, nàng số 4 cớ sao ăn chửi suốt bao năm?",
description: "Những mỹ nhân cổ trang như Tiểu Phong hay Tố Tố đều lựa chọn tự tử vì tình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/20/1-1629432816450506734363.png',
alt: "Đông Cung,Tiểu Phong,Mỹ nhân Hoa ngữ,Tam Sinh Tam Thế Thập Lý Đào Hoa,Diên Hi Công Lược,Chân Hoàn Truyện,tần lam,nhiệt y trát,Bành Tiểu Nhiễm,Dương Mịch,Trường An Như Cố,Trường An Như Cố (2021),Châu Sinh Như Cố,Châu Sinh Như Cố (2021),Bạch Lộc,phim cổ trang,phim trung quốc,phim Hoa ngữ,Phim truyền hình,",
category: 'stars',
time: '09:22 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:22 AM',
link: '/hoi-my-nhan-tu-tu-van-dep-nao-long-tren-phim-trung-tieu-phong-qua-kinh-dien-nang-so-4-co-sao-an-chui-suot-bao-nam',
component: 'page_20210820092256'},
{title: "Khoe đang \"thả rông\" mà người xem không tin, nữ streamer xinh đẹp bất ngờ làm ngay hành động nhạy cảm để chứng minh",
description: "Thế mới thấy chiêu trò của các cô nàng streamer ngày càng tinh vi và khó lường hơn theo thời gian.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/20/photo-1-162946337598629722990.jpg',
alt: "streamer,hành động phản cảm,chiêu trò,khoe thân,",
category: 'images',
time: '09:22 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:22 AM',
link: '/khoe-dang-tha-rong-ma-nguoi-xem-khong-tin-nu-streamer-xinh-dep-bat-ngo-lam-ngay-hanh-dong-nhay-cam-de-chung-minh',
component: 'page_20210820092245'},
{title: "6 sai lệch so với nguyên tác của Harry Potter: Cú twist ngoạn mục lại bị cắt bỏ, mối tình của Harry vốn dĩ \"nồng thắm\" hơn nhiều!",
description: "Nội dung Harry Potter thiếu sót như thế nào khi chuyển thể từ truyện lên phim?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/17/harrypotterwbf7harryandginnyabouttokissstill080615land-1629217062975667448956.jpg',
alt: "phim Harry Potter,harry potter and the sorcerer's stone (2001),Harry Potter và Hòn đá Phù thủy,Tiểu thuyết Harry Potter,Harry Potter và Chiếc cốc lửa,sao harry potter,phim Âu Mỹ,Phim chuyển thể,Phim chuyển thể từ tiểu thuyết,harry potter,",
category: 'news',
time: '09:22 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:22 AM',
link: '/6-sai-lech-so-voi-nguyen-tac-cua-harry-potter-cu-twist-ngoan-muc-lai-bi-cat-bo-moi-tinh-cua-harry-von-di-nong-tham-hon-nhieu',
component: 'page_20210820092237'},
{title: "Bất ngờ đứt quai áo khi đang lên sóng, nữ streamer xinh đẹp hoảng sợ, vội vã che đậy \"cảnh xuân\"",
description: "Thật khó để có thể lý giải tại sao chiếc quai áo của cô nàng streamer lại có thể đứt đơn giản như vậy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/photo-1-16287677426361086176348.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,STREAMER,NỮ STREAMER,EUN-JI,",
category: 'images',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/bat-ngo-dut-quai-ao-khi-dang-len-song-nu-streamer-xinh-dep-hoang-so-voi-va-che-day-canh-xuan',
component: 'page_20210820092159'},
{title: "Biết rằng không tốt, tại sao nhiều game thủ Việt vẫn cứ đâm đầu vào \"server lậu\"?",
description: "Không phải ngẫu nhiên mà dù nhận thức được vấn đề, thế nhưng game thủ Việt vẫn rất ưa thích server lậu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-16290080798151868990329.jpg',
alt: "GAME,TIN TỨC GAME,GAME THỦ,BÀI ĐÁNG CHÚ Ý,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/biet-rang-khong-tot-the-nhung-tai-sao-nhieu-game-thu-viet-van-cu-dam-dau-vao-server-lau-the-gioi-ngam-cua-lang-game-viet',
component: 'page_20210820092150'},
{title: "BXH 10 isekai anime tệ nhất thập kỷ, bạn đã \"lỡ xem\" bao nhiêu bộ trong số này? (P.1)",
description: "Trong một thập kỷ vừa qua, isekai anime xuất hiện và gần như càn quét cộng đồng fan manga-anime. Vậy đâu là những bộ phim bị đánh giá dở tệ nhất?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/high-school-prodigies-16292781850941653289036.jpg',
alt: "ISEKAI,BẢNG XẾP HẠNG,ANIME,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/bxh-10-isekai-anime-te-nhat-thap-ky-ban-da-lo-xem-bao-nhieu-bo-trong-so-nay-p1',
component: 'page_20210820092140'},
{title: "6 bộ anime có cái kết gây hoang mang nhất từ trước đến nay",
description: "Không phải mọi manga và anime đều có một cái kết êm đẹp, chiều lòng khán giả.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/confusing-anime-endings-feature-1629252662239440709171.jpg',
alt: "ANIME,MANGA,ENDING,KHÁM PHÁ,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/6-bo-anime-co-cai-ket-gay-hoang-mang-nhat-tu-truoc-den-nay',
component: 'page_20210820092128'},
{title: "18 món đồ chơi quen thuộc mà thế hệ 8x, 9x khắp thế giới đều biết, kể cả Việt Nam luôn",
description: "Hóa ra những món đồ chơi này đều rất nổi tiếng ở trên thế giới, chứ không chỉ ở nước mình đâu nhé!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/20/photo-1-1629436770307546739656.jpg',
alt: "ĐỒ CHƠI,TRẺ NHỎ,TUỔI THƠ,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/18-mon-do-choi-quen-thuoc-ma-the-he-8x-9x-khap-the-gioi-deu-biet-ke-ca-viet-nam-luon',
component: 'page_20210820092118'},
{title: "One Punch Man: Ngoài cú đấm \"một phát chết luôn\" đây là 10 khả năng cực mạnh của Saitama mà không phải ai cũng biết (P1)",
description: "Trong One Punch Man, Saitama được biết đến với những cú đấm cực mạnh, tuy nhiên bên cạnh đó anh cũng sở hữu những khả năng đáng kinh ngạc khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/20/anh-1-16294330111421917383989.jpg',
alt: "ONE PUNCH MAN,THÁNH PHỒNG SAITAMA,CÚ ĐẤM MỘT PHÁT CHẾT LUÔN,SỨC MẠNH SAITAMA,MANGA NHẬT BẢN,",
category: 'games',
time: '09:21 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:21 AM',
link: '/one-punch-man-ngoai-cu-dam-mot-phat-chet-luon-day-la-10-kha-nang-cuc-manh-cua-saitama-ma-khong-phai-ai-cung-biet-p1',
component: 'page_20210820092108'},
{title: "Bộ sưu tập hình ảnh giúp bạn tiếp thu được những kiến thức kỳ quái chả ai biét",
description: "Không phải thứ gì quá phi thường nhưng các hình ảnh này đem lại rất nhiều sự thú vị.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/19/photo-1-16293792348581858519105.jpg',
alt: "HÌNH ẢNH,MÃN NHÃN,",
category: 'news',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/bo-suu-tap-hinh-anh-giup-ban-tiep-thu-duoc-nhung-kien-thuc-ky-quai-cha-ai-biet',
component: 'page_20210819130439'},
{title: "4 đặc điểm thường có ở những cô nàng rất dễ thụ thai ngay trong lần \"thả\" đầu tiên, xem thử bạn có thuộc nhóm này hay không",
description: "Khi hội tụ đủ 4 yếu tố sau đây thì khả năng có thai sau khi quan hệ là rất cao đó bạn nhé!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/19/photo-1-16293916769601099805699.jpg',
alt: "Sức khỏe sinh sản,sức khỏe thể chất,thời điểm rụng trứng,nguy cơ vô sinh,tế bào mỡ,",
category: 'life',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/4-dac-diem-thuong-co-o-nhung-co-nang-rat-de-thu-thai-ngay-trong-lan-tha-dau-tien-xem-thu-ban-co-thuoc-nhom-nay-hay-khong',
component: 'page_20210819130430'},
{title: "Dragon Ball Super: 4 sự thật về Bản Ngã Tối Thượng - sức mạnh mới Vegeta đạt được khi đánh nhau với Granolah",
description: "Nếu Bản Năng Vô Cực của Goku thiên về phòng thủ thì Bản Ngã Tối Thượng của Vegeta mạnh về tấn công.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/19/photo-1-1629366956824601229100.jpg',
alt: "VEGETA,BẢN NGÃ TỐI TƯỢNG,GRANOLAH,ULTRA EGO,SỨC MẠNH MỚI CỦA VEGETA,DRAGON BALL SUPER CHƯƠNG 75,DRAGON BALL SUPER,",
category: 'games',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/dragon-ball-super-4-su-that-ve-ban-nga-toi-thuong-suc-manh-moi-vegeta-dat-duoc-khi-danh-nhau-voi-granolah',
component: 'page_20210819130419'},
{title: "Dragon Ball Super: Đều nâng cao sức mạnh, Fusion Dance và bông tai Potara đâu là phép \"hợp thể\" mạnh hơn?",
description: "Trong Dragon Ball Super có rất nhiều những phương pháp hợp thể mang đến sức mạnh lớn hơn cho người dùng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/19/abg-1-16293436932771911374204.png',
alt: "DRAGON BALL SUPER,FUSION DANCE,BÔNG TAI POTARA,HỢP THỂ,GOKU,VEGETA,GOGETA,VEGITO,",
category: 'games',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/dragon-ball-super-deu-nang-cao-suc-manh-fusion-dance-va-bong-tai-potara-dau-la-phep-hop-the-manh-hon',
component: 'page_20210819130409'},
{title: "Dragon Ball Super 75 tiết lộ tên hình dạng mới của Vegeta, dường như là để \"cà khịa\" Ultra Instinct của Goku",
description: "Vegeta đã tiết lộ tên hình dạng mới của mình trong Dragon Ball Super chap 75.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/19/photo-1-16293438552742024791516.png',
alt: "DRAGON BALL SUPER,DRAGON BALL SUPER CHAP 75,GOKU,VEGETA,ULTRA EGO,ULTRA INSTINCT,GRANOLAH,",
category: 'games',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/dragon-ball-super-75-tiet-lo-ten-hinh-dang-moi-cua-vegeta-duong-nhu-la-de-ca-khia-ultra-instinct-cua-goku',
component: 'page_20210819130400'},
{title: "Thả rông, cởi bung cả cúc áo khi lên sóng, nữ streamer xinh đẹp bất ngờ làm hành động đầy nhạy cảm, khó tin",
description: "Có lẽ cô nàng streamer cũng tự ý thức được màn phơi bày của mình sẽ mang tới hiệu ứng như thế nào.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/14/photo-1-16289202701611838115861.gif',
alt: "GÁI XINH,CỘNG ĐỒNG MẠNG,STREAMER,NỮ STREAMER,BOONG-SOON,HOT GIRL,",
category: 'images',
time: '01:03 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:03 PM',
link: '/tha-rong-coi-bung-ca-cuc-ao-khi-len-song-nu-streamer-xinh-dep-bat-ngo-lam-hanh-dong-day-nhay-cam-kho-tin',
component: 'page_20210819130329'},
{title: "Cơ sở khoa học đằng sau Suicide Squad: Sao biển sở hữu toàn \"skill\" đỉnh thế này, bảo sao phản diện Starro lại bá đạo đến thế!",
description: "Sao biển có thị giác rất nhạy bén, sở hữu khả năng tái sinh và nhân bản ấn tượng và là một kẻ săn mồi chuyên nghiệp - tất cả đều là cảm hứng để tạo nên một quái thú Starro đáng sợ trong bom tấn The Suicide Squad.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-16292938268231774715637.jpg',
alt: "SAO BIỂN,JAMES GUNN,SUICIDE SQUAD,DC,THE SUICIDE SQUAD,SIÊU ANH HÙNG,",
category: 'news',
time: '01:03 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:03 PM',
link: '/co-so-khoa-hoc-dang-sau-suicide-squad-sao-bien-so-huu-toan-skill-dinh-the-nay-bao-sao-phan-dien-starro-lai-ba-dao-den-the',
component: 'page_20210819130320'},
{title: "Những nhân vật \"bán máu\" nổi tiếng trong anime - manga: Càng bầm dập, càng dễ win!",
description: "Đối với những nhân vật sử dụng máu làm vũ khí, vết thương hở có thể coi là chìa khóa đánh bại đối thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/photo-1-162921809458754272033.jpg',
alt: "MANGA,ANIME,",
category: 'games',
time: '01:03 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:03 PM',
link: '/nhung-nhan-vat-ban-mau-noi-tieng-trong-anime-manga-cang-bam-dap-cang-de-win',
component: 'page_20210819130311'},
{title: "Được nhân viên McDonald’s cưu mang thuở nghèo đói, Cristiano Ronaldo tìm lại và trả ơn họ khi đã thành công",
description: "\"Cristiano Ronaldo là người tuyệt vời khi không quên những điều nhỏ bé trong quá khứ. Tôi vốn không tin vào nhân quả nhưng bây giờ tôi bắt đầu tin rằng nó có thật\", người phụ nữ chia sẻ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/19/photo-1-1629360509016983153142.png',
alt: "cristiano ronaldo,nhân viên McDonald’s,ronaldo,",
category: 'news',
time: '01:03 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:03 PM',
link: '/duoc-nhan-vien-mcdonalds-cuu-mang-thuo-ngheo-doi-cristiano-ronaldo-tim-lai-va-tra-on-ho-khi-da-thanh-cong',
component: 'page_20210819130303'},
{title: "Nữ MC Việt từng bị chỉ trích dữ dội vì mặc bikini dẫn chương trình",
description: "Việc mặc bikini lên sóng truyền hình khiến nữ MC nhận về nhiều ý kiến trái chiều.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/19/screen-shot-2021-08-19-at-124627-16293520790851919126446.png',
alt: "Tiên Tri Nước Nga,MC,bikini,phản cảm,gây tranh cãi,chỉ trích,ném đá,World Cup,mc thu hằng,",
category: 'images',
time: '01:02 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:02 PM',
link: '/nu-mc-viet-tung-bi-chi-trich-du-doi-vi-mac-bikini-dan-chuong-trinh',
component: 'page_20210819130241'},
{title: "Á hậu Huyền My chính thức trở thành BTV truyền hình sau 7 năm đăng quang, visual lên sóng chưa chi đã thấy mê lắm rồi",
description: "Loạt ảnh nét đẹp lao động của Huyền My khiến dân tình ngất ngây, nụ cười rạng rỡ giật spotlight giữa trường quay.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/23270234642230347744129356619438171871737232n-16292912374321690658979.jpg',
alt: "Huyền My,á hậu huyền my,sao Việt,Vbiz,showbiz Việt,á hậu hoa hậu Việt Nam 2014,Nguyễn Trần Huyền My,Huyền My làm BTV,biên tập viên truyền hình,",
category: 'stars',
time: '01:02 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:02 PM',
link: '/a-hau-huyen-my-chinh-thuc-tro-thanh-btv-truyen-hinh-sau-7-nam-dang-quang-visual-len-song-chua-chi-da-thay-me-lam-roi',
component: 'page_20210819130228'},
{title: "Kim Jong Kook - Song Ji Hyo tình bể bình thế này, có chối đây đẩy thì fan cứ tích cực \"chèo thuyền\"!",
description: "Qua những hình ảnh này, khán giả có thể phần nào hiểu ra lý do Kim Jong Kook và Song Ji Hyo lại được yêu thích đến như vậy.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-1-1629268367708796859063.jpg',
alt: "sao Hàn,loveline,kim jong kook,song ji hyo,hẹn hò,sao hẹn hò,yêu đương,đẩy thuyền,running man,",
category: 'stars',
time: '01:02 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:02 PM',
link: '/kim-jong-kook-song-ji-hyo-tinh-be-binh-the-nay-co-choi-day-day-thi-fan-cu-tich-cuc-cheo-thuyen',
component: 'page_20210819130213'},
{title: "Những dự án game blockchain đáng chú ý của người Việt",
description: "Ngoài Axie Infinity, một số dự án game blockchain kiếm tiền đang nhận được sự quan tâm của đông đảo game thủ, dù vẫn còn đang ở những giai đoạn phát triển rất sớm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-3-16292811337451720211475.jpg',
alt: "dự án game,giai đoạn phát triển,lập trình viên,Game nhập vai,nhân vật ảo,nhà phát triển,",
category: 'games',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/nhung-du-an-game-blockchain-dang-chu-y-cua-nguoi-viet',
component: 'page_20210819130158'},
{title: "Rắn Săn Mồi và những tựa game mobile đời đầu tại Việt Nam, giới trẻ ngày nay muốn cũng chẳng tìm lại được bản gốc",
description: "Cái thời chưa có smartphone thì đây chính là những tựa game được chơi nhiều nhất tại Việt Nam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628773009425905671914.jpg',
alt: "GAME THỦ,GAME,GAME MOBILE,TIN TỨC GAME,",
category: 'games',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/ran-san-moi-va-nhung-tua-game-mobile-doi-dau-tai-viet-nam-gioi-tre-ngay-nay-muon-cung-chang-tim-lai-duoc-ban-goc',
component: 'page_20210819130142'},
{title: "Top 10 tựa game indie hay nhất nửa đầu năm 2021",
description: "Game indie luôn có cách để khiến game thủ phải ngạc nhiên.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-16292276014751552938098.png',
alt: "GAME HAY,GAME ĐỈNH,GAME INDIE,GAME,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/top-10-tua-game-indie-hay-nhat-nua-dau-nam-2021',
component: 'page_20210819130135'},
{title: "7 phản diện của vũ trụ điện ảnh Marvel hóa ra lại là \"anh hùng\" trong các bộ phim khác",
description: "Những diễn viên này có thể là phản diện trong MCU, nhưng ở phim khác họ lại là anh hùng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/photo-1-1629206055332634247423.jpg',
alt: "PHẢN DIỆN MCU,VŨ TRỤ ĐIỆN ẢNH MARVEL,ÁC NHÂN MARVEL,SIÊU ANH HÙNG,PHIM HAY,DIỄN VIÊN,ANH HÙNG TRONG PHIM,",
category: 'news',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/7-phan-dien-cua-vu-tru-dien-anh-marvel-hoa-ra-lai-la-anh-hung-trong-cac-bo-phim-khac',
component: 'page_20210819130127'},
{title: "Mai Shiranui và những màn cosplay khiến nam game thủ rạo rực",
description: "Mai Shiranui là một trong những nữ nhân vật đình đám nhất lịch sử làng game.",
src: 'https://gamek.mediacdn.vn/thumb_w/690/133514250583805952/2021/8/18/photo-1-16292608991452048745108.jpg',
alt: "NHÂN VẬT NỮ,MAI SHIRANUI,COSPLAY,DEAD OR ALIVE,KING OF FIGHTERS,GAME NỔI TIẾNG,",
category: 'images',
time: '01:00 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:00 PM',
link: '/mai-shiranui-va-nhung-man-cosplay-khien-nam-game-thu-rao-ruc',
component: 'page_20210819130028'},
{title: "Cộng đồng game thủ phát sốt trước Tifa tóc ngắn vô cùng quyến rũ",
description: "Một Tifa khác lạ nhưng vẫn cực kỳ xinh đẹp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-1629259505085449191550.jpg',
alt: "CỘNG ĐỒNG GAME,FINAL FANTASY VII,FINAL FANTASY VII REMAKE,NHÂN VẬT NỮ,TIFA,CÔ GÁI XINH ĐẸP,",
category: 'games',
time: '01:00 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:00 PM',
link: '/cong-dong-game-thu-phat-sot-truoc-tifa-toc-ngan-vo-cung-quyen-ru',
component: 'page_20210819130020'},
{title: "Cách build tối ưu của Yoimiya: Nhân vật gây tranh cãi trong cộng đồng Genshin Impact",
description: "Gác lại những tranh cãi trong cộng đồng Genshin Impact, nhân vật Yoimiya có ưu điểm gì và nên được build như thế nào?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/genshin-impact-cach-build-yoimiya-2-16292529626951351367237.jpg',
alt: "GENSHIN IMPACT,GAME,KHÁM PHÁ,",
category: 'games',
time: '01:00 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:00 PM',
link: '/cach-build-toi-uu-cua-yoimiya-nhan-vat-gay-tranh-cai-trong-cong-dong-genshin-impact',
component: 'page_20210819130010'},
{title: "5 tựa anime hành động không thể bỏ lỡ trong nửa đầu năm 2021",
description: "Nửa đầu 2021 là một mùa anime tuyệt vời và không khiến khán giả thất vọng, đặc biệt là ở thể loại hành động/phiêu lưu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/vivy-fluorite-eyes-song-01-02-44-1280x720-16291251280092063486149.png',
alt: "ANIME,HÀNH ĐỘNG,TOKYO REVENGERS,",
category: 'games',
time: '01:00 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:00 PM',
link: '/5-tua-anime-hanh-dong-khong-the-bo-lo-trong-nua-dau-nam-2021',
component: 'page_20210819130002'},
{title: "Top 10 tựa game bắn súng góc nhìn thứ nhất gắn liền với game thủ Việt thế hệ 8x, 9x (P.2)",
description: "Những game đã trở thành huyền thoại tuổi thơ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-16292245183131807925352.png',
alt: "GAME HAY,GAME,GAME ĐỈNH,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/top-10-tua-game-ban-sung-goc-nhin-thu-nhat-gan-lien-voi-game-thu-viet-the-he-8x-9x-p2',
component: 'page_20210819125951'},
{title: "10 game đồ họa đẹp xuất sắc, phong cảnh thiên nhiên siêu hùng vĩ (P2)",
description: "Với những người yêu thích game đồ họa đẹp, đây là những cái tên không nên bỏ qua.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/photo-1-1629213500801682258591.jpg',
alt: "ARMA 3,GAME ĐẸP,GAME ĐỒ HỌA ĐẸP,CHẤT LƯỢNG HÌNH ẢNH,GAME BẮN SÚNG,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/10-game-do-hoa-dep-xuat-sac-phong-canh-thien-nhien-sieu-hung-vi-p2',
component: 'page_20210819125943'},
{title: "Những tựa game chơi đơn dài dằng dặc nhưng ai cũng muốn dài nữa",
description: "Lý do đơn giản cho việc này là do game quá cuốn khiến họ không thể từ bỏ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/17/photo-1-16292170219291431767090.jpg',
alt: "GAME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/nhung-tua-game-choi-don-dai-dang-dac-nhung-ai-cung-muon-dai-nua',
component: 'page_20210819125932'},
{title: "5 cảnh Ecchi trong anime \"nóng bỏng\" nhất nửa đầu 2021, số 2 khiến các fan \"thót tim\" vì quá táo bạo",
description: "Mở đầu năm 2021 với sự xuất hiện của rất nhiều bộ anime Ecchi mới, cùng những cảnh vô cùng nóng bỏng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/anh-1-16291836454901848390812.jpg',
alt: "ANIME ECCHI,ANIME NHẬT BẢN,CẢNH NÓNG,NHÂN VẬT ANIME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/5-canh-ecchi-trong-anime-nong-bong-nhat-nua-dau-2021-so-2-khien-cac-fan-thot-tim-vi-qua-tao-bao',
component: 'page_20210819125920'},
{title: "Khám phá 5 bí mật cực \"dị\" của bán yêu InuYasha: Ngay cả fan cứng chưa chắc đã biết hết!",
description: "Cùng xem và kiểm tra xem mình có biết hết cả 5 bí mật này không bạn nhé!",
src: 'https://www.tvovermind.com/wp-content/uploads/2018/11/2121-inuyasha-the-final-act-26-960x540.jpg',
alt: "KHUYỂN DẠ XOA TRUYỀN KỲ - IP INUYASHA,GAME MOBILE,GAME ONLINE,GAME DI ĐỘNG,INUYASHA,GAME BẢN QUYỀN,ANIME,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/kham-pha-5-bi-mat-cuc-di-cua-ban-yeu-inuyasha-ngay-ca-fan-cung-chua-chac-da-biet-het',
component: 'page_20210819125910'},
{title: "So sánh nét vẽ giữa manga và anime \"Có bạn gái, lại thêm bạn gái\", bản gốc đã đỉnh lên hoạt hình lại càng tuyệt hơn",
description: "Anh em có thích motip bắt cá hai tay của bộ anime mới trong mùa hè này \"Có bạn gái, lại thêm bạn gái\" không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16291028703011912979480.jpg',
alt: "ANIME,SO SÁNH ANIME VÀ MANGA,CÓ BẠN GÁI,LẠI THÊM BẠN GÁI,KANOJO MO KANOJO,ANIME MÙA HÈ,",
category: 'games',
time: '12:59 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:59 PM',
link: '/so-sanh-net-ve-giua-manga-va-anime-co-ban-gai-lai-them-ban-gai-ban-goc-da-dinh-len-hoat-hinh-lai-cang-tuyet-hon',
component: 'page_20210819125901'},
{title: "Dragon Ball Super: Lý do thực sự khiến Grand Zeno lại yêu mến Goku đến như vậy?",
description: "Grand Zeno là một trong những nhân vật quyền lực nhất trong Dragon Ball Super. Là một vị Thần thế nhưng Zeno lại vô cùng yêu thích Goku, ông luôn coi anh như là bạn của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/ah-1-16291008953261460905032.jpg',
alt: "DRAGON BALL SUPER,GIẢI ĐẤU SỨC MẠNH,THẦN HỦY DIỆT,GRAND ZENO,GOKU,",
category: 'games',
time: '12:58 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:58 PM',
link: '/dragon-ball-super-ly-do-thuc-su-khien-grand-zeno-lai-yeu-men-goku-den-nhu-vay',
component: 'page_20210819125852'},
]
export const dataContent03=[{title: "Cổ Lực Na Trát gây bão Weibo với màn múa thay trang phục trong 1 giây: Mặt kinh diễm như tranh, khí chất tiên tử mê hồn",
description: "Sau màn trình diễn này, vô số lời ngợi khen đã được gửi tới nàng \"Mỹ nhân Tân Cương\" Cổ Lực Na Trát.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-1-162925109970473436377.jpeg',
alt: "cổ lực na trát,mỹ nhân Tân Cương,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '12:58 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:58 PM',
link: '/co-luc-na-trat-gay-bao-weibo-voi-man-mua-thay-trang-phuc-trong-1-giay-mat-kinh-diem-nhu-tranh-khi-chat-tien-tu-me-hon',
component: 'page_20210819125832'},
{title: "Song Ji Hyo phủ nhận loveline với Kim Jong Kook, netizen phản ứng: \"Xin lỗi chị, em là một 'thuyền viên' cố chấp\"",
description: "Dù biết loveline giữa Song Ji Hyo - Kim Jong Kook chỉ mang tính chất giải trí, fan vẫn nhất quyết không xuống \"thuyền\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-1-16292591809311976158846.jpg',
alt: "kim jong kook,song ji hyo,spartace,Kim Jong Kook & Song Ji Hyo,running man,loveline,phủ nhận tin đồn hẹn hò,tin đồn hẹn hò,đẩy thuyền,thuyền viên,",
category: 'stars',
time: '12:58 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:58 PM',
link: '/song-ji-hyo-phu-nhan-loveline-voi-kim-jong-kook-netizen-phan-ung-xin-loi-chi-em-la-mot-thuyen-vien-co-chap',
component: 'page_20210819125816'},
{title: "Chỉ 1 phút chỉnh niềng răng mà tạo ra khoảnh khắc \"để đời\", phải công nhận Joy (Red Velvet) thời MAMA 2017 đúng là cực phẩm!",
description: "Sự thật đằng sau bức ảnh \"để đời\" được Joy kể lại làm fan cười ngất, tuy nhiên ai cũng phải công nhận, visual tại MAMA năm ấy là thời kỳ đỉnh cao nhất của cô nàng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/photo2021-08-1608-16-52-16290768233691043931960.jpg',
alt: "red velvet,Joy (Red Velvet),Irene (Red Velvet),Red Velvet (SM),Joy,MAMA,Red Velvet comeback 2021,red velvet comeback,Red Velvet come back,Joy debut solo,joy red velvet,joy (red velvet) hát nhép,người đẹp,idol Kpop,Moment,viral,video viral,sexy,sao sexy,Cũ Rồi Đào Lại Cho Mới,",
category: 'stars',
time: '12:57 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:57 PM',
link: '/chi-1-phut-chinh-nieng-rang-ma-tao-ra-khoanh-khac-de-doi-phai-cong-nhan-joy-red-velvet-thoi-mama-2017-dung-la-cuc-pham',
component: 'page_20210819125757'},
{title: "Kéo hình trong Contra và những thủ thuật khiến tình bạn, anh em trong giới game thủ dễ sứt mẻ nhất mọi thời đại",
description: "Chơi game như thế này thì có ngày \"combat thật\" cũng không chừng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-1628845798165770969488.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,CONTRA,LEFT 4 DEAD,D-DAY,",
category: 'games',
time: '12:57 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:57 PM',
link: '/keo-hinh-trong-contra-va-nhung-thu-thuat-khien-tinh-ban-anh-em-trong-gioi-game-thu-de-sut-me-nhat-moi-thoi-dai',
component: 'page_20210819125720'},
{title: "Mặc váy ngắn nhưng lại hồn nhiên ra đứng cạnh quạt, nữ streamer xinh đẹp bất cẩn \"lộ hàng\" khó đỡ",
description: "Sự cố lần này đã mang tới nhiều tiêu cực cho cô nàng streamer.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/15/photo-1-1629025884531627878591.jpg',
alt: "CỘNG ĐỒNG MẠNG,HOT GIRL,GÁI XINH,NỮ STREAMER,NAPUR,STREAMER,",
category: 'images',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/mac-vay-ngan-nhung-lai-hon-nhien-ra-dung-canh-quat-nu-streamer-xinh-dep-bat-can-lo-hang-kho-do',
component: 'page_20210819125657'},
{title: "Jennie và 3 lần tái hiện lại những nhân vật kinh điển: Có đọ lại body bốc lửa của Angelina Jolie khi hóa thân thành Lara Croft huyền thoại?",
description: "Bạn nghĩ sao về những tạo hình lấy cảm hứng từ các nhân vật nổi tiếng của Jennie?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/16/5473207915133523788010198188912766227040247n-16291142861721328843914.jpg',
alt: "jennie,star style,sao Hàn,Lara Croft,Phoebe Dynevor,Angelina Jolie,Uma Thurman,",
category: 'stars',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/jennie-va-3-lan-tai-hien-lai-nhung-nhan-vat-kinh-dien-co-do-lai-body-boc-lua-cua-angelina-jolie-khi-hoa-than-thanh-lara-croft-huyen-thoai',
component: 'page_20210819125646'},
{title: "Xoài Non khoe ảnh makeup đeo lens ngày khai giảng, visual thế này bảo sao ông xã đổ gục!",
description: "Hóa ra lối makeup \"ngựa ngựa\" này là vũ khí khiến visual Xoài Non thăng hạng!",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/16/tram-tro-truoc-nhan-sac-chi-dau-17-tuoi-cua-diep-lam-anh-hinh-6-16291242929181600059655.jpeg',
alt: "Xoài non,Makeup,Trang điểm,sao makeup,showbiz Việt,sao Việt,streamer,",
category: 'stars',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/xoai-non-khoe-anh-makeup-deo-lens-ngay-khai-giang-visual-the-nay-bao-sao-ong-xa-do-guc',
component: 'page_20210819125632'},
{title: "Hình mẫu “cha mẹ trực thăng”: Tưởng tốt nhưng thực chất đang huỷ hoại tương lai và cuộc sống của con cái mình",
description: "Cha mẹ nào cũng muốn con mình hạnh phúc và làm điều tốt cho bản thân nhưng việc trở thành \"cha mẹ trực thăng\" đôi khi có thể phản tác dụng và gây hại nhiều hơn lợi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/17/photo-1-16291622629061133102157.jpg',
alt: "phản tác dụng,kỳ thi tuyển sinh,thi tuyển sinh đại học,nhà tâm lý học,bậc cha mẹ,Phương pháp giáo dục con cái,Bài học dạy con,",
category: 'life',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/hinh-mau-cha-me-truc-thang-tuong-tot-nhung-thuc-chat-dang-huy-hoai-tuong-lai-va-cuoc-song-cua-con-cai-minh',
component: 'page_20210819125622'},
{title: "\"Giải ngố\" 18+: 4 thời điểm không nên làm chuyện ấy vì rất dễ gây tổn hại tới sức khỏe, nhất là đối với phái nữ",
description: "Việc thường xuyên quan hệ trong những khoảng thời gian sau đây đều không hề tốt cho sức khỏe chút nào. Do đó, bạn cần chú ý để tránh mắc phải nhé!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/photo-1-16291329638351675332416.jpg',
alt: "Giải ngố 18+,đồng hồ sinh học,bạn có biết?,kỳ kinh nguyệt,",
category: 'life',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/giai-ngo-18-4-thoi-diem-khong-nen-lam-chuyen-ay-vi-rat-de-gay-ton-hai-toi-suc-khoe-nhat-la-doi-voi-phai-nu',
component: 'page_20210819125614'},
{title: "Top 10 tựa game bắn súng góc nhìn thứ nhất gắn liền với game thủ Việt thế hệ 8x, 9x",
description: "Những game đã trở thành huyền thoại tuổi thơ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-1629122147785572385314.jpg',
alt: "GAME BẮN SÚNG,GAME THỦ VIỆT,GAME THỦ,GAME,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/top-10-tua-game-ban-sung-goc-nhin-thu-nhat-gan-lien-voi-game-thu-viet-the-he-8x-9x',
component: 'page_20210819125603'},
{title: "Những điều không phải ai cũng biết về chiến binh Valkyrie trong thần thoại Bắc Âu",
description: "Trong thần thoại Bắc Âu, các nữ chiến binh Valkyrie có lẽ là những nhân vật được biết đến nhiều nhất",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/16/valkyries-shadows-1629101903187774463403.jpg',
alt: "VALKYRIE,KHÁM PHÁ,THẦN THOẠI BẮC ÂU,",
category: 'news',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/nhung-dieu-khong-phai-ai-cung-biet-ve-chien-binh-valkyrie-trong-than-thoai-bac-au',
component: 'page_20210819125554'},
{title: "10 game đồ họa đẹp xuất sắc, phong cảnh thiên nhiên siêu hùng vĩ (P1)",
description: "Bạn sẽ bị hớp hổn bởi những tựa game đồ họa đẹp tuyệt vời này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/16/photo-1-16291136972941823190210.jpg',
alt: "RED DEAD REDEMPTION,GAME ĐỒ HỌA ĐẸP,ĐỒ HỌA ĐẸP,TÁC PHẨM NGHỆ THUẬT,GOD OF WAR,THẾ GIỚI ẢO,FAR CRY 4,MÀN ẢNH RỘNG,",
category: 'games',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/10-game-do-hoa-dep-xuat-sac-phong-canh-thien-nhien-sieu-hung-vi-p1',
component: 'page_20210819125544'},
{title: "Top 5 \"anh hói\" mạnh nhất thế giới anime, chưa ai vượt qua được \"thánh phồng\" Saitama",
description: "Dù không may mắn khi có quả đầu trọc lốc, thế nhưng bù lại 5 nhân vật này lại sở hữu sức mạnh kinh khủng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/anh-1-1629096867117208652779.jpeg',
alt: "ONE PUNCH MAN,SAITAMA,DRAGON BALL,NHÂN VẬT ANIME,NHÂN VẬT HÓI,ANIME NHẬT BẢN,",
category: 'games',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/top-5-anh-hoi-manh-nhat-the-gioi-anime-chua-ai-vuot-qua-duoc-thanh-phong-saitama',
component: 'page_20210819125535'},
{title: "Rất hay đăng ảnh bốc lửa nhưng có 1 chuyện Phí Phương Anh mãi vẫn không chịu thừa nhận",
description: "Phủ nhận chuyện \"trùng tu\" nhưng Phí Phương Anh vẫn làm dấy lên mối nghi ngờ chỉ qua vài bức ảnh.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/5d08efd2ebd36-1049-1629109759052152931948.jpeg',
alt: "phí phương anh,công nghệ thẩm mỹ thời nay,dao kéo,phẫu thuật thẩm mỹ,nghi vấn sao thẩm mỹ,nghi án dao kéo,star style,Làm ngực,",
category: 'stars',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/rat-hay-dang-anh-boc-lua-nhung-co-1-chuyen-phi-phuong-anh-mai-van-khong-chiu-thua-nhan',
component: 'page_20210819125524'},
{title: "Game thủ thế hệ 8-9x càng già càng lười chơi: Do game ngày xưa quá chất hay thời gian đã bào mòn tất cả?",
description: "Không thể phủ nhận một thực tế rằng, các game thủ thế hệ đầu 8-9x dường như không còn quá hào hứng với các tựa game ngày nay.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-16286774789911054554458.jpg',
alt: "GAME THỦ,TIN TỨC GAME,GAME,",
category: 'games',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/game-thu-the-he-8-9x-cang-gia-cang-luoi-choi-la-do-game-ngay-xua-qua-chat-hay-thoi-gian-da-bao-mon-tat-ca',
component: 'page_20210819125507'},
{title: "Review Free Guy: Phim có chủ đề trò chơi điện tử hay nhất từ trước đến nay?",
description: "Không chỉ là một bộ phim lấy chủ đề trò chơi điện tử, Free Guy thực sự là một bộ phim hay với tính giải trí cao.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16290889280931173430949.jpg',
alt: "TRÒ CHƠI ĐIỆN TỬ,FREE GUY,CỘNG ĐỒNG GAME,CỘNG ĐỒNG GAME THỦ,",
category: 'games',
time: '12:54 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:54 PM',
link: '/review-free-guy-phim-co-chu-de-tro-choi-dien-tu-hay-nhat-tu-truoc-den-nay',
component: 'page_20210819125458'},
{title: "Mặc bikini xuống phố chơi điện tử xèng, nữ streamer từng bị \"fan boy lớp 6\" quấy rối nhận vô số chỉ trích",
description: "Trong quá khứ, cô nàng streamer từng không ít lần có những hành động đầy nhạy cảm như vậy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/photo-1-1628592705610638733924.jpg',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,STREAMER,SHIN JAE EUN,NỮ STREAMER,",
category: 'images',
time: '12:54 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:54 PM',
link: '/mac-bikini-xuong-pho-choi-dien-tu-xeng-nu-streamer-tung-bi-fan-boy-lop-6-quay-roi-nhan-vo-so-chi-trich',
component: 'page_20210819125435'},
{title: "Vừa trở lại sau khi nhập viện, nữ streamer xinh đẹp nhún nhảy quá đà, ngã ngồi tới mức vô tình hớ hênh trên sóng",
description: "Trở lại sau khi điều trị trầm cảm, cô nàng streamer lại tiếp tục khiến người xem phải đỏ mặt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628738773829604753551.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,NỮ STREAMER,STREAMER,EDDYRIN,",
category: 'images',
time: '12:54 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:54 PM',
link: '/vua-tro-lai-sau-khi-nhap-vien-nu-streamer-xinh-dep-nhun-nhay-qua-da-nga-ngoi-toi-muc-vo-tinh-ho-henh-tren-song',
component: 'page_20210819125404'},
{title: "Naruto: Những thông tin và sự kiện quan trọng trong 3 cuộc Đại chiến thế giới Ninja đầu tiên",
description: "Ba cuộc đại chiến Ninja đầu tiên đã cướp đi sinh mạng của rất nhiều người.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-1629093479714282877037.png',
alt: "NARUTO,BORUTO,ĐẠI CHIẾN THẾ GIỚI NINJA,CUỘC CHIẾN NINJA,LÀNG NINJA,",
category: 'games',
time: '12:53 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:53 PM',
link: '/naruto-nhung-thong-tin-va-su-kien-quan-trong-trong-3-cuoc-dai-chien-the-gioi-ninja-dau-tien',
component: 'page_20210819125355'},
{title: "Những Pokémon mà ai cũng biết dù không phải là fan của thương hiệu này",
description: "Một số Pokémon nổi tiếng đến nỗi ngay cả những người không xem thương hiệu này cũng biết đến chúng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16290922423471474035069.jpg',
alt: "PIKACHU,POKÉMON,LUCARIO,PORYGON,POKÉMON NỔI TIẾNG,",
category: 'games',
time: '12:53 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:53 PM',
link: '/nhung-pokemon-ma-ai-cung-biet-du-khong-phai-la-fan-cua-thuong-hieu-nay',
component: 'page_20210819125347'},
{title: "Bị chê vòng một chảy xệ, nữ sinh 2K chụp cận cảnh khuôn ngực hơn 1 mét đáp trả anti fan",
description: "Nhận không ít bình luận chê bai kém duyên, nữ sinh Hải Dương đáp trả gay gắt, không còn lặng lẽ như hồi mới nổi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-1629079924550695055364.jpg',
alt: "NỮ SINH,NGỰC KHỦNG,HẢI DƯƠNG,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '12:52 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:52 PM',
link: '/bi-che-vong-mot-chay-xe-nu-sinh-2k-chup-can-canh-khuon-nguc-hon-1-met-dap-tra-anti-fan',
component: 'page_20210819125255'},
{title: "Record Of Ragnarok: Thổn thức ngắm nữ thần sắc đẹp Aphrodite phiên bản người Việt, tâm hồn đâu thua gì bản gốc",
description: "Nữ thần sắc đẹp Aphrodite trong Record Of Ragnarok đang rất được lòng các fan và giới cosplay.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/16/photo-1-16290826622961623602045-16290826801601385682416.jpg',
alt: "SHUUMATSU NO VALKYRIE,NỮ THẦN SẮC ĐẸP APHRODITE,COSPLAY NỮ THẦN SẮC ĐẸP APHRODITE,COSER,COSPLAY,HANA BUNNY,",
category: 'images',
time: '12:52 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:52 PM',
link: '/record-of-ragnarok-thon-thuc-ngam-nu-than-sac-dep-aphrodite-phien-ban-nguoi-viet-tam-hon-dau-thua-gi-ban-goc',
component: 'page_20210819125221'},
{title: "Top 10 con rồng bá đạo nhất trong thế giới game (P.2)",
description: "Cứ nghe đến loài rồng thì hầu hết toàn hàng khủng...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-1-16290530066511307359247.jpg',
alt: "RỒNG,THẾ GIỚI GAME,GAME THỦ,LỊCH SỬ,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '12:52 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:52 PM',
link: '/top-10-con-rong-ba-dao-nhat-trong-the-gioi-game-p2',
component: 'page_20210819125213'},
{title: "Đừng nói Nobita nghèo nữa, nghe giá căn nhà gia đình Nobi ở mà hú hồn luôn!",
description: "Nghiên cứu về nhà đất của một tác giả viết tiểu thuyết tại Nhật Bản khiến người xem bất ngờ về giá trị căn nhà của Nobita.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/photo-1-16290954413781077324210.jpg',
alt: "Nobita,Doraemon,truyện tranh doremon,phim Nhật Bản,Phim hoạt hình,truyện tranh Nhật Bản,",
category: 'games',
time: '12:51 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:51 PM',
link: '/dung-noi-nobita-ngheo-nua-nghe-gia-can-nha-gia-dinh-nobi-o-ma-hu-hon-luon',
component: 'page_20210819125153'},
{title: "5 Điêu Thuyền của màn ảnh Hoa ngữ: Lưu Diệc Phi không có cửa với huyền thoại năm xưa, người thứ 5 diễn đơ nhưng makeup đẹp điên đảo",
description: "Điện ảnh Hoa ngữ ghi nhận loạt diễn viên từng vào vai Điêu Thuyền, mỗi người toát lên một khí chất khiến khán giả khó lòng lãng quên.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/15/photo-1-16289992852711624559456.jpg',
alt: "Điêu Thuyền,Lưu Diệc Phi,cổ lực na trát,trần hồng,đổng tuyền,trần hảo,tứ đại mỹ nhân,Tam Quốc Diễn Nghĩa,tạo hình cổ trang,Mỹ nhân cổ trang,người đẹp cổ trang,sao Hoa ngữ,star style,",
category: 'stars',
time: '12:51 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:51 PM',
link: '/5-dieu-thuyen-cua-man-anh-hoa-ngu-luu-diec-phi-khong-co-cua-voi-huyen-thoai-nam-xua-nguoi-thu-5-dien-do-nhung-makeup-dep-dien-dao',
component: 'page_20210819125141'},
{title: "Top 10 con rồng bá đạo nhất trong thế giới game",
description: "Cứ nghe đến loài rồng thì hầu hết toàn hàng khủng...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/16/photo-4-16290513465831410342950.jpg',
alt: "RỒNG,LỊCH SỬ,GAME,LÀNG GAME,THẾ GIỚI GAME,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '04:07 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:07 PM',
link: '/top-10-con-rong-ba-dao-nhat-trong-the-gioi-game',
component: 'page_20210815160733'},
{title: "Những tựa game tưởng là kết thúc có hậu nhưng hóa ra toàn lừa",
description: "Cứ nghĩ kỹ đi là bạn sẽ hiểu vấn đề xảy ra là kỳ quặc và lắm vấn đề hơn nhiều so với tưởng tượng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/15/photo-1-1629044886251622526273.jpg',
alt: "GAME,",
category: 'games',
time: '04:07 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:07 PM',
link: '/nhung-tua-game-tuong-la-ket-thuc-co-hau-nhung-hoa-ra-toan-lua',
component: 'page_20210815160724'},
{title: "Bây giờ chả ai gửi tiết kiệm nữa, thế họ làm gì để tiền đẻ ra tiền?",
description: "Không một tài sản nào trên đời sẽ đến một cách dễ dàng: Đầu tư không phải là con đường tắt đến với sự giàu có.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/1-16290387435451827783682.png',
alt: "Có tiền thì làm gì,đầu tư,tiết kiệm,người trẻ,giới trẻ,đế chế sổ tiết kiệm,ma trận đầu tư,bài học tài chính,tài chính,quản lý tài chính,",
category: 'life',
time: '04:07 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:07 PM',
link: '/bay-gio-cha-ai-gui-tiet-kiem-nua-the-ho-lam-gi-de-tien-de-ra-tien',
component: 'page_20210815160712'},
{title: "Người gan kém thường có 3 biểu hiện đặc trưng khi đi ngủ, mong rằng bạn không xuất hiện bất kỳ cái nào",
description: "Gan khỏe cơ thể mới khỏe, còn gan xấu thì bạn sẽ rất dễ bị trằn trọc, khó ngủ vào ban đêm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/photo-1-16290464450851819138154.jpg',
alt: "chịu trách nhiệm,máu lưu thông,",
category: 'life',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/nguoi-gan-kem-thuong-co-3-bieu-hien-dac-trung-khi-di-ngu-mong-rang-ban-khong-xuat-hien-bat-ky-cai-nao',
component: 'page_20210815160656'},
{title: "Hot nhất Weibo tối nay: Dương Mịch hack tuổi thần sầu với visual như gái 18, thế này thì đàn em chạy dài cũng chẳng đuổi kịp",
description: "Vẫn biết Dương Mịch cực kỳ biết cách chăm chút hình ảnh nhưng Cnet vẫn phải trầm trồ trước nét đẹp như \"lão hoá ngược\" của cô nàng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/photo-1-16290432169391833182506.jpg',
alt: "Dương Mịch,nhan sắc của sao,vóc dáng của sao,sao trên sóng livestream,nhan sắc thật của sao,",
category: 'stars',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/hot-nhat-weibo-toi-nay-duong-mich-hack-tuoi-than-sau-voi-visual-nhu-gai-18-the-nay-thi-dan-em-chay-dai-cung-chang-duoi-kip',
component: 'page_20210815160646'},
{title: "Cùng nhìn lại làn sóng chơi game NFT ở Việt Nam, có \"dễ ăn\" như thiên hạ đồn thổi?",
description: "Thay vì chơi game để giải trí, giờ đây làn sóng NFT đang mang tới sức hút rất lớn cho các game thủ muốn làm giàu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628672866860630037694.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,GAME NFT,",
category: 'games',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/cung-nhin-lai-lan-song-choi-game-nft-o-viet-nam-co-de-an-nhu-thien-ha-don-thoi',
component: 'page_20210815160628'},
{title: "5 năm chỉ chơi 1 tướng, Girl 1 Champ Hoàng Yến Chibi đập tan lời đồn ác ý khi khoe trọn cơ thể cực kỳ sexy",
description: "Mới đây, Hoàng Yến Chibi lại khiến cho tất cả phải ngỡ ngàng khi khoe trọn cơ thể vô cùng gợi cảm của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-16290271793201232598381.jpg',
alt: "LIÊN QUÂN MOBILE,LIÊN QUÂN,CỘNG ĐỒNG LIÊN QUÂN MOBILE,HOÀNG YẾN CHIBI,HOÀNG YẾN,GIRL 1 CHAMP,GAME THỦ,GÁI XINH,SEXY,",
category: 'stars',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/5-nam-chi-choi-1-tuong-girl-1-champ-hoang-yen-chibi-dap-tan-loi-don-ac-y-khi-khoe-100-co-the-cuc-ky-sexy',
component: 'page_20210815160619'},
{title: "One Piece: Đây là 8 \"siêu năng lực\" không đến từ trái ác quỷ, có tới 3 thành viên Mũ Rơm sở hữu kỹ thuật đặc biệt",
description: "Những kỹ thuật này đều rất kỳ diệu đấy nhé!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/15/photo-1-1629019012401268560689.jpg',
alt: "ONE PIECE,MISS GOLDENWEEK,SIÊU NĂNG LỰC TRONG ONE PIECE,NĂNG LỰC KHÔNG ĐẾN TỪ TRÁI ÁC QUỶ,TRÁI ÁC QUỶ,",
category: 'games',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/one-piece-day-la-8-sieu-nang-luc-khong-den-tu-trai-ac-quy-co-toi-3-thanh-vien-mu-rom-so-huu-ky-thuat-dac-biet',
component: 'page_20210815160609'},
{title: "Top 5 nữ giáo viên quyến rũ trong thế giới anime, xem phim mà chỉ ước được cô giáo dạy học",
description: "Cùng điểm qua 5 nữ giáo viên quyến rũ trong thế giới anime mà chúng tôi tổng hợp được nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/hau3-16290103011632025900797.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/top-5-nu-giao-vien-quyen-ru-trong-the-gioi-anime-xem-phim-ma-chi-uoc-duoc-co-giao-day-hoc',
component: 'page_20210815160558'},
{title: "Hơn 300.000 thông tin sinh viên Việt Nam bị hacker rao bán",
description: "Thông tin cá nhân của nhiều sinh viên Việt Nam đã bị hacker đánh cắp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-16290169678581550254612.jpg',
alt: "SINH VIÊN VIỆT NAM,THÔNG TIN CÁ NHÂN,HACK,HACKER,NGƯỜI DÙNG VIỆT,",
category: 'tech',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/hon-300000-thong-tin-sinh-vien-viet-nam-bi-hacker-rao-ban',
component: 'page_20210815160549'},
{title: "Nhan sắc xinh đẹp của thiên thần trượt băng đã cosplay Trùng Trụ, tài sắc vẹn toàn càng ngắm càng mê",
description: "Cùng đến với những hình ảnh cực kỳ xinh đẹp của thiên thần trượt băng đã cosplay Trùng Trụ gây sốt cộng đồng mạng trong những ngày vừa qua nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/co5-1629006817313376916211.jpg',
alt: "LỄ HỘI COSPLAY,BỘ ẢNH COSPLAY,CHÙM ẢNH COSPLAY,NHÂN VẬT COSPLAY,ẢNH COSPLAY,COSPLAY KIMETSU NO YAIBA,TRÙNG TRỤ KIMETSU NO YAIBA,",
category: 'stars',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/nhan-sac-xinh-dep-cua-thien-than-truot-bang-da-cosplay-trung-tru-tai-sac-ven-toan-cang-ngam-cang-me',
component: 'page_20210815160540'},
{title: "Top 10 Pokémon hệ độc nổi bật qua các thế hệ, Gengar vẫn được chú ý nhất",
description: "Ash đã gặp rất nhiều Pokémon mạnh trong chuyến du hành của mình, bao gồm cả những loài hệ độc.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-1629002882487880472230.jpg',
alt: "BULBASAUR,GENGAR,POKÉMON,POKÉMON HỆ ĐỘC,CÁC LOÀI POKÉMON,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/top-10-pokemon-he-doc-noi-bat-qua-cac-the-he-gengar-van-duoc-chu-y-nhat',
component: 'page_20210815160531'},
{title: "Top 4 vấn đề gây tranh cãi nhiều nhất trong One Piece, nơi tôn vinh những \"thánh cào phím\" và những lần \"gây war\" không ngớt",
description: "Cho đến nay khi một trong bốn vấn đề sau đây trong One Piece được đưa ra bàn luận thì nó luôn tạo ra những cuộc tranh luận không có hồi kết. Cùng chúng tôi điểm qua các vấn đề này nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/14/yp1-16289265719271956823559.png',
alt: "ONE PIECE,FAN ONE PIECE,TRANH CÃI ONE PIECE,CỘNG ĐỒNG ONE PIECE,TỨ HOÀNG ONE PIECE,ĐÔ ĐỐC ONE PIECE,ARC WANO,TỨ HOÀNG KAIDO,TỨ HOÀNG BIG MOM,PHƯỢNG HOÀNG MARCO,EIICHIRO ODA,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/top-4-van-de-gay-tranh-cai-nhieu-nhat-trong-one-piece-noi-ton-vinh-nhung-thanh-cao-phim-va-nhung-lan-gay-war-khong-ngot',
component: 'page_20210815160521'},
{title: "Những trò chơi khiến bạn \"chết nhiều hơn sống\" nhưng cuốn thôi rồi",
description: "Các tựa game này có độ thử thách khá cao và đòi hỏi người chơi phải nắm được quy tắc thì mới có thể sống sót.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/14/photo-1-16289586805401838925611.png',
alt: "GAME,",
category: 'games',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/nhung-tro-choi-khien-ban-chet-nhieu-hon-song-nhung-cuon-thoi-roi',
component: 'page_20210815160510'},
{title: "Suri Cruise khoe chân dài miên man trên phố, nhưng phải ôm ngực khư khư vì sợ lộ phần nhạy cảm",
description: "Suri Cruise chăm diện đồ ngắn dạo phố nhưng đôi khi trang phục thoải mái lại đẩy cô công chúa nhà Tom Cruise vào tình huống khó xử, ngượng ngùng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/photo-1-16290342517302050187624.jpg',
alt: "suri cruise,tom cruise,katie holmes,sao us uk,sao hollywood,con gái Tom Cruise,",
category: 'stars',
time: '04:04 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:04 PM',
link: '/suri-cruise-khoe-chan-dai-mien-man-tren-pho-nhung-phai-om-nguc-khu-khu-vi-so-lo-phan-nhay-cam',
component: 'page_20210815160455'},
{title: "Thưởng 300 triệu cho anti-fan nếu như chấp nhận lộ mặt và xinh hơn mình, nữ streamer \"siêu vòng một\" bị chỉ trích vì chơi trội",
description: "Cô nàng streamer này tỏ ra khá hào phóng nhưng không kém phần cứng rắn với anti-fan.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/photo-6-1629016213250628061417.jpg',
alt: "streamer,Siêu vòng một,antifan,",
category: 'images',
time: '04:04 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:04 PM',
link: '/thuong-300-trieu-cho-anti-fan-neu-nhu-chap-nhan-lo-mat-va-xinh-hon-minh-nu-streamer-sieu-vong-mot-bi-chi-trich-vi-choi-troi',
component: 'page_20210815160425'},
{title: "Những khung cảnh quen thuộc của game thủ Việt thời còn chưa có Internet, nhìn là thấy cả bầu trời kỷ niệm (p2)",
description: "Những hình ảnh đáng quý gợi về không biết bao nhiêu cảm xúc cho thế hệ các game thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/10/photo-1-1628566717051642727140.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,",
category: 'games',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/nhung-khung-canh-quen-thuoc-cua-game-thu-viet-thoi-con-chua-co-internet-nhin-la-thay-ca-bau-troi-ky-niem-p2',
component: 'page_20210814114051'},
{title: "Nếu mê thể loại hành động thì đây là 10 manga chiến đấu hấp dẫn nhất định nên xem",
description: "Nếu bạn muốn tìm cảm giác mạnh trong những bộ manga chiến đấu, thì 10 cái tên dưới đây sẽ giúp bạn thỏa mãn.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/14/photo-1-16289300476001649657496.jpg',
alt: "DRAGON BALL,MANGA HÀNH ĐỘNG,MANGA CHIẾN ĐẤU,KENGAN ASURA,FLAME OF RECCA,BAKI,",
category: 'games',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/neu-me-the-loai-hanh-dong-thi-day-la-10-manga-chien-dau-hap-dan-nhat-dinh-nen-xem',
component: 'page_20210814114042'},
{title: "Thoả sức chiến game tại nhà với bộ ba laptop gaming Acer",
description: "Những mẫu laptop gaming từ Acer chắc chắn sẽ nâng tầm trải nghiệm của bạn khi tận hưởng các tựa game hot hiện nay.",
src: 'https://channel.mediacdn.vn/thumb_w/640/2021/8/14/photo-1-1628918761364726630697.jpg',
alt: "ACER,LAPTOP,",
category: 'tech',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/thoa-suc-chien-game-tai-nha-voi-bo-ba-laptop-gaming-acer',
component: 'page_20210814114035'},
{title: "Nhan sắc thăng hạng của mỹ nhân bị ghét vì đóng cảnh nóng phản cảm trong Tân Hoàn Châu Cách Cách",
description: "Năm xưa nữ diễn viên này từng chịu chỉ trích \"bất chấp thủ đoạn để nổi tiếng\" vì cảnh nóng trong Tân Hoàn Châu Cách Cách.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/14/hamthanhtu-9-16289321889752056026119.jpg',
alt: "hám thanh tử,tân hoàn châu cách cách,ngày ấy bây giờ,cảnh nóng,",
category: 'stars',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/nhan-sac-thang-hang-cua-my-nhan-bi-ghet-vi-dong-canh-nong-phan-cam-trong-tan-hoan-chau-cach-cach',
component: 'page_20210814114021'},
{title: "\"Cửa ải\" để thành người mẫu của Victoria's Secret: Số đo 3 vòng \"siêu thực\" gây tranh cãi, chế độ ăn kiêng như ác mộng!",
description: "Để trở thành \"thiên thần\" đeo trên vai đôi cánh màu nhiệm của Victoria's Secret, các người mẫu sẽ phải thoả mãn được hàng loạt tiêu chí khó như... hô phong hoán vũ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/14/roosworkout-thumb-1549468754-1628935684422928971291.jpeg',
alt: "victoria's secret,người mẫu,siêu mẫu,gigi hadid,bella hadid,thời trang,Showbiz,Biến Cũ Làng Mốt,",
category: 'stars',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/cua-ai-de-thanh-nguoi-mau-cua-victorias-secret-so-do-3-vong-sieu-thuc-gay-tranh-cai-che-do-an-kieng-nhu-ac-mong',
component: 'page_20210814114010'},
{title: "Cải tạo căn hộ 67m2 với chi phí 230 triệu: Thiết kế toàn đường cong mềm mại, khu bếp - bàn ăn nối liền cực hay ho",
description: "Căn hộ được cải tạo theo phong cách hiện đại, tươi trẻ với mức chi phí hợp lý.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/14/z267484202555272d45d3b8d7c1833ee2c4c0f56e0da67-16289359953141728239480.jpg',
alt: "Căn hộ chung cư,phong cách hiện đại,tranh treo tường,Trang trí nội thất,gam màu trắng,nhà vệ sinh,không gian sinh hoạt,Đồ nội thất,bàn đa năng,Tủ quần áo,bàn trang điểm,Cải tạo nhà,house n home,",
category: 'life',
time: '11:39 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:39 AM',
link: '/cai-tao-can-ho-67m2-voi-chi-phi-230-trieu-thiet-ke-toan-duong-cong-mem-mai-khu-bep-ban-an-noi-lien-cuc-hay-ho',
component: 'page_20210814113959'},
{title: "BLACKPINK \"đại náo\" sự kiện PUBG: Từ sang chảnh hóa \"bánh bèo\" xinh xỉu, nhưng sao cả 4 lộ cẳng chân chẳng đẹp như mơ thế này",
description: "Trong sự kiện mới, BLACKPINK thật sự khiến dân tình xỉu ngang vì nhan sắc xinh đẹp hút hồn, nhưng phải thở dài khi kéo xuống đôi chân của cả 4 thành viên.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/13/e8qtbvpucamcwwf-16288724275191988611193.jpg',
alt: "Blackpink,lisa (black pink),lisa (blackpink),lisa,jennie,Jennie (BlackPink),jisoo (blackpink),jisoo,rosé (blackpink),Rosé,nhan sắc của blackpink,body của blackpink,body của sao,body của sao Hàn,nhan sắc sao,nhan sắc của sao,nhan sắc của sao Hàn,đôi chân của sao,sao lộ khuyết điểm,PUBG,",
category: 'stars',
time: '10:40 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:40 PM',
link: '/blackpink-dai-nao-su-kien-pubg-tu-sang-chanh-hoa-banh-beo-xinh-xiu-nhung-sao-ca-4-lo-cang-chan-chang-dep-nhu-mo-the-nay',
component: 'page_20210813224013'},
{title: "Tạo hình dị vực của sao Trung: Bành Tiểu Nhiễm vẫn \"đỉnh của chóp\", Triệu Lệ Dĩnh mất điểm vì lông mày, Nhiệt Ba bị soán ngôi bởi 2 đồng hương",
description: "Dàn mỹ nhân của Cbiz trông thế nào trong tạo hình dị vực?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/12/52426857529098130918556505237438482874368n-1628758242733244696967.jpg',
alt: "sao Trung,sao Hoa ngữ,Cbiz,dị vực,tạo hình dị vực,thời trang trong phim,Địch Lệ Nhiệt Ba,đồng lệ á,triệu lệ dĩnh,Angela Baby,Lưu Thi Thi,Lê Tư,Bành Tiểu Nhiễm,star style,",
category: 'stars',
time: '10:39 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:39 PM',
link: '/tao-hinh-di-vuc-cua-sao-trung-banh-tieu-nhiem-van-dinh-cua-chop-trieu-le-dinh-mat-diem-vi-long-may-nhiet-ba-bi-soan-ngoi-boi-2-dong-huong',
component: 'page_20210813223953'},
]
export const dataContent04=[{title: "Lén lút đi đóng phim 18+, nàng hot girl hoảng hồn khi bị sếp và đồng nghiệp phát hiện và cái kết đầy bất ngờ sau đó",
description: "Đây cũng là lý do chính khiến cho cô nàng hot girl quyết định trở thành diễn viên phim 18+ full time.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/11/photo-1-1628650191644363447295-1628650205333343767926.jpg',
alt: "HOT GIRL,GÁI XINH,PHIM AV,AV,PHIM 18+,SHIHO FUJIE,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '10:38 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:38 PM',
link: '/len-lut-di-dong-phim-18-nang-hot-girl-hoang-hon-khi-bi-sep-va-dong-nghiep-phat-hien-va-cai-ket-day-bat-ngo-sau-do',
component: 'page_20210813223818'},
{title: "Người thổi sáo thành Hamelin – Câu chuyện rùng rợn về vụ mất tích của 130 đứa trẻ",
description: "Người thổi sáo thành Hamelin là câu chuyện cổ tích về cái giá phải trả cho sự vô ơn. Tuy nhiên, dường như rất ít người biết rằng câu chuyện trên có ẩn chứa những sự kiện có thật.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/oldest-known-picture-of-the-pied-piper-16287570134881806818927.jpg',
alt: "KHÁM PHÁ,CỔ TÍCH,NGƯỜI THỔI SÁO,",
category: 'news',
time: '10:38 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:38 PM',
link: '/nguoi-thoi-sao-thanh-hamelin-cau-chuyen-rung-ron-ve-vu-mat-tich-cua-130-dua-tre',
component: 'page_20210813223802'},
{title: "Thiếu niên thức trắng 11 đêm để \"phục vụ khoa học\", và đây là những gì đã xảy ra",
description: "Năm 1963, có 2 cậu thiếu niên quyết định bắt tay vào làm một dự án khoa học, và cuối cùng chẳng ngờ ghi danh vào Kỷ lục Guinness thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-16288545350811022662061.jpeg',
alt: "KHOA HỌC,",
category: 'news',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/thieu-nien-thuc-trang-11-dem-de-phuc-vu-khoa-hoc-va-day-la-nhung-gi-da-xay-ra',
component: 'page_20210813092956'},
{title: "Chứng minh \"luyện cơ\" trên giường là cách giảm cân hiệu quả, nữ YouTuber khiến CĐM không khỏi phấn khích",
description: "Rất nhiều khán giản đã bày tỏ sự ủng hộ với video của nữ YouTuber này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/13/photo-1-16288285076622116945542.jpg',
alt: "GÁI XINH,HOT GIRL,NỮ YOUTUBER,YOUTUBER,",
category: 'images',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/chung-minh-luyen-co-tren-giuong-la-cach-giam-can-hieu-qua-nu-youtuber-khien-cdm-khong-khoi-phan-khich',
component: 'page_20210813092942'},
{title: "Nữ phản diện trong Kamen Rider Revice khiến fan ngây ngất vì nhan sắc trời cho",
description: "Tuy Kamen Rider Revice vẫn chưa ra mắt nhưng nữ diễn viên Yui Asakura đã khiến cho nhiều fan đứng ngồi không yên nhờ nhan sắc trời cho của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/hoi1-16288340857632120564090.jpg',
alt: "BỘ ẢNH COSPLAY,COSPLAY,KAMEN RIDER REVICE,KAMEN RIDER,CHÙM ẢNH COSPLAY,NỮ COSER,",
category: 'games',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/nu-phan-dien-trong-kamen-rider-revice-khien-fan-ngay-ngat-vi-nhan-sac-troi-cho',
component: 'page_20210813092931'},
{title: "Bị fan chê “tâm hồn\" màn hình phẳng, nữ streamer vừa nhún nhảy vừa kéo áo khoe khéo vòng 1 phập phồng",
description: "Rất ít lần xuất hiện với phong cách sexy, nữ streamer thu hút người xem.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-16288450560971743268108.jpg',
alt: "STREAMER,THẢO NGUYÊN,CỘNG ĐỒNG MẠNG,TÂM HỒN,SEXY,",
category: 'images',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/bi-fan-che-tam-hon-man-hinh-phang-nu-streamer-vua-nhun-nhay-vua-keo-ao-khoe-kheo-vong-1-phap-phong',
component: 'page_20210813092920'},
{title: "Dragon Ball Super: Đều thuộc cấp độ Thần, thế nhưng Ultra Instinct hay Hakai kỹ thuật nào mạnh hơn?",
description: "Trước đó trong chapter 71 của Dragon Ball Super chúng ta đã thấy Beerus và Whis đang cạnh tranh với nhau, nhằm chứng minh Ultra Instinct hay Hakai mạnh hơn. Và đến bây giờ chúng ta tiếp tục thấy cuộc chiến giữa Goku và Vegeta cũng về vấn đề này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/anh-1-16287495909931430819453.jpg',
alt: "DRAGON BALL SUPER,GOKU VS VEGETA,ULTRA INSTINCT,BẢN NĂNG VÔ CỰC,HAKAI,THẦN HỦY DIỆT,THIÊN SỨ,",
category: 'games',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/dragon-ball-super-deu-thuoc-cap-do-than-the-nhung-ultra-instinct-hay-hakai-ky-thuat-nao-manh-hon',
component: 'page_20210813092908'},
{title: "Lê Bống đăng ảnh giường chiếu tình tứ bên gái lạ cùng clip toàn tiếng “nhạy cảm”, hot girl thân mật kia là ai?",
description: "Đăng ảnh giường chiếu tình tứ bên gái lạ cùng clip toàn tiếng “nhạy cảm”, Lê Bống khiến CĐM đỏ mặt truy lùng danh tính hot girl.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-16288263234501839838603.jpg',
alt: "LÊ BỐNG,TRANG LÊ,GÁI XINH,TIKTOK,HOT GIRL,TIKTOKER,CỘNG ĐỒNG MẠNG,CĐM,MXH,MẠNG XÃ HỘI,",
category: 'images',
time: '09:28 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:28 AM',
link: '/le-bong-dang-anh-giuong-chieu-tinh-tu-ben-gai-la-cung-clip-toan-tieng-nhay-cam-hot-girl-than-mat-kia-la-ai',
component: 'page_20210813092850'},
{title: "Những nhân vật Anime có khả năng kháng độc tuyệt diệu, có người còn coi độc là thức ăn luôn",
description: "Dù cho trúng phải loại độc gì thì những nhân vật anime này cũng không gục ngã.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-1628823377261838656799.jpg',
alt: "ANIME/MANGA,KHẢ NĂNG MIỄN ĐỘC,NHÂN VẬT ANIME KHÁNG ĐỘC,KHẢ NĂNG KHÁNG ĐỘC,CHẤT ĐỘC TRONG ANIME,",
category: 'games',
time: '09:28 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:28 AM',
link: '/nhung-nhan-vat-anime-co-kha-nang-khang-doc-tuyet-dieu-co-nguoi-con-coi-doc-la-thuc-an-luon',
component: 'page_20210813092840'},
{title: "Bỏ nghề streamer đi quảng cáo nội y, cô gái xinh đẹp bất ngờ lột xác, tái xuất siêu gợi cảm khiến người xem bất ngờ",
description: "Màn tái xuất của cô nàng streamer này khiến không ít người ngỡ ngàng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/page-16283396214311297596010.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,STREAMER,NỮ STREAMER,BITNARA,",
category: 'images',
time: '09:28 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:28 AM',
link: '/bo-nghe-streamer-di-quang-cao-noi-y-co-gai-xinh-dep-bat-ngo-lot-xac-tai-xuat-sieu-goi-cam-khien-nguoi-xem-bat-ngo',
component: 'page_20210813092816'},
{title: "Những nhân vật đã từng phá vỡ được chiếc khiên của Captain America là ai?",
description: "Bất chấp sức mạnh đáng kinh ngạc đến từ chiếc khiên của Captain America, một số người vẫn có thể phá vỡ nó. Dưới đây là những nhân vật mạnh nhất đã từng làm được điều đó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-1628822349591989798565.jpg',
alt: "AVENGERS: ENDGAME,INFINITY GAUNTLET,CAPTAIN AMERICA,KHIÊN CỦA CAPTAIN AMERICA,KHIÊN CỦA CAPTAIN AMERICA BỊ VỠ,THANOS,",
category: 'news',
time: '09:28 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:28 AM',
link: '/nhung-nhan-vat-da-tung-pha-vo-duoc-chiec-khien-cua-captain-america-la-ai',
component: 'page_20210813092806'},
{title: "Mỹ nhân Hoa ngữ lộ khuyết điểm chết người trên phim: Lưu Diệc Phi - Đường Yên chỉ đẹp khi không cười, Triệu Lệ Dĩnh gầy đến trơ xương",
description: "Dù sở hữu nhan sắc nổi trội nhưng các diễn viên này vẫn có nhược điểm về ngoại hình và bị lộ rõ khi lên phim.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/10/3-16286131376591082157721.jpg',
alt: "Lưu Diệc Phi,Đường Yên,triệu lệ dĩnh,Dương Mịch,Lưu Thi Thi,Châu Tấn,Địch Lệ Nhiệt Ba,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:27 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:27 AM',
link: '/my-nhan-hoa-ngu-lo-khuyet-diem-chet-nguoi-tren-phim-luu-diec-phi-duong-yen-chi-dep-khi-khong-cuoi-trieu-le-dinh-gay-den-tro-xuong',
component: 'page_20210813092754'},
{title: "Kết cục của Tứ đại mỹ nhân: Chiêu Quân làm vợ 3 đời cha con, Điêu Thuyền bị Quan Vũ chê chẳng còn trong sạch, Tây Thi lại càng thảm",
description: "Có lẽ câu \"hồng nhan bạc phận\" đều ứng với số phận của Tứ đại mỹ nhân Trung Quốc cổ đại.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/24/-16245354592749449386.jpg',
alt: "tứ đại mỹ nhân,hồng nhan bạc phận,Tam Quốc Diễn Nghĩa,",
category: 'news',
time: '09:27 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:27 AM',
link: '/ket-cuc-cua-tu-dai-my-nhan-chieu-quan-lam-vo-3-doi-cha-con-dieu-thuyen-bi-quan-vu-che-chang-con-trong-sach-tay-thi-lai-cang-tham',
component: 'page_20210813092743'},
{title: "Đát Kỷ không phải \"hồ ly tinh\"! Viện nghiên cứu Trung Quốc khôi phục lại dung mạo của nàng với hình tượng nữ tướng",
description: "Đát Kỷ vẫn thường được người đời nhắc đến với hình ảnh của một hồ yêu mê hoặc Trụ Vương khiến nhà Thương diệt vong. Nàng trong lịch sử thực chất là người thế nào?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/12/dat-ky-16287337036721111533094.jpg',
alt: "Hồ ly tinh,Di tích lịch sử,Đát Kỷ,Lịch sử Trung Quốc,",
category: 'news',
time: '09:27 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:27 AM',
link: '/dat-ky-khong-phai-ho-ly-tinh-vien-nghien-cuu-trung-quoc-khoi-phuc-lai-dung-mao-cua-nang-voi-hinh-tuong-nu-tuong',
component: 'page_20210813092730'},
{title: "Lắc lư, ngả ngốn trên ghế khi lên sóng, nữ streamer xinh đẹp gặp tai nạn, tiếp đất bằng mặt và cái kết để lại nhiều nghi vấn",
description: "Một số người thậm chí còn tình nghi rằng phải chăng đây là kịch bản mà cô nàng streamer này cố tình dàn dựng ra nữa.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/13/photo-2-1628843711216357538514.jpg',
alt: "streamer,gặp tai nạn,thân hình nóng bỏng,Người mẫu ảnh,cộng đồng mạng,Phát triển bản thân,",
category: 'images',
time: '09:26 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:26 AM',
link: '/lac-lu-nga-ngon-tren-ghe-khi-len-song-nu-streamer-xinh-dep-gap-tai-nan-tiep-dat-bang-mat-va-cai-ket-de-lai-nhieu-nghi-van',
component: 'page_20210813092604'},
{title: "Cùng nhìn lại lịch sử phát triển của làng game Việt qua các mốc thời gian - Mở đầu cho trào lưu auto trong game, Kiếm Thế có \"công hay tội\"?",
description: "Kiếm Thế có thể coi là tựa game đi tiên phong cho trào lưu game \"mì ăn liền\" sau này ở Việt Nam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-1628312214490603217886.jpg',
alt: "GAME,GAME THỦ,KIẾM THẾ,TIN TỨC GAME,MINH CHÂU TAM QUỐC,",
category: 'games',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/cung-nhin-lai-lich-su-phat-trien-cua-lang-game-viet-qua-cac-moc-thoi-gian-mo-dau-cho-trao-luu-auto-trong-game-kiem-the-co-cong-hay-toi',
component: 'page_20210813092549'},
{title: "Mỹ nhân Nhật Bản kể chuyện bị mất việc vì gò bồng đảo quá khủng, tức mình chuyển nghề 18+",
description: "Ngay từ lúc còn đi học, cô nàng Riri Nanatsumori đã sở hữu bộ ngực lớn hơn so với các bạn đồng trang lứa.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/photo-1-16287504068951491005346.jpg',
alt: "RIRI NANATSUMORI,IDOL,",
category: 'tech',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/my-nhan-nhat-ban-ke-chuyen-bi-mat-viec-vi-go-bong-dao-qua-khung-tuc-minh-phai-chuyen-nghe-18',
component: 'page_20210813092540'},
{title: "ViewSonic VX2468-PC-MHD: Màn gaming siêu mượt 165Hz, giá lại còn \"hạt dẻ\"",
description: "Mẫu màn hình gaming mới toanh tới từ ViewSonic, với một thiết kế sáng tạo và độc đáo, đi kèm với hiệu năng ấn tượng. Sản phẩm này được kỳ vọng làm mọi game thủ hài lòng với mức giá vô cùng hợp lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/nested-sequence-0900030624still003-1628771661082495164199.jpg',
alt: "MÀN HÌNH VIEWSONIC,MÀN HÌNH GAMING,VX2468-PC-MHD,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/viewsonic-vx2468-pc-mhd-man-gaming-sieu-muot-165hz-gia-lai-con-hat-de',
component: 'page_20210813092530'},
{title: "One Piece: \"Ác quỷ\" thức tỉnh bên trong Nico Robin chính là khả năng nguy hiểm nhất của băng Mũ Rơm?",
description: "Hình thức mới của Nico Robin trong chapter 1021 thực sự khiến các fan vô cùng phấn khích. Nhiều người cho rằng đây chính là khả năng thức tỉnh nguy hiểm nhất trong băng Mũ Rơm.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/anh-5-1628752426219749165279.jpg',
alt: "ONE PIECE,TRÁI ÁC QUỶ,NICO ROBIN,THỨC TỈNH TRÁI ÁC QUỶ,BLACK MARIA,TOBI ROPPO,",
category: 'games',
time: '09:25 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:25 AM',
link: '/one-piece-ac-quy-thuc-tinh-ben-trong-nico-robin-chinh-la-kha-nang-nguy-hiem-nhat-cua-bang-mu-rom',
component: 'page_20210813092517'},
{title: "Record Of Ragnarok: Thảng thốt khi thấy nàng Á thần Brunhilde bằng xương bằng thịt, ăn mặc sexy khoe body xịn đét",
description: "Thế giới của Record Of Ragnarok có rất nhiều mỹ nhân xinh đẹp khiến các fan mê mệt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628738437581655397035.jpg',
alt: "SHUUMATSU NO VALKYRIE,RECORD OF RAGNAROK,BRUNHILDE,VALKYRIE,COSPLAY,COSER,",
category: 'images',
time: '09:23 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:23 AM',
link: '/record-of-ragnarok-thang-thot-khi-thay-nang-a-than-brunhilde-bang-xuong-bang-thit-an-mac-sexy-khoe-body-xin-det',
component: 'page_20210813092334'},
{title: "Top 5 nhóc tỳ đáng yêu nhất manga, chưa ai vượt qua nổi \"Shin bút chì\" với những câu chuyện cực bựa",
description: "Phải công nhận là em bé và con nít trong thế giới anime/manga thật dễ thương vô cùng. Mắt to tròn lấp lánh, hành động ngây ngô, đôi khi việc tỏ ra già trước tuổi của những đứa nhóc này thật \"đốn tim\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/anh-5-1628667756398797204709.png',
alt: "ANIME NHẬT BẢN,NHÓC TỲ,NHÂN VẬT ANIME,SHIN CẬU BÉ BÚT CHÌ,CHIE CÔ BÉ HẠT TIÊU,NHÓC MARUKO,",
category: 'games',
time: '09:23 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:23 AM',
link: '/top-5-nhoc-ty-dang-yeu-nhat-manga-chua-ai-vuot-qua-noi-shin-but-chi-voi-nhung-cau-chuyen-cuc-bua',
component: 'page_20210813092324'},
{title: "“Bạn gái mai mối” của ViruSs vừa có pha hớ hênh tai hại khi lên hình với chế độ “thả rông\"",
description: "Đăng tải clip skincare trước khi ngủ, “vùng cấm” của hot girl được mai mối với ViruSs hớ hênh táo bạo.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/photo-1-16287378005141042991511.jpg',
alt: "VIRUSS,BẠN GÁI,SUNNA,GÁI XINH,LỘ,",
category: 'images',
time: '09:22 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:22 AM',
link: '/ban-gai-mai-moi-cua-viruss-vua-co-pha-ho-henh-tai-hai-khi-len-hinh-voi-che-do-tha-rong',
component: 'page_20210813092243'},
{title: "Lên sóng khoe body siêu nuột, nữ streamer triệu follow bất ngờ bị fan soi ra đầu gối thâm đen, đặt nghi vấn đầy \"tế nhị\"",
description: "Bản thân cô nàng streamer có lẽ cũng chẳng thể ngờ mình lại bị để ý kỹ tới vậy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/photo-1-16285730174161660398690.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,STREAMER,HAN-KYUNG,NỮ STREAMER,",
category: 'images',
time: '09:22 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:22 AM',
link: '/len-song-khoe-body-sieu-nuot-nu-streamer-trieu-follow-bat-ngo-bi-fan-soi-ra-dau-goi-tham-den-dat-nghi-van-day-te-nhi',
component: 'page_20210813092217'},
{title: "Top 10 tựa game chi phí phát triển không bao nhiêu nhưng gom tiền nhiều như bom tấn (P.2)",
description: "Những siêu phẩm làng game không nhất tiền phải đầu tư tiền tỷ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628711658851477207572.jpg',
alt: "GAME,GAME HAY,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,GAME ĐỈNH,",
category: 'games',
time: '09:22 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:22 AM',
link: '/top-10-tua-game-chi-phi-phat-trien-khong-bao-nhieu-nhung-gom-tien-nhieu-nhu-bom-tan-p2',
component: 'page_20210813092207'},
{title: "Trên tay cặp RAM \"quái thú\" Kingston Fury Renegade RGB 4600, nằm trong top đầu thế giới",
description: "Kingston Fury Renegade RGB 4600 sở hữu hiệu năng vô cùng khủng khiếp dành cho những game thủ hàng đầu và \"dân chơi\" công nghệ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628702578003297069338.jpg',
alt: "RAM QUÁI THÚ,RAM MẠNH NHẤT THẾ GIỚI,LINH KIỆN MÁY TÍNH,RAM,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/tren-tay-cap-ram-quai-thu-kingston-fury-renegade-rgb-4600-nam-trong-top-dau-the-gioi',
component: 'page_20210813092159'},
{title: "Dragon Ball Super: Không phải Zeno, đây mới là người quyền lực đứng đằng sau thống trị đa vũ trụ?",
description: "Trong Dragon Ball Super, thì có vẻ như Zeno không phải là người nắm giữ quyền lực thực sự.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/anh-1-162866537721938394384.jpeg',
alt: "DRAGON BALL SUPER,THẦN HỦY DIỆT,ĐA VŨ TRỤ,KING ZENO,GRAND PRIEST,THIÊN SỨ,",
category: 'games',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/dragon-ball-super-khong-phai-zeno-day-moi-la-nguoi-quyen-luc-dung-dang-sau-thong-tri-da-vu-tru',
component: 'page_20210813092150'},
{title: "Những sự thật đen tối đằng sau câu chuyện Bạch Tuyết và bảy chú lùn",
description: "Câu chuyện cổ tích \"Nàng Bạch Tuyết và bảy chú lùn\" có thể là sản phẩm của rất nhiều câu chuyện ghép lại. Không ít trong số đó vô cùng đen tối.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/snow-white2-1628671589734535858386.jpg',
alt: "TRUYỀN THUYẾT,TRUYỆN CỔ TÍCH,NÀNG BẠCH TUYẾT VÀ BẢY CHÚ LÙN,",
category: 'news',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/nhung-su-that-den-toi-dang-sau-cau-chuyen-bach-tuyet-va-bay-chu-lun',
component: 'page_20210813092140'},
{title: "NÓNG: Seungri (BIGBANG) chính thức bị kết án 3 năm tù giam, phạt số tiền khổng lồ vì 2 tội danh",
description: "Vào ngày hôm nay (12/8), Tòa án đã đưa ra mức án cuối cùng dành cho Seungri sau vụ bê bối Burning Sun.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/screenshot-47-16287555137381276804635.png',
alt: "seungri,Seungri (BIGBANG),Bê bối tình dục chấn động của Seungri,Seungri chính thức là nghi phạm môi giới mại dâm,club của seungri dính phốt tấn công tình dục,Burning Sun,scandal burning sun,yoo in suk,bigbang,",
category: 'stars',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/nong-seungri-chinh-thuc-bi-ket-an-3-nam-tu-giam-phat-215-ty-dong',
component: 'page_20210813092129'},
{title: "Ngất lịm với nhan sắc bùng nổ của \"gái 1 con\" Triệu Lệ Dĩnh ngày hôm nay, đúng là phụ nữ đẹp nhất khi không thuộc về ai",
description: "Vẫn biết nhan sắc của Triệu Lệ Dĩnh càng ngày càng xuất sắc nhưng quả thật cô nàng khiến ai cũng phải trầm trồ với visual \"lên hương\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/photo-1-16287732196331015836426.jpg',
alt: "triệu lệ dĩnh,Mỹ nhân Hoa ngữ,nhan sắc của sao,nhan sắc mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/ngat-lim-voi-nhan-sac-bung-no-cua-gai-1-con-trieu-le-dinh-ngay-hom-nay-dung-la-phu-nu-dep-nhat-khi-khong-thuoc-ve-ai',
component: 'page_20210813092120'},
{title: "7 nàng a hoàn đẹp nhất màn ảnh Hoa ngữ: Phạm Băng Băng - Dương Mịch thần thái ngời ngời, \"trùm cuối\" còn xinh hơn cả Yoona",
description: "Dù chỉ vào vai a hoàn, nhưng những nữ diễn viên này vẫn tỏa sáng nhờ khả năng diễn xuất tốt cùng nhan sắc nổi bật của mình.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/14-16286997509131783388598.jpg',
alt: "Trần Tiểu Vân,Phạm Băng Băng,Dương Mịch,triệu lệ dĩnh,Mạnh Tử Nghĩa,thư sướng,Lưu Đào,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/7-nang-a-hoan-dep-nhat-man-anh-hoa-ngu-pham-bang-bang-duong-mich-than-thai-ngoi-ngoi-trum-cuoi-con-xinh-hon-ca-yoona',
component: 'page_20210813092106'},
{title: "Cùng nhìn lại lịch sử phát triển của làng game Việt qua các mốc thời gian: 2005-2009, thời kỳ game online cực thịnh nhưng đi kèm với nhiều định kiến (p2)",
description: "2005 - 2009 có thể coi là quãng thời gian phát triển mạnh mẽ nhất của làng game Việt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/photo-1-16282477392111513328995.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,AUDITION,CAO BỒI KHÔNG GIAN,VÕ LÂM TRUYỀN KỲ,MU ONLINE,PRISON TALE,",
category: 'games',
time: '09:20 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:20 AM',
link: '/cung-nhin-lai-lich-su-phat-trien-cua-lang-game-viet-qua-cac-moc-thoi-gian-2005-2009-thoi-ky-game-online-cuc-thinh-nhung-di-kem-voi-nhieu-dinh-kien-p2',
component: 'page_20210813092031'},
{title: "Tựa game Call of Duty tiếp theo sẽ trở lại với bối cảnh thế chiến thứ hai",
description: "Theo những thông tin bị lộ mới nhất, Activision sẽ một lần nữa đem series game đình đám trở lại thế chiến thứ hai.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-16287848600011503657039.jpg',
alt: "CALL OF DUTY,THẾ CHIẾN THỨ HAI,THÁI BÌNH DƯƠNG,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/tua-game-call-of-duty-tiep-theo-se-tro-lai-voi-boi-canh-the-chien-thu-hai',
component: 'page_20210812173339'},
{title: "Điện tử 4 nút, điện tử xèng và những chiếc máy gợi nhớ về tuổi thơ không thể quên của các game thủ",
description: "Những chiếc máy này chắc chắn đã là một phần tuổi thơ không thể xóa nhòa đối với các game thủ thế hệ 8-9x.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/photo-1-1628488000334741755679.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/dien-tu-4-nut-dien-tu-xeng-va-nhung-chiec-may-goi-nho-ve-tuoi-tho-khong-the-quen-cua-cac-game-thu',
component: 'page_20210812173329'},
{title: "10 nhân vật MCU được xây dựng \"rất khác\" so với phiên bản truyện tranh",
description: "Không chỉ có một ngoại hình khác, mà những nhân vật này còn có một câu chuyện khác giữa phiên bản truyện tranh và MCU.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-16287766964011376795581.jpg',
alt: "MCU,COMIC SIÊU ANH HÙNG,MARVEL,NHÂN VẬT MARVEL BỊ THAY ĐỔI,ALDRICH KILLIAN,MORDO,",
category: 'news',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/10-nhan-vat-mcu-duoc-xay-dung-rat-khac-so-voi-phien-ban-truyen-tranh',
component: 'page_20210812173319'},
{title: "10 game co-op cho bạn làm trùm, có thể \"đồ sát\" tất cả bạn bè cùng chơi (P2)",
description: "Đây đều là những game co-op rất hay mà bạn không nên bỏ qua.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/photo-1-16287855378722059225818.jpg',
alt: "VIRUS NGUY HIỂM,KHU VỰC NGUY HIỂM,VIRUS CHẾT NGƯỜI,GAME HÀNH ĐỘNG,GAME CO-OP,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/10-game-co-op-cho-ban-lam-trum-co-the-do-sat-tat-ca-ban-be-cung-choi-p2',
component: 'page_20210812173310'},
{title: "Dragon Ball Super: Mãn nhãn ngắm \"from hủy diệt\" của Vegeta, hoàng tử không lông mày nhưng vẫn ngầu bá cháy",
description: "Fan Vegeta cuối cùng cũng đợi được đến ngày \"anh Rau\" tỏa sáng rồi!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-162878158453327091717.jpg',
alt: "DRAGON BALL SUPER,DRAGON BALL,VEGETA,SUPER SAIYAN,VEGETA FROM HỦY DIỆT,",
category: 'games',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/dragon-ball-super-man-nhan-ngam-from-huy-diet-cua-vegeta-hoang-tu-khong-long-may-nhung-van-ngau-ba-chay',
component: 'page_20210812173301'},
{title: "Trên tay màn hình gaming VX3268-2KPC-MHD: Cong, to, chơi game siêu đã",
description: "Nếu như bạn đang tìm kiếm một mẫu màn hình gaming cong với kích cỡ lớn, độ phân giải cao nhưng đi kèm với một mức giá hợp lý thì ViewSonic VX3268-2KPC-MHD quả thật là sự lựa chọn siêu phù hợp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/sequence-0300050108still016-16287791555691728394936.png',
alt: "MÀN HÌNH GAMING,VIEWSONIC,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/tren-tay-man-hinh-gaming-vx3268-2kpc-mhd-cong-to-choi-game-sieu-da',
component: 'page_20210812173254'},
{title: "Top 4 bóng hồng \"ngoài lạnh trong nóng\" trong anime: Vẻ ngoài lạnh như băng nhưng vẫn khiến fan \"tan chảy\", đổ ầm ầm",
description: "Đây chính là 4 gương mặt khiến các khán giả hâm mộ anime phải đổ gục vì vẻ ngoài lạnh lùng cùng tính cách trái ngược của mình",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-1628767919213968256515.jpg',
alt: "GAME MOBILE,GAME ONLINE,GAME DI ĐỘNG,ANIME,KHUYỂN DẠ XOA TRUYỀN KỲ - IP INUYASHA,",
category: 'games',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/top-4-bong-hong-ngoai-lanh-trong-nong-trong-anime-ve-ngoai-lanh-nhu-bang-nhung-van-khien-fan-tan-chay-do-am-am',
component: 'page_20210812173242'},
{title: "TOP 4 nhân vật có thể \"bán hành\" cho Đông Phương Bất Bại: Top 1 \"ngồi chơi xơi nước\" vẫn \"ez win\"",
description: "Không phải đánh hòa, mà là đánh bại.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-16287608828061340195011.jpg',
alt: "TÂN MINH CHỦ,GAME MOBILE,GAME DI ĐỘNG,GAME ONLINE,KIM DUNG,GAME CHIẾN THUẬT,ĐÔNG PHƯƠNG BẤT BẠI,",
category: 'news',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/top-4-nhan-vat-co-the-ban-hanh-cho-dong-phuong-bat-bai-top-1-ngoi-choi-xoi-nuoc-van-ez-win',
component: 'page_20210812173230'},
{title: "ViewSonic VX2468-PC-MHD: Màn gaming siêu mượt 165Hz, giá lại còn \"hạt dẻ\"",
description: "Mẫu màn hình gaming mới toanh tới từ ViewSonic, với một thiết kế sáng tạo và độc đáo, đi kèm với hiệu năng ấn tượng. Sản phẩm này được kỳ vọng làm mọi game thủ hài lòng với mức giá vô cùng hợp lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/nested-sequence-0900030624still003-1628771661082495164199.jpg',
alt: "MÀN HÌNH VIEWSONIC,MÀN HÌNH GAMING,VX2468-PC-MHD,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/viewsonic-vx2468-pc-mhd-man-gaming-sieu-muot-165hz-gia-lai-con-hat-de',
component: 'page_20210812173217'},
{title: "Hoà Minzy hé lộ bí mật về lần đầu công khai quý tử hào môn, tung luôn ảnh khoe body nuột khó tin chỉ sau 8 tháng sinh",
description: "Hoà Minzy đã chia sẻ vlog chuyến đi du lịch đầu tiên của bé Bo, vô tình để lộ body mẹ bỉm sau khi bí mật sinh con.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/hoa-minzy-chinh-thuc-xac-nhan-co-con-cong-bo-hinh-anh-con-trai-af9a5ee4-16287865436941841377666.jpg',
alt: "hòa minzy,ca sĩ hoà minzy,gia đình Hoà Minzy,bé bo con trai hoà minzy,con sao việt,sao Việt,showbiz Việt,body của sao,Nhóc tỳ sao việt,con của sao,Hoà Minzy sinh con,",
category: 'stars',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/hoa-minzy-he-lo-bi-mat-ve-lan-dau-cong-khai-quy-tu-hao-mon-tung-luon-anh-khoe-body-nuot-kho-tin-chi-sau-8-thang-sinh',
component: 'page_20210812173200'},
{title: "Vì sao những người ưu tú luôn đặt báo thức lúc 5:57 sáng?",
description: "Lên kế hoạch chuẩn bị trước không nằm ở chỗ bạn đã làm bao nhiêu việc, mà là sau khi bạn nhận thức được việc này, bạn sẽ có được trạng thái năng lượng cao để làm việc. Cuộc sống của bạn cũng từ đó mà có một diện mạo hoàn toàn mới.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/photo-1-1628772521685332629050.jpg',
alt: "hãng truyền thông,doanh nhân nổi tiếng,thời gian làm việc,quản lý cấp cao,năng lượng cơ thể,",
category: 'life',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/vi-sao-nhung-nguoi-uu-tu-luon-dat-bao-thuc-luc-557-sang',
component: 'page_20210812173151'},
{title: "Liều thuốc \"thần tiên\" khiến trái tim chẳng thể ngủ yên và câu chuyện phía sau những bộ phim ngôn tình \"ngọt sún răng\" của màn ảnh Hoa ngữ",
description: "Những năm gần đây, nhà sản xuất phim Trung Quốc ưa thích đem các tác phẩm ngôn tình \"ngọt sâu răng\" lên phim. Dù nội dung đơn giản, không có tình tiết tréo ngoe hay ân oán tình thù rối não, thế nhưng thể loại phim này vẫn thu hút sự quan tâm của đông đảo khán giả.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/12-16287686339931475075859.jpg',
alt: "Trung Quốc,phim ngôn tình Trung Quốc,sức khỏe tinh thần,phim chuyển thể ngôn tình,Phim tình cảm,giải tỏa stress,phim ngôn tình,phim ngôn tình Hoa ngữ,phim ngôn tình chuyển thể,ngọt sún răng,liều thuốc cho trái tim,",
category: 'news',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/phia-sau-nhung-bo-phim-ngon-tinh-ngot-sun-rang-cua-man-anh-hoa-ngu-lieu-thuoc-than-tien-khien-trai-tim-chang-the-ngu-yen',
component: 'page_20210812173141'},
{title: "Phim tài liệu rùng rợn về UFO vừa ra mắt đã leo top: Hàng loạt bằng chứng người ngoài hành tinh có thật, \"lật tẩy\" âm mưu che đậy trong quá khứ?",
description: "Chỉ vừa mới ra mắt, phim tài liệu về người ngoài hành tinh và UFO của Netflix đã nhanh chóng leo top thịnh hành thế giới.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/screen-shot-2021-08-12-at-153253-16287574352901294696728.png',
alt: "UFO,UFO & người ngoài hành tinh,phim tài liệu,thông tin về UFO,Người ngoài hành tinh,phim Âu Mỹ,Kỳ án rúng động thế giới lên phim,Top Secret UFO Projects: Declassified (2021),",
category: 'news',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/phim-tai-lieu-rung-ron-ve-ufo-vua-ra-mat-da-leo-top-hang-loat-bang-chung-nguoi-ngoai-hanh-tinh-co-that-lat-tay-am-muu-che-day-trong-qua-khu',
component: 'page_20210812173132'},
{title: "Tạo hình 5 nàng Tiểu Long Nữ xứ Trung: Lý Nhược Đồng - Lưu Diệc Phi vững ngôi thần tiên tỷ tỷ, 2 phiên bản mới nhất đua nhau rời xa nguyên tác",
description: "Thật bất ngờ khi có 1 nàng Tiểu Long Nữ lại gia nhập hội ghiền đùi gà!",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/photo-1-16286933955911077975879.jpg',
alt: "Tiểu Long Nữ,Lưu Diệc Phi,Phạm Văn Phương,Mao Hiểu Huệ,Trần Nghiên Hy,Lý Nhược Đồng,Thần điêu đại hiệp,",
category: 'stars',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/tao-hinh-5-nang-tieu-long-nu-xu-trung-ly-nhuoc-dong-luu-diec-phi-vung-ngoi-than-tien-ty-ty-2-phien-ban-moi-nhat-dua-nhau-roi-xa-nguyen-tac',
component: 'page_20210812173119'},
{title: "Ngọc Trinh diện áo hở trên, cắt dưới, vòng 1 bèn chơi trốn tìm luôn",
description: "Ngọc Trinh thường xuyên có những pha chơi \"ú oà\" với chính vòng 1 của mình.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/1569885654275627350160585801166340115404300n-161511920757831234536-1615178661479-16151786617951936696395-16286859687671815588616.jpeg',
alt: "sao đỏ thời trang,Ngọc Trinh,sao mặc xấu,mặc đẹp,vòng 1 sao,showbiz Việt,sao Việt,",
category: 'stars',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/ngoc-trinh-dien-ao-ho-tren-cat-duoi-vong-1-ben-choi-tron-tim-luon',
component: 'page_20210811110039'},
{title: "Lệ Quyên đáp trả khi loạt \"ảnh cưới\" chụp với Lâm Bảo Châu bị mỉa mai \"như hai mẹ con\"",
description: "Trên trang cá nhân, Lệ Quyên chia sẻ đầy ẩn ý về loạt khoảnh khắc được dân tình xôn xao là ảnh cưới.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/3-16278737249272036986130-16286560802871304075358-1628683934743661208012.png',
alt: "lệ quyên,Lệ Quyên và Lâm Bảo Châu,Lệ Quyên và Lâm Bảo Châu hẹn hò,Lệ Quyên ly hôn,con trai lệ quyên,ca sĩ lệ quyên,Lâm Bảo Châu,sao hẹn hò,sao Việt,showbiz Việt,",
category: 'life',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/le-quyen-dap-tra-khi-loat-anh-cuoi-chup-voi-lam-bao-chau-bi-mia-mai-nhu-hai-me-con',
component: 'page_20210811110028'},
{title: "Sao Hoa ngữ bị chê không đủ đẹp để đóng đại mỹ nhân: Triệu Lệ Dĩnh thiếu khí chất, \"trùm cuối\" phá nát hình tượng Hằng Nga",
description: "Kể cả khi diễn xuất rất tốt thì những nữ diễn viên này vẫn bị chê không lột tả được vẻ đẹp của các đại mỹ nhân nức tiếng.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/3-16286627599161317881769.jpg',
alt: "triệu lệ dĩnh,viên san san,an dĩ hiên,vương lệ khôn,dĩnh nhi,ân đào,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/sao-hoa-ngu-bi-che-khong-xung-vao-vai-dai-my-nhan-trieu-le-dinh-thieu-khi-chat-trum-cuoi-pha-nat-hinh-tuong-hang-nga',
component: 'page_20210811110019'},
{title: "Cả thanh xuân dùng máy tính, liệu bạn có biết hết những phím tắt \"thần thánh\" này?",
description: "Dùng Windows lâu năm, nhưng có những phím tắt đến bây giờ chúng ta mới biết.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/base64-16286003709001840645289.png',
alt: "bạn có biết?,tổ hợp phím,tiết kiệm thời gian,đa chức năng,ứng dụng windows,",
category: 'tech',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/ca-thanh-xuan-dung-may-tinh-lieu-ban-co-biet-het-nhung-phim-tat-than-thanh-nay',
component: 'page_20210811110000'},
]
export const dataContent05=[{title: "Mỹ nhân Hoa - Hàn U40 mà vẫn hóa nữ sinh ngon ơ: Triệu Lệ Dĩnh có đỉnh bằng loạt \"ma cà rồng\" của Hàn Quốc?",
description: "Bạn thích tạo hình nữ sinh của mỹ nhân nào nhất?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/22490033944008436167005745741514556988718863n-1628643044228495697507.jpg',
alt: "nữ sinh,sao Hoa ngữ,sao Trung,sao Hàn,Cbiz,Kbiz,triệu lệ dĩnh,jang nara,park bo young,star style,làm đẹp,Makeup,tạo hình trong phim,",
category: 'stars',
time: '10:59 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:59 AM',
link: '/my-nhan-hoa-han-u40-ma-van-hoa-nu-sinh-ngon-o-trieu-le-dinh-co-dinh-bang-loat-ma-ca-rong-cua-han-quoc',
component: 'page_20210811105927'},
{title: "Tổng hợp loạt lùm xùm để đời của Lê Bống, từ những lần bị ném đá vô lý cho tới vô số hình ảnh phản cảm khó bào chữa",
description: "Kể ra thì Lê Bống cũng vướng vào không ít những rắc rối kể từ khi ra mắt thì phải.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/8/photo-1-16284105172572014784813.jpeg',
alt: "HOT GIRL,GÁI XINH,LÊ BỐNG,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '10:58 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:58 AM',
link: '/tong-hop-loat-lum-xum-de-doi-cua-le-bong-tu-nhung-lan-bi-nem-da-vo-ly-cho-toi-vo-so-hinh-anh-phan-cam-kho-bao-chua',
component: 'page_20210811105844'},
{title: "Để mặt mộc lên sóng, nữ streamer nóng bỏng bị chê nhan sắc xuống hạng, chính chủ ngao ngán phân trần",
description: "Cô nàng streamer khẳng định rằng, hình ảnh không được “mượt mà” là do thiết bị kém chất lượng mà thôi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/11/anh-chup-man-hinh-2021-08-11-luc-092221-16286503253121526244614.png',
alt: "STREAMER,VŨ NGỌC KIM CHI,LỘ ẢNH,LIVESTREAM,MẶT MỘC,",
category: 'images',
time: '10:58 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:58 AM',
link: '/de-mat-moc-len-song-nu-streamer-nong-bong-bi-che-nhan-sac-xuong-hang-chinh-chu-ngao-ngan-phan-tran',
component: 'page_20210811105822'},
{title: "\"Bắt lỗi\" những hạt sạn rõ rành rành đầy khó hiểu trong phim Harry Potter",
description: "Dù là một bộ phim bom tấn của Hollywood và được làm trong nhiều năm, thế nhưng sai sót của Harry Potter là rất nhiều và rất dễ bị phát hiện ra.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-16286523770431525969444.png',
alt: "HARRY POTTER,",
category: 'news',
time: '10:58 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:58 AM',
link: '/bat-loi-nhung-hat-san-ro-ranh-ranh-day-kho-hieu-trong-phim-harry-potter',
component: 'page_20210811105812'},
{title: "Những chi tiết thú vị trong Black Widow đã hé lộ thông tin quan trọng về các mối quan hệ thuộc MCU",
description: "Bom tấn Black Widow của nhà Marvel đã cung cấp nhiều thông tin quan trọng mà các fan tò mò từ lâu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628646184462213925237.jpg',
alt: "MARVEL,BLACK WIDOW,MCU,DỊ NHÂN,EASTER EGG BLACK WIDOW,",
category: 'news',
time: '10:58 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:58 AM',
link: '/nhung-chi-tiet-thu-vi-trong-black-widow-da-he-lo-thong-tin-quan-trong-ve-cac-moi-quan-he-thuoc-mcu',
component: 'page_20210811105801'},
{title: "Số phận các thuyền viên trên tàu Mary Celeste, bí ẩn hơn 135 năm chưa có lời giải",
description: "Cho đến nay, vụ án các thuyền viên tàu Mary Celeste mất tích vấn là bí ẩn lớn của nhân loại",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/the-missing-crew-of-the-mary-celeste-16285962245971287547253.jpg',
alt: "CON TÀU MA,KHÁM PHÁ,RÙNG RỢN,MARY CELESTE,",
category: 'news',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/so-phan-cac-thuyen-vien-tren-tau-mary-celeste-bi-an-hon-135-nam-chua-co-loi-giai',
component: 'page_20210811105746'},
{title: "Top 10 tựa game chi phí phát triển không bao nhiêu nhưng gom tiền nhiều như bom tấn",
description: "Những siêu phẩm làng game không nhất tiền phải đầu tư tiền tỷ...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628619405076633259899.jpg',
alt: "GAME,SIÊU PHẨM,KINH PHÍ THẤP,CỘNG ĐỒNG MẠNG,BOM TẤN,TIN TỨC GAME,",
category: 'games',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/top-10-tua-game-chi-phi-phat-trien-khong-bao-nhieu-nhung-gom-tien-nhieu-nhu-bom-tan',
component: 'page_20210811105730'},
{title: "Muốn học theo thành công của MCU nhưng 6 vũ trụ điện ảnh này đã thất bại thảm hại",
description: "Không phải hãng phim nào cũng thành công trong việc xây dựng vũ trụ điện ảnh.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/photo-1-16285886879362063622559.jpg',
alt: "VŨ TRỤ ĐIỆN ẢNH,PHIM ĐIỆN ẢNH,X-MEN,DCEU,THE AMAZING SPIDER-MAN,MEN IN BLACK,",
category: 'news',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/muon-hoc-theo-thanh-cong-cua-mcu-day-la-6-vu-tru-dien-anh-da-that-bai-tham-hai',
component: 'page_20210811105720'},
{title: "Dragon Ball Super: Cưới được cô vợ giàu có, vậy Gohan đã làm công việc gì để nuôi sống gia đình?",
description: "Không giống như ông bố Goku phải đi làm nông dân để nuôi sống gia đình, thì Gohan dường như nhàn nhã hơn nhiều.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/10/anh-1-16285785706161476972933.jpg',
alt: "DRAGON BALL,SON GOKU,7 VIÊN NGỌC RỒNG,SON GOHAN,SATAN,CÔNG VIỆC CỦA GOHAN,",
category: 'games',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/dragon-ball-super-cuoi-duoc-co-vo-giau-co-vay-gohan-da-lam-cong-viec-gi-de-nuoi-song-gia-dinh',
component: 'page_20210811105706'},
{title: "300475 hé lộ 5 trận đánh, chiến dịch siêu hùng tráng",
description: "300475 đang trong quá trình phát triển.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/10/photo-1-16285889554181442125226.jpg',
alt: "DỰ ÁN GAME,GAME THUẦN VIỆT,300475,CHIẾN DỊCH HỒ CHÍ MINH,ĐIỆN BIÊN PHỦ TRÊN KHÔNG,GAME LỊCH SỬ,KHÁNG CHIẾN CHỐNG MỸ,",
category: 'games',
time: '10:56 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:56 AM',
link: '/300475-he-lo-5-tran-danh-chien-dich-sieu-hung-trang',
component: 'page_20210811105653'},
{title: "Thánh nữ 18+ một thời yêu cầu studio xóa hết phim cũ, tạm biệt quá khứ huy hoàng",
description: "Rina Ishihara giải nghệ đã lâu, thế nhưng vẫn có rất nhiều đồn đoán về tương lai của thánh nữ 18+ một thời này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/10/photo-1-16285734154011611256084.jpg',
alt: "RINA ISHIHARA,IDOL,",
category: 'images',
time: '10:56 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:56 AM',
link: '/thanh-nu-18-mot-thoi-yeu-cau-studio-xoa-het-phim-cu-tam-biet-qua-khu-huy-hoang',
component: 'page_20210811105630'},
{title: "Những xà thần nổi tiếng trong thần thoại: Hydra còn xếp sau Tương Liễu Thị của Trung Hoa",
description: "Hydra trong thần thoại Hy Lạp và Tương Liễu Thị trong thần thoại Trung Hoa, xà thần nào đáng sợ hơn?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/quainhan-10-1628517629913322917197.jpg',
alt: "THẦN THOẠI HY LẠP,THẦN THOẠI TRUNG HOA,HYDRA,TƯƠNG LIỄU THỊ,",
category: 'news',
time: '10:56 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:56 AM',
link: '/nhung-xa-than-noi-tieng-trong-than-thoai-hydra-con-xep-sau-tuong-lieu-thi-cua-trung-hoa',
component: 'page_20210811105619'},
{title: "Diện áo quá mỏng, nữ streamer bất ngờ lộ chi tiết đầy \"nhạy cảm\" trên sóng khiến người xem tranh cãi kịch liệt",
description: "Có lẽ cô nàng streamer cũng chỉ biết dở khóc dở cười vì mình lại là chủ đề tranh cãi như vậy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/photo-1-16280803925441976784457.jpg',
alt: "GÁI XINH,HOT GIRL,STREAMER,NỮ STREAMER,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '10:56 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:56 AM',
link: '/dien-ao-qua-mong-nu-streamer-bat-ngo-lo-chi-tiet-day-nhay-cam-tren-song-khien-nguoi-xem-tranh-cai-kich-liet',
component: 'page_20210811105600'},
{title: "Những điều thú vị xoay quanh Team Rocket, những kẻ tấu hài tội nghiệp trong thế giới Pokémon",
description: "Vai trò của James, Jessie và Meowth thường là tấu hài chứ ít khi làm được trò trống gì.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/photo-1-1628560536284410144980.png',
alt: "POKÉMON,TEAM ROCKET,",
category: 'games',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/nhung-dieu-thu-vi-xoay-quanh-team-rocket-nhung-ke-tau-hai-toi-nghiep-trong-the-gioi-pokemon',
component: 'page_20210811105549'},
{title: "Top 5 cô nàng anime xinh đẹp với khả năng y tế tuyệt vời, đã thế còn có trái tim thiện lương",
description: "Hầu hết họ đều sở hữu khả năng y tế và chữa bệnh tuyệt vời.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/9/anh-1-16284980747111263394186.jpg',
alt: "ANIME,NHÂN VẬT ANIME,NARUTO,ONE PIECE,FAIRY TAIL,KHẢ NĂNG Y TẾ,",
category: 'games',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/top-5-co-nang-anime-xinh-dep-voi-kha-nang-y-te-tuyet-voi-da-the-con-co-trai-tim-thien-luong',
component: 'page_20210811105539'},
{title: "Cùng nhìn lại lịch sử phát triển của làng game Việt qua các mốc thời gian - Gunbound chứ không phải MU mới là tựa game online đầu tiên? (p1)",
description: "Đây cũng là tựa game mà chẳng nhiều người nghĩ tới đã vô tình \"hủy hoại\" làng game Việt như thế đấy.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/photo-1-1628245014196765231890.jpg',
alt: "GAME,GAME THỦ,MU ONLINE,TIN TỨC GAME,VLTK,GUNBOUND,TS ONLINE,",
category: 'games',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/cung-nhin-lai-lich-su-phat-trien-cua-lang-game-viet-qua-cac-moc-thoi-gian-gunbound-chu-khong-phai-mu-moi-la-tua-game-online-dau-tien-p1',
component: 'page_20210811105527'},
{title: "Mỹ: Người dân Greenville tuyệt vọng trong thị trấn bị cháy rừng xóa sổ",
description: "Nước Mỹ đang chứng kiến đợt cháy rừng khủng khiếp với tổng số gần 40.000 đám cháy, nhiều hơn mức trung bình hàng năm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/photo-1-1628663110255394090395.jpg',
alt: "thế giới,Greenville,cháy rừng Mỹ,tin nóng xã hội,",
category: 'news',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/my-nguoi-dan-greenville-tuyet-vong-trong-thi-tran-bi-chay-rung-xoa-so',
component: 'page_20210811105511'},
{title: "Câu cửa miệng giúp founder TikTok khởi nghiệp thành công chỉ sau 6 năm ra trường: \"Trì hoãn sự thoả mãn lại ngay!\"",
description: "Bạn đã nghe đến thuật ngữ \"trì hoãn thỏa mãn\" bao giờ chưa? Càng biết chờ đợi, thành quả thu được sẽ càng lớn. Đây chính là biết nhịn cái lợi nhỏ để có thể thu được cái lợi lớn hơn. Để làm được, yêu cầu phải kiểm soát tốt được bản thân.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/photo-2-16286533700861518086712.jpg',
alt: "công ty sở hữu,người giàu nhất thế giới,tốt nghiệp đại học,công ty khởi nghiệp,",
category: 'news',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/cau-cua-mieng-giup-founder-tiktok-khoi-nghiep-thanh-cong-chi-sau-6-nam-ra-truong-tri-hoan-su-thoa-man-lai-ngay',
component: 'page_20210811105457'},
{title: "4 nàng Lý Mạc Sầu quyết ăn thua đủ về dung nhan với Tiểu Long Nữ: Người \"phẩy\" light chất chơi, người nóng bỏng muốn \"hấp hối\"",
description: "Bên cạnh bộ đôi nhân vật chính, Lý Mạc Sầu cũng là vai diễn luôn được khán giả quan tâm ở mỗi phiên bản phim Thần Điêu Đại Hiệp.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/photo-1-16283064376731058381652.jpg',
alt: "Lý Mạc Sầu,Thần điêu đại hiệp,Mạnh Quảng Mỹ,Trương Hinh Dư,Mao Lâm Lâm,trần hồng,star style,thần điêu đại hiệp (2014),tạo hình trong phim,tạo hình nhân vật,",
category: 'stars',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/4-nang-ly-mac-sau-quyet-an-thua-du-ve-dung-nhan-voi-tieu-long-nu-nguoi-phay-light-chat-choi-nguoi-nong-bong-muon-hap-hoi',
component: 'page_20210811105443'},
{title: "Loạt thảm hoạ tạo hình phim cổ trang Hoa ngữ: Lưu Diệc Phi bưng cả mâm ngũ quả lên đầu nhưng chưa chắc đọ được combo cá chép của Triệu Lệ Dĩnh?",
description: "Có vẻ những người đẹp Hoa ngữ đình đám ít nhiều từng bị tổ hoá trang dìm khi tham gia dự án phim cổ trang thì phải.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/photo-1-1628426161100814976995.jpg',
alt: "phim cổ trang,Vương Tổ Hiền,Trần Nghiên Hy,Tiểu Long Nữ,Lưu Diệc Phi,Lưu Gia Linh,Địch Lệ Nhiệt Ba,Dương Mịch,Cúc Tịnh Y,star style,sao Cbiz,tạo hình nhân vật,tạo hình trong phim,",
category: 'stars',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/loat-tham-hoa-tao-hinh-phim-co-trang-hoa-ngu-luu-diec-phi-bung-ca-mam-ngu-qua-len-dau-nhung-chua-chac-do-duoc-combo-ca-chep-cua-trieu-le-dinh',
component: 'page_20210811105426'},
{title: "Tứ đại mỹ nhân trên màn ảnh Hoa ngữ: \"Vương Chiêu Quân\" Dương Mịch có lép vế trước loạt nhan sắc huyền thoại?",
description: "Nhan sắc của tứ đại mỹ nhân trên màn ảnh Hoa ngữ thu hút đông đảo sự chú ý của netizen.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/8/photo-1-15700670484611852808998-1628426119499114659797.jpg',
alt: "tứ đại mỹ nhân,sao Trung,sao Hoa ngữ,Dương Mịch,Tưởng Cần Cần,trần hồng,Lâm Phương Bình,star style,tạo hình nhân vật,tạo hình trong phim,",
category: 'stars',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/tu-dai-my-nhan-tren-man-anh-hoa-ngu-vuong-chieu-quan-duong-mich-co-lep-ve-truoc-loat-nhan-sac-huyen-thoai',
component: 'page_20210811105414'},
{title: "BLACKPINK và 1 \"rổ\" lỗi trang phục khó xử: Hết tụt áo lại rách váy, pha xử lý của Jennie gây sốt vì cao tay!",
description: "Không biết lỗi do stylist hay vì vận xui mà BLACKPINK đã 5 lần 10 lượt vướng sự cố trang phục bi hài tới vậy.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/1-171640-1628651713299474880525.png',
alt: "Blackpink,sự cố trang phuc,sao đỏ thời trang,rách váy,tụt quần,idol Kpop,sao Hàn,star style,",
category: 'stars',
time: '10:53 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:53 AM',
link: '/blackpink-va-1-ro-loi-trang-phuc-kho-xu-het-tut-ao-lai-rach-vay-pha-xu-ly-cua-jennie-gay-sot-vi-cao-tay',
component: 'page_20210811105358'},
{title: "Jihyo (TWICE) diện chung áo với mẫu hãng nhưng \"chiếm sóng\" nhờ 1 chi tiết \"mlem\" hơn hẳn",
description: "Chính nhờ điểm đặc biệt này mà Jihyo luôn tạo cảm giác gợi cảm khó cưỡng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/18/2008415376054701237475222705310698259576272n-16240075379411007675571.jpg',
alt: "sao Hàn,jihyo,TWICE,star style,style của sao,",
category: 'stars',
time: '10:53 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:53 AM',
link: '/jihyo-twice-dien-chung-ao-voi-mau-hang-nhung-chiem-song-nho-1-chi-tiet-mlem-hon-han',
component: 'page_20210811105323'},
{title: "Cho rằng bản thân chưa đủ quyến rũ, vậy cô gái da ngăm, vòng 1 bốc lửa đến nghẹt thở này là ai vậy Jihyo ơi?",
description: "Bạn thích Jihyo theo style nào hơn?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/screenshot-453-16286535197461574804397.png',
alt: "jihyo (twice),TWICE,star style,sao Hàn,idol,Kpop,quyến rũ,sexy,dễ thương,",
category: 'stars',
time: '10:52 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:52 AM',
link: '/cho-rang-ban-than-chua-du-quyen-ru-vay-co-gai-da-ngam-vong-1-boc-lua-den-nghet-tho-nay-la-ai-vay-jihyo-oi',
component: 'page_20210811105221'},
{title: "Game thủ tái hiện Thành phố Hồ Chí Minh tươi đẹp trong tựa game Minecraft",
description: "Một tác phẩm để đời của game thủ Minecraft Việt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/photo-1-1628618708382440378325.jpg',
alt: "Thành phố Hồ Chí Minh,Hồ Chí Minh,game,mine craft,",
category: 'games',
time: '10:52 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:52 AM',
link: '/game-thu-tai-hien-thanh-pho-ho-chi-minh-tuoi-dep-trong-tua-game-minecraft',
component: 'page_20210811105208'},
{title: "Trốn Sài Gòn, vợ chồng về quê Phú Yên xây nhà style Địa Trung Hải, dành hẳn 40m2 làm hồ bơi cho con",
description: "Ngôi nhà gây ấn tượng với khoảng sân rộng thênh thang, hồ bơi mát lạnh cùng đất trồng hoa và rau trái xung quanh.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/8/9/21376005811750267596689093140582484154896113n-16284837570881616624042.jpeg',
alt: "khoe nhà đẹp,house n home,phong cách địa trung hải,thiết kế nhà,xây nhà,làm nhà,kiến trúc sư,Phú Yên,",
category: 'life',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/tron-sai-gon-vo-chong-ve-que-phu-yen-xay-nha-style-dia-trung-hai-danh-han-40m2-lam-ho-boi-cho-con',
component: 'page_20210811105154'},
{title: "Luôn ăn vận thanh lịch nhưng Emma Watson vẫn có 2 pha \"lộ hàng\" mà cô không bao giờ muốn nhớ lại nữa",
description: "Và thật may mắn, đây chỉ là 2 sự cố hiếm hoi trong lịch sử ăn mặc của Emma Watson.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/10/kz65wzrxhlaf5uj9vcvy0hrmihyfbkjrl31q2idin3q-1628604398082745033437.jpeg',
alt: "emma watson,lộ hàng,sự cố trang phục,harry potter,sao us uk,star style,sao hớ hênh,",
category: 'stars',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/luon-an-van-thanh-lich-nhung-emma-watson-van-co-2-pha-lo-hang-ma-co-khong-bao-gio-muon-nho-lai-nua',
component: 'page_20210811105118'},
{title: "Hai anh em đem tranh Càn Long vẽ bán cho chuyên gia kiểm định, 1 năm sau mới \"điếng người\": Báo cảnh sát cũng không kịp nữa rồi!",
description: "Chứng kiến hành động của vị chuyên gia sau đó, hai anh em nhà họ Chu mới vội vàng đi báo cảnh sát nhưng cũng không kịp nữa rồi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285829258491147498617.jpg',
alt: "Đài truyền hình Trung Quốc,Hoàng đế Càn Long,",
category: 'news',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/hai-anh-em-dem-tranh-can-long-ve-ban-cho-chuyen-gia-kiem-dinh-1-nam-sau-moi-dieng-nguoi-bao-canh-sat-cung-khong-kip-nua-roi',
component: 'page_20210811105109'},
{title: "Vì sao trong lễ cưới, các cô dâu thường đeo khăn trùm đầu?",
description: "Hơn cả một món phụ kiện, chiếc khăn trùm đầu của cô dâu còn có ý nghĩa vô cùng lớn lao.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/10/elora1-16285882056331409566607.jpg',
alt: "khăn trùm đầu,lễ cưới,ý nghĩa,cô dâu,phụ kiện,",
category: 'life',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/vi-sao-trong-le-cuoi-cac-co-dau-thuong-deo-khan-trum-dau',
component: 'page_20210811105100'},
{title: "Shark Hưng học 1 đại học dễ quá nên học luôn 2 - 3 trường, 4 lần tốt nghiệp Thạc sĩ, còn gây sốt khi lọt top thế giới về mảng này",
description: "Đằng sau sự tinh thông kiến thức của Shark Hưng là cả quá trình đi học miệt mài cả vị Phó chủ tịch.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285906520021581419114.png',
alt: "chương trình thực tế,đại học quốc gia,shark tank,shark hưng,",
category: 'news',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/shark-hung-hoc-1-dai-hoc-de-qua-nen-hoc-luon-2-3-truong-4-lan-tot-nghiep-thac-si-con-gay-sot-khi-lot-top-the-gioi-ve-mang-nay',
component: 'page_20210811105041'},
{title: "Ăn gì để ngăn ngừa nguy cơ mắc bệnh gút gây đau sưng tứ chi? Ăn cơm là đủ rồi, cần chi cao sang",
description: "Ngày càng có nhiều người trẻ mắc bệnh gút, căn bệnh gây ra những cơn đau, sưng viêm tứ chi khiến bạn phải \"sống dở chết dở\". Để ngăn ngừa nguy cơ đối mặt với nó, chúng ta đều biết cần giữ cho mình 1 chế độ ăn lành mạnh nhưng liệu bạn có biết rằng chỉ cần... ăn cơm là đủ \"kháng\" gút?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285955955311001452446.jpg',
alt: "bệnh gút,gạo ong biển,",
category: 'life',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/an-gi-de-ngan-ngua-nguy-co-mac-benh-gut-gay-dau-sung-tu-chi-an-com-la-du-roi-can-chi-cao-sang',
component: 'page_20210811105031'},
{title: "3 bộ phận trên cơ thể nam giới càng cọ rửa sạch sẽ khi tắm thì thận càng khỏe mạnh, sức khỏe dồi dào",
description: "Tắm là việc mà nhiều người làm hàng ngày, đặc biệt là trong trường hợp ra nhiều mồ hôi. Bên cạnh đó, nếu nam giới chú ý cọ rửa kĩ ở 1 số bộ phận khi tắm thì rất có lợi cho sức khỏe.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285841882251407951264.jpeg',
alt: "tắm,thận,cọ rửa,Sức khỏe sinh sản,",
category: 'life',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/3-bo-phan-tren-co-the-nam-gioi-cang-co-rua-sach-se-khi-tam-thi-than-cang-khoe-manh-suc-khoe-doi-dao',
component: 'page_20210811105021'},
{title: "Nhờ Jisoo (BLACKPINK) một ứng dụng tăng 160 bậc trên App Store, nhiều người đăng kí đến nỗi đơ luôn cả app",
description: "Đây không phải lần đầu Jisoo khiến một ứng dụng trở nên nổi tiếng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/22955807010161020224965396189254754894077920n-1628579327444746997901.jpg',
alt: "gương mặt đại diện,nền tảng mới,Google Play Store,fan hâm mộ,jisoo,Celebe,Blackpink,ứng dụng,app,MXH,",
category: 'stars',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/nho-jisoo-blackpink-mot-ung-dung-tang-160-bac-tren-app-store-nhieu-nguoi-dang-ki-den-noi-do-luon-ca-app',
component: 'page_20210811105013'},
{title: "10 bộ phim bom tấn đã được phát hành trong năm 2020 và 2021, The Suicide Squad đang khiến netizen \"bấn loạn\"",
description: "Mặc dù được phát hành trong tình hình đại dịch căng thẳng, những bộ phim này vẫn gặt hái được thành công nhất định.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/9/photo-1-1628477882307314683247.jpg',
alt: "BAD BOYS FOR LIFE,PHIM CHIẾU RẠP,PHIM ĐIỆN ẢNH,PHIM BOM TẤN 2021,TOP PHIM,BIRDS OF PREY,THE SUICIDE SQUAD,FAST 9,",
category: 'news',
time: '10:56 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:56 AM',
link: '/10-bo-phim-bom-tan-da-duoc-phat-hanh-trong-nam-2020-va-2021-the-suicide-squad-dang-khien-netizen-ban-loan',
component: 'page_20210809105618'},
{title: "Gái xinh đăng đàn oán trách người đã bỏ rơi mình sau sự cố lộ clip nhạy cảm",
description: "Hot girl từng livestream quảng cáo game cấm đã có những động thái đầu sau tin đồn lộ clip nóng.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/8/9/photo-2-162771035403325926843-16285012988902110324162.jpeg',
alt: "LÊ PHƯƠNG ANH,LỘ CLIP,HOT GIRL,PR GAME,",
category: 'images',
time: '10:55 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:55 AM',
link: '/gai-xinh-dang-dan-oan-trach-nguoi-da-bo-roi-minh-sau-su-co-lo-clip-nhay-cam',
component: 'page_20210809105553'},
{title: "Dragon Ball Super: Dù Bản năng Vô cực mạnh thế nào thì vẫn không thể thay thế tuyệt chiêu này của Goku",
description: "Mặc dù Dragon Ball liên tục có các chiêu thức mới, nhưng chiêu thức đặc trưng của Goku vẫn là Kamehameha.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/anh-1-1628492049545263907366.jpg',
alt: "DRAGON BALL SUPER,BẢN NĂNG VÔ CỰC,SON GOKU,KAMEHAMEHA,",
category: 'games',
time: '10:55 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:55 AM',
link: '/dragon-ball-super-du-ban-nang-vo-cuc-manh-the-nao-thi-van-khong-the-thay-the-tuyet-chieu-nay-cua-goku',
component: 'page_20210809105544'},
{title: "CĐM ngất ngây với nữ game thủ có vòng một khủng, là nàng Yasuo gợi cảm từng \"cởi\" để biến thành Natalya",
description: "Mới đây, cộng đồng mạng được một phen ngất ngây với sự hóa thân của nữ coser, một trong những gương mặt thân quen của game thủ Liên Quân.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2020/12/28/screenshot7-1609174048692148671450.png',
alt: "LIÊN QUÂN MOBILE,LIÊN QUÂN,GÁI XINH,COSER,COSPLAY,COSPLAYER,TRANG ĐỖ,BÍT TẾT,CỘNG ĐỒNG MẠNG,CĐM,CỘNG ĐỒNG LIÊN QUÂN MOBILE,",
category: 'images',
time: '10:55 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:55 AM',
link: '/cdm-ngat-ngay-voi-nu-game-thu-co-vong-mot-khung-nguoi-tung-quyet-tam-coi-de-cong-hien-cho-lien-quan',
component: 'page_20210809105522'},
{title: "3 hiểu lầm \"ăn sâu bám rễ\" trong tâm trí hội mê phim cổ trang Trung Quốc, đọc ngay để mở mang kiến thức",
description: "Có rất nhiều kiến thức về văn hoá, lịch sử được cài cắm trong những bộ phim cổ trang Trung Quốc. Tuy nhiên, có những chi tiết về văn hoá xưa trong phim chưa sát với thực tế và khiến cho khán giả xem phim hiểu lầm về sự thật lịch sử.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/3/21-1627962547508685262938.jpeg',
alt: "Trung Quốc,thời nhà thanh,triều đại nhà Thanh,Vua Càn Long,công chúa,Hoàn Châu cách cách,Lịch sử Trung Quốc,phim cổ trang Hoa Ngữ,phim cổ trang trung quốc,sự thật,sự thật thú vị,thâm cung bí sử,thâm cung bí sử Trung Quốc,có thể bạn chưa biết,phim cổ trang,",
category: 'news',
time: '10:55 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:55 AM',
link: '/nhung-hieu-lam-an-sau-bam-re-trong-tam-tri-cac-mot-phim-co-trang-trung-quoc-doc-ngay-de-mo-mang-kien-thuc',
component: 'page_20210809105506'},
{title: "35 tuổi mà cứ xinh mơn mởn như thiếu nữ 18, Triệu Lệ Dĩnh vừa tung ảnh mặc đồng phục là gây bão khắp Weibo ngay",
description: "Trở thành diễn viên trong MV ca nhạc của nam ca sĩ Trương Kiệt, Triệu Lệ Dĩnh khiến netizen xuýt xoa với nét đẹp trong trẻo, đáng yêu.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/9/photo-1-1628513974213601470298.jpg',
alt: "triệu lệ dĩnh,Mỹ nhân Hoa ngữ,nhan sắc của sao,vóc dáng của sao,body của sao,",
category: 'stars',
time: '10:54 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:54 AM',
link: '/35-tuoi-ma-cu-xinh-mon-mon-nhu-thieu-nu-18-trieu-le-dinh-vua-tung-anh-mac-dong-phuc-la-gay-bao-khap-weibo-ngay',
component: 'page_20210809105456'},
{title: "Jennie lập kỷ lục với MV SOLO nhưng vẫn bị video vũ đạo ngang ngược nhất của BLACKPINK vượt mặt",
description: "Jennie lập kỷ lục ở mảng nữ nghệ sĩ solo Kpop. Thế nhưng MV của cô vẫn không nhiều lượt xem bằng video trình diễn vũ đạo How You Like That, được BLACKPINK phát hành vào tháng 7/2020.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/9/jennie-solo-162850394849752557449.jpeg',
alt: "Jennie (BlackPink),Blackpink,How You Like That Dance Performance,view youtube,MV SOLO Jennie,kỷ lục nghệ sĩ solo nữ Kpop,How You Like That,",
category: 'stars',
time: '10:54 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:54 AM',
link: '/jennie-lap-ky-luc-voi-mv-solo-nhung-van-bi-video-vu-dao-ngang-nguoc-nhat-cua-blackpink-vuot-mat',
component: 'page_20210809105446'},
{title: "Hoá ra con hàu được người ta nuôi như thế này, \"đột nhập\" vào làng bè ở Vũng Tàu mới được mở mang tầm mắt",
description: "Ăn đó giờ nhưng chắc nhiều người chưa biết hàu được nuôi như thế nào.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/9/anh-chup-man-hinh-2021-08-09-luc-190442-1628510926073641453141.png',
alt: "dinh dưỡng cao,Cư dân mạng,Động vật thân mềm,hàu,con hàu,nuôi hàu,cách nuôi hàu,đánh bắt hàu,phương pháp nuôi hàu,",
category: 'news',
time: '10:54 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:54 AM',
link: '/hoa-ra-con-hau-duoc-nguoi-ta-nuoi-nhu-the-nay-dot-nhap-vao-lang-be-o-vung-tau-moi-duoc-mo-mang-tam-mat',
component: 'page_20210809105435'},
{title: "Những tựa game với cái kết dang dở khiến người chơi cảm thấy tức muốn chết",
description: "Kết không đẹp thì thôi, nhưng kết kiểu khó chịu thế này thì đúng là bực mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/8/photo-1-16284418824842061273504.jpg',
alt: "GAME,",
category: 'games',
time: '10:22 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:22 PM',
link: '/nhung-tua-game-voi-cai-ket-dang-do-khien-nguoi-choi-cam-thay-tuc-muon-chet',
component: 'page_20210808222221'},
{title: "12 chi tiết \"siêu ẩn\" trong bom tấn Gia Đình Siêu Nhân của Pixar cho fan cứng: Một cảnh quay còn được Avengers bắt chước!",
description: "Siêu phẩm hoạt hình The Incredibles (Gia Đình Siêu Nhân) khéo léo cài cắm loạt chi tiết ẩn khiến người hâm mộ khó có thể nhận ra nếu chỉ xem một lần.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/photo-14-1628475328550605475751.jpg',
alt: "GIA ĐÌNH SIÊU NHÂN,THE INCREDIBLES,PIXAR,PHIM HOẠT HÌNH,",
category: 'news',
time: '10:22 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:22 PM',
link: '/12-chi-tiet-sieu-an-trong-bom-tan-gia-dinh-sieu-nhan-cua-pixar-cho-fan-cung-mot-canh-quay-con-duoc-avengers-bat-chuoc',
component: 'page_20210808222213'},
{title: "Iron Man chính thức trở lại dù đã chết trong Endgame, hướng giải quyết của Marvel khiến ai cũng phải thán phục",
description: "Dù Iron Man đã hy sinh trong Avengers: Endgame, Marvel đã tìm cách để chính thức đưa nhân vật này quay trở lại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/9/photo-1-16284760850321848111665.jpg',
alt: "TONY STARK,IRON MAN,AVENGERS: ENDGAME,MCU,WHAT IF...?,LOKI,ĐA VŨ TRỤ,",
category: 'news',
time: '10:22 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:22 PM',
link: '/iron-man-chinh-thuc-tro-lai-du-da-chet-trong-endgame-huong-giai-quyet-cua-marvel-khien-ai-cung-phai-than-phuc',
component: 'page_20210808222202'},
{title: "Khoe thân lấp ló nửa kín nửa hở, nữ streamer lạ mặt khiến dân tình phát sốt, xôn xao tìm info sau màn \"tung cước\" thẳng camera",
description: "Cô nàng streamer này đang được dân mạng xôn xao tìm kiếm info.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/page-16279922429181763025203.jpg',
alt: "HOT GIRL,STREAMER,CỘNG ĐỒNG MẠNG,GÁI XINH,NỮ STREAMER,",
category: 'images',
time: '10:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:21 PM',
link: '/khoe-than-lap-lo-nua-kin-nua-ho-nu-streamer-la-mat-khien-dan-tinh-phat-sot-xon-xao-tim-info-sau-man-tung-cuoc-thang-camera',
component: 'page_20210808222139'},
{title: "Diễn xuất dở tệ, nàng hot girl phim 18+ vẫn được coi là tân binh sáng giá bậc nhất, fan đua nhau bảo vệ \"Đóng nhiều rồi sẽ tốt hơn\"",
description: "Cô nàng hot girl này đang ghi dấu ấn mạnh mẽ nhờ vào vẻ ngoài xinh đẹp của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/-1627814714842107585052.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,SAITO AMIRI,PHIM 18+,AV,PHIM AV,",
category: 'images',
time: '10:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:21 PM',
link: '/dien-xuat-do-te-nang-hot-girl-phim-18-van-duoc-coi-la-tan-binh-sang-gia-bac-nhat-fan-dua-nhau-bao-ve-dong-nhieu-roi-se-tot-hon',
component: 'page_20210808222129'},
{title: "U mê chùm ảnh các địa điểm nổi tiếng Nhật Bản lên anime Your Name: Vẻ đẹp nao lòng không khác gì đời thực!",
description: "Các họa sĩ của anime Your Name đình đám đã thực sự rất tâm huyết để tái hiện lại khung cảnh tuyệt đẹp của thành phố thực.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/roppongi-art-center-2-16284292604101771625758.jpg',
alt: "Your Name (Kimi no Na wa),Your Name,anime,phim anime,Your Name (2016),",
category: 'travel',
time: '10:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:21 PM',
link: '/u-me-chum-anh-cac-dia-diem-noi-tieng-nhat-ban-len-anime-your-name-ve-dep-nao-long-khong-khac-gi-doi-thuc',
component: 'page_20210808222113'},
{title: "Chú bé đáng yêu ở phim Châu Tinh Trì hóa ra là... cô bé, lột xác thành mỹ nhân lại từng sánh đôi với Tiêu Chiến?",
description: "Nếu bạn đã từng ăn \"cú lừa\" này từ Châu Tinh Trì thì xin chúc mừng, tuổi thơ bạn thật tuyệt vời!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/7/photo-1-1628312179961827626103.jpg',
alt: "Châu Tinh Trì,từ kiều,tiêu chiến,CJ7,Siêu khuyển thần thông,phim Hoa ngữ,phim điện ảnh,phim chiếu rạp,dàn sao ngày ấy bây giờ,sao nhí,Người Đi Ngược Chiều Đẹp Nhất,ngày ấy bây giờ,",
category: 'stars',
time: '10:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:21 PM',
link: '/chu-be-dang-yeu-o-phim-chau-tinh-tri-hoa-ra-la-co-be-lot-xac-thanh-my-nhan-lai-tung-sanh-doi-voi-tieu-chien',
component: 'page_20210808222103'},
{title: "Loạt \"phốt\" nối dài của Jack: Thái độ sao hạng A, nghi vấn nói xấu Sơn Tùng - ViruSs nhưng scandal có con riêng mới gây sốc toàn tập",
description: "Đã hot càng thêm hot, Jack bây giờ chính là cái tên thu hút đông đảo sự chú ý từ phía công chúng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/thumb-bes-16283928436601467777066.jpg',
alt: "Jack,profile Jack,scandal jack,jack có con với thiên an,jack bắt cá 2 tay,nam ca sĩ Jack,K-ICM,Scandal Jack bị tố ngoại tình, có con,",
category: 'stars',
time: '03:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 03:21 PM',
link: '/loat-phot-noi-dai-cua-jack-thai-do-sao-hang-a-nghi-van-noi-xau-son-tung-viruss-nhung-scandal-co-con-rieng-moi-gay-soc-toan-tap',
component: 'page_20210808152154'},
]
export const dataContent06=[{title: "The Suicide Squad: Tầng lớp cặn bã của siêu anh hùng làm nên điều Marvel chắc chắn sẽ chối từ",
description: "The Suicide Squad hẳn là bộ phim siêu anh hùng bạo lực, máu me nhất từ trước tới nay - đến cái mức mà Deadpool trông có vẻ hiền lành hơn hẳn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/ngang-16284211045481640544690.png',
alt: "Suicide Squad (2016),James Gunn,DCEU,DC Universe,phim DC,DC,phim siêu anh hùng,review phim,phim Âu Mỹ,The Suicide Squad (2021),",
category: 'stars',
time: '03:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 03:21 PM',
link: '/the-suicide-squad-tang-lop-can-ba-cua-sieu-anh-hung-lam-nen-dieu-marvel-chac-chan-se-choi-tu',
component: 'page_20210808152116'},
{title: "Những hình phạt tàn độc do Võ Tắc Thiên nghĩ ra, \"Ngọc nữ leo thang\" trở thành cơn ác mộng của bất cứ ai",
description: "Những hình phạt do Võ Tắc Thiên sáng tạo ra đến cùng là ngoan độc đến nhường nào mới có thể khiến vạn người thuần phục như vậy?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/20150204-020837-7041547560823743520x782-16283911549241546755802.jpg',
alt: "Võ Tắc Thiên,Lịch sử Trung Hoa,hành hình,",
category: 'news',
time: '03:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 03:21 PM',
link: '/nhung-hinh-phat-tan-doc-do-vo-tac-thien-nghi-ra-ngoc-nu-leo-thang-tro-thanh-con-ac-mong-cua-bat-cu-ai',
component: 'page_20210808152102'},
{title: "Làng game Việt bấy lâu nay vẫn dậm chân tại chỗ chẳng thể phát triển, nguyên nhân vì đâu?",
description: "Vì đâu mà game Việt vẫn còn đang phải giậm chân tại chỗ với những dự án game mobile đơn giản mà chưa có nổi một cú hit?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-1628156685246444204171.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/lang-game-viet-bay-lau-nay-van-dam-chan-tai-cho-chang-the-phat-trien-nguyen-nhan-vi-dau',
component: 'page_20210808110255'},
{title: "Những nghịch lý ngược đời, bị hiểu sai một cách đầy ngang trái về game trong Avengers và một loạt bộ phim khác (p2)",
description: "Game không phải như trong phim, cứ bấm loạn nút lên là thắng đâu nhé anh em.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/8/screenshot6-16284162234731391199138.png',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/nhung-nghich-ly-nguoc-doi-bi-hieu-sai-mot-cach-day-ngang-trai-ve-game-trong-avengers-va-mot-loat-bo-phim-khac-p2',
component: 'page_20210808110243'},
{title: "Dragon Ball: Hồi sinh cả vũ trụ nhưng Goku và Vegeta lại chưa từng ước chủng tộc Saiyan quay trở lại, lý do vì đâu?",
description: "Nhờ sức mạnh của Rồng thần không ít lần một người chết hay một hành tinh nào đó được hồi sinh quay trở lại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/8/anh-1-16284058255101769228073.jpg',
alt: "DRAGON BALL,7 VIÊN NGỌC RỒNG,RỒNG THẦN,HÀNH TINH SAIYAN,GOKU,VEGETA,HỒI SINH NGƯỜI CHẾT,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/dragon-ball-hoi-sinh-ca-vu-tru-nhung-goku-va-vegeta-lai-chua-tung-uoc-chung-toc-saiyan-quay-tro-lai-ly-do-vi-dau',
component: 'page_20210808110234'},
{title: "Tò mò phim 18+ là diễn thật hay giả, nàng hot girl từ bỏ cuộc sống đại học, tự dấn thân vào ngành để \"kiểm tra\"",
description: "Sau đó thì tất nhiên, cô nàng hot girl này cũng đã biết được kết quả thật sự là như thế nào.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/-16279075809431784909761.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,PHIM AV,PHIM 18+,AV,ARAI RIMA,",
category: 'images',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/to-mo-phim-18-la-dien-that-hay-gia-nang-hot-girl-tu-bo-cuoc-song-dai-hoc-tu-dan-than-vao-nganh-de-kiem-tra',
component: 'page_20210808110217'},
{title: "[Review] My Time at Portia phiên bản di động: Khi cả thế giới nằm trong tầm tay bạn!",
description: "Trò chơi My Time at Portia đã được ra mắt trên thiết bị di động vào ngày 4 tháng 8 vừa qua.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-162832600068850224543.jpg',
alt: "MY TIME AT PORTIA,REVIEW,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/review-my-time-at-portia-phien-ban-di-dong-khi-ca-the-gioi-nam-trong-tam-tay-ban',
component: 'page_20210808110211'},
{title: "Top 5 game open world có đồ họa đỉnh của chóp, theo bình chọn của game thủ",
description: "Có nhiều tựa game được khen ngợi về đồ họa, nhưng 5 tựa game này có lẽ là kinh điển nhất trong thể loại open world",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/5-800x450-16282376254711350454925.jpg',
alt: "GAME,",
category: 'games',
time: '11:02 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:02 AM',
link: '/top-5-game-open-world-co-do-hoa-dinh-cua-chop-theo-binh-chon-cua-game-thu',
component: 'page_20210808110202'},
{title: "Top 5 anime cực hấp dẫn khi có những cô nàng nóng bỏng và hot nhất mùa hè 2021",
description: "Các anime hấp dẫn người xem không chỉ bởi nội dung mà nhân vật nữ trong phim cũng là một yếu tố quyết định dẫn đến thành công của bộ phim.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/nh-1-1628321240615429169804.jpg',
alt: "ANIME HÈ 2021,NHÂN VẬT ANIME,NỮ CHÍNH ANIME,TOP ANIME HẤP DẪN,",
category: 'games',
time: '11:01 AM',
date: '08/08/2021',
timestamp: '08/08/2021 11:01 AM',
link: '/top-5-anime-cuc-hap-dan-khi-co-nhung-co-nang-nong-bong-va-hot-nhat-mua-he-2021',
component: 'page_20210808110150'},
{title: "Thánh nữ trượt băng Nhật Bản cosplay Điệp Trụ trong Kimetsu no Yaiba",
description: "Bức ảnh của nữ vận động viên trượt băng Marin Honda đã nhận được hàng chục nghìn lượt like của fan hâm mộ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/photo-1-16283400307351407860644.jpg',
alt: "KIMETSU NO YAIBA,COSPLAY,TRƯỢT BĂNG,MARIN HONDA,",
category: 'news',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/thanh-nu-truot-bang-nhat-ban-cosplay-diep-tru-trong-kimetsu-no-yaiba',
component: 'page_20210807154638'},
{title: "Sau khi bật chế độ thần thánh mới, Vegeta chính thức đánh bại Goku trở thành nhân vật được yêu thích nhất Dragon Ball Super",
description: "Trong chapter 74 của Dragon Ball Super chúng ta đã được chứng kiến màn tỏa sáng của Vegeta trước trận đấu với Granolah. Sau khi thấy được sự biến đổi thần thánh mới, một cuộc thăm dò của Viz Media xác nhận rằng Vegeta là nhân vật được yêu thích nhất trong Dragon Ball Super.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/nh-1-16283235562581341148953.jpg',
alt: "DRAGON BALL SUPER,VEGETA,GOKU,THẦN HỦY DIỆT,BẢN NĂNG VÔ CỰC,GOD KI,",
category: 'games',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/sau-khi-bat-che-do-than-thanh-moi-vegeta-chinh-thuc-danh-bai-goku-tro-thanh-nhan-vat-duoc-yeu-thich-nhat-dragon-ball-super',
component: 'page_20210807154628'},
{title: "Những nghịch lý ngược đời, bị hiểu sai một cách đầy ngang trái về game trong Avengers và một loạt bộ phim khác (p1)",
description: "Hai người cùng chơi một tựa game single-player, điều chỉ có thể xuất hiện trên phim.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/screenshot27-1628329462687161582959.png',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/nhung-nghich-ly-nguoc-doi-bi-hieu-sai-mot-cach-day-ngang-trai-ve-game-trong-avengers-va-mot-loat-bo-phim-khac-p1',
component: 'page_20210807154619'},
{title: "Trên tay MSI SPATIUM M480, SSD cao cấp dành cho game thủ đích thực",
description: "MSI SPATIUM M480 là mẫu SSD cao cấp với hiệu năng vượt trội.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/photo-1-162833286592113245303.jpg',
alt: "SSD,MSI,SPATIUM M480,Ổ CỨNG SSD,",
category: 'tech',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/tren-tay-msi-spatium-m480-ssd-cao-cap-danh-cho-game-thu-dich-thuc',
component: 'page_20210807154609'},
{title: "8 game có cốt truyện sinh ra để \"lừa\" game thủ (P2)",
description: "Những tựa game này đều có cốt truyện \"hack não\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-1628331883029848668213.jpg',
alt: "LIFE IS STRANGE,CỐT TRUYỆN,GAME CỐT TRUYỆN,ASSASSIN’S CREED 3,",
category: 'games',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/8-game-co-cot-truyen-sinh-ra-de-lua-game-thu-p2',
component: 'page_20210807154600'},
{title: "Những khó khăn mà nhân vật chính trong isekai anime thường gặp phải (P.2)",
description: "Nhân vật chính trong isekai anime có nhiều lợi thế và đặc quyền, nhưng chắc chắn họ cũng gặp không ít khó khăn",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/sword-art-online-war-of-underworld-asuna-1628233175359273668004.jpg',
alt: "KHÁM PHÁ,ANIME,ISEKAI ANIME,",
category: 'games',
time: '03:45 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:45 PM',
link: '/nhung-kho-khan-ma-nhan-vat-chinh-trong-isekai-anime-thuong-gap-phai-p2',
component: 'page_20210807154549'},
{title: "Xếp hạng các bộ phim thuộc Fast and Furious saga, vẫn chưa cái tên nào vượt qua được phần 7 huyền thoại",
description: "\"Fast and Furious\" - Quá nhanh quá nguy hiểm là 1 trong những thương hiệu phim đắt khách nhất trên màn ảnh thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-16283042338841885128623.jpg',
alt: "FAST AND FURIOUS,HOBBS AND SHAW,THE FAST SAGA,FAST & FURIOUS,THE FAST AND THE FURIOUS,QUÁ NHANH QUÁ NGUY HIỂM,",
category: 'news',
time: '03:45 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:45 PM',
link: '/xep-hang-cac-bo-phim-thuoc-fast-and-furious-saga-van-chua-cai-ten-nao-vuot-qua-duoc-phan-7-huyen-thoai',
component: 'page_20210807154540'},
{title: "Đừng ăn mì tôm trứng xúc xích nữa, đây là cách để làm mì gói trở nên dinh dưỡng hơn",
description: "Ăn mì tôm cùng với trứng và xúc xích chẳng khác nào dội bom lên hệ tim mạch của bạn.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/photo-1-1628314839916736337919.png',
alt: "MÌ TÔM,MÌ GÓI,MÌ TÔM TRỨNG XÚC XÍCH,KHOA HỌC,NGHIÊN CỨU,SỨC KHỎE,",
category: 'news',
time: '03:45 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:45 PM',
link: '/dung-an-mi-tom-trung-xuc-xich-nua-day-la-cach-de-lam-mi-goi-tro-nen-dinh-duong-hon',
component: 'page_20210807154530'},
{title: "Quấn duy nhất tạp dề lên sóng dạy nấu ăn, nữ YouTuber khiến fan đỏ mắt khi hào phóng khoe đôi gò bổng đảo 1m siêu gợi cảm",
description: "Mỗi lần tạp dề bị xê dịch, cô nàng YouTuber lại khiến cho không ít fan phải chảy máu mũi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-16278781006541262755540.jpg',
alt: "GÁI XINH,HOT GIRL,YOUTUBER,NỮ YOUTUBER,YOUTUBE,CỘNG ĐỒNG MẠNG,MIZUSAKI YUMI,",
category: 'images',
time: '03:45 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:45 PM',
link: '/quan-duy-nhat-tap-de-len-song-day-nau-an-nu-youtuber-khien-fan-do-mat-khi-hao-phong-khoe-doi-go-bong-dao-1m-sieu-goi-cam',
component: 'page_20210807154505'},
{title: "Những bản mod phá game nhất mà game thủ Skyrim không nên cài đặt",
description: "Phần lớn người chơi Skyrim đều thống nhất rằng các bản mod này cực kỳ phá game",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/bgsdecember2020modslcard776x470-1628159608167711147384.jpg',
alt: "KHÁM PHÁ,GAME,OPEN WORLD GAME,SKYRIM,",
category: 'games',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/nhung-ban-mod-pha-game-nhat-ma-game-thu-skyrim-khong-nen-cai-dat',
component: 'page_20210807154455'},
{title: "Dragon Ball: Dù sở hữu sức mạnh khủng nhưng 4 nhân vật này chưa từng chiến thắng ở bất kỳ cuộc chiến nào",
description: "Thật đáng ngạc nhiên khi có một số nhân vật chính trong Dragon Ball lại không có một chiến thắng nào xuyên suốt bộ truyện.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/anh-1-16282352607131499219046.gif',
alt: "DRAGON BALL,NHÂN VẬT DRAGON BALL,SON GOKU,7 VIÊN NGỌC RỒNG,",
category: 'games',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/dragon-ball-du-so-huu-suc-manh-khung-nhung-4-nhan-vat-nay-chua-tung-chien-thang-o-bat-ky-cuoc-chien-nao',
component: 'page_20210807154444'},
{title: "Xu hướng high teen sắp bùng nổ rồi: Khởi nguồn từ huyền thoại Clueless ngày xưa và giờ có BLACKPINK, Somi lăng xê nhiệt tình",
description: "Dường như đây sẽ là phong cách làm mưa làm gió trong thời gian tới.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/tumblrbe1d056d31ad36418533bf69ce786e02bae8c4a2540-16283151099122001578213.gif',
alt: "somi,Black Pink,clueless,star style,sao Hàn,thời trang trong phim,high teen,hot trends,hot trends 2021,",
category: 'stars',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/xu-huong-high-teen-sap-bung-no-roi-khoi-nguon-tu-huyen-thoai-clueless-ngay-xua-va-gio-co-blackpink-somi-lang-xe-nhiet-tinh',
component: 'page_20210807154429'},
{title: "Ngọc Trinh đăng clip hậu trường bộ ảnh hở đến gây bão, ai dè để lộ body trơ xương khác hẳn ảnh đã chỉnh sửa",
description: "Thân hình của Ngọc Trinh vào khoảng đầu năm 2021 đã gây tranh cãi rất nhiều vì quá gầy.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/128764309102149734589304838208032571802860702o-1607039468229384161398-1628324409876313351228.jpeg',
alt: "sao Việt,showbiz Việt,nữ hoàng nội y,Ngọc Trinh,body sao,",
category: 'stars',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/ngoc-trinh-dang-clip-hau-truong-bo-anh-ho-den-gay-bao-ai-de-de-lo-body-tro-xuong-khac-han-anh-da-chinh-sua',
component: 'page_20210807154418'},
{title: "6 khoảnh khắc kinh diễm nhất của Selena Gomez trên thảm đỏ: Chưa bao giờ vượt quá giới hạn, dù hở bạo nhưng điểm tinh tế vẫn tròn 100",
description: "Không biết tới bao giờ chúng ta mới lại được nhìn Selena Gomez thả dáng xuất sắc tiếp đây.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/7/photo-1-1628309575333344594990.jpg',
alt: "Selena Gomez,star style,Thời trang thảm đỏ,thảm đỏ,",
category: 'stars',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/6-khoanh-khac-kinh-diem-nhat-cua-selena-gomez-tren-tham-do-chua-bao-gio-vuot-qua-gioi-han-du-ho-bao-nhung-diem-tinh-te-van-tron-100',
component: 'page_20210807154400'},
{title: "10 nữ idol sinh ra là để nhuộm tóc vàng bạch kim: Quanh đi quẩn lại vẫn toàn các báu vật của 3 \"ông lớn\"",
description: "Visual của các idol Kpop khi để tóc vàng bạch kim đúng là khiến con dân điên đảo vì xuất thần quá mức.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/nu-than-toc-vang-rose-blackpink-nay-da-co-doi-thu-2a252b92-1628312258014643678302.jpg',
alt: "nhuộm tóc,tóc của sao,sao Hàn,idol Kpop,tóc tẩy,mỹ nhân tóc vàng,tóc vàng,idol Hàn,sm entertainment,JYP,YG Entertainment,",
category: 'stars',
time: '03:43 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:43 PM',
link: '/10-nu-idol-sinh-ra-la-de-nhuom-toc-vang-bach-kim-quanh-di-quan-lai-van-toan-cac-bau-vat-cua-3-ong-lon',
component: 'page_20210807154349'},
{title: "\"Xả ảnh\" HD chưa từng công bố của BLACKPINK: Choáng khi zoom cận mặt Jennie, Jisoo o ép vòng 1 đè bẹp thánh body Lisa - Rosé",
description: "Nhan sắc của các thành viên BLACKPINK trong THE ALBUM phiên bản Nhật đã trở thành chủ đề hot trên mạng xã hội.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/e6npqnwxmaisvsn-1628266883930666893959.jpg',
alt: "Blackpink,lisa (blackpink),Jennie (BlackPink),rosé (blackpink),jisoo (blackpink),jisoo,lisa,jennie,Rosé,sao Hàn,nhan sắc sao Hàn,the album,thánh body,body của sao,mỹ nhân hàn,mỹ nhân kpop,",
category: 'stars',
time: '03:43 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:43 PM',
link: '/xa-anh-hd-chua-tung-cong-bo-cua-blackpink-choang-khi-zoom-can-mat-jennie-jisoo-o-ep-vong-1-de-bep-thanh-body-lisa-rose',
component: 'page_20210807154338'},
{title: "Đừng tưởng còn trẻ thì không bị bệnh gút tấn công, hãy để ý đến 4 biểu hiện bất thường trên cơ thể để khám chữa kịp thời",
description: "Cách đây 10 đến 20 năm, bệnh gút được coi là bệnh của người trung niên và người già hoặc bệnh của người giàu, nhưng những năm gần đây, căn bệnh này đang có xu hướng tăng nhanh và trẻ hóa một cách chóng mặt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/7/-16283069036381614016449.jpg',
alt: "bệnh gút,thói quen ăn uống,trẻ hóa,",
category: 'news',
time: '03:43 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:43 PM',
link: '/dung-tuong-con-tre-thi-khong-bi-benh-gut-tan-cong-hay-de-y-den-4-bieu-hien-bat-thuong-tren-co-the-de-kham-chua-kip-thoi',
component: 'page_20210807154327'},
{title: "Kiếm 30 tỷ tiền donate mỗi năm, nữ streamer top 1 khiến fan bất ngờ, sốc nặng khi công khai mặt mộc chưa trang điểm",
description: "Rất nhiều người đã tỏ ra khá bất ngờ trước màn thay đổi có phần khác biệt này của cô nàng streamer.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/7/photo-1-16283301973181707590348.jpg',
alt: "streamer,bảng xếp hạng,mắt hai mí,phẫu thuật thẩm mỹ,",
category: 'images',
time: '03:43 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:43 PM',
link: '/kiem-30-ty-tien-donate-moi-nam-nu-streamer-top-1-khien-fan-bat-ngo-soc-nang-khi-cong-khai-mat-moc-chua-trang-diem',
component: 'page_20210807154308'},
{title: "Những trò chơi tìm lại được thành công nhờ game thủ \"mách nước\"",
description: "Nghe lời góp ý từ các fan hâm mộ, những tựa game này dần dần tìm lại được thành công và được yêu thích.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/photo-1-1628259338797394301209.jpg',
alt: "GAME,",
category: 'games',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/nhung-tro-choi-tim-lai-duoc-thanh-cong-nho-game-thu-mach-nuoc',
component: 'page_20210806162439'},
{title: "Nếu cứ lạm dụng Haki liệu những trận chiến trong anime One Piece có đi theo \"vết xe đổ\" của Dragon Ball hay không?",
description: "Thời gian gần đây nhiều khán giả hâm mộ anime One Piece phàn nàn về việc Haki đang được các nhà làm phim khai thác quá đà.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/hai1-16282411964212135704424.jpg',
alt: "ONE PIECE,MANGA ONE PIECE,ANIME ONE PIECE,RORONOA ZORO,LUFFY VS LUCCI,EIICHIRO ODA,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,NHỮNG NGƯỜI SỞ HỮU HAKI BÁ VƯƠNG,TỨ HOÀNG KAIDO,TỨ HOÀNG BIG MOM,KAIDO VÀ BIG MOM,",
category: 'games',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/neu-cu-lam-dung-haki-lieu-nhung-tran-chien-trong-anime-one-piece-co-di-theo-vet-xe-do-cua-dragon-ball-hay-khong',
component: 'page_20210806162430'},
{title: "Toxic, hack, không dùng tiếng Anh và những lý do khiến game thủ Việt thường xuyên phải chịu những điều tiếng tại các tựa game global",
description: "Không phải ngẫu nhiên mà game thủ Việt thường để lại ấn tượng không quá tích cực với bạn bè thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280537716561210499790.jpg',
alt: "GAME THỦ,GAME,TIN TỨC GAME,",
category: 'games',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/toxic-hack-khong-dung-tieng-anh-va-nhung-ly-do-khien-game-thu-viet-thuong-xuyen-phai-chiu-nhung-dieu-tieng-tai-cac-tua-game-global',
component: 'page_20210806162421'},
{title: "Mua căn hộ Vinhomes Ocean Park, chủ nhà \"đắp\" thêm 350 triệu thiết kế theo style Indochine làm nơi nghỉ dưỡng",
description: "Căn hộ được thiết kế tỉ mỉ đến từng chi tiết, đặc biệt là phần sàn gạch và bức tranh tường.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/8/6/8-3-1628240517469390285723.jpg',
alt: "khoe nhà đẹp,căn hộ,màu chủ đạo,thiết kế đẹp,gỗ tự nhiên,Đồ nội thất,giấy dán tường,Vinhomes Ocean Park,house n home,",
category: 'life',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/mua-can-ho-vinhomes-ocean-park-chu-nha-dap-them-350-trieu-thiet-ke-theo-style-indochine-lam-noi-nghi-duong',
component: 'page_20210806162406'},
{title: "Thích PK nhưng lại lên án việc \"đập tiền\" và những nghịch lý đầy thượng đẳng tồn tại bấy lâu nay của game thủ Việt",
description: "Thế mới thấy các game thủ Việt thượng đẳng như thế nào.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-16278822524001697710181.jpg',
alt: "GAME,TIN TỨC GAME,GAME THỦ,",
category: 'games',
time: '09:39 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:39 AM',
link: '/thich-pk-nhung-lai-len-an-viec-dap-tien-choi-game-va-nhung-nghich-ly-day-thuong-dang-ton-tai-bay-lau-nay-cua-cac-game-thu-viet',
component: 'page_20210806093912'},
{title: "Thử ngay tựa game Business Tour - tựa game Monopoly hot nhất tuần qua được Độ Mixi và đồng bọn yêu thích",
description: "Thể loại cờ tỷ phú đã không còn quá xa lạ đối với anh em game thủ từ trước tới nay. Tuy vậy, Business Tour vẫn mang tới một sức hút mạnh mẽ khi kết nối được bạn bè để cùng vạch ra những chiến lược tư duy đỉnh cao.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/-16282395595731630100420.jpg',
alt: "BUSINESS TOUR,",
category: 'games',
time: '09:39 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:39 AM',
link: '/thu-ngay-tua-game-business-tour-tua-game-monopoly-hot-nhat-tuan-qua-duoc-do-mixi-va-dong-bon-yeu-thich',
component: 'page_20210806093904'},
{title: "Top 4 trận đấu tay không mãn nhãn nhất trong thế giới anime, hấp dẫn còn hơn cả Dragon Ball",
description: "Bốn trận đấu tay không sau đây sẽ mang lại cho các bạn những cảm xúc chân thật hơn trong các bộ anime.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/hl1-1628233738698725479211.gif',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '09:38 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:38 AM',
link: '/top-4-tran-dau-tay-khong-man-nhan-nhat-trong-the-gioi-anime-hap-dan-con-hon-ca-dragon-ball',
component: 'page_20210806093855'},
{title: "4 nhân vật bị fan One Piece nghi ngờ là hậu duệ của Rocks D. Xebec",
description: "Có giả thuyết cho rằng Shanks chính là hậu duệ của Rocks D. Xebec.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/6/photo-1-162824203958082222118.jpg',
alt: "ONE PIECE,GOL D. ROGER,HẬU DUỆ CỦA ROCKS D. XEBEC,SHANKS,BUGGY,MARSHALL D. TEACH,",
category: 'games',
time: '09:38 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:38 AM',
link: '/4-nhan-vat-bi-fan-one-piece-nghi-ngo-la-hau-due-cua-rocks-d-xebec',
component: 'page_20210806093846'},
{title: "8 game có cốt truyện sinh ra để \"lừa\" game thủ (P1)",
description: "Những tựa game này có cốt truyện hết sức \"hại não\" và game thủ sẽ bị lừa nhiều lần khi chơi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/photo-1-16282515535541214055067.png',
alt: "DOKI DOKI LITERATURE CLUB,HEAVY RAIN,FARCRY 3,CONTRA,",
category: 'games',
time: '09:38 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:38 AM',
link: '/8-game-co-cot-truyen-sinh-ra-de-lua-game-thu-p1',
component: 'page_20210806093837'},
{title: "Nữ YouTuber nóng bỏng vội vàng phân trần khi clip massage vòng 1 bị đánh cắp, ghép vào quảng cáo \"bẩn\"",
description: "Nữ YouTuber này khẳng định rằng việc massage chẳng giúp vòng 1 to lên chút nào và khuyên mọi người đừng để bị lừa.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/6/gai-16282207381481235339054.jpg',
alt: "GÁI XINH,HOT GIRL,YOUTUBER,NỮ YOUTUBER,",
category: 'images',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/nu-youtuber-nong-bong-voi-vang-phan-tran-khi-clip-massage-vong-1-bi-danh-cap-ghep-vao-quang-cao-ban',
component: 'page_20210806093759'},
{title: "Dragon Ball Super: Những sự thật đáng kinh ngạc về trạng thái Super Saiyan God mà không phải ai cũng biết",
description: "Super Saiyan God là 1 trạng thái cực mạnh xuất hiện trong Dragon Ball Super.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/abg-1-16281455824961943368232.jpg',
alt: "DRAGON BALL,SUPER SAIYAN GOD,THẦN HỦY DIỆT,HÀNH TINH VEGETA,7 VIÊN NGỌC RỒNG,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/dragon-ball-super-nhung-su-that-dang-kinh-ngac-ve-trang-thai-super-saiyan-god-ma-khong-phai-ai-cung-biet',
component: 'page_20210806093748'},
{title: "Những khó khăn mà nhân vật chính trong isekai anime thường gặp phải (P.1)",
description: "Không phải lúc nào các nhân vật chính trong isekai anime cũng có cuộc sống suôn sẻ sau khi chuyển sinh",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/azusa-child-form-with-pills-1628152715274674158216.jpg',
alt: "ISEKAI,MANGA ANIME,ANIME,ISEKAI ANIME,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/nhung-kho-khan-ma-nhan-vat-chinh-trong-isekai-anime-thuong-gap-phai-p1',
component: 'page_20210806093740'},
{title: "Thực hiện lại trào lưu tưởng như đã lỗi thời, nàng hot girl khiến fan chảy máu mũi vì thân hình quá gợi cảm",
description: "Cô nàng hot girl dường như đã làm sống lại trào lưu tưởng như đã hết trend này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/1/photo-1-1627797383339325927780.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,IN-KYUNG,STREAMER,NỮ STREAMER,",
category: 'images',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/thuc-hien-lai-trao-luu-tuong-nhu-da-loi-thoi-nang-hot-girl-khien-fan-chay-mau-mui-vi-than-hinh-qua-goi-cam',
component: 'page_20210806093723'},
{title: "Nếu kéo về \"late game\" liệu Broly có thể đánh bại Jiren nếu 2 người đụng độ trong Dragon Ball Super?",
description: "Đó là câu hỏi mà nhiều fan của Dragon Ball Super quan tâm khi mà Broly và Jiren đều là những chiến binh rất mạnh.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/ku2-1628161887430142217655.jpg',
alt: "ANIME DRAGON BALL SUPER,MANGA DRAGON BALL SUPER,MOVIE DRAGON BALL SUPER: BROLY,MANGA DRAGON BALL SUPER,SON GOKU,HỢP THỂ GOGETA,ANIME DRAGON BALL SUPER: BROLY,GOKU VS JIREN,JIREN VS BROLY,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/neu-keo-ve-late-game-lieu-broly-co-the-danh-bai-jiren-neu-2-nguoi-dung-do-trong-dragon-ball-super',
component: 'page_20210806093715'},
{title: "Loạt game được đánh giá cao vì \"fame to\" rồi không đạt tới kỳ vọng",
description: "Nhận được nhiều lời khen cũng không phải điều gì quá tích cực đối với các nhà phát triển.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-1628171339462307298922.jpg',
alt: "GAME,",
category: 'games',
time: '09:37 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:37 AM',
link: '/loat-game-duoc-danh-gia-cao-vi-fame-to-roi-khong-dat-toi-ky-vong',
component: 'page_20210806093707'},
{title: "6 cách đơn giản để kiểm tra và khắc phục chuột không dây bị đơ",
description: "Hãy thử làm theo những cách này nếu có vấn đề về chuột không dây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-1628180440158944367743.jpg',
alt: "CHUỘT KHÔNG DÂY,CHUỘT MÁY TÍNH,CHUỘT,",
category: 'tech',
time: '09:36 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:36 AM',
link: '/6-cach-don-gian-de-kiem-tra-va-khac-phuc-chuot-khong-day-bi-do',
component: 'page_20210806093650'},
{title: "Muôn màu mỹ nhân dự đám tang trên màn ảnh xứ Trung: Cúc Tịnh Y lố cực điểm, ca cuối khóc \"giả trân\" mà xinh tột cùng",
description: "Ngay cả trong cảnh đám tang, các mỹ nhân như Cúc Tịnh Y hay Triệu Lệ Dĩnh cũng khoe sắc theo nhiều cách khác nhau.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/photo-1-1628166200315347069722.jpg',
alt: "Cúc Tịnh Y,triệu lệ dĩnh,Tân Chỉ Lôi,Trương Hinh Dư,Xa Thi Mạn,Châu Tấn,Đồng Dao,Như Ý Truyện,Minh Lan Truyện,Như Ý Phương Phi,Thiên long bát bộ,phim Hoa ngữ,phim cổ trang,phim trung quốc,Phim truyền hình,Yến Vân Đài,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:36 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:36 AM',
link: '/muon-mau-my-nhan-du-dam-tang-tren-man-anh-xu-trung-cuc-tinh-y-lo-cuc-diem-ca-cuoi-khoc-gia-tran-ma-xinh-tot-cung',
component: 'page_20210806093635'},
{title: "Hành trình thay đổi body của \"bông hồng lai\" Somi: Fan lo lắng vì vòng eo siêu thực lộ cả xương sườn?",
description: "Qua thời gian, center đời đầu của chuỗi show tuyển tú Produce - Jeon Somi ngày một đổi khác, trưởng thành và vô cùng cuốn hút.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-16282351309751096494008.jpg',
alt: "Jeon Somi,somi,produce,Produce 101,sixteen,JYP,JYP Entertainment,The Black Label,Teddy,vóc dáng,nuột nà,búp bê sống,",
category: 'stars',
time: '09:36 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:36 AM',
link: '/hanh-trinh-thay-doi-body-cua-bong-hong-lai-somi-fan-lo-lang-vi-vong-eo-sieu-thuc-lo-ca-xuong-suon',
component: 'page_20210806093626'},
{title: "Đồ tập bỗng dưng trở nên \"đen tối\" nhờ cách mix hoặc lối tạo dáng khó hiểu của những nhân vật này",
description: "Có người vô tình, có người thì bị cho là cố ý, nhưng dù thế nào thì cách họ mix đồ tập bỗng trở nên \"tăm tối\" hơn trong mắt người xem.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/2saovietnamnetjscvn-images-2021-07-05-10-02-quynh-nga-01-1628223153811906562888.jpg',
alt: "quỳnh nga,Mai Phương Thuý,đồ tập gym,lệ quyên,sao xấu,sao mặc xấu,thảm hoạ thời trang,sao đỏ thời trang,",
category: 'images',
time: '09:36 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:36 AM',
link: '/do-tap-bong-dung-tro-nen-den-toi-nho-cach-mix-hoac-loi-tao-dang-kho-hieu-cua-nhung-nhan-vat-nay',
component: 'page_20210806093614'},
{title: "Tiết lộ sốc: Messi bị Barcelona \"lật kèo\" vào phút chót, cay đắng rời Nou Camp trong nỗi đau",
description: "Có vẻ như chính Messi cũng không thể ngờ được mình lại không thể tiếp tục khoác áo Barcelona.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-1628216103335911731197.jpeg',
alt: "messi,barcelona,Messi bị Barcelona lật kèo,messi rời Barca,",
category: 'news',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/tiet-lo-soc-messi-bi-barcelona-lat-keo-vao-phut-chot-cay-dang-roi-nou-camp-trong-noi-dau',
component: 'page_20210805231056'},
{title: "Ariana Grande gây sốc nhẹ vì vòng 1 bỗng dưng nảy nở, ai ngờ giờ bị chính fandom \"lật tẩy\"",
description: "Quả là 1 màn \"bóc phốt\" thú vị tới từ cộng đồng fan của Ariana Grande.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/20542856341750211025806544325069818059031174n-16282140665451950051075.jpeg',
alt: "Ariana Grande,công nghệ thẩm mỹ thời nay,sao us uk,Showbiz,dao kéo,nâng ngực,",
category: 'stars',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/ariana-grande-gay-soc-nhe-vi-vong-1-bong-dung-nay-no-ai-ngo-gio-bi-chinh-fandom-lat-tay',
component: 'page_20210805231045'},
{title: "10 bộ phim khiến diễn viên chính nhục nhã, xấu hổ cả đời: Kate Winslet muốn nôn mửa vì Titanic, ngôi sao khác còn khóc suốt 1 tiếng vì bị ép đóng!",
description: "Những cái tên dưới đây dù hay - dở thế nào thì vẫn khiến diễn viên chính phải xấu hổ và nhục nhã, hối hận mãi không thôi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/1/photo-1-16278081029641864655115.png',
alt: "Titanic (1997),kate winslet,Nicole Kidman,zac efron,Jamie Lee Curtis,Kelly Clarkson,Will Smith,channing tatum,Lindsay Lohan,halle berry,ben affleck,phim Âu Mỹ,",
category: 'stars',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/10-bo-phim-khien-dien-vien-chinh-nhuc-nha-xau-ho-ca-doi-kate-winslet-muon-non-mua-vi-titanic-ngoi-sao-khac-con-khoc-suot-1-tieng-vi-bi-ep-dong',
component: 'page_20210805231036'},
]
export const dataContent07=[{title: "Những nàng công chúa đẹp kinh diễm nhất Cbiz: Angela Baby - Dương Mịch đẹp nghẹt thở, vẫn bị Nhiệt Ba diễm lệ \"đè bẹp\"",
description: "Vẫn biết dàn mỹ nhân Cbiz sở hữu nhan sắc hoàn hảo, tuy nhiên khi nhìn vào những lần Angela Baby, Địch Lệ Nhiệt Ba, Triệu Lệ Dĩnh,... dạo bước thảm đỏ với váy công chúa, dân tình vẫn xuýt xoa vì quá kinh diễm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/3/ba6-1627980978769306097484.jpeg',
alt: "Dương Mịch,Angela Baby,Lưu Diệc Phi,Triệu Lộ Tư,triệu lệ dĩnh,quan hiểu đồng,Cúc Tịnh Y,sao Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,Địch Lệ Nhiệt Ba,mỹ nhân,",
category: 'stars',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/nhung-nang-cong-chua-dep-kinh-diem-nhat-cbiz-angela-baby-duong-mich-dep-nghet-tho-van-bi-nhiet-ba-kinh-diem-de-bep',
component: 'page_20210805231011'},
{title: "Quỳnh Nga đăng ảnh khoe hoa quả nhưng spotlight lại dồn cả vào \"toà thiên nhiên\" sau lớp váy ngủ",
description: "Và người ta cũng biết đây không phải lần đầu Quỳnh Nga khiến người xem phải đắm đuối với \"kỳ quan\" của mình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/base64-1628180150788560845786.png',
alt: "quỳnh nga,sao Việt,showbiz Việt,sao sexy,ngực khủng,vòng 1,",
category: 'stars',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/quynh-nga-dang-anh-khoe-hoa-qua-nhung-spotlight-lai-don-ca-vao-toa-thien-nhien-sau-lop-vay-ngu',
component: 'page_20210805230945'},
{title: "Cháy rừng kỷ lục hoành hành ở California, phần lớn thị trấn Greenville chỉ còn là đống tro tàn",
description: "Đám cháy rừng Dixie đã \"xé toạc\" thị trấn miền núi Greenville, bang California, Mỹ vào tối 4/8 (theo giờ địa phương), khiến phần lớn thị trấn trở thành đống tro tàn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-16282158059912060667640.jpg',
alt: "cháy rừng kỷ lục tại California,cháy rừng,Mỹ,đống tro tàn,thị trấn greenville,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/chay-rung-ky-luc-hoanh-hanh-o-california-phan-lon-thi-tran-greenville-chi-con-la-dong-tro-tan',
component: 'page_20210805230936'},
{title: "Chiều nay, lý do Messi rời Barca sẽ chính thức được làm sáng tỏ",
description: "Đích thân Chủ tịch Joan Laporta sẽ lý giải nguyên nhân vì sao Barcelona để siêu sao Lionel Messi ra đi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-16282219122042139531664.jpg',
alt: "Messi rời Barca,messi,barcelona,barca,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/chieu-nay-ly-do-messi-roi-barca-se-chinh-thuc-duoc-lam-sang-to',
component: 'page_20210805230925'},
{title: "Toàn bộ sự nghiệp vĩ đại của Messi tại Barcelona qua ảnh",
description: "Hãy cùng điểm lại top những khoảnh khắc ấn tượng nhất của Lionel Messi trước khi anh rời Barca.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-14-1628201624024814025663.jpg',
alt: "messi,barcelona,la liga,messi rời Barca,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/toan-bo-su-nghiep-vi-dai-cua-messi-tai-barcelona-qua-anh',
component: 'page_20210805230916'},
{title: "Tuyên bố chia tay Messi chỉ là cái cớ, Barcelona sẵn sàng cho vụ \"lật kèo\" gây sốc?",
description: "Theo tiết lộ của nhà báo Tây Ban Nha - Toni Juanmarti, việc Barcelona tuyên bố chia tay Lionel Messi chỉ là cách để họ gây áp lực lên Ban tổ chức La Liga.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/1-16282213093631207320459.jpeg',
alt: "messi,barcelona,Messi rời Barca,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/tuyen-bo-chia-tay-messi-chi-la-cai-co-barcelona-san-sang-cho-vu-lat-keo-gay-soc',
component: 'page_20210805230908'},
{title: "Làn sóng game NFT nở rộ, thời của người chơi hệ \"cày cuốc\" ở Việt Nam đã tới nhưng liệu lúc này game có còn để vui?",
description: "Các tựa game NFT đa số đều chỉ phục vụ mục đích kiếm tiền của các game thủ thay vì tận hưởng niềm vui như trước.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/29/photo-1-16275550158302101498565.png',
alt: "TIN TỨC GAME,GAME THỦ,GAME,GAME NFT,",
category: 'games',
time: '08:49 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:49 AM',
link: '/lan-song-game-nft-no-ro-thoi-cua-nguoi-choi-he-cay-cuoc-o-viet-nam-da-toi-nhung-lieu-luc-nay-game-co-con-de-vui',
component: 'page_20210805084922'},
{title: "Camazotz – Batman phiên bản độc ác trong thần thoại Mesoamerica",
description: "Thần thoại Mesoamerican kể rằng sâu trong hang động tăm tối, có quái vật dơi Camazotz sinh sống. Nó là một trong 4 sinh vật đem đến sự hủy diệt trong thời kỳ mặt trời đầu tiên.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/camazotz0-1628071372472655698067.jpg',
alt: "KHÁM PHÁ,DƠI MA CÀ RỒNG,SINH VẬT HUYỀN BÍ,THẦN THOẠI,BATMAN,",
category: 'news',
time: '08:49 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:49 AM',
link: '/camazotz-batman-phien-ban-doc-ac-trong-than-thoai-mesoamerica',
component: 'page_20210805084910'},
{title: "Quá xinh đẹp và gợi cảm, hot girl 2k2 bất ngờ bị hỏi đầy tế nhị về chuyện tâm sinh lý, đáp trả thẳng thừng khiến CĐM ngỡ ngàng",
description: "Cô nàng hot girl đáp trả những bình luận khiếm nhã một cách vô cùng thông minh.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/photo-1-1628067838542765184467.jpg',
alt: "HOT GIRL,SUNNA,WILSON NHẬT ANH,CỘNG ĐỒNG MẠNG,GÁI XINH,",
category: 'images',
time: '08:48 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:48 AM',
link: '/qua-xinh-dep-va-goi-cam-hot-girl-2k2-bat-ngo-bi-hoi-day-te-nhi-ve-chuyen-tam-sinh-ly-dap-tra-thang-thung-khien-cdm-ngo-ngang',
component: 'page_20210805084847'},
{title: "Chẳng cần đẹp “nghiêng nước, nghiêng thành”, nghiêng như nữ streamer 19 tuổi này cũng đủ khiến fan nam ngây ngất",
description: "Khoe góc nghiêng thần thánh, nữ streamer 2002 lại khiến người xem “khó thở\".",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/8/5/22032532718207572814453247729205524555001674n-16281298282721637368836.jpeg',
alt: "STREAMER,LINH CHICHAN,HOT GIRL,NÓNG BỎNG,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '08:48 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:48 AM',
link: '/chang-can-dep-nghieng-nuoc-nghieng-thanh-nghieng-nhu-nu-streamer-19-tuoi-nay-cung-du-khien-fan-nam-ngay-ngat',
component: 'page_20210805084826'},
{title: "Giật mình khi thấy dàn gái xinh Ghibli đều béo ú sau thời gian dài ở nhà chống dịch, rồi ai cũng sẽ bị \"ngải heo\" quật thôi",
description: "Các fan hâm mộ truyện tranh luôn là những người cực kỳ sáng tạo và đôi khi các nhân vật hoạt hình nổi tiếng được họ \"chế\" lại thành bộ dạng cực \"dị\" mà bạn khó có thể tưởng tượng được.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/photo-1-162813241659122418386.jpg',
alt: "OẠT HÌNH GHIBLI,NHÂN VẬT GHIBLI,NHÂN VẬT GHIBLI BÉO Ú,CHIHIRO,",
category: 'games',
time: '08:48 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:48 AM',
link: '/giat-minh-khi-thay-dan-gai-xinh-ghibli-deu-beo-u-sau-thoi-gian-dai-o-nha-chong-dich-roi-ai-cung-se-bi-ngai-heo-quat-thoi',
component: 'page_20210805084816'},
{title: "Battlefield 2042 lộ cấu hình chính thức, CPU Core i7 4790, 16GB RAM và RTX 2060",
description: "Cấu hình Battlefield 2042 khá nặng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-16281361355451053647608.jpg',
alt: "LỘ CẤU HÌNH,CHIẾN DỊCH QUẢNG BÁ,BATTLEFIELD 2042,CARD ĐỒ HỌA,CẤU HÌNH TỐI THIỂU,GAME THỦ VIỆT,",
category: 'games',
time: '08:48 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:48 AM',
link: '/battlefield-2042-lo-cau-hinh-chinh-thuc-cpu-core-i7-4790-16gb-ram-va-rtx-2060',
component: 'page_20210805084806'},
{title: "So sánh sức mạnh của Super Saiyan 4 Gogeta và Super Saiyan Blue Gogeta, 2 \"lưỡng long nhất thể\" mạnh nhất thế giới Dragon Ball",
description: "Liệu sự hợp nhất nào của Gogeta là đỉnh cao của cuộc chiến trong thế giới Dragon Ball?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/anh-1-16280620138171675379246.png',
alt: "DRAGON BALL,7 VIÊN NGỌC RỒNG,HỢP THỂ GOGETA,SUPER SAIYAN 4,DRAGON BALL GT,DRAGON BALL SUPER,SUPER SAIYAN BLUE,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/so-sanh-suc-manh-cua-super-saiyan-4-gogeta-va-super-saiyan-blue-gogeta-2-luong-long-nhat-the-manh-nhat-the-gioi-dragon-ball',
component: 'page_20210805084759'},
{title: "Những điểm yếu ngớ ngẩn mà khán giả khó chấp nhận ở 10 phản diện anime đình đám (P.1)",
description: "Một số phản diện trong anime vô cùng tiềm năng, nhưng lại bị đánh bại theo cách không thể ngờ đến vì điểm yếu ngớ ngẩn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/princess-tutu-duck-as-a-duck-dancing-1628064431920805535622.jpg',
alt: "KHÁM PHÁ,MANGA,ANIME,HOẠT HÌNH NHẬT BẢN,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/nhung-diem-yeu-ngo-ngan-ma-khan-gia-kho-chap-nhan-o-10-phan-dien-anime-dinh-dam-p1',
component: 'page_20210805084750'},
{title: "Đừng \"nhìn mặt mà bắt hình dong\", 5 nhân vật anime này mạnh hơn rất nhiều so với vẻ ngoài \"ngáo ngơ\" của mình",
description: "Không giống như bề ngoài của mình, một số nhân vật trong anime sở hữu sức mạnh vô cùng kinh khủng khiến nhiều người phải e sợ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/anh-1-16280589606261873536026.jpg',
alt: "ANIME,NHÂN VẬT ANIME,DRAGON BALL,ONE PIECE,BLACK CLOVER,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/dung-nhin-mat-ma-bat-hinh-dong-5-nhan-vat-anime-nay-manh-hon-rat-nhieu-so-voi-ve-ngoai-ngao-ngo-cua-minh',
component: 'page_20210805084741'},
{title: "Những thử thách tưởng là vớ vẩn nhưng đố bạn làm được hoàn hảo",
description: "Mùa dịch rảnh rỗi thì thử làm những thứ như thế này xem, biết đâu bạn khám phá ra được tài năng của bản thân đó.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/photo-1-16281539793231764751337.jpg',
alt: "KỲ TÍCH,VỚ VẨN,",
category: 'news',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/nhung-thu-thach-tuong-la-vo-van-nhung-do-ban-lam-duoc-hoan-hao',
component: 'page_20210805084732'},
{title: "Sẽ ra sao nếu các loài động vật đều có tạo hình khối hộp vuông như Minecraft?",
description: "Đã bao giờ bạn hình dung về việc thế giới quanh ta bỗng nhiên trở thành những khối vuông như trong Minecraft chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-16281597059632071972653.jpg',
alt: "MINECRAFT,KHỐI HỘP VUÔNG,GAME 8-BIT,ĐỘNG VẬT,",
category: 'news',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/se-ra-sao-neu-cac-loai-dong-vat-deu-co-tao-hinh-khoi-hop-vuong-nhu-minecraft',
component: 'page_20210805084722'},
{title: "Thủ thuật đơn giản giúp Google Chrome bớt ngốn RAM",
description: "Cách đơn giản giúp sử dụng Google Chrome bớt ngốn RAM.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/-1628164834349595680420.jpg',
alt: "RAM,GOOGLE CHROME,CHROME,",
category: 'tech',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/thu-thuat-don-gian-giup-google-chrome-bot-ngon-ram',
component: 'page_20210805084714'},
{title: "11 thông tin thú vị xung quanh Goku trong Dragon Ball: chưa bao giờ đánh bại Vegeta, cũng chẳng phải người mạnh nhất",
description: "Goku vẫn còn tiềm năng phát triển mạnh mẽ hơn trong tương lai.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-16281580913081068309699.jpg',
alt: "GOKU,DRAGON BALL,THÔNG TIN VỀ GOKU,BẢN NĂNG VÔ CỰC,KỸ THUẬT CỦA GOKU,TÊN THẬT CỦA GOKU,",
category: 'games',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/11-thong-tin-thu-vi-xung-quanh-goku-trong-dragon-ball-chua-bao-gio-danh-bai-vegeta-cung-chang-phai-nguoi-manh-nhat',
component: 'page_20210805084706'},
{title: "Wakuoo - Nền tảng chơi Game Mobile trên PC thế hệ mới nhẹ hơn giả lập",
description: "Nếu bạn muốn trải nghiệm Game&APP Android trên máy tính mà không muốn cài giả lập vì lo nặng máy, thì Wakuoo chính là sự lựa chọn không tồi, phù hợp cho máy tính cấu hình thấp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/5-16281344631912005858058.jpg',
alt: "WAKUOO,CHƠI GAME ANDROID TRÊN PC,CHƠI GAME MOBILE TRÊN PC,GIẢ LẬP ANDROID,",
category: 'tech',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/wakuoo-nen-tang-choi-game-mobile-tren-pc-the-he-moi-nhe-hon-gia-lap',
component: 'page_20210805084655'},
{title: "Xuất hiện thanh niên siêu nhọ, ngồi nhà chơi game mà vẫn bị sét đánh… qua tay cầm",
description: "Cày game đang hăng thì bị sét đánh trúng khiến cho thanh niên game thủ bị giật điện thông qua tay cầm, rất may là vẫn an toàn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-1628087795258881430866.jpg',
alt: "",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/xuat-hien-thanh-nien-sieu-nho-ngoi-nha-choi-game-ma-van-bi-set-danh-qua-tay-cam',
component: 'page_20210805084643'},
{title: "Tổng hợp những trái ác quỷ Zoan thần thoại đã xuất hiện trong One Piece, ai cũng bá đạo ngoại trừ người này?",
description: "Được xem là loại trái ác quỷ hiếm nhất, cùng chúng tôi điểm qua những người sở hữu sức mạnh đáng sợ trong One Piece này nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/ky1-1628082681430236186284.png',
alt: "ONE PIECE,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,TỨ HOÀNG KAIDO,PHƯỢNG HOÀNG MARCO,NĂNG LỰC ÁC QUỶ YAMATO,TRÁI ÁC QUỶ,ZOAN THẦN THOẠI,THỦY SƯ SENGOKU,",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/tong-hop-nhung-trai-ac-quy-zoan-than-thoai-da-xuat-hien-trong-one-piece-ai-cung-ba-dao-ngoai-tru-nguoi-nay',
component: 'page_20210805084633'},
{title: "Đấu Trường Chân Lý: 3 đội hình xoay quanh Yasuo ở mùa 5.5 mà fan của \"đấng\" không thể bỏ qua",
description: "Nếu game thủ Đấu Trường Chân Lý sở hữu Yasuo từ sớm thì có thể hướng tới 1 trong 3 đội hình dưới đây.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/ma-su-16280628718901700424253.jpg',
alt: "ĐẤU TRƯỜNG CHÂN LÝ,CẨM NANG ĐẤU TRƯỜNG CHÂN LÝ,RIOT GAMES,GAME,RIOT,TIN GAME,YASUO,LEE SIN,RIVEN,DRAVEN,RELL,JAX,KALISTA,",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/dau-truong-chan-ly-3-doi-hinh-xoay-quanh-yasuo-o-mua-55-ma-fan-cua-dang-khong-the-bo-qua',
component: 'page_20210805084622'},
{title: "Những tựa game còn chưa ra mắt đã biết là thất bại",
description: "Công bằng mà nói thì các tựa game này có chất lượng không đến nỗi tệ, thậm chí là khá tốt nhưng cuối cùng vẫn \"hỏng việc\" vì những sai lầm khác nhau.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280898648651601284701.jpg',
alt: "GAME,",
category: 'games',
time: '08:46 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:46 AM',
link: '/nhung-tua-game-con-chua-ra-mat-da-biet-la-that-bai',
component: 'page_20210805084609'},
{title: "Cận cảnh thành phố hẹp nhất thế giới, kiến trúc độc đáo ai cũng khen lạ nhưng bảo ở lại thì... thôi",
description: "Nói chung là được cái tiếng chứ không được cái miếng nào cả!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/bst4w0ghy7tsfghs-1628167401897111052670.jpg',
alt: "kiến trúc độc đáo,mạng xã hội Trung Quốc,relax,thành phố,kì lạ,",
category: 'travel',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/can-canh-thanh-pho-hep-nhat-the-gioi-kien-truc-doc-dao-ai-cung-khen-la-nhung-bao-o-lai-thi-thoi',
component: 'page_20210805084555'},
{title: "Những bộ phim \"thất lạc\" của tuổi thơ chúng ta: Một thời mất ăn mất ngủ, giờ muốn tìm lại cái tên cũng khó!",
description: "Liệu có ai mất cả thanh xuân để đi tìm lại những bộ phim này không?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/1/g0hlezfqjp5drxmzkgzww9pup7n-1627798441341617348561.jpg',
alt: "Zathura: A Space Adventure,Kristen Stewart,josh hutcherson,taylor lautner,rachel mcadams,Anna Faris,phim Âu Mỹ,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/nhung-bo-phim-that-lac-cua-tuoi-tho-chung-ta-mot-thoi-mat-an-mat-ngu-gio-muon-tim-lai-cai-ten-cung-kho',
component: 'page_20210805084545'},
{title: "Tìm thấy “máy tính bảng” 3.700 năm tuổi của người Babylon: Minh chứng hùng hồn nhất của “xuyên không”?",
description: "Chiếc máy tính bảng Si.427 3.700 năm tuổi của người Babylon ẩn chứa điều kinh ngạc gì?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/photo-1-16281517391601033572813.jpg',
alt: "máy tính bảng,nhà toán học,thế kỷ 19,khảo cổ học,Thổ Nhĩ Kỳ,New South Wales,tam giác vuông,hình chữ nhật,sử dụng thiết bị,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/tim-thay-may-tinh-bang-3700-nam-tuoi-cua-nguoi-babylon-minh-chung-hung-hon-nhat-cua-xuyen-khong',
component: 'page_20210805084533'},
{title: "Avengers: Endgame từng có 1 đoạn rất ghê rợn và tàn nhẫn về Đội trưởng Mỹ, đạo diễn hé lộ lý do bắt buộc phải cắt bỏ",
description: "Trong cảnh phim này của Avengers: Endgame, Thanos đã thực sự chém đầu Đội trưởng Mỹ không khoan nhượng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/photo-1-1628139198044442413037.jpg',
alt: "Avengers: Endgame (2019),marvel,phim marvel,Captain America,Thanos,phim Âu Mỹ,vũ trụ điện ảnh marvel,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/avengers-endgame-tung-co-1-doan-rat-ghe-ron-va-tan-nhan-ve-doi-truong-my-dao-dien-he-lo-ly-do-bat-buoc-phai-cat-bo',
component: 'page_20210805084521'},
{title: "Bà xã nhà Messi ngày càng trở nên nóng bỏng thế này, bảo sao đến bạn gái Ronaldo cũng phải \"thả tim\" lia lịa",
description: "Antonella Roccuzzo ngày càng trở nên bạo dạn và quyến rũ hơn thông qua những shoot hình đăng tải lên mạng xã hội.",
src: 'https://sport5.mediacdn.vn/158855217956261888/2021/8/5/antonelaroccuzzo23148993836812276786435964785506388326497197n-1628156574716450839769.jpg',
alt: "messi,Bà xã Messi,Antonella Roccuzzo,bạn gái ronaldo,georgina,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/ba-xa-nha-messi-ngay-cang-tro-nen-nong-bong-the-nay-bao-sao-den-ban-gai-ronaldo-cung-phai-tha-tim-lia-lia',
component: 'page_20210805084510'},
{title: "Ngắm lại visual 5 nàng Tây Thi đẹp nhất: Mỹ nhân thập niên 80 - 90 đẹp mãn nhãn, có người U40 vẫn \"cưa sừng\" cực ngọt khi vào vai thiếu nữ 15",
description: "Đây chính là những nàng Tây Thi để lại dấu ấn sâu đậm trong lòng khán giả bởi tạo hình xuất thần.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/photo-1-1628042726013449816725.jpg',
alt: "Tây Thi,đồng lệ á,Tưởng Cần Cần,Tây Thi Bí Sử,star style,Makeup,tạo hình nhân vật,",
category: 'news',
time: '07:00 PM',
date: '04/08/2021',
timestamp: '04/08/2021 07:00 PM',
link: '/ngam-lai-visual-5-nang-tay-thi-dep-nhat-my-nhan-thap-nien-80-90-dep-man-nhan-co-nguoi-u40-van-cua-sung-cuc-ngot-khi-vao-vai-thieu-nu-15',
component: 'page_20210804190016'},
{title: "Top 6 nhân loại mạnh nhất trong Attack on Titan, Mikasa Ackerman thế mà chỉ đứng hạng 3",
description: "Top 3 của danh sách này đã bị thống trị bởi tộc nhân Ackerman.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280705862911029149739.jpg',
alt: "ATTACK ON TITAN,TITAN,ERWIN SMITH,GABI,MIKE ZACHARIAS,NHÂN LOẠI MẠNH NHẤT ATTACK ON TITAN,",
category: 'games',
time: '07:00 PM',
date: '04/08/2021',
timestamp: '04/08/2021 07:00 PM',
link: '/top-6-nhan-loai-manh-nhat-trong-attack-on-titan-mikasa-ackerman-the-ma-chi-dung-hang-3',
component: 'page_20210804190000'},
{title: "Hướng dẫn reset modem để sóng Wi-fi mạnh hơn, ổn định hơn",
description: "Một phương pháp đơn giản giúp sóng Wi-fi ở nhà bạn luôn ổn định.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/photo-1-1628072573804417190587.jpg',
alt: "MODEM,MODEM WI-FI,SÓNG WI-FI,WI-FI,WIFI,MODEM WIFI,KẾT NỐI INTERNET,",
category: 'tech',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/huong-dan-reset-modem-de-song-wi-fi-manh-hon-on-dinh-hon',
component: 'page_20210804185950'},
{title: "One Punch Man: Chỉ với 5 chiêu thức bình thường này mà Thánh Phồng Saitama trở nên \"vô đối\"",
description: "Ngoài kĩ năng tối thượng \"Đấm thường nghiêm túc\", anh hùng One Punch Man của chúng ta còn chiêu gì nữa? Cùng chúng tôi điểm qua những chiêu thức của Thánh Phồng Saitama nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/hp1-1628050012532853990835.jpg',
alt: "MANGA ONE PUNCH MAN,TÁC GIẢ ONE PUNCH MAN,MỸ NHÂN ONE PUNCH MAN,FAN ART ONE PUNCH MAN,ANIME ONE PUNCH MAN,FAN ONE PUNCH MAN,CỘNG ĐỒNG ONE PUNCH MAN,THÁNH PHỒNG SAITAMA,BÍ MẬT VỀ SỨC MẠNH CỦA SAITAMA,THẦY TRÒ SAITAMA VS GENOS,",
category: 'games',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/one-punch-man-chi-voi-5-chieu-thuc-binh-thuong-nay-ma-thanh-phong-saitama-tro-nen-vo-doi',
component: 'page_20210804185942'},
{title: "Từ Hulk xanh, Hulk đỏ, Hulk xám thì giờ đây chúng ta có cả Blue Hulk!",
description: "Hulk có màu xanh lá cây, đỏ, xám dường như đã quá quen thuộc với chúng ta, nhưng Blue Hulk lại là một điều khá mới mẻ là đây cũng là một trong những nhân vật mạnh mẽ nhất của Marvel.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-1628050244150335388129.jpg',
alt: "HULK,",
category: 'news',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/tu-hulk-xanh-hulk-do-hulk-xam-thi-gio-day-chung-ta-co-ca-blue-hulk',
component: 'page_20210804185933'},
{title: "Mỹ nữ 18+ kể chuyện nghề, bật mí đến năm 19 tuổi mới biết \"chuyện người lớn là gì\"",
description: "Theo chia sẻ của Ema Futaba, cô nàng cảm thấy mình là người run nhất khi bước chân vào ngành công nghiệp 18+.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/photo-1-16280459479352124794069.jpg',
alt: "EMA FUTABA,IDOL,",
category: 'images',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/my-nu-18-ke-chuyen-nghe-bat-mi-den-nam-19-tuoi-moi-biet-chuyen-nguoi-lon-la-gi',
component: 'page_20210804185918'},
{title: "Tại sao khỉ đột, tinh tinh và chuột túi hầu như không cần phải tập luyện sức mạnh mà cơ bắp của chúng vẫn cực kỳ phát triển?",
description: "Chắc chắn chúng ta đã từng nhìn thấy những con chuột túi đực, tinh tinh hay khỉ đột sở hữu cơ bắp cuồn cuộn mà chẳng cần phải đến phòng tập gym, trong khi con người chúng ta phải trả qua tập luyện cực khổ mới có được cơ bắp như ý muốn, tại sao lại như vậy?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/photo-1-16280543932061707804782.jpg',
alt: "KHỈ ĐỘT,ĐỘNG VẬT,TINH TINH,CƠ BẮP,CỘNG ĐỒNG MẠNG,",
category: 'news',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/tai-sao-khi-dot-tinh-tinh-va-chuot-tui-hau-nhu-khong-can-phai-tap-luyen-suc-manh-ma-co-bap-cua-chung-van-cuc-ky-phat-trien',
component: 'page_20210804185909'},
{title: "Pokémon: Câu chuyện đau lòng mà không phải ai cũng biết về quá khứ của Jessie, hot girl Team Rocket",
description: "Jessie của Team Rocket nổi tiếng với bản chất mưu mô và hay cạnh tranh, thế nhưng đằng sau đó là cô cũng là một người vô cùng đáng thương.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/anh-1-1627986987887728224467.jpg',
alt: "POKÉMON,TEAM ROCKET,JESSIE,PIKACHU,QUÁ KHỨ TEAM ROCKET,ANIME NHẬT BẢN,",
category: 'games',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/pokemon-cau-chuyen-dau-long-ma-khong-phai-ai-cung-biet-ve-qua-khu-cua-jessie-hot-girl-team-rocket',
component: 'page_20210804185900'},
{title: "Dragon Ball Super: Tại sao Whis và các thiên thần khác không bao giờ trực tiếp chiến đấu?",
description: "Là một trong những sinh vật mạnh mẽ nhất trong Dragon Ball Super, Whis có thể giải quyết mọi vấn đề mà các anh hùng của chúng ta từng gặp phải - vậy tại sao anh ấy lại không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-16280475963551265237932.jpeg',
alt: "WHIS,THIÊN SỨ,DRAGON BALL SUPER,THIÊN THẦN,THẦN HỦY DIỆT,",
category: 'games',
time: '06:58 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:58 PM',
link: '/dragon-ball-super-tai-sao-whis-va-cac-thien-than-khac-khong-bao-gio-truc-tiep-chien-dau',
component: 'page_20210804185848'},
{title: "Lắc mạnh tới mức \"rụng\" 90% đôi gò bồng đảo trên sóng vì được donate khủng, nữ streamer bị cấm kênh ngay sau đó",
description: "Hình ảnh của cô nàng streamer này đang được phủ sóng khắp mọi nơi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-1627901503857631211449.gif',
alt: "CỘNG ĐỒNG MẠNG,NỮ STREAMER,UMJI,STREAMER,HOT GIRL,GÁI XINH,",
category: 'images',
time: '06:58 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:58 PM',
link: '/lac-manh-toi-muc-rung-90-doi-go-bong-dao-tren-song-vi-duoc-donate-khung-nu-streamer-bi-cam-kenh-ngay-sau-do',
component: 'page_20210804185820'},
{title: "Ngắm nhan sắc nóng bỏng của các mỹ nhân 18+ trong \"ngày khoe ngực\"",
description: "Đến hẹn lại lên, các cư dân mạng lại được một phen trầm trồ và mãn nhãn trong ngày Oppai no Hi, hay còn được biết đến như ngày của ngực.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/-1627970236034478794934.jpg',
alt: "YUA MIKAMI,IDOL,",
category: 'images',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/ngam-nhan-sac-nong-bong-cua-cac-my-nhan-18-trong-ngay-khoe-nguc',
component: 'page_20210804185749'},
{title: "Đòi tiền chuộc EA không trả, rao bán chẳng ai thèm mua, hacker đắng lòng phát miễn phí mã nguồn FIFA 21 lên mạng",
description: "Sau nỗ lực tống tiền EA và rao bán chợ đen thất bại, hacker bất lực phát tán mã nguồn FIFA 21 cho ai dùng thì dùng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-162801406573018726064.jpg',
alt: "FIFA,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,HACKER,DỮ LIỆU,",
category: 'tech',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/doi-tien-chuoc-ea-khong-tra-rao-ban-chang-ai-them-mua-hacker-dang-long-phat-mien-phi-ma-nguon-fifa-21-len-mang',
component: 'page_20210804185739'},
{title: "VCS từ ngày \"ra ở riêng\", trở thành khu vực lớn: Gặt hái được gì ngoài hai chữ \"thụt lùi\"?",
description: "Việc trở thành một khu vực độc lập vào năm 2018 tưởng như sẽ là cột mốc mới cho sự phát triển của VCS...",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/-16280085373611997724014.jpg',
alt: "LIÊN MINH HUYỀN THOẠI,LMHT,GAM,VCS,GARENA,MSI,CKTG,GAME,TIN TỨC GAME,TIN GAME,TIN LMHT,TIN LOL,",
category: 'games',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/vcs-tu-ngay-ra-o-rieng-tro-thanh-khu-vuc-lon-gat-hai-duoc-gi-ngoai-hai-chu-thut-lui',
component: 'page_20210804185730'},
{title: "Những game hậu bản dở thậm chí dở hơn cả phần trước",
description: "Không phải lúc nào làm ra phần sau thì cũng ăn khách như phần trước được cả.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/photo-1-1628006300469786762825.jpg',
alt: "GAME,",
category: 'games',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/nhung-phan-hau-ban-do-den-muc-lam-hong-ca-danh-tieng-game-truoc',
component: 'page_20210804185722'},
{title: "Tổng hợp 5 cách để giúp PC của bạn đỡ ồn vào đêm khuya",
description: "Vào buổi đêm tĩnh lặng, bạn sẽ thấy dàn PC của mình phát ra những tiếng ồn ào không cần thiết...",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/photo-1-16280086374332119094977.jpg',
alt: "TIẾNG ỒN,GAMING,DÀN PC,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'tech',
time: '06:57 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:57 PM',
link: '/tong-hop-5-cach-de-giup-pc-cua-ban-do-on-vao-dem-khuya',
component: 'page_20210804185714'},
{title: "Vụ án đào mộ chấn động: Xác chết cổ nhất Trung Quốc bị ném xuống mương, hung thủ bại lộ vì bức thư nặc danh!",
description: "Tháng 5/1994, thi hài cổ nhất Trung Quốc được phát hiện ở thành phố Kinh Môn, tỉnh Hồ Bắc. Đây là thi hài người phụ nữ sống cách đây gần 2.400 năm được bảo tồn hoàn chỉnh nhất về ngoại hình và xương cốt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/photo-6-1628067552764355170762.jpg',
alt: "tỉnh Hồ Bắc,người phụ nữ,quy mô lớn,cảnh sát trưởng,cỗ quan tài,tầng lớp quý tộc,Lịch sử đất nước,Thời Chiến Quốc,đào trộm mộ,thi thể phụ nữ,cao chạy xa bay,",
category: 'news',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/vu-an-dao-mo-chan-dong-xac-chet-co-nhat-trung-quoc-bi-nem-xuong-muong-hung-thu-bai-lo-vi-buc-thu-nac-danh',
component: 'page_20210804185659'},
{title: "Các phi tần tuẫn táng cùng hoàng đế khi được phát hiện chân luôn ở tư thế mở rộng, trước khi chết đã xảy ra chuyện gì?",
description: "Những gì mà các phi tần mỹ nữ phải tuẫn táng cùng hoàng đế phải chịu đựng trước khi chết sẽ được hé lộ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/2/-1627902134049205873274.jpg',
alt: "thời cổ đại,cung tần mỹ nữ,Trung Quốc,",
category: 'news',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/cac-phi-tan-tuan-tang-cung-hoang-de-chan-khi-duoc-phat-hien-chan-luon-o-tu-the-mo-rong-truoc-khi-chet-da-xay-ra-chuyen-gi',
component: 'page_20210804185649'},
{title: "\"Tuyển tập\" bê bối đạo nhái của Vu Chính: Chê bai Như Ý Truyện nhưng \"cướp\" hẳn cảnh quay cho vào phim mình?",
description: "Các bộ phim truyền hình do Vu Chính sản xuất không ít lần dính nghi án đạo nhái, vay mượn ý tưởng.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/3/photo-1-1628001417737353057620.jpg',
alt: "phim Hoa ngữ,Phim truyền hình,phim truyền hình Hoa ngữ,phim truyền hình trung quốc,phim trung quốc,Thâm cung nội chiến,Xa Thi Mạn,trương khả di,Hậu Cung Như Ý Truyện (2018),hậu cung chân hoàn truyện (2011),vu chính,",
category: 'news',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/tuyen-tap-be-boi-dao-nhai-cua-vu-chinh-che-bai-nhu-y-truyen-nhung-cuop-han-canh-quay-cho-vao-phim-minh',
component: 'page_20210804185635'},
{title: "\"Làm tổ\" trên giường cả ngày mà không biết 7 cách nâng \"mood\" phòng ngủ thì khó mà thư giãn",
description: "Phòng ngủ của bạn sẽ chill hơn nếu biết 7 mẹo nhỏ mà có võ này.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/photo-1-16280664391501236439911.jpg',
alt: "phòng ngủ,bóng đèn led,nến thơm,máy lọc không khí,vỏ gối,house n home,",
category: 'life',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/lam-to-tren-giuong-ca-ngay-ma-khong-biet-7-cach-nang-mood-phong-ngu-thi-kho-ma-thu-gian',
component: 'page_20210804185624'},
{title: "Gợi ý thực phẩm cần thiết để phát triển cơ bắp cho người tập thể hình",
description: "Để phát triển cơ bắp, bạn có thể lựa chọn một số thực phẩm giúp tăng cơ dưới đây!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/nhung-dieu-it-biet-ve-trung-ga-xong-khoi-han-quoc-dang-gay-sot-o-viet-nam-324992124719-1500777418-width600height380-16280716646241504815593.jpg',
alt: "phát triển cơ bắp,đảm bảo dinh dưỡng,chế độ ăn uống lành mạnh,chế độ tập luyện,ăn uống khoa học,tăng cơ bắp,",
category: 'life',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/goi-y-thuc-pham-can-thiet-de-phat-trien-co-bap-cho-nguoi-tap-the-hinh',
component: 'page_20210804185614'},
]
export const dataContent08=[{title: "One Piece: Là sự phụ của Zoro, liệu Mắt Diều Hâu có đủ sức để đánh bại Kaido?",
description: "Đây là câu hỏi mà nhiều fan One Piece thắc mắc khi mà Zoro đã có thể chém được Kaido thì với sức mạnh của mình liệu ông thầy Mắt Diều Hâu sẽ làm được gì Tứ Hoàng này hay không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/ha1-1627968691684392808561.jpg',
alt: "ONE PIECE,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,TRUYỆN ONE PIECE,TỨ HOÀNG KAIDO,ARC WANO,ZORO VÀ MIHAWK,DRACULE MIHAWK,MẮT DIỀU HÂU MIHAWK,RORONOA ZORO,HAKI BÁ VƯƠNG,TỨ HOÀNG SHANKS,",
category: 'games',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/one-piece-la-su-phu-cua-zoro-lieu-mat-dieu-hau-co-du-suc-de-danh-bai-kaido',
component: 'page_20210803111333'},
{title: "Khi \"tâm hồn nặng trĩu\" của nữ thần sắc đẹp trong Record Of Ragnarok trở thành vựa muối chế ảnh, tấm nào nhìn cũng sướng nhức mắt",
description: "Xem loạt ảnh chế theo nữ thần sắc đẹp trong Record Of Ragnarok dưới đây mà các fan đều phải tấm tắc khen quá sáng tạo.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/2/-162790902781713007764.jpg',
alt: "RECORD OF RAGNAROK,APHRODITE,NỮ THẦN SẮC ĐẸP,ẢNH CHẾ APHRODITE,MEME APHRODITE,",
category: 'games',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/khi-tam-hon-nang-triu-cua-nu-than-sac-dep-trong-record-of-ragnarok-tro-thanh-vua-muoi-che-anh-tam-nao-nhin-cung-suong-nhuc-mat',
component: 'page_20210803111324'},
{title: "Bất ngờ lên sóng hít đất, tập thể dục rồi kéo quần khoe vòng eo thon gọn, nữ streamer gợi cảm nhận vô số chỉ trích",
description: "Cô nàng streamer đang là tâm điểm của mọi sự chú ý sau tiết mục lên sóng quá nóng bỏng vừa rồi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/-16279627859881063865677.jpg',
alt: "HOT GIRL,CỘNG ĐỒNG MẠNG,HWA-JEONG,STREAMER,GÁI XINH,NỮ STREAMER,",
category: 'images',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/bat-ngo-len-song-hit-dat-tap-the-duc-roi-keo-quan-khoe-vong-eo-thon-gon-nu-streamer-goi-cam-nhan-vo-so-chi-trich',
component: 'page_20210803111311'},
{title: "Nở rộ các dự án chơi game kiếm tiền ăn theo Axie Infinity",
description: "Dù phần nhiều là dự án trên giấy, vô số đồng tiền ảo của các dự án game blockchain (hay game kiếm ra tiền) đã mọc lên như nấm hút hàng triệu USD của những nhà đầu tư mạo hiểm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/photo-1-16279711334191957670293.jpg',
alt: "AXIE INFINITY,GAME,NFT,",
category: 'games',
time: '11:13 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:13 AM',
link: '/no-ro-cac-du-an-choi-game-kiem-tien-an-theo-axie-infinity',
component: 'page_20210803111304'},
{title: "Muốn \"bóc trần\" nhan sắc Rosé (BLACKPINK) ở hậu trường mà bó tay, \"đắt\" nhất combo tóc vàng bay tôn lên visual đẹp muốn lịm đi",
description: "Rosé (BLACKPINK) thực sự đẹp như nàng thơ trong hậu trường chương trình The Sea Of Hope.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/3/unnamed-1-16279577072521308032579.jpg',
alt: "Blackpink,rosé (blackpink),Rosé,the sea of hope,nhan sắc sao Hàn,nhan sắc của blackpink,nhan sắc của sao Hàn,nhan sắc thật của sao,",
category: 'stars',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/muon-boc-tran-nhan-sac-rose-blackpink-o-hau-truong-ma-bo-tay-dat-nhat-combo-toc-vang-bay-ton-len-visual-dep-muon-lim-di',
component: 'page_20210803111244'},
{title: "Bao năm qua, người chơi Counter-Strike 1.1 bị “lừa” bởi bí mật vũ khí trong game, đa số chẳng hề hay biết",
description: "Có một sự thật khó thú vị mà bao năm nay, game thủ Counter-Strike phiên bản 1.1 bị qua mặt mà không hề hay biết.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-1627922207809764016793.jpg',
alt: "COUNTER-STRIKE 1.1,CS:GO,COUNTER-STRIKE,COUNTER STRIKE,COUNTER STRIKE 1.1,TIN TỨC GAME,",
category: 'games',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/bao-nam-qua-nguoi-choi-counter-strike-11-bi-lua-boi-bi-mat-vu-khi-trong-game-da-so-chang-he-hay-biet',
component: 'page_20210803111232'},
{title: "Một game thủ khiến cộng đồng trầm trồ khi tự mình đưa tất cả tướng LMHT thành bài ma thuật Yu-Gi-Oh!",
description: "Game thủ LMHT đã phải tốn tới 3 năm để hoàn thành bộ sưu tập kể trên.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/3/photo-1-1627953614281910334369.jpg',
alt: "LIÊN MINH HUYỀN THOẠI,LMHT,RIOT GAMES,GAME,RIOT,TIN GAME,YU-GI-OH!,YUGI OH,TIN TỨC GAME,GAME THỦ,CỘNG ĐỒNG MẠNG,GAME THỦ LMHT,",
category: 'games',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/mot-game-thu-khien-cong-dong-tram-tro-khi-tu-minh-dua-tat-ca-tuong-lmht-thanh-bai-ma-thuat-yu-gi-oh',
component: 'page_20210803111225'},
{title: "One Piece: Những bằng chứng rõ ràng nhất cho thấy Shanks có một đứa con, nhưng \"ông chú\" Tóc Đỏ lại bỏ bê đi \"giải cứu thế giới\"",
description: "Là một trong những nhân vật quan trọng trong One Piece, nhưng những thông tin về Shanks này lại rất ít được tiết lộ, đặc biệt là về đời tư của anh.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/avata-1627804270384233611899.jpg',
alt: "SHANKS TÓC ĐỎ,TỨ HOÀNG,SHANKS CÓ CON,LUFFY MŨ RƠM,CON CỦA MAKINO,ONE PIECE,",
category: 'games',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/one-piece-nhung-bang-chung-ro-rang-nhat-cho-thay-shanks-co-mot-dua-con-nhung-ong-chu-toc-do-lai-bo-be-di-giai-cuu-the-gioi',
component: 'page_20210803111216'},
{title: "Vén áo lên sóng nhảy quá bốc, nữ streamer xinh đẹp xấu hổ khi để lộ \"một chấm\", cố che đậy nhưng không kịp",
description: "Trang phục thì lỏng lẻo trong khi lại vận động mạnh, cô nàng streamer này không hớ hênh mới lạ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/30/-162761049583397657357.jpg',
alt: "STREAMER,NỮ STREAMER,CỘNG ĐỒNG MẠNG,CHAE-AH,HOT GIRL,GÁI XINH,",
category: 'images',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/ven-ao-len-song-nhay-qua-boc-nu-streamer-xinh-dep-xau-ho-khi-de-lo-mot-cham-co-che-day-nhung-khong-kip',
component: 'page_20210803111200'},
{title: "5 nhân vật \"siêu phụ\" nhưng lại có vai trò \"siêu quan trọng\", làm ảnh hưởng lớn đến cốt truyện anime/manga",
description: "Nếu không có họ thì chắc chắn câu chuyện sẽ đi theo một hướng hoàn toàn khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/anh-1-16278854979831663757585.jpg',
alt: "ONE PIECE,NARUTO,MY HERO ACADEMIA,ANIME,DRAGON BALL,KIMETSU NO YAIBA,ANIME NHẬT BẢN,NHÂN VẬT PHỤ,",
category: 'games',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/5-nhan-vat-sieu-phu-nhung-lai-co-vai-tro-sieu-quan-trong-lam-anh-huong-lon-den-cot-truyen-anime-manga',
component: 'page_20210803111152'},
{title: "Các nhà khoa học đã chụp được ảnh thời điểm trứng người chấp nhận tinh trùng, thực sự sẽ có một tia sáng cực lớn như pháo hoa",
description: "Cách đây vài ngày, khi một nhóm nghiên cứu từ Đại học Northwestern đang quan sát quá trình thụ tinh của một tế bào trứng, họ đã rất ngạc nhiên khi phát hiện ra rằng khi một tế bào trứng của con người chấp nhận tinh trùng, một ánh sáng chói lóa phát ra xung quanh tế bào trứng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/photo-1-1627924827274339787322.gif',
alt: "KHÁM PHÁ,KHOA HỌC,CON NGƯỜI,TINH TRÙNG,CỘNG ĐỒNG MẠNG,",
category: 'news',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/cac-nha-khoa-hoc-da-chup-duoc-anh-thoi-diem-trung-nguoi-chap-nhan-tinh-trung-thuc-su-se-co-mot-tia-sang-cuc-lon-nhu-phao-hoa',
component: 'page_20210803111136'},
{title: "Cấu hình máy tính như nào để chơi được Bless Unleashed, game nhập vai trực tuyến miễn phí hot nhất Steam?",
description: "Bless Unleashed là game miễn phí cực hot trên Steam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-1627918275629617952262.jpeg',
alt: "CẤU HÌNH MÁY TÍNH,GAME NHẬP VAI,GAME MIỄN PHÍ,BLESS UNLEASHED,STEAM,GAME MMORPG,FREE TO PLAY,",
category: 'games',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/cau-hinh-may-tinh-nhu-nao-de-choi-duoc-bless-unleashed-game-nhap-vai-truc-tuyen-mien-phi-hot-nhat-steam',
component: 'page_20210803111127'},
{title: "One Punch Man: \"Thánh Phồng\" Saitama suýt chút nữa đã rất cô độc vì quá mạnh nếu không có người này?",
description: "Có một câu hỏi mà nhiều độc giả One Punch Man thắc mắc đó là nhân vật nào là người tin tưởng Saitama nhất?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/hi1-1627898258668285855636.jpg',
alt: "MANGA ONE PUNCH MAN,TÁC GIẢ ONE PUNCH MAN,MỸ NHÂN ONE PUNCH MAN,ANIME ONE PUNCH MAN,KING ONE PUNCH MAN,FAN ONE PUNCH MAN,CỘNG ĐỒNG ONE PUNCH MAN,THÁNH PHỒNG SAITAMA,HỌC TRÒ SAITAMA,THẦY TRÒ SAITAMA VS GENOS,FUBUKI VS BANG,",
category: 'games',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/one-punch-man-thanh-phong-saitama-suyt-chut-nua-da-rat-co-doc-vi-qua-manh-neu-khong-co-nguoi-nay',
component: 'page_20210803111112'},
{title: "7 địa điểm nổi tiếng nhất trong Thần thoại Hy Lạp mà bạn hoàn toàn có thể đặt chân đến",
description: "Ít ai biết rằng, có rất nhiều địa điểm trong thần thoại Hy Lạp được dựa trên những nơi có thật ngoài đời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/altar-of-zeus-at-mount-ida-ancient-anatolia-1627888102763439783445.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI HY LẠP,TRUYỀN THUYẾT,",
category: 'travel',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/7-dia-diem-noi-tieng-nhat-trong-than-thoai-hy-lap-ma-ban-hoan-toan-co-the-dat-chan-den',
component: 'page_20210803111103'},
{title: "Lộ \"bàn tay lạ\" ngay trên sóng khi diện trang phục hở hang, nữ YouTuber nóng bỏng khiến fan đặt nghi vấn",
description: "Cô nàng YouTuber đang khiến rất nhiều người băn khoăn và đặt ra các câu hỏi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/29/photo-1-16275492913581437760126.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,YOUTUBER,QINGER,NỮ YOUTUBER,",
category: 'images',
time: '11:10 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:10 AM',
link: '/lo-ban-tay-la-ngay-tren-song-khi-dien-trang-phuc-ho-hang-nu-youtuber-nong-bong-khien-fan-dat-nghi-van',
component: 'page_20210803111016'},
{title: "Top 7 nhân vật có bộ não thông minh nhất trong thế giới anime, Thám Tử Lừng Danh Conan chỉ xếp cuối cùng",
description: "Bảy nhân vật sau đây được cho là sở hữu những bộ não thông minh nhất trong thế giới anime.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/cu1-16278104439231846267790.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '11:10 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:10 AM',
link: '/top-7-nhan-vat-co-bo-nao-thong-minh-nhat-trong-the-gioi-anime-tham-tu-lung-danh-conan-chi-xep-cuoi-cung',
component: 'page_20210803111005'},
{title: "Nữ thần lai nhà YG Jeon Somi gây bão với visual siêu thực như búp bê Barbie, Jennie u mê đến mức spam bình luận khen hết lời",
description: "Nhan sắc, body của Jeon Somi đỉnh thế nào mà lại khiến Jennie (BLACKPINK) thành fangirl cuồng nhiệt như thế này?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/2/937a26868500482b9ea2a9269c04f50b-16279138982341883782145.jpg',
alt: "Jeon Somi,Jennie (BlackPink),Blackpink,nhan sắc sao Hàn,sao Hàn,Somi comeback,somi,The Black Label,YG Entertainment,nữ thần lai,Somi (The Black Label),body của sao,",
category: 'stars',
time: '11:09 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:09 AM',
link: '/nu-than-lai-nha-yg-jeon-somi-gay-bao-voi-visual-sieu-thuc-nhu-bup-be-barbie-jennie-u-me-den-muc-spam-binh-luan-khen-het-loi',
component: 'page_20210803110946'},
{title: "Tiên nữ bê đĩa đào là ai? Truyền thuyết gắn liền với chiếc đĩa sứ cô tiên nhà nào cũng có",
description: "Những chiếc đĩa sứ với hình cô tiên bê đĩa đào hẳn đã là một phần tuổi thơ của rất nhiều người. Nhưng nhân vật này là ai? Tại sao lại được in hình trên đĩa?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/3/photo-1-1627958822484112425535.jpg',
alt: "Người Trung Hoa,đời nhà thanh,",
category: 'news',
time: '11:09 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:09 AM',
link: '/tien-nu-be-dia-dao-la-ai-truyen-thuyet-gan-lien-voi-chiec-dia-su-co-tien-nha-nao-cung-co',
component: 'page_20210803110933'},
{title: "Khai thật đi! Bạn đã từng đập chuột, bàn phím vì các nhiệm vụ huyền thoại, khó đến mức “phát điên” này chưa?",
description: "Có lẽ trong series GTA thì đây xứng đáng là chuỗi nhiệm vụ khó bậc nhất khiến người chơi vô cùng ức chế.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-1627836247715331530341.jpg',
alt: "GTA,GTA VICE CITY,GTA SAN ANDREAS,TIN TỨC GAME,DEMOLITION MAN,",
category: 'games',
time: '01:22 AM',
date: '44235',
timestamp: '02/08/2021 01:22 AM',
link: '/khai-that-di-ban-da-tung-dap-chuot-ban-phim-vi-cac-nhiem-vu-huyen-thoai-kho-den-muc-phat-dien-nay-chua',
component: 'page_20210802012229'},
{title: "Đồng coin Axie Infinity rớt giá thảm hại, game thủ Việt náo loạn vì \"đu đỉnh\"",
description: "Game thủ Axie Infinity Việt Nam đang lo lắng vì giá tiền ảo của game liên tục giảm.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/2/3-16278792827231259436558-1627879302333166739249.png',
alt: "GAME THỦ VIỆT,GAME NFT,AXIE INFINITY,TIỀN ẢO,SLP,AXS,SLP COIN,",
category: 'games',
time: '01:22 AM',
date: '44235',
timestamp: '02/08/2021 01:22 AM',
link: '/dong-coin-axie-infinity-rot-gia-tham-hai-game-thu-viet-nao-loan-vi-du-dinh',
component: 'page_20210802012221'},
{title: "Thương hiệu thời trang Louis Vuitton chuẩn bị ra mắt game NFT điện thoại",
description: "Không chỉ lấn sân sang ngành game, thương hiệu thời trang của Pháp cũng sẽ sử dụng luôn cả NFT.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-16278756721141711018649.jpg',
alt: "THƯƠNG HIỆU THỜI TRANG,GAME NFT,NFT,TIỀN ẢO,BLOCKCHAIN,TRÒ CHƠI ĐIỆN TỬ,",
category: 'games',
time: '01:22 AM',
date: '44235',
timestamp: '02/08/2021 01:22 AM',
link: '/thuong-hieu-thoi-trang-louis-vuitton-chuan-bi-ra-mat-game-nft-dien-thoai',
component: 'page_20210802012212'},
{title: "BXH diễn viên 18+ ăn khách 6/2021: Yua Mikami \"đánh rơi\" ngôi đầu, Eimi Fukada vẫn chìm nghỉm?",
description: "Việc Yua Mikami bỏ lỡ vị trí top 1 trong tháng 7 vừa qua là điều khá bất ngờ với nhiều người.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/photo-1-16278770679801776928089.png',
alt: "IDOL,YUA MIKAMI,BẢNG XẾP HẠNG,SAIKA KAWAKITA,EIMI FUKADA,",
category: 'images',
time: '01:22 AM',
date: '44235',
timestamp: '02/08/2021 01:22 AM',
link: '/bxh-dien-vien-18-an-khach-6-2021-yua-mikami-danh-roi-ngoi-dau-eimi-fukada-van-chim-nghim',
component: 'page_20210802012203'},
{title: "Xinh quá mức, hot girl tân binh phim 18+ chưa debut đã được kỳ vọng sẽ chấm dứt \"kỷ nguyên\" của Yua Mikami",
description: "Cô nàng hot girl này đang là một trong những chủ đề nóng nhất ở ngành công nghiệp phim 18+ thời điểm hiện tại.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/28/screenshot26-1627475768995824801854-16274757850181367325443-16274758003152076189091.png',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,KAEDE,PHIM AV,PHIM 18+,AV,",
category: 'images',
time: '01:21 AM',
date: '44235',
timestamp: '02/08/2021 01:21 AM',
link: '/xinh-qua-muc-hot-girl-tan-binh-phim-18-chua-debut-da-duoc-ky-vong-se-cham-dut-ky-nguyen-cua-yua-mikami',
component: 'page_20210802012148'},
{title: "12 game NFT có thể kiếm tiền \"ngon\" như Axie Infinity (Phần 2)",
description: "Mời các bạn tiếp tục đến với series những tựa game NFT hot nhất thị trường.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-16278323277561900075355.jpg',
alt: "THẾ GIỚI ẢO,GAME NFT,CHƠI GAME KIẾM TIỀN,PLAY TO EARN,THẾ GIỚI MỞ,TIỀN ẢO,",
category: 'games',
time: '01:21 AM',
date: '44235',
timestamp: '02/08/2021 01:21 AM',
link: '/12-game-nft-co-the-kiem-tien-ngon-nhu-axie-infinity-phan-2',
component: 'page_20210802012138'},
{title: "Thả rông vòng một rồi bôi dầu bóng loáng khi lên sóng, nữ streamer khiến người xem sốc nặng khi cố tình hớ hênh \"điểm nhạy cảm\"",
description: "Số lượng án phạt cấm kênh mà cô nàng streamer này phải nhận chắc cũng chẳng thể đếm nổi nữa rồi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/2/photo-1-1627876511023130087944.jpg',
alt: "thả rông vòng một,điểm nhạy cảm,ứng cử viên,đồng nghiệp nam,phòng khách sạn,áo hai dây,Khoe thân phản cảm,streamer,",
category: 'images',
time: '01:21 AM',
date: '44235',
timestamp: '02/08/2021 01:21 AM',
link: '/tha-rong-vong-mot-roi-boi-dau-bong-loang-khi-len-song-nu-streamer-khien-nguoi-xem-soc-nang-khi-co-tinh-ho-henh-diem-nhay-cam',
component: 'page_20210802012128'},
{title: "Lọ Lem nhà MC Quyền Linh xinh thực sự, ở nhà mặc đồ bộ mà cũng có loạt ảnh \"tới công chuyện\"",
description: "Không xuất hiện trong những bộ váy nền nã, nữ tính thường thấy nhưng Lọ Lem vẫn khiến mọi người khen nức nở.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/2/anh-chup-man-hinh-2021-08-02-luc-73949-sa-16278650239571095112497.png',
alt: "Lọ Lem,Con gái MC Quyền Linh,MC Quyền Linh,mai thảo linh,gái xinh,Hello GenZ,Nhà Lên Đèn, Em Lên Đồ,gái xinh gen Z,",
category: 'stars',
time: '01:21 AM',
date: '44235',
timestamp: '02/08/2021 01:21 AM',
link: '/lo-lem-nha-mc-quyen-linh-xinh-thuc-su-o-nha-mac-do-bo-ma-cung-co-loat-anh-toi-cong-chuyen',
component: 'page_20210802012120'},
{title: "Đại chiến bikini phiên bản ở nhà mùa dịch: Sao Việt lẫn gái xinh vẫn diện toàn đồ sexy rõ chiến, chị em nom thèm ra biển lắm rồi",
description: "Vẫn là trào lưu lên đồ ở nhà nhưng cục diện đã thay đổi khi chị đại Thanh Hằng khiến đàn em thơ phần nào bị lu mờ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/2/2288412615766622333288115516340885772097766n-16278676557721976941634.jpg',
alt: "show your best contest,show your best,estee lauder,nghỉ dịch,Gái đẹp đại chiến bikini,đồ bơi,thanh hằng,Kiều Ly Phạm,vợ Rhymastic,star style,Nhà Lên Đèn, Em Lên Đồ,",
category: 'images',
time: '01:21 AM',
date: '44235',
timestamp: '02/08/2021 01:21 AM',
link: '/dai-chien-bikini-phien-ban-o-nha-mua-dich-sao-viet-lan-gai-xinh-van-dien-toan-do-sexy-ro-chien-chi-em-nom-them-ra-bien-lam-roi',
component: 'page_20210802012106'},
{title: "Mỹ nhân 18+ hé lộ mắc bệnh nghiêm trọng, than ngắn thở dài bỏ ngỏ việc giải nghệ",
description: "Aoi Tsubasa đã tâm sự về chuyện cô nàng mắc bệnh dạ dày, nhưng cuối cùng lại phát hiện thêm bệnh khác và phải trì hoãn việc đóng phim.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/1/photo-1-1627792620526175642758.jpg',
alt: "AOI TSUBASA,IDOL,",
category: 'images',
time: '01:20 AM',
date: '44235',
timestamp: '02/08/2021 01:20 AM',
link: '/my-nhan-18-he-lo-mac-benh-nghiem-trong-than-ngan-tho-dai-bo-ngo-viec-giai-nghe',
component: 'page_20210802012044'},
{title: "One Piece: Biến thành động vật và 3 đặc điểm của trái ác quỷ hệ Zoan khác hẳn những hệ khác",
description: "Người sở hữu năng lực trái ác quỷ hệ Zoan có khả năng biến thành một loài động vật, kèm theo đó là các đặc điểm cũng như khả năng của loài vật đó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-1627800104680345133960.jpg',
alt: "ONE PIECE,TRÁI ÁC QUỶ,TRÁI ZOAN,ZOAN CỔ ĐẠI,LOẠI TRÁI ÁC QUỶ,",
category: 'games',
time: '01:19 AM',
date: '44235',
timestamp: '02/08/2021 01:19 AM',
link: '/one-piece-bien-thanh-dong-vat-va-3-dac-diem-cua-trai-ac-quy-he-zoan-khac-han-nhung-he-khac',
component: 'page_20210802011936'},
{title: "8 mẹo đơn giản để tăng tín hiệu sóng Wi-fi lên mức tối đa",
description: "Trong những ngày giãn cách xã hội, mạng Wi-fi là một trong những yếu tố quan trọng để học tập, làm việc và giải trí.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/1/photo-1-1627820158564578927919.jpg',
alt: "YẾU TỐ QUAN TRỌNG,TĂNG TỐC MẠNG WI-FI,WI-FI,SÓNG WI-FI,TĂNG TỐC MẠNG,TĂNG TỐC WI-FI,TÍN HIỆU WI-FI,",
category: 'tech',
time: '01:19 AM',
date: '44235',
timestamp: '02/08/2021 01:19 AM',
link: '/8-meo-don-gian-de-tang-tin-hieu-song-wi-fi-len-muc-toi-da',
component: 'page_20210802011917'},
{title: "Top 10 Rồng thần \"ngầu\" nhất Dragon Ball: Con bay trên trời, con đi dưới đất, con dùng hẳn máy thời gian để \"săn lùng\" (P1)",
description: "Dragon Ball xuất hiện vô số phiên bản Rồng thần, mỗi con đều có những hình dạng và sức mạnh đặc biệt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/anh-1-16278005780421511752096.jpg',
alt: "DRAGON BALL,7 VIÊN NGỌC RỒNG,RỒNG THẦN,SERIES DRAGON BALL,",
category: 'games',
time: '01:19 AM',
date: '44235',
timestamp: '02/08/2021 01:19 AM',
link: '/top-10-rong-than-ngau-nhat-dragon-ball-con-bay-tren-troi-con-di-duoi-dat-con-dung-han-may-thoi-gian-de-san-lung-p1',
component: 'page_20210802011906'},
{title: "Cày phim ngày dịch, top 5 anime người máy - mecha siêu hấp dẫn sau đây sẽ kiến bạn hài lòng",
description: "Cùng chúng tôi đến với 5 anime người máy - mecha cực đỉnh giúp bạn giải trí trong những ngày ở nhà chống dịch này nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/any1-16277975881851921103740.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,GAME ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/cay-phim-ngay-dich-top-5-anime-nguoi-may-mecha-sieu-hap-dan-sau-day-se-kien-ban-hai-long',
component: 'page_20210802011854'},
{title: "Giật mình khi thấy các mỹ nhân anime bất ngờ đi độ ngực, ai cũng có tâm hồn ngồn ngộn nhức mắt",
description: "Dù phụ nữ có da trắng đến cỡ nào, da mặt đẹp và dáng chuẩn đến bao nhiêu thì trong mắt đàn ông, vòng 1 vẫn là điểm họ dừng mắt đầu tiên và lâu nhất.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-16277383453661718633477.jpg',
alt: "ANIME,MỸ NHÂN ANIME,VÒNG 1,TÂM HỒN PHỤ NỮ,MỸ NHÂN NGỰC KHỦNG,FAN ART,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/giat-minh-khi-thay-cac-my-nhan-anime-bat-ngo-di-do-nguc-ai-cung-co-tam-hon-ngon-ngon-nhuc-mat',
component: 'page_20210802011846'},
{title: "Mặc áo trễ nải rồi làm \"rơi\" tâm hồn trên sóng, nữ streamer vô danh bỗng chốc hóa tân binh tiềm năng, được donate nhiệt liệt",
description: "Một hành động tưởng như vô tình, nhưng lại khiến cô nàng streamer trở nên nổi tiếng một cách khó lường.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/27/photo-1-1627364087502275971880.gif',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/mac-ao-tre-nai-roi-lam-roi-tam-hon-tren-song-nu-streamer-vo-danh-bong-choc-hoa-tan-binh-tiem-nang-duoc-donate-nhiet-liet',
component: 'page_20210802011826'},
{title: "Những điều ngớ ngẩn game thủ thường gặp trong open world game",
description: "Dù open world game được xây dựng với mục tiêu mô phỏng lại thế giới thực càng giống càng tốt, nhưng vẫn có những chi tiết cực kỳ ngớ ngẩn tồn tại trong phần lớn các tựa game.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/29/-16275505649941752679599.jpg',
alt: "KHÁM PHÁ,GAME OPEN WORLD,GAME ONLINE,TRÒ CHƠI ĐIỆN TỬ,GIẢI TRÍ,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/nhung-dieu-ngo-ngan-game-thu-thuong-gap-trong-open-world-game',
component: 'page_20210802011814'},
{title: "Top 10 tựa game PC những năm 2000 với đồ họa đỉnh cao làm game thủ mãi nhớ (P.2)",
description: "Những ấn tượng không thể nào quên với các anh em game thủ 8x 9x một thời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/1/photo-1-1627755900498830370556.png',
alt: "GAME,GAME HAY,GAME ĐỈNH,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,ĐỒ HỌA GAME,",
category: 'games',
time: '01:18 AM',
date: '44235',
timestamp: '02/08/2021 01:18 AM',
link: '/top-10-tua-game-pc-nhung-nam-2000-voi-do-hoa-dinh-cao-lam-game-thu-mai-nho-p2',
component: 'page_20210802011803'},
{title: "12 game NFT có thể kiếm tiền \"ngon\" như Axie Infinity (Phần 1)",
description: "Game NFT đã khởi đầu cho xu hướng chơi game kiếm tiền đang thịnh hành trên thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-16277432005911663617292.jpg',
alt: "AXIE INFINITY,GAME NFT,CHƠI GAME KIẾM TIỀN,PLAY TO EARN,TIỀN ẢO,AXS,SLP,",
category: 'games',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/12-game-nft-co-the-kiem-tien-ngon-nhu-axie-infinity-phan-1',
component: 'page_20210802011748'},
{title: "Tại sao các huấn luyện viên chỉ dùng 6 Pokémon mà thôi?",
description: "Con số 6 được coi là chuẩn hóa với các huấn luyện viên Pokémon kể từ đầu khi mới khai sinh ra game cho tới thời điểm hiện tại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/31/photo-1-16277492905702081276162.png',
alt: "POKÉMON,",
category: 'games',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/tai-sao-cac-huan-luyen-vien-chi-dung-6-pokemon-ma-thoi',
component: 'page_20210802011740'},
{title: "Triệu Lệ Dĩnh thật sự \"nặng nợ\" với giày dép!",
description: "Vốn có tạng người nhỏ bé, đặc biệt là đôi chân nhỏ nên Triệu Lệ Dĩnh cũng gặp nhiều khó khăn khi chọn giày dép.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/1/photo-1-16272214927271347365146-1627272206890982067480-16278016114931281461667.jpeg',
alt: "triệu lệ dĩnh,sao Trung,sao Hoa ngữ,star style,giày cao gót,boot,sao mặc gì,giày rộng,",
category: 'stars',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/trieu-le-dinh-that-su-nang-no-voi-giay-dep',
component: 'page_20210802011723'},
{title: "Ngay trong đêm Khang Hy băng hà, Ung Chính lập tức xử tử thân tín đã theo tiên đế suốt 60 năm, ông đến cùng đã đắc tội với ai?",
description: "Lý do thực sự khiến Ung Chính xử tử Triệu Xương - một thái giám cận kề hơn 60 năm bên Khang Hy đế ngay trong đêm ông băng hà là gì?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/26/-16272921001512030615251.jpg',
alt: "nhà Thanh Trung Quốc,Uông Chính,khang hy,",
category: 'news',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/ngay-trong-dem-khang-hy-bang-ha-ung-chinh-lap-tuc-xu-tu-than-tin-da-theo-tien-de-suot-60-nam-ong-den-cung-da-dac-toi-voi-ai',
component: 'page_20210802011714'},
{title: "Mỹ nhân Hoa ngữ \"cưa sừng làm nghé\" hóa nữ sinh: Nhiệt Ba - Trịnh Sảng trẻ trung ngỡ ngàng, \"trùm cuối\" là cao thủ hack tuổi",
description: "Nhiều mỹ nhân Hoa ngữ khiến khán giả ngỡ ngàng bởi vẻ trẻ trung và trong sáng khi vào vai nữ sinh.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/27/33-1627371707185415933730.jpg',
alt: "Địch Lệ Nhiệt Ba,Dương Mịch,Lưu Thi Thi,Lưu Diệc Phi,Trịnh Sảng,Trần Nghiên Hy,đàm tùng vận,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/my-nhan-hoa-ngu-cua-sung-lam-nghe-hoa-nu-sinh-nhiet-ba-trinh-sang-tre-trung-ngo-ngang-trum-cuoi-la-cao-thu-hack-tuoi',
component: 'page_20210802011700'},
{title: "Đặt lên bàn cân hai mỹ nhân \"tình tin đồn\" của Bụt: Mai Dora và Lê Thị Khánh Huyền, ai hơn ai?",
description: "\"Mai Dora và Lê Thị Khánh Huyền cùng đặt lên bàn cân, ai hơn ai?\" đang là câu hỏi được rất nhiều fan thắc mắc.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/m2-16277355526021552858183.jpg',
alt: "người yêu tin đồn,làng giải trí,bạn trai cũ,người yêu mới,chuyện tình cổ tích,bạn gái mới,cộng đồng mạng,Mai Dora,streamer,nữ streamer,TikToker,lê thị khánh huyền,",
category: 'images',
time: '02:36 PM',
date: '31/07/2021',
timestamp: '31/07/2021 02:36 PM',
link: '/dat-len-ban-can-hai-my-nhan-tinh-tin-don-cua-but-mai-dora-va-le-thi-khanh-huyen-ai-hon-ai',
component: 'page_20210731143657'},
{title: "Top 10 tựa game PC những năm 2000 với đồ họa đỉnh cao làm game thủ mãi nhớ",
description: "Những ấn tượng không thể nào quên với các anh em game thủ 8x 9x một thời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-1627718538346379055070.jpg',
alt: "GAME,GAME HAY,GAME ĐỈNH,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'games',
time: '01:20 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:20 PM',
link: '/top-10-tua-game-pc-nhung-nam-2000-voi-do-hoa-dinh-cao-lam-game-thu-mai-nho',
component: 'page_20210731132037'},
{title: "Giải phẫu cơ thể của Thần hủy diệt Beerus: Mèo mà không có lông và những bí mật kỳ lạ gây sốc khác",
description: "Trong Dragon Ball Super, Beerus là Thần hủy diệt của Vũ trụ 7. Ông là người vô cùng mạnh mẽ, sở hữu sức mạnh hủy diệt đáng sợ khiến ai cũng phải dè chừng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/31/anh-1-16277170034351938628634.png',
alt: "DRAGON BALL SUPER,THẦN HỦY DIỆT,VŨ TRỤ 7,BEERUS,SỨC MẠNH HỦY DIỆT,THIÊN SỨ WHIS,",
category: 'games',
time: '01:20 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:20 PM',
link: '/giai-phau-co-the-cua-than-huy-diet-beerus-meo-ma-khong-co-long-va-nhung-bi-mat-ky-la-gay-soc-khac',
component: 'page_20210731132029'},
{title: "Đi mua bút vẽ cũng bị gọi là họa sĩ, nữ YouTuber siêu vòng 1 than thở: \"Tôi làm chỉ vì đam mê\"",
description: "Nữ YouTuber này khẳng định rằng mình chẳng phài là họa sĩ chuyên nghiệp như nhiều người lầm tưởng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/31/gai-16277119747911360430274-1627712175729158353346.jpg',
alt: "GÁI XINH,HOT GIRL,YOUTUBER,NỮ YOUTUBER,",
category: 'images',
time: '01:20 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:20 PM',
link: '/di-mua-but-ve-cung-bi-goi-la-hoa-si-nu-youtuber-sieu-vong-1-than-tho-toi-lam-chi-vi-dam-me',
component: 'page_20210731132015'},
{title: "Pikachu và những người bạn thân nhất của Satoshi trong các series Pokémon",
description: "Kể từ khi ra mắt, loạt Pokémon đã đưa người xem đắm chìm vào những cuộc phiêu lưu kỳ thú của nhân vật chính Satoshi trong thế giới rộng lớn. Và trong cuộc hành trình ấy chắc hẳn là sẽ không thể thiếu những người bạn thân thiết, cùng nhau vượt qua mọi gian nan, khó khăn.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/31/photo-1-16277092247282014576557.jpg',
alt: "PIKACHU,SATOSHI,POKÉMON,BẠN THÂN CỦA SATOSHI,",
category: 'games',
time: '01:19 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:19 PM',
link: '/pikachu-va-nhung-nguoi-ban-than-nhat-cua-satoshi-trong-cac-series-pokemon',
component: 'page_20210731131958'},
{title: "Người đẹp 18+ kể chuyện lên hình, tiết lộ gu bạn trai yêu thích là đàn ông trung niên",
description: "Rio Kuriyama đã chia sẻ về thú vui và gu bạn trai yêu thích của cô nàng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/30/photo-1-1627660637278307115892.jpg',
alt: "IDOL,RIO KURIYAMA,",
category: 'images',
time: '01:19 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:19 PM',
link: '/nguoi-dep-18-ke-chuyen-len-hinh-tiet-lo-gu-ban-trai-yeu-thich-la-dan-ong-trung-nien',
component: 'page_20210731131937'},
{title: "Khi quốc kỳ Việt Nam \"hóa người\", profile mê phở và giỏi xem bói khiến netizen thích thú",
description: "Nhìn thông tin của \"anh chàng Việt Nam\" netizen không khỏi cảm thán \"chất quá!\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-1627707697606365323420.jpg',
alt: "QUỐC KỲ VIỆT NAM,TOKYO OLYMPIC 2020,WORLD FLAGS,QUỐC KỲ VIỆT NAM BIẾN THÀNH NGƯỜI,",
category: 'news',
time: '01:19 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:19 PM',
link: '/khi-quoc-ky-viet-nam-hoa-nguoi-profile-me-pho-va-gioi-xem-boi-khien-netizen-thich-thu',
component: 'page_20210731131929'},
{title: "Hot girl phim 18+ khiến fan ngỡ ngàng khi ví von công việc như trò chơi xếp hình, kêu gọi đồng nghiệp nên nhớ \"việc chính\" thay vì tập trung làm YouTube",
description: "Chia sẻ của cô nàng hot girl khiến cho không ít người phải bật cười vì sự hài hước.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/28/photo-1-1627449356189352428462.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,EIMI FUKADA,AV,PHIM AV,PHIM 18+,",
category: 'images',
time: '01:19 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:19 PM',
link: '/hot-girl-phim-18-khien-fan-ngo-ngang-khi-vi-von-cong-viec-nhu-tro-choi-xep-hinh-keu-goi-dong-nghiep-nen-nho-viec-chinh-thay-vi-tap-trung-lam-youtube',
component: 'page_20210731131920'},
]
export const dataContent09=[{title: "Hot girl Lê Phương Anh đang hot rần rần trên mạng là ai?",
description: "Cái tên Lê Phương Anh đang khiến không ít người chú ý.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/1742548851284859431909811985173040770867015n-16277027190401799624308.jpg',
alt: "Lê Phương Anh,gái xinh,hot girl,Cư dân mạng,cộng đồng mạng,",
category: 'images',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/hot-girl-le-phuong-anh-dang-hot-ran-ran-tren-mang-la-ai',
component: 'page_20210731131843'},
{title: "Hội nữ phụ cổ trang \"một phát lên luôn\" vì quá đẹp: Nhiệt Ba - Lộ Tư chạm mặt cực gắt, số 4 \"ghi bàn\" chỉ với vài phút lên sóng!",
description: "Một số mỹ nhân như Địch Lệ Nhiệt Ba, Triệu Lộ Tư,... từng ghi điểm khi thủ vai nữ phụ trong các phim cổ trang đình đám.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/29/-16275527395871012338823.jpg',
alt: "Như Ý Truyện,Tam Sinh Tam Thế: Thập Lý Đào Hoa,Trường Ca Hành,Địch Lệ Nhiệt Ba,Triệu Lộ Tư,Lý Niệm,Lưu Đào,vương viện khả,Tân Chỉ Lôi,Dương Quý Phi Bí Sử,Mị Nguyệt Truyện,Thiên long bát bộ,Diên Hi Công Lược,phim Hoa ngữ,phim trung quốc,Phim truyền hình,phim cổ trang,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/hoi-nu-phu-co-trang-mot-phat-len-luon-vi-qua-dep-nhiet-ba-lo-tu-cham-mat-cuc-gat-so-4-ghi-ban-chi-voi-vai-phut-len-song',
component: 'page_20210731131834'},
{title: "Địch Lệ Nhiệt Ba lên xe hoa trong phim đến tận 6 lần, là \"cô dâu\" của loạt mỹ nam đình đám nhất xứ Trung",
description: "Địch Lệ Nhiệt Ba từng làm đám cưới với Cao Vỹ Quang, Đặng Luân, Hoàng Cảnh Du và sắp tới là Dương Dương.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/25/2208188983412207008871718384330042314919955n-1626843797190593941880-1627205190130937966228.jpeg',
alt: "Phim truyền hình,phim trung quốc,phim truyền hình trung quốc,Em Là Niềm Kiêu Hãnh Của Anh,Địch Lệ Nhiệt Ba,Dương Dương,hoàng cảnh du,Đặng Luân,Cao Vỹ Quang,Hạnh Phúc Trong Tầm Tay (2020),Tam Sinh Tam Thế Thập Lý Đào Hoa,Tam Sinh Tam Thế Chẩm Thượng Thư,Hạnh Phúc Trong Tầm Tay,Em Là Niềm Kiêu Hãnh Của Anh (2021),",
category: 'stars',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/dich-le-nhiet-ba-len-xe-hoa-trong-phim-den-tan-6-lan-la-co-dau-cua-loat-my-nam-dinh-dam-nhat-xu-trung',
component: 'page_20210731131823'},
{title: "Một ngày của hoàng đế xưa kia trải qua như thế nào? Càn Long dậy lúc 3h sáng, 7h tối lật thẻ bài, mỗi ngày lặp lại vô vị",
description: "Cuộc sống của hoàng đế có thực sự vui vẻ, cả ngày chỉ ăn chơi hưởng lạc như chúng ta vẫn nghĩ?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/29/-16275485979551090830879.jpg',
alt: "Trung Quốc cổ đại,Càn Long,",
category: 'news',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/mot-ngay-cua-hoang-de-xua-kia-trai-qua-nhu-the-nao-can-long-day-luc-3h-sang-7h-toi-lat-the-bai-moi-ngay-lap-lai-vo-vi',
component: 'page_20210731131813'},
{title: "Điểm lại 10 trend khai nguồn từ idol Hàn, đâu mới là xu hướng được lòng netizen nhất?",
description: "Toàn là các trend dễ học, dễ làm như này, bảo sao netizen nhà mình cứ tha hồ thử nghiệm mãi thôi.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/screen-shot-2019-01-19-at-42224-pm-15478903271781963938842-16277037921091345497149.png',
alt: "Makeup,star style,tóc của sao,hot trend,idol Hàn,idol Kpop,hot trends 2021,",
category: 'stars',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/diem-lai-10-trend-khai-nguon-tu-idol-han-dau-moi-la-xu-huong-duoc-long-netizen-nhat',
component: 'page_20210731131802'},
{title: "Chết chìm với bể visual bùng nổ của \"cảnh sát\" Krystal ở phim mới, netizen đùa \"bắt em đi chị ơi\"",
description: "Đại Học Cảnh Sát vừa chiêu đãi khán giả bằng loạt hình ảnh mới đẹp lịm người của Krystal và bạn diễn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/2237122058783872030332062401946223716323604n-16277120661511418042355.jpg',
alt: "Phim Hàn Quốc,Krystal,jinyoung (got7),Phim truyền hình,Police University (2021),Đại Học Cảnh Sát (2021),",
category: 'stars',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/chet-chim-voi-be-visual-bung-no-cua-canh-sat-krystal-o-phim-moi-netizen-dua-bat-em-di-chi-oi',
component: 'page_20210731131749'},
{title: "Lên đồ cảnh sát mà vẫn sang chảnh, thần thái ngời ngợi thì chỉ có Krystal, bảo sao netizen khen đến là mỏi miệng",
description: "Tạo hình mới của Krystal đang nhận được rất nhiều lời khen ngợi từ người hâm mộ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/2173667428783877763664825335057837963301280n-1627727822602427564995.jpg',
alt: "Krystal,sao Hàn,Kbiz,star style,Police University (2021),tóc của sao,",
category: 'stars',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/len-do-canh-sat-ma-van-sang-chanh-than-thai-ngoi-ngoi-thi-chi-co-krystal-bao-sao-netizen-khen-den-la-moi-mieng',
component: 'page_20210731131736'},
{title: "“Cuộc chiến” gây tranh cãi nhất cõi mạng hiện nay: Phở hay cơm tấm mới xứng đáng là món ăn đại diện Việt Nam?",
description: "Xuất phát từ một bài đăng phản biện trong nhóm Facebook, chủ đề này hiện đang gây tranh cãi lớn trên MXH.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/ct1-1627745375211263119053.jpeg',
alt: "cuộc chiến ẩm thực,phở,quốc hồn quốc tuý,cơm tấm,sườn nướng,món ăn đại diện Việt Nam,",
category: 'news',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/cuoc-chien-gay-tranh-cai-nhat-coi-mang-hien-nay-pho-hay-com-tam-moi-xung-dang-la-mon-an-dai-dien-viet-nam',
component: 'page_20210731131727'},
{title: "Chỉ bằng 1 lời nói chân thành, Ronaldo đã thay đổi số phận của cậu bé Nhật Bản từng bị đám đông chế giễu: Đẳng cấp thực thụ của một ngôi sao lớn!",
description: "Được đích thân Cristiano Ronaldo bảo vệ và khuyên nhủ, cậu bé 12 tuổi từng bị đám đông chế giễu năm nào giờ đã trở thành nhà vô địch bóng đá quốc gia ở cấp độ trung học.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/31/photo-1-16277387120352048380142.jpg',
alt: "ronaldo,Nhật Bản,",
category: 'news',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/chi-bang-1-loi-noi-chan-thanh-ronaldo-da-thay-doi-so-phan-cua-cau-be-nhat-ban-tung-bi-dam-dong-che-gieu-dang-cap-thuc-thu-cua-mot-ngoi-sao-lon',
component: 'page_20210731131713'},
{title: "Quấn mỗi khăn tắm lên sóng, nữ streamer gây sốc với chuỗi động tác gợi cảm khiến người xem không cầm được \"máu mũi\"",
description: "Nhiều người cho rằng những hành động này của cô nàng streamer đôi khi còn chạm tới ranh giới của sự phản cảm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/31/photo-8-16277071686801105238482.jpg',
alt: "nhân vật chính,đáng chú ý,chảy máu mũi,streamer,nữ streamer,game,fan,cao thủ,",
category: 'images',
time: '01:16 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:16 PM',
link: '/quan-moi-khan-tam-len-song-nu-streamer-gay-soc-voi-chuoi-dong-tac-goi-cam-khien-nguoi-xem-khong-cam-duoc-mau-mui',
component: 'page_20210731131649'},
{title: "Những sinh vật kỳ bí, quỷ quyết và đầy mê hoặc trong thần thoại Bắc Âu",
description: "Trong thần thoại Bắc Âu không chỉ có các vị thần và người khổng lồ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/30/to-make-my-small-elves-coats-1627635135792898967258.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,THẦN THOẠI BẮC ÂU,",
category: 'news',
time: '12:19 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:19 PM',
link: '/nhung-sinh-vat-ky-bi-quy-quyet-va-day-me-hoac-trong-than-thoai-bac-au',
component: 'page_20210730121944'},
{title: "“Nước đến bàn phím vẫn không nhảy\", game thủ mặc kệ dòng lũ, xắn quần ngồi đánh quái",
description: "Những hình ảnh game thủ Philippines ngồi “cày” game mặc cho dòng nước lũ dâng lên với tốc độ nhanh chóng đang thu hút sự chú ý của cộng đồng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/29/photo-1-16275517364501564578952.jpg',
alt: "GAME THỦ,CÀY GAME,PHILIPPINES,",
category: 'games',
time: '12:19 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:19 PM',
link: '/nuoc-den-ban-phim-van-khong-nhay-game-thu-mac-ke-dong-lu-xan-quan-ngoi-danh-quai',
component: 'page_20210730121934'},
{title: "Loạt mỹ nhân 18+ trẻ tuổi được thiên thần Yua Mikami hết lời khen ngợi",
description: "Những cô gái này đều là các đàn em được Yua Mikami yêu thích và cũng được đánh giá cao bởi công chúng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/29/-162755398443429398537.jpg',
alt: "RIKKA ONO,IDOL,YUA MIKAMI,NOZOMI ISHIHARA,",
category: 'images',
time: '12:19 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:19 PM',
link: '/loat-my-nhan-18-tre-tuoi-duoc-thien-than-yua-mikami-het-loi-khen-ngoi',
component: 'page_20210730121913'},
{title: "Axie Infinity coin giảm giá chóng mặt, game thủ Việt hoang mang lo lắng",
description: "Axie Infinity coin đã bốc hơi 21% giá trị trong 2 ngày qua.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/29/untitled-1627574937832795816169.png',
alt: "AXIE INFINITY,AXS,AXS COIN,GAME THỦ VIỆT,GIỚI CÔNG NGHỆ,CÔNG NGHỆ VIỆT NAM,TIỀN ẢO,CRYPTO,",
category: 'news',
time: '12:19 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:19 PM',
link: '/axie-infinity-coin-giam-gia-chong-mat-game-thu-viet-hoang-mang-lo-lang',
component: 'page_20210730121904'},
{title: "Top game cấu hình nhẹ nhưng lại sở hữu đồ họa đỉnh cao",
description: "Game có đồ họa đẹp không thiếu nhưng game vừa đẹp vừa nhẹ thì lại không nhiều.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/photo-1-1627581743584424295397.png',
alt: "CỘNG ĐỒNG MẠNG,GAME HAY,GAME ĐỈNH,TIN TỨC GAME,ĐỒ HỌA ĐẸP,MÁY YẾU,CẤU HÌNH THẤP,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/top-game-cau-hinh-nhe-nhung-lai-so-huu-do-hoa-dinh-cao',
component: 'page_20210730121855'},
{title: "Top game cấu hình nhẹ nhưng lại sở hữu đồ họa đỉnh cao (P.2)",
description: "Game có đồ họa đẹp không thiếu nhưng game vừa đẹp vừa nhẹ thì lại không nhiều.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/photo-1-16276148883661382259834.jpg',
alt: "GAME HAY,GAME NHẸ,TIN TỨC GAME,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/top-game-cau-hinh-nhe-nhung-lai-so-huu-do-hoa-dinh-cao-p2',
component: 'page_20210730121846'},
{title: "4 xung đột giữa các diễn viên của Fast & Furious khiến người dọa bỏ vai kẻ thì bye bye thật",
description: "Mặc dù chủ đề chính của Fast & Furious là gia đình, nhưng điều đó không có nghĩa mọi người trong tập thể này lúc nào cũng gắn bó với nhau, dù là trong phim hay ngoài đời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/29/photo-1-16275643825141972207601.jpg',
alt: "FAST & FURIOUS,SERIES FAST,THE FAST AND THE FURIOUS,THE FAST SAGA,VIN DIESEL,THE ROCK,TYRESE GIBSON,",
category: 'news',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/4-xung-dot-giua-cac-dien-vien-cua-fast-furious-khien-nguoi-doa-bo-vai-ke-thi-bye-bye-that',
component: 'page_20210730121837'},
{title: "Hòa mình cùng Thế vận hội 2020 với những bộ anime thể thao nổi tiếng",
description: "Thế vận hội Tokyo 2020 cuối cùng cũng đang diễn ra tại Nhật Bản và khán giả có thể cùng học cùng chơi với một số anime thể thao sau.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/photo-1-162758385936526249098.jpg',
alt: "ANIME,OLYMPIC,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/hoa-minh-cung-the-van-hoi-2020-voi-nhung-bo-anime-the-thao-noi-tieng',
component: 'page_20210730121828'},
{title: "Tải miễn phí bom tấn siêu anh hùng Marvel Avengers trên Steam",
description: "Tải Marvel Avengers và chiến thôi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/30/photo-1-1627620857366227458726.jpg',
alt: "SIÊU ANH HÙNG,GAME PHIÊU LƯU,MARVEL’S AVENGERS,GAME MIỄN PHÍ,GAME SIÊU ANH HÙNG,",
category: 'games',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/tai-mien-phi-bom-tan-sieu-anh-hung-marvels-avengers-tren-steam',
component: 'page_20210730121818'},
{title: "Sau “cô vợ quốc dân\" Tiểu Hý, thêm một nàng hot TikToker Quảng Ninh được CĐM ráo riết “soi info\"",
description: "Mới đây, một hot TikToker sinh năm 2004, quê ở Quảng Ninh bất ngờ được tìm kiếm nhiều trên các diễn đàn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/photo-1-16276393073501722962601.jpg',
alt: "TIKTOKER,STREAMER,LỘ CLIP,NHẠY CẢM,DUY NHỎ,CỘNG ĐỒNG MẠNG,LINH NGỌC ĐÀM,",
category: 'images',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/sau-co-vo-quoc-dan-tieu-hy-them-mot-nang-hot-tiktoker-quang-ninh-duoc-cdm-rao-riet-soi-info',
component: 'page_20210730121752'},
{title: "5 anime có cái kết đẹp nhất do cư dân mạng Nhật Bản bình chọn: Kimetsu no Yaiba cũng có chân",
description: "Theo một khảo sát dành cho netizen Nhật, đây là những bộ anime shonen mà theo họ là có cái kết đẹp nhất.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/inuyashaanime11715041280x0-372f9970-162763048698727674113.jpeg',
alt: "ANIME,",
category: 'games',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/5-anime-co-cai-ket-dep-nhat-do-cu-dan-mang-nhat-ban-binh-chon-kimetsu-no-yaiba-cung-co-chan',
component: 'page_20210730121742'},
{title: "Dragon Ball Super: Bản chất sức mạnh hủy diệt của Hakai, thứ có thể giúp Vegeta đánh bại \"Kẻ sống sót\" Granolah",
description: "Hakai là sức mạnh của các vị Thần Hủy Diệt và dường như đã được Vegeta sử dụng trong chap mới đây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/30/anh-1-1627628436774114725314.jpg',
alt: "DRAGON BALL SUPER,VEGETA,HOÀNG TỬ SAIYAN,THẦN HỦY DIỆT,HAKAI,SỨC MẠNH HỦY DIỆT,GRANOLAH,CHIẾN BINH MẠNH NHẤT VŨ TRỤ,",
category: 'games',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/dragon-ball-super-ban-chat-suc-manh-huy-diet-cua-hakai-thu-co-the-giup-vegeta-danh-bai-ke-song-sot-granolah',
component: 'page_20210730121731'},
{title: "Tiểu mỹ nhân 18+ chia sẻ chuyện lần đầu lên hình, hé lộ về những bộ đồ \"cả đời không dám mặc lại\"",
description: "Miu Shiromine quyết định chọn ngành công nghiệp 18+, bởi nó thỏa mãn điều kiện là \"nổi tiếng\" và \"ít thị phi\".",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/30/photo-1-1627645123243931552153.jpg',
alt: "MIU SHIROMINE,IDOL,",
category: 'images',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/tieu-my-nhan-18-chia-se-chuyen-lan-dau-len-hinh-he-lo-ve-nhung-bo-do-ca-doi-khong-dam-mac-lai',
component: 'page_20210730121718'},
{title: "Những khung cảnh quen thuộc của game thủ Việt thời còn chưa có Internet, nhìn là thấy cả bầu trời kỷ niệm (p1)",
description: "Những hình ảnh đáng quý gợi về không biết bao nhiêu cảm xúc cho thế hệ các game thủ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/26/photo-1-1627302216562611661450.jpg',
alt: "TIN TỨC GAME,GAME THỦ,GAME,",
category: 'games',
time: '12:17 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:17 PM',
link: '/nhung-khung-canh-quen-thuoc-cua-game-thu-viet-thoi-con-chua-co-internet-nhin-la-thay-ca-bau-troi-ky-niem-p1',
component: 'page_20210730121708'},
{title: "Ngắm nhan sắc hot TikToker \"người yêu tin đồn\" của Bụt, mặt cực xinh và body cực \"mlem mlem\"",
description: "\"Hot girl 2k4\" Lê Thị Khánh Huyền đang được cộng đồng mạng quan tâm bởi tin đồn tình cảm với chàng Bụt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/30/nghi-van-hot-tiktoker-le-thi-khanh-huyen-la-co-gai-trong-story-cua-tinh-cu-linh-ngoc-dam-1627483775-1-16276479825931298548263.jpg',
alt: "lê thị khánh huyền,Khánh Huyền tiktoker,Chàng Bụt,người yêu cũ Linh Ngọc Đàm,bụt,Bụt có người yêu mới,game thủ,streamer,TikToker,",
category: 'images',
time: '12:16 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:16 PM',
link: '/ngam-nhan-sac-hot-tiktoker-nguoi-yeu-tin-don-cua-but-mat-cuc-xinh-va-body-cuc-mlem-mlem',
component: 'page_20210730121652'},
{title: "Ngất ngây ảnh nữ thần ngực khủng Anne Hathaway hóa công chúa: Nhan sắc chuẩn báu vật Hollywood, khí chất vương giả khó ai bì",
description: "2 thập kỷ đã trôi qua nhưng nhan sắc của Anne Hathaway trong bộ phim Nhật Ký Công Chúa vẫn khiến khán giả phải trầm trồ và mê mẩn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/30/photo-1-16276241792571824908501.jpg',
alt: "anne hathaway,sao us uk,sao hollywood,Nhật Ký Công Chúa,mỹ nhân hollywood,nhan sắc mỹ nhân Hollywood,mỹ nhân,Nhật Ký Công Chúa (2001),The Princess Diaries,The Princess Diaries (2001),",
category: 'stars',
time: '12:16 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:16 PM',
link: '/ngat-ngay-anh-nu-than-nguc-khung-anne-hathaway-hoa-cong-chua-nhan-sac-chuan-bau-vat-hollywood-khi-chat-vuong-gia-kho-ai-bi',
component: 'page_20210730121641'},
{title: "Vợ chồng trẻ mua căn hộ 76m2, chọn màu bê tông sắc lạnh nhìn \"chất\" như phim",
description: "Ở khu vực phòng ngủ của căn hộ sử dụng mảng tường kính liên thông với WC thông qua 1 hệ rèm chống nước.",
src: 'https://kenh14cdn.com/thumb_w/500/203336854389633024/2021/7/30/p33-16276390927461692195962.jpg',
alt: "phong cách hiện đại,phòng vệ sinh,kiến trúc sư,Phòng ngủ nhỏ,không gian sinh hoạt,Không gian bếp,Tủ quần áo,bàn trang điểm,bàn làm việc,khoe nhà đẹp,house n home,căn hộ,",
category: 'life',
time: '12:16 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:16 PM',
link: '/vo-chong-tre-mua-can-ho-76m2-chon-mau-be-tong-sac-lanh-nhin-chat-nhu-phim',
component: 'page_20210730121631'},
{title: "Mang đồ vật nhạy cảm lên sóng, nữ streamer xinh đẹp công khai thói quen đáng lên án, tiết lộ thường vào phòng tắm khi livestream để làm điều này",
description: "Những chia sẻ của cô nàng streamer khiến cho rất nhiều người xem cũng phải cảm thấy tức giận.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/30/photo-4-16276213516981623035451.jpg',
alt: "streamer,nhan sắc gợi cảm,hình ảnh phản cảm,câu nói dối,đáng lên án,nhân vật chính,",
category: 'images',
time: '12:16 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:16 PM',
link: '/mang-do-vat-nhay-cam-len-song-nu-streamer-xinh-dep-cong-khai-thoi-quen-dang-len-an-tiet-lo-thuong-vao-phong-tam-khi-livestream-de-lam-dieu-nay',
component: 'page_20210730121620'},
{title: "Những bí mật ẩn giấu của các vận động viên Olympic",
description: "Qua cách họ tìm mọi cách vượt qua giới hạn bản thân, bạn có thể có cái nhìn mới về cơ thể con người.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/29/photo-1-16275335451592084135479.jpg',
alt: "VẬN ĐỘNG VIÊN,CƠ THỂ CON NGƯỜI,THỂ DỤC DỤNG CỤ,CHẠY NƯỚC RÚT,NHÀ VÔ ĐỊCH,OLYMPIC,",
category: 'news',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/nhung-bi-mat-an-giau-cua-cac-van-dong-vien-olympic',
component: 'page_20210729162657'},
{title: "Những tựa game 18+ không phù hợp để chơi trước mặt nhiều người",
description: "Có quá nhiều cảnh bạo lực và tình dục, những cảnh này không phù hợp để bạn trình chiếu và chơi trước mặt mọi người cho lắm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/28/photo-1-1627486426479237341960.jpg',
alt: "GAME,",
category: 'games',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/nhung-tua-game-18-khong-phu-hop-de-choi-truoc-mat-nhieu-nguoi',
component: 'page_20210729162648'},
{title: "Căn villa 400m2 có khoảng sân vườn ngập nắng gió, thiết kế đẹp tinh tế, giản đơn đề cao sự \"không hoàn hảo\"",
description: "Căn villa được thiết kế theo phong cách Wabi Sabi, trên tinh thần tôn trọng tự nhiên và hướng tới sự đơn giản, bình yên.",
src: 'https://kenh14cdn.com/zoom/90_60/203336854389633024/2021/7/27/photo1627382400143-1627382400338253477531.jpg',
alt: "khoe nhà đẹp,khu đô thị,kiến trúc sư,không gian sinh hoạt,Đồ nội thất,nội thất gỗ,Đồ trang trí,không gian làm việc,phòng chiếu phim,bồn tắm,house n home,villa,biệt thự,",
category: 'life',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/can-villa-400m2-co-khoang-san-vuon-ngap-nang-gio-thiet-ke-dep-tinh-te-gian-don-de-cao-su-khong-hoan-hao',
component: 'page_20210729162639'},
{title: "Loạt Pokémon được lấy cảm hứng từ những con quái vật truyền thuyết",
description: "Thế giới Pokémon có rất nhiều điều thú vị, và nó bao gồm cả nguồn gốc của các loài Pokémon.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/28/photo-1-16274085038071368152910.jpg',
alt: "POKÉMON,TRUYỀN THUYẾT,",
category: 'games',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/loat-pokemon-duoc-lay-cam-hung-tu-truyen-thuyet-nhat-ban',
component: 'page_20210729162629'},
{title: "Mua căn hộ tập thể 40m2, trai độc thân đắp thêm 420 triệu để \"thay máu\" toàn bộ, đơn giản nhưng đẹp hết chỗ chê",
description: "Căn hộ được cải tạo với style trẻ trung, hiện đại, phù hợp với cuộc sống độc thân.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/7/27/hi-10-16274015583131737337430.jpg',
alt: "Cải tạo nhà,Đồ nội thất,nhà vệ sinh,kiến trúc sư,phòng làm việc,Phòng ngủ nhỏ,phong cách Nhật Bản,căn hộ nhỏ,house n home,",
category: 'life',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/mua-can-ho-tap-the-40m2-trai-doc-than-dap-them-420-trieu-de-thay-mau-toan-bo-don-gian-nhung-dep-het-cho-che',
component: 'page_20210729162620'},
{title: "20 game giả lập cho bạn làm đủ nghề trong xã hội, từ thợ sửa ống nước đến chàng lái taxi (P1)",
description: "Những game giả lập này sẽ cho phép bạn làm rất nhiều nghề trong xã hội.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/26/photo-1-1627315208858588438104.jpg',
alt: "GAME GIẢ LẬP,SỬA ỐNG NƯỚC,GAME MÔ PHỎNG,TÀI XẾ XE TẢI,EURO TRUCK SIMULATOR 2,STARDEW VALLEY,",
category: 'games',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/20-game-gia-lap-cho-ban-lam-du-nghe-trong-xa-hoi-tu-tho-sua-ong-nuoc-den-chang-lai-taxi-p1',
component: 'page_20210729162610'},
{title: "Top 4 ninja huyền thoại đã trở thành nguồn cảm hứng nghệ thuật, xuất hiện nhiều trong anime/manga",
description: "Từ những chiến binh Tokugawa trung thành đến người sáng lập Kunoichi đều trở thành cảm hứng sáng tác cho các mangaka.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/26/photo-1-16272838335811881199361.jpg',
alt: "NINJA,KUNOICHI,NINJA HUYỀN THOẠI,HANZO HATTORI,KOTARO FUMA,SASUKE SARUTOBI,CHIYOME MOCHIZUKI,",
category: 'news',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/top-4-ninja-huyen-thoai-da-tro-thanh-nguon-cam-hung-nghe-thuat-xuat-hien-nhieu-trong-anime-manga',
component: 'page_20210729162559'},
{title: "Thành gái độc thân, body Dương Mịch ngày càng \"mlem\": Nhìn màn hở bạo khoe vòng eo sexy ở sự kiện hôm nay là rõ",
description: "Hình ảnh trong sự kiện tối ngày 27/7 của Dương Mịch khiến dân tình cảm thấy nghẹt thở vì độ sexy ngút ngàn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/27/photo-1-1627390920786544123096.jpg',
alt: "Dương Mịch,sao Hoa ngữ,body của sao,vóc dáng mỹ nhân Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,nhan sắc mỹ nhân showbiz,",
category: 'stars',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/thanh-gai-doc-than-body-duong-mich-ngay-cang-mlem-nhin-man-ho-bao-khoe-vong-eo-sexy-o-su-kien-hom-nay-la-ro',
component: 'page_20210729162550'},
{title: "Mãn nhãn chiêm ngưỡng loạt quốc kỳ các nước tham dự Tokyo Olympic 2020 được vẽ theo phong cách anime",
description: "Quốc kỳ Việt Nam đẹp quá anh em nhỉ?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/26/photo-1-16272808098881406095513.jpg',
alt: "QUỐC KỲ,QUỐC KỲ VẼ AIME,TOKYO OLYMPIC 2020,ANIME,",
category: 'news',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/man-nhan-chiem-nguong-loat-quoc-ky-cac-nuoc-tham-du-tokyo-olympic-2020-duoc-ve-theo-phong-cach-anime',
component: 'page_20210729162539'},
{title: "Đạt 2,5 tỷ đô, Axie Infinity - trò chơi do Việt Nam sản xuất trở thành game NFT đắt giá nhất mọi thời đại",
description: "Axie Infinity trở thành niềm tự hào của làng game Việt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/26/photo-1-16272953989991650537087.png',
alt: "AXIE INFINITY,GAME VIỆT,TỶ ĐÔ,BITCOIN,TIỀN ẢO,CRYPTO,CÔNG NGHỆ VIỆT NAM,",
category: 'games',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/dat-25-ty-do-axie-infinity-tro-choi-do-viet-nam-san-xuat-tro-thanh-game-nft-dat-gia-nhat-moi-thoi-dai',
component: 'page_20210729162530'},
{title: "Thực hiện Body painting lên vòng 1 trên sóng trực tiếp, nữ streamer nóng bỏng khiến khán giả sốc nặng",
description: "Nội dung đầy táo bạo của nữ streamer này đã khiến cô nàng trở nên cực kỳ nổi tiếng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/26/4-1627276191391850785522.jpg',
alt: "GÁI XINH,HOT GIRL,STREAMER,NỮ STREAMER,",
category: 'images',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/thuc-hien-body-painting-len-vong-1-tren-song-truc-tiep-nu-streamer-nong-bong-khien-khan-gia-soc-nang',
component: 'page_20210729162522'},
{title: "Cách nhận 12 Pokémon miễn phí trong Pokémon Unite (Phần 2)",
description: "Hãy nhanh tay tham gia Pokémon Unite để nhận được nhiều phần quà miễn phí giá trị.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/26/photo-1-16272752477791676071344.jpg',
alt: "HUẤN LUYỆN VIÊN,POKÉMON,POKÉMON UNITE,MOBA POKÉMON,MOBA POKEMON,MOBA POKÉMON UNITE,",
category: 'games',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/cach-nhan-12-pokemon-mien-phi-trong-pokemon-unite-phan-2',
component: 'page_20210729162514'},
{title: "Mặc váy quá ngắn, nữ streamer gặp sự cố nhạy cảm khi đứng lên, nhận đủ combo \"lộ hàng + cấm kênh\"",
description: "Cô nàng streamer này có lẽ cũng chẳng thể ngờ rằng chỉ một phút sơ sẩy mà mọi thứ đi xa như vậy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/22/photo-1-1626939476082235832977.jpg',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,STREAMER,HANNYANG,NỮ STREAMER,",
category: 'images',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/mac-vay-qua-ngan-nu-streamer-gap-su-co-nhay-cam-khi-dung-len-nhan-du-combo-lo-hang-cam-kenh',
component: 'page_20210729162504'},
{title: "Chân có dài, có đẹp đến mấy mà mặc váy tennis kiểu này thì đố ai dám nhìn các chị em!",
description: "Váy tennis đúng là giúp khoe chân thật nhưng mặc sao cho duyên thì không phải ai cũng biết.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/26/dan-gai-dep-bi-che-lo-vi-1778552547293415744040624037406399480137656n-1619232494-869-width1242height1886-1-16272770382601129431429.jpg',
alt: "fashion,thời trang,không mặc xấu,lộ hàng,pha lộ hàng,ăn mặc phản cảm,ăn mặc hớ hênh,chân váy ngắn,váy tennis,váy ngắn,",
category: 'images',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/chan-co-dai-co-dep-den-may-ma-mac-vay-tennis-kieu-nay-thi-do-ai-dam-nhin-cac-chi-em',
component: 'page_20210729162453'},
{title: "Nếu không thích MacBook thì đây là 5 mẫu laptop sang chảnh mỏng nhẹ xịn xò không kém cho các nàng",
description: "MacBook sang xịn thật nhưng không phải ai cũng thích dùng, và đây là những gợi ý không kém phần chất lượng trong tầm giá 25 triệu.",
src: 'https://kenh14cdn.com/2020/11/6/frame-285-16046320623751918346486.png',
alt: "laptop,Macbook,webuy,thích thì mua,Vũ trụ ở nhà không la cà,đồ điện tử,đồ công nghệ,",
category: 'tech',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/neu-khong-thich-macbook-thi-day-la-5-mau-laptop-sang-chanh-mong-nhe-xin-xo-khong-kem-cho-cac-nang',
component: 'page_20210729162444'},
{title: "Đập bỏ tường, phòng khách của vợ chồng trẻ rộng đến mức chứa được 20 người, còn có góc làm việc chill hết nấc",
description: "Bộ sofa cực lớn trong phòng khách cũng là chi tiết khiến hội \"sâu lười\" thích mê đấy.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/26/d8f1c7616dc340199051e9ca7eb39097-16272931491721888550257.jpeg',
alt: "phòng làm việc,không gian sinh hoạt,bàn làm việc,phong cách Nhật Bản,Căn hộ chung cư,phòng ngủ,khoe nhà đẹp,house n home,",
category: 'life',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/dap-bo-tuong-phong-khach-cua-vo-chong-tre-rong-den-muc-chua-duoc-20-nguoi-con-co-goc-lam-viec-chill-het-nac',
component: 'page_20210729162434'},
{title: "Tựa game tỷ đô do người Việt sản xuất lên sóng truyền hình quốc gia vì mức độ tăng trưởng “khủng\", cộng đồng được dịp nức nở",
description: "Axie Infinity đang trở thành hiện tượng công nghệ toàn cầu khi thu hút hàng trăm nghìn game thủ từ khắp nơi trên thế giới, giá trị vốn hóa lên tới tỷ đô.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/26/photo-1-16272869695311005334810.jpg',
alt: "VTV,game,Axie Infinity,dự án game,2 tỷ usd,Đài truyền hình quốc gia,Nhà sáng lập,công ty công nghệ,nhà phát triển,giá trị vốn hóa,tổng giá trị,tỷ phú công nghệ,",
category: 'tech',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/tua-game-ty-do-do-nguoi-viet-san-xuat-len-song-truyen-hinh-quoc-gia-vi-muc-do-tang-truong-khung-cong-dong-duoc-dip-nuc-no',
component: 'page_20210729162423'},
{title: "Top 20 nhân vật có tiền truy nã cao nhất One Piece tính tới thời điểm hiện tại, Luffy còn thua 6 người này?",
description: "Cùng chúng tôi điểm qua 20 nhân vật có tiền truy nã cao nhất trong One Piece tính cho tới thời điểm hiện tại nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/25/gu1-16271926625831676502001.jpg',
alt: "ONE PIECE,FAN ONE PIECE,CỘNG ĐỒNG ONE PIECE,TRUY NÃ ONE PIECE,LUFFY MŨ RƠM,TỨ HOÀNG KAIDO,TỨ HOÀNG BIG MOM,ARC WANO,TỨ HOÀNG SHANKS,GOL D. ROGER,PHƯỢNG HOÀNG MARCO,",
category: 'games',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/top-20-nhan-vat-co-tien-truy-na-cao-nhat-one-piece-tinh-toi-thoi-diem-hien-tai-luffy-con-thua-6-nguoi-nay',
component: 'page_20210729162410'},
{title: "BXH diễn viên 18+ ăn khách nhất nửa đầu 2021: Tân binh đầu bảng, Yua Mikami về hạng 3",
description: "Vị trí hạng 3 là một thành tích không hề tệ của người đẹp sở hữu biệt danh \"idol quốc dân\" trong năm thứ 6 của sự nghiệp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/25/photo-1-1627176756650960298581.png',
alt: "BẢNG XẾP HẠNG,YUA MIKAMI,ICHIKA MATSUMOTO,IDOL,HANA HIMESAKI,",
category: 'images',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/bxh-dien-vien-18-an-khach-nhat-nua-dau-2021-tan-binh-dau-bang-yua-mikami-ve-hang-3',
component: 'page_20210729162401'},
{title: "Những Pokémon khiến người chơi phải đỏ mắt truy lùng qua từng phiên bản",
description: "Mỗi lần tìm chúng khó hơn mò kim đáy bể chỉ vì các nhà thiét kế tìm cách chơi khó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/24/photo-1-16271432927312095336961.jpg',
alt: "POKÉMON,",
category: 'games',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/nhung-pokemon-phai-do-mat-truy-lung-qua-tung-phien-ban',
component: 'page_20210729162352'},
{title: "Những quái vật đáng sợ từng được đồn đại xuất hiện trong lịch sử loài người (P.2)",
description: "Thế giới tồn tại rất nhiều loài sinh vật kinh dị, gây nên sự sợ hãi đối với con người.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/24/photo-1-162713687304154013119.png',
alt: "QUÁI VẬT,TRUYỀN THUYẾT,",
category: 'news',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/nhung-quai-vat-dang-so-tung-duoc-don-dai-xuat-hien-trong-lich-su-loai-nguoi-p2',
component: 'page_20210729162344'},
]
export const dataContent10=[{title: "Game thủ thấy gì khi đi hết map các game open world đình đám? (P.1)",
description: "Dù bạn tin hay không, các nhà phát triển game open world có đến 1001 cách ngăn bạn đến được rìa thế giới.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/21/-16268588209211899282210.jpg',
alt: "OPEN WORLD,GAME,",
category: 'games',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/game-thu-thay-gi-khi-di-het-map-cac-game-open-world-dinh-dam-p1',
component: 'page_20210729162324'},
{title: "10 ác nữ ấn tượng trên màn ảnh Hoa ngữ: Triệu Lệ Dĩnh hiểm độc đến mấy cũng không gây ức chế bằng \"trùm cuối\"",
description: "Đây đều là những mỹ nhân sở hữu nhan sắc xinh đẹp nhưng lòng dạ mưu mô, thủ đoạn trong phim Hoa ngữ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/23/3-1627031123979499954580.jpg',
alt: "triệu lệ dĩnh,Lý Thuần,lý thấm,tô thanh,mao hiểu đồng,Tân Chỉ Lôi,Xa Thi Mạn,tưởng hân,Lưu Đào,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/10-ac-nu-an-tuong-tren-man-anh-hoa-ngu-trieu-le-dinh-hiem-doc-den-may-cung-khong-gay-uc-che-bang-trum-cuoi',
component: 'page_20210729162312'},
{title: "Những mỹ nữ làng game Việt thường xuyên \"hở bạo\" khoe body gợi cảm khiến fan \"đứng ngồi không yên\"",
description: "Với lợi thế sở hữu thân hình nóng bỏng, các hot girl làng game thường xuyên khoe những bức ảnh thiếu vải lên mạng xã hội.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/24/mai-dora-16271052862411596419679.png',
alt: "Làng Game Việt,thân hình nóng bỏng,ảnh thiếu vải,mạng xã hội,streamer kim chi,Mai Dora,thiên thy,kim chi,Linh Chichan,",
category: 'images',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/nhung-my-nu-lang-game-viet-thuong-xuyen-ho-bao-khoe-body-goi-cam-khien-fan-dung-ngoi-khong-yen',
component: 'page_20210729162258'},
{title: "Cái chết của Ung Chính sau cả trăm năm vẫn là bí ẩn nay được hé lộ qua một hồ sơ mật, chính sử không dám ghi lại",
description: "Nguyên nhân thực sự đằng sau cái chết của Ung Chính là gì?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/22/-16269520845031092644030.jpg',
alt: "Ung Chính,Lịch sử Trung Quốc,",
category: 'news',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/cai-chet-cua-ung-chinh-sau-ca-tram-nam-van-la-bi-an-nay-duoc-he-lo-qua-mot-ho-so-mat-chinh-su-khong-dam-ghi-lai',
component: 'page_20210729162250'},
{title: "Mua căn hộ 58m2, vợ chồng trẻ tự thiết kế siêu ưng nhưng nể nhất là chi phí siêu \"hạt dẻ\"",
description: "Để tiết kiệm, vợ chồng chị Dung không thuê kiến trúc sư mà tự mày mò lên ý tưởng cho từng góc trong nhà.",
src: 'https://kenh14cdn.com/zoom/90_60/203336854389633024/2021/7/27/photo1627382400143-1627382400338253477531.jpg',
alt: "Mua nhà,thiết kế nhà,căn hộ nhỏ,Đồ nội thất,đồ decor,phong cách japandi,trồng cây,khoe nhà đẹp,house n home,",
category: 'life',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/mua-can-ho-58m2-vo-chong-tre-tu-thiet-ke-sieu-ung-nhung-ne-nhat-la-chi-phi-sieu-hat-de',
component: 'page_20210729162241'},
{title: "Livestream cảnh tắm bồn gợi cảm, nữ streamer gây sốc khi cởi toàn bộ, khéo léo tạo \"quần áo\" với bọt xà phòng",
description: "Sự khéo léo của cô nàng streamer khiến không ít người phải bất ngờ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/23/photo-2-16270131455121115321307.jpg',
alt: "bọt xà phòng,hành động phản cảm,thời gian gần,streamer,game thủ,stream,livestream,nữ streamer,",
category: 'images',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/livestream-canh-tam-bon-goi-cam-nu-streamer-gay-soc-khi-coi-toan-bo-kheo-leo-tao-quan-ao-voi-bot-xa-phong',
component: 'page_20210729162230'},
{title: "Nguyên mẫu của Ngụy Anh Lạc: 10 năm sinh 6 đứa con, trăm năm sau khai quật lăng mộ mới phát hiện Càn Long ích kỷ tới mức nào",
description: "Ngụy Anh Lạc gây ấn tượng trong lòng khán giả với hình tượng là một người phụ nữ đầy lý trí và quyết đoán, nguyên mẫu của nhân vật này thực sự có số phận thế nào?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/25/-16246007872231961607847.jpg',
alt: "Ngụy Anh Lạc,Càn Long,",
category: 'news',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/nguyen-mau-cua-nguy-anh-lac-10-nam-sinh-6-dua-con-tram-nam-sau-khai-quat-lang-mo-moi-phat-hien-can-long-ich-ky-toi-muc-nao',
component: 'page_20210729162223'},
{title: "Tại sao Hòa Thân bốc cát bỏ vào cháo cứu nạn liền được Càn Long trọng thưởng, trăm năm sau người đời còn ngợi ca?",
description: "Hành động thể hiện sự thông minh, tài trí của Hòa Thân - một tên tham quan nổi tiếng trong lịch sử Trung Hoa.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/22/-16269489575971688054983.jpg',
alt: "Hòa Thân,Lịch sử Trung Hoa,Càn Long,",
category: 'news',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/tai-sao-hoa-than-boc-cat-bo-vao-chao-cuu-nan-lien-duoc-can-long-trong-thuong-tram-nam-sau-nguoi-doi-con-ngoi-ca',
component: 'page_20210729162215'},
{title: "Quá xinh đẹp và gợi cảm, nàng hot girl Việt được CĐM nháo nhào xin link, bất ngờ khoe thu nhập gần trăm triệu mỗi tháng",
description: "Những thông tin về cô nàng hot girl này đang nhận được rất nhiều sự quan tâm từ phía cộng đồng mạng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/19/photo-1-1626687793287460678808.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,ĐOAN NGHI,",
category: 'images',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/qua-xinh-dep-va-goi-cam-nang-hot-girl-viet-duoc-cdm-nhao-nhao-xin-link-bat-ngo-khoe-thu-nhap-gan-tram-trieu-moi-thang',
component: 'page_20210729162206'},
{title: "Dragon Ball Super: 5 người Saiyan đã có thể biến thành Super Saiyan God, số 4 là trường hợp đặc biệt",
description: "Không phải chỉ mình Goku mới có thể biến thành Super Saiyan God.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/20/photo-1-16267746209691819938455.jpg',
alt: "SUPER SAIYAN GOD,DRAGON BALL SUPER,SAIYAN,VEGETA,TRUNKS XENO,THẦN CỦA NGƯỜI SAIYAN,BLACK GOKU,",
category: 'games',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/dragon-ball-super-5-nguoi-saiyan-da-co-the-bien-thanh-super-saiyan-god-so-4-la-truong-hop-dac-biet',
component: 'page_20210729162156'},
{title: "Những cái chết lãng xẹt bậc nhất trong thế giới trò chơi điện tử",
description: "Không phải cái chết nào cũng vĩ đại như bạn nghĩ đâu, còn có vô số lý do khiến cho game thủ tức anh ách cho đến khi biết nguyên nhân.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/20/photo-1-16267868025941909041185.png',
alt: "GAME,",
category: 'games',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/nhung-cai-chet-lang-xet-bac-nhat-trong-the-gioi-tro-choi-dien-tu',
component: 'page_20210729162146'},
{title: "Hàng loạt homestay, villa Đà Lạt \"kêu cứu\" giữa mùa dịch: Oằn mình gồng gánh các chi phí trong khi doanh thu gần như bằng 0",
description: "Sau hàng loạt đợt dịch bệnh, các cơ sở lưu trú ở Đà Lạt đang phải đối mặt với vô vàn khó khăn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/20/2-16267981477971739455427.jpeg',
alt: "đà lạt,homestay,homestay đà lạt,cơ sở lưu trú Đà Lạt,",
category: 'travel',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/hang-loat-homestay-villa-da-lat-keu-cuu-giua-mua-dich-chat-vat-gong-ganh-cac-chi-phi-trong-khi-doanh-thu-gan-nhu-bang-0',
component: 'page_20210729162137'},
{title: "Những con quái vật rắn nổi tiếng trong thần thoại Hy Lạp",
description: "Rắn thường được miêu tả với tư cách là các vị thần hoặc quái vật trong thần thoại của người Hy Lạp.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/20/photo-1-1626771905962962558871.jpg',
alt: "THẦN THOẠI,QUÁI VẬT,HY LẠP,HYDRA,MEDUSA,",
category: 'news',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/nhung-con-quai-vat-ran-noi-tieng-trong-than-thoai-hy-lap',
component: 'page_20210729162122'},
{title: "Người gan kém thường có 3 thay đổi bất thường ở bàn tay, đi khám ngay vì rất dễ là dấu hiệu của ung thư gan",
description: "Ung thư gan có thể nhen nhóm xuất hiện trong cơ thể bạn bất cứ lúc nào và ngay khi thấy 3 hiện tượng ở bàn tay dưới đây là lúc bạn cần đi kiểm tra gan ngay.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/20/photo-1-16267430533472025884574.jpg',
alt: "thay đổi bất thường,mu bàn tay,tế bào gan,",
category: 'life',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/nguoi-gan-kem-thuong-co-3-thay-doi-bat-thuong-o-ban-tay-di-kham-ngay-vi-rat-de-la-dau-hieu-cua-ung-thu-gan',
component: 'page_20210729162112'},
{title: "\"Ngỡ ngàng, ngơ ngác và bật ngửa\" khi ngắm tâm hồn khủng bố của nữ waifu được yêu thích nhất hiện nay",
description: "Loạt ảnh cosplay cô nàng Sayu Ogiwara trong anime Cạo Râu Xong, Tôi Nhặt Gái Về Nhà do nữ coser 芥川Aku thể hiện đã khiến anh em không thể rời mắt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/19/-16266772503211234007580.jpg',
alt: "CẠO RÂU XONG,TÔI NHẶT GÁI VỀ NHÀ,SAYU OGIWARA,芥川AKU,COSER,COSPLAY,GÁI XINH,",
category: 'images',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/ngo-ngang-ngo-ngac-va-bat-ngua-khi-ngam-tam-hon-khung-bo-cua-nu-waifu-duoc-yeu-thich-nhat-hien-nay',
component: 'page_20210729162057'},
{title: "Top 10 lời đồn “huyền thoại” dù hư cấu nhưng nhiều game thủ vẫn tin sái cổ (P.2)",
description: "Những cú lừa cực mạnh nhưng anh em nào cũng ít nhất một lần dính trap...",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/19/photo-1-16267104636641078128704.png',
alt: "GAME,GAME HAY,BÍ ẨN,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,GAME THỦ,",
category: 'games',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/top-10-loi-don-huyen-thoai-du-hu-cau-nhung-nhieu-game-thu-van-tin-sai-co-p2',
component: 'page_20210729162047'},
{title: "Những loài quái vật kỳ quái trong truyền thuyết châu Á",
description: "Với trí tưởng tượng độc đáo, người châu Á đã tưởng tượng ra rất nhiều con quái vật ma quái và có phần dị hợm vô cùng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/19/-16266845034714681647.jpg',
alt: "QUÁI VẬT,YÊU QUÁI,",
category: 'news',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/nhung-loai-quai-vat-ky-quai-trong-truyen-thuyet-chau-a',
component: 'page_20210729162037'},
{title: "Biểu tượng cảm xúc nào nổi tiếng nhất thế giới?",
description: "Phần lớn người dùng emoji đồng ý rằng biểu tượng “cười ra nước mắt” vô cùng thú vị.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/19/photo-1-16266831185991254590428.jpg',
alt: "EMOJI,NỔI TIẾNG NHẤT,NGƯỜI THAM GIA,MẠNG XÃ HỘI,",
category: 'tech',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/bieu-tuong-cam-xuc-nao-noi-tieng-nhat-the-gioi',
component: 'page_20210729162028'},
{title: "Top 10 Pokémon khiến con người cảm thấy sợ hãi nhất, nhiều loài đáng sợ ngay từ vẻ ngoài",
description: "Con người và Pokémon hầu hết cùng tồn tại một cách hòa hợp, nhưng những Pokémon nguy hiểm này có thể làm hại con người nếu không được kiểm soát.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-16266038127731600690029.jpg',
alt: "POKÉMON,POKÉMON KHIẾN CON NGƯỜI SỢ,MEWTWO,GENGAR,ALAKAZAM,COFAGRIGUS,HYDREIGON,",
category: 'games',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/top-10-pokemon-khien-con-nguoi-cam-thay-so-hai-nhat-nhieu-loai-dang-so-ngay-tu-ve-ngoai',
component: 'page_20210729162017'},
{title: "Đa vũ trụ Marvel là gì?",
description: "Cái kết của series Loki đã chính thức đưa MCU bước vào đa vũ trụ, nhưng rốt cuộc thì tình trạng này là gì mà lại có vẻ nghiêm trọng đến vậy?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-16266197121021694927468.jpg',
alt: "MARVEL,ĐA VŨ TRỤ,SIÊU ANH HÙNG,MARVEL MULTIVERSE,TRUYỆN TRANH,ĐA VŨ TRỤ MARVEL,",
category: 'news',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/da-vu-tru-marvel-la-gi',
component: 'page_20210729162008'},
{title: "Cổ điển nhưng không lỗi thời, Timing Hero vẫn thu hút hàng triệu game thủ thử sức trên toàn thế giới",
description: "Gạt đi định kiến về những tựa game trắng đen lỗi thời, Timing Hero sẽ là lựa chọn hoàn hảo cho những pha hành động mãn nhãn trong thời điểm hiện tại.",
src: 'https://lh6.googleusercontent.com/YWHfqxRBujPr2kf9S6VFggV1_2Ds-TaO89iDF31b1oCAWavC7yuiQGfWQjx0RS8dlqDOXcfuYWVITLD8KKmX8F9iezCS0NOB4YtP0aMCNAOapuOKeNVuEPD6rXTAWZzgjrY_91v3',
alt: "TIMIING HERO,TIN TỨC GAME,",
category: 'games',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/co-dien-nhung-khong-loi-thoi-timing-hero-van-thu-hut-hang-trieu-game-thu-thu-suc-tren-toan-the-gioi',
component: 'page_20210729162000'},
{title: "Top 10 công việc kỳ lạ mà siêu anh hùng đã làm để kiếm tiền trang trải cuộc sống trong comic (P.1)",
description: "Không phải ai cũng có nhiều tiền như Batman và Iron Man, một số siêu anh hùng phải làm việc để kiếm sống.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-1626603187021266884680.jpg',
alt: "MARVEL,GHOST RIDER,SIÊU ANH HÙNG,CÔNG VIỆC CỦA SIÊU ANH HÙNG,BLACK CANARY,DC,",
category: 'news',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/top-10-cong-viec-ky-la-ma-sieu-anh-hung-da-lam-de-kiem-tien-trang-trai-cuoc-song-trong-comic-p1',
component: 'page_20210729161949'},
{title: "Top 10 công việc kỳ lạ mà siêu anh hùng đã làm để kiếm tiền trang trải cuộc sống trong comic (P.2)",
description: "Không phải ai cũng có nhiều tiền như Batman và Iron Man, một số siêu anh hùng phải làm việc để kiếm sống.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-16266035178331141843848.jpg',
alt: "MARVEL,DC,GREEN LANTERN,SIÊU ANH HÙNG,NGHỆ NGHIỆP CỦA SIÊU ANH HÙNG,",
category: 'news',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/top-10-cong-viec-ky-la-ma-sieu-anh-hung-da-lam-de-kiem-tien-trang-trai-cuoc-song-trong-comic-p2',
component: 'page_20210729161939'},
{title: "Ngắm nhan sắc và body của nữ chính Cạo Râu Xong, Tôi Nhặt Gái Về Nhà là hiểu ngay vì sao waifu này được yêu thích nhất",
description: "Origawa Sayu chính là best girl được yêu thích nhất anime mùa xuân 2021.",
src: 'https://i.imgur.com/n2USa0A.gif',
alt: "CẠO RÂU XONG,TÔI NHẶT GÁI VỀ NHÀ,ORIGAWA SAYU,WAIFU,BEST GIRL,GÁI XINH ANIME,",
category: 'images',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/ngam-nhan-sac-va-body-cua-nu-chinh-cao-rau-xong-toi-nhat-gai-ve-nha-la-hieu-ngay-vi-sao-waifu-nay-duoc-yeu-thich-nhat',
component: 'page_20210729161930'},
{title: "Trang trại 3.800 máy PS4 Slim hoá ra không phải để đào coin, mà dùng để \"cày\" vật phẩm trong game FIFA 21",
description: "PS4 có thể kiếm tiền, nhưng không bằng cách khai thác tiền điện tử.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/19/photo-1-1626707442669514919282.png',
alt: "COIN,CỘNG ĐỒNG MẠNG,PS4 SLIM,TIN TỨC GAME,PS4,",
category: 'games',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/trang-trai-3800-may-ps4-slim-hoa-ra-khong-phai-de-dao-coin-ma-dung-de-cay-vat-pham-trong-game-fifa-21',
component: 'page_20210729161920'},
{title: "Chàng trai mang món \"pháp khí\" đi kiểm định khiến cả trường quay kinh hãi, chuyên gia lắc đầu: Cầm về đi, tôi không định giá!",
description: "Khi nghe tới vật liệu làm nên món \"pháp khí\", toàn bộ khán giả trong trường quay đều \"toát mồ hôi hột\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/19/photo-1-16266781826341961459693.jpeg',
alt: "toát mồ hôi,toát mồ hôi hột,người dân địa phương,giá trị vật chất,sức hấp dẫn,",
category: 'news',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/chang-trai-mang-mon-phap-khi-di-kiem-dinh-khien-ca-truong-quay-kinh-hai-chuyen-gia-lac-dau-cam-ve-di-toi-khong-dinh-gia',
component: 'page_20210729161909'},
{title: "20 chú động vật béo tròn núc ních, có thể vẽ lại hoàn toàn bằng 1 chiếc compa",
description: "Trông dễ thương vậy thôi chứ béo phì khổ sở ghê lắm đó nghe.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/19/round-animals22-16266923346001459291269.jpg',
alt: "động vật béo,đáng yêu,tròn vo,relax,",
category: 'news',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/20-chu-dong-vat-beo-tron-nuc-nich-co-the-ve-lai-hoan-toan-bang-1-chiec-compa',
component: 'page_20210729161859'},
{title: "Sau Thám Tử Đã Chết các fan anime thích thú với dàn harem trong \"Có Bạn Gái Lại Thêm Bạn Gái\", bắt cá 2 tay có khi lại hay",
description: "Thêm một siêu phẩm anime nữa được nhiều khán giả yêu thích với dàn harem vô cùng ấn tượng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/17/rui3-1626497074652125518877.png',
alt: "RENT-A-GIRL-FRIEND,CÓ BẠN GÁI LẠI THÊM BẠN GÁI,HAREM,ANIME MỚI,DIAMOND THUÊ CHIZURU MIZUHARA,KAZUYA KINOSHITA,",
category: 'games',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/sau-tham-tu-da-chet-cac-fan-anime-thich-thu-voi-dan-harem-trong-co-ban-gai-lai-them-ban-gai-bat-ca-2-tay-co-khi-lai-hay',
component: 'page_20210729161845'},
{title: "Hy-Brasil: Hòn đảo ma trong truyền thuyết và điểm tọa độ UFO kỳ bí được viết bằng mã nhị phân",
description: "Câu chuyện về hòn đảo được lưu truyền khắp châu Âu trong nhiều thế kỷ. Người ta nói rằng hòn đảo là nơi sinh sống của các vị thánh hoặc là một thiên đường nơi có nền văn minh tiên tiến.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/15/binary-code-showing-the-coordinates-of-hy-brasil-1626340762537741265047.jpg',
alt: "KHÁM PHÁ,BÍ ẨN,TRUYỀN THUYẾT,",
category: 'news',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/hy-brasil-hon-dao-ma-trong-truyen-thuyet-va-diem-toa-do-ufo-ky-bi-duoc-viet-bang-ma-nhi-phan',
component: 'page_20210729161836'},
{title: "Những trò chơi khiến game thủ giật mình vì \"hút máu\" quá dữ dội",
description: "Những pha hút máu này xứng đáng được kể tới vì chúng được sinh ra đúng với mục đích \"hút máu\" người chơi tới tận giọt cuối cùng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/17/photo-1-1626467721491353955828.jpg',
alt: "GAME,HÚT MÁU,",
category: 'games',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/nhung-tro-choi-khien-game-thu-giat-minh-vi-hut-mau-qua-du-doi',
component: 'page_20210729161825'},
{title: "Tham quan bản đồ các “lò” đào tạo game thủ, “thiên đường” cho 500 anh em là đây chứ đâu!",
description: "Nhiều quốc gia châu Á đặt niềm tin rằng, Esports sẽ phát triển trong thời gian tới và game thủ là ngành nghề cần được đào tạo kỹ lưỡng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/16/-1626452062656620490724.jpg',
alt: "GAME THỦ,TRƯỜNG HỌC,ESPORTS,",
category: 'games',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/tham-quan-ban-do-cac-lo-dao-tao-game-thu-thien-duong-cho-500-anh-em-la-day-chu-dau',
component: 'page_20210729161815'},
{title: "18 ví dụ chứng minh những bộ phim càng bom tấn lại càng có nhiều lỗi rất ngớ ngẩn",
description: "Có rất nhiều bộ phim bom tấn quá hay, khiến người xem không còn tinh tế để nhận rằng chúng luôn có những hạt sạn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/16/photo-1-16264291359491681184130.jpg',
alt: "THE HOBBIT VÀ THE LORD OF THE RINGS,HARRY POTTER,HARRY POTTER VÀ HỘI PHƯỢNG HOÀNG,SPIDER-MAN,STAR TREK,TWILIGHT,PIRATES OF THE CARIBBEAN,",
category: 'news',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/18-vi-du-chung-minh-nhung-bo-phim-cang-bom-tan-lai-cang-co-nhieu-loi-rat-ngo-ngan',
component: 'page_20210729161807'},
{title: "Địa ngục Tartarus trong thần thoại Hy Lạp nằm ở đâu?",
description: "Tartarus là vực thẳm địa ngục trong thần thoại Hy Lạp, nơi đem lại nỗi đau khổ cho các Titan bị giam giữ. Tartarus cũng là tên của một vị thần, cổ xưa và nguyên thủy, tồn tại trước cả Titan và Olympus.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/16/aion-and-gaia-162642442147914292086.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,THẦN THOẠI HY LẠP,CUỘC SỐNG,",
category: 'news',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/dia-nguc-tartarus-trong-than-thoai-hy-lap-nam-o-dau',
component: 'page_20210729161758'},
{title: "Công khai bạn trai rồi mất hút, không lên sóng, nữ streamer gợi cảm phải quỳ xuống xin lỗi người xem ngày trở lại",
description: "Cô nàng streamer đã phải quỳ xuống xin lỗi khán giả sau khi bặt vô âm tín suốt một thời gian dài.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/14/photo-1-1626252949529462255133.jpg',
alt: "HOT GIRL,CỘNG ĐỒNG MẠNG,GÁI XINH,PARK MIN-JUNG,STREAMER,NỮ STREAMER,",
category: 'images',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/cong-khai-ban-trai-roi-mat-hut-khong-len-song-nu-streamer-goi-cam-phai-quy-xuong-xin-loi-nguoi-xem-ngay-tro-lai',
component: 'page_20210729161746'},
{title: "Không khoe thì thôi, một khi đã “show” thì Ngân Sát Thủ cũng khiến anh em phải \"nóng mặt\" với đường cong hoàn hảo!",
description: "Với phong cách “kín cổng cao tường”, Ngân Sát Thủ không nhiều lần khoe vóc dáng quyến rũ, nhưng cứ hở ra là khiến dân tình trầm trồ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/16/-16264268804161922126421.jpg',
alt: "STREAMER,NGÂN SÁT THỦ,VÒNG 3,NÓNG BỎNG,",
category: 'images',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/khong-khoe-thi-thoi-mot-khi-da-show-thi-ngan-sat-thu-cung-khien-anh-em-phai-nong-mat-voi-duong-cong-hoan-hao',
component: 'page_20210729161737'},
{title: "Đường Tăng đẹp nhất Tây Du Ký tức giận bỏ vai chỉ vì 18 nghìn đồng, dối lừa khán giả rồi bị Trư Bát Giới \"vạch mặt\"?",
description: "Trong Tây Du Ký, mối quan hệ giữa các thành viên không phải lúc nào cũng hòa nhã.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/17/photo-1-16264908640182071786208.jpeg',
alt: "TRƯ BÁT GIỚI,ĐƯỜNG TĂNG,TÂY DU KÝ,TỪ THIẾU HOA,ĐƯỜNG TĂNG ĐẸP NHẤT,",
category: 'news',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/duong-tang-dep-nhat-tay-du-ky-tuc-gian-bo-vai-chi-vi-18-nghin-dong-doi-lua-khan-gia-roi-bi-tru-bat-gioi-vach-mat',
component: 'page_20210729161728'},
{title: "Nàng Điêu Thuyền xinh nhất Liên Quân khoe thân hình sexy “rất gì và này nọ”, đập tan tin đồn thất thiệt",
description: "Mới đây, Hoàng Yến Chibi, nàng Điêu Thuyền xinh bậc nhất Liên Quân đã chứng minh rằng cô nàng không chỉ xinh mà tâm hồn cũng rất đẹp.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/16/photo-1-1626424095083402482469.jpg',
alt: "LIÊN QUÂN MOBILE,LIÊN QUÂN,CỘNG ĐỒNG LIÊN QUÂN MOBILE,SEXY,GÁI XINH,HOÀNG YẾN,HOÀNG YẾN CHIBI,ĐIÊU THUYỀN,GAME THỦ,NỮ GAME THỦ,",
category: 'images',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/nang-dieu-thuyen-xinh-nhat-lien-quan-khoe-than-hinh-sexy-rat-gi-va-nay-no-dap-tan-tin-don-that-thiet',
component: 'page_20210729161719'},
{title: "Chơi game hay, dạy học giỏi, các nữ giáo viên trẻ khiến anh em game thủ tới tấp muốn đăng ký lớp phụ đạo",
description: "Thừa các yếu tố để thu hút cánh mày râu, các nữ giáo viên xinh đẹp sở hữu lượt fan “khủng\".",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/10/photo-1-16259322309741565601941.jpg',
alt: "GIÁO VIÊN,CHƠI GAME,STREAMER,GAME THỦ,CỘNG ĐỒNG MẠNG,",
category: 'games',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/choi-game-hay-day-hoc-gioi-cac-nu-giao-vien-tre-khien-anh-em-game-thu-toi-tap-muon-dang-ky-lop-phu-dao',
component: 'page_20210729161710'},
{title: "Loạt game Pokémon hay nhất trong mỗi thế hệ, cái tên nào khi được xướng lên cũng khiến fan mê mệt",
description: "Trải qua tám thế hệ trò chơi điện tử, người hâm mộ Pokémon đã có thể chơi một số tựa game tuyệt vời, nhưng đâu mới là cái tên hay nhất từ mỗi thế hệ?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/10/photo-1-16258922973121614617511.jpg',
alt: "POKÉMON,POKÉMON GOLD,POKÉMON YELLOW,GAME POKÉMON,CÁC THẾ HỆ POKÉMON,POKÉMON EMERALD,POKÉMON PLATINUM,",
category: 'games',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/loat-game-pokemon-hay-nhat-trong-moi-the-he-cai-ten-nao-khi-duoc-xuong-len-cung-khien-fan-me-met',
component: 'page_20210729161701'},
{title: "Yua Mikami tiết lộ chuyện bị từ mặt, \"chiến tranh lạnh\" với mẹ ruột vì làm diễn viên 18+",
description: "Câu chuyện gia đình của Yua Mikami mới đây đã được chính cô tiết lộ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/10/photo-1-16258967126351031921583.jpeg',
alt: "YUA MIKAMI,IDOL,",
category: 'images',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/yua-mikami-tiet-lo-chuyen-bi-tu-mat-chien-tranh-lanh-voi-me-ruot-vi-lam-dien-vien-18',
component: 'page_20210729161652'},
{title: "13 báu vật ma thuật của đảo Anh, những truyền thuyết bị lãng quên (P.1)",
description: "Các báu vật này được nhắc đến trong văn bản cổ của xứ Welsh với tiêu đề \"Tri Thlws ar Ddeg Ynys Prydain\", dịch ra có nghĩa là \"Tên của 13 báu vật ở phía bắc đảo Anh\".",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/9/sword-of-rhydderch-the-generous0-16258376823891587814041.jpg',
alt: "KHÁM PHÁ,ĐỜI SỐNG,TRUYỀN THUYẾT,LỊCH SỬ,THẦN THOẠI,",
category: 'news',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/13-bau-vat-ma-thuat-cua-dao-anh-nhung-truyen-thuyet-bi-lang-quen-p1',
component: 'page_20210729161642'},
{title: "Nữ streamer gợi cảm hé lộ câu chuyện bị bạn trai hành hạ, \"quấy rối\" mỗi ngày, sợ tới mức không dám mách bố",
description: "Câu chuyện của cô nàng streamer khiến cho khá nhiều người phải bất ngờ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/7/photo-1-16256486514361323409952.jpg',
alt: "GÁI XINH,STREAMER,CỘNG ĐỒNG MẠNG,NỮ STREAMER,HOT GIRL,",
category: 'images',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/nu-streamer-goi-cam-he-lo-cau-chuyen-bi-ban-trai-hanh-ha-quay-roi-moi-ngay-so-toi-muc-khong-dam-mach-bo',
component: 'page_20210729161633'},
{title: "Kể khổ vì vòng một quá khủng rồi vạch áo lên khoe ngay sau đó, nữ streamer bị phản pháo \"Thôi làm trò đi\"",
description: "Cô nàng streamer khiến khá nhiều người tỏ ra ghen tị và bực bội khi cứ liên tục than vãn về vòng một của mình.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-16257302380821208815160.jpg',
alt: "HOT GIRL,GÁI XINH,NỮ STREAMER,STREAMER,CỘNG ĐỒNG MẠNG,SEUL,",
category: 'images',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/ke-kho-vi-vong-mot-qua-khung-roi-vach-ao-len-khoe-ngay-sau-do-nu-streamer-bi-phan-phao-thoi-lam-tro-di',
component: 'page_20210729161623'},
{title: "Tại sao bánh quy thường có viền răng cưa? Chính nhà sản xuất lên tiếng giải đáp bí ẩn bao năm mà chưa ai nghĩ ra nổi",
description: "Viền răng cưa ở những chiếc bánh quy không phải để cho đẹp mà đáp án thực sự khiến hầu hết mọi người, nhất là hội mê bánh quy nghe xong đều sững sờ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/8/newfile-8-16257365816362135614612.jpg',
alt: "bánh quy,giải đáp thắc mắc,bánh quy Ritz,",
category: 'news',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/tai-sao-banh-quy-thuong-co-vien-rang-cua-chinh-nha-san-xuat-len-tieng-giai-dap-bi-an-bao-nam-khien-dan-mang-soc-vi-chua-ai-nghi-ra-noi',
component: 'page_20210729161613'},
{title: "Mỹ nhân Hoa ngữ ngượng chín mặt vì lộ nội y trên phim: \"Trùm cuối\" còn chuộng thả rông vòng 1 nữa cơ",
description: "Nhiều mỹ nhân đình đám xứ Trung từng bẽ mặt vì mắc lỗi nội y khi đóng phim.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/9/2-16258209658481572498571.jpg',
alt: "nhặt sạn phim ảnh,phim trung quốc,phim cổ trang,cổ lực na trát,triệu lệ dĩnh,Địch Lệ Nhiệt Ba,Dương Mịch,Lưu Diệc Phi,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/my-nhan-hoa-ngu-nguong-chin-mat-vi-lo-noi-y-tren-phim-trum-cuoi-con-chuong-tha-rong-vong-1',
component: 'page_20210729161603'},
{title: "Tiểu mỹ nhân 18+ chia sẻ chuyện lần đầu, gây sốc với tuyên bố muốn được \"trải nghiệm\" với tất cả nam đồng nghiệp",
description: "Cô nàng Karen Kaede đã có những bật mí khiến cho tất cả các fan hâm mộ phải bất ngờ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-1625728032365655486688.jpg',
alt: "IDOL,KAREN KAEDE,",
category: 'images',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/tieu-my-nhan-18-chia-se-chuyen-lan-dau-len-phim-mong-muon-duoc-trai-nghiem-voi-tat-ca-nam-dong-nghiep',
component: 'page_20210729161549'},
{title: "Quái vật người tuyết Yeti có thực sự tồn tại, hay chỉ là tưởng tượng của loài người?",
description: "Yeti, dịch theo ngôn ngữ địa phương có nghĩa là \"Người tuyết\", là một sinh vật huyền bí, khổng lồ và được cho là sống tại khu vực dãy núi Himalaya lạnh giá và một vài vùng núi phương Bắc.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-1625736015746304669013.jpg',
alt: "QUÁI VẬT,YETI,TRUYỀN THUYẾT,",
category: 'news',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/quai-vat-nguoi-tuyet-yeti-co-thuc-su-ton-tai-hay-chi-la-tuong-tuong-cua-loai-nguoi',
component: 'page_20210729161540'},
{title: "9 điểm \"phi logic\" từ Harry Potter: Voldemort có thể bị hạ gục đơn giản, nhà ngục Azkaban chắc chắn có vấn đề!",
description: "Có những thắc mắc lạ lùng mà ngay cả fan cứng Harry Potter cũng phải lắc đầu không giải thích được.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-16257387231192055409347.jpg',
alt: "AZKABAN,NHÀ NGỤC AZKABAN,HARRY POTTER,VOLDEMORT,CHI TIẾT PHI LÝ TRONG HARRY POTTER,",
category: 'news',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/9-diem-phi-logic-tu-harry-potter-voldemort-co-the-bi-ha-guc-don-gian-nha-nguc-azkaban-chac-chan-co-van-de',
component: 'page_20210729161530'},
{title: "6 nhân vật đã chết không phải do bị giết trong One Piece, số 2 là trường hợp rất đặc biệt",
description: "Không phải cái chết nào trong One Piece cũng do bị giết đâu nhé!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-16257367946381870341655.jpg',
alt: "ONE PIECE,CÁI CHẾT ONE PIECE,NHÂN VẬT ONE PIECE CHẾT VÌ BỊ BỆNH,PORTGAS D. ROUGE,VINSMOKE SORA,",
category: 'games',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/6-nhan-vat-da-chet-khong-phai-do-bi-giet-trong-one-piece-so-2-la-truong-hop-rat-dac-biet',
component: 'page_20210729161522'},
]
export const dataContent11=[{title: "Khoe vòng một \"cân\" điện thoại dễ dàng, nàng hot girl gây sốc khi chia sẻ được hơn 30 người tán tỉnh mỗi ngày",
description: "Cô nàng hot girl này đang nhận được sự chú ý của đông đảo cộng đồng mạng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/6/photo-3-1625559152979963587134.png',
alt: "GÁI XINH,CỘNG ĐỒNG MẠNG,HOT GIRL,YOUTUBER,YOUTUBE,",
category: 'images',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/khoe-vong-mot-can-dien-thoai-de-dang-nang-hot-girl-gay-soc-khi-chia-se-duoc-hon-30-nguoi-tan-tinh-moi-ngay',
component: 'page_20210729161503'},
{title: "Phải ăn cơm bình dân với giá 60k, hot girl phim 18+ lên tiếng than khóc, chỉ trích các khán giả \"xem lậu\" không trả tiền",
description: "Cô nàng hot girl thừa nhận chưa bao giờ công việc của mình khủng hoảng như vậy.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/6/-16255478010812016927052.jpg',
alt: "GÁI XINH,CỘNG ĐỒNG MẠNG,HOT GIRL,MISASHI HORIUCHI,AV,PHIM AV,PHIM 18+,",
category: 'images',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/phai-an-com-binh-dan-voi-gia-60k-hot-girl-phim-18-len-tieng-than-khoc-chi-trich-cac-khan-gia-xem-lau-khong-tra-tien',
component: 'page_20210729161454'},
{title: "Truyền thuyết nàng tiên cá siêu dị trong thần thoại Nhật Bản",
description: "Thần thoại Nhật Bản cũng có nàng tiên cá. Tuy nhiên, nàng tiên cá này không xinh đẹp, gợi cảm như thần thoại Hy Lạp mà vô cùng kì dị.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/7/magical-mermaids-16256640999882054863144.jpg',
alt: "KHÁM PHÁ,TIÊN CÁ,THẦN THOẠI,THẦN THOẠI NHẬT BẢN,",
category: 'news',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/truyen-thuyet-nang-tien-ca-sieu-di-trong-than-thoai-nhat-ban',
component: 'page_20210729161444'},
{title: "Game thủ mobile gây sốc khi mạnh tay rút hầu bao chi số tiền “kỷ lục” trong 6 tháng đầu năm",
description: "Dù trong thời kỳ dịch bệnh lũng đoạn, hoành hành, game thủ mobile vẫn chứng minh mình là người chịu chơi nhất khi mạnh tay chi số tiền khủng vào game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/7/photo-1-16256531735491340483581.jpg',
alt: "GAME THỦ,GAME MOBILE,DOANH THU,",
category: 'games',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/game-thu-mobile-gay-soc-khi-manh-tay-rut-hau-bao-chi-so-tien-ky-luc-trong-6-thang-dau-nam',
component: 'page_20210729161437'},
{title: "Trở lại nơi từng bị quấy rối khiếm nhã, nữ streamer siêu vòng một gây bất ngờ, tiếp tục cởi áo khoe body ngay sau đó",
description: "Hành động của cô nàng streamer ngay lập tức đã thu hút được rất nhiều sự chú ý từ phía người xem.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/5/photo-1-16254791447121610697265.gif',
alt: "GÁI XINH,STREAMER,HOT GIRL,CỘNG ĐỒNG MẠNG,RAN,NỮ STREAMER,",
category: 'images',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/tro-lai-noi-tung-bi-quay-roi-khiem-nha-nu-streamer-sieu-vong-gay-bat-ngo-tiep-tuc-coi-ao-khoe-body-ngay-sau-do',
component: 'page_20210729161429'},
{title: "MXH toàn cầu \"sốc visual\" vì BTS tại show Louis Vuitton: Jungkook đẹp tê điếng, hot hơn cả là màn lột xác của thành viên giàu nhất nhóm",
description: "BTSxLouisVuitton đã trở thành hashtag nóng nhất mạng xã hội hiện tại.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/7/20480873342068777127434615991189081392576798n-162566155003360307880.jpg',
alt: "BTS,bangtan boys (bts),v (bts),jungkook (bts),Jimin (BTS),Jin (BTS),suga (bts),RM (BTS),j-hope (bts),sao Hàn,Louis Vuitton,",
category: 'stars',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/mxh-toan-cau-soc-visual-vi-bts-tai-show-louis-vuitton-jungkook-dep-te-dieng-hot-hon-ca-la-man-lot-xac-cua-thanh-vien-giau-nhat-nhom',
component: 'page_20210729161420'},
{title: "Pháp thuật \"vẽ vòng\" Tôn Ngộ Không để bảo vệ Đường Tăng có gì đặc biệt?",
description: "Trong tập phim \"Ba lần đánh bại Bạch Cốt Tinh\", Tôn Ngộ Không đã dùng phép này để bảo vệ Đường Tăng đang ở một mình.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/6/photo-1-16255712177881351405509.jpg',
alt: "TÔN NGỘ KHÔNG,TÂY DU KÝ,ĐƯỜNG TĂNG,",
category: 'news',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/phap-thuat-ve-vong-ton-ngo-khong-de-bao-ve-duong-tang-co-gi-dac-biet',
component: 'page_20210729161409'},
{title: "Những chú chó ghi dấu ấn đậm nhất trong làng game thế giới, ai chơi qua cũng đều phải nhớ mãi",
description: "Những chú chó có cả tốt và xấu này đã từng khuynh đảo làng game thế giới.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/6/screenshot10-1625559357592580298764.png',
alt: "GAME,GAME THỦ,TIN TỨC GAME,",
category: 'games',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/nhung-chu-cho-ghi-dau-an-dam-nhat-trong-lang-game-the-gioi-ai-choi-qua-cung-deu-phai-nho-mai',
component: 'page_20210729161400'},
{title: "Khám phá \"bảo tàng quỷ ám\" nhà Warren cùng những giai thoại \"nổi da gà\"",
description: "Trong các phần phim thuộc vũ trụ điện ảnh Conjuring, khán giả nhiều lần \"chạm trán\" với những món đồ quỷ ám và cũng là chứng tích cho hành trình trừ ma của gia đình Warren.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/6/photo-1-1625567801393958335029.jpg',
alt: "BÚP BÊ ANNABELLE,VŨ TRỤ ĐIỆN ẢNH CONJURING,BẢO TÀNG WARREN,MÓN ĐỒ QUỶ ÁM,PHIM KINH DỊ,CHIẾC GƯƠNG TRIỆU HỒN,",
category: 'news',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/kham-pha-bao-tang-quy-am-nha-warren-cung-nhung-giai-thoai-noi-da-ga',
component: 'page_20210729161351'},
{title: "Những vũ khí đời thực được buff quá đà trong game",
description: "Các vũ khí này thực sự rất mạnh trong game, khiến cho người chơi phải tự hỏi xem đời thực có thật sự mạnh như thế này không.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/6/photo-1-1625590321049993105138.jpg',
alt: "GAME,ĐỜI THỰC,",
category: 'games',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/nhung-vu-khi-doi-thuc-duoc-buff-qua-da-trong-game',
component: 'page_20210729161340'},
{title: "Cosplay nhân vật Street Fighter trong Free Fire, các hot girl Việt nóng bỏng và sexy với vòng 3 \"hút mắt\"",
description: "Nhân vật này được người chơi Free Fire chào đón, sau thông tin đây là thành quả từ màn hợp tác toàn cầu của tựa game này với Street Fighter V.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/7/5/373-3737940street-fighter-v-street-fighter-game-chun-li-16255024378632038847535.png',
alt: "HOT GIRL,FREE FIRE,CHUN-LI,CHI CỦ CẢI,LÊN BỐNG,",
category: 'images',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/cosplay-nhan-vat-street-fighter-trong-free-fire-cac-hot-girl-viet-nong-bong-va-sexy-voi-vong-3-hut-mat',
component: 'page_20210729161332'},
{title: "8 sinh vật siêu dị bị lãng quên trong thần thoại Hy Lạp mà không phải ai cũng biết (P.1)",
description: "Bên cạnh những anh hùng, thần thánh và các chuyến phiêu lưu thú vị, thần thoại Hy Lạp còn là kho tàng giới thiệu vô số sinh vật hoặc quái vật cực dị như Dracaenae, Onocentaur hãy mãng xà Cerastes...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/5/campebyhighdarktemplardd3kenh-fullview-16254955842141325519901.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,LỊCH SỬ,THẦN THOẠI HY LẠP,ĐỜI SỐNG,",
category: 'news',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/8-sinh-vat-sieu-di-bi-lang-quen-trong-than-thoai-hy-lap-ma-khong-phai-ai-cung-biet-p1',
component: 'page_20210729161320'},
{title: "Ăn vận hớ hênh, lộ cả chi tiết \"nhạy cảm\" lúc ngồi xuống, nữ streamer phản pháo: \"Sai hết, tôi tẩy lông mỗi ngày\"",
description: "Cô nàng streamer sau đó cũng đã lên tiếng phân trần và xin lỗi về sự việc lần này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/5/photo-1-1625455395199233021683.gif',
alt: "GÁI XINH,CỘNG ĐỒNG MẠNG,STREAMER,HOT GIRL,NỮ STREAMER,PADAK,",
category: 'images',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/an-van-ho-henh-lo-ca-chi-tiet-nhay-cam-luc-ngoi-xuong-nu-streamer-phan-phao-sai-het-toi-tay-long-moi-ngay',
component: 'page_20210729161311'},
{title: "Sở hữu số đo ba vòng 88-62-91, hot girl \"Nóng cùng Euro\" khiến dân mạng đứng hình vì đôi gò bồng đảo gợi cảm",
description: "Cô nàng hot girl này vốn dĩ cũng đã là gương mặt rất quen thuộc với cộng đồng mạng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/5/photo-1-1625460294647880712999.jpg',
alt: "CỘNG ĐỒNG MẠNG,HẰNG HABI,GÁI XINH,HOT GIRL,",
category: 'images',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/so-huu-so-do-ba-vong-88-62-91-hot-girl-nong-cung-euro-khien-dan-mang-dung-hinh-vi-doi-go-bong-dao-goi-cam',
component: 'page_20210729161301'},
{title: "Đỏ mặt với những hình ảnh khoe thân quá đà của hot girl tham gia Nóng Cùng Euro",
description: "Hằng Habi đang là cái tên được chú ý bởi những hình ảnh nóng bỏng quá đà của mình.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/6/2027121808496674859489985109134781483385345n-1625566481943913953278.jpeg',
alt: "Hằng Habi,nóng cùng euro 2020,nóng cùng euro,Euro 2020,gái xinh,",
category: 'images',
time: '04:12 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:12 PM',
link: '/do-mat-voi-nhung-hinh-anh-khoe-than-qua-da-cua-hot-girl-tham-gia-nong-cung-euro',
component: 'page_20210729161250'},
{title: "Những vũ khí nổi danh từng xuất hiện trong thế giới trò chơi điện tử",
description: "Không chỉ mạnh nhất, bá nhất, chúng còn là những cái tên đình đám được nhiều người chơi game biết tới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/4/photo-1-16254123885261530926387.jpg',
alt: "VŨ KHÍ,GAME,",
category: 'games',
time: '04:12 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:12 PM',
link: '/nhung-vu-khi-noi-danh-tung-xuat-hien-trong-the-gioi-tro-choi-dien-tu',
component: 'page_20210729161240'},
{title: "Nghiện cởi, nữ streamer xinh đẹp cả triệu follow gây sốc khi đổi nghề sang bán ảnh nóng, nội y và đồ chơi 18+",
description: "Cô nàng streamer này cuối cùng cũng nhận ra đam mê và sở trường của mình.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/2/photo-1-1625229141688743020814.jpg',
alt: "GÁI XINH,STREAMER,SAMA,NỮ STREAMER,CỘNG ĐỒNG MẠNG,HOT GIRL,",
category: 'images',
time: '04:12 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:12 PM',
link: '/nghien-coi-nu-streamer-xinh-dep-ca-trieu-follow-gay-soc-khi-doi-nghe-sang-ban-anh-nong-noi-y-va-do-choi-18',
component: 'page_20210729161230'},
{title: "Sướng mắt ngắm mỹ nhân Azur Lane diện áo cắt xẻ tạo dáng khoe đường cong nóng bỏng",
description: "Cùng chiêm ngưỡng màn cosplay mỹ nhân Saint Louis trong Azur Lane hở bạo dưới đây nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/5/photo-1-1625482170584801269582.jpeg',
alt: "AZUR LANE,MỸ NHÂN,GÁI XINH,COSER,COSPLAY,SAINT LOUIS,",
category: 'images',
time: '04:12 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:12 PM',
link: '/suong-mat-ngam-my-nhan-azur-lane-dien-ao-cat-xe-tao-dang-khoe-duong-cong-nong-bong',
component: 'page_20210729161220'},
{title: "Sau loạt ảnh hở bạo gây tranh cãi, \"nữ tướng Natalya\" nhiều lần bị đại gia giấu mặt gạ làm sugar baby",
description: "Được đại gia hứa chu cấp tiền mỗi tháng, cô nàng coser nữ tướng Natalya không ngại “cà khịa” đáp trả “người môi giới”.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/5/photo-1-16254538383201763302749.jpg',
alt: "COSER,LIÊN QUÂN MOBILE,TƯỚNG NATALYA,SUGAR BABY,DADDY,",
category: 'images',
time: '04:12 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:12 PM',
link: '/sau-loat-anh-ho-bao-gay-tranh-cai-nu-tuong-natalya-nhieu-lan-bi-dai-gia-giau-mat-ga-lam-sugar-baby',
component: 'page_20210729161209'},
{title: "Vạch áo khoe “tâm hồn”, dân mạng \"bối rối\" trước trào lưu mới của các nữ streamer",
description: "Khoe vòng một lấp ló, các nữ streamer bước đầu thu hút được sự chú ý của fan hâm mộ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/4/-16254118675411949617776.jpg',
alt: "STREAMER,HỞ BẠO,GÁI XINH,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/vach-ao-khoe-tam-hon-dan-mang-boi-roi-truoc-trao-luu-moi-cua-cac-nu-streamer',
component: 'page_20210729161159'},
{title: "Ngọc Trinh biến trend “con gái miền Tây” thành bữa tiệc khoe body “xôi thịt” ngồn ngộn và thần thái đỉnh từng giây!",
description: "Ơ kìa, Ngọc Trinh đầu tư cả bộ bikini mới cho hợp màu siêu xe để đu trend luôn đây này.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/5/2128928609278650014018717643360140152334194n-16254945848162093186802-16254946051841629841253.jpg',
alt: "Ngọc Trinh,sao khoe body,body sao việt,showbiz Việt,Body Ngọc Trinh,đại chiến bikini,",
category: 'stars',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/ngoc-trinh-bien-trend-con-gai-mien-tay-thanh-bua-tiec-khoe-body-xoi-thit-ngon-ngon-va-than-thai-dinh-tung-giay',
component: 'page_20210729161147'},
{title: "Lâu lắm mới thấy 1 nữ phụ ăn mặc đến là duyên, chẳng chút mờ nhạt mà còn lấn át cả IU mới sợ!",
description: "Kang Han Na đang dần để lại ấn tượng về một nữ phụ sành điệu và nổi bật trong lòng khán giả.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/5/kanghan-nafull144064-16254838544811772504521.jpg',
alt: "Kang Hana,IU,suzy,hyeri,sao Hàn,phim Hàn,drama,Người tình ánh trăng,khởi nghiệp,Bạn Cùng Phòng Của Tôi Là Gumiho,star style,nữ phụ,",
category: 'stars',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/nang-fashion-icon-dang-len-cua-kdrama-phong-cach-hien-dai-nhin-ma-thich-me-bien-luon-iu-thanh-vai-phu-mo-nhat',
component: 'page_20210729161138'},
{title: "Ngọc Trinh đi bơi tại biệt thự 1800m2, Vũ Khắc Tiệp \"tóm gọn\" bằng clip quay vội nhưng không dìm nổi body chuẩn chỉnh",
description: "Người hâm mộ một lần nữa \"mắt tròn mắt dẹt\" với chiếc clip \"sương sương\" ghi lại khoảnh khắc quyến rũ không lối thoát của Ngọc Trinh.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/4/photo-1-16253595832721777633316.png',
alt: "Ngọc Trinh,nữ hoàng nội y Ngọc Trinh,Body Ngọc Trinh,nữ hoàng nội y,Vũ Khắc Tiệp,ông bầu Vũ Khắc Tiệp,ông bầu Venus,sao Việt,đại chiến bikini,Sao diện bikini,",
category: 'stars',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/ngoc-trinh-hot-hai-di-boi-tai-biet-thu-1800m2-vu-khac-tiep-tom-gon-bang-chiec-clip-quay-voi-nhung-khong-dim-noi-body-chuan-tung-centimet',
component: 'page_20210729161128'},
{title: "Tạm dừng làm YouTuber, cô giáo hot girl Trân Trần khiến CĐM bỏng mắt với màn khoe vòng một gợi cảm để \"test phao bơi\"",
description: "Xinh đẹp, gợi cảm, hot girl Trân Trần luôn rất biết cách thu hút sự chú ý của cộng đồng mạng với những bộ ảnh đầy quyến rũ.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/6/30/photo-1-16250600667601909530668.jpg',
alt: "HOT GIRL,CỘNG ĐỒNG MẠNG,GÁI XINH,TRÂN TRẦN,YOUTUBER,",
category: 'images',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/tam-dung-lam-youtuber-co-giao-hot-girl-tran-tran-khien-cdm-bong-mat-voi-man-khoe-vong-mot-goi-cam-de-test-phao-boi',
component: 'page_20210729161118'},
{title: "Streamer Trang Lucy khiến CĐM “chao đảo\" với bộ ảnh “nửa kín nửa hở\", vén áo chạm đến khuôn ngực để tạo dáng gợi cảm",
description: "“Biến hình” quá nhanh, nữ streamer quê Lạng Sơn vấp phải nhiều điều tiếng.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/7/4/1432337828837734323861079012632147955526943n-1625354809507603698046.jpeg',
alt: "STREAMER,TRANG LUCY,HỞ TÁO BẠO,GÁI XINH,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/streamer-trang-lucy-khien-cdm-chao-dao-voi-bo-anh-nua-kin-nua-ho-ven-ao-cham-den-khuon-nguc-de-tao-dang-goi-cam',
component: 'page_20210729161107'},
{title: "Loạt ending khiến người chơi ức chế trong các game đa lựa chọn",
description: "Có những bad-ending trong các tựa game nhiều lựa chọn, và cũng có cả những worst-ending chỉ để người chơi \"tức ói máu\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/4/photo-1-1625370979166885029656.jpg',
alt: "GAME,",
category: 'games',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/loat-ending-khien-nguoi-choi-uc-che-trong-cac-game-da-lua-chon',
component: 'page_20210729161058'},
{title: "Kỷ niệm 6 năm ngày ra mắt, Yua Mikami chia sẻ ký ức khó quên \"Bị bạn diễn bỏ rơi, hắt hủi không đồng ý đóng chung\"",
description: "Những chia sẻ thú vị của Yua Mikami đang thu hút được rất nhiều sự chú ý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/1/photo-1-16251316325511961405685.jpg',
alt: "GÁI XINH,PHIM 18+,CỘNG ĐỒNG MẠNG,AV,PHIM AV,YUA MIKAMI,",
category: 'images',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/ky-niem-6-nam-ngay-ra-mat-yua-mikami-chia-se-ky-uc-kho-quen-bi-ban-dien-bo-roi-hat-hui-khong-dong-y-dong-chung',
component: 'page_20210729161049'},
{title: "4 game bom tấn cực đỉnh sẽ ra mắt trong tháng 7/2021",
description: "Tháng 7 này sẽ chào đón nhiều game bom tấn đỉnh cao.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/4/photo-1-16253820610221131739331.jpg',
alt: "GAME BOM TẤN,THỜI CHIẾN QUỐC,ĐẠI TƯỚNG QUÂN,LIÊN MINH HUYỀN THOẠI,CÔNG THỨC 1,",
category: 'games',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/4-game-bom-tan-cuc-dinh-se-ra-mat-trong-thang-7-2021',
component: 'page_20210729161038'},
{title: "Ngọc Trinh đi bơi tại biệt thự 1800m2, Vũ Khắc Tiệp \"tóm gọn\" bằng clip quay vội nhưng không dìm nổi body chuẩn chỉnh",
description: "Người hâm mộ một lần nữa \"mắt tròn mắt dẹt\" với chiếc clip \"sương sương\" ghi lại khoảnh khắc quyến rũ không lối thoát của Ngọc Trinh.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/4/photo-1-16253595832721777633316.png',
alt: "Ngọc Trinh,nữ hoàng nội y Ngọc Trinh,Body Ngọc Trinh,nữ hoàng nội y,Vũ Khắc Tiệp,ông bầu Vũ Khắc Tiệp,ông bầu Venus,sao Việt,đại chiến bikini,Sao diện bikini,",
category: 'news',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/ngoc-trinh-hot-hai-di-boi-tai-biet-thu-1800m2-vu-khac-tiep-tom-gon-bang-chiec-clip-quay-voi-nhung-khong-dim-noi-body-chuan-tung-centimet',
component: 'page_20210729161028'},
{title: "Loạt Pokémon có nhiều đất diễn ở phần 1 nhưng lại biến mất trong thế hệ sau",
description: "Không phải chúng yếu đi, chỉ đơn giản là các Pokémon ngày nay mạnh hơn mà thôi.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/1/photo-1-16251525412681096304439.png',
alt: "POKÉMON,",
category: 'games',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/loat-pokemon-co-nhieu-dat-dien-o-phan-1-nhung-lai-bien-mat-trong-the-he-sau',
component: 'page_20210729161017'},
{title: "Bắt trend mặc hở ra phố, các hot girl làng game thi nhau “show body\" nóng bỏng nhưng điều nhận về liệu có đáng?",
description: "Với sự nóng bỏng của đường cong, những nàng hot girl thu hút được ánh nhìn của cánh mày râu nhưng đằng sau đó là nhiều nguy cơ phiền phức.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/2/-1625242658517157726884.jpg',
alt: "STREAMER,HOT GIRL,GAME,CỘNG ĐỒNG MẠNG,HỞ BẠO,",
category: 'images',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/bat-trend-mac-ho-ra-pho-cac-hot-girl-lang-game-thi-nhau-show-body-nong-bong-nhung-dieu-nhan-ve-lieu-co-dang',
component: 'page_20210729161007'},
{title: "Monopod – Truyền thuyết về người lùn chỉ có một chân giữa đầy bí ẩn trong sách cổ",
description: "Giống người chỉ có một chân giữa Monopod được nhắc đến nhiều trong sách cổ, vậy giống người này có thật hay không?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/2/sciapode-16252243335561791656975.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,CUỘC SỐNG,LỊCH SỬ,SINH VẬT THẦN THOẠI,",
category: 'news',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/monopod-truyen-thuyet-ve-nguoi-lun-chi-co-mot-chan-giua-day-bi-an-trong-sach-co',
component: 'page_20210729160956'},
{title: "Mặt xinh dáng đẹp, nàng hot girl Việt 2k2 khoe vòng một gợi cảm gây sốt, CĐM nô nức rủ nhau xin info",
description: "Cô nàng hot girl này đang gây xôn xao CĐM bởi vẻ ngoài quá ư gợi cảm của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/30/photo-1-16250268830842063902535.png',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,TRANG PHẠM,",
category: 'images',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/mat-xinh-dang-dep-nang-hot-girl-viet-2k2-khoe-vong-mot-goi-cam-gay-sot-cdm-no-nuc-ru-nhau-xin-info',
component: 'page_20210729160944'},
{title: "Hết bị đồng nghiệp trói, \"sàm sỡ\" trên sóng, nữ streamer gợi cảm lại gây sốt với màn đánh trứng khoe ngực nóng bỏng",
description: "Cô nàng streamer này từ lâu đã luôn là một trong những cái tên đầy tai tiếng tại Hàn Quốc.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/3/photo-1-16253156231762009686295.jpg',
alt: "cao thủ,game thủ,streamer,nữ streamer,tạp chí Maxim,dàn mỹ nhân,hot girl xinh đẹp,hình ảnh phản cảm,trang cá nhân,",
category: 'images',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/het-bi-dong-nghiep-troi-sam-so-tren-song-nu-streamer-goi-cam-lai-gay-sot-voi-man-danh-trung-khoe-nguc-nong-bong',
component: 'page_20210729160930'},
{title: "Một ngọc nữ của showbiz Việt bị đồn vừa \"độ\" vòng 1, cô nhẹ nhàng show luôn ảnh 10 năm trước: Do chị \"khum\" thích mặc hở thôi!",
description: "Nhìn người ta dịu dàng, hay mặc đồ kín đâu có nghĩa là không có body sexy đâu cư dân mạng ơi!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/3/210619900351758119642656451566730756785475n-162531707747769657727.jpeg',
alt: "Midu,midu nâng vòng 1,midu sửa ngực,midu sửa ngực ở đâu,phẫu thuật thẩm mỹ,sao nâng ngực ở đâu,",
category: 'stars',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/mot-ngoc-nu-cua-showbiz-viet-bi-don-vua-do-vong-1-co-nhe-nhang-show-luon-anh-10-nam-truoc-do-chi-khum-thich-mac-ho-thoi',
component: 'page_20210729160923'},
{title: "Những trò chơi đặc biệt được mệnh danh \"sách giáo khoa\" cho cả làng game",
description: "Những trò chơi này đã góp phần xây dựng thể loại game trở nên chuẩn chỉ hơn, qua đó trở thành \"tiêu chuẩn sách giáo khoa\" cho việc làm game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/30/photo-1-16250681605831005129831.jpg',
alt: "GAME,THỂ LOẠI,",
category: 'games',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/nhung-tro-choi-dac-biet-gop-phan-dinh-hinh-ca-the-loai-game',
component: 'page_20210729160913'},
{title: "Số phận những đứa con của thần Loki trong thần thoại Bắc Âu",
description: "Trong thần thoại Bắc Âu, Loki là nhân vật độc đáo và khó hiểu khi vừa giúp đỡ vừa phá hoại các vị thần. Cuộc chiến Ragnarok cũng bắt nguồn từ Loki và những đứa con.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/30/the-children-of-loki-e28094-norse-mythology-explained-16250469800041162828921.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,THẦN THOẠI BẮC ÂU,THOR,LOKI,",
category: 'news',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/so-phan-nhung-dua-con-cua-than-loki-trong-than-thoai-bac-au',
component: 'page_20210729160904'},
{title: "Tuyên bố \"Thả rông không lo bị phạt\", nữ streamer gợi cảm gây sốc khi thêm hẳn một camera chỉ để quay vòng một của mình",
description: "Cô nàng streamer này có màn lách luật không thể ấn tượng hơn đối với người xem.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/29/photo-1-1624938785764399100860.png',
alt: "HOT GIRL,STREAMER,GÁI XINH,KIM YUDI,NỮ STREAMER,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/tuyen-bo-tha-rong-khong-lo-bi-phat-nu-streamer-goi-cam-gay-soc-khi-them-han-mot-camera-chi-de-quay-vong-mot-cua-minh',
component: 'page_20210729160855'},
{title: "“Người yêu mai mối” của ViruSs \"đào\" lại ảnh cũ: Xinh đẹp, thần thái từ nhỏ!",
description: "Xem lại hình cũ, fan được dịp ngắm nhan sắc hot girl lai tây từng được “đẩy thuyền” với ViruSs, thần thái sang chảnh từ khi mới lên 4.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/1/photo-1-16251085597431413737173.jpg',
alt: "HOT GIRL,STREAMER,VIRUSS,XEMESIS,",
category: 'images',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/nguoi-yeu-mai-moi-cua-viruss-dao-lai-anh-cu-xinh-dep-than-thai-tu-nho',
component: 'page_20210729160845'},
{title: "Những tựa game open-world bị ghẻ lạnh một cách oan uổng",
description: "Vì những lý do mà không ai hiểu nổi, các tựa game open-world này đã chìm vào quên lãng, dù chính người chơi của chúng đều khẳng định chất lượng tuyệt vời.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/30/-1625042149365447372853.jpg',
alt: "KHÁM PHÁ,OPEN-WORLD GAME,GIẢI TRÍ,TRÒ CHƠI ĐIỆN TỬ,THẾ GIỚI MỞ,",
category: 'games',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/nhung-tua-game-open-world-bi-ghe-lanh-mot-cach-oan-uong',
component: 'page_20210729160835'},
{title: "Ly kỳ bí ẩn chưa từng biết về Shapeshifter, những kẻ đáng sợ có khả năng biến hình người",
description: "Shapeshifter là những con quái vật duy nhất trải qua nhiều quá trình biến đổi dữ dội tới thế.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/1/photo-1-16251299149081972291856.jpg',
alt: "QUÁI VẬT,BIẾN HÌNH,SHAPESHIFTER,",
category: 'news',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/ly-ky-bi-an-chua-tung-biet-ve-shapeshifter-nhung-ke-dang-so-co-kha-nang-bien-hinh-nguoi',
component: 'page_20210729160826'},
{title: "Bốn người Việt Nam bất ngờ bị Facebook đệ đơn kiện vì lý do khiến nhiều người cảm thấy hả hê",
description: "Vào ngày 29/6 vừa qua, Facebook đưa ra thông báo kiện hai nhóm lừa đảo trực tuyến, đáng chú ý có một nhóm người Việt trong đó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/30/photo-1-1625052441581230996678.jpeg',
alt: "FACEBOOK,MXH,MẠNG XÃ HỘI,SCAM,CỘNG ĐỒNG MẠNG,CĐM,LỪA ĐẢO,",
category: 'news',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/bon-nguoi-viet-nam-bat-ngo-bi-facebook-de-don-kien-vi-ly-do-khien-nhieu-nguoi-cam-thay-ha-he',
component: 'page_20210729160809'},
{title: "Top 10 bộ anime hành động huyền thoại, những tượng đài khó bị xô đổ trong làng phim hoạt hình Nhật Bản",
description: "Bạn đã xem hết những bộ anime huyền thoại này chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/photo-1-16249608398691027219202.jpg',
alt: "ANIME,ANIME SHONEN,ANIME HÀNH ĐỘNG,DRAGON BALL Z,NARUTO,ANIME HUYỀN THOẠI,ANIME HAY,ONE PIECE,",
category: 'games',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/top-10-bo-anime-hanh-dong-huyen-thoai-nhung-tuong-dai-kho-bi-xo-do-trong-lang-phim-hoat-hinh-nhat-ban',
component: 'page_20210729160800'},
{title: "Xinh như hot girl, cặp chị em sinh đôi gây sốc khi tuyển bạn trai chung, tự quảng cáo bản thân là \"mua 1 tặng 1\"",
description: "Hai cô nàng hot girl này đang khiến cộng đồng mạng cực kỳ bất ngờ trước tuyên bố của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/28/photo-1-1624852011052764043997.jpg',
alt: "HOT GIRL,CỘNG ĐỒNG MẠNG,GÁI XINH,",
category: 'images',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/xinh-nhu-hot-girl-cap-chi-em-sinh-doi-gay-soc-khi-tuyen-ban-trai-chung-tu-quang-cao-ban-than-la-mua-1-tang-1',
component: 'page_20210729160750'},
{title: "Những vũ khí vô hại nhất trong thế giới game, cẩn thận khi dùng không còn mỗi cái nịt",
description: "Trò chơi điện tử thỉnh thoảng lại đẻ ra một vài những thứ vũ khí không đâu, và đây là một vài thứ ăn hại nhất trong số chúng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/29/-1624984287291322107325.jpg',
alt: "GAME,VŨ KHÍ,VÔ DỤNG,",
category: 'games',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/nhung-vu-khi-vo-hai-nhat-trong-the-gioi-game-can-than-khi-dung-khong-con-moi-cai-nit',
component: 'page_20210729160739'},
{title: "Dàn mỹ nữ làng game khoe body giữa mùa giãn cách, không phải cơ bụng phẳng lì thì cũng vòng ba cực quyến rũ",
description: "Những bóng hồng làng game thi nhau khoe thân hình \"bốc lửa\" mùa giãn cách khiến ai nấy đều ghen tị.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/28/lnd-16274550923762104140693.jpg',
alt: "Tập thể dục,khoe thân hình,Chúc Anh,Linh Ngọc Đàm,Thảo Trang,Ngân Sát Thủ,game thủ,streamer,MC,",
category: 'images',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/dan-my-nu-lang-game-khoe-body-giua-mua-gian-cach-khong-phai-co-bung-phang-li-thi-cung-vong-ba-cuc-quyen-ru',
component: 'page_20210729160727'},
{title: "Buổi tối Hà Nội vắng hơn cả Tết: Phố xá nơi đâu cũng \"thinh lặng\", người dân ở nhà đóng cửa chống dịch",
description: "Trong buổi tối đầu tiên thực hiện theo Công điện số 15/CĐ-UBND, Hà Nội đã trở nên vắng vẻ hơn bao giờ hết.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/20/dsc4445-16267155740041692750510.jpg',
alt: "Hà Nội,quang cảnh Hà Nội,hà nội vắng vẻ,Nhiều chuỗi lây nhiễm Covid-19 tại Hà Nội,",
category: 'life',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/buoi-toi-ha-noi-vang-hon-ca-tet-pho-xa-noi-dau-cung-thinh-lang-nguoi-dan-o-nha-dong-cua-chong-dich',
component: 'page_20210729160716'},
{title: "Trai phố cổ chi 150 triệu cải tạo sân thượng 26m2 thành \"mini penthouse\", góc nào cũng sang xịn nhưng mê nhất là bồn tắm cực chill",
description: "Đây vốn là sân thượng tầng 6 của một căn nhà trong khu phố cổ Hà Nội.",
src: 'https://kenh14cdn.com/zoom/90_60/203336854389633024/2021/7/19/photo1626628125797-16266281259421044606843.png',
alt: "hệ thống điện nước,nhà vệ sinh,trang trí bếp,Cải tạo nhà,thiết kế nhà,Sân thượng,bồn tắm,house n home,",
category: 'life',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/trai-pho-co-chi-150-trieu-cai-tao-san-thuong-26m2-thanh-mini-penthouse-goc-nao-cung-sang-xin-nhung-me-nhat-la-bon-tam-cuc-chill',
component: 'page_20210729160707'},
{title: "Giải mã: Tại sao nhiều khách sạn lại lắp phòng tắm kính trong suốt?",
description: "Thiết kế này chắc chắn được các cặp đôi ưa thích. Tuy nhiên, những lí do khác cũng vô cùng thuyết phục.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/19/k2-16266627360011266503501.jpeg',
alt: "khách sạn,kính trong suốt,phòng tắm,phòng tắm khách sạn,Hổng ngờ luôn á!,",
category: 'life',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/giai-ma-tai-sao-nhieu-khach-san-lai-lap-phong-tam-kinh-trong-suot',
component: 'page_20210729160657'},
]
export const dataContent12=[{title: "Steam Deck: 10 triệu mua máy cầm tay chiến mọi loại game bom tấn, liệu anh em có tin vào sản phẩm thần kì này không?",
description: "Mới đây Valve đã nhá hàng sản phẩm máy chơi game “cây nhà lá vườn” do chính công ty sản xuất, đáp ứng mọi nhu cầu của game thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/17/photo-1-16264933757941782750761.jpg',
alt: "GAME,GAME THỦ,TIN TỨC GAME,STEAM,",
category: 'games',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/steam-deck-10-trieu-mua-may-cam-tay-chien-moi-loai-game-bom-tan-lieu-anh-em-co-tin-vao-san-pham-than-ki-nay-khong',
component: 'page_20210729160628'},
{title: "Mê mẩn những chiếc \"mặt nạ máy móc\" của dàn nhân vật anime, cảm giác vừa ngầu vừa an toàn",
description: "Đeo mặt nạ kín và dầy cộp như này thì khỏi lo virus làm phiền nữa nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/17/photo-1-1626495933255607981801.jpg',
alt: "MẶT NẠ,FAN ART,NHÂN VẬT ANIME,MẶT NẠ MÁY MÓC,",
category: 'games',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/me-man-nhung-chiec-mat-na-may-moc-cua-dan-nhan-vat-anime-cam-giac-vua-ngau-vua-an-toan',
component: 'page_20210729160619'},
{title: "Có thể cài Windows 11, game crack và cả Epic Games Store trên Steam Deck",
description: "Steam Deck sẽ tạo ra một cuộc cách mạng mới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-1626587589951893523105.jpg',
alt: "MÁY CHƠI GAME,HỆ ĐIỀU HÀNH,HỆ MÁY CONSOLE,NINTENDO SWITCH,STEAM DECK,",
category: 'tech',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/co-the-cai-windows-11-game-crack-va-ca-epic-games-store-tren-steam-deck',
component: 'page_20210729160609'},
{title: "Tân binh 18+ hé lộ định bỏ nghề sau 2 tháng, được fan ủng hộ nhiều quá nên quyết định \"quay xe\"",
description: "Ban đầu, Nozomi Ishihara có ý định đóng càng nhiều phim càng tốt, sau đó sẽ \"nghỉ hưu\" non chỉ sau 2 tháng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-16265969479391177303039.jpeg',
alt: "IDOL,NOZOMI ISHIHARA,",
category: 'images',
time: '04:05 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:05 PM',
link: '/tan-binh-18-he-lo-dinh-bo-nghe-sau-2-thang-duoc-fan-ung-ho-nhieu-qua-nen-quyet-dinh-quay-xe',
component: 'page_20210729160559'},
{title: "Giảm cân thành công để sở hữu thân hình nóng bỏng, cô gái bỗng hóa thành nữ YouTuber nổi tiếng",
description: "Nỗ lực giảm cân của nữ YouTuber này thật đáng để người ta học hỏi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-162658830079429725748.jpg',
alt: "GÁI XINH,HOT GIRL,YOUTUBER,NỮ YOUTUBER,",
category: 'images',
time: '04:05 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:05 PM',
link: '/giam-can-thanh-cong-de-so-huu-than-hinh-nong-bong-co-gai-bong-hoa-thanh-nu-youtuber-noi-tieng',
component: 'page_20210729160549'},
{title: "Bộ giáp Rescue của vợ Iron Man mạnh mẽ tới mức nào trong MCU?",
description: "Bạn có tò mò về sức mạnh của bộ giáp mà Tony Stark chế tạo cho Pepper Potts không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-1626576566449109487457.jpg',
alt: "TONY STARK,IRON MAN,MARVEL,RESCUE,PEPPER POTTS,BỘ GIÁP RESCUE,",
category: 'tech',
time: '04:05 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:05 PM',
link: '/bo-giap-rescue-cua-vo-iron-man-manh-me-toi-muc-nao-trong-mcu',
component: 'page_20210729160539'},
{title: "Thời nào còn là \"hot girl tiểu học\", cô gái xinh đẹp lột xác đầy nóng bỏng khiến CĐM ngỡ ngàng",
description: "Hình ảnh của cô nàng hot girl sau khi trưởng thành đang khiến cho rất nhiều người phải bất ngờ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/15/photo-1-16263272773742117286505.jpg',
alt: "GÁI XINH,CỘNG ĐỒNG MẠNG,HOT GIRL,TRẦN HUYỀN CHÂU,",
category: 'images',
time: '04:05 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:05 PM',
link: '/thoi-nao-con-la-hot-girl-tieu-hoc-co-gai-xinh-dep-lot-xac-day-nong-bong-khien-cdm-ngo-ngang',
component: 'page_20210729160529'},
{title: "Lưu Thi Thi 17 tuổi đẹp tới nhường nào: Gương mặt bầu bĩnh siêu dễ thương, tóc bết khóc sụt sùi mà visual vẫn ngút ngàn",
description: "Loạt ảnh cũ của Lưu Thi Thi mới được \"đào mộ\" khiến dân tình phải xuýt xoa trước nét đẹp kiều diễm của cô nàng.",
src: 'https://kenh14cdn.com/203336854389633024/2021/6/29/photo-1-16249822963361057313185.gif',
alt: "Lưu Thi Thi,sao Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,mỹ nhân,",
category: 'stars',
time: '10:05 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:05 PM',
link: '/luu-thi-thi-17-tuoi-dep-toi-nhuong-nao-guong-mat-bau-binh-sieu-de-thuong-toc-bet-khoc-sut-sui-ma-visual-van-ngut-ngan',
component: 'page_20210629220538'},
{title: "Yua Mikami hào hứng khoe tác phẩm mới đánh dấu cột mốc trong nghề, tiết lộ kiệt sức sau 3 ngày quay ròng rã",
description: "Những hình ảnh mới nhất của Yua Mikami đang khiến cho không ít fan phải cảm thấy háo hức.",
src: 'https://huyetnguyet.com/static/media/20210629220518--10-124528.07498b02.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,YUA MIKAMI,AV,PHIM AV,PHIM 18+,",
category: 'images',
time: '10:05 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:05 PM',
link: '/yua-mikami-hao-hung-khoe-tac-pham-moi-danh-dau-cot-moc-trong-nghe-tiet-lo-kiet-suc-sau-3-ngay-quay-rong-ra',
component: 'page_20210629220518'},
{title: "Tuyển bạn trai trên sóng, nữ streamer gây sốc khi chỉ cần người \"khỏe mạnh\", đưa ra 20 tiêu chí đầy nhạy cảm",
description: "Cô nàng streamer xinh đẹp khiến cho không ít người mừng thầm nhưng rồi cũng hụt hẫng ngay sau đó.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629220457--15-651279.jpg',
alt: "HOT GIRL,GÁI XINH,STREAMER,CỘNG ĐỒNG MẠNG,NỮ STREAMER,SOO JI-YEON,",
category: 'images',
time: '10:04 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:04 PM',
link: '/tuyen-ban-trai-tren-song-nu-streamer-gay-soc-khi-chi-can-nguoi-khoe-manh-dua-ra-20-tieu-chi-day-nhay-cam',
component: 'page_20210629220457'},
{title: "Ngọc Trinh diện đồ Prada tạo dáng điệu đà, ai dè lộ ngay chỗ \"ứ ừ\" khiến ai nấy ái ngại",
description: "Số trời đã định, dù có kín cổng cao tường đến mấy mà không \"dính đạn\" thì không phải Ngọc Trinh?",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629220429--10-642707.jpg',
alt: "fashion,thời trang,sao lộ hàng,tai tiếng,nữ hoàng nội y,yêu nữ hàng hiệu,Ngọc Trinh,Prada,star style,sao đỏ thời trang,",
category: 'images',
time: '10:04 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:04 PM',
link: '/ngoc-trinh-dien-do-prada-tao-dang-dieu-da-ai-de-lo-ngay-cho-u-u-khien-ai-nay-ai-ngai',
component: 'page_20210629220429'},
{title: "Sự thật về Nobita: \"Anh chàng hậu đậu\" có thể ghi tên vào \"kỷ lục Guinness\" nhờ khả năng đặc biệt này",
description: "Có thể bạn không nhận ra, Nobita có một khả năng đặc biệt khiến nhiều người phải kinh ngạc.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/25/anh-1-16246037787701447091743.jpg',
alt: "NOBITA,DOAREMON,MANGA NHẬT BẢN,SỰ THẬT VỀ NOBITA,",
category: 'games',
time: '10:04 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:04 PM',
link: '/su-that-ve-nobita-anh-chang-hau-dau-co-the-ghi-ten-vao-ky-luc-guinness-nho-kha-nang-dac-biet-nay',
component: 'page_20210629220415'},
{title: "Thần lừa lọc Loki đã bị trừng phạt thảm khốc thế nào sau khi sát hại thần quang minh Balder trong thần thoại Bắc Âu?",
description: "Loki bị trói lên một phiến đá bằng bộ lòng của con trai hắn, ngày qua ngày hắn chịu sự dày vò khổ sở để trả giá cho tội lỗi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/25/processedsamloki-1624593509307320096817.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI BẮC ÂU,THẦN THOẠI,LOKI,THẦN LỪA LỌC LOKI,",
category: 'games',
time: '10:04 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:04 PM',
link: '/than-lua-loc-loki-da-bi-trung-phat-tham-khoc-the-nao-sau-khi-sat-hai-than-quang-minh-balder-trong-than-thoai-bac-au',
component: 'page_20210629220405'},
{title: "Hiker Games chia sẻ những hình ảnh đầu tiên về Quân đội Việt Nam trong 300475",
description: "300475 đang phát triển rất tốt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/photo-1-1624943482294517114223.jpg',
alt: "HÌNH ẢNH ĐẦU TIÊN,QUÂN ĐỘI VIỆT NAM,NHÀ PHÁT TRIỂN,GAME THUẦN VIỆT,",
category: 'games',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/hiker-games-chia-se-nhung-hinh-anh-dau-tien-ve-quan-doi-viet-nam-trong-300475',
component: 'page_20210629220356'},
{title: "Xinh đẹp và gợi cảm, nữ streamer Việt bất ngờ gặp rắc rối, bị \"ghép mặt\" vào ảnh nóng bỏng",
description: "Màn ghép ảnh không tì vết của cô nàng streamer này đã khiến cho khá nhiều người phải trầm trồ vì sự gợi cảm.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/6/28/screenshot854-1621392944405530986247-1624854174009612651548.png',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,STREAMER,NỮ STREAMER,QUỲNH ALEE,",
category: 'stars',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/xinh-dep-va-goi-cam-nu-streamer-viet-bat-ngo-gap-rac-roi-bi-ghep-mat-vao-anh-nong-bong',
component: 'page_20210629220347'},
{title: "Tiểu mỹ nhân 18+ tâm tình chuyện công việc, so sánh bạn trai và đồng nghiệp có gì khác biệt",
description: "Yuna Ogura đã chia sẻ về việc sự khác biệt giữa đồng nghiệp và bạn trai theo yêu cầu của người xem.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629220331--10-49537.jpeg',
alt: "YUNA OGURA,IDOL,",
category: 'images',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/tieu-my-nhan-18-tam-tinh-chuyen-cong-viec-so-sanh-ban-trai-va-dong-nghiep-co-gi-khac-biet',
component: 'page_20210629220331'},
{title: "Hot girl phim 18+ gây sốc khi báo hiệu ngày tàn của ngành công nghiệp, khuyên các đàn em nên rút lui, đừng ảo tưởng được như Yua Mikami",
description: "Tuyên bố của một hot girl trong ngành cho thấy chỉ những cái tên top đầu như Yua Mikami mới \"sinh tồn\" dễ dàng trong hai tháng qua.",
src: 'https://huyetnguyet.com/static/media/20210629220317--10-037865.9e99d70a.jpg',
alt: "HOT GIRL,GÁI XINH,YUA MIKAMI,CỘNG ĐỒNG MẠNG,",
category: 'images',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/hot-girl-phim-18-gay-soc-khi-bao-hieu-ngay-tan-cua-nganh-cong-nghiep-khuyen-cac-dan-em-nen-rut-lui-dung-ao-tuong-duoc-nhu-yua-mikami',
component: 'page_20210629220317'},
{title: "Thích thú với loạt meme Aphrodite \"nâng ngực\" trong anime Record Of Ragnarok, càng ngắm càng mát vào ngày hè",
description: "Cùng đến với loạt ảnh meme của nữ thần sắc đẹp Aphrodite trong anime Shuumatsu No Valkyrie - Record Of Ragnarok nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/aou1-162494151203817476037.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,GAME ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/thich-thu-voi-loat-meme-aphrodite-nang-nguc-trong-anime-record-of-ragnarok-cang-ngam-cang-mat-vao-ngay-he',
component: 'page_20210629220308'},
{title: "Bị lấy hình ảnh ra để quảng cáo thẩm mỹ, hot girl Việt gợi cảm bức xúc lên tiếng, chia sẻ chưa từng dao kéo bao giờ",
description: "Cô nàng hot girl tỏ ra rất bức xúc trước sự việc lần này.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629220247--17-42378.jpeg',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,LÊ PHƯƠNG ANH,",
category: 'images',
time: '10:02 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:02 PM',
link: '/bi-lay-hinh-anh-ra-de-quang-cao-tham-my-hot-girl-viet-goi-cam-buc-xuc-len-tieng-chia-se-chua-tung-dao-keo-bao-gio',
component: 'page_20210629220247'},
{title: "Đập hộp mainboard ASUS Maximus XIII Extreme: 25 triệu, hàng khủng siêu cấp vô địch",
description: "Chỉ một chiếc mainboard đã có giá cao hơn cả một bộ case hoàn chỉnh của nhiều anh em mất rồi...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/photo-1-162497655243056236696.jpg',
alt: "MAINBOARD,BO MẠCH CHỦ,ASUS,ASUS MAXIMUS XIII EXTREME,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '10:02 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:02 PM',
link: '/dap-hop-mainboard-asus-maximus-xiii-extreme-25-trieu-hang-khung-sieu-cap-vo-dich',
component: 'page_20210629220238'},
{title: "Kiếm đủ tiền bạc, cô đào 18+ làm đơn yêu cầu xóa hết phim cũ, sống đời thanh thản",
description: "Rika Hoshimi mới đây đã làm đơn yêu cầu xóa hết các bộ phim cũ cô từng tham gia, qua đó gạt bỏ đi quá khứ diễn viên 18+ của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/29/photo-1-1624953658246399843412.jpeg',
alt: "IDOL,RIKA HOSHIMI,",
category: 'images',
time: '10:00 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:00 PM',
link: '/kiem-du-tien-bac-co-dao-18-lam-don-yeu-cau-xoa-het-phim-cu-song-doi-thanh-than',
component: 'page_20210629220029'},
{title: "Thả rông vòng một trên sóng, nữ streamer \"lộ hàng\" đáng tiếc, vội trần tình \"Thứ bạn thấy không giống như bạn nghĩ đâu\"",
description: "Cô nàng streamer cho rằng thực tế khác xa so với những gì mà mọi người tưởng tượng.",
src: 'https://huyetnguyet.com/static/media/20210629220008--10-697893.ab4e40a7.jpg',
alt: "HOT GIRL,CỘNG ĐỒNG MẠNG,STREAMER,GÁI XINH,NỮ STREAMER,HARU,",
category: 'images',
time: '10:00 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:00 PM',
link: '/tha-rong-vong-mot-tren-song-nu-streamer-lo-hang-dang-tiec-voi-tran-tinh-thu-ban-thay-khong-giong-nhu-ban-nghi-dau',
component: 'page_20210629220008'},
{title: "Những con trùm đòi hỏi game thủ \"dùng não\" mới có thể vượt qua",
description: "Không phải lúc nào bạo lực cũng sẽ là phương án xử lý chính xác cho được.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/28/photo-1-1624813965384935059512.jpg',
alt: "GAME,TRÙM,BOSS,",
category: 'games',
time: '10:00 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:00 PM',
link: '/nhung-con-trum-doi-hoi-game-thu-dung-nao-moi-co-the-vuot-qua',
component: 'page_20210629220000'},
{title: "Khánh Vy diện bra trông rất xinh nhưng soi kỹ là thấy ngay 1 điểm gây \"bất bình\"",
description: "Bình thường Khánh Vy chọn đồ rất ổn, thế còn nội y thì đã thực sự ổn chưa?",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215924--10-572464.jpg',
alt: "Khánh Vy,nội y,MC Khánh Vy,hot girl 7 thứ tiếng,không mặc xấu,",
category: 'images',
time: '09:59 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:59 PM',
link: '/khanh-vy-dien-bra-trong-rat-xinh-nhung-soi-ky-la-thay-ngay-1-diem-gay-bat-binh',
component: 'page_20210629215924'},
{title: "Tranh cãi sao Hollywood lên sân khấu Victorias Secret: Bella Hadid gặp tình cũ, Ariana méo mặt vì thiên thần, Taylor trả đũa nàng mẫu vì bị chê",
description: "Ngoài sự nóng bỏng của dàn thiên thần chân dài, khán giả còn vô cùng chú ý đến những ngôi sao Hollywood đình đám góp mặt trong show diễn của Victorias Secret.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215845--11-613621.jpg',
alt: "gigi hadid,sao us uk,sao hollywood,Victorias secret,bella hadid,taylor swift,Ariana Grande,The Weeknd,irina shayk,Kanye West,",
category: 'stars',
time: '09:58 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:58 PM',
link: '/tranh-cai-sao-hollywood-len-san-khau-victorias-secret-bella-hadid-gap-tinh-cu-ariana-meo-mat-vi-thien-than-taylor-tra-dua-nang-mau-vi-bi-che',
component: 'page_20210629215845'},
{title: "Rơi camera ở vị trí hiểm hóc, nữ streamer xinh đẹp \"vô tình\" khoe trọn vẹn vòng một trước người xem",
description: "Tới cỡ này thì cũng chẳng biết đây là trường hợp vô tình hay cố ý của cô nàng streamer này nữa.",
src: 'https://huyetnguyet.com/static/media/20210629215629--11-556719.cfc278c2.jpg',
alt: "hình ảnh nóng bỏng,thay đổi phong cách,khoe vòng một,streamer,game thủ,cao thủ,hot girl,nữ streamer,",
category: 'images',
time: '09:56 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:56 PM',
link: '/roi-camera-o-vi-tri-hiem-hoc-nu-streamer-xinh-dep-vo-tinh-khoe-tron-ven-vong-mot-truoc-nguoi-xem',
component: 'page_20210629215629'},
{title: "Tự sự của một người làm ngành F&B: \"Sạt nghiệp\" vì nhiều khách vô ý thức, ra quán cà phê gọi cốc nước vài chục ngàn đồng rồi thản nhiên \"đổ bê tông\" tới nửa ngày trời",
description: "Nếu coi quán cà phê là nơi làm việc miễn phí với wifi \"căng đét\" và điều hoà mát lạnh, gọi ly nước vài chục ngàn rồi \"cắm rễ\" tại quán thì hàng quán chúng tôi chỉ có nước... sạt nghiệp!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/29/a3-1624976235560991831279.jpeg',
alt: "quán cà phê,văn hoá,đi cà phê,văn hoá cà phê,chủ quán,kinh doanh,ngành F&B,",
category: 'life',
time: '09:56 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:56 PM',
link: '/tu-su-cua-mot-nguoi-lam-nganh-fb-sat-nghiep-vi-nhieu-khach-vo-y-thuc-ra-quan-ca-phe-goi-coc-nuoc-vai-chuc-ngan-dong-roi-than-nhien-do-be-tong-toi-nua-ngay-troi',
component: 'page_20210629215613'},
{title: "Bị đề nghị cởi cúc áo lấy tiền donate, nữ streamer xinh đẹp có hành động đầy bất ngờ khiến fan hưởng ứng nhiệt liệt",
description: "Cô nàng cũng đang được coi là nữ streamer sở hữu mặt mộc đẹp nhất ở thời điểm hiện tại.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215549--16-658111.gif',
alt: "GÁI XINH,STREAMER,HOT GIRL,CỘNG ĐỒNG MẠNG,NỮ STREAMER,",
category: 'images',
time: '09:55 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:55 PM',
link: '/bi-de-nghi-coi-cuc-ao-lay-tien-donate-nu-streamer-xinh-dep-co-hanh-dong-day-bat-ngo-khien-fan-huong-ung-nhiet-liet',
component: 'page_20210629215549'},
{title: "Nhiều lần xuất hiện cạnh chị em streamer Quỳnh Alee và Gấm Kami, nàng hot girl lạ gây chú ý",
description: "Cô nàng gây chú ý bởi nhan sắc như búp bê, nhiều người tò mò không biết đây có phải nữ streamer mới nổi?",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215524--11-35378.jpeg',
alt: "STREAMER,QUỲNH ALEE,GẤM KAMI,HOT GIRL,GÁI XINH,",
category: 'stars',
time: '09:55 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:55 PM',
link: '/nhieu-lan-xuat-hien-canh-chi-em-streamer-quynh-alee-va-gam-kami-nang-hot-girl-la-gay-chu-y',
component: 'page_20210629215524'},
{title: "Tìm hiểu vì sao anh chàng đầu trọc Krillin ngày càng \"out meta\" trong Dragon Ball?",
description: "Là một trong những nhân vật xuất hiện lâu đời trong Dragon Ball, tuy nhiên Krillin ngày càng trở nên yếu đuối và \"vô dụng\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/ki1-16247679904461224239578.jpg',
alt: "NHÂN VẬT DRAGON BALL,MANGA DRAGON BALL,ANIME DRAGON BALL,KRILLIN,SON GOKU,ANDROID 18,",
category: 'games',
time: '09:55 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:55 PM',
link: '/tim-hieu-vi-sao-anh-chang-dau-troc-krillin-ngay-cang-out-meta-trong-dragon-ball',
component: 'page_20210629215515'},
{title: "Top 10 bộ anime trở nên đặc biệt bởi có nhân vật chính cô đơn, phớt lờ xã hội",
description: "Liệu bạn có yêu thích những nhân vật chính anime kiểu này không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/photo-1-1624774703038458926019.jpg',
alt: "KUROKI TOMOKO,ANIME,NHÂN VẬT CHÍNH CÔ ĐƠN,SAIKI KUSUO NO PSI-NAN,SAIKI KUSUO,HANDA-KUN,GOBLIN SLAYER,HYOUKA,",
category: 'games',
time: '09:55 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:55 PM',
link: '/top-10-bo-anime-tro-nen-dac-biet-boi-co-nhan-vat-chinh-co-don-phot-lo-xa-hoi',
component: 'page_20210629215506'},
{title: "Dragon Ball: Những điều ước có sức ảnh hưởng lớn nhất series, đặc biệt số 4 đã thay đổi toàn bộ vũ trụ",
description: "Trong series Dragon Ball có rất nhiều điều ước đã được thực hiện. Tuy nhiên, 4 điều này có thể là tác động mạnh nhất thay đổi toàn bộ cốt truyện.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/anh-1-16247820993851392773325.jpg',
alt: "DRAGON BALL,ĐIỀU ƯỚC,VŨ TRỤ,THẦN HỦY DIỆT,RỒNG THẦN,GIẢI ĐẤU QUYỀN LỰC,",
category: 'games',
time: '09:54 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:54 PM',
link: '/dragon-ball-nhung-dieu-uoc-co-suc-anh-huong-lon-nhat-series-dac-biet-so-4-da-thay-doi-toan-bo-vu-tru',
component: 'page_20210629215457'},
{title: "Top 5 tân binh anime hấp dẫn nhất mùa hè 2021, cái tên nào khiến bạn ấn tượng nhất?",
description: "5 cái tên sau đây được xem là những tân binh anime đáng xem nhất hè 2021.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/27/and1-16247787631831417625429.jpg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '09:54 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:54 PM',
link: '/top-5-tan-binh-anime-hap-dan-nhat-mua-he-2021-cai-ten-nao-khien-ban-an-tuong-nhat',
component: 'page_20210629215449'},
{title: "Top 10 tựa game open-world hay nhất dành cho điện thoại Android do game thủ bình chọn (P.1)",
description: "Những tựa game này được tạo ra cho người dùng Android với trải nghiệm cực kỳ mới lạ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/24/-16245505692101933197214.jpg',
alt: "KHÁM PHÁ,GAME,GAME ONLINE,OPEN-WORLD GAME,GAME THẾ GIỚI MỞ,ANDROID,",
category: 'games',
time: '09:54 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:54 PM',
link: '/top-10-tua-game-open-world-hay-nhat-danh-cho-dien-thoai-android-do-game-thu-binh-chon-p1',
component: 'page_20210629215439'},
{title: "Hồ bơi nhà Vũ Khắc Tiệp hoá sàn diễn của Ngọc Trinh và “MC 3000 chữ”: Body hừng hực, nữ hoàng nội y có lép vế vì MC của VTV?",
description: "Những khoảnh khắc khoe thân của Ngọc Trinh và MC Thanh Thanh Huyền mau chóng nhận được nhiều lượt \"thả tim\" từ người hâm mộ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215412--10-48187.jpeg',
alt: "sao Việt,showbiz Việt,Ngọc Trinh,Vũ Khắc Tiệp,nữ hoàng nội y,body sao,venus,MC Thanh Thanh Huyền,",
category: 'images',
time: '09:54 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:54 PM',
link: '/ho-boi-nha-vu-khac-tiep-hoa-san-dien-cua-ngoc-trinh-va-mc-3000-chu-body-hung-huc-nu-hoang-noi-y-co-lep-ve-vi-mc-cua-vtv',
component: 'page_20210629215412'},
{title: "Bước nhẹ ra vườn hồng ở biệt thự 21 tỷ, Lọ Lem có ngay bộ ảnh sống ảo, “sốc visual” nhất là góc nghiêng đẹp nức nở!",
description: "Biết là khen mãi rồi nhưng MC Quyền Linh có cô con gái Lọ Lem “đáng đồng tiền bát gạo” thật sự!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215258--11-099671.jpg',
alt: "MC Quyền Linh,sao Việt,Vbiz,showbiz Việt,Con gái MC Quyền Linh,bé lọ lem,con gái Quyền Linh,Lọ Lem con Quyền Linh,mỹ nhân gen z,",
category: 'stars',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/buoc-nhe-ra-vuon-hong-o-biet-thu-21-ty-lo-lem-co-ngay-bo-anh-song-ao-soc-visual-nhat-la-goc-nghieng-dep-nuc-no',
component: 'page_20210629215258'},
{title: "“Khoe” nét lai Tây từ bé, Xoài Non bị anti-fan chọc tức đến “uất nghẹn”",
description: "Xoài Non bị dân mạng nghi ngờ can thiệp dao kéo từ khi mới 14 tuổi.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215235--10-81303.jpeg',
alt: "XOÀI NON,XEMESIS,CỘNG ĐỒNG MẠNG,PHẪU THUẬT,",
category: 'stars',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/khoe-net-lai-tay-tu-be-xoai-non-bi-anti-fan-choc-tuc-den-uat-nghen',
component: 'page_20210629215235'},
{title: "Hướng dẫn cách kiểm tra xem máy tính của bạn có cài được Windows 11 hay không",
description: "Windows 11 sẽ sớm ra mắt.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/26/untitled-16246844690631257928528.png',
alt: "GÃ KHỔNG LỒ,CÀI ĐẶT WINDOWS,WINDOWS 11,WINDOWS 10,",
category: 'tech',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/huong-dan-cach-kiem-tra-xem-may-tinh-cua-ban-co-cai-duoc-windows-11-hay-khong',
component: 'page_20210629215227'},
{title: "Top 4 anime tệ nhất mùa xuân 2021, toàn tên tuổi nổi tiếng nhưng chất lượng thì như hạch",
description: "Ngoài những thành công của Tokyo Revengers hay Cạo Râu Xong Tôi Nhặt Gái Về Nhà thì 4 anime sau đây được xem là gây ra thất vọng nhất mùa xuân 2021",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/26/anp1-16246840029101637187802.jpeg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,REVIEW ANIME,ĐÁNH GIÁ ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/top-4-anime-te-nhat-mua-xuan-2021-toan-ten-tuoi-noi-tieng-nhung-chat-luong-thi-nhu-hach',
component: 'page_20210629215217'},
{title: "Lộ cách thức bẻ khóa iPhone của cỗ máy khét tiếng trong giới bảo mật",
description: "Nổi danh trong giới bảo mật vì khả năng bẻ khóa iPhone, đây là lần đầu cách thức hoạt động của GrayKey lộ diện.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/26/photo-1-16247074555841400998645.png',
alt: "IPHONE,BẢO MẬT,BẺ KHÓA,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/lo-cach-thuc-be-khoa-iphone-cua-co-may-khet-tieng-trong-gioi-bao-mat',
component: 'page_20210629215205'},
{title: "Cựu tiếp viên hàng không lấy chồng CEO mang bầu vẫn khoe ảnh cực cháy, thế này ai làm lại đây!",
description: "Sắc vóc như thế này có mê không cơ chứ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215143--11-654452.jpg',
alt: "vũ ngọc châm,Vũ Ngọc Châm mang thai,CEO,chồng CEO,vũ ngọc châm và bạn trai,",
category: 'stars',
time: '09:51 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:51 PM',
link: '/cuu-tiep-vien-hang-khong-lay-chong-ceo-mang-bau-van-khoe-anh-cuc-chay-the-nay-ai-lam-lai-day',
component: 'page_20210629215143'},
{title: "Sinh viên mới ra trường: Kiếm mức lương 20 - 30 triệu/tháng thế nào?",
description: "Hóa ra để kiếm hàng chục triệu/tháng khi mới ra trường không khó như bạn nghĩ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/20/photo-1472220625704-91e1462799b2-162417118059132874823.jpg',
alt: "đáp ứng nhu cầu,chia sẻ câu chuyện,Tuyển dụng,bí quyết xin việc,Công việc,sinh viên,sinh viên mới ra trường,bí quyết thu nhập cao,",
category: 'news',
time: '09:51 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:51 PM',
link: '/sinh-vien-moi-ra-truong-kiem-muc-luong-20-30-trieu-thang-the-nao',
component: 'page_20210629215134'},
{title: "Ở nhà rảnh rang, Khánh Vy cũng có ảnh OOTD đẹp \"lịm tim\" chỉ bằng công thức chụp siêu đơn giản",
description: "Tham khảo ngay loạt gợi ý hay ho này từ Khánh Vy, đảm bảo bạn ngồi ở nhà thở thôi cũng có ảnh đẹp!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215106--10-316484.jpg',
alt: "star style,fashion,thời trang,làm đẹp,Makeup,sống ảo,selfie,chụp ảnh,Khánh Vy,show your best contest,Nhà Lên Đèn, Em Lên Đồ,Vũ trụ ở nhà không la cà,",
category: 'stars',
time: '09:51 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:51 PM',
link: '/o-nha-ranh-rang-khanh-vy-cung-co-anh-ootd-dep-lim-tim-chi-bang-cong-thuc-chup-sieu-don-gian',
component: 'page_20210629215106'},
{title: "\"Thánh ké fame\" Triệu Lộ Tư bùng nổ visual trên livestream: Biểu cảm, góc nghiêng đỉnh cao, bảo sao mọi lỗi lầm đều được tha thứ",
description: "Nhìn vào loạt khung hình xuất sắc của Triệu Lộ Tư, netizen xứ Trung càng phải công nhận cô nàng sở hữu nhan sắc ấn tượng.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215040--11-089240.gif',
alt: "Triệu Lộ Tư,sao Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:50 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:50 PM',
link: '/thanh-ke-fame-trieu-lo-tu-bung-no-visual-tren-livestream-bieu-cam-goc-nghieng-dinh-cao-bao-sao-moi-loi-lam-deu-duoc-tha-thu',
component: 'page_20210629215040'},
{title: "Ôn thi trên sóng và bất ngờ được donate quá nhiều, cô gái quyết định làm streamer, không đi tìm việc nữa",
description: "Mục đích livestream ôn thi của nữ streamer này đơn giản chỉ là quá cô đơn mà thôi.",
src: 'https://huyetnguyet.com/static/media/20210629215024--10-462143.1af90c89.jpg',
alt: "streamer,ôn thi,áp lực thi cử,",
category: 'images',
time: '09:50 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:50 PM',
link: '/on-thi-tren-song-va-bat-ngo-duoc-donate-qua-nhieu-co-gai-quyet-dinh-lam-streamer-khong-di-tim-viec-nua',
component: 'page_20210629215024'},
{title: "Top những game offline hấp dẫn nhất trên di động dành cho game thủ “đứt cáp” hay hết sạch 4G để online",
description: "Nếu rơi vào các tình huống mất mạng, đứt cáp hoặc không còn dữ liệu 4G để online thì game thủ không nên bỏ qua các tựa game dưới đây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/23/photo-1-1624465989685829439736.jpg',
alt: "TOP GAME MOBILE,TOP GAME,TOP GAME HAY NHẤT,TOP GAME MOBILE HAY NHẤT,LIMBO,GAME OFFLINE,TOP GAME OFFLINE,TOP GAME OFFLINE HAY NHẤT,GAME MOBILE OFFLINE,TOP GAME MOBILE OFFLINE,",
category: 'games',
time: '09:50 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:50 PM',
link: '/top-nhung-game-offline-hap-dan-nhat-tren-di-dong-danh-cho-game-thu-dut-cap-hay-het-sach-4g-de-online',
component: 'page_20210629215009'},
{title: "Cúi người sát camera để cảm ơn fan donate, nữ streamer xinh đẹp lộ 95% vòng một, suýt nữa hớ hênh luôn điểm nhạy cảm",
description: "Hành động tri ân fan hâm mộ của cô nàng streamer này lại càng làm cho không khí thêm phần nóng trong buổi livestream.",
src: 'https://huyetnguyet.com/static/media/20210629214941--11-880977.880c4274.gif',
alt: "GÁI XINH,CỘNG ĐỒNG MẠNG,HOT GIRL,STREAMER,NỮ STREAMER,EUN,",
category: 'images',
time: '09:49 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:49 PM',
link: '/cui-nguoi-sat-camera-de-cam-on-fan-donate-nu-streamer-xinh-dep-lo-95-vong-mot-suyt-nua-ho-henh-luon-diem-nhay-cam',
component: 'page_20210629214941'},
{title: "Dàn mỹ nữ Nhật Bản được xem là nguyên mẫu ngoài đời thật của các nhân vật nữ trong Thám tử Conan",
description: "Trong cuộc phỏng vấn năm 2019, tác giả Aoyama Gosho đã bật mí nguyên mẫu ngoài đời thực của các nhân vật nữ được yêu thích nhất bộ truyện Thám tử Conan.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629214921--13-345233.jpg',
alt: "KHÁM PHÁ,MANGA,ANIME,GIẢI TRÍ,THÁM TỬ CONAN,XUẤT BẢN,TRUYỆN TRANH,",
category: 'images',
time: '09:49 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:49 PM',
link: '/dan-my-nu-nhat-ban-duoc-xem-la-nguyen-mau-ngoai-doi-that-cua-cac-nhan-vat-nu-trong-tham-tu-conan',
component: 'page_20210629214921'},
{title: "8 gia đình \"có số má\" nhất thế giới anime, xứng danh \"cha ông mạnh, con cháu cũng phải mạnh\"",
description: "Theo bạn đâu là gia đình mạnh nhất trong thế giới anime?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/23/photo-1-16244438362121929745127.jpg',
alt: "GIA ĐÌNH ANIME,DRAGON BALL,KENGAN ASHURA,FULLMETAL ALCHEMIST,BAKI,GIA ĐÌNH JOESTAR,",
category: 'games',
time: '09:49 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:49 PM',
link: '/8-gia-dinh-co-so-ma-nhat-the-gioi-anime-xung-danh-cha-ong-manh-con-chau-cung-phai-manh',
component: 'page_20210629214910'},
]
export const dataContent13=[{title: "Ragnarok – Trận chiến tận thế trong thần thoại Bắc Âu diễn ra như thế nào?",
description: "Theo truyền thuyết về Ragnarok, thế giới sẽ lại nổi lên một lần nữa, các vị thần còn sống sót sẽ gặp nhau và thế giới được tái sinh nhờ hai con người sót lại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/22/ragnarok-digital-16243352134241137803023.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,RAGNAROK,THẦN THOẠI BẮC ÂU,LỊCH SỬ,VĂN HÓA,LOKI,",
category: 'games',
time: '09:48 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:48 PM',
link: '/ragnarok-tran-chien-tan-the-trong-than-thoai-bac-au-dien-ra-nhu-the-nao',
component: 'page_20210629214836'},
{title: "Conan: Điểm nhanh 5 anh chàng đa tài thuộc \"Hội bạn thân ở Học viện Cảnh sát\", đáng tiếc chỉ 1 người còn sống",
description: "Hội bạn thân ở Học viện Cảnh sát gồm có Jinpei Matsuda, Rei Furuya, Wataru Date, Hiromitsu Morofushi và Kenji Hagiwara.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/22/photo-1-16243361326392054330609.jpg',
alt: "HAGIWARA KENJI,HỌC VIỆN CẢNH SÁT,WILD POLICE STORY,THÁM TỬ LỪNG DANH CONAN,DATE WATARU,MATSUDA JINPEI,",
category: 'games',
time: '09:48 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:48 PM',
link: '/conan-diem-nhanh-5-anh-chang-da-tai-thuoc-hoi-ban-than-o-hoc-vien-canh-sat-dang-tiec-chi-1-nguoi-con-song',
component: 'page_20210629214826'},
{title: "Hạ thấp quần, khoe vòng eo theo cái cách đầy phản cảm, nữ streamer xinh đẹp nhận án phạt cấm kênh lần thứ n vẫn chưa sợ",
description: "Hành động của cô nàng streamer đang làm dấy lên làn sóng lo ngại trước thực trạng ngày càng táo bạo của các kênh phát sóng ngày nay.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629214800--16-190302.gif',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,PADAK,STREAMER,NỮ STREAMER,",
category: 'images',
time: '09:48 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:48 PM',
link: '/ha-thap-quan-khoe-vong-eo-theo-cai-cach-day-phan-cam-nu-streamer-xinh-dep-nhan-an-phat-cam-kenh-lan-thu-n-van-chua-so',
component: 'page_20210629214800'},
{title: "Mặc quần bó sát, lộ cả chi tiết \"tế nhị\" khi khoe vòng ba đầy phản cảm, Lê Bống thêm một lần bị chỉ trích mạnh mẽ",
description: "Mọi hành động của Lê Bống bây giờ đều đang phải chịu rất nhiều đánh giá từ phía cộng đồng mạng.",
src: 'https://huyetnguyet.com/static/media/20210629214738--10-643811.1ec0a489.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,LÊ BỐNG,",
category: 'images',
time: '09:47 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:47 PM',
link: '/mac-quan-bo-sat-lo-ca-chi-tiet-te-nhi-khi-kheo-vong-ba-day-phan-cam-le-bong-them-mot-lan-bi-chi-trich-manh-me',
component: 'page_20210629214738'},
{title: "Mở tour \"suối nước nóng\" giá 12 triệu, nàng hot girl phim 18+ bẽ bàng khi không ai đăng ký, lên tiếng chỉ trích fan thậm tệ",
description: "Cô nàng hot girl tỏ ra khá xấu hổ sau khi không mấy người quan tâm tới tour du lịch của mình.",
src: 'https://huyetnguyet.com/static/media/20210629214718--11-875061.86c62c00.jpg',
alt: "HOT GIRL,GÁI XINH,CỘNG ĐỒNG MẠNG,PHIM AV,AV,PHIM 18+,YUURI ASADA,",
category: 'images',
time: '09:47 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:47 PM',
link: '/mo-tour-suoi-nuoc-nong-gia-12-trieu-nang-hot-girl-phim-18-be-bang-khi-khong-ai-dang-ky-len-tieng-chi-trich-fan-tham-te',
component: 'page_20210629214718'},
{title: "Meo Xinh - tiểu thư Hà Nội lần đầu khoe hình với bikini, nhan sắc và thần thái tuổi 15 đỉnh quá trời ơi",
description: "Dù mới 15 tuổi thôi nhưng Lê Gia Linh (Meo Xinh) thực sự xứng đáng là một \"thế lực\" nhan sắc đáng gờm đấy!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629214649--12-032345.jpg',
alt: "Lê Gia Linh,Meo xinh,lê gia linh (meo xinh),rich kid 2k6,Tiểu thư,tiểu thư 2k6,Hello GenZ,Hello Gen Z,youtuber gen z,Gái đẹp đại chiến bikini,đại chiến bikini,gái xinh,",
category: 'images',
time: '09:46 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:46 PM',
link: '/meo-xinh-tieu-thu-ha-noi-lan-dau-khoe-hinh-voi-bikini-nhan-sac-va-than-thai-tuoi-15-dinh-qua-troi-oi',
component: 'page_20210629214649'},
{title: "5 lí do đế chế nội y Victorias Secret sụp đổ: Buôn bán và tiếp thị tình dục, phân biệt phụ nữ, thiên vị chị em Gigi và Kendall?",
description: "Sau hơn 40 năm thành lập, Victorias Secret đã đánh mất ngôi vương và xuống dốc liên tục do hàng loạt nguyên nhân gây tranh cãi.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629214618--17-638593.jpg',
alt: "Victorias secret,sao us uk,sao hollywood,kendall jenner,gigi hadid,bella hadid,",
category: 'images',
time: '09:46 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:46 PM',
link: '/5-li-do-khien-de-che-noi-y-victorias-secret-sup-do-buon-ban-va-tiep-thi-tinh-duc-phan-biet-phu-nu-thien-vi-chi-em-gigi-va-kendall',
component: 'page_20210629214618'},
{title: "Pokémon có thể giao tiếp với con người bằng cách nào? Nói chuyện, tâm linh, trực giác,... đều được cả",
description: "Bạn đã bao giờ thắc mắc Pokémon có thể giao tiếp với con người bằng cách nào hay chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/19/photo-1-162409752103114445468.jpg',
alt: "POKÉMON,POKÉMON GIAO TIẾP VỚI CON NGƯỜI,POKÉMON NÓI CHUYỆN,MEOWTH,MEOWTH NÓI CHUYỆN,",
category: 'games',
time: '09:46 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:46 PM',
link: '/pokemon-co-the-giao-tiep-voi-con-nguoi-bang-cach-nao-noi-chuyen-tam-linh-truc-giac-deu-duoc-ca',
component: 'page_20210629214607'},
{title: "MC Mai Dora úp mở dự định cosplay Ahri để phỏng vấn, fan vội ngăn cản vì sợ tuyển thủ... không thể tập trung",
description: "Cô nàng MC nóng bỏng của VCS lại khiến fan nổ đom đóm mắt với dự định đầy táo bạo của mình.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629214547--10-065206.png',
alt: "LIÊN MINH HUYỀN THOẠI,MAI DORA,MC MAI DORA,GAME,TIN GAME,VCS MÙA HÈ 2021,VCS,LMHT,",
category: 'images',
time: '09:45 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:45 PM',
link: '/mc-mai-dora-up-mo-du-dinh-cosplay-ahri-de-phong-van-fan-voi-ngan-can-vi-so-tuyen-thu-khong-the-tap-trung',
component: 'page_20210629214547'},
{title: "Rũ bỏ hình tượng ngây thơ, nữ streamer gây sốc khi bôi dầu bóng loáng vòng một, lên sóng nhảy gợi cảm",
description: "Những màn trình diễn gợi cảm của các cô nàng streamer giờ đang chuyển dần sang phản cảm và làm mất lòng người xem.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629214514--19-825786.jpg',
alt: "GÁI XINH,HOT GIRL,STREAMER,NỮ STREAMER,CỘNG ĐỒNG MẠNG,HAN JI-NA,",
category: 'images',
time: '09:45 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:45 PM',
link: '/ru-bo-hinh-tuong-ngay-tho-nu-streamer-gay-soc-khi-boi-dau-bong-loang-vong-mot-len-song-nhay-goi-cam',
component: 'page_20210629214514'},
{title: "Top 10 võ tướng nổi tiếng và đáng sợ nhất trong lịch sử Tam Quốc",
description: "Những người như Lã Bố, Mã Siêu, Triệu Vân, Quan Vũ... đều chiếm được địa vị nhất định trong thời Tam Quốc nhờ vào võ công cao cường của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/19/photo-1-16241154185281758165110.jpg',
alt: "TAM QUỐC,VÕ TƯỚNG,TRIỆU VÂN,QUAN VŨ,TRƯƠNG PHI,LÃ BỐ,",
category: 'games',
time: '09:45 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:45 PM',
link: '/top-10-vo-tuong-noi-tieng-va-dang-so-nhat-trong-lich-su-tam-quoc',
component: 'page_20210629214503'},
{title: "Hot girl phim 18+ gây sốc khi tự nhận bản thân không giỏi diễn xuất, gia nhập ngành công nghiệp vì hay mất \"hứng\" vào giữa đêm",
description: "Cô nàng hot girl này hiện đang là một trong những gương mặt top đầu của ngành công nghiệp AV Nhật Bản.",
src: 'https://huyetnguyet.com/static/media/20210629214443--11-755643.e36b7e24.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,SAKURA MOMO,PHIM AV,AV,PHIM 18+,",
category: 'images',
time: '09:44 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:44 PM',
link: '/hot-girl-phim-18-gay-soc-khi-tu-nhan-ban-than-khong-gioi-dien-xuat-gia-nhap-nganh-cong-nghiep-vi-hay-mat-hung-vao-giua-dem',
component: 'page_20210629214443'},
{title: "Lá bài Rồng Trắng Mắt Xanh trị giá nghìn đô trong Yu-Gi-Oh! được mua hết sạch trong ngày đầu ra mắt",
description: "Điều này thêm một lần nữa chứng minh được sức hút của trò chơi cũng như tựa manga huyền thoại Yu-Gi-Oh!.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/19/bo1-16241186006802089246700.jpg',
alt: "GAME YU-GI-OH!,YU-GI-OH! MANGA,YU-GI-OH! TRADING CARD GAME,TRÒ CHƠI YU-GI-OH! TRADING CARD GAME,VUA TRÒ CHƠI YUGI,THẦN BÀI YUGI,MUTO YUGI,THẺ BÀI YUGI OH,",
category: 'games',
time: '09:44 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:44 PM',
link: '/la-bai-rong-trang-mat-xanh-tri-gia-nghin-do-trong-yu-gi-oh-duoc-mua-het-sach-trong-ngay-dau-ra-mat',
component: 'page_20210629214434'},
{title: "7 bí ẩn nổi tiếng thường xuyên bị hiểu nhầm do thế lực thần bí gây ra, nhưng lời giải hóa ra lại khá đơn giản",
description: "Lời giải cho những bí ẩn này hóa ra lại đơn giản hơn chúng ta tưởng!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/20/photo-1-1624173966024705477776.jpg',
alt: "VĂN MINH MAYA,LỊCH SỬ LOÀI NGƯỜI,NHÀ KHOA HỌC,NGƯỜI NGOÀI HÀNH TINH,THUNG LŨNG CHẾT,PHẢN ỨNG HÓA HỌC,",
category: 'news',
time: '09:44 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:44 PM',
link: '/7-bi-an-noi-tieng-thuong-xuyen-bi-hieu-nham-do-the-luc-than-bi-gay-ra-nhung-loi-giai-hoa-ra-lai-kha-don-gian',
component: 'page_20210629214425'},
{title: "Điểm mặt 6 đứa con của \"Thần Điêu Đại Bịp\" Loki, đứa là quái vật kẻ thì chấp cả Thor",
description: "Với giới tính linh hoạt cùng khả năng thiên biến vạn hóa thành vô số hình dạng, Loki đã có tất cả 6 người con vô cùng \"quái dị\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/20/fe6-1624165713993278620488.jpg',
alt: "THẦN LỪA LỌC LOKI,BOX LOKI,TRAILER LOKI,LADY LOKI,PHẢN DIỆN LOKI,BIẾN THỂ CỦA LOKI,SERIES LOKI,NHÂN VẬT MỚI TRONG LOKI,BIẾN THỂ LOKI,",
category: 'games',
time: '09:44 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:44 PM',
link: '/diem-mat-6-dua-con-cua-than-dieu-dai-bip-loki-dua-la-quai-vat-ke-thi-chap-ca-thor',
component: 'page_20210629214415'},
{title: "Ngày nắng ngắm gái xinh trong The Vagrant như thể muốn x2 combo nóng",
description: "Cô nàng Valerie trong The Vagrant nóng bỏng quá đi mất.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629214345--10-554329.jpg',
alt: "NGÀY NẮNG,GÁI XINH,VALERIE,THE VAGRANT,COSPLAY,COSER,",
category: 'images',
time: '09:43 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:43 PM',
link: '/ngay-nang-ngam-gai-xinh-trong-the-vagrant-nhu-the-muon-x2-combo-nong',
component: 'page_20210629214345'},
{title: "Thất vọng vì thua Đức tan nát, Ronaldo bèn cởi trần khiến dân tình náo loạn: 6 múi sầu riêng đều tăm tắp nhờ nói không với Coca",
description: "Ronaldo show body cơ bắp cho dân tình chiêm ngưỡng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/20/photo-1-1624146308081764237574.jpeg',
alt: "ronaldo,Bồ Đào Nha,đức,Euro 2020,tiêu điểm euro,Đồng hành cùng Euro,tin nóng Euro,",
category: 'news',
time: '09:43 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:43 PM',
link: '/that-vong-vi-thua-duc-tan-nat-ronaldo-ben-coi-tran-khien-dan-tinh-nao-loan-6-mui-sau-rieng-deu-tam-tap',
component: 'page_20210629214330'},
{title: "Mỹ nhân Hoa ngữ đóng phim tiên hiệp: Ai cũng đẹp ngoại trừ \"trùm cuối\"!",
description: "Phim thần tiên kỳ ảo hay còn gọi là tiên hiệp là thể loại vốn được ưa chuộng trên màn ảnh Hoa ngữ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/6/20/2-1624124044817534968683.jpg',
alt: "châu đông vũ,triệu lệ dĩnh,Dương Mịch,Địch Lệ Nhiệt Ba,Dương Tử,nghê ni,Lưu Diệc Phi,viên băng nghiên,phim cổ trang,phim tiên hiệp,phim trung quốc,phim Hoa ngữ,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:43 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:43 PM',
link: '/my-nhan-hoa-ngu-dong-phim-tien-hiep-ai-cung-dep-ngoai-tru-trum-cuoi',
component: 'page_20210629214318'},
{title: "Ronaldo cởi trần khoe body như tượng tạc sau khi đội nhà thua tan tác trước Đức nhưng vậy thì chiếc áo của CR7 đã ở đâu?",
description: "Sau khi trận đấu với Đức khép lại, chiếc áo của Ronaldo đã bỗng dưng biến mất. Vậy nó đã đi đâu?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/20/photo-3-1624200538417983513447.jpg',
alt: "ronaldo,Euro 2020,tin nóng Euro,",
category: 'news',
time: '09:43 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:43 PM',
link: '/ronaldo-coi-tran-khoe-body-nhu-tuong-tac-sau-khi-doi-nha-thua-tan-tac-truoc-duc-nhung-vay-thi-chiec-ao-cua-cr7-da-o-dau',
component: 'page_20210629214307'},
{title: "Nghẹt thở với bộ ảnh sinh nhật của Cúc Tịnh Y: Visual kinh diễm tới từng milimet, vòng 1 đẫy đà như muốn chực trào ra ngoài",
description: "Ngày hôm nay, netizen xứ Trung gửi rất nhiều lời chúc mừng sinh nhật dành tặng cho \"Mỹ nhân 4000 năm\" Cúc Tịnh Y.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210618130438--15-429117.jpg',
alt: "",
category: 'images',
time: '01:04 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:04 PM',
link: '/nghet-tho-voi-bo-anh-sinh-nhat-cua-cuc-tinh-y-visual-kinh-diem-toi-tung-milimet-vong-1-day-da-nhu-muon-truc-trao-ra-ngoai',
component: 'page_20210618130438'},
{title: "Sau khi nữ streamer sexy Mai Dora ngất xỉu ngay trên sóng, một bộ phận cư dân mạng có nhiều bình luận ác ý với từ ngữ thô tục, giễu cợt",
description: "Một bộ phận cư dân mạng đang hành xử rất kém văn minh trước sự cố của Mai Dora. Đây là hành vi kém văn hóa rất đáng lên án.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210618130416--11-473397.jpg',
alt: "",
category: 'images',
time: '01:04 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:04 PM',
link: '/sau-khi-nu-streamer-sexy-mai-dora-ngat-xiu-ngay-tren-song-mot-bo-phan-cu-dan-mang-co-nhieu-binh-luan-ac-y-voi-tu-ngu-tho-tuc-gieu-cot',
component: 'page_20210618130416'},
{title: "3 nữ hoàng cảnh nóng xứ Hàn: Người bị bạn trai bỏ vì khỏa thân 100%, người lên đời cả tình yêu lẫn sự nghiệp",
description: "Từng được biết đến với danh xưng nữ hoàng cảnh nóng, 3 nữ diễn viên này hiện tại đều đã trở thành những nữ hoàng rating, ngôi sao phòng vé xứ Hàn.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210618130342--12-815764.jpg',
alt: "",
category: 'images',
time: '01:03 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:03 PM',
link: '/su-nghiep-cua-3-nu-hoang-canh-nong-phim-han-vien-man-nhat-dich-thi-la-son-ye-jin',
component: 'page_20210618130342'},
{title: "Cuộc hành quân \"thần tốc\" trong đêm của chiến sĩ Bắc Giang để nhường doanh trại cho người dân cách ly",
description: "Hàng trăm chiến sĩ của Sư đoàn 325 tại huyện Lục Ngạn (Bắc Giang) đã hành quân vào rừng, dựng hàng chục lán trại dã chiến để nhường doanh trại cho các công nhân ở Bắc Giang cách ly tập trung.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/6/18/dsc5152-16239532159142115958997.jpg',
alt: "",
category: 'tech',
time: '01:03 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:03 PM',
link: '/cuoc-hanh-quan-than-toc-trong-dem-cua-chien-si-bac-giang-de-nhuong-doanh-trai-cho-nguoi-dan-cach-ly',
component: 'page_20210618130328'},
{title: "Nóng: Nữ streamer sexy nhất Việt Nam - Mai Dora bất ngờ ngất xỉu ngay trên sóng livestream",
description: "Hiện tại người hâm mộ đang vô cùng lo lắng cho sức khỏe của nữ streamer Mai Dora.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210618130259--11-303271.jpg',
alt: "",
category: 'images',
time: '01:02 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:02 PM',
link: '/nong-nu-streamer-sexy-nhat-viet-nam-mai-dora-bat-ngo-ngat-xiu-ngay-tren-song-livestream',
component: 'page_20210618130259'},
{title: "Điểm mặt 4 manga bán chạy nhất nửa đầu năm 2021 tại Nhật Bản hưởng lợi từ phần chuyển thể anime xuất sắc",
description: "Mới đây nhất thì Oricon công bố đã công top 10 manga bán chạy nhất nửa đầu năm 2021 tại Nhật Bản. Bốn cái tên đứng đầu đều được cho là hưởng lợi từ phần chuyển thể anime vô cùng thành công.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/anu1-16239218593091146716359.jpg',
alt: "TOP MANGA,NHÂN VẬT MANGA,LỄ HỘI MANGA,TAM QUỐC MANGA,ANIME - MANGA,SHOP ANIME - MANGA,SHOP MANGA,ÔNG TỔ MANGA,GAME MANGA,ĐẠI CHIẾN MANGA,",
category: 'games',
time: '01:02 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:02 PM',
link: '/diem-mat-4-manga-ban-chay-nhat-nua-dau-nam-2021-tai-nhat-ban-huong-loi-tu-phan-chuyen-the-anime-xuat-sac',
component: 'page_20210618130226'},
{title: "Đánh giá Samsung 980 - SSD PCIe gen 3 vẫn thể hiện đẳng cấp nhanh \"xé gió\"",
description: "Samsung 980 là phiên bản thấp hơn của chiếc SSD hàng khủng 980 Pro, sử dụng kết nối PCIe gen 4, nhưng với nhu cầu sử dụng phổ thông thì vẫn hết sức ấn tượng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/photo-1-16239370492261108255459.jpg',
alt: "SSD,SAMSUNG,SAMSUNG 980,CỘNG ĐỒNG MẠNG,TIN TỨC GAME,",
category: 'tech',
time: '01:02 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:02 PM',
link: '/danh-gia-samsung-980-ssd-pcie-gen-3-van-the-hien-dang-cap-nhanh-xe-gio',
component: 'page_20210618130218'},
{title: "Đào Bitcoin thực sự tiêu tốn năng lượng đến mức nào?",
description: "Đào Bitcoin thực sự tốn năng lượng?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/photo-2-16239487061001019874080.jpg',
alt: "MUA XE HƠI,Ô NHIỄM MÔI TRƯỜNG,GIAO DỊCH BITCOIN,SỬ DỤNG CÔNG NGHỆ,TIÊU THỤ ĐIỆN,",
category: 'tech',
time: '01:02 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:02 PM',
link: '/dao-bitcoin-thuc-su-tieu-ton-nang-luong-den-muc-nao',
component: 'page_20210618130206'},
{title: "Top 8 bộ truyện isekai có nội dung khó đỡ làm mưa làm gió năm 2021 (P.2)",
description: "Để tìm được nét độc đáo riêng giữa thị trường truyện isekai đang nở rộ, tác giả của những bộ truyện này đã quyết định nghĩ các ý tưởng siêu dị.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/the-karate-master-fights-truck-kun-16239215523831281995025.jpg',
alt: "ISEKAI,MANGA,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/top-8-bo-truyen-isekai-co-noi-dung-kho-do-lam-mua-lam-gio-nam-2021-p2',
component: 'page_20210618130157'},
{title: "Bản đồ trong open-world game có thể rộng đến mức nào? Những tiết lộ khiến game thủ sửng sốt",
description: "Đâu là giới hạn của thế giới trong trò chơi open-world? Đôi khi game thủ sẽ phải giật mình khi biết được sự thật này.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/16/-16238526557041274300704.jpg',
alt: "KHÁM PHÁ,GAME ONLINE,GAME,TRÒ CHƠI ĐIỆN TỬ,OPEN-WORLD GAME,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/ban-do-trong-open-world-game-co-the-rong-den-muc-nao-nhung-tiet-lo-khien-game-thu-sung-sot',
component: 'page_20210618130145'},
{title: "Tự gắn mác vẫn còn \"cái ngàn vàng\", nàng hot girl phim 18+ bị ném đá tơi tả ngay sau tác phẩm debut",
description: "Cô nàng hot girl đang nhận phải rất nhiều chỉ trích từ phía cộng đồng mạng.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210618130125--10-237022.jpg',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,MAKOTO TODA,PHIM AV,AV,PHIM 18+,",
category: 'images',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/tu-gan-mac-van-con-cai-ngan-vang-nang-hot-girl-phim-18-bi-nem-da-toi-ta-ngay-sau-tac-pham-debut',
component: 'page_20210618130125'},
{title: "Dragon Ball Super: Các fan cho rằng Ultra Instinct của Goku đã \"out meta\" khi dễ dàng bị Granola hạ gục",
description: "Dragon Ball Super chap 73 chứng kiến cảnh Goku kích hoạt Ultra Instinct để đối đầu với Granola nhưng vẫn bị chiến binh mạnh nhất vũ trụ đánh gục.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/go1-16239900225751533435803.jpg',
alt: "ANIME DRAGON BALL SUPER,MANGA DRAGON BALL SUPER,DRAGON BALL SUPER CHAP 73,SON GOKU,KẺ SỐNG SÓT GRANOLA,GOKU VS GRANOLA,CON MẮT CỦA GRANOLAH,PERFECT ULTRA INSTINCT,ULTRA INSTINCT,THIÊN SỨ WHIS,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/dragon-ball-super-cac-cho-rang-ultra-instinct-cua-goku-da-out-meta-khi-de-dang-bi-granola-ha-guc',
component: 'page_20210618130116'},
{title: "Nổi tiếng không kém One Piece thế nhưng 5 anime sau đây lại bị \"hắt hủi\" tại Việt Nam, toàn những cái tên lạ hoắc",
description: "Nổi tiếng không kém gì Pokémon, One Piece, Conan tại đất nước Nhật Bản thế nhưng 5 series anime sau đây lại không được quan tâm tại Việt Nam, thậm chí có những cái tên mà chẳng ai biết đến.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/ani1-16239912086061456127846.jpeg',
alt: "SHOP ANIME - MANGA,PHONG CÁCH ANIME,TRAILER ANIME,MOVIE ANIME,ĐÁNH GIÁ ANIME,REVIEW ANIME,TV ANIME,MANGA ANIME,TOP ANIME,",
category: 'games',
time: '01:01 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:01 PM',
link: '/noi-tieng-khong-kem-one-piece-the-nhung-5-anime-sau-day-lai-bi-hat-hui-tai-viet-nam-toan-nhung-cai-ten-la-hoac',
component: 'page_20210618130105'},
{title: "Yua Mikami \"hái ra tiền\" nhiều cỡ nào từ ngành công nghiệp 18+?",
description: "Ai cũng biết Yua Mikami là ngôi sao số 1 của ngành công nghiệp 18+, nhưng cô nàng ăn nên làm ra thế nào thì vẫn là một dấu hỏi.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210618130042--10-789875.jpg',
alt: "IDOL,YUA MIKAMI,",
category: 'images',
time: '01:00 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:00 PM',
link: '/yua-mikami-hai-ra-tien-nhieu-co-nao-tu-nganh-cong-nghiep-18',
component: 'page_20210618130042'},
{title: "Worm là gì? Tại sao chúng lại nguy hiểm với máy tính?",
description: "Worm máy tính là một loại chương trình phần mềm độc hại có chức năng chính là lây nhiễm sang các máy tính khác trong khi vẫn hoạt động trên những hệ thống bị nhiễm.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/photo-1-1623999663289177940704.jpg',
alt: "WORM,PC,WORM MÁY TÍNH,HỆ ĐIỀU HÀNH WINDOWS,CHƯƠNG TRÌNH PHẦN MỀM,PHẦN MỀM ĐỘC HẠI,",
category: 'tech',
time: '01:00 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:00 PM',
link: '/worm-la-gi-tai-sao-chung-lai-nguy-hiem-voi-may-tinh',
component: 'page_20210618130029'},
{title: "Top 10 anime đang phát sóng được đánh giá cao nhất hiện nay theo MyAnimeList",
description: "Anime mùa xuân năm nay không có nhiều tác phẩm ấn tượng bằng năm ngoái nhưng vẫn có những cái tên rất tuyệt vời cho bạn thưởng thức.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/odd-taxi-16240043903291086406425.jpg',
alt: "ANIME,TOP ANIME,ANIME HAY,ODD TAXI,TOKYO REVENGERS,THÁM TỬ LỪNG DANH CONAN,MEGALO BOX 2,",
category: 'games',
time: '01:00 PM',
date: '18/06/2021',
timestamp: '18/06/2021 01:00 PM',
link: '/top-10-anime-dang-phat-song-duoc-danh-gia-cao-nhat-hien-nay-theo-myanimelist',
component: 'page_20210618130016'},
{title: "Mát mắt ngắm mỹ nhân Azur Lane diện đồ bơi khoe body nuột nà, tâm hồn cứ gọi là bát ngát",
description: "Nắng như thế này thì làm sao để giải nhiệt cho tốt anh em nhỉ?",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210618125927--10-84410.jpeg',
alt: "COSPLAY,NGẮM GÁI,GÁI XINH,ẢNH GÁI ĐẸP,AZUR LANE,KASHINO,COSER,",
category: 'images',
time: '12:59 PM',
date: '18/06/2021',
timestamp: '18/06/2021 12:59 PM',
link: '/mat-mat-ngam-my-nhan-azur-lane-dien-do-boi-khoe-body-nuot-na-tam-hon-cu-goi-la-bat-ngat',
component: 'page_20210618125927'},
{title: "Đây là 10 ý tưởng thú vị về Pokémon mà fan có thể chưa biết, cái tên ban đầu cũng rất cầu kỳ",
description: "Trước khi có tên chính thức là Pokémon, bạn có biết thương hiệu này được gọi là gì không?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/photo-1-16239870877751018865523.jpg',
alt: "POKÉMON,CAPSULE MONSTERS,POLIWHIRL,GENGAR,PIKACHU,",
category: 'games',
time: '12:59 PM',
date: '18/06/2021',
timestamp: '18/06/2021 12:59 PM',
link: '/day-la-10-y-tuong-thu-vi-ve-pokemon-ma-fan-co-the-chua-biet-cai-ten-ban-dau-cung-rat-cau-ky',
component: 'page_20210618125919'},
{title: "Top 10 phản diện đáng nhớ trong phim hoạt hình Pokémon (P.2)",
description: "Trong anime, rất nhiều nhân vật phản diện tỏa sáng hơn so với những gì chúng được thể hiện trong game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/18/photo-1-1624034373653677183133.jpg',
alt: "POKÉMON,PHẢN DIỆN,",
category: 'games',
time: '12:59 PM',
date: '18/06/2021',
timestamp: '18/06/2021 12:59 PM',
link: '/top-10-phan-dien-dang-nho-trong-phim-hoat-hinh-pokemon-p2',
component: 'page_20210618125907'},
{title: "Top 10 phản diện đáng nhớ trong phim hoạt hình Pokémon (P.1)",
description: "Trong anime, rất nhiều nhân vật phản diện tỏa sáng hơn so với những gì chúng được thể hiện trong game.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/photo-1-1623947652010101484898.jpg',
alt: "POKÉMON,PHẢN DIỆN,",
category: 'games',
time: '12:55 PM',
date: '18/06/2021',
timestamp: '18/06/2021 12:55 PM',
link: '/top-10-phan-dien-dang-nho-trong-phim-hoat-hinh-pokemon-p1',
component: 'page_20210618125537'},
{title: "Bị gạ combo \"150 triệu + 1 căn nhà\" cho một đêm ở cạnh, nàng hot girl Việt hài hước đáp trả \"Em có nhà rồi\"",
description: "Màn đối đáp rất thông minh và hài hước của cô nàng hot girl đang nhận được nhiều lời khen từ phía cộng đồng mạng.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210617140755--14-128010.png',
alt: "Gai Xinh, Hot Girl, Vo Ngoc Tran, Cong Dong Mang, ",
category: 'images',
time: '02:07 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:07 PM',
link: '/bi-ga-combo-150-trieu-1-can-nha-cho-mot-dem-o-canh-nang-hot-girl-viet-hai-huoc-dap-tra-em-co-nha-roi',
component: 'page_20210617140755'},
{title: "Top 7 manga bóng đá giúp bạn giải trí trong thời gian chờ đợi vòng loại World Cup 2022 cuối cùng của đội tuyển Việt Nam",
description: "Tiếp bước Tsubasa, Itto, Fantasista vang danh một thời, 7 manga bóng đá sau đây là những siêu phẩm mà các fan của môn thể thao vua không nên bỏ qua trong khi chờ đợi vòng loại World Cup 2022 cuối cùng của đội tuyển Việt Nam.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/17/ma1-16239080157491600788916.png',
alt: "Top Manga, Nhan Vat Manga, Le Hoi Manga, Anime Manga, Shop Manga, Game Manga, Shop Anime Manga, ",
category: 'games',
time: '02:07 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:07 PM',
link: '/top-7-manga-bong-da-giup-ban-giai-tri-trong-thoi-gian-cho-doi-vong-loai-world-cup-2022-cuoi-cung-cua-doi-tuyen-viet-nam',
component: 'page_20210617140743'},
{title: "Tân binh 18+ bày tỏ chuyện quá khứ, kể chuyện \"ăn tập bao nhiêu vào hết gò bồng đảo\"",
description: "Thân hình nhỏ nhắn cùng khuôn mặt dễ thương càng làm nổi bật hơn cặp gò bồng đảo nóng bỏng của Mahina Amane.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210617140720--10-421968.jpg',
alt: "Mahina Amane, Idol, ",
category: 'images',
time: '02:07 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:07 PM',
link: '/tan-binh-18-bay-to-chuyen-qua-khu-ke-chuyen-an-tap-bao-nhieu-vao-het-go-bong-dao',
component: 'page_20210617140720'},
{title: "Nhìn những gương mặt tuyệt sắc này thì ai còn dám nói điện ảnh Nhật thiếu bóng dáng mỹ nhân?",
description: "Khi nhắc đến nhan sắc châu Á, không chỉ Hoa ngữ mới sở hữu những vẻ đẹp khuynh nước khuynh thành, mà Nhật Bản cũng hội tụ hàng loạt mỹ nhân thần thái chẳng kém ai.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210617140532--10-493002.jpg',
alt: "My Nhan Nhat, Dien Anh Nhat, Gai Xinh, Gai Nhat, Sayuri Yoshinaga, Rie Miyazawa, Tokiwa Takako, ",
category: 'images',
time: '02:05 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:05 PM',
link: '/nhin-nhung-guong-mat-tuyet-sac-nay-thi-ai-con-dam-noi-dien-anh-nhat-thieu-bong-dang-my-nhan',
component: 'page_20210617140532'},
{title: "Sở hữu vòng một \"nặng\" nhất làng phim 18+ Nhật Bản, nàng hot girl mơ lật đổ Yua Mikami: \"Tôi muốn là người giỏi nhất\"",
description: "Rất nhiều người cho rằng ước mơ của cô nàng hot girl hoàn toàn có thể xảy ra.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210617140500--10-049292.jpg',
alt: "Gai Xinh, Cong Dong Mang, Hot Girl, images, Phim Av, Phim 18, Asuka Ada, ",
category: 'images',
time: '02:05 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:05 PM',
link: '/so-huu-vong-mot-nang-nhat-lang-phim-18-nhat-ban-nang-hot-girl-mo-lat-do-yua-mikami-toi-muon-la-nguoi-gioi-nhat',
component: 'page_20210617140500'},
{title: "Bất ngờ đứng lên \"sắp xếp\" vòng một đầy gợi cảm trên sóng, nữ streamer khiến người xem bấn loạn, không hiểu lý do",
description: "Rất nhiều người cảm thấy tò mò, khó hiểu động cơ của cô nàng streamer khi làm vậy.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210617140424--10-706329.jpg',
alt: "Gai Xinh, Hot Girl, Streamer, Nu Streamer, Cong Dong Mang, Ah Young, ",
category: 'images',
time: '02:04 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:04 PM',
link: '/bat-ngo-dung-len-sap-xep-vong-mot-day-goi-cam-tren-song-nu-streamer-khien-nguoi-xem-ban-loan-khong-hieu-ly-do',
component: 'page_20210617140424'},
{title: "Những nhân vật đáng yêu bậc nhất được nhiều game thủ ưa thích",
description: "Không ít các tựa game đình đám sở hữu hình ảnh nhân vật được nhân cách hóa với những đường nét ngộ nghĩnh, đáng yêu để tạo sự gần gũi, yêu thích.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/17/photo-1-16238729975221756858983.jpg',
alt: "Game, Nhan Vat, ",
category: 'games',
time: '02:04 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:04 PM',
link: '/nhung-nhan-vat-dang-yeu-bac-nhat-duoc-nhieu-game-thu-ua-thich',
component: 'page_20210617140409'},
{title: "Top game “kinh điển” hay nhất trên Mobile, có một phần Final Fantasy huyền thoại",
description: "Nếu bạn đang tìm kiếm những tựa game thuộc thể loại “kinh điển” trên nền tảng di động thì đừng bỏ qua những cái tên dưới đây.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/16/photo-1-16238616599652048581583.jpg',
alt: "Top Game Mobile, Top Game, Tin Tuc Game, Final Fantasy, Final Fantasy Vii, Android, Ios, Titan Quest, ",
category: 'games',
time: '02:03 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:03 PM',
link: '/top-game-kinh-dien-hay-nhat-tren-mobile-co-mot-phan-final-fantasy-huyen-thoai',
component: 'page_20210617140357'},
{title: "Đọ sắc trong tà áo dài trắng tinh khôi, các hot girl Esports hoá “nàng thơ\" trong sáng",
description: "Khác với hình ảnh trên sóng livestream, những cô nàng nổi tiếng giới Esports thướt tha trong tà áo dài trắng học đường.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210617140309--10-30061.jpeg',
alt: "Streamer, Esports, Hot Girl, Ao Dai, Cuoi Cap, ",
category: 'stars',
time: '02:03 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:03 PM',
link: '/do-sac-trong-ta-ao-dai-trang-tinh-khoi-cac-hot-girl-esports-hoa-nang-tho-trong-sang',
component: 'page_20210617140309'},
{title: "Lộ danh tính nàng hot girl gợi cảm đăng ảnh cổ vũ tuyển Việt Nam gây bão mạng, hóa ra là gương mặt quen thuộc",
description: "Cô nàng hot girl đang gây bão mạng hóa ra là một cái tên cũng đã không còn xa lạ với chúng ta.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616113329--13-06039.jpeg',
alt: "Gai Xinh, Hot Girl, Cong Dong Mang, Le Phuong Anh, ",
category: 'images',
time: '11:33 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:33 AM',
link: '/lo-danh-tinh-nang-hot-girl-goi-cam-dang-anh-co-vu-tuyen-viet-nam-gay-bao-mang-hoa-ra-la-guong-mat-quen-thuoc',
component: 'page_20210616113329'},
]
export const dataContent14=[{title: "Thả rông vòng một, nữ streamer xinh đẹp chỉ lên sóng buộc tóc, uống nước cũng thu hút cả ngàn người theo dõi",
description: "Visual đỉnh cao của cô nàng streamer đã trở thành điểm nhấn lớn nhất.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616113259--10-587553.png',
alt: "Gai Xinh, Hot Girl, Cong Dong Mang, Streamer, Nu Streamer, ",
category: 'images',
time: '11:32 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:32 AM',
link: '/tha-rong-vong-mot-nu-streamer-xinh-dep-chi-len-song-buoc-toc-uong-nuoc-cung-thu-hut-ca-ngan-nguoi-theo-doi',
component: 'page_20210616113259'},
{title: "Bị phụ huynh khẩn cầu rồi từ mặt, nàng hot girl phim 18+ vẫn quyết tâm theo nghề, trở thành \"thánh nữ\" để khẳng định bản thân",
description: "Cô nàng hot girl đã chứng minh rằng mình không hề sai khi lựa chọn con đường này.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616113238--10-043133.jpg',
alt: "Gai Xinh, Hot Girl, Cong Dong Mang, Kana Momonogi, images, Phim Av, Phim 18, ",
category: 'images',
time: '11:32 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:32 AM',
link: '/bi-phu-huynh-khan-cau-roi-tu-mat-nang-hot-girl-phim-18-van-quyet-tam-theo-nghe-tro-thanh-thanh-nu-de-khang-dinh-ban-than',
component: 'page_20210616113238'},
{title: "Liên tục gặp tai nạn khoe thân, hớ hênh với các clip Lookbook, nữ YouTuber bất ngờ được lên trang bìa của tạp chí 18+",
description: "Sự nghiệp của cô nàng YouTuber này cũng đang phát triển hơn bao giờ hết.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616113119--15-761451.gif',
alt: "Gai Xinh, Hot Girl, Cong Dong Mang, Youtuber, Eun Ji, Nu Youtuber, ",
category: 'images',
time: '11:31 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:31 AM',
link: '/lien-tuc-gap-tai-nan-khoe-than-ho-henh-voi-cac-clip-lookbook-nu-youtuber-bat-ngo-duoc-len-trang-bia-cua-tap-chi-18',
component: 'page_20210616113119'},
{title: "Những tựa game bạo lực 18+ khiến người chơi phải tránh xa vì quá đáng sợ",
description: "Mức độ bạo lực của các tựa game dãn nhãn không dành cho người dưới 18 tuổi này có thể khiến không ít người phải sửng sốt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/14/i-medieval-ii-total-war-digital-16236590681821075933505.jpg',
alt: "Game, games, ",
category: 'games',
time: '11:31 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:31 AM',
link: '/nhung-tua-game-bao-luc-18-khien-nguoi-choi-phai-tranh-xa-vi-qua-dang-so',
component: 'page_20210616113102'},
{title: "Top 10 nữ thần trong game khiến bạn tin vào tình yêu đích thực",
description: "Đắm chìm trong cả thế giới ảo lẫn nhan sắc của các vị nữ thần...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/14/photo-1-16236834372831875923547.jpg',
alt: "Game, Nu Than, Cong Dong Mang, Game Dinh, Tin Tuc Game, ",
category: 'games',
time: '11:30 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:30 AM',
link: '/top-10-nu-than-trong-game-khien-ban-tin-vao-tinh-yeu-dich-thuc',
component: 'page_20210616113051'},
{title: "Cốt truyên đầy bi kịch ít người biết tới của Contra - tựa game quốc dân của thế hệ 8-9x",
description: "Rất nhiều người biết tới Contra, nhưng không phải ai cũng biết được cốt truyện bi kịch của nó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/13/photo-1-16235858486381724759341.jpg',
alt: "Game, Tin Tuc Game, Game Thu, ",
category: 'games',
time: '11:30 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:30 AM',
link: '/cot-truyen-day-bi-kich-it-nguoi-biet-toi-cua-contra-tua-game-quoc-dan-cua-the-he-8-9x',
component: 'page_20210616113041'},
{title: "Quảng cáo game mới, “nữ hoàng” sexy lại “thiêu đốt” thị giác game thủ",
description: "Không hổ danh là nữ streamer nóng bỏng nhất làng game, Kim Chi khiến người hâm mộ phải thốt lên: “Ối dồi ôi”!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616113021--11-197716.jpg',
alt: "Streamer, Sexy, Kim Chi, Hot Girl, Cong Dong Mang, ",
category: 'images',
time: '11:30 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:30 AM',
link: '/quang-cao-game-moi-nu-hoang-sexy-lai-thieu-dot-thi-giac-game-thu',
component: 'page_20210616113021'},
{title: "Sở hữu vòng một cả mét, hot girl phim 18+ vừa debut đã lập kỷ lục, chia sẻ thường thả rông vì không tìm được nội y vừa cỡ",
description: "Cô nàng hot girl này đang là tâm điểm của sự chú ý sau mà debut như mơ của mình.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616113003--15-785283.jpg',
alt: "Hot Girl, Gai Xinh, Ichika Seta, Cong Dong Mang, Phim Av, images, Phim 18, ",
category: 'images',
time: '11:30 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:30 AM',
link: '/so-huu-vong-mot-ca-met-hot-girl-phim-18-vua-debut-da-lap-ky-luc-chia-se-thuong-tha-rong-vi-khong-tim-duoc-noi-y-vua-co',
component: 'page_20210616113003'},
{title: "Gây tranh cãi tại \"Nóng cùng Euro\", hot girl Lê Bống đăng ảnh gợi cảm nhưng liên tục bị fan troll sấp mặt",
description: "Cô nàng Lê Bống đang bị \"troll\" khá nhiều sau phát biểu có phần hơi \"lủng\" kiến thức bóng đá của mình.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616112918--17-191345.jpg',
alt: "Gai Xinh, Hot Girl, Cong Dong Mang, Le Bong, ",
category: 'images',
time: '11:29 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:29 AM',
link: '/gay-tranh-cai-tai-nong-cung-euro-hot-girl-le-bong-dang-anh-goi-cam-nhung-lien-tuc-bi-fan-troll-sap-mat',
component: 'page_20210616112918'},
{title: "Cùng ngắm Triệu Lệ Dĩnh và 7749 tạo dáng \"giả trân\" khi quảng cáo trang sức",
description: "Là quảng cáo trang sức hạng sang hay trang sức đồ chơi đây thưa cô Triệu Lệ Dĩnh?",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616112833--10-651324.jpg',
alt: "Trieu Le Dinh, Trieu Lo Tu, Nayeon, Dep, Star Style, Style Cua Sao, ",
category: 'stars',
time: '11:28 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:28 AM',
link: '/cung-ngam-trieu-le-dinh-va-7749-tao-dang-gia-tran-khi-quang-cao-trang-suc',
component: 'page_20210616112833'},
{title: "Hóa ra Jennie từng có thời mặc đẹp đến mức antifan cũng phải \"câm nín\", không chê được điểm nào",
description: "Phải công nhận đây là giai đoạn bùng nổ của Jennie, hoàn hảo từ body cho tới phong cách thời trang, bảo sao ai cũng mê như điếu đổ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616112719--10-775735.jpg',
alt: "Jennie, Black Pink, Sao Han, Trang Phuc Trinh Dien, Outfit, Mac Dep, Idol, Kpop, ",
category: 'stars',
time: '11:27 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:27 AM',
link: '/hoa-ra-jennie-tung-co-thoi-mac-dep-den-muc-antifan-cung-phai-cam-nin-khong-che-duoc-diem-nao',
component: 'page_20210616112719'},
{title: "Nóng bức, hot girl con lai Việt - Trung mặc áo dây trễ nải, thu hút sự chú ý của cộng đồng",
description: "Gương mặt thanh tú cùng vòng một nuột nà, hot girl 2002 liên tục được cư dân mạng “thả tim”.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614093056--10-550813.jpg',
alt: "Hot Girl, Gai Xinh, 10x, Sexy, ",
category: 'images',
time: '09:30 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:30 AM',
link: '/nong-buc-hot-girl-con-lai-viet-trung-mac-ao-day-tre-nai-thu-hut-su-chu-y-cua-cong-dong',
component: 'page_20210614093056'},
{title: "Ơn Trời! Cuối cùng Triệu Lệ Dĩnh cũng đã bớt một màu và chuyển sang... màu mới rồi!",
description: "Sau quãng thời gian chỉ lên đồ một màu thì nay Triệu Lệ Dĩnh đã được đổi style mới mẻ hơn.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614093016--10-686311.jpg',
alt: "Sao Do Thoi Trang, Trieu Le Dinh, Sao Hoa Ngu, Star Style, Style Cua Sao, ",
category: 'images',
time: '09:30 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:30 AM',
link: '/on-troi-cuoi-cung-trieu-le-dinh-cung-da-bot-mot-mau-va-chuyen-sang-mau-moi-roi',
component: 'page_20210614093016'},
{title: "HLV Park Hang-seo bị cấm chỉ đạo trận UAE",
description: "Sau một pha tranh cãi nảy lửa với cầu thủ Malaysia trong trận đấu rạng sáng 12/6, ông Park Hang-seo đã phải nhận một thẻ vàng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/12/photo-2-1623441592144528997364.jpg',
alt: "Viet Nam Vs Malaysia Tai Vong Loai World Cup 2022, Tuyen Viet Nam, Viet Nam Vs Malaysia, Park Hang Seo, ",
category: 'news',
time: '09:30 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:30 AM',
link: '/hlv-park-hang-seo-bi-cam-chi-dao-tran-uae',
component: 'page_20210614093003'},
{title: "Cộng đồng mạng phát cuồng với màn trình diễn đỉnh cao của \"Manuel David de Tấn Trường\"",
description: "Ngay sau đó, trên mạng xã hội, các CĐV Việt Nam đã so sánh Tấn Trường với những thủ môn nổi tiếng thế giới như David De Gea hay Manuel Neuer bằng cách ghép tên khá hài hước. Sau đó, dù phải nhận bàn thua trên chấm 11m, Tấn Trường vẫn vững vàng. ĐT Việt Nam có những sự thay đổi người kịp thời và có được bàn vươn lên dẫn trước 2-1 nhờ công của Quế Ngọc Hải.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/12/photo-1-1623437890849206762956.png',
alt: "Viet Nam, Viet Nam Vs Malaysia, Viet Nam Dau Malaysia, Bui Tan Truong, Tan Truong, Thu Mon Tan Truong, Vong Loai World Cup 2022, World Cup 2022, Tan Truong Cuu Thua, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, ",
category: 'news',
time: '09:29 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:29 AM',
link: '/cong-dong-mang-phat-cuong-voi-man-trinh-dien-dinh-cao-cua-manuel-david-de-tan-truong',
component: 'page_20210614092946'},
{title: "Nữ coser gốc Việt cũng quyết định “cởi” để biến thành nữ trợ lý ảo Samsung, khoe trọn những thứ \"18+\"",
description: "Có vẻ như, nữ trợ lý ảo của Samsung vẫn chưa hết hot khi mới đây, nàng cosplayer gốc Việt cũng quyết định “cởi” để biến thân.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092821--10-789976.jpg',
alt: "Sam, Samsung, Gai Xinh, Cosplayer, images, Hanna Bunny, Coser, ",
category: 'images',
time: '09:28 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:28 AM',
link: '/nu-coser-goc-viet-cung-quyet-dinh-coi-de-bien-thanh-nu-tro-ly-ao-samsung-khoe-tron-nhung-thu-18',
component: 'page_20210614092821'},
{title: "Những tựa game hay tuyệt nhưng khiến game thủ phát cáu vì cái kết vớ vẩn",
description: "Các tựa game đình đám này từng được game thủ đánh giá rất chất lượng, thế nhưng cái kết của chúng khiến tất cả đều cảm thấy khó chịu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/4863-1623403239959306092727.jpg',
alt: "Game, The Legend Of Zelda Majoras Mask, Super Mario Bros 2, ",
category: 'games',
time: '09:28 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:28 AM',
link: '/nhung-tua-game-hay-tuyet-nhung-khien-game-thu-phat-cau-vi-cai-ket-vo-van',
component: 'page_20210614092810'},
{title: "Bên cạnh DOTA, D-DAY, đây chính là những custom map huyền thoại một thời của Warcraft 3 mà hiếm ai không biết tới (p1)",
description: "Bên cạnh hai cái tên siêu đỉnh ở trên, Warcraft 3 vẫn còn vô số những custom map thú vị khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/9/photo-1-16232297806691593115037.jpg',
alt: "Game, Game Thu, Tin Tuc Game, Warcraft 3, ",
category: 'games',
time: '09:27 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:27 AM',
link: '/ben-canh-dota-d-day-day-chinh-la-nhung-custom-map-huyen-thoai-mot-thoi-cua-warcraft-3-ma-hiem-ai-khong-biet-toi-p1',
component: 'page_20210614092757'},
{title: "Những Pokémon được thiết kế dựa trên nhân vật có thật",
description: "Pokémon được lấy cảm hứng rất nhiều từ đời thực, trong đó có cả những người thực sự tồn tại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/12/photo-1-1623514660251583445982.png',
alt: "Hitmonlee, Hitmonchan, Pokemon, Snorlax, ",
category: 'games',
time: '09:27 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:27 AM',
link: '/nhung-pokemon-duoc-thiet-ke-dua-tren-nhan-vat-co-that',
component: 'page_20210614092742'},
{title: "Top 10 tựa game hậu tận thế với bản đồ bạt ngàn cho bạn thỏa sức tung hoành (P.2)",
description: "Những tựa game cho anh em tha hồ giải trí.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/12/photo-1-16234995858851322117157.jpg',
alt: "Cong Dong Mang, Tin Tuc Game, Game, Game Thu, Sinh Ton, The Gioi Mo, ",
category: 'games',
time: '09:27 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:27 AM',
link: '/top-10-tua-game-hau-tan-the-voi-ban-do-bat-ngan-cho-ban-thoa-suc-tung-hoanh-p2',
component: 'page_20210614092729'},
{title: "Cộng đồng game thủ mê mẩn bộ ảnh nóng bỏng mắt của nữ cosplayer Hiino Yuki",
description: "Sở hữu khuôn mặt xinh xắn, vóc dáng gợi cảm, nữ cosplayer Hiino Yuki đã trở thành cái tên nổi tiếng trong làng cosplay theo phong cách Ero bốc lửa.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092648--10-752028.jpg',
alt: "Game, Manga, Anime, Cosplay, Cosplayer, ",
category: 'images',
time: '09:26 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:26 AM',
link: '/cong-dong-game-thu-me-man-bo-anh-nong-bong-mat-cua-nu-cosplayer-hiino-yuki',
component: 'page_20210614092648'},
{title: "Liên tục thả dáng gợi cảm với bikini nóng bỏng, Jun Vũ khiến fan không khỏi trầm trồ, \"chảy máu mũi\"",
description: "Những ngày gần đây, cộng đồng mạng được một phen náo loạn với những hình ảnh cực kỳ gợi cảm của Jun Vũ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092614--15-324082.jpg',
alt: "Hot Girl, Gai Xinh, Jun Vu, Cong Dong Mang, ",
category: 'images',
time: '09:26 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:26 AM',
link: '/lien-tuc-tha-dang-goi-cam-voi-bikini-nong-bong-jun-vu-khien-fan-khong-khoi-tram-tro-chay-mau-mui',
component: 'page_20210614092614'},
{title: "Lên sóng vẽ tranh không ai xem, nữ streamer xinh đẹp quyết hở bạo, rung lắc vòng một bằng màn vũ đạo để đời",
description: "Không phải lúc nào những động tác vũ đạo gợi cảm của cô nàng streamer này cũng được đón nhận.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092543--16-754336.gif',
alt: "Gai Xinh, Cong Dong Mang, Streamer, Hot Girl, Grille Young, Nu Streamer, ",
category: 'images',
time: '09:25 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:25 AM',
link: '/len-song-ve-tranh-khong-ai-xem-nu-streamer-xinh-dep-quyet-ho-bao-rung-lac-vong-mot-bang-man-vu-dao-de-doi',
component: 'page_20210614092543'},
{title: "Đam mê “thả rông” vòng một, khoe thềm ngực quyến rũ, các hot girl làng game được và mất những gì?",
description: "Với thế mạnh sở hữu vòng một gợi cảm, các hot streamer thu hút sự chú ý của cộng đồng fan.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092507--10-65347.jpeg',
alt: "Streamer, Vong Mot, Tha Rong, Chieu Tro, ",
category: 'images',
time: '09:25 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:25 AM',
link: '/dam-me-tha-rong-vong-mot-khoe-them-nguc-quyen-ru-cac-hot-girl-lang-game-duoc-va-mat-nhung-gi',
component: 'page_20210614092507'},
{title: "10 game chiến thuật cho bạn làm thống soái, chỉ huy hàng trăm vạn quân (Phần 2)",
description: "Game chiến thuật luôn có sức hút kỳ lạ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/12/photo-1-16235122911981082464026.png',
alt: "Game Chien Thuat, Xay Dung Can Cu, The Ky 18, Tang Lop Quy Toc, Age Of Empires, ",
category: 'games',
time: '09:24 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:24 AM',
link: '/10-game-chien-thuat-cho-ban-lam-thong-soai-chi-huy-hang-tram-van-quan-phan-2',
component: 'page_20210614092454'},
{title: "Lee Kwang Soo liên tục xin lỗi trong đoạn thư chia tay gửi đồng đội Running Man",
description: "Tâm thư của Kwang Soo trong tập cuối cùng khiến fan không thể kìm được nước mắt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/photo-1-16235849348172030459849.jpg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man Sau 11 Nam, Running Man, ",
category: 'news',
time: '09:24 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:24 AM',
link: '/kwang-soo-viet-thu-tay-gui-cac-thanh-vien-va-fan-moi-nguoi-bay-gio-hay-quen-em-di',
component: 'page_20210614092438'},
{title: "Mỹ nữ Titanic từng gây sốc với loạt cảnh nóng hở 100% bên \"người tình\" kém 15 tuổi, nhận luôn Oscar vì quá xuất sắc",
description: "Kate Winslet luôn được biết đến như là một trong những cái tên chịu lăn xả vì cảnh nóng nhất nhì điện ảnh Hollywood.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092415--11-609549.jpg',
alt: "Kate Winslet, Titanic, Titanic 1997, Canh Nong, Canh Nong Tao Bao, Canh Nong Hollywood, Phim Au My, The Reader, ",
category: 'images',
time: '09:24 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:24 AM',
link: '/my-nu-titanic-tung-gay-soc-voi-loat-canh-nong-ho-100-ben-nguoi-tinh-kem-15-tuoi-nhan-luon-oscar-vi-qua-xuat-sac',
component: 'page_20210614092415'},
{title: "1 Á hậu Việt Nam gây sốc vì mốt \"thả rông\", tưởng đột phá ai dè nhận \"gạch đá\" từ cư dân mạng",
description: "Hỡi những tín đồ thời trang! Đừng lên đồ như cô Á hậu này bởi mốt \"no bra\" không phải thứ vũ khí linh hoạt tới mức có thể áp dụng trên mọi trang phục.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092349--10-271654.jpg',
alt: "A Hau Viet Nam, Thuy An, A Hau Thuy An, Loa Lo, Sao Xau, Sao Dep, Sao Do Thoi Trang, ",
category: 'images',
time: '09:23 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:23 AM',
link: '/1-a-hau-viet-nam-gay-soc-vi-tha-rong-loa-lo-tuong-dot-pha-ai-de-an-gach-da-tu-cu-dan-mang',
component: 'page_20210614092349'},
{title: "Bây giờ thử đặt Song Hye Kyo và Son Ye Jin lên bàn cân: Diện đồ 2 dây e ấp vòng 1 tới \"ná thở\", ai mới ngồi \"mâm trên\"?",
description: "Không phải ai cũng từng thấy những hình ảnh nóng bỏng tới mức này của Song Hye Kyo và Son Ye Jin đâu!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614092054--10-578120.jpg',
alt: "Song Hye Kyo, Son Ye Jin, Sao Han, Kbiz, Ngoc Nu, Sexy, Goi Cam, Vong Mot, Quyen Ru, ",
category: 'images',
time: '09:20 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:20 AM',
link: '/bay-gio-thu-dat-song-hye-kyo-va-son-ye-jin-len-ban-can-dien-do-2-day-e-ap-vong-1-toi-na-tho-ai-moi-ngoi-mam-tren',
component: 'page_20210614092054'},
{title: "Se Chan khóc gục xuống bàn, So Min và PD nhòe lệ khi chia tay Kwang Soo",
description: "Giây phút chia ly Kwang Soo, hai thành viên ít tuổi nhất Se Chan và So Min đã khóc hết nước mắt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/photo-1-16235842431201126222547.jpg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man Sau 11 Nam, Yang Se Chan, Jeon So Min, Running Man, ",
category: 'news',
time: '09:20 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:20 AM',
link: '/se-chan-khoc-guc-xuong-ban-so-min-va-pd-nhoe-le-khi-chia-tay-kwang-soo',
component: 'page_20210614092039'},
{title: "Chủ tịch Văn Toàn dạy bạn “làm giàu”: Bị fan Malaysia công kích, chớp cơ hội ra luôn mẫu áo in hình cú ngã penalty",
description: "Thời đại nào rồi mà còn suy sụp khi bị anti làm phiền, phải chớp lấy cơ hội buôn bán như Văn Toàn đi nhé!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/a8-125931-16235717463561143548443.jpg',
alt: "Van Toan, Nguyen Van Toan, Relax, Cau Thu Van Toan, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, ",
category: 'news',
time: '09:20 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:20 AM',
link: '/chu-tich-van-toan-day-ban-lam-giau-bi-fan-malaysia-cong-kich-chop-co-hoi-ra-luon-mau-ao-in-hinh-cu-nga-penalty',
component: 'page_20210614092027'},
{title: "Lee Kwang Soo trong mắt NSX Running Man trước giờ chia tay: \"Cậu ấy không phải là đồ ngốc\"",
description: "Mọi thông tin mới nhất của Lee Kwang Soo trước giờ chính thức chia tay Running Man đều khiến cư dân mạng hết sức quan tâm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/anh-1-1623559384920800773602.jpeg',
alt: "Lee Kwang Soo, Sao Han, Idol, Kpop, Lee Kwang Soo Roi Running Man, Running Man, ",
category: 'news',
time: '09:20 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:20 AM',
link: '/lee-kwang-soo-trong-mat-nsx-running-man-truoc-gio-chia-tay-cau-ay-khong-phai-la-do-ngoc',
component: 'page_20210614092016'},
{title: "Nhìn clip diện bikini của Ngọc Trinh, đố bạn tìm được chút dấu hiệu còn sót lại của vòng eo 56!",
description: "\"Vòng eo 56\" từng được coi là biểu tượng không thể tách rời của Ngọc Trinh, nhưng chắc giờ cô sẽ phải đi tìm số đo mới rồi!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614091942--10-493647.png',
alt: "Ngoc Trinh, Bikini, Vong Eo, Vong 2, Body Sao Viet, Sexy Body, ",
category: 'images',
time: '09:19 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:19 AM',
link: '/nhin-clip-dien-bikini-cua-ngoc-trinh-do-ban-tim-duoc-chut-dau-hieu-con-sot-lai-cua-vong-eo-56',
component: 'page_20210614091942'},
{title: "Cởi áo ngực ngay trên sóng, nữ streamer xinh đẹp vô tình gây sốc khi vô tình hớ hênh \"nhũ hoa\" trước hàng ngàn người xem",
description: "Hành động của cô nàng streamer này khiến rất nhiều người phải bất ngờ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614091903--10-625482.png',
alt: "Gai Xinh, Streamer, Hot Girl, Nu Streamer, Kim Min Young, Hearthstone, ",
category: 'images',
time: '09:19 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:19 AM',
link: '/coi-ao-nguc-ngay-tren-song-nu-streamer-xinh-dep-vo-tinh-gay-soc-khi-vo-tinh-ho-henh-nhu-hoa-truoc-hang-ngan-nguoi-xem',
component: 'page_20210614091903'},
{title: "Top 10 tựa game hậu tận thế với bản đồ bạt ngàn cho bạn thỏa sức tung hoành",
description: "Những tựa game cho anh em tha hồ giải trí.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/12/photo-1-16234965349261716902608.jpg',
alt: "Game, Cong Dong Mang, The Gioi Mo, Tin Tuc Game, Game Hay, ",
category: 'games',
time: '09:18 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:18 AM',
link: '/top-10-tua-game-hau-tan-the-voi-ban-do-bat-ngan-cho-ban-thoa-suc-tung-hoanh',
component: 'page_20210614091850'},
{title: "Vô tư gác chân lên ghế, nữ streamer xinh đẹp hớ hênh, lộ ngay điểm nhạy cảm gây chú ý",
description: "Có lẽ cô nàng streamer hồn nhiên quá mà quên mất rằng mình đang trên sóng trước mặt hàng nghìn người xem thì phải.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210614091818--12-155164.gif',
alt: "Gai Xinh, Hot Girl, Streamer, Cong Dong Mang, Nu Streamer, Jjj, ",
category: 'images',
time: '09:18 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:18 AM',
link: '/vo-tu-gac-chan-len-ghe-nu-streamer-xinh-dep-ho-henh-lo-ngay-diem-nhay-cam-gay-chu-y',
component: 'page_20210614091818'},
{title: "Nữ coser gốc Việt cũng quyết định “cởi” để biến thành nữ trợ lý ảo Samsung, khoe trọn những thứ \"18+\"",
description: "Có vẻ như, nữ trợ lý ảo của Samsung vẫn chưa hết hot khi mới đây, nàng cosplayer gốc Việt cũng quyết định “cởi” để biến thân.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210611132832--10-789976.jpg',
alt: "Sam, Samsung, Gai Xinh, Cosplayer, images, Hanna Bunny, Coser, ",
category: 'images',
time: '01:28 PM',
date: '11/06/2021',
timestamp: '11/06/2021 01:28 PM',
link: '/nu-coser-goc-viet-cung-quyet-dinh-coi-de-bien-thanh-nu-tro-ly-ao-samsung-khoe-tron-nhung-thu-18',
component: 'page_20210611132832'},
{title: "Những tựa game hay tuyệt nhưng khiến game thủ phát cáu vì cái kết vớ vẩn",
description: "Các tựa game đình đám này từng được game thủ đánh giá rất chất lượng, thế nhưng cái kết của chúng khiến tất cả đều cảm thấy khó chịu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/4863-1623403239959306092727.jpg',
alt: "Game, The Legend Of Zelda Majoras Mask, Super Mario Bros 2, ",
category: 'games',
time: '01:28 PM',
date: '11/06/2021',
timestamp: '11/06/2021 01:28 PM',
link: '/nhung-tua-game-hay-tuyet-nhung-khien-game-thu-phat-cau-vi-cai-ket-vo-van',
component: 'page_20210611132820'},
{title: "Bên cạnh DOTA, D-DAY, đây chính là những custom map huyền thoại một thời của Warcraft 3 mà hiếm ai không biết tới (p1)",
description: "Bên cạnh hai cái tên siêu đỉnh ở trên, Warcraft 3 vẫn còn vô số những custom map thú vị khác.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/9/photo-1-16232297806691593115037.jpg',
alt: "Game, Game Thu, Tin Tuc Game, Warcraft 3, ",
category: 'games',
time: '01:28 PM',
date: '11/06/2021',
timestamp: '11/06/2021 01:28 PM',
link: '/ben-canh-dota-d-day-day-chinh-la-nhung-custom-map-huyen-thoai-mot-thoi-cua-warcraft-3-ma-hiem-ai-khong-biet-toi-p1',
component: 'page_20210611132807'},
{title: "Game \"tập làm nông dân\" My Time At Portia sắp có mặt trên nền tảng di dộng",
description: "Một phiên bản vui tươi và mới lạ hơn so với bản gốc trên PC của trò chơi My Time At Portia.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/photo-1-16233453346472028627908.jpg',
alt: "My Time In Portia, Game Mobile, Ios, Android, ",
category: 'games',
time: '01:11 AM',
date: '11/06/2021',
timestamp: '11/06/2021 01:11 AM',
link: '/game-tap-lam-nong-dan-my-time-at-portia-sap-co-mat-tren-nen-tang-di-dong',
component: 'page_20210611011115'},
{title: "Thần thú Niên từng bị Hồng Quân Lão Tổ đánh bại trong thần thoại Trung Hoa quyền lực ra sao?",
description: "Theo truyền thuyết Trung Hoa, Niên đã bị Hồng Quân Lão Tổ đánh bại và chấp nhận trở thành vật cưỡi của ông.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/11/thelegendarynian0-16233835237231846806261.jpg',
alt: "Kham Pha, Than Thoai, Van Hoa, Nien, Truyen Thuyet Dan Gian, ",
category: 'news',
time: '01:11 AM',
date: '11/06/2021',
timestamp: '11/06/2021 01:11 AM',
link: '/than-thu-nien-tung-bi-hong-quan-lao-to-danh-bai-trong-than-thoai-trung-hoa-quyen-luc-ra-sao',
component: 'page_20210611011104'},
{title: "Hé lộ cuộc sống sau giải nghệ của \"thánh nữ\" một thời Rina Ishihara, hóa ra vẫn làm công việc liên quan tới nghề cũ",
description: "Cực kỳ nổi tiếng với vô số tác phẩm, nhưng Rina Ishihara lại vô cùng im ắng khi giải nghệ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210611011047--13-990170.jpg',
alt: "Hot Girl, Rina Ishihara, Gai Xinh, Cong Dong Mang, Phim 18, images, Phim Av, ",
category: 'images',
time: '01:10 AM',
date: '11/06/2021',
timestamp: '11/06/2021 01:10 AM',
link: '/he-lo-cuoc-song-sau-giai-nghe-cua-thanh-nu-mot-thoi-rina-ishihara-hoa-ra-van-lam-cong-viec-lien-quan-toi-nghe-cu',
component: 'page_20210611011047'},
{title: "\"Mợ ngố\" Song Ji Hyo từng đóng cảnh nóng 40 lần cho một bộ phim, bị chỉ trích ham hư danh nên mới lột đồ",
description: "Vì những cảnh nóng quá trần trụi ở Song Hoa Điếm, Song Ji Hyo từng bị chỉ trích thậm tệ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210611011022--11-896287.jpg',
alt: "A Frozen Flower, Song Hoa Diem, Song Ji Hyo, Jo In Sung, Canh Giuong Chieu, Phim Dien Anh, Phim Han Quoc, Canh Nong, ",
category: 'images',
time: '01:10 AM',
date: '11/06/2021',
timestamp: '11/06/2021 01:10 AM',
link: '/mo-ngo-song-ji-hyo-tung-dong-canh-nong-40-lan-cho-mot-bo-phim-bi-chi-trich-ham-hu-danh-nen-moi-lot-do',
component: 'page_20210611011022'},
{title: "Ngọc Trinh lại \"đốt mắt\" netizen trong bộ ảnh diện nội y cực nóng bỏng, vòng 3 ngồn ngộn không chút che chắn chiếm hết spotlight",
description: "Được mệnh danh là \"Nữ hoàng nội y\", Ngọc Trinh chưa bao giờ khiến dân tình thôi bàn tán khi diện những bộ nội y táo bạo.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210611010946--14-13272.jpeg',
alt: "Ngoc Trinh, Nu Hoang Noi Y, Ban Than Vu Khac Tiep, Ngoc Trinh Dien Bikini, Sao Viet, Showbiz Viet, ",
category: 'images',
time: '01:09 AM',
date: '11/06/2021',
timestamp: '11/06/2021 01:09 AM',
link: '/ngoc-trinh-lai-dot-mat-netizen-trong-bo-anh-dien-noi-y-cuc-nong-bong-vong-3-ngon-ngon-khong-chut-che-chan-chiem-het-spotlight',
component: 'page_20210611010946'},
{title: "Xuất hiện tựa game bắn khủng long, sinh tồn trong thời tiền sử cực cuốn, hứa hẹn sẽ là siêu phẩm trong thời gian tới đây",
description: "Mặc dù mới đang ở bước Early Access, thế nhưng tựa game này đã nhận được rất nhiều những bình luận tích cực.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-1623147814364791725203.jpg',
alt: "Game, Game Thu, Tin Tuc Game, Second Exhibition, ",
category: 'games',
time: '01:18 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:18 PM',
link: '/xuat-hien-tua-game-ban-khung-long-sinh-ton-trong-thoi-tien-su-cuc-cuon-hua-hen-se-la-sieu-pham-trong-thoi-gian-toi-day',
component: 'page_20210610131850'},
{title: "10 game chiến thuật cho bạn làm thống soái, chỉ huy hàng trăm vạn quân (Phần 1)",
description: "Những tựa game chiến thuật luôn có sức cuốn hút kỳ lạ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/10/photo-1-1623342880158183739885.jpg',
alt: "Game Chien Thuat, Chien Tranh The Gioi, Company Of Heroes 2, Hearts Of Iron Iv, Total War Three Kingdoms, ",
category: 'games',
time: '01:18 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:18 PM',
link: '/10-game-chien-thuat-cho-ban-lam-thong-soai-chi-huy-hang-tram-van-quan-phan-1',
component: 'page_20210610131836'},
{title: "Vì sao Pokémon huyền thoại Mewtwo dùng hang Cerulean làm nhà?",
description: "Không nhiều nơi trên thế giới Pokemon có đủ sự phù hợp để Mewtwo có thể gọi là nhà.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/11/photo-1-16233447817771943324158.png',
alt: "Pokemon, Mewtwo, ",
category: 'games',
time: '01:18 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:18 PM',
link: '/vi-sao-pokemon-huyen-thoai-mewtwo-dung-cerulean-lam-nha-cua-no',
component: 'page_20210610131823'},
{title: "Hiền Hồ gia nhập đường đua underwear của CK nhưng body \"mướt mải mlem\" thế kia thì ai chịu nổi?",
description: "Từng chút, từng chút một, netizen sẽ phải quên đi hình ảnh \"búp bê không tình yêu\" ngày nào của Hiền Hồ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/6/10/1949823033500387064801055361509519388287276n-1623335772268860895140.jpeg',
alt: "Hien Ho, Underwear, images, Calvin Klein, Bodysuit, Sao Do Thoi Trang, ",
category: 'images',
time: '01:18 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:18 PM',
link: '/hien-ho-gia-nhap-duong-dua-underwear-cua-ck-nhung-body-muot-mai-mlem-the-kia-thi-ai-chiu-noi',
component: 'page_20210610131807'},
{title: "Dàn sao đổ bộ lễ trao giải Bạch Ngọc Lan: Cúc Tịnh Y đẹp như tiên tử, đè bẹp Nghê Ni - Quan Hiểu Đồng khoe vòng 1 hết cỡ",
description: "Sự kiện Bạch Ngọc Lan tổ chức ngày 10/6 quy tụ dàn sao cực hot như Nghê Ni, Cúc Tịnh Y, Quan Hiểu Đồng, Nhậm Mẫn,...",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/10/photo-1-1623318837570830234296.jpg',
alt: "Cuc Tinh Y, Nghe Ni, Nham Man, Quan Hieu Dong, Tran Triet Vien, Sao Hoa Ngu, My Nhan Hoa Ngu, Ton Thien, Dam Tung Van, Tran Ngoc Ky, Dong Tu Kien, ",
category: 'stars',
time: '01:17 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:17 PM',
link: '/dan-sao-do-bo-le-trao-giai-bach-ngoc-lan-cuc-tinh-y-dep-nhu-tien-tu-de-bep-nghe-ni-quan-hieu-dong-khoe-vong-1-het-co',
component: 'page_20210610131755'},
]
export const dataContent15=[{title: "Kể chuyện bị bạn diễn \"bạo lực\", nàng hot girl phim 18+ Nhật Bản khiến fan thương cảm, khen ngợi sự chuyên nghiệp",
description: "Câu chuyện của cô nàng hot girl đang thu hút được rất nhiều sự chú ý từ phía các fan hâm mộ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-1623124066694828591545.jpg',
alt: "Hot Girl, Gai Xinh, Cong Dong Mang, Kana Momonogi, images, Phim Av, Phim 18, ",
category: 'images',
time: '09:25 PM',
date: '09/06/2021',
timestamp: '09/06/2021 09:25 PM',
link: '/ke-chuyen-bi-ban-dien-bao-luc-nang-hot-girl-phim-18-nhat-ban-khien-fan-thuong-cam-khen-ngoi-su-chuyen-nghiep',
component: 'page_20210609212513'},
{title: "Khó tin, game đỉnh của người Việt nhận mưa lời khen từ quốc tế, \"thèm khát\" 1 lần game mở miễn phí như này",
description: "Có những tựa game mà ngay cả cộng đồng game thủ cũng ít người biết được đó là sản phẩm do người Việt làm ra.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/10/-1623296014399440917318.jpg',
alt: "Shadow Of Death, App Store, Game Mien Phi, Tin Tuc Game, ",
category: 'games',
time: '09:25 PM',
date: '09/06/2021',
timestamp: '09/06/2021 09:25 PM',
link: '/game-dinh-cua-nguoi-viet-dang-mien-phi-tren-app-store-game-thu-nuoc-ngoai-het-loi-khen-ngoi',
component: 'page_20210609212503'},
{title: "Loạt các vũ khí trấn phái của các võ tướng trong Dynasty Warriors",
description: "Phương thiên họa kích hay bát xà mâu có lẽ đã rất quen thuộc với những người hâm mộ dòng game Tam Quốc.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/9/photo-1-1623236614015201115630.jpg',
alt: "Vu Khi, Tam Quoc, Dynasty Warriors, ",
category: 'games',
time: '09:42 AM',
date: '09/06/2021',
timestamp: '09/06/2021 09:42 AM',
link: '/loat-cac-vu-khi-tran-phai-cua-cac-vo-tuong-trong-dynasty-warriors',
component: 'page_20210609094259'},
{title: "[Trực tiếp từ UAE] Buổi tập đầu tiên của tuyển Việt Nam sau trận gặp Indonesia: Tuấn Anh, Văn Toàn cùng có mặt",
description: "Vào lúc 22h ngày 8/6, đội tuyển Việt Nam sẽ có buổi tập trên sân Shabab Al-ahli.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/untitled-1623165495924715520315.png',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Viet Nam, Tuyen Viet Nam, Tuan Anh, Van Toan, Viet Nam Nghien Nat Indonesia 4 0, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/truc-tiep-tu-uae-hinh-anh-buoi-tap-dau-tien-cua-tuyen-viet-nam-sau-tran-gap-indonesia-nin-tho-huong-ve-tuan-anh-van-toan',
component: 'page_20210608140647'},
{title: "Top 5 cầu thủ Việt có lượng người theo dõi khủng nhất trên Facebook, cái tên \"mlem mlem\" Đoàn Văn Hậu xếp thứ mấy?",
description: "Sau trận thắng 4-0 trước đội tuyển Indonesia, Facebook các cầu thủ Việt Nam được nhiều người chú ý đến, thấp nhất cũng được cả triệu người follow!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-16231527937981291522408.jpg',
alt: "Doi Tuyen Viet Nam, Cau Thu Viet Nam, Vong Loai World Cup, World Cup 2022, Nguyen Quang Hai, Luong Xuan Truong, Doan Van Hau, Cong Phuong, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/top-5-cau-thu-viet-co-luong-nguoi-theo-doi-khung-nhat-tren-facebook-cai-ten-mlem-mlem-doan-van-hau-xep-thu-may',
component: 'page_20210608140634'},
{title: "Dàn trai đẹp U23 sau 3 năm: Đồng loạt lấy vợ có con nhưng độ \"mlem mlem\" không hề suy giảm!",
description: "Bạn đã sẵn sàng nhìn lại sự thay đổi của những cầu thủ từng được cả Việt Nam hâm mộ cuồng nhiệt sau 3 năm chưa!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/1-16231658569281054746604.png',
alt: "Doi Tuyen Bong Da, Doi Tuyen Bong Da Viet Nam, World Cup 2022, U23 Viet Nam, Doi Tuyen U23 Viet Nam, Quang Hai, Doan Van Hau, Cong Phuong, Vong Loai World Cup 2022, Bui Tien Dung, Hong Duy, Duy Manh, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/dan-trai-dep-u23-sau-3-nam-dong-loat-lay-vo-co-con-nhung-do-mlem-mlem-khong-he-suy-giam',
component: 'page_20210608140622'},
{title: "Báo Thái Lan bất ngờ “oán trách”, cho rằng tuyển Việt Nam quá mạnh khiến đội nhà bị loại",
description: "Truyền thông Thái Lan cho rằng chính sự xuất sắc của tuyển Việt Nam đã góp phần khiến đội nhà phải sớm chấm dứt giấc mơ World Cup.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-162314340016446582388.jpg',
alt: "Thai Lan, Viet Nam, Vong Loai World Cup 2022, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/bao-thai-lan-bat-ngo-oan-trach-cho-rang-tuyen-viet-nam-qua-manh-khien-doi-nha-bi-loai',
component: 'page_20210608140609'},
{title: "Báo Indonesia quay sang oán trách đội nhà, điểm mặt 6 cái tên đá tồi nhất",
description: "Tờ Bola chỉ mặt điểm tên 6 cầu thủ chơi dưới kỳ vọng trong trận Indonesia thua Việt Nam 0-4.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/9/photo-5-16231748625421736336327.png',
alt: "Indonesia, Viet Nam, Dt Viet Nam, Vong Loai World Cup 2022, ",
category: 'news',
time: '2:05 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:05 PM',
link: '/bao-indonesia-quay-sang-oan-trach-doi-nha-diem-mat-6-cai-ten-da-toi-nhat',
component: 'page_20210608140554'},
{title: "6 bí kíp siêu hữu ích mà ai cũng cần học, vì chúng có thể cứu lấy bạn vào một ngày nào đó",
description: "Bạn có thể rơi vào những hoàn cảnh hiểm nghèo, hoặc đơn giản là các tình huống khiến một ngày trở nên khó khăn hơn. Làm thế nào lúc đó nhỉ?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/8/photo-1-1623131702795545224846.jpg',
alt: "Bi Kip, Chua Chay, Tinh Huong Xau Nhat, ",
category: 'news',
time: '2:05 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:05 PM',
link: '/6-bi-kip-sieu-huu-ich-ma-ai-cung-can-hoc-vi-chung-co-the-cuu-lay-ban-vao-mot-ngay-nao-do',
component: 'page_20210608140543'},
{title: "Mua chung cư 72m2, vợ chồng mới cưới tự tay thiết kế từng góc theo phong cách Hàn Quốc, chơi thêm cả bể cá ban công",
description: "Vì diện tích căn hộ không được rộng nên vợ chồng chị Nguyệt Út ưu tiên chọn tông màu nâu trắng để nhà sáng sủa và tạo cảm giác rộng rãi hơn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/5/26/18650845737681714566265132333997200191415436n-162204531648783806752.jpeg',
alt: "Hai Vo Chong, Do Noi That, Tu Thiet Ke, Be Ca, House N Home, Design Nha, ",
category: 'life',
time: '2:05 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:05 PM',
link: '/mua-chung-cu-72m2-vo-chong-moi-cuoi-tu-tay-thiet-ke-tung-goc-theo-phong-cach-han-quoc-choi-them-ca-be-ca-ban-cong',
component: 'page_20210608140529'},
{title: "Khi các nữ streamer Việt khoe vòng một lấp lửng, từ thần thái cho đến độ quyến rũ đều khiến người xem \"nghẹt thở\"",
description: "Diện bikini hay thả dáng bạo thôi là chưa đủ, các streamer còn tự tin khoe vòng một trần làm cộng đồng mạng \"nóng mặt\" mỗi khi ngắm nhìn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/6/8/mai2-1623133702689807881264.jpg',
alt: "Khoe Vong Mot, Cong Dong Mang, Vong Mot Khong Che, Vong Mot Khung, Mai Dora, Streamer, Linh Chichan, Thien Thy, Ohsusu, Vong Mot Dep, ",
category: 'images',
time: '2:05 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:05 PM',
link: '/khi-cac-nu-streamer-viet-khoe-vong-mot-lap-lung-tu-than-thai-cho-den-do-quyen-ru-deu-khien-nguoi-xem-nghet-tho',
component: 'page_20210608140515'},
{title: "Ngắm nhìn nhan sắc tuyệt phẩm của \"em gái quốc dân\" Kudo Mio, người từng hóa thân thành \"siêu nhân hồng\"",
description: "Kudo Mio là chủ nhân của những màn \"biến hình\" thành \"siêu nhân hồng\" nổi tiếng trong Mashin Sentai Kiramager.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/lai1-16231402824472146662111.jpg',
alt: "Festival Cosplay, Le Hoi Cosplay, Bo Anh Cosplay, Chum Anh Cosplay, Anh Cosplay, Nhan Vat Cosplay, Kudo Mio, Kamen Rider Ghost, Mashin Sentai Kiramager, ",
category: 'images',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/ngam-nhin-nhan-sac-tuyet-pham-cua-em-gai-quoc-dan-kudo-mio-nguoi-tung-hoa-than-thanh-sieu-nhan-hong',
component: 'page_20210608140447'},
{title: "Top 10 game bóng đá hay nhất cho anh em \"quẩy\" quên sầu",
description: "Hòa cùng không khí bóng đá ngập tràn, mời anh em thưởng thức những tựa game đỉnh nhất trong làng túc cầu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-16231625058421802311440.png',
alt: "Game Bong Da, Game Dinh, Game Hay, Tin Tuc Game, Cong Dong Mang, ",
category: 'games',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/top-10-game-bong-da-hay-nhat-cho-anh-em-quay-quen-sau',
component: 'page_20210608140437'},
{title: "SBTC Esports công bố đội hình tham dự VCS Mùa Hè 2021, Thầy Giáo Ba phán câu xanh rời: Trụ hạng được là hay rồi",
description: "Mất đi dàn sao của mình, SBTC Esports có vẻ như chỉ đặt một mục tiêu khiêm tốn tại VCS Mùa Hè 2021.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-1623169862035949007145.jpg',
alt: "Lien Minh Huyen Thoai, Sbtc Esports, games, Tin Game, Game, Lmht, Tin Tuc Game, Tin Lmht, Tin Lol, Vcs Mua He 2021, Vcs, Thay Giao Ba, ",
category: 'games',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/sbtc-esports-cong-bo-doi-hinh-tham-du-vcs-mua-he-2021-thay-giao-ba-phan-cau-xanh-roi-tru-hang-duoc-la-hay-roi',
component: 'page_20210608140425'},
{title: "Đấu Trường Chân Lý: Những đội hình bá đạo đầu mùa nhưng sử dụng bây giờ là \"tự hủy cực mạnh\"",
description: "Đây là những đội hình hồi đầu mùa 5 Đấu Trường Chân Lý đã tỏ ra vô cùng mạnh nhưng sử dụng ở hiện tại sẽ chỉ tụt rank không phanh.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/-1623132507124323700766.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Game, Tin Tuc Game, Tin Dtcl, Tin Tft, Tin Game, Riot Games, Riot, ",
category: 'games',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/dau-truong-chan-ly-nhung-doi-hinh-ba-dao-dau-mua-nhung-su-dung-bay-gio-la-tu-huy-cuc-manh',
component: 'page_20210608140412'},
{title: "Không o ép mình trong hình mẫu idol sexy \"đại trà\", nữ streamer chấp nhận làm \"người tối cổ\" tự tin với phong cách riêng",
description: "“Quay xe” đi ngược với hình tượng xây dựng trước đây của mình, nữ streamer Liên Quân được người hâm mộ ủng hộ với sự thay đổi mới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/photo-1-162314875179286100347.jpg',
alt: "Streamer, Trang Banana, Lien Quan Mobile, Hot Girl, Sexy, ",
category: 'images',
time: '2:04 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:04 PM',
link: '/khong-o-ep-minh-trong-hinh-mau-idol-sexy-dai-tra-nu-streamer-chap-nhan-lam-nguoi-toi-co-tu-tin-voi-phong-cach-rieng',
component: 'page_20210608140402'},
{title: "Hướng dẫn bảo vệ sức khỏe đi kèm ảnh bikini gợi cảm, nữ bác sĩ xinh đẹp khiến CĐM phát sốt",
description: "Nữ bác sĩ trong câu chuyện bỗng chốc trở nên vô cùng nổi tiếng chỉ sau ít ngày.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/8/photo-1-16231381931681875170061-16231383504341365539882.jpg',
alt: "Gai Xinh, Hot Girl, Nu Bac Si, Cong Dong Mang, ",
category: 'images',
time: '2:03 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:03 PM',
link: '/huong-dan-bao-ve-suc-khoe-di-kem-anh-bikini-goi-cam-nu-bac-si-xinh-dep-khien-cdm-phat-sot',
component: 'page_20210608140350'},
{title: "Đấu Trường Chân Lý: Leo rank dễ dàng hơn với mẹo \"xoay trang bị\" cho chủ lực từ kỳ thủ Thách Đấu",
description: "Việc lựa chọn trang bị cho chủ lực phù hợp với từng trận đấu là yếu tố cực kỳ quan trọng trong Đấu Trường Chân Lý.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/8/draven-162312351755518684537.png',
alt: "Dau Truong Chan Ly, Cam Nang Dau Truong Chan Ly, Riot Games, Riot, Game, Tin Game, Draven, Vo Cuc Kiem, Mordekaiser, Hecarim, ",
category: 'games',
time: '2:03 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:03 PM',
link: '/dau-truong-chan-ly-leo-rank-de-dang-hon-voi-meo-xoay-trang-bi-cho-chu-luc-tu-ky-thu-thach-dau',
component: 'page_20210608140340'},
{title: "Đấu Trường Chân Lý: 3 đội hình bỗng nhiên được tin dùng trở lại nhờ khả năng khắc chế \"meta Thây Ma\"",
description: "Những đội hình dưới đây sẽ giúp game thủ Đấu Trường Chân Lý hạ gục những team-comp xoay quanh Thây Ma khá đơn giản.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/8/yasuo-16231430857111318751965.png',
alt: "Dau Truong Chan Ly, Cam Nang Dau Truong Chan Ly, Riot Games, Game, Riot, Tin Game, Mordekaiser, Trundle, Yasuo, Karma, Riven, ",
category: 'games',
time: '2:03 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:03 PM',
link: '/dau-truong-chan-ly-3-doi-hinh-bong-nhien-duoc-tin-dung-tro-lai-nho-kha-nang-khac-che-meta-thay-ma',
component: 'page_20210608140326'},
{title: "Bố tiền vệ Tuấn Anh: Con tôi nhiều lần chấn thương rồi, nhìn Indonesia đá thế thấy sợ quá",
description: "Chỉ sau 35 phút thi đấu, Nguyễn Tuấn Anh đã phải rời sân. Trước đó, tiền vệ này liên tục phải chịu những pha vào bóng ác ý từ phía các cầu thủ Indonesia.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-16230977964252088751721.png',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Tuyen Viet Nam, Park Hang Seo, ",
category: 'news',
time: '06:39 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:39 PM',
link: '/bo-tien-ve-tuan-anh-con-toi-nhieu-lan-chan-thuong-roi-nhin-indonesia-da-the-thay-so-qua',
component: 'page_20210607183934'},
{title: "Cả MXH không ngủ để cổ vũ ĐT Việt Nam đá bóng: Gay cấn quá rồi",
description: "Có đang coi đá bóng \"khum\" mọi người ơi!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/base64-16230865264841352253184.png',
alt: "Dt Viet Nam, Viet Nam Da Bong, Indonesia, ",
category: 'news',
time: '06:39 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:39 PM',
link: '/ca-mxh-khong-ngu-de-co-vu-dt-viet-nam-da-bong-gay-can-qua-roi',
component: 'page_20210607183917'},
{title: "Cầu thủ Indonesia chắp tay xin lỗi Tuấn Anh trong đường hầm vì phạm lỗi nguy hiểm",
description: "Nguyễn Tuấn Anh bị Pratama Arhan Alif phạm lỗi nguy hiểm là tình huống đáng chú ý nhất hiệp 1 trận tuyển Việt Nam gặp Indonesia vào tối 7/6.",
src: 'https://sport5.mediacdn.vn/thumb_w/700/158855217956261888/2021/6/8/2021-6-7-doi-tuyen-viet-nam-doi-tuyen-indonesia-hieu-luong-21-1623109628124627525171.jpg',
alt: "Cau Thu Indonesia, Tuan Anh, Vong Loai World Cup, Tuyen Viet Nam, Uae, Viet Nam Thang Indonesia, ",
category: 'news',
time: '06:39 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:39 PM',
link: '/cau-thu-indonesia-chap-tay-xin-loi-tuan-anh-trong-duong-ham-vi-pham-loi-nguy-hiem',
component: 'page_20210607183906'},
{title: "Chùm ảnh: Ối giời ơi Văn Hậu đẹp trai quá!",
description: "Càng ngắm càng mê, hihi!",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/6/8/2021-5-27-doi-tuyen-viet-nam-tap-luyen-uae-sport5-hieu-luong-40-16221656002181077713714-162309236235551010895.jpg',
alt: "Van Hau, Doan Van Hau, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Nam Than, Dan Nam Than Doi Tuyen Viet Nam, ",
category: 'news',
time: '06:38 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:38 PM',
link: '/chum-anh-oi-gioi-oi-van-hau-dep-trai-qua',
component: 'page_20210607183855'},
{title: "Chứng kiến cầu thủ Indonesia liên tục vào bóng triệt hạ, \"Nam vương\" boxing Trương Đình Hoàng hiến kế cho thầy trò HLV Park Hang-seo",
description: "Nhà vô địch WBA châu Á Trương Đình Hoàng muốn các cầu thủ của tuyển Việt Nam học qua một khóa boxing để không còn ngại những đối thủ chơi bóng thô bạo.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-16230975822461769638760.png',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Vong Loai World Cup 2022, Tuyen Viet Nam, ",
category: 'news',
time: '06:38 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:38 PM',
link: '/chung-kien-cau-thu-indonesia-lien-tuc-vao-bong-triet-ha-nam-vuong-boxing-truong-dinh-hoang-hien-ke-cho-thay-tro-hlv-park-hang-seo',
component: 'page_20210607183844'},
{title: "Đây đích thị là bộ váy kém duyên nhất Cbiz hôm qua, lại còn \"tòng teng\" 1 vật ở ngay chỗ nhạy cảm!",
description: "Lẽ nào nữ nhi này đã mất nhận thức về không gian, thời gian để ăn vận cho phù hợp?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-1-1623079166395367192097.jpg',
alt: "Sao Do Thoi Trang, Tham Hoa Thoi Trang, Loi Trang Phuc, Mac Xau, Sao Xau, Sao Mac Xau, Loi An Mac, ",
category: 'images',
time: '06:38 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:38 PM',
link: '/day-dich-thi-la-bo-vay-kem-duyen-nhat-cbiz-hom-qua-lai-con-tong-teng-1-vat-o-ngay-cho-nhay-cam',
component: 'page_20210607183825'},
{title: "Duy Mạnh bóp cổ cầu thủ Indonesia sau pha phạm lỗi nguy hiểm với Tuấn Anh",
description: "Các cầu thủ Indonesia liên tục phạm lỗi bằng những pha vào bóng quyết liệt quá mức cần thiết đối với các tuyển thủ Việt Nam.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-3-16230894305991210492944.jpg',
alt: "Duy Manh, Quang Haii, Doi Tuyen Viet Nam, Cau Thu Indonesia, ",
category: 'news',
time: '06:36 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:36 PM',
link: '/duy-manh-bop-co-cau-thu-indonesia-sau-pha-pham-loi-nguy-hiem-voi-tuan-anh',
component: 'page_20210607183631'},
{title: "Fan Việt Nam chế loạt ảnh hài hước sau trận thắng Indonesia",
description: "Đội tuyển Việt Nam giành chiến thắng dễ dàng 4-0 trước đội tuyển Indonesia. Fan Việt Nam được dịp chế ảnh \"cà khịa\" lối đá thô bạo của thầy trò HLV Shin Tae-yong.",
src: 'https://sport5.mediacdn.vn/thumb_w/700/158855217956261888/2021/6/8/19348880914978784438780196833412912387709210n-1623093367726564978067.jpg',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, World Cup 2022, Tuyen Viet Nam Indonesia, Bong Da, ",
category: 'news',
time: '06:36 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:36 PM',
link: '/fan-viet-nam-che-loat-anh-hai-huoc-sau-tran-thang-indonesia',
component: 'page_20210607183618'},
{title: "Ngã ngửa nhan sắc thật của dàn sao ở sự kiện Thập Quang: Nhiệt Ba gây sốc vì da chảy xệ, Lưu Diệc Phi gây thất vọng tràn trề",
description: "Xuất hiện lộng lẫy tại sự kiện Thập Quang Thịnh Điển ngày 7/6, dàn sao Hoa ngữ như Địch Lệ Nhiệt Ba, Dương Mịch, Lưu Diệc Phi, Ngô Lỗi, Trần Hiểu,... bị dân tình soi nhan sắc nhiệt tình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/-1623061473587678019572.jpg',
alt: "Dich Le Nhiet Ba, Luu Diec Phi, Ngo Loi, La Tan, Truong Vu Ky, Tran Hieu, Tieu Chien, Phan Viet Minh, Truong Hue Van, Bach Bach Ha, Sao Hoa Ngu, My Nhan Hoa Ngu, Nhan Sac My Nhan Hoa Ngu, Mao Hieu Dong, Duong Mich, Boc Tran Nhan Sac Cua Sao, Nhan Sac That Cua Sao, ",
category: 'news',
time: '06:36 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:36 PM',
link: '/nga-ngua-nhan-sac-that-cua-dan-sao-o-su-kien-thap-quang-nhiet-ba-gay-soc-vi-da-chay-xe-luu-diec-phi-gay-that-vong-tran-tre',
component: 'page_20210607183602'},
{title: "Nhiệt Ba ngày càng táo bạo tới ngộp thở: Hết khoe lưng trần sexy lại \"bắt lú\" với váy xuyên thấu khoe vòng 1 căng tràn",
description: "Vẫn biết Địch Lệ Nhiệt Ba sở hữu sắc vóc hàng đầu Cbiz nhưng những hình ảnh trong sự kiện mới của cô nàng vẫn khiến dân tình \"xịt máu mũi\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/1900971343295717452840988115651203566335817n-1623074855708409541982.jpeg',
alt: "Dich Le Nhiet Ba, Sao Hoa Ngu, My Nhan Hoa Ngu, Nhan Sac My Nhan Hoa Ngu, Voc Dang My Nhan Hoa Ngu, Body Cua Sao, ",
category: 'images',
time: '06:35 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:35 PM',
link: '/nhiet-ba-ngay-cang-tao-bao-toi-ngop-tho-het-khoe-lung-tran-sexy-lai-bat-lu-voi-vay-xuyen-thau-khoe-vong-1-cang-tran',
component: 'page_20210607183550'},
{title: "Trông Nhiệt Ba \"gánh\" tấm váy nhìn mà phát ngốt này, ta như hiểu được tầm quan trọng của nhan sắc trong chuyện ăn mặc",
description: "Nhan sắc của Địch Lệ Nhiệt Ba như thể tấm phao cứu sinh, \"gánh\" được vô số trang phục dù có khó mặc tới mấy.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/6/7/1944697613056473045284797254835451750697360n-16230581050632067566763.jpg',
alt: "Star Style, Thoi Trang, Sao Do Thoi Trang, Dich Le Nhiet Ba, Nu Dien Vien Trung Quoc, Sao Cbiz, Loi Trang Phuc, Trang Phuc Tham Do, ",
category: 'images',
time: '06:35 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:35 PM',
link: '/nhin-nhiet-ba-ganh-tam-vay-nhin-ma-phat-ngot-nay-ta-nhu-hieu-duoc-tam-quan-trong-cua-nhan-sac-trong-chuyen-an-mac',
component: 'page_20210607183535'},
{title: "Những khoảnh khắc tuyệt đẹp của tuyển Việt Nam khiến người hâm mộ \"đổ rần rần\"",
description: "Một chiến thắng ngọt ngào đầy bản lĩnh 4-0 trước Indonesia minh chứng cho sự nỗ lực không ngừng nghỉ của tuyển Việt Nam. Thầy trò HLV Park Hang-seo đã mạnh mẽ vượt qua thử thách đầu tiên trên đất UAE.",
src: 'https://sport5.mediacdn.vn/158855217956261888/2021/6/8/6789cf399f7b6b25326a-16230908905311280246809.jpg',
alt: "Nhan Hieu Danh Cho Nam, Dong Hanh Cung Dtqgvn, Mensbiore, ",
category: 'news',
time: '06:34 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:34 PM',
link: '/nhung-khoanh-khac-tuyet-dep-cua-tuyen-viet-nam-khien-nguoi-ham-mo-do-ran-ran',
component: 'page_20210607183435'},
{title: "Nổi cáu trước trọng tài, nhưng HLV Park Hang-seo vô cùng tình cảm ôm học trò sau trận thắng Indonesia",
description: "HLV Park Hang-seo luôn có những khoảnh khắc đầy thú vị từ việc nổi cáu với trọng tài đến cử chỉ tình cảm, ấm áp dành cho học trò.",
src: 'https://sport5.mediacdn.vn/158855217956261888/2021/6/8/3a7829fa76b882e6dba9-16230937312841517797883.jpg',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Tuyen Viet Nam, Park Hang Seo, Bong Da, ",
category: 'news',
time: '06:34 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:34 PM',
link: '/noi-cau-truoc-trong-tai-nhung-hlv-park-hang-seo-vo-cung-tinh-cam-om-hoc-tro-sau-tran-thang-indonesia',
component: 'page_20210607183422'},
{title: "TIẾN LINH - Cầu thủ \"mlem mlem\" chưa có bồ hot nhất hôm nay là ai?",
description: "Với pha ghi bàn mở màn cho đội tuyển Việt Nam, cái tên Tiến Linh đang chiếm trọn spotlight trên MXH.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/anh-chup-man-hinh-2021-06-08-luc-005815-16230888291721087952933.png',
alt: "Cau Thu Dep Trai, Doi Tuyen Viet Nam, Tien Linh, Tien Linh Ghi Ban, Tien Linh Ghi Man Mo Man, Viet Nam Vs Indonesia, Viet Nam Vs Indonesia Tai Vong Loai World Cup, Viet Nam Vs Indonesia Vong Loai World Cup 2022, ",
category: 'news',
time: '06:34 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:34 PM',
link: '/tien-linh-cau-thu-mlem-mlem-chua-co-bo-hot-nhat-hom-nay-la-ai',
component: 'page_20210607183406'},
{title: "Top 10 Hoa hậu Việt Nam thức cả đêm cổ vũ Đoàn Văn Hậu thi đấu, như này không yêu phí quá",
description: "Trên Instagram cá nhân, Doãn Hải My đã đăng story khoảnh khắc về Đoàn Văn Hậu và đồng đội đồng thời bày tỏ niềm tự hào.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/1-1623093543733411031890.png',
alt: "Doan Hai My, Doan Van Hau, Dt Viet Nam, Hen Ho, Ban Gai Tin Don, Top 10 Hoa Hau Viet Nam, ",
category: 'news',
time: '06:33 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:33 PM',
link: '/top-10-hoa-hau-thuc-ca-dem-co-vu-doan-van-hau-nhu-nay-khong-yeu-phi-qua',
component: 'page_20210607183356'},
{title: "Trận bóng đi qua, BLV Biên Cương để lại \"rổ\" quote: Đây không phải bóng đá, đây là võ thuật!",
description: "Những phát biểu của BLV Biên Cương luôn thu hút sự chú ý của khán giả.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/10-1623091570504298968086.jpg',
alt: "Blv Bien Cuong, Dt Viet Nam, Da Bong, Bien Cuong, Dua Con Cua Than Gio, ",
category: 'news',
time: '06:33 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:33 PM',
link: '/tran-bong-qua-di-btv-bien-cuong-de-lai-ro-quote-day-khong-phai-bong-da-day-la-vo-thuat',
component: 'page_20210607183344'},
{title: "Văn Toàn được đồng đội cõng lên xe trở về khách sạn sau trận thắng Indonesia",
description: "Hình ảnh Văn Toàn được Văn Toản cõng lên xe buýt của đội tuyển Việt Nam sau trận thắng Indonesia đang khiến fan vô cùng lo lắng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-1623097159194116242689.jpg',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Tuyen Viet Nam, Van Toan, Cong Phuong, ",
category: 'news',
time: '06:33 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:33 PM',
link: '/van-toan-duoc-dong-doi-cong-len-xe-tro-ve-khach-san-sau-tran-thang-indonesia',
component: 'page_20210607183313'},
{title: "Xúc động hình ảnh tuyển Việt Nam đặt tay lên ngực trái, thực hiện lễ chào cờ sau gần 2 năm không thi đấu quốc tế",
description: "Đã gần 2 năm, đội tuyển Việt Nam mới lại có một trận đấu quốc tế chính thức mang lại nhiều cảm xúc như vậy.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-1623088048557732454516.jpg',
alt: "Indonesia, Viet Nam, Viet Nam Vs Indonesia, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, ",
category: 'news',
time: '06:32 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:32 PM',
link: '/xuc-dong-hinh-anh-tuyen-viet-nam-dat-tay-len-nguc-trai-thuc-hien-le-chao-co-sau-gan-2-nam-khong-thi-dau-quoc-te',
component: 'page_20210607183256'},
{title: "Đấu Trường Chân Lý: Tộc Thây Ma và Kayle nhận những nerf vô cùng nặng nề từ Riot tại bản 11.12",
description: "Khả năng lao vào tuyến sau của Thây Ma Biến Dị gần như sẽ bị loại bỏ khỏi Đấu Trường Chân Lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/4/photo-1-16227952697041628641001.jpg',
alt: "Dau Truong Chan Ly, Riot Games, Riot, Game, Tin Game, Riven, Kayle, Brand, Karma, Katarina, Leblanc, ",
category: 'games',
time: '10:40 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:40 AM',
link: '/dau-truong-chan-ly-toc-thay-ma-va-kayle-nhan-nhung-nerf-vo-cung-nang-ne-tu-riot-tai-ban-1112',
component: 'page_20210607104018'},
{title: "Thiên thần Eimi Fukada hốt hoảng khi bị fan phát hiện địa chỉ nhà trong đêm, phải chuyển đi ngay tắp lự",
description: "Eimi Fukada dường như đã rất lo lắng khi bị các fan tìm ra rằng cô nàng đang sinh sống ở đâu.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/7/photo-1-16230428242351689597927.jpg',
alt: "Eimi Fukada, Idol, ",
category: 'images',
time: '10:40 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:40 AM',
link: '/thien-than-eimi-fukada-hot-hoang-khi-bi-fan-phat-hien-dia-chi-nha-trong-dem-phai-chuyen-di-ngay-tap-lu',
component: 'page_20210607104004'},
{title: "Fan xúc động khi biết ý nghĩa dãy số trên áo Lee Kwang Soo trong tập cuối ghi hình Running Man",
description: "Ê-kíp Running Man khiến người hâm mộ rất cảm động khi tinh tế đặt con số ý nghĩa này trong tập cuối chia tay Lee Kwang Soo.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-2-1622994189590992603592-16230504527311881116045.jpeg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man Sau 11 Nam, Running Man, ",
category: 'news',
time: '10:39 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:39 AM',
link: '/fan-xuc-dong-khi-biet-y-nghia-day-so-tren-ao-lee-kwang-soo-trong-tap-cuoi-ghi-hinh-running-man',
component: 'page_20210607103948'},
{title: "Diện váy xẻ ngực sâu táo bạo, Dương Mịch khiến \"người tình\" Hứa Khải bối rối, không dám nhìn ngay trên sân khấu",
description: "Rõ ràng rất thân thiết với nhau trên phim trường nhưng Hứa Khải tỏ ra xa cách với Dương Mịch tại sự kiện hôm nay.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-1-16230572341301297509824.jpg',
alt: "Duong Mich, Sao Hoa Ngu, My Nhan Hoa Ngu, Nhan Sac My Nhan Hoa Ngu, Voc Dang My Nhan Hoa Ngu, ",
category: 'news',
time: '10:39 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:39 AM',
link: '/dien-vay-xe-nguc-sau-tao-bao-duong-mich-khien-nguoi-tinh-hua-khai-boi-roi-khong-dam-nhin-ngay-tren-san-khau',
component: 'page_20210607103935'},
{title: "Game Mobile của người Việt làm ra đứng top 1 BXH cổng game nổi tiếng Trung Quốc, vượt qua cả ĐTCL Mobile",
description: "Mới đây, tựa game Mobile Fanmade của Việt Nam đã xuất sắc đứng vị trí top 1 bảng xếp hạng cổng game nổi tiếng của Trung Quốc.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/7/photo-1-1623048377886367284082.jpg',
alt: "Hang Rong Mobile, Hang Rong Online, Hang Rong, Vng, Dau Truong Chan Ly Mobile, Dtcl Mobile, Game, Tin Tuc Game, ",
category: 'games',
time: '10:34 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:34 AM',
link: '/game-mobile-fanmade-cua-viet-nam-dung-top-1-bxh-cong-game-noi-tieng-trung-quoc-vuot-qua-ca-dtcl-mobile',
component: 'page_20210607103429'},
{title: "Ảnh “team qua đường” huyền thoại của Jennie: Cam thường mà đẹp không chê nổi, chắc mỗi sáng thích lắm khi tự soi gương",
description: "Với gương mặt đẹp đỉnh cao cùng vóc dáng hoàn hảo, không gì có thể \"dìm hàng\" được Jennie (BLACKPINK).",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/14450780615751480260076814344098544848298000n-1612697360789176231461-16230325955431241259490.jpg',
alt: "Jennie, Jennie Blackpink, Blackpink, Sao Han, Idol Kpop, Nhan Sac Sao Han, Anh Cam Thuong, ",
category: 'images',
time: '12:38 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:38 AM',
link: '/anh-team-qua-duong-huyen-thoai-cua-jennie-cam-thuong-ma-dep-khong-che-noi-chac-moi-sang-thich-lam-khi-tu-soi-guong',
component: 'page_20210607003803'},
{title: "Cúc Tịnh Y e ấp bên \"phi công\" mới, Lưu Diệc Phi \"về nhà\" cùng Trần Hiểu sau thảm họa Mulan ở lễ kỷ niệm 10 năm Tencent",
description: "Sự kiện kỷ niệm 10 năm của Tencent là dịp để hàng loạt ngôi sao tuyên truyền phim của mình, như Lưu Diệc Phi, Dương Mịch, Cúc Tịnh Y,...",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/08-1623042170784105273236.png',
alt: "Cuc Tinh Y, Tang Thuan Hy, Luu Diec Phi, Tran Hieu, Dich Le Nhiet Ba, Ngo Loi, Duong Mich, Hua Khai, Mo Nam Chi, Mong Hoa Luc, Truong Ca Hanh, Dinh Luat Tinh Yeu 8020, Hoan Lac Tung 3, Phim Hoa Ngu, Phim Trung Quoc, Phim Truyen Hinh, Thap Quang Thinh Dien 2021, Tencent, ",
category: 'images',
time: '12:37 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:37 AM',
link: '/cuc-tinh-y-e-ap-ben-phi-cong-moi-luu-diec-phi-ve-nha-cung-tran-hieu-sau-tham-hoa-mulan-o-le-ky-niem-10-nam-tencent',
component: 'page_20210607003747'},
{title: "Song Ji Hyo bất ngờ nhảy bổ ôm chặt Jong Kook, \"thuyền SpartAce\" lần nữa ra khơi!",
description: "Hành động thân mật giữa Song Ji Hyo - Kim Jong Kook khiến fan cực kỳ thích thú!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-1-16230057709232088822568.jpg',
alt: "Running Man, Song Ji Hyo, Kim Jong Kook, Lee Kwang Soo, Yoo Jae Suk, Spartace, ",
category: 'news',
time: '12:37 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:37 AM',
link: '/song-ji-hyo-bat-ngo-nhay-bo-om-chat-jong-kook-thuyen-spartace-lan-nua-ra-khoi',
component: 'page_20210607003730'},
{title: "Nữ streamer đăng hình khoe giảm cân thành công nhưng vòng 1 lớn bất thường lại “phản chủ”",
description: "Thông báo đã giảm cân nhưng vòng một nở nang hơn thường của nữ streamer mới là điều thu hút sự chú ý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/7/19495138111986911606341131705495736842107744n-1-16230345726861891383002.jpg',
alt: "Lien Quan Mobile, Streamer, Thao Nari, Cong Dong Mang, ",
category: 'images',
time: '12:37 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:37 AM',
link: '/nu-streamer-dang-hinh-khoe-giam-can-thanh-cong-nhung-vong-1-lon-bat-thuong-lai-phan-chu',
component: 'page_20210607003706'},
{title: "Sự kiện khủng Thập Quang Thịnh Điển: Dương Mịch sexy hết nấc, Lưu Diệc Phi gây thất vọng vì dừ chát so với Nhiệt Ba - Cúc Tịnh Y",
description: "Bên cạnh Dương Mịch, Lưu Diệc Phi, sự kiện Thập Quang Thịnh Điển sáng ngày 7/6 còn gây sốt bởi dàn sao hot như Cúc Tịnh Y, Tiêu Chiến,...",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-1-16230384584171718332887.jpg',
alt: "Duong Mich, Luu Diec Phi, Cuc Tinh Y, Hua Nguy Chau, Sao Hoa Ngu, Dich Le Nhiet Ba, Truong Vu Ky, My Nhan Hoa Ngu, Ngo Loi, Nhan Sac My Nhan Hoa Ngu, ",
category: 'images',
time: '12:36 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:36 AM',
link: '/cap-nhat-su-kien-khung-thap-quang-thinh-dien-duong-mich-sexy-het-nac-luu-diec-phi-gay-that-vong-vi-du-chat-so-voi-cuc-tinh-y',
component: 'page_20210607003652'},
{title: "Đấu Trường Chân Lý: 3 combo hoàn hảo giữa các quân cờ, item giúp game thủ hạ gục đối phương dễ dàng",
description: "Game thủ Đấu Trường Chân Lý có thể áp dụng những combo dưới đây để leo rank một cách vô cùng hiệu quả.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/4/28/-16195984600291477540285.jpg',
alt: "Dau Truong Chan Ly, Cam Nang Dau Truong Chan Ly, Riot Games, Riot, Game, Tin Game, Kennen, Ziggs, Thresh, Syndra, Warwick, ",
category: 'games',
time: '12:36 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:36 AM',
link: '/dau-truong-chan-ly-3-combo-hoan-hao-giua-cac-quan-co-item-giup-game-thu-ha-guc-doi-phuong-de-dang',
component: 'page_20210607003603'},
{title: "Đấu Trường Chân Lý: Không phải điều tốt, Kho Vũ Khí khả năng sẽ \"con dao hai lưỡi\" đối với game thủ",
description: "Chế độ Kho Vũ Khí nhiều khả năng sẽ khiến các game thủ “ôm hận” nếu không sử dụng hợp lý.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/7/-16230105223551699469966.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Kho Vu Khi, Riot Games, Riot, Tin Game, Tin Tuc Game, Tin Tft, Tin Dtcl, Tft, ",
category: 'games',
time: '12:35 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:35 AM',
link: '/dau-truong-chan-ly-khong-phai-dieu-tot-kho-vu-khi-kha-nang-se-con-dao-hai-luoi-doi-voi-game-thu',
component: 'page_20210607003553'},
]
export const dataContent16=[{title: "LMHT: Cố gắng sửa lỗi của phép Trừng Phạt nhưng Riot Games lại vô tình tạo ra thêm 3 bug mới",
description: "Cộng đồng LMHT cũng phải lắc đầu ngán ngẩm với pha \"chữa lợn lành thành lợn què\" này của Riot Games.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/1/21/photo-1-16101246037061748195876-1611197465456703819752.jpg',
alt: "Lien Minh Huyen Thoai, Lmht, Riot Games, Riot, Game, Tin Game, Trung Phat, Loi Lmht, Tin Lmht, Tin Lol, Viego, ",
category: 'games',
time: '12:35 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:35 AM',
link: '/lmht-co-gang-sua-loi-cua-phep-trung-phat-nhung-riot-games-lai-vo-tinh-tao-ra-them-3-bug-moi',
component: 'page_20210607003527'},
{title: "Cộng đồng Steam phát sốt với game sinh tồn mới, toàn điểm 9 với 10, lại còn miễn phí 100%",
description: "Game sinh tồn Muck đang trở thành hiện tượng trên Steam.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/7/photo-1-1623043993514384383939.jpg',
alt: "Muck, Game Sinh Ton, Cau Hinh Toi Thieu, He Dieu Hanh, ",
category: 'games',
time: '12:33 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:33 AM',
link: '/cong-dong-steam-phat-sot-voi-game-sinh-ton-moi-toan-diem-9-voi-10-lai-con-mien-phi-100',
component: 'page_20210607003313'},
{title: "Vào vai cô hầu gái sexy, nữ streamer thu hút sự chú ý của nam huấn luyện viên tuyển Liên Quân nổi tiếng",
description: "Vừa bị kẻ gian sử dụng hình ảnh sexy trục lợi, nữ streamer nóng bỏng vẫn công khai đăng tải những khoảnh khắc quyến rũ, gợi cảm.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/6/7/tuyen-thu-team-flash-3-16173573762301523385087-1623040854990973300145.jpg',
alt: "Streamer, Lien Quan Mobile, Team Flash, Hot Girl, Cong Dong Mang, ",
category: 'images',
time: '12:26 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:26 AM',
link: '/vao-vai-co-hau-gai-sexy-nu-streamer-thu-hut-su-chu-y-cua-nam-huan-luyen-vien-tuyen-lien-quan-noi-tieng',
component: 'page_20210607002607'},
{title: "Cảnh nóng bị nghi thật 100% làm Thang Duy bị \"phong sát\": Vợ chồng Lương Triều Vỹ lục đục, nữ chính có chia sẻ gây sốc",
description: "Cho đến hiện tại, cảnh nóng của Thang Duy và Lương Triều Vỹ trong Sắc, Giới vẫn khiến khán giả tranh cãi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/6/lust-caution-547b68e25e0a628-07-202004g33-13-1622995935799375128902.jpg',
alt: "Sac Gioi, Sac Gioi, Sac Gioi 2008, Luong Trieu Vy, Thang Duy, Luu Gia Linh, Ly An, Canh Nong, Canh 18, Phim Hoa Ngu, Kim Ma, Phim Trung Quoc, Phim Dien Anh, Phim Chieu Rap, ",
category: 'news',
time: '07:43 PM',
date: '06/06/2021',
timestamp: '06/06/2021 7:43 PM',
link: '/canh-nong-bi-nghi-that-100-lam-thang-duy-bi-phong-sat-vo-chong-luong-trieu-vy-luc-duc-nu-chinh-co-chia-se-gay-soc',
component: 'page_20210606194311'},
{title: "Lee Kwang Soo và hành trình ở Running Man: Khởi đầu bằng nước mưa, kết thúc đẫm nước mắt",
description: "Sau 11 năm đồng hành cùng Running Man, Lee Kwang Soo cuối cùng cũng phải nói lời chia tay ngôi nhà thứ 2 của mình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/6/photo-2-1622994189590992603592.jpg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man, Sao Han, Kpop, Running Man, ",
category: 'news',
time: '07:42 PM',
date: '06/06/2021',
timestamp: '06/06/2021 7:42 PM',
link: '/lee-kwang-soo-va-hanh-trinh-o-running-man-khoi-dau-bang-nuoc-mua-ket-thuc-dam-nuoc-mat',
component: 'page_20210606194256'},
{title: "Apple cập nhật AirTags sau những lo ngại rình rập",
description: "Apple đã phát hành một bản cập nhật phần mềm cho AirTags sau những lo ngại rằng chúng có thể được sử dụng để theo dõi mọi người một cách bí mật.",
src: 'https://ichef.bbci.co.uk/news/800/cpsprodpb/6A4A/production/_118801272_gettyimages-1315307818.jpg',
alt: "Apple, Airtags",
category: 'news',
time: '11:29 AM',
date: '06/06/2021',
timestamp: '06/06/2021 11:29 AM',
link: '/apple-cap-nhat-airstags-sau-nhung-lo-ngai-rinh-rap',
component: 'page_20210606112900'},
{title: "Mặc áo khoét sâu khoe vòng 1, nữ streamer Tốc Chiến nóng bỏng bị bạn trai kém tuổi dọa “ban\" ngay trên sóng",
description: "Không thích người yêu ăn mặc hớ hênh khi livestream, bạn trai của nữ streamer nổi giận đùng đùng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/6/anh-chup-man-hinh-2021-06-06-luc-091820-1622947842625468028111.png',
alt: "Streamer, Lai Lai, Zeros, Toc Chien, ",
category: 'images',
time: '08:48 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:48 AM',
link: '/mac-ao-khoet-sau-khoe-vong-1-nu-streamer-toc-chien-nong-bong-bi-ban-trai-kem-tuoi-doa-ban-ngay-tren-song',
component: 'page_20210606084830'},
{title: "9 con số cho thấy thế giới này còn rất nhiều sự thật siêu thú vị mà bạn chưa bao giờ biết tới",
description: "Chẳng hạn, bạn có biết mỗi ngày nước Mỹ có bao nhiêu triệu phú xuất hiện không? Nó lớn hơn bạn tưởng nhiều đấy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/6/photo-1-16229557224491540876688.jpg',
alt: "Su That, Thu Vi, ",
category: 'news',
time: '08:47 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:47 AM',
link: '/9-con-so-cho-thay-the-gioi-nay-con-rat-nhieu-su-that-sieu-thu-vi-ma-ban-chua-bao-gio-biet-toi',
component: 'page_20210606084757'},
{title: "Loạt mỹ nhân 18+ sở hữu thân hình mi nhon vẫn được vạn người mê (P.2)",
description: "Không thuộc dạng sở hữu thân hình bốc lửa, thế nhưng những cô gái này vẫn có lượng fan hâm mộ đông đảo.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/6/photo-1-16229236895341716459232.jpg',
alt: "Idol, Himari Asada, Natsu Tojo, Rikka Ono, ",
category: 'images',
time: '08:47 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:47 AM',
link: '/loat-my-nhan-18-so-huu-than-hinh-mi-nhon-van-duoc-van-nguoi-me-p2',
component: 'page_20210606084735'},
{title: "Hiker Games chính thức hé lộ giá bán gói donate từng phiên bản của tựa game 300475, bản rẻ nhất là 1.000.000 VND",
description: "Game thủ hãy chuẩn bị tiền để mua 300475.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/6/photo-1-1622912847306429666092.jpg',
alt: "Nha Phat Trien, Game Thuan Viet, Chien Dich Ho Chi Minh, 300475, Cong Dong Game Thu, ",
category: 'games',
time: '08:47 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:47 AM',
link: '/hiker-games-chinh-thuc-he-lo-gia-tung-phien-ban-cua-tua-game-300475-ban-re-nhat-chi-tu-500000-vnd',
component: 'page_20210606084722'},
{title: "LMHT xuất hiện hình thức gian lận xếp hạng mới, cộng đồng cho rằng nguyên nhân tới từ lỗ hổng client",
description: "Nhiều game thủ LMHT cũng xác nhận rằng hình thức gian lận này đang rất phổ biến ở mức rank Kim Cương - Cao Thủ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/6/photo-1-16229481139211967180953.jpg',
alt: "Lien Minh Huyen Thoai, Lmht, Riot Games, Riot, Game, Tin Game, Gian Lan Xep Hang, Client Lmht, Cong Dong Mang, ",
category: 'games',
time: '08:47 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:47 AM',
link: '/lmht-xuat-hien-hinh-thuc-gian-lan-xep-hang-moi-cong-dong-cho-rang-nguyen-nhan-toi-tu-lo-hong-client',
component: 'page_20210606084710'},
{title: "Nhảy sexy rồi tự cởi áo lưới ngay trên sóng, nữ streamer bức xúc khi bị chỉ trích: \"Xem cho sướng rồi ném đá\"",
description: "Nhưng cũng phải công nhận rằng lần lên sóng của cô nàng streamer này quả thật có phần hơi táo bạo quá thì phải.",
src: 'https://kenh14cdn.com/203336854389633024/2021/6/6/photo-5-16229479957611852385827.gif',
alt: "Streamer, Sexy, Phong Cach Sexy, Vo Thoi Han, ",
category: 'images',
time: '08:46 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:46 AM',
link: '/nhay-sexy-roi-tu-coi-ao-luoi-ngay-tren-song-nu-streamer-buc-xuc-khi-bi-chi-trich-xem-cho-suong-roi-nem-da',
component: 'page_20210606084605'},
{title: "Sana (TWICE) gây xôn xao khi diện trang phục hút mắt khoe khéo vòng 1 gợi cảm",
description: "Không hổ danh mỹ nhân đa phong cách của TWICE, Sana dù đáng yêu hay quyến rũ cũng đều cân được tất.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/6/2222286019067323305222471906153005431656325578752n-1622951862803149823242.jpg',
alt: "Sana, Twice, Sao Han, Idol, Kpop, ",
category: 'images',
time: '08:45 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:45 AM',
link: '/sana-twice-gay-xon-xao-khi-dien-trang-phuc-hut-mat-khoe-kheo-vong-1-goi-cam',
component: 'page_20210606084512'},
{title: "Thiếu nữ Sài Gòn sinh năm 2001: Vòng 1 lớn giúp tôn dáng và khiến mình trông nữ tính hơn",
description: "Cô bạn sinh năm 2001 cảm thấy tự tin với số đo cơ thể hiện tại.",
src: 'https://kenh14cdn.com/thumb_w/620/2018/10/17/screen-shot-2018-10-17-at-10255-pm-1539756232354344860934.png',
alt: "Nu Sinh, Vong 1, Cong Dong Mang, Gioi Tre, Lam Dep, Thoi Trang, ",
category: 'images',
time: '08:44 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:44 AM',
link: '/nu-sinh-sai-gon-sinh-nam-2001-tiet-lo-ly-do-co-vong-1-khung-minh-uong-sua-dau-nanh-tu-nam-lop-3',
component: 'page_20210606084411'},
{title: "Đấu Trường Chân Lý: Mẹo hay từ Thách Đấu giúp chủ lực của bạn né hết các kỹ năng diện rộng nguy hiểm",
description: "Game thủ Đấu Trường Chân Lý có thể áp dụng ngay lập tức những cách sắp xếp đội hình dưới đây.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/5/volibear-16228629609371320914625.png',
alt: "Dau Truong Chan Ly, Riot Games, Cam Nang Dau Truong Chan Ly, Game, Riot, Tin Game, Teemo, Karma, Kennen, Volibear, Sett, ",
category: 'games',
time: '09:06 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:06 AM',
link: '/dau-truong-chan-ly-meo-hay-tu-thach-dau-giup-chu-luc-cua-ban-ne-het-cac-ky-nang-dien-rong-nguy-hiem',
component: 'page_20210605190617'},
{title: "Đấu Trường Chân Lý: Riot Mortdog gợi ý việc kết hợp từ nhiều mùa khiến game thủ \"phát cuồng\"",
description: "Sẽ khó có thể tượng tượng những quân cờ vốn đã “bá đạo” ở mùa 5 sẽ như thế nào nếu được mang những ưu điểm từ các Tộc/Hệ của chúng ở các mùa trước.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/5/-1622870886726486206593.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Riot Games, Tft, Riot, Tin Game, Tin Dtcl, ",
category: 'games',
time: '09:06 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:06 AM',
link: '/dau-truong-chan-ly-riot-mortdog-goi-y-viec-ket-hop-tu-nhieu-mua-khien-game-thu-phat-cuong',
component: 'page_20210605090601'},
{title: "“CS:GO Mobile” tiến hành thử nghiệm, đã có trên Google Play, game thủ nghi vấn ông lớn này phát hành tại VN?",
description: "Mới đây, một Fanpage tại Việt Nam đã đăng tải thông tin về tựa game được cho là rất giống với CS:GO và dự đoán một ông lớn sẽ phát hành.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/5/photo-1-16228904622902071607147.jpg',
alt: "Csgo, Csgo Mobile, The Origin Mission, Garena, Vng, Tin Tuc Game, Valve, ",
category: 'games',
time: '09:05 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:05 AM',
link: '/csgo-mobile-tien-hanh-thu-nghiem-da-co-tren-google-play-game-thu-nghi-van-ong-lon-nay-phat-hanh-tai-vn',
component: 'page_20210605090549'},
{title: "Tự tụt vai áo, lau mồ hôi vòng một, nữ streamer xinh đẹp bất ngờ nhận cơn mưa donate từ người xem",
description: "Những hình ảnh siêu gợi cảm từ phía cô nàng streamer này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/2/photo-1-1622610944851179501104.gif',
alt: "Gai Xinh, Hot Girl, Streamer, Nu Streamer, Cong Dong Mang, Moon Wol, ",
category: 'images',
time: '09:05 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:05 AM',
link: '/tu-tut-vai-ao-lau-mo-hoi-vong-mot-nu-streamer-xinh-dep-bat-ngo-nhan-con-mua-donate-tu-nguoi-xem',
component: 'page_20210605090509'},
{title: "Sau lùm xùm “hở bạo”, nữ coser Việt lên tiếng: Đó là nghệ thuật chứ không phải khoe thân phản cảm!",
description: "Nữ coser cho biết trước khi đăng tải bức hình có tính chất sexy lên đã hỏi ý kiến của gia đình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/5/photo-1-16228455339111476917907.jpg',
alt: "Coser, Cosplay, Ho Bao, Nghe Thuat, Phan Cam, ",
category: 'images',
time: '09:04 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:04 AM',
link: '/sau-lum-xum-ho-bao-nu-coser-viet-len-tieng-do-la-nghe-thuat-chu-khong-phai-khoe-than-phan-cam',
component: 'page_20210605090443'},
{title: "Xuất hiện tân binh \"thấp\" nhất trong lịch sử ngành công nghiệp phim 18+, đe dọa soán ngôi Yua Mikami chỉ sau 2-3 năm",
description: "Đa số các tân binh tiềm năng ngày nay đều được mang ra để so sánh với Yua Mikami thì phải.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/photo-1-16226903340271764166493.jpg',
alt: "Gai Xinh, Hot Girl, Phim Av, images, Cong Dong Mang, Phim 18, Himari Asada, ",
category: 'images',
time: '09:04 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:04 AM',
link: '/xuat-hien-tan-binh-thap-nhat-trong-lich-su-nganh-cong-nghiep-phim-18-de-doa-soan-ngoi-yua-mikami-chi-sau-2-3-nam',
component: 'page_20210605090417'},
{title: "Ninja Gaiden chính thức trở lại vào tuần sau dành cho các game thủ đam mê \"chặt chém\"",
description: "Ninja Gaiden đã trở lại với ba tựa game dự kiến sẽ làm khó người chơi vào tháng 6 năm nay.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/5/photo-1-16228335521911854152638.jpg',
alt: "Ninja Gaiden, Koei Tecmo, Ninja Gaiden Master Collection, ",
category: 'games',
time: '09:04 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:04 AM',
link: '/ninja-gaiden-chinh-thuc-tro-lai-vao-tuan-sau-danh-cho-cac-game-thu-dam-me-chat-chem',
component: 'page_20210605090405'},
{title: "Loạt mỹ nhân 18+ sở hữu thân hình mi nhon vẫn được vạn người mê (P.1)",
description: "Không thuộc dạng sở hữu thân hình bốc lửa, thế nhưng những cô gái này vẫn có lượng fan hâm mộ đông đảo.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/5/photo-1-16228818803121054981855.jpg',
alt: "Idol, Yui Nagase, Yura Kano, Mia Nanasawa, ",
category: 'images',
time: '09:03 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:03 AM',
link: '/loat-my-nhan-18-so-huu-than-hinh-mi-nhon-van-duoc-van-nguoi-me-p1',
component: 'page_20210605090338'},
{title: "Valorant liên tục \"đạt đỉnh\", Riot hé lộ ra mắt phiên bản Mobile khiến game thủ phấn khích",
description: "Trung bình trong 1 tháng, số lượng người Valorant đã đạt tới đỉnh 14 triệu người chơi. Không chỉ dừng lại ở đó, Riot còn đang chuẩn bị mang Valorant lên điện thoại.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/4/photo-1-162279923339857968698.jpg',
alt: "Valorant, Riot, Tin Tuc Game, Cong Dong Mang, Valorant Mobile, ",
category: 'games',
time: '09:01 AM',
date: '05/06/2021',
timestamp: '05/06/2021 9:01 AM',
link: '/valorant-lien-tuc-dat-dinh-riot-he-lo-ra-mat-phien-ban-mobile-khien-game-thu-phan-khich',
component: 'page_20210605090159'},
{title: "Chấp nhận không mặc áo để quảng cáo sản phẩm, nữ streamer Liên Quân chính thức lập \"kỷ lục\" về sự nóng bỏng của mình",
description: "Pha quảng cáo sản phẩm này của nữ streamer đúng là có một không hai!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/4/12001053527346369835233625612492553298947301n-1622801084776974662585.jpg',
alt: "Streamer, Lien Quan, Nude, Nong Bong, Hot Girl, ",
category: 'images',
time: '04:44 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:44:00',
link: '/chap-nhan-khong-mac-ao-de-quang-cao-san-pham-nu-streamer-lien-quan-chinh-thuc-lap-ky-luc-ve-su-nong-bong-cua-minh',
component: 'page_20210604044426'},
{title: "Khám phá thị trường quảng cáo game nửa đầu năm 2021",
description: "Game di động vẫn là lựa chọn hàng đầu của nhiều người bởi vì tính tiện lợi của nó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/-1622697635625506697225.jpg',
alt: "Game, Game Thu, Tin Tuc Game, ",
category: 'news',
time: '04:43 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:43:00',
link: '/kham-pha-thi-truong-quang-cao-game-nua-dau-nam-2021',
component: 'page_20210604044355'},
{title: "Chia tay bạn trai, mỹ nhân Nhật Bản lẳng lặng nộp đơn đăng ký làm diễn viên 18+",
description: "Remu Suzumori đã có chút chia sẻ về quá khứ của cô nàng và những câu chuyện xung quanh đời sống của mình.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/4/photo-1-16227968502611694022836.jpg',
alt: "images, Remu Suzumori, ",
category: 'images',
time: '04:43 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:43:00',
link: '/chia-tay-ban-trai-my-nhan-nhat-ban-lang-lang-nop-don-dang-ky-lam-dien-vien-18',
component: 'page_20210604044342'},
{title: "Đấu Trường Chân Lý: Leo top là dễ với những đội hình tập hợp những \"Wonder Woman\" cực mạnh ở mùa 5",
description: "Nếu đã cảm thấy chán những công thức đội hình truyền thống, bạn hoàn toàn có thể trải nghiệm thử thách mới với những lối chơi tiêu chuẩn của riêng mình.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/4/-16227560516681426978234.jpg',
alt: "Dau Truong Chan Ly, Dtcl, Doi Hinh Dtcl, Game, Tin Game, Tin Tuc Game, Tin Dtcl, Tft, Cam Nang Dtcl, ",
category: 'games',
time: '04:43 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:43:00',
link: '/dau-truong-chan-ly-leo-top-la-de-voi-nhung-doi-hinh-tap-hop-nhung-wonder-woman-cuc-manh-o-mua-5',
component: 'page_20210604044331'},
{title: "Ghim ngay những câu nói truyền cảm hứng trong Haikyuu!! để \"đếch sợ gì hết\" mà tiến lên",
description: "Dưới đây là một số câu nói đã tạo nên tiếng vang của anime/manga Haikyuu!!.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/4/photo-1-16227860348861596575077.jpg',
alt: "Haikyuu, Chang Khong Lo Ti Hon, Cau Noi Hay Haikyuu, Ukai Keishin, ",
category: 'news',
time: '04:43 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:43:00',
link: '/ghim-ngay-nhung-cau-noi-truyen-cam-hung-trong-haikyuu-de-dech-so-gi-het-ma-tien-len',
component: 'page_20210604044318'},
{title: "Loạt khoảnh khắc quên tắt camera trên sóng và các sự cố khó đỡ của những nữ streamer nổi tiếng",
description: "Quên tắt camera là một trong những sai lầm cơ bản của không ít các streamer.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/26/photo-1-16167481645331042568720.jpg',
alt: "Streamer, Camera, Su Co, Noi Am Anh, Hinh Anh Phan Chieu, Hinh Anh Nhay Cam, ",
category: 'images',
time: '04:42 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:42:00',
link: '/loat-khoanh-khac-quen-tat-camera-tren-song-va-cac-su-co-kho-do-cua-nhung-nu-streamer-noi-tieng',
component: 'page_20210604044225'},
{title: "Lan Ngọc đã khoe ảnh diện đồ bơi nóng bỏng đón Hè, tiện thể cho “bé mỡ” đi chơi cùng cho vui",
description: "Body dễ thương của Ninh Dương Lan Ngọc khiến các fans không ngừng \"trêu ghẹo\" vì quá dễ thương!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/26/photo-1-16167676833932083825842.jpg',
alt: "Sao Viet, Showbiz Viet, Ninh Duong Lan Ngoc, Lan Ngoc, ",
category: 'images',
time: '04:41 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:41:00',
link: '/lan-ngoc-da-khoe-anh-dien-do-boi-nong-bong-don-he-tien-the-cho-be-mo-di-choi-cung-cho-vui',
component: 'page_20210604044125'},
{title: "Hoa khôi tiếp viên Vietnam Airlines: Không hở thì thôi, chứ hở là phải \"xịt máu mũi\"",
description: "Thường xuất hiện trong bộ đồng phục tiếp viên hàng không nên hình ảnh gợi cảm của Dương Thu Thảo khiến nhiều người trầm trồ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/16/duongthaooo151880898115856967157405764542858277423554n-16159055909201675405743.jpg',
alt: "Tiep Vien Hang Khong, Duong Thu Thao, Sexy, Dai Hoi Sexy, Goi Cam, Gai Xinh, Hot Girl, ",
category: 'images',
time: '04:40 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:40:00',
link: '/hoa-khoi-tiep-vien-hang-khong-buoc-vao-dai-hoi-sexy-voi-vong-1-cang-day-lap-lo',
component: 'page_20210604044036'},
{title: "Gái xinh nổi tiếng với loạt vlog trên ô tô khoe ảnh quá bạo, phải \"zoom\" sát rạt vòng 1 mới chịu",
description: "Xem ảnh của Phương Uyên xong mà không đỏ mặt mới lạ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/17/1605698522582714691872591309494835808438833n-1615981035635385712321.jpg',
alt: "Mang Xa Hoi, Phuong Uyen, Gai Xinh, Cap Doi Lam Vlog Tren O To, ",
category: 'images',
time: '04:39 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:39:00',
link: '/gai-xinh-noi-tieng-voi-loat-vlog-tren-o-to-khoe-anh-qua-bao-phai-zoom-sat-rat-vong-1-moi-chiu',
component: 'page_20210604043950'},
{title: "Ninh Dương Lan Ngọc diện bikini khoe body nóng hơn ngày hè, ai ngờ lướt xuống đôi chân \"té ngang\" vì chi tiết này",
description: "Mới gia nhập lại đường đua bikini chào hè nhưng Lan Ngọc đã bị netizen nhận xét thiếu đầu tư, hoặc là chỉ vì... không để ý mà thôi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/21/1602542722906584957608686882563716759127501o-1616329288396660640978.jpg',
alt: "Lan Ngoc, Ninh Duong Lan Ngoc, Sao Viet, Vbiz, Showbiz Viet, Body Cua Sao, Body Sao, ",
category: 'images',
time: '04:38 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:38:00',
link: '/ninh-duong-lan-ngoc-dien-bikini-khoe-body-nong-hon-ngay-he-ai-ngo-luot-xuong-doi-chan-te-ngang-vi-chi-tiet-nay',
component: 'page_20210604043806'},
{title: "Viết tus thả thính táo bạo, nữ streamer gợi cảm khẳng định: Đừng nhìn vẻ ngoài đánh đồng tất cả!",
description: "Vào vai nữ tài xế xe tải sexy, nữ streamer thu hút hàng nghìn lượt xem trực tiếp bởi khả năng “thả thính” ngọt ngào.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/22/photo-1-16164112929921059165438.png',
alt: "Streamer, Linh Chichan, Nu Tai Xe, An Mac Sexy, Xem Truc Tiep, ",
category: 'images',
time: '04:36 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:36:00',
link: '/viet-tus-tha-thinh-tao-bao-nu-streamer-goi-cam-khang-dinh-dung-nhin-ve-ngoai-danh-dong-tat-ca',
component: 'page_20210604043625'},
{title: "Sợ lộ nội y sẽ bị phạt, nữ streamer \"outplay\" bằng cách không mặc, buộc áo hờ hững che vòng một",
description: "Khoảnh khắc đầy gợi cảm của cô nàng streamer khiến cho không ít fan phải chảy máu mũi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/25/photo-1-16166599985231619238366.jpg',
alt: "Nguoi Ham Mo, Khoe Vong Mot, Streamer, Stream, Han Quoc, Game Thu, Noi Y, Nu Streamer, Nen Tang, Cao Thu, Nu Streamer Xinh Dep, ",
category: 'images',
time: '04:35 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:35:00',
link: '/so-lo-noi-y-se-bi-phat-nu-streamer-outplay-bang-cach-khong-mac-buoc-ao-ho-hung-che-vong-mot',
component: 'page_20210604043529'},
{title: "Thánh nữ\" Dota 2 mừng tuổi mới, chiêu đãi fan bằng bộ ảnh khoe vòng một cực phẩm trên Instagram",
description: "Người mẫu Jun Vũ - game thủ Dota 2 - một lần nữa khiến người hâm mộ phải trầm trồ trước vẻ đẹp và thần thái đỉnh cao.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/4/junvu7-16228229722301879983909.png',
alt: "Nguoi Ham Mo, Cong Dong Game Thu, Cong Dong Mang, Than Hinh Dong Ho Cat, Streamer, Game Thu, Cao Thu, Dota 2, Jun Vu, ",
category: 'images',
time: '04:34 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:34:00',
link: '/thanh-nu-dota-2-mung-tuoi-moi-chieu-dai-fan-bang-bo-anh-khoe-vong-mot-cuc-pham-tren-instagram',
component: 'page_20210604043413'},
{title: "Mi Vân \"đu\" trend TikTok với Bào Ngư: Con gái sắp thành thiếu nữ mà sao chị không chịu già đi một xíu?",
description: "Xem clip thì trông rõ chuẩn 2 chị em chứ 2 mẹ con gì đâu!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/3/15/12974030113181736618716562919128715140970729n-1615789595911379304573.jpg',
alt: "Mi Van, Hot Girl, Hot Girl Doi Dau, Con Gai Mi Van, Chi Dep, ",
category: 'images',
time: '04:33 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:33:00',
link: '/mi-van-du-trend-tiktok-voi-bao-ngu-con-gai-sap-thanh-thieu-nu-ma-sao-chi-khong-chiu-gia-di-mot-xiu',
component: 'page_20210604043337'},
{title: "Ngọc Trinh trình làng BST nội y bị tố đạo nhái Victoria\"s Secret: Thiết kế và \"giá rổ\" thế nào?",
description: "Với khoảng 500k là bạn đã sắm được trọn bộ đồ lót của Venus\" Secret.",
src: 'https://afamilycdn.com/150157425591193600/2021/6/2/1879373681503831137856513896795404376676089n-162263222469382786144.jpg',
alt: "Ngọc Trinh",
category: 'images',
time: '10:10 AM',
date: '03/06/2021',
timestamp: '03/06/2021 10:10:00',
link: '/ngoc-trinh-trinh-lang-bst-noi-y-bi-to-dao-nhai-victorias-secret-thiet-ke-va-gia-ro-the-nao',
component: 'page_202106031010'},
{title: "Linh Chichan diện trang phục thả rông tới 90% vòng một, nữ streamer Việt nhận về nhiều ý kiến trái chiều",
description: "Thời trang khi ra đường của cô nàng streamer cũng khiến khá nhiều người phải chảy máu mũi.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/1/photo-1-16225193754541692620860.jpg',
alt: "Linh Chichan",
category: 'images',
time: '03:05 AM',
date: '03/06/2021',
timestamp: '03/06/2021 03:05:00',
link: '/linh-chichan-dien-trang-phuc-tha-rong-toi-90-vong-mot-nu-streamer-viet-nhan-ve-nhieu-y-kien-trai-chieu',
component: 'page_202106030950'},
{title: "2 game miễn phí cho PlayStation 4 trong tháng 6",
description: "Với 2 trò chơi này, game thủ PS4 sẽ thoải mái giải trí trong những ngày nghỉ hè dài sắp tới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/photo-1-1622718503123169428884.jpg',
alt: "PlayStation",
category: 'games',
time: '07:00 PM',
date: '03/06/2021',
timestamp: '03/06/2021 19:00:00',
link: '/2-game-mien-phi-cho-playstation-4-trong-thang-6',
component: 'page_202106030700'},
{title: "Đại gia Đức Huy lộ hint du lịch với tình trẻ kém 27 tuổi, chính là địa điểm tuyên bố \"không có em, anh có em khác\"",
description: "Dù đã đăng ảnh cách nhau cả 4 - 5 ngày nhưng hint hẹn hò của đại gia Đức Huy và tình tin đồn kém 27 tuổi vẫn rõ mồn một.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/3/0-1622697731272517784711.jpg',
alt: "Cam Dan",
category: 'images',
time: '06:53 PM',
date: '03/06/2021',
timestamp: '03/06/2021 18:53:00',
link: '/dai-gia-duc-huy-lo-hint-du-lich-voi-tinh-tre-kem-27-tuoi-chinh-la-dia-diem-tuyen-bo-khong-co-em-anh-co-em-khac',
component: 'page_20210603065324'},
{title: "Bị chế giễu vì nặng tới 1 tạ, nữ YouTuber giảm 30kg thần tốc, lột xác gợi cảm tới khó tin",
description: "Hành trình biến đổi nhan sắc của cô nàng YouTuber này không khỏi khiến cho các đấng mày râu phải lác mắt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/5/23/photo-1-1621763998246465890053.png',
alt: "Nayoon",
category: 'images',
time: '06:52 PM',
date: '03/06/2021',
timestamp: '03/06/2021 18:52:00',
link: '/bi-che-gieu-vi-nang-toi-1-ta-nu-youtuber-giam-30kg-than-toc-lot-xac-goi-cam-toi-kho-tin',
component: 'page_20210603065223'},
{title: "Nợ nần chồng chất, lừa cả tiền fan, nữ streamer xinh đẹp tuyên bố cùng đường, đóng phim 18+ để trả nợ",
description: "Rất nhiều người đã phải cảm thấy bất ngờ trước dự định này của cô nàng streamer.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/2/photo-1-16226287706571521534897.jpg',
alt: "Streamer",
category: 'images',
time: '06:50 PM',
date: '03/06/2021',
timestamp: '03/06/2021 18:50:00',
link: '/no-nan-chong-chat-lua-ca-tien-fan-nu-streamer-xinh-dep-tuyen-bo-cung-duong-dong-phim-18-de-tra-no',
component: 'page_20210603065007'},
{title: "Hot girl có khuôn ngực hơn 1m bất ngờ tiết lộ tin sốc: đang xạ trị ung thư, xin cư dân mạng ngưng body shaming",
description: "Thông tin đang điều trị ung thư từ phía hot girl ngực \"khủng\" khiến fan của cô nàng không ngừng lo lắng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/1/photo-1-16225441898391637114654.jpg',
alt: "Hot Girl",
category: 'images',
time: '05:46 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:46:00',
link: '/hot-girl-co-khuon-nguc-hon-1m-bat-ngo-tiet-lo-tin-soc-dang-xa-tri-ung-thu-xin-cu-dan-mang-ngung-body-shaming',
component: 'page_20210603054621'},
{title: "Ken Shimizu chỉ ra \"đối tác\" lợi hại nhất từng gặp, khiến anh chàng phải đầu hàng chỉ sau 3 phút, không dám đóng cùng lần hai",
description: "Tới cả Ken Shimizu cũng phải nể phục cô nàng này thì có lẽ chúng ta cũng đủ hiểu độ \"bá đạo\" rồi đấy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/5/31/photo-1-16224524093421609918764.png',
alt: "Gai Xinh",
category: 'news',
time: '05:45 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:45:00',
link: '/ken-shimizu-chi-ra-doi-tac-loi-hai-nhat-tung-gap-khien-anh-chang-phai-dau-hang-chi-sau-3-phut-khong-dam-dong-cung-lan-hai',
component: 'page_20210603054509'},
{title: "Những tựa game hay nhất trong 10 năm qua theo bình chọn của game thủ",
description: "Hãy cùng các game thủ nhìn lại quãng đường 10 năm qua và điểm tên những tựa game được đánh giá hay nhất trong giai đoạn này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/2/photo-1-16226095087411103493431.jpg',
alt: "Game",
category: 'games',
time: '05:43 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:43:00',
link: '/nhung-tua-game-hay-nhat-trong-10-nam-qua-theo-binh-chon-cua-game-thu',
component: 'page_20210603054326'},
{title: "3 CPU giá rẻ dưới 2 triệu nhưng vẫn chơi game ngon mà không cần card đồ họa",
description: "Những CPU tích hợp nhân đồ họa mạnh đang trở thành cứu cánh cho game thủ.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/photo-1-16226973043441708766718.jpg',
alt: "Card Man Hinh",
category: 'tech',
time: '05:41 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:41:00',
link: '/3-cpu-gia-re-duoi-2-trieu-nhung-van-choi-game-ngon-ma-khong-can-card-do-hoa',
component: 'page_20210603054155'},
{title: "Hé lộ kết cấu cốt truyện của game thuần Việt 300475",
description: "300475 đang trong quá trình phát triển đúng dự kiến.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/photo-1-1622708652103288680148.jpg',
alt: "300475",
category: 'games',
time: '05:40 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:40:00',
link: '/he-lo-ket-cau-cot-truyen-cua-game-thuan-viet-300475',
component: 'page_20210603054034'},
{title: "Tải ngay game Turn-Based cực đỉnh Warhammer Quest: Silver Tower, miễn phí 100%",
description: "Warhammer Quest: Silver Tower là cái tên không thể bỏ qua với người yêu thích thể loại chiến thuật theo lượt.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/3/photo-1-1622716375224530270223.jpg',
alt: "Warhammer Quest Silver Tower",
category: 'games',
time: '05:37 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:37:00',
link: '/tai-ngay-game-turn-based-cuc-dinh-warhammer-quest-silver-tower-mien-phi-100',
component: 'page_20210603053727'},
]
export const dataContent17=[{title: "Gấu Kami, nữ streamer gần 7 triệu lượt follow TikTok bị giả mạo tài khoản hàng loạt, thậm chí bị lợi dụng hình ảnh để lừa đảo",
description: "Gấm Kami - nữ streamer/TikToker cực hot hiện nay đang bị nhiều kẻ gian giả mạo tài khoản TikTok và mục đích không chỉ là câu view",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/2/photo-1-1622620397947621513281.jpg',
alt: "Gấu Kami",
category: 'news',
time: '03:05 AM',
date: '03/06/2021',
timestamp: '03/06/2021 03:05:00',
link: '/gau-kami-nu-streamer-gan-7-trieu-luot-follow-tiktok-bi-gia-mao-tai-khoan-hang-loat-tham-chi-bi-loi-dung-hinh-anh-de-lua-dao',
component: 'page_202106030305'},
{title: "Ngọc Trinh vô tư đăng clip chỉnh sửa nội y, để lộ cả đồ bảo hộ bên trong nhìn mà \"thót tim\"",
description: "Ngọc Trinh tiếp tục gây tranh cãi vì hành động vô tư của mình.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/6/2/base64-16226096789721565082138.png',
alt: "Ngọc Trinh",
category: 'images',
time: '01:19 PM',
date: '02/06/2021',
timestamp: '02/06/2021 13:19:00',
link: '/ngoc-trinh-vo-tu-dang-clip-chinh-sua-noi-y-de-lo-ca-do-bao-ho-ben-trong-nhin-ma-thot-tim',
component: 'page_202106021319'},
{title: "One Piece: Dù đã chết nhưng sức mạnh của 6 nhân vật huyền thoại này cho đến nay vẫn chưa ai vượt qua nổi",
description: "Thế hệ hải tặc mới vẫn chưa ai có thể vượt qua được những tượng đài sức mạnh trong One Piece này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/2/photo-1-16226084298312007960851.jpg',
alt: "One Piece",
category: 'news',
time: '12:23 PM',
date: '02/06/2021',
timestamp: '02/06/2021 12:23:00',
link: '/one-piece-du-da-chet-nhung-suc-manh-cua-6-nhan-vat-huyen-thoai-nay-cho-den-nay-van-chua-ai-vuot-qua-noi',
component: 'page_202106021223'},
{title: "Đấu Trường Chân Lý: Học hỏi những mẹo độc khi chơi Sett \"One Punch Man\" từ kỳ thủ top 8 Thách Đấu",
description: "Những mẹo dưới đây sẽ giúp game thủ Đấu Trường Chân Lý leo rank hiệu quả hơn với việc chơi xoay quanh Sett.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/2/sett-1622624730224552537391.jpg',
alt: "Đấu Trường Chân Lý",
category: 'games',
time: '11:59 PM',
date: '02/06/2021',
timestamp: '02/06/2021 23:59:00',
link: '/dau-truong-chan-ly-hoc-hoi-nhung-meo-doc-khi-choi-sett-one-punch-man-tu-ky-thu-top-8-thach-dau',
component: 'page_202106021159'},
{title: "Sam bị tuột bikini trên sóng, nữ YouTuber vội vàng che đậy nhưng vẫn hớ hênh, lên tiếng trần tình tại \"vòng một quá to\"",
description: "Tai nạn của cô nàng YouTuber đang khiến khá nhiều người cảm thấy bất ngờ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/2/photo-1-1622604324537468863202.jpg',
alt: "Streamer",
category: 'images',
time: '10:31 AM',
date: '02/06/2021',
timestamp: '02/06/2021 10:31:00',
link: '/sam-bi-tuot-bikini-tren-song-nu-youtuber-voi-vang-che-day-nhung-van-ho-henh-len-tieng-tran-tinh-tai-vong-mot-qua-to',
component: 'page_202106021031'},
{title: "Hợp tác hãng xe McLaren, PUBG Mobile tung event hấp dẫn, siêu tốc bậc nhất làng game tháng 6",
description: "Có thể nói cơ hội trải nghiệm siêu xe McLaren ngoài đời chưa bao giờ dễ đến thế đối với cộng đồng game thủ PUBG Mobile trong mùa hè này.",
src: 'https://channel.mediacdn.vn/thumb_w/640/2021/6/2/photo-1-1622618650933658080991.jpg',
alt: "PUBG Mobile",
category: 'games',
time: '03:30 AM',
date: '02/06/2021',
timestamp: '02/06/2021 03:30:00',
link: '/hop-tac-hang-xe-mclaren-pubg-mobile-tung-event-hap-dan-sieu-toc-bac-nhat-lang-game-thang-6',
component: 'page_202106020330'},
{title: "Vũ Thị Anh Thư lộ ảnh thời chưa phẫu thuật thẩm mỹ?",
description: "So sánh ảnh trước đây, nhiều người cho rằng Vũ Thị Anh Thư đã đụng chạm \"dao kéo\" nhiều bộ phận trên cơ thể để có nhan sắc như hiện tại.",
src: 'https://sohanews.sohacdn.com/160588918557773824/2021/6/1/v2-16225299233441943712939.jpg',
alt: "Vũ Thị Anh Thư",
category: 'images',
time: '07:00 PM',
date: '01/06/2021',
timestamp: '01/06/2021 19:00:00',
link: '/vu-thi-anh-thu-lo-anh-thoi-chua-phau-thuat-tham-my',
component: 'page_202106011900'},
]
