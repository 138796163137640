export const data_travel=[{title: "U mê chùm ảnh các địa điểm nổi tiếng Nhật Bản lên anime Your Name: Vẻ đẹp nao lòng không khác gì đời thực!",
description: "Các họa sĩ của anime Your Name đình đám đã thực sự rất tâm huyết để tái hiện lại khung cảnh tuyệt đẹp của thành phố thực.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/roppongi-art-center-2-16284292604101771625758.jpg',
alt: "Your Name (Kimi no Na wa),Your Name,anime,phim anime,Your Name (2016),",
category: 'travel',
time: '10:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:21 PM',
link: '/u-me-chum-anh-cac-dia-diem-noi-tieng-nhat-ban-len-anime-your-name-ve-dep-nao-long-khong-khac-gi-doi-thuc',
component: 'page_20210808222113'},
{title: "Cận cảnh thành phố hẹp nhất thế giới, kiến trúc độc đáo ai cũng khen lạ nhưng bảo ở lại thì... thôi",
description: "Nói chung là được cái tiếng chứ không được cái miếng nào cả!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/bst4w0ghy7tsfghs-1628167401897111052670.jpg',
alt: "kiến trúc độc đáo,mạng xã hội Trung Quốc,relax,thành phố,kì lạ,",
category: 'travel',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/can-canh-thanh-pho-hep-nhat-the-gioi-kien-truc-doc-dao-ai-cung-khen-la-nhung-bao-o-lai-thi-thoi',
component: 'page_20210805084555'},
{title: "7 địa điểm nổi tiếng nhất trong Thần thoại Hy Lạp mà bạn hoàn toàn có thể đặt chân đến",
description: "Ít ai biết rằng, có rất nhiều địa điểm trong thần thoại Hy Lạp được dựa trên những nơi có thật ngoài đời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/2/altar-of-zeus-at-mount-ida-ancient-anatolia-1627888102763439783445.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI HY LẠP,TRUYỀN THUYẾT,",
category: 'travel',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/7-dia-diem-noi-tieng-nhat-trong-than-thoai-hy-lap-ma-ban-hoan-toan-co-the-dat-chan-den',
component: 'page_20210803111103'},
{title: "Hàng loạt homestay, villa Đà Lạt \"kêu cứu\" giữa mùa dịch: Oằn mình gồng gánh các chi phí trong khi doanh thu gần như bằng 0",
description: "Sau hàng loạt đợt dịch bệnh, các cơ sở lưu trú ở Đà Lạt đang phải đối mặt với vô vàn khó khăn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/20/2-16267981477971739455427.jpeg',
alt: "đà lạt,homestay,homestay đà lạt,cơ sở lưu trú Đà Lạt,",
category: 'travel',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/hang-loat-homestay-villa-da-lat-keu-cuu-giua-mua-dich-chat-vat-gong-ganh-cac-chi-phi-trong-khi-doanh-thu-gan-nhu-bang-0',
component: 'page_20210729162137'},
]
