import React from "react";

// eslint-disable-next-line
import { BrowserRouter as Switch, Route } from "react-router-dom";
// eslint-disable-next-line
import * as pages from "storages/content/2021/10";

export default function Pages202110() {
  return <Switch></Switch>;
}
