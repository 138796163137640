import React from "react";

// eslint-disable-next-line
import { BrowserRouter as Switch, Route } from "react-router-dom";
// eslint-disable-next-line
import * as pages from "storages/content/2021/09";

export default function Pages202109() {
  return <Switch></Switch>;
}
