export const data_stars=[{title: "4 nàng Thái tử phi của màn ảnh Hoa ngữ: Bành Tiểu Nhiễm - Bạch Lộc khổ hết phần thiên hạ, trùm cuối lẳng lơ không ai bằng",
description: "Đây đều là những nàng Thái tử phi tuyệt sắc của màn ảnh Hoa ngữ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/24046443911759036162424096988002265811029896n-1630078071902378160743.jpg',
alt: "Bạch Lộc,Bành Tiểu Nhiễm,Trương Thiên Ái,Lý Khải Hinh,Trường An Như Cố (2021),Trường An Như Cố,Thái Tử Phi Thăng Chức Ký (2015),Đông Cung (2019),Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/4-nang-thai-tu-phi-cua-man-anh-hoa-ngu-banh-tieu-nhiem-bach-loc-kho-het-phan-thien-ha-trum-cuoi-lang-lo-khong-ai-bang',
component: 'page_20210828085141'},
{title: "Hôm trước bị soi lộ \"bé mỡ\" khi mặc corset, nay Địch Lệ Nhiệt Ba có màn phục thù ngầu đét, kín đáo mà xịn mịn hơn nhiều",
description: "Style lần này của Địch Lệ Nhiệt Ba trông xịn xò hơn hẳn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/28/23374400812384769432478543129952877572888388n-1630153654584493135419.jpg',
alt: "Địch Lệ Nhiệt Ba,sao Trung,sao Hoa ngữ,star style,corset,",
category: 'stars',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/hom-truoc-bi-soi-lo-be-mo-khi-mac-corset-nay-dich-le-nhiet-ba-co-man-phuc-thu-ngau-det-kin-dao-ma-xin-min-hon-nhieu',
component: 'page_20210828085124'},
{title: "10 cặp đôi lệch tuổi vẫn chemistry bùng nổ ở phim Hàn: Sốc nhất là Yoona với \"ông chú\" hơn 21 tuổi",
description: "Chênh lệch 10 - 12 tuổi đã là gì, một số cặp đôi phim Hàn còn lệch nhau tận 20 tuổi luôn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-1630141592063467129612.jpg',
alt: "kang ha neul,Kim Go Eun,ji chang wook,song seung hun,kim yoo jung,lee sun kyun,nam goong min,lee byung hun,Uhm Jung Hwa,Phim Hàn Quốc,",
category: 'stars',
time: '08:51 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:51 AM',
link: '/10-cap-doi-lech-tuoi-van-chemistry-bung-no-o-phim-han-soc-nhat-la-yoona-voi-ong-chu-hon-21-tuoi',
component: 'page_20210828085108'},
{title: "Sốc tận óc với loạt gameshow đậm chất 18+ của Nhật Bản: Cởi đồ, khỏa thân ngay trên sóng truyền hình!",
description: "Nhiều gameshow Nhật Bản khiến khán giả phải đỏ mặt vì những tình tiết cực kỳ nhạy cảm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/photo-1-16300606420182064502388.jpg',
alt: "show nhật bản,tv show,gameshow 18+,Cao Thủ Cởi Đồ,Be Cute Or Get Pie,gây tranh cãi,nhạy cảm,sex,The Bum Game,Underessing Her With Your Eyes,Boiling Bath,",
category: 'stars',
time: '08:48 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:48 AM',
link: '/soc-tan-oc-voi-loat-gameshow-dam-chat-18-cua-nhat-ban-coi-do-khoa-than-ngay-tren-song-truyen-hinh',
component: 'page_20210828084837'},
{title: "Đúng là chỉ Jennie (BLACKPINK) làm được: Đang hóa \"bánh bèo\" trong trẻo, 5s sau sexy \"xịt máu\" vì lộ vòng 1 khủng trắng nõn",
description: "Vẻ đẹp ngây thơ pha lẫn với gợi cảm của Jennie (BLACKPINK) chính là lý do khiến fan u mê cô \"không lối thoát\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/e9tu-cowyaepq1u-16300567539681572243994.jpg',
alt: "jennie,Jennie (BlackPink),Blackpink,sao Hàn,nhan sắc sao Hàn,nhan sắc Jennie,blackpink summer diary 2021,idol Kpop,",
category: 'stars',
time: '08:47 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:47 AM',
link: '/dung-la-chi-jennie-blackpink-lam-duoc-dang-hoa-banh-beo-trong-treo-5s-sau-sexy-xit-mau-vi-lo-vong-1-khung-trang-non',
component: 'page_20210828084702'},
{title: "Sau bao năm bị chê để tóc mái không hợp, Jennie giờ đã phục thù thành công, thậm chí còn sang xịn không tưởng",
description: "Từng bị chê không hợp tóc mái, Jennie giờ đã chứng minh không có kiểu tóc gì cô không \"cân\" được.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/27/teaser-jennie-chinh-thuc-cong-bo-tro-lai-voi-toc-mai-van-cuc-ngau-d39dbc26-1630051282037281814165.jpeg',
alt: "jennie,star style,sao Hàn,tóc mái,tóc của sao,kiểu tóc,",
category: 'stars',
time: '08:46 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:46 AM',
link: '/sau-bao-nam-bi-che-de-toc-mai-khong-hop-jennie-gio-da-phuc-thu-thanh-cong-tham-chi-con-sang-xin-khong-tuong',
component: 'page_20210828084654'},
{title: "Phim Việt có 1 loạt trang phục hớ hênh phản cảm, người xem thấy mà tức!",
description: "Không rõ vì ý đồ của stylist hay gì mà nhiều diễn viên phim Việt lại chọn áo quần rất phản cảm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/26/o-mat-truoc-nhung-kieu-trang-phuc-ho-henh-trong-phim-viet-khan-gia-xem-cung-phai-buc-xuc-thanh-bi-ng-i-ph-n-x-1629900656-675-width600height337-1629951892879832787625.jpeg',
alt: "phim Việt,phản cảm,lố lăng,hớ hênh,thời trang trong phim,Thanh Bi,Phi Thanh Vân,Liên Tít,",
category: 'stars',
time: '08:45 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:45 AM',
link: '/phim-viet-co-1-loat-trang-phuc-ho-henh-phan-cam-nguoi-xem-thay-ma-tuc',
component: 'page_20210828084503'},
{title: "Jennie thân hình chuẩn nhất, nhưng Lisa mới là thành viên body sang nhất BLACKPINK và đây là lý do!",
description: "Đến netizen khó tính nhất cũng không thể chê bai body cực phẩm của Lisa (BLACKPINK).",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/26/lalalalisam2405567842054567149545175623479004395616732n-16299425978411956553044.jpg',
alt: "lisa,lisa (blackpink),Blackpink,jennie,Jennie (BlackPink),body sao Hàn,idol Kpop,sao Hàn,",
category: 'stars',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/jennie-than-hinh-chuan-nhat-nhung-lisa-moi-la-thanh-vien-body-sang-nhat-blackpink-va-day-la-ly-do',
component: 'page_20210828084453'},
{title: "Triệu Lệ Dĩnh và 2 lần gặp sự cố trang phục: Lúc tạo ra khoảnh khắc đẹp thổn thức, lúc bị \"ném đá\" không thương tiếc",
description: "Có thể nói đây là 2 sự cố trang phục đáng nhớ nhất của Triệu Lệ Dĩnh.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/25/photo-1-1618590780257433604819-1629897644752946975497.jpg',
alt: "triệu lệ dĩnh,sao Trung,sao Hoa ngữ,star style,trang sức,phụ kiện,sự cố trang phục,",
category: 'stars',
time: '08:44 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:44 AM',
link: '/trieu-le-dinh-va-2-lan-gap-su-co-trang-phuc-luc-tao-ra-khoanh-khac-dep-thon-thuc-luc-bi-nem-da-khong-thuong-tiec',
component: 'page_20210828084415'},
{title: "Show thực tế sao nữ Hàn muốn né nhất: Sơ hở là lộ mặt mộc nhận không ra!",
description: "Khi tham gia Law Of The Jungle, các sao nữ Hàn Quốc bắt buộc không được trang điểm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/24/photo-9-16297896883171699901946.png',
alt: "Mặt mộc,không trang điểm,sao Hàn,idol,Kpop,hani,TWICE,exid,gfriend,Law of the Jungle,",
category: 'stars',
time: '08:40 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:40 AM',
link: '/show-thuc-te-sao-nu-han-muon-ne-nhat-so-ho-la-lo-mat-moc-nhan-khong-ra',
component: 'page_20210828084046'},
{title: "Cứ ngọt ngào mọi lúc thế này, có gọi Joy (Red Velvet) là \"crush quốc dân\" cũng chẳng sai!",
description: "Style của Joy ngày càng biến hoá ngọt ngào, tươi mới. Phải chăng đây là điểm then chốt khiến Crush sa vào lưới tình?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/24/photo-1-16297900783842105719095.jpg',
alt: "Joy (Red Velvet),joy hẹn hò crush,sao Hàn,star style,sao mặc đẹp,tips mặc đẹp,mặc đẹp như sao Hàn,red velvet,váy hoa,váy caro,",
category: 'stars',
time: '08:40 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:40 AM',
link: '/cu-ngot-ngao-moi-luc-the-nay-co-goi-joy-red-velvet-la-crush-quoc-dan-cung-chang-sai',
component: 'page_20210828084034'},
{title: "Mỹ nhân Hoa ngữ đóng cảnh hôn khi chưa tròn 18: Trịnh Sảng mãnh liệt suýt gãy mũi, cô cuối gây sốc vì mới 12 tuổi!",
description: "Trịnh Sảng thực hiện cảnh hôn đầu đời khi mới 17 tuổi, thế nhưng đó chưa phải \"kỷ lục\" trẻ nhất Cbiz.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/22/photo-1-1629602143047901615631.jpeg',
alt: "Trịnh Sảng,Lý Lan Địch,Tưởng Y Y,ngô lỗi,Tiếu ngạo giang hồ,Thiên Dục,Lý Tiểu Lộ,Cùng Ngắm Mưa Sao Băng,Bí Quả,cảnh hôn,phim Hoa ngữ,phim trung quốc,Phim truyền hình,phim điện ảnh,Trương Hàn,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '08:35 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:35 AM',
link: '/my-nhan-hoa-ngu-dong-canh-hon-khi-chua-tron-18-trinh-sang-manh-liet-suyt-gay-mui-co-cuoi-gay-soc-vi-moi-12-tuoi',
component: 'page_20210828083544'},
{title: "7 nàng ma nữ Thiện Nữ U Hồn đẹp xuất chúng: Nhiệt Ba - Lưu Diệc Phi đều lép vế trước \"tường thành nhan sắc Hong Kong\"",
description: "Lưu Diệc Phi có khí chất thần tiên nhưng khi đóng vai ma nữ Nhiếp Tiểu Thiến lại thua xa đàn chị đình đám này.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/18/2c21baf2187e6dd577825d0b15c373b0-162927355140225107415.jpeg',
alt: "Nhiếp Tiểu Thiến,Lưu Diệc Phi,thiện nữ u hồn,Dương Mịch,trương gia nghê,Vương Tổ Hiền,Từ Hy Viên,Chung Sở Hy,Địch Lệ Nhiệt Ba,Tân Thiện Nữ U Hồn,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '08:33 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:33 AM',
link: '/7-nang-ma-nu-thien-nu-u-hon-dep-xuat-chung-nhiet-ba-luu-diec-phi-deu-lep-ve-truoc-tuong-thanh-nhan-sac-hong-kong',
component: 'page_20210828083327'},
{title: "Triệu Lệ Dĩnh hôm nay trẻ thế, nhìn như thiếu nữ cấp 3 chứ ai nghĩ đã U40 rồi!",
description: "Triệu Lệ Dĩnh khiến fan mừng điên lên bởi vẻ ngoài quá mức trẻ trung, xinh xắn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/22/2403249703660049818212931320835913294549647n-1629621502768229377509.jpg',
alt: "star style,sao mặc gì,high teen,triệu lệ dĩnh,sao Cbiz,nữ diễn viên Trung Quốc,happy camp,",
category: 'stars',
time: '08:33 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:33 AM',
link: '/trieu-le-dinh-hom-nay-tre-the-nhin-nhu-thieu-nu-cap-3-chu-ai-nghi-da-u40-roi',
component: 'page_20210828083313'},
{title: "Loạt nụ hôn \"cướp đồ ăn\" trên màn ảnh Hoa ngữ: Triệu Lộ Tư còn đáng yêu chán, qua đến màn cướp mì thì \"quay xe\" gấp",
description: "Hình Phi, Thành Nghị, Triệu Lộ Tư... đều đã từng thực hiện nụ hôn \"trao đổi thức ăn\" với bạn diễn.",
src: 'https://t.vietgiaitri.com/2020/8/10/4-canh-hon-ngot-ngao-cop-mac-lang-tieu-tong-uy-long-tiem-tiem-dam-tung-van-trong-lay-danh-nghia-nguoi-nha-159-5196671.jpg',
alt: "Phim truyền hình,phim trung quốc,phim truyền hình trung quốc,Hình Phi,thành nghị,Trương Dư Hi,Triệu Lộ Tư,Trần Thiên Thiên Trong Lời Đồn (2020),",
category: 'stars',
time: '11:05 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:05 AM',
link: '/loat-nu-hon-cuop-do-an-tren-man-anh-hoa-ngu-dan-tinh-khong-me-chi-thay-mat-ve-sinh',
component: 'page_20210821110554'},
{title: "Idol Hàn cũng từng khốn đốn vì loạt quần, váy \"hư hỏng\": Người lộ vòng 3, người bị gắn với cái danh \"thời trang đóng bỉm\"",
description: "Đây đều là những trang phục trình diễn khiến người xem cảm thấy khó chịu vì hở hang quá đà của idol Hàn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/19/d4borosvuaex3zg-1629377406092535904504.jpg',
alt: "Kpop,idol,sao Hàn,star style,trang phục trình diễn,phản cảm,nhạy cảm,váy ngắn,quần ngắn,quần bảo hộ,",
category: 'stars',
time: '09:23 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:23 AM',
link: '/idol-han-cung-tung-khon-don-vi-loat-quan-vay-hu-hong-nguoi-lo-vong-3-nguoi-bi-gan-voi-cai-danh-thoi-trang-dong-bim',
component: 'page_20210820092333'},
{title: "Hội mỹ nhân tự tử vẫn đẹp nao lòng trên phim Trung: Tiểu Phong quá kinh điển, nàng số 4 cớ sao ăn chửi suốt bao năm?",
description: "Những mỹ nhân cổ trang như Tiểu Phong hay Tố Tố đều lựa chọn tự tử vì tình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/20/1-1629432816450506734363.png',
alt: "Đông Cung,Tiểu Phong,Mỹ nhân Hoa ngữ,Tam Sinh Tam Thế Thập Lý Đào Hoa,Diên Hi Công Lược,Chân Hoàn Truyện,tần lam,nhiệt y trát,Bành Tiểu Nhiễm,Dương Mịch,Trường An Như Cố,Trường An Như Cố (2021),Châu Sinh Như Cố,Châu Sinh Như Cố (2021),Bạch Lộc,phim cổ trang,phim trung quốc,phim Hoa ngữ,Phim truyền hình,",
category: 'stars',
time: '09:22 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:22 AM',
link: '/hoi-my-nhan-tu-tu-van-dep-nao-long-tren-phim-trung-tieu-phong-qua-kinh-dien-nang-so-4-co-sao-an-chui-suot-bao-nam',
component: 'page_20210820092256'},
{title: "Á hậu Huyền My chính thức trở thành BTV truyền hình sau 7 năm đăng quang, visual lên sóng chưa chi đã thấy mê lắm rồi",
description: "Loạt ảnh nét đẹp lao động của Huyền My khiến dân tình ngất ngây, nụ cười rạng rỡ giật spotlight giữa trường quay.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/23270234642230347744129356619438171871737232n-16292912374321690658979.jpg',
alt: "Huyền My,á hậu huyền my,sao Việt,Vbiz,showbiz Việt,á hậu hoa hậu Việt Nam 2014,Nguyễn Trần Huyền My,Huyền My làm BTV,biên tập viên truyền hình,",
category: 'stars',
time: '01:02 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:02 PM',
link: '/a-hau-huyen-my-chinh-thuc-tro-thanh-btv-truyen-hinh-sau-7-nam-dang-quang-visual-len-song-chua-chi-da-thay-me-lam-roi',
component: 'page_20210819130228'},
{title: "Kim Jong Kook - Song Ji Hyo tình bể bình thế này, có chối đây đẩy thì fan cứ tích cực \"chèo thuyền\"!",
description: "Qua những hình ảnh này, khán giả có thể phần nào hiểu ra lý do Kim Jong Kook và Song Ji Hyo lại được yêu thích đến như vậy.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-1-1629268367708796859063.jpg',
alt: "sao Hàn,loveline,kim jong kook,song ji hyo,hẹn hò,sao hẹn hò,yêu đương,đẩy thuyền,running man,",
category: 'stars',
time: '01:02 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:02 PM',
link: '/kim-jong-kook-song-ji-hyo-tinh-be-binh-the-nay-co-choi-day-day-thi-fan-cu-tich-cuc-cheo-thuyen',
component: 'page_20210819130213'},
{title: "Sau 11 năm, Song Ji Hyo cuối cùng đã chính thức lên tiếng về chuyện tình cảm với Kim Jong Kook",
description: "Câu trả lời của Song Ji Hyo về mối quan hệ với Kim Jong Kook trong cuộc phỏng vấn mới đây đã khiến fan vô cùng bất ngờ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/18/4407o2-15946316607471974203887-16292592199701564511157.jpg',
alt: "song ji hyo,Kim Jong Kook & Song Ji Hyo,running man,kim jong kook,sao hẹn hò,The Witch's Diner (2021),spartace,",
category: 'stars',
time: '12:58 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:58 PM',
link: '/sau-11-nam-song-ji-hyo-cuoi-cung-da-chinh-thuc-len-tieng-ve-chuyen-tinh-cam-voi-kim-jong-kook',
component: 'page_20210819125841'},
{title: "Cổ Lực Na Trát gây bão Weibo với màn múa thay trang phục trong 1 giây: Mặt kinh diễm như tranh, khí chất tiên tử mê hồn",
description: "Sau màn trình diễn này, vô số lời ngợi khen đã được gửi tới nàng \"Mỹ nhân Tân Cương\" Cổ Lực Na Trát.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-1-162925109970473436377.jpeg',
alt: "cổ lực na trát,mỹ nhân Tân Cương,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '12:58 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:58 PM',
link: '/co-luc-na-trat-gay-bao-weibo-voi-man-mua-thay-trang-phuc-trong-1-giay-mat-kinh-diem-nhu-tranh-khi-chat-tien-tu-me-hon',
component: 'page_20210819125832'},
{title: "Song Ji Hyo phủ nhận loveline với Kim Jong Kook, netizen phản ứng: \"Xin lỗi chị, em là một 'thuyền viên' cố chấp\"",
description: "Dù biết loveline giữa Song Ji Hyo - Kim Jong Kook chỉ mang tính chất giải trí, fan vẫn nhất quyết không xuống \"thuyền\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/18/photo-1-16292591809311976158846.jpg',
alt: "kim jong kook,song ji hyo,spartace,Kim Jong Kook & Song Ji Hyo,running man,loveline,phủ nhận tin đồn hẹn hò,tin đồn hẹn hò,đẩy thuyền,thuyền viên,",
category: 'stars',
time: '12:58 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:58 PM',
link: '/song-ji-hyo-phu-nhan-loveline-voi-kim-jong-kook-netizen-phan-ung-xin-loi-chi-em-la-mot-thuyen-vien-co-chap',
component: 'page_20210819125816'},
{title: "Chỉ 1 phút chỉnh niềng răng mà tạo ra khoảnh khắc \"để đời\", phải công nhận Joy (Red Velvet) thời MAMA 2017 đúng là cực phẩm!",
description: "Sự thật đằng sau bức ảnh \"để đời\" được Joy kể lại làm fan cười ngất, tuy nhiên ai cũng phải công nhận, visual tại MAMA năm ấy là thời kỳ đỉnh cao nhất của cô nàng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/photo2021-08-1608-16-52-16290768233691043931960.jpg',
alt: "red velvet,Joy (Red Velvet),Irene (Red Velvet),Red Velvet (SM),Joy,MAMA,Red Velvet comeback 2021,red velvet comeback,Red Velvet come back,Joy debut solo,joy red velvet,joy (red velvet) hát nhép,người đẹp,idol Kpop,Moment,viral,video viral,sexy,sao sexy,Cũ Rồi Đào Lại Cho Mới,",
category: 'stars',
time: '12:57 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:57 PM',
link: '/chi-1-phut-chinh-nieng-rang-ma-tao-ra-khoanh-khac-de-doi-phai-cong-nhan-joy-red-velvet-thoi-mama-2017-dung-la-cuc-pham',
component: 'page_20210819125757'},
{title: "Jennie và 3 lần tái hiện lại những nhân vật kinh điển: Có đọ lại body bốc lửa của Angelina Jolie khi hóa thân thành Lara Croft huyền thoại?",
description: "Bạn nghĩ sao về những tạo hình lấy cảm hứng từ các nhân vật nổi tiếng của Jennie?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/16/5473207915133523788010198188912766227040247n-16291142861721328843914.jpg',
alt: "jennie,star style,sao Hàn,Lara Croft,Phoebe Dynevor,Angelina Jolie,Uma Thurman,",
category: 'stars',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/jennie-va-3-lan-tai-hien-lai-nhung-nhan-vat-kinh-dien-co-do-lai-body-boc-lua-cua-angelina-jolie-khi-hoa-than-thanh-lara-croft-huyen-thoai',
component: 'page_20210819125646'},
{title: "Xoài Non khoe ảnh makeup đeo lens ngày khai giảng, visual thế này bảo sao ông xã đổ gục!",
description: "Hóa ra lối makeup \"ngựa ngựa\" này là vũ khí khiến visual Xoài Non thăng hạng!",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/16/tram-tro-truoc-nhan-sac-chi-dau-17-tuoi-cua-diep-lam-anh-hinh-6-16291242929181600059655.jpeg',
alt: "Xoài non,Makeup,Trang điểm,sao makeup,showbiz Việt,sao Việt,streamer,",
category: 'stars',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/xoai-non-khoe-anh-makeup-deo-lens-ngay-khai-giang-visual-the-nay-bao-sao-ong-xa-do-guc',
component: 'page_20210819125632'},
{title: "Rất hay đăng ảnh bốc lửa nhưng có 1 chuyện Phí Phương Anh mãi vẫn không chịu thừa nhận",
description: "Phủ nhận chuyện \"trùng tu\" nhưng Phí Phương Anh vẫn làm dấy lên mối nghi ngờ chỉ qua vài bức ảnh.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/5d08efd2ebd36-1049-1629109759052152931948.jpeg',
alt: "phí phương anh,công nghệ thẩm mỹ thời nay,dao kéo,phẫu thuật thẩm mỹ,nghi vấn sao thẩm mỹ,nghi án dao kéo,star style,Làm ngực,",
category: 'stars',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/rat-hay-dang-anh-boc-lua-nhung-co-1-chuyen-phi-phuong-anh-mai-van-khong-chiu-thua-nhan',
component: 'page_20210819125524'},
{title: "5 Điêu Thuyền của màn ảnh Hoa ngữ: Lưu Diệc Phi không có cửa với huyền thoại năm xưa, người thứ 5 diễn đơ nhưng makeup đẹp điên đảo",
description: "Điện ảnh Hoa ngữ ghi nhận loạt diễn viên từng vào vai Điêu Thuyền, mỗi người toát lên một khí chất khiến khán giả khó lòng lãng quên.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/15/photo-1-16289992852711624559456.jpg',
alt: "Điêu Thuyền,Lưu Diệc Phi,cổ lực na trát,trần hồng,đổng tuyền,trần hảo,tứ đại mỹ nhân,Tam Quốc Diễn Nghĩa,tạo hình cổ trang,Mỹ nhân cổ trang,người đẹp cổ trang,sao Hoa ngữ,star style,",
category: 'stars',
time: '12:51 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:51 PM',
link: '/5-dieu-thuyen-cua-man-anh-hoa-ngu-luu-diec-phi-khong-co-cua-voi-huyen-thoai-nam-xua-nguoi-thu-5-dien-do-nhung-makeup-dep-dien-dao',
component: 'page_20210819125141'},
{title: "Hot nhất Weibo tối nay: Dương Mịch hack tuổi thần sầu với visual như gái 18, thế này thì đàn em chạy dài cũng chẳng đuổi kịp",
description: "Vẫn biết Dương Mịch cực kỳ biết cách chăm chút hình ảnh nhưng Cnet vẫn phải trầm trồ trước nét đẹp như \"lão hoá ngược\" của cô nàng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/photo-1-16290432169391833182506.jpg',
alt: "Dương Mịch,nhan sắc của sao,vóc dáng của sao,sao trên sóng livestream,nhan sắc thật của sao,",
category: 'stars',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/hot-nhat-weibo-toi-nay-duong-mich-hack-tuoi-than-sau-voi-visual-nhu-gai-18-the-nay-thi-dan-em-chay-dai-cung-chang-duoi-kip',
component: 'page_20210815160646'},
{title: "5 năm chỉ chơi 1 tướng, Girl 1 Champ Hoàng Yến Chibi đập tan lời đồn ác ý khi khoe trọn cơ thể cực kỳ sexy",
description: "Mới đây, Hoàng Yến Chibi lại khiến cho tất cả phải ngỡ ngàng khi khoe trọn cơ thể vô cùng gợi cảm của mình.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/photo-1-16290271793201232598381.jpg',
alt: "LIÊN QUÂN MOBILE,LIÊN QUÂN,CỘNG ĐỒNG LIÊN QUÂN MOBILE,HOÀNG YẾN CHIBI,HOÀNG YẾN,GIRL 1 CHAMP,GAME THỦ,GÁI XINH,SEXY,",
category: 'stars',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/5-nam-chi-choi-1-tuong-girl-1-champ-hoang-yen-chibi-dap-tan-loi-don-ac-y-khi-khoe-100-co-the-cuc-ky-sexy',
component: 'page_20210815160619'},
{title: "Nhan sắc xinh đẹp của thiên thần trượt băng đã cosplay Trùng Trụ, tài sắc vẹn toàn càng ngắm càng mê",
description: "Cùng đến với những hình ảnh cực kỳ xinh đẹp của thiên thần trượt băng đã cosplay Trùng Trụ gây sốt cộng đồng mạng trong những ngày vừa qua nhé!",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/15/co5-1629006817313376916211.jpg',
alt: "LỄ HỘI COSPLAY,BỘ ẢNH COSPLAY,CHÙM ẢNH COSPLAY,NHÂN VẬT COSPLAY,ẢNH COSPLAY,COSPLAY KIMETSU NO YAIBA,TRÙNG TRỤ KIMETSU NO YAIBA,",
category: 'stars',
time: '04:05 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:05 PM',
link: '/nhan-sac-xinh-dep-cua-thien-than-truot-bang-da-cosplay-trung-tru-tai-sac-ven-toan-cang-ngam-cang-me',
component: 'page_20210815160540'},
{title: "Suri Cruise khoe chân dài miên man trên phố, nhưng phải ôm ngực khư khư vì sợ lộ phần nhạy cảm",
description: "Suri Cruise chăm diện đồ ngắn dạo phố nhưng đôi khi trang phục thoải mái lại đẩy cô công chúa nhà Tom Cruise vào tình huống khó xử, ngượng ngùng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/photo-1-16290342517302050187624.jpg',
alt: "suri cruise,tom cruise,katie holmes,sao us uk,sao hollywood,con gái Tom Cruise,",
category: 'stars',
time: '04:04 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:04 PM',
link: '/suri-cruise-khoe-chan-dai-mien-man-tren-pho-nhung-phai-om-nguc-khu-khu-vi-so-lo-phan-nhay-cam',
component: 'page_20210815160455'},
{title: "Nhan sắc thăng hạng của mỹ nhân bị ghét vì đóng cảnh nóng phản cảm trong Tân Hoàn Châu Cách Cách",
description: "Năm xưa nữ diễn viên này từng chịu chỉ trích \"bất chấp thủ đoạn để nổi tiếng\" vì cảnh nóng trong Tân Hoàn Châu Cách Cách.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/14/hamthanhtu-9-16289321889752056026119.jpg',
alt: "hám thanh tử,tân hoàn châu cách cách,ngày ấy bây giờ,cảnh nóng,",
category: 'stars',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/nhan-sac-thang-hang-cua-my-nhan-bi-ghet-vi-dong-canh-nong-phan-cam-trong-tan-hoan-chau-cach-cach',
component: 'page_20210814114021'},
{title: "\"Cửa ải\" để thành người mẫu của Victoria's Secret: Số đo 3 vòng \"siêu thực\" gây tranh cãi, chế độ ăn kiêng như ác mộng!",
description: "Để trở thành \"thiên thần\" đeo trên vai đôi cánh màu nhiệm của Victoria's Secret, các người mẫu sẽ phải thoả mãn được hàng loạt tiêu chí khó như... hô phong hoán vũ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/14/roosworkout-thumb-1549468754-1628935684422928971291.jpeg',
alt: "victoria's secret,người mẫu,siêu mẫu,gigi hadid,bella hadid,thời trang,Showbiz,Biến Cũ Làng Mốt,",
category: 'stars',
time: '11:40 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:40 AM',
link: '/cua-ai-de-thanh-nguoi-mau-cua-victorias-secret-so-do-3-vong-sieu-thuc-gay-tranh-cai-che-do-an-kieng-nhu-ac-mong',
component: 'page_20210814114010'},
{title: "BLACKPINK \"đại náo\" sự kiện PUBG: Từ sang chảnh hóa \"bánh bèo\" xinh xỉu, nhưng sao cả 4 lộ cẳng chân chẳng đẹp như mơ thế này",
description: "Trong sự kiện mới, BLACKPINK thật sự khiến dân tình xỉu ngang vì nhan sắc xinh đẹp hút hồn, nhưng phải thở dài khi kéo xuống đôi chân của cả 4 thành viên.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/13/e8qtbvpucamcwwf-16288724275191988611193.jpg',
alt: "Blackpink,lisa (black pink),lisa (blackpink),lisa,jennie,Jennie (BlackPink),jisoo (blackpink),jisoo,rosé (blackpink),Rosé,nhan sắc của blackpink,body của blackpink,body của sao,body của sao Hàn,nhan sắc sao,nhan sắc của sao,nhan sắc của sao Hàn,đôi chân của sao,sao lộ khuyết điểm,PUBG,",
category: 'stars',
time: '10:40 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:40 PM',
link: '/blackpink-dai-nao-su-kien-pubg-tu-sang-chanh-hoa-banh-beo-xinh-xiu-nhung-sao-ca-4-lo-cang-chan-chang-dep-nhu-mo-the-nay',
component: 'page_20210813224013'},
{title: "Tạo hình dị vực của sao Trung: Bành Tiểu Nhiễm vẫn \"đỉnh của chóp\", Triệu Lệ Dĩnh mất điểm vì lông mày, Nhiệt Ba bị soán ngôi bởi 2 đồng hương",
description: "Dàn mỹ nhân của Cbiz trông thế nào trong tạo hình dị vực?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/12/52426857529098130918556505237438482874368n-1628758242733244696967.jpg',
alt: "sao Trung,sao Hoa ngữ,Cbiz,dị vực,tạo hình dị vực,thời trang trong phim,Địch Lệ Nhiệt Ba,đồng lệ á,triệu lệ dĩnh,Angela Baby,Lưu Thi Thi,Lê Tư,Bành Tiểu Nhiễm,star style,",
category: 'stars',
time: '10:39 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:39 PM',
link: '/tao-hinh-di-vuc-cua-sao-trung-banh-tieu-nhiem-van-dinh-cua-chop-trieu-le-dinh-mat-diem-vi-long-may-nhiet-ba-bi-soan-ngoi-boi-2-dong-huong',
component: 'page_20210813223953'},
{title: "Mỹ nhân Hoa ngữ lộ khuyết điểm chết người trên phim: Lưu Diệc Phi - Đường Yên chỉ đẹp khi không cười, Triệu Lệ Dĩnh gầy đến trơ xương",
description: "Dù sở hữu nhan sắc nổi trội nhưng các diễn viên này vẫn có nhược điểm về ngoại hình và bị lộ rõ khi lên phim.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/10/3-16286131376591082157721.jpg',
alt: "Lưu Diệc Phi,Đường Yên,triệu lệ dĩnh,Dương Mịch,Lưu Thi Thi,Châu Tấn,Địch Lệ Nhiệt Ba,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:27 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:27 AM',
link: '/my-nhan-hoa-ngu-lo-khuyet-diem-chet-nguoi-tren-phim-luu-diec-phi-duong-yen-chi-dep-khi-khong-cuoi-trieu-le-dinh-gay-den-tro-xuong',
component: 'page_20210813092754'},
{title: "NÓNG: Seungri (BIGBANG) chính thức bị kết án 3 năm tù giam, phạt số tiền khổng lồ vì 2 tội danh",
description: "Vào ngày hôm nay (12/8), Tòa án đã đưa ra mức án cuối cùng dành cho Seungri sau vụ bê bối Burning Sun.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/screenshot-47-16287555137381276804635.png',
alt: "seungri,Seungri (BIGBANG),Bê bối tình dục chấn động của Seungri,Seungri chính thức là nghi phạm môi giới mại dâm,club của seungri dính phốt tấn công tình dục,Burning Sun,scandal burning sun,yoo in suk,bigbang,",
category: 'stars',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/nong-seungri-chinh-thuc-bi-ket-an-3-nam-tu-giam-phat-215-ty-dong',
component: 'page_20210813092129'},
{title: "Ngất lịm với nhan sắc bùng nổ của \"gái 1 con\" Triệu Lệ Dĩnh ngày hôm nay, đúng là phụ nữ đẹp nhất khi không thuộc về ai",
description: "Vẫn biết nhan sắc của Triệu Lệ Dĩnh càng ngày càng xuất sắc nhưng quả thật cô nàng khiến ai cũng phải trầm trồ với visual \"lên hương\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/photo-1-16287732196331015836426.jpg',
alt: "triệu lệ dĩnh,Mỹ nhân Hoa ngữ,nhan sắc của sao,nhan sắc mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/ngat-lim-voi-nhan-sac-bung-no-cua-gai-1-con-trieu-le-dinh-ngay-hom-nay-dung-la-phu-nu-dep-nhat-khi-khong-thuoc-ve-ai',
component: 'page_20210813092120'},
{title: "7 nàng a hoàn đẹp nhất màn ảnh Hoa ngữ: Phạm Băng Băng - Dương Mịch thần thái ngời ngời, \"trùm cuối\" còn xinh hơn cả Yoona",
description: "Dù chỉ vào vai a hoàn, nhưng những nữ diễn viên này vẫn tỏa sáng nhờ khả năng diễn xuất tốt cùng nhan sắc nổi bật của mình.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/14-16286997509131783388598.jpg',
alt: "Trần Tiểu Vân,Phạm Băng Băng,Dương Mịch,triệu lệ dĩnh,Mạnh Tử Nghĩa,thư sướng,Lưu Đào,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/7-nang-a-hoan-dep-nhat-man-anh-hoa-ngu-pham-bang-bang-duong-mich-than-thai-ngoi-ngoi-trum-cuoi-con-xinh-hon-ca-yoona',
component: 'page_20210813092106'},
{title: "Hoà Minzy hé lộ bí mật về lần đầu công khai quý tử hào môn, tung luôn ảnh khoe body nuột khó tin chỉ sau 8 tháng sinh",
description: "Hoà Minzy đã chia sẻ vlog chuyến đi du lịch đầu tiên của bé Bo, vô tình để lộ body mẹ bỉm sau khi bí mật sinh con.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/hoa-minzy-chinh-thuc-xac-nhan-co-con-cong-bo-hinh-anh-con-trai-af9a5ee4-16287865436941841377666.jpg',
alt: "hòa minzy,ca sĩ hoà minzy,gia đình Hoà Minzy,bé bo con trai hoà minzy,con sao việt,sao Việt,showbiz Việt,body của sao,Nhóc tỳ sao việt,con của sao,Hoà Minzy sinh con,",
category: 'stars',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/hoa-minzy-he-lo-bi-mat-ve-lan-dau-cong-khai-quy-tu-hao-mon-tung-luon-anh-khoe-body-nuot-kho-tin-chi-sau-8-thang-sinh',
component: 'page_20210812173200'},
{title: "Tạo hình 5 nàng Tiểu Long Nữ xứ Trung: Lý Nhược Đồng - Lưu Diệc Phi vững ngôi thần tiên tỷ tỷ, 2 phiên bản mới nhất đua nhau rời xa nguyên tác",
description: "Thật bất ngờ khi có 1 nàng Tiểu Long Nữ lại gia nhập hội ghiền đùi gà!",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/photo-1-16286933955911077975879.jpg',
alt: "Tiểu Long Nữ,Lưu Diệc Phi,Phạm Văn Phương,Mao Hiểu Huệ,Trần Nghiên Hy,Lý Nhược Đồng,Thần điêu đại hiệp,",
category: 'stars',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/tao-hinh-5-nang-tieu-long-nu-xu-trung-ly-nhuoc-dong-luu-diec-phi-vung-ngoi-than-tien-ty-ty-2-phien-ban-moi-nhat-dua-nhau-roi-xa-nguyen-tac',
component: 'page_20210812173119'},
{title: "Ngọc Trinh diện áo hở trên, cắt dưới, vòng 1 bèn chơi trốn tìm luôn",
description: "Ngọc Trinh thường xuyên có những pha chơi \"ú oà\" với chính vòng 1 của mình.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/1569885654275627350160585801166340115404300n-161511920757831234536-1615178661479-16151786617951936696395-16286859687671815588616.jpeg',
alt: "sao đỏ thời trang,Ngọc Trinh,sao mặc xấu,mặc đẹp,vòng 1 sao,showbiz Việt,sao Việt,",
category: 'stars',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/ngoc-trinh-dien-ao-ho-tren-cat-duoi-vong-1-ben-choi-tron-tim-luon',
component: 'page_20210811110039'},
{title: "Sao Hoa ngữ bị chê không đủ đẹp để đóng đại mỹ nhân: Triệu Lệ Dĩnh thiếu khí chất, \"trùm cuối\" phá nát hình tượng Hằng Nga",
description: "Kể cả khi diễn xuất rất tốt thì những nữ diễn viên này vẫn bị chê không lột tả được vẻ đẹp của các đại mỹ nhân nức tiếng.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/3-16286627599161317881769.jpg',
alt: "triệu lệ dĩnh,viên san san,an dĩ hiên,vương lệ khôn,dĩnh nhi,ân đào,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/sao-hoa-ngu-bi-che-khong-xung-vao-vai-dai-my-nhan-trieu-le-dinh-thieu-khi-chat-trum-cuoi-pha-nat-hinh-tuong-hang-nga',
component: 'page_20210811110019'},
{title: "Mỹ nhân Hoa - Hàn U40 mà vẫn hóa nữ sinh ngon ơ: Triệu Lệ Dĩnh có đỉnh bằng loạt \"ma cà rồng\" của Hàn Quốc?",
description: "Bạn thích tạo hình nữ sinh của mỹ nhân nào nhất?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/22490033944008436167005745741514556988718863n-1628643044228495697507.jpg',
alt: "nữ sinh,sao Hoa ngữ,sao Trung,sao Hàn,Cbiz,Kbiz,triệu lệ dĩnh,jang nara,park bo young,star style,làm đẹp,Makeup,tạo hình trong phim,",
category: 'stars',
time: '10:59 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:59 AM',
link: '/my-nhan-hoa-han-u40-ma-van-hoa-nu-sinh-ngon-o-trieu-le-dinh-co-dinh-bang-loat-ma-ca-rong-cua-han-quoc',
component: 'page_20210811105927'},
{title: "4 nàng Lý Mạc Sầu quyết ăn thua đủ về dung nhan với Tiểu Long Nữ: Người \"phẩy\" light chất chơi, người nóng bỏng muốn \"hấp hối\"",
description: "Bên cạnh bộ đôi nhân vật chính, Lý Mạc Sầu cũng là vai diễn luôn được khán giả quan tâm ở mỗi phiên bản phim Thần Điêu Đại Hiệp.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/photo-1-16283064376731058381652.jpg',
alt: "Lý Mạc Sầu,Thần điêu đại hiệp,Mạnh Quảng Mỹ,Trương Hinh Dư,Mao Lâm Lâm,trần hồng,star style,thần điêu đại hiệp (2014),tạo hình trong phim,tạo hình nhân vật,",
category: 'stars',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/4-nang-ly-mac-sau-quyet-an-thua-du-ve-dung-nhan-voi-tieu-long-nu-nguoi-phay-light-chat-choi-nguoi-nong-bong-muon-hap-hoi',
component: 'page_20210811105443'},
{title: "Loạt thảm hoạ tạo hình phim cổ trang Hoa ngữ: Lưu Diệc Phi bưng cả mâm ngũ quả lên đầu nhưng chưa chắc đọ được combo cá chép của Triệu Lệ Dĩnh?",
description: "Có vẻ những người đẹp Hoa ngữ đình đám ít nhiều từng bị tổ hoá trang dìm khi tham gia dự án phim cổ trang thì phải.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/photo-1-1628426161100814976995.jpg',
alt: "phim cổ trang,Vương Tổ Hiền,Trần Nghiên Hy,Tiểu Long Nữ,Lưu Diệc Phi,Lưu Gia Linh,Địch Lệ Nhiệt Ba,Dương Mịch,Cúc Tịnh Y,star style,sao Cbiz,tạo hình nhân vật,tạo hình trong phim,",
category: 'stars',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/loat-tham-hoa-tao-hinh-phim-co-trang-hoa-ngu-luu-diec-phi-bung-ca-mam-ngu-qua-len-dau-nhung-chua-chac-do-duoc-combo-ca-chep-cua-trieu-le-dinh',
component: 'page_20210811105426'},
{title: "Tứ đại mỹ nhân trên màn ảnh Hoa ngữ: \"Vương Chiêu Quân\" Dương Mịch có lép vế trước loạt nhan sắc huyền thoại?",
description: "Nhan sắc của tứ đại mỹ nhân trên màn ảnh Hoa ngữ thu hút đông đảo sự chú ý của netizen.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/8/photo-1-15700670484611852808998-1628426119499114659797.jpg',
alt: "tứ đại mỹ nhân,sao Trung,sao Hoa ngữ,Dương Mịch,Tưởng Cần Cần,trần hồng,Lâm Phương Bình,star style,tạo hình nhân vật,tạo hình trong phim,",
category: 'stars',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/tu-dai-my-nhan-tren-man-anh-hoa-ngu-vuong-chieu-quan-duong-mich-co-lep-ve-truoc-loat-nhan-sac-huyen-thoai',
component: 'page_20210811105414'},
{title: "BLACKPINK và 1 \"rổ\" lỗi trang phục khó xử: Hết tụt áo lại rách váy, pha xử lý của Jennie gây sốt vì cao tay!",
description: "Không biết lỗi do stylist hay vì vận xui mà BLACKPINK đã 5 lần 10 lượt vướng sự cố trang phục bi hài tới vậy.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/1-171640-1628651713299474880525.png',
alt: "Blackpink,sự cố trang phuc,sao đỏ thời trang,rách váy,tụt quần,idol Kpop,sao Hàn,star style,",
category: 'stars',
time: '10:53 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:53 AM',
link: '/blackpink-va-1-ro-loi-trang-phuc-kho-xu-het-tut-ao-lai-rach-vay-pha-xu-ly-cua-jennie-gay-sot-vi-cao-tay',
component: 'page_20210811105358'},
{title: "Jihyo (TWICE) diện chung áo với mẫu hãng nhưng \"chiếm sóng\" nhờ 1 chi tiết \"mlem\" hơn hẳn",
description: "Chính nhờ điểm đặc biệt này mà Jihyo luôn tạo cảm giác gợi cảm khó cưỡng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/18/2008415376054701237475222705310698259576272n-16240075379411007675571.jpg',
alt: "sao Hàn,jihyo,TWICE,star style,style của sao,",
category: 'stars',
time: '10:53 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:53 AM',
link: '/jihyo-twice-dien-chung-ao-voi-mau-hang-nhung-chiem-song-nho-1-chi-tiet-mlem-hon-han',
component: 'page_20210811105323'},
{title: "Cho rằng bản thân chưa đủ quyến rũ, vậy cô gái da ngăm, vòng 1 bốc lửa đến nghẹt thở này là ai vậy Jihyo ơi?",
description: "Bạn thích Jihyo theo style nào hơn?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/screenshot-453-16286535197461574804397.png',
alt: "jihyo (twice),TWICE,star style,sao Hàn,idol,Kpop,quyến rũ,sexy,dễ thương,",
category: 'stars',
time: '10:52 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:52 AM',
link: '/cho-rang-ban-than-chua-du-quyen-ru-vay-co-gai-da-ngam-vong-1-boc-lua-den-nghet-tho-nay-la-ai-vay-jihyo-oi',
component: 'page_20210811105221'},
{title: "Luôn ăn vận thanh lịch nhưng Emma Watson vẫn có 2 pha \"lộ hàng\" mà cô không bao giờ muốn nhớ lại nữa",
description: "Và thật may mắn, đây chỉ là 2 sự cố hiếm hoi trong lịch sử ăn mặc của Emma Watson.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/10/kz65wzrxhlaf5uj9vcvy0hrmihyfbkjrl31q2idin3q-1628604398082745033437.jpeg',
alt: "emma watson,lộ hàng,sự cố trang phục,harry potter,sao us uk,star style,sao hớ hênh,",
category: 'stars',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/luon-an-van-thanh-lich-nhung-emma-watson-van-co-2-pha-lo-hang-ma-co-khong-bao-gio-muon-nho-lai-nua',
component: 'page_20210811105118'},
{title: "Nhờ Jisoo (BLACKPINK) một ứng dụng tăng 160 bậc trên App Store, nhiều người đăng kí đến nỗi đơ luôn cả app",
description: "Đây không phải lần đầu Jisoo khiến một ứng dụng trở nên nổi tiếng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/22955807010161020224965396189254754894077920n-1628579327444746997901.jpg',
alt: "gương mặt đại diện,nền tảng mới,Google Play Store,fan hâm mộ,jisoo,Celebe,Blackpink,ứng dụng,app,MXH,",
category: 'stars',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/nho-jisoo-blackpink-mot-ung-dung-tang-160-bac-tren-app-store-nhieu-nguoi-dang-ki-den-noi-do-luon-ca-app',
component: 'page_20210811105013'},
{title: "35 tuổi mà cứ xinh mơn mởn như thiếu nữ 18, Triệu Lệ Dĩnh vừa tung ảnh mặc đồng phục là gây bão khắp Weibo ngay",
description: "Trở thành diễn viên trong MV ca nhạc của nam ca sĩ Trương Kiệt, Triệu Lệ Dĩnh khiến netizen xuýt xoa với nét đẹp trong trẻo, đáng yêu.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/9/photo-1-1628513974213601470298.jpg',
alt: "triệu lệ dĩnh,Mỹ nhân Hoa ngữ,nhan sắc của sao,vóc dáng của sao,body của sao,",
category: 'stars',
time: '10:54 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:54 AM',
link: '/35-tuoi-ma-cu-xinh-mon-mon-nhu-thieu-nu-18-trieu-le-dinh-vua-tung-anh-mac-dong-phuc-la-gay-bao-khap-weibo-ngay',
component: 'page_20210809105456'},
{title: "Jennie lập kỷ lục với MV SOLO nhưng vẫn bị video vũ đạo ngang ngược nhất của BLACKPINK vượt mặt",
description: "Jennie lập kỷ lục ở mảng nữ nghệ sĩ solo Kpop. Thế nhưng MV của cô vẫn không nhiều lượt xem bằng video trình diễn vũ đạo How You Like That, được BLACKPINK phát hành vào tháng 7/2020.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/9/jennie-solo-162850394849752557449.jpeg',
alt: "Jennie (BlackPink),Blackpink,How You Like That Dance Performance,view youtube,MV SOLO Jennie,kỷ lục nghệ sĩ solo nữ Kpop,How You Like That,",
category: 'stars',
time: '10:54 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:54 AM',
link: '/jennie-lap-ky-luc-voi-mv-solo-nhung-van-bi-video-vu-dao-ngang-nguoc-nhat-cua-blackpink-vuot-mat',
component: 'page_20210809105446'},
{title: "Chú bé đáng yêu ở phim Châu Tinh Trì hóa ra là... cô bé, lột xác thành mỹ nhân lại từng sánh đôi với Tiêu Chiến?",
description: "Nếu bạn đã từng ăn \"cú lừa\" này từ Châu Tinh Trì thì xin chúc mừng, tuổi thơ bạn thật tuyệt vời!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/7/photo-1-1628312179961827626103.jpg',
alt: "Châu Tinh Trì,từ kiều,tiêu chiến,CJ7,Siêu khuyển thần thông,phim Hoa ngữ,phim điện ảnh,phim chiếu rạp,dàn sao ngày ấy bây giờ,sao nhí,Người Đi Ngược Chiều Đẹp Nhất,ngày ấy bây giờ,",
category: 'stars',
time: '10:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:21 PM',
link: '/chu-be-dang-yeu-o-phim-chau-tinh-tri-hoa-ra-la-co-be-lot-xac-thanh-my-nhan-lai-tung-sanh-doi-voi-tieu-chien',
component: 'page_20210808222103'},
{title: "Loạt \"phốt\" nối dài của Jack: Thái độ sao hạng A, nghi vấn nói xấu Sơn Tùng - ViruSs nhưng scandal có con riêng mới gây sốc toàn tập",
description: "Đã hot càng thêm hot, Jack bây giờ chính là cái tên thu hút đông đảo sự chú ý từ phía công chúng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/thumb-bes-16283928436601467777066.jpg',
alt: "Jack,profile Jack,scandal jack,jack có con với thiên an,jack bắt cá 2 tay,nam ca sĩ Jack,K-ICM,Scandal Jack bị tố ngoại tình, có con,",
category: 'stars',
time: '03:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 03:21 PM',
link: '/loat-phot-noi-dai-cua-jack-thai-do-sao-hang-a-nghi-van-noi-xau-son-tung-viruss-nhung-scandal-co-con-rieng-moi-gay-soc-toan-tap',
component: 'page_20210808152154'},
{title: "Toàn cảnh 24 giờ bùng nổ bê bối chấn động của Jack: Thiên An và 2 cô gái tố cáo liên hoàn, chiêu thức tán gái và cuộc gọi đối chất gây sốc",
description: "Những diễn biến trong vụ bê bối tình cảm của Jack diễn ra liên hồi khiến người chơi hệ hóng chuyện chuyên nghiệp cũng phải xỉu ngang vì hít không kịp drama. Vậy sau 24 giờ diễn ra, lùm xùm này đã có những tình tiết gì?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/fb-1628440838583422351702.png',
alt: "Scandal Jack bị tố ngoại tình, có con,lùm xùm của Jack,Jack bị tố ngoại tình,con gái của Jack,sao Việt,Vbiz,showbiz Việt,Jack và Thiên An,Thiên An,Jack bị bóc phốt,toàn cảnh bê bối của Jack,Jack là ai,Drama Thiên An và Jack,biến của Jack,",
category: 'stars',
time: '03:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 03:21 PM',
link: '/toan-canh-24-gio-bung-no-be-boi-chan-dong-cua-jack-thien-an-va-2-co-gai-to-cao-lien-hoan-chieu-thuc-tan-gai-va-cuoc-goi-doi-chat-gay-soc',
component: 'page_20210808152134'},
{title: "The Suicide Squad: Tầng lớp cặn bã của siêu anh hùng làm nên điều Marvel chắc chắn sẽ chối từ",
description: "The Suicide Squad hẳn là bộ phim siêu anh hùng bạo lực, máu me nhất từ trước tới nay - đến cái mức mà Deadpool trông có vẻ hiền lành hơn hẳn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/ngang-16284211045481640544690.png',
alt: "Suicide Squad (2016),James Gunn,DCEU,DC Universe,phim DC,DC,phim siêu anh hùng,review phim,phim Âu Mỹ,The Suicide Squad (2021),",
category: 'stars',
time: '03:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 03:21 PM',
link: '/the-suicide-squad-tang-lop-can-ba-cua-sieu-anh-hung-lam-nen-dieu-marvel-chac-chan-se-choi-tu',
component: 'page_20210808152116'},
{title: "Xu hướng high teen sắp bùng nổ rồi: Khởi nguồn từ huyền thoại Clueless ngày xưa và giờ có BLACKPINK, Somi lăng xê nhiệt tình",
description: "Dường như đây sẽ là phong cách làm mưa làm gió trong thời gian tới.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/tumblrbe1d056d31ad36418533bf69ce786e02bae8c4a2540-16283151099122001578213.gif',
alt: "somi,Black Pink,clueless,star style,sao Hàn,thời trang trong phim,high teen,hot trends,hot trends 2021,",
category: 'stars',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/xu-huong-high-teen-sap-bung-no-roi-khoi-nguon-tu-huyen-thoai-clueless-ngay-xua-va-gio-co-blackpink-somi-lang-xe-nhiet-tinh',
component: 'page_20210807154429'},
{title: "Ngọc Trinh đăng clip hậu trường bộ ảnh hở đến gây bão, ai dè để lộ body trơ xương khác hẳn ảnh đã chỉnh sửa",
description: "Thân hình của Ngọc Trinh vào khoảng đầu năm 2021 đã gây tranh cãi rất nhiều vì quá gầy.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/128764309102149734589304838208032571802860702o-1607039468229384161398-1628324409876313351228.jpeg',
alt: "sao Việt,showbiz Việt,nữ hoàng nội y,Ngọc Trinh,body sao,",
category: 'stars',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/ngoc-trinh-dang-clip-hau-truong-bo-anh-ho-den-gay-bao-ai-de-de-lo-body-tro-xuong-khac-han-anh-da-chinh-sua',
component: 'page_20210807154418'},
{title: "6 khoảnh khắc kinh diễm nhất của Selena Gomez trên thảm đỏ: Chưa bao giờ vượt quá giới hạn, dù hở bạo nhưng điểm tinh tế vẫn tròn 100",
description: "Không biết tới bao giờ chúng ta mới lại được nhìn Selena Gomez thả dáng xuất sắc tiếp đây.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/7/photo-1-1628309575333344594990.jpg',
alt: "Selena Gomez,star style,Thời trang thảm đỏ,thảm đỏ,",
category: 'stars',
time: '03:44 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:44 PM',
link: '/6-khoanh-khac-kinh-diem-nhat-cua-selena-gomez-tren-tham-do-chua-bao-gio-vuot-qua-gioi-han-du-ho-bao-nhung-diem-tinh-te-van-tron-100',
component: 'page_20210807154400'},
{title: "10 nữ idol sinh ra là để nhuộm tóc vàng bạch kim: Quanh đi quẩn lại vẫn toàn các báu vật của 3 \"ông lớn\"",
description: "Visual của các idol Kpop khi để tóc vàng bạch kim đúng là khiến con dân điên đảo vì xuất thần quá mức.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/7/nu-than-toc-vang-rose-blackpink-nay-da-co-doi-thu-2a252b92-1628312258014643678302.jpg',
alt: "nhuộm tóc,tóc của sao,sao Hàn,idol Kpop,tóc tẩy,mỹ nhân tóc vàng,tóc vàng,idol Hàn,sm entertainment,JYP,YG Entertainment,",
category: 'stars',
time: '03:43 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:43 PM',
link: '/10-nu-idol-sinh-ra-la-de-nhuom-toc-vang-bach-kim-quanh-di-quan-lai-van-toan-cac-bau-vat-cua-3-ong-lon',
component: 'page_20210807154349'},
{title: "\"Xả ảnh\" HD chưa từng công bố của BLACKPINK: Choáng khi zoom cận mặt Jennie, Jisoo o ép vòng 1 đè bẹp thánh body Lisa - Rosé",
description: "Nhan sắc của các thành viên BLACKPINK trong THE ALBUM phiên bản Nhật đã trở thành chủ đề hot trên mạng xã hội.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/e6npqnwxmaisvsn-1628266883930666893959.jpg',
alt: "Blackpink,lisa (blackpink),Jennie (BlackPink),rosé (blackpink),jisoo (blackpink),jisoo,lisa,jennie,Rosé,sao Hàn,nhan sắc sao Hàn,the album,thánh body,body của sao,mỹ nhân hàn,mỹ nhân kpop,",
category: 'stars',
time: '03:43 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:43 PM',
link: '/xa-anh-hd-chua-tung-cong-bo-cua-blackpink-choang-khi-zoom-can-mat-jennie-jisoo-o-ep-vong-1-de-bep-thanh-body-lisa-rose',
component: 'page_20210807154338'},
{title: "Muôn màu mỹ nhân dự đám tang trên màn ảnh xứ Trung: Cúc Tịnh Y lố cực điểm, ca cuối khóc \"giả trân\" mà xinh tột cùng",
description: "Ngay cả trong cảnh đám tang, các mỹ nhân như Cúc Tịnh Y hay Triệu Lệ Dĩnh cũng khoe sắc theo nhiều cách khác nhau.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/photo-1-1628166200315347069722.jpg',
alt: "Cúc Tịnh Y,triệu lệ dĩnh,Tân Chỉ Lôi,Trương Hinh Dư,Xa Thi Mạn,Châu Tấn,Đồng Dao,Như Ý Truyện,Minh Lan Truyện,Như Ý Phương Phi,Thiên long bát bộ,phim Hoa ngữ,phim cổ trang,phim trung quốc,Phim truyền hình,Yến Vân Đài,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:36 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:36 AM',
link: '/muon-mau-my-nhan-du-dam-tang-tren-man-anh-xu-trung-cuc-tinh-y-lo-cuc-diem-ca-cuoi-khoc-gia-tran-ma-xinh-tot-cung',
component: 'page_20210806093635'},
{title: "Hành trình thay đổi body của \"bông hồng lai\" Somi: Fan lo lắng vì vòng eo siêu thực lộ cả xương sườn?",
description: "Qua thời gian, center đời đầu của chuỗi show tuyển tú Produce - Jeon Somi ngày một đổi khác, trưởng thành và vô cùng cuốn hút.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-16282351309751096494008.jpg',
alt: "Jeon Somi,somi,produce,Produce 101,sixteen,JYP,JYP Entertainment,The Black Label,Teddy,vóc dáng,nuột nà,búp bê sống,",
category: 'stars',
time: '09:36 AM',
date: '06/08/2021',
timestamp: '06/08/2021 09:36 AM',
link: '/hanh-trinh-thay-doi-body-cua-bong-hong-lai-somi-fan-lo-lang-vi-vong-eo-sieu-thuc-lo-ca-xuong-suon',
component: 'page_20210806093626'},
{title: "Ariana Grande gây sốc nhẹ vì vòng 1 bỗng dưng nảy nở, ai ngờ giờ bị chính fandom \"lật tẩy\"",
description: "Quả là 1 màn \"bóc phốt\" thú vị tới từ cộng đồng fan của Ariana Grande.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/20542856341750211025806544325069818059031174n-16282140665451950051075.jpeg',
alt: "Ariana Grande,công nghệ thẩm mỹ thời nay,sao us uk,Showbiz,dao kéo,nâng ngực,",
category: 'stars',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/ariana-grande-gay-soc-nhe-vi-vong-1-bong-dung-nay-no-ai-ngo-gio-bi-chinh-fandom-lat-tay',
component: 'page_20210805231045'},
{title: "10 bộ phim khiến diễn viên chính nhục nhã, xấu hổ cả đời: Kate Winslet muốn nôn mửa vì Titanic, ngôi sao khác còn khóc suốt 1 tiếng vì bị ép đóng!",
description: "Những cái tên dưới đây dù hay - dở thế nào thì vẫn khiến diễn viên chính phải xấu hổ và nhục nhã, hối hận mãi không thôi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/1/photo-1-16278081029641864655115.png',
alt: "Titanic (1997),kate winslet,Nicole Kidman,zac efron,Jamie Lee Curtis,Kelly Clarkson,Will Smith,channing tatum,Lindsay Lohan,halle berry,ben affleck,phim Âu Mỹ,",
category: 'stars',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/10-bo-phim-khien-dien-vien-chinh-nhuc-nha-xau-ho-ca-doi-kate-winslet-muon-non-mua-vi-titanic-ngoi-sao-khac-con-khoc-suot-1-tieng-vi-bi-ep-dong',
component: 'page_20210805231036'},
{title: "1 hành động nhỏ của Jennie (BLACKPINK) tại fansign hot trở lại, khiến Knet phải thốt lên: \"Thì ra con người thật là đây?\"",
description: "Hành động này khiến fan càng thêm \"u mê\" Jennie (BLACKPINK) không lối thoát.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/5/91ccd292-c32a-4c07-ae06-03b557043b8c-1628158240140106028803.gif',
alt: "jennie,Jennie (BlackPink),Blackpink,sao Hàn,idol Kpop,fansign,",
category: 'stars',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/1-hanh-dong-nho-cua-jennie-blackpink-tai-fansign-hot-tro-lai-khien-knet-phai-thot-len-thi-ra-con-nguoi-that-la-day',
component: 'page_20210805231026'},
{title: "Những nàng công chúa đẹp kinh diễm nhất Cbiz: Angela Baby - Dương Mịch đẹp nghẹt thở, vẫn bị Nhiệt Ba diễm lệ \"đè bẹp\"",
description: "Vẫn biết dàn mỹ nhân Cbiz sở hữu nhan sắc hoàn hảo, tuy nhiên khi nhìn vào những lần Angela Baby, Địch Lệ Nhiệt Ba, Triệu Lệ Dĩnh,... dạo bước thảm đỏ với váy công chúa, dân tình vẫn xuýt xoa vì quá kinh diễm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/3/ba6-1627980978769306097484.jpeg',
alt: "Dương Mịch,Angela Baby,Lưu Diệc Phi,Triệu Lộ Tư,triệu lệ dĩnh,quan hiểu đồng,Cúc Tịnh Y,sao Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,Địch Lệ Nhiệt Ba,mỹ nhân,",
category: 'stars',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/nhung-nang-cong-chua-dep-kinh-diem-nhat-cbiz-angela-baby-duong-mich-dep-nghet-tho-van-bi-nhiet-ba-kinh-diem-de-bep',
component: 'page_20210805231011'},
{title: "Quỳnh Nga đăng ảnh khoe hoa quả nhưng spotlight lại dồn cả vào \"toà thiên nhiên\" sau lớp váy ngủ",
description: "Và người ta cũng biết đây không phải lần đầu Quỳnh Nga khiến người xem phải đắm đuối với \"kỳ quan\" của mình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/base64-1628180150788560845786.png',
alt: "quỳnh nga,sao Việt,showbiz Việt,sao sexy,ngực khủng,vòng 1,",
category: 'stars',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/quynh-nga-dang-anh-khoe-hoa-qua-nhung-spotlight-lai-don-ca-vao-toa-thien-nhien-sau-lop-vay-ngu',
component: 'page_20210805230945'},
{title: "Muốn \"bóc trần\" nhan sắc Rosé (BLACKPINK) ở hậu trường mà bó tay, \"đắt\" nhất combo tóc vàng bay tôn lên visual đẹp muốn lịm đi",
description: "Rosé (BLACKPINK) thực sự đẹp như nàng thơ trong hậu trường chương trình The Sea Of Hope.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/3/unnamed-1-16279577072521308032579.jpg',
alt: "Blackpink,rosé (blackpink),Rosé,the sea of hope,nhan sắc sao Hàn,nhan sắc của blackpink,nhan sắc của sao Hàn,nhan sắc thật của sao,",
category: 'stars',
time: '11:12 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:12 AM',
link: '/muon-boc-tran-nhan-sac-rose-blackpink-o-hau-truong-ma-bo-tay-dat-nhat-combo-toc-vang-bay-ton-len-visual-dep-muon-lim-di',
component: 'page_20210803111244'},
{title: "Nữ thần lai nhà YG Jeon Somi gây bão với visual siêu thực như búp bê Barbie, Jennie u mê đến mức spam bình luận khen hết lời",
description: "Nhan sắc, body của Jeon Somi đỉnh thế nào mà lại khiến Jennie (BLACKPINK) thành fangirl cuồng nhiệt như thế này?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/2/937a26868500482b9ea2a9269c04f50b-16279138982341883782145.jpg',
alt: "Jeon Somi,Jennie (BlackPink),Blackpink,nhan sắc sao Hàn,sao Hàn,Somi comeback,somi,The Black Label,YG Entertainment,nữ thần lai,Somi (The Black Label),body của sao,",
category: 'stars',
time: '11:09 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:09 AM',
link: '/nu-than-lai-nha-yg-jeon-somi-gay-bao-voi-visual-sieu-thuc-nhu-bup-be-barbie-jennie-u-me-den-muc-spam-binh-luan-khen-het-loi',
component: 'page_20210803110946'},
{title: "Lọ Lem nhà MC Quyền Linh xinh thực sự, ở nhà mặc đồ bộ mà cũng có loạt ảnh \"tới công chuyện\"",
description: "Không xuất hiện trong những bộ váy nền nã, nữ tính thường thấy nhưng Lọ Lem vẫn khiến mọi người khen nức nở.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/2/anh-chup-man-hinh-2021-08-02-luc-73949-sa-16278650239571095112497.png',
alt: "Lọ Lem,Con gái MC Quyền Linh,MC Quyền Linh,mai thảo linh,gái xinh,Hello GenZ,Nhà Lên Đèn, Em Lên Đồ,gái xinh gen Z,",
category: 'stars',
time: '01:21 AM',
date: '44235',
timestamp: '02/08/2021 01:21 AM',
link: '/lo-lem-nha-mc-quyen-linh-xinh-thuc-su-o-nha-mac-do-bo-ma-cung-co-loat-anh-toi-cong-chuyen',
component: 'page_20210802012120'},
{title: "Triệu Lệ Dĩnh thật sự \"nặng nợ\" với giày dép!",
description: "Vốn có tạng người nhỏ bé, đặc biệt là đôi chân nhỏ nên Triệu Lệ Dĩnh cũng gặp nhiều khó khăn khi chọn giày dép.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/1/photo-1-16272214927271347365146-1627272206890982067480-16278016114931281461667.jpeg',
alt: "triệu lệ dĩnh,sao Trung,sao Hoa ngữ,star style,giày cao gót,boot,sao mặc gì,giày rộng,",
category: 'stars',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/trieu-le-dinh-that-su-nang-no-voi-giay-dep',
component: 'page_20210802011723'},
{title: "Mỹ nhân Hoa ngữ \"cưa sừng làm nghé\" hóa nữ sinh: Nhiệt Ba - Trịnh Sảng trẻ trung ngỡ ngàng, \"trùm cuối\" là cao thủ hack tuổi",
description: "Nhiều mỹ nhân Hoa ngữ khiến khán giả ngỡ ngàng bởi vẻ trẻ trung và trong sáng khi vào vai nữ sinh.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/27/33-1627371707185415933730.jpg',
alt: "Địch Lệ Nhiệt Ba,Dương Mịch,Lưu Thi Thi,Lưu Diệc Phi,Trịnh Sảng,Trần Nghiên Hy,đàm tùng vận,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/my-nhan-hoa-ngu-cua-sung-lam-nghe-hoa-nu-sinh-nhiet-ba-trinh-sang-tre-trung-ngo-ngang-trum-cuoi-la-cao-thu-hack-tuoi',
component: 'page_20210802011700'},
{title: "Hội nữ phụ cổ trang \"một phát lên luôn\" vì quá đẹp: Nhiệt Ba - Lộ Tư chạm mặt cực gắt, số 4 \"ghi bàn\" chỉ với vài phút lên sóng!",
description: "Một số mỹ nhân như Địch Lệ Nhiệt Ba, Triệu Lộ Tư,... từng ghi điểm khi thủ vai nữ phụ trong các phim cổ trang đình đám.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/29/-16275527395871012338823.jpg',
alt: "Như Ý Truyện,Tam Sinh Tam Thế: Thập Lý Đào Hoa,Trường Ca Hành,Địch Lệ Nhiệt Ba,Triệu Lộ Tư,Lý Niệm,Lưu Đào,vương viện khả,Tân Chỉ Lôi,Dương Quý Phi Bí Sử,Mị Nguyệt Truyện,Thiên long bát bộ,Diên Hi Công Lược,phim Hoa ngữ,phim trung quốc,Phim truyền hình,phim cổ trang,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/hoi-nu-phu-co-trang-mot-phat-len-luon-vi-qua-dep-nhiet-ba-lo-tu-cham-mat-cuc-gat-so-4-ghi-ban-chi-voi-vai-phut-len-song',
component: 'page_20210731131834'},
{title: "Địch Lệ Nhiệt Ba lên xe hoa trong phim đến tận 6 lần, là \"cô dâu\" của loạt mỹ nam đình đám nhất xứ Trung",
description: "Địch Lệ Nhiệt Ba từng làm đám cưới với Cao Vỹ Quang, Đặng Luân, Hoàng Cảnh Du và sắp tới là Dương Dương.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/25/2208188983412207008871718384330042314919955n-1626843797190593941880-1627205190130937966228.jpeg',
alt: "Phim truyền hình,phim trung quốc,phim truyền hình trung quốc,Em Là Niềm Kiêu Hãnh Của Anh,Địch Lệ Nhiệt Ba,Dương Dương,hoàng cảnh du,Đặng Luân,Cao Vỹ Quang,Hạnh Phúc Trong Tầm Tay (2020),Tam Sinh Tam Thế Thập Lý Đào Hoa,Tam Sinh Tam Thế Chẩm Thượng Thư,Hạnh Phúc Trong Tầm Tay,Em Là Niềm Kiêu Hãnh Của Anh (2021),",
category: 'stars',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/dich-le-nhiet-ba-len-xe-hoa-trong-phim-den-tan-6-lan-la-co-dau-cua-loat-my-nam-dinh-dam-nhat-xu-trung',
component: 'page_20210731131823'},
{title: "Điểm lại 10 trend khai nguồn từ idol Hàn, đâu mới là xu hướng được lòng netizen nhất?",
description: "Toàn là các trend dễ học, dễ làm như này, bảo sao netizen nhà mình cứ tha hồ thử nghiệm mãi thôi.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/screen-shot-2019-01-19-at-42224-pm-15478903271781963938842-16277037921091345497149.png',
alt: "Makeup,star style,tóc của sao,hot trend,idol Hàn,idol Kpop,hot trends 2021,",
category: 'stars',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/diem-lai-10-trend-khai-nguon-tu-idol-han-dau-moi-la-xu-huong-duoc-long-netizen-nhat',
component: 'page_20210731131802'},
{title: "Chết chìm với bể visual bùng nổ của \"cảnh sát\" Krystal ở phim mới, netizen đùa \"bắt em đi chị ơi\"",
description: "Đại Học Cảnh Sát vừa chiêu đãi khán giả bằng loạt hình ảnh mới đẹp lịm người của Krystal và bạn diễn.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/2237122058783872030332062401946223716323604n-16277120661511418042355.jpg',
alt: "Phim Hàn Quốc,Krystal,jinyoung (got7),Phim truyền hình,Police University (2021),Đại Học Cảnh Sát (2021),",
category: 'stars',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/chet-chim-voi-be-visual-bung-no-cua-canh-sat-krystal-o-phim-moi-netizen-dua-bat-em-di-chi-oi',
component: 'page_20210731131749'},
{title: "Lên đồ cảnh sát mà vẫn sang chảnh, thần thái ngời ngợi thì chỉ có Krystal, bảo sao netizen khen đến là mỏi miệng",
description: "Tạo hình mới của Krystal đang nhận được rất nhiều lời khen ngợi từ người hâm mộ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/2173667428783877763664825335057837963301280n-1627727822602427564995.jpg',
alt: "Krystal,sao Hàn,Kbiz,star style,Police University (2021),tóc của sao,",
category: 'stars',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/len-do-canh-sat-ma-van-sang-chanh-than-thai-ngoi-ngoi-thi-chi-co-krystal-bao-sao-netizen-khen-den-la-moi-mieng',
component: 'page_20210731131736'},
{title: "Ngất ngây ảnh nữ thần ngực khủng Anne Hathaway hóa công chúa: Nhan sắc chuẩn báu vật Hollywood, khí chất vương giả khó ai bì",
description: "2 thập kỷ đã trôi qua nhưng nhan sắc của Anne Hathaway trong bộ phim Nhật Ký Công Chúa vẫn khiến khán giả phải trầm trồ và mê mẩn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/30/photo-1-16276241792571824908501.jpg',
alt: "anne hathaway,sao us uk,sao hollywood,Nhật Ký Công Chúa,mỹ nhân hollywood,nhan sắc mỹ nhân Hollywood,mỹ nhân,Nhật Ký Công Chúa (2001),The Princess Diaries,The Princess Diaries (2001),",
category: 'stars',
time: '12:16 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:16 PM',
link: '/ngat-ngay-anh-nu-than-nguc-khung-anne-hathaway-hoa-cong-chua-nhan-sac-chuan-bau-vat-hollywood-khi-chat-vuong-gia-kho-ai-bi',
component: 'page_20210730121641'},
{title: "Thành gái độc thân, body Dương Mịch ngày càng \"mlem\": Nhìn màn hở bạo khoe vòng eo sexy ở sự kiện hôm nay là rõ",
description: "Hình ảnh trong sự kiện tối ngày 27/7 của Dương Mịch khiến dân tình cảm thấy nghẹt thở vì độ sexy ngút ngàn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/27/photo-1-1627390920786544123096.jpg',
alt: "Dương Mịch,sao Hoa ngữ,body của sao,vóc dáng mỹ nhân Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,nhan sắc mỹ nhân showbiz,",
category: 'stars',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/thanh-gai-doc-than-body-duong-mich-ngay-cang-mlem-nhin-man-ho-bao-khoe-vong-eo-sexy-o-su-kien-hom-nay-la-ro',
component: 'page_20210729162550'},
{title: "Dàn sao nữ tỏ thái độ vì phát hiện “yêu râu xanh” Ngô Diệc Phàm: Triệu Lệ Dĩnh - Angela Baby cực phũ, Lưu Diệc Phi ám ảnh sau nụ hôn ngấu nghiến",
description: "Sau loạt bê bối của Ngô Diệc Phàm, những lời chia sẻ của dàn sao nữ liên quan đến ngôi sao này liên tiếp bị đào lại.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/25/photo-1-1627191894233494817409.jpg',
alt: "ngô diệc phàm,triệu kim mạch,Angela Baby,triệu lệ dĩnh,Lưu Diệc Phi,Tần Ngưu Chính Uy,",
category: 'stars',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/dan-sao-nu-to-thai-do-vi-phat-hien-yeu-rau-xanh-trong-ngo-diec-pham-trieu-le-dinh-angela-dap-cuc-phu-luu-diec-phi-ne-voi-vi-am-anh',
component: 'page_20210729162335'},
{title: "10 ác nữ ấn tượng trên màn ảnh Hoa ngữ: Triệu Lệ Dĩnh hiểm độc đến mấy cũng không gây ức chế bằng \"trùm cuối\"",
description: "Đây đều là những mỹ nhân sở hữu nhan sắc xinh đẹp nhưng lòng dạ mưu mô, thủ đoạn trong phim Hoa ngữ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/23/3-1627031123979499954580.jpg',
alt: "triệu lệ dĩnh,Lý Thuần,lý thấm,tô thanh,mao hiểu đồng,Tân Chỉ Lôi,Xa Thi Mạn,tưởng hân,Lưu Đào,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/10-ac-nu-an-tuong-tren-man-anh-hoa-ngu-trieu-le-dinh-hiem-doc-den-may-cung-khong-gay-uc-che-bang-trum-cuoi',
component: 'page_20210729162312'},
{title: "Mỹ nhân Hoa ngữ ngượng chín mặt vì lộ nội y trên phim: \"Trùm cuối\" còn chuộng thả rông vòng 1 nữa cơ",
description: "Nhiều mỹ nhân đình đám xứ Trung từng bẽ mặt vì mắc lỗi nội y khi đóng phim.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/9/2-16258209658481572498571.jpg',
alt: "nhặt sạn phim ảnh,phim trung quốc,phim cổ trang,cổ lực na trát,triệu lệ dĩnh,Địch Lệ Nhiệt Ba,Dương Mịch,Lưu Diệc Phi,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/my-nhan-hoa-ngu-nguong-chin-mat-vi-lo-noi-y-tren-phim-trum-cuoi-con-chuong-tha-rong-vong-1',
component: 'page_20210729161603'},
{title: "MXH toàn cầu \"sốc visual\" vì BTS tại show Louis Vuitton: Jungkook đẹp tê điếng, hot hơn cả là màn lột xác của thành viên giàu nhất nhóm",
description: "BTSxLouisVuitton đã trở thành hashtag nóng nhất mạng xã hội hiện tại.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/7/20480873342068777127434615991189081392576798n-162566155003360307880.jpg',
alt: "BTS,bangtan boys (bts),v (bts),jungkook (bts),Jimin (BTS),Jin (BTS),suga (bts),RM (BTS),j-hope (bts),sao Hàn,Louis Vuitton,",
category: 'stars',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/mxh-toan-cau-soc-visual-vi-bts-tai-show-louis-vuitton-jungkook-dep-te-dieng-hot-hon-ca-la-man-lot-xac-cua-thanh-vien-giau-nhat-nhom',
component: 'page_20210729161420'},
{title: "Ngọc Trinh biến trend “con gái miền Tây” thành bữa tiệc khoe body “xôi thịt” ngồn ngộn và thần thái đỉnh từng giây!",
description: "Ơ kìa, Ngọc Trinh đầu tư cả bộ bikini mới cho hợp màu siêu xe để đu trend luôn đây này.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/5/2128928609278650014018717643360140152334194n-16254945848162093186802-16254946051841629841253.jpg',
alt: "Ngọc Trinh,sao khoe body,body sao việt,showbiz Việt,Body Ngọc Trinh,đại chiến bikini,",
category: 'stars',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/ngoc-trinh-bien-trend-con-gai-mien-tay-thanh-bua-tiec-khoe-body-xoi-thit-ngon-ngon-va-than-thai-dinh-tung-giay',
component: 'page_20210729161147'},
{title: "Lâu lắm mới thấy 1 nữ phụ ăn mặc đến là duyên, chẳng chút mờ nhạt mà còn lấn át cả IU mới sợ!",
description: "Kang Han Na đang dần để lại ấn tượng về một nữ phụ sành điệu và nổi bật trong lòng khán giả.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/5/kanghan-nafull144064-16254838544811772504521.jpg',
alt: "Kang Hana,IU,suzy,hyeri,sao Hàn,phim Hàn,drama,Người tình ánh trăng,khởi nghiệp,Bạn Cùng Phòng Của Tôi Là Gumiho,star style,nữ phụ,",
category: 'stars',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/nang-fashion-icon-dang-len-cua-kdrama-phong-cach-hien-dai-nhin-ma-thich-me-bien-luon-iu-thanh-vai-phu-mo-nhat',
component: 'page_20210729161138'},
{title: "Ngọc Trinh đi bơi tại biệt thự 1800m2, Vũ Khắc Tiệp \"tóm gọn\" bằng clip quay vội nhưng không dìm nổi body chuẩn chỉnh",
description: "Người hâm mộ một lần nữa \"mắt tròn mắt dẹt\" với chiếc clip \"sương sương\" ghi lại khoảnh khắc quyến rũ không lối thoát của Ngọc Trinh.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/4/photo-1-16253595832721777633316.png',
alt: "Ngọc Trinh,nữ hoàng nội y Ngọc Trinh,Body Ngọc Trinh,nữ hoàng nội y,Vũ Khắc Tiệp,ông bầu Vũ Khắc Tiệp,ông bầu Venus,sao Việt,đại chiến bikini,Sao diện bikini,",
category: 'stars',
time: '04:11 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:11 PM',
link: '/ngoc-trinh-hot-hai-di-boi-tai-biet-thu-1800m2-vu-khac-tiep-tom-gon-bang-chiec-clip-quay-voi-nhung-khong-dim-noi-body-chuan-tung-centimet',
component: 'page_20210729161128'},
{title: "Một ngọc nữ của showbiz Việt bị đồn vừa \"độ\" vòng 1, cô nhẹ nhàng show luôn ảnh 10 năm trước: Do chị \"khum\" thích mặc hở thôi!",
description: "Nhìn người ta dịu dàng, hay mặc đồ kín đâu có nghĩa là không có body sexy đâu cư dân mạng ơi!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/3/210619900351758119642656451566730756785475n-162531707747769657727.jpeg',
alt: "Midu,midu nâng vòng 1,midu sửa ngực,midu sửa ngực ở đâu,phẫu thuật thẩm mỹ,sao nâng ngực ở đâu,",
category: 'stars',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/mot-ngoc-nu-cua-showbiz-viet-bi-don-vua-do-vong-1-co-nhe-nhang-show-luon-anh-10-nam-truoc-do-chi-khum-thich-mac-ho-thoi',
component: 'page_20210729160923'},
{title: "Lưu Thi Thi 17 tuổi đẹp tới nhường nào: Gương mặt bầu bĩnh siêu dễ thương, tóc bết khóc sụt sùi mà visual vẫn ngút ngàn",
description: "Loạt ảnh cũ của Lưu Thi Thi mới được \"đào mộ\" khiến dân tình phải xuýt xoa trước nét đẹp kiều diễm của cô nàng.",
src: 'https://kenh14cdn.com/203336854389633024/2021/6/29/photo-1-16249822963361057313185.gif',
alt: "Lưu Thi Thi,sao Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,mỹ nhân,",
category: 'stars',
time: '10:05 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:05 PM',
link: '/luu-thi-thi-17-tuoi-dep-toi-nhuong-nao-guong-mat-bau-binh-sieu-de-thuong-toc-bet-khoc-sut-sui-ma-visual-van-ngut-ngan',
component: 'page_20210629220538'},
{title: "Xinh đẹp và gợi cảm, nữ streamer Việt bất ngờ gặp rắc rối, bị \"ghép mặt\" vào ảnh nóng bỏng",
description: "Màn ghép ảnh không tì vết của cô nàng streamer này đã khiến cho khá nhiều người phải trầm trồ vì sự gợi cảm.",
src: 'https://gamek.mediacdn.vn/thumb_w/660/133514250583805952/2021/6/28/screenshot854-1621392944405530986247-1624854174009612651548.png',
alt: "GÁI XINH,HOT GIRL,CỘNG ĐỒNG MẠNG,STREAMER,NỮ STREAMER,QUỲNH ALEE,",
category: 'stars',
time: '10:03 PM',
date: '29/06/2021',
timestamp: '29/06/2021 10:03 PM',
link: '/xinh-dep-va-goi-cam-nu-streamer-viet-bat-ngo-gap-rac-roi-bi-ghep-mat-vao-anh-nong-bong',
component: 'page_20210629220347'},
{title: "Tranh cãi sao Hollywood lên sân khấu Victorias Secret: Bella Hadid gặp tình cũ, Ariana méo mặt vì thiên thần, Taylor trả đũa nàng mẫu vì bị chê",
description: "Ngoài sự nóng bỏng của dàn thiên thần chân dài, khán giả còn vô cùng chú ý đến những ngôi sao Hollywood đình đám góp mặt trong show diễn của Victorias Secret.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215845--11-613621.jpg',
alt: "gigi hadid,sao us uk,sao hollywood,Victorias secret,bella hadid,taylor swift,Ariana Grande,The Weeknd,irina shayk,Kanye West,",
category: 'stars',
time: '09:58 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:58 PM',
link: '/tranh-cai-sao-hollywood-len-san-khau-victorias-secret-bella-hadid-gap-tinh-cu-ariana-meo-mat-vi-thien-than-taylor-tra-dua-nang-mau-vi-bi-che',
component: 'page_20210629215845'},
{title: "Nhiều lần xuất hiện cạnh chị em streamer Quỳnh Alee và Gấm Kami, nàng hot girl lạ gây chú ý",
description: "Cô nàng gây chú ý bởi nhan sắc như búp bê, nhiều người tò mò không biết đây có phải nữ streamer mới nổi?",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215524--11-35378.jpeg',
alt: "STREAMER,QUỲNH ALEE,GẤM KAMI,HOT GIRL,GÁI XINH,",
category: 'stars',
time: '09:55 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:55 PM',
link: '/nhieu-lan-xuat-hien-canh-chi-em-streamer-quynh-alee-va-gam-kami-nang-hot-girl-la-gay-chu-y',
component: 'page_20210629215524'},
{title: "Bước nhẹ ra vườn hồng ở biệt thự 21 tỷ, Lọ Lem có ngay bộ ảnh sống ảo, “sốc visual” nhất là góc nghiêng đẹp nức nở!",
description: "Biết là khen mãi rồi nhưng MC Quyền Linh có cô con gái Lọ Lem “đáng đồng tiền bát gạo” thật sự!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215258--11-099671.jpg',
alt: "MC Quyền Linh,sao Việt,Vbiz,showbiz Việt,Con gái MC Quyền Linh,bé lọ lem,con gái Quyền Linh,Lọ Lem con Quyền Linh,mỹ nhân gen z,",
category: 'stars',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/buoc-nhe-ra-vuon-hong-o-biet-thu-21-ty-lo-lem-co-ngay-bo-anh-song-ao-soc-visual-nhat-la-goc-nghieng-dep-nuc-no',
component: 'page_20210629215258'},
{title: "“Khoe” nét lai Tây từ bé, Xoài Non bị anti-fan chọc tức đến “uất nghẹn”",
description: "Xoài Non bị dân mạng nghi ngờ can thiệp dao kéo từ khi mới 14 tuổi.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215235--10-81303.jpeg',
alt: "XOÀI NON,XEMESIS,CỘNG ĐỒNG MẠNG,PHẪU THUẬT,",
category: 'stars',
time: '09:52 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:52 PM',
link: '/khoe-net-lai-tay-tu-be-xoai-non-bi-anti-fan-choc-tuc-den-uat-nghen',
component: 'page_20210629215235'},
{title: "Cựu tiếp viên hàng không lấy chồng CEO mang bầu vẫn khoe ảnh cực cháy, thế này ai làm lại đây!",
description: "Sắc vóc như thế này có mê không cơ chứ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215143--11-654452.jpg',
alt: "vũ ngọc châm,Vũ Ngọc Châm mang thai,CEO,chồng CEO,vũ ngọc châm và bạn trai,",
category: 'stars',
time: '09:51 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:51 PM',
link: '/cuu-tiep-vien-hang-khong-lay-chong-ceo-mang-bau-van-khoe-anh-cuc-chay-the-nay-ai-lam-lai-day',
component: 'page_20210629215143'},
{title: "Ở nhà rảnh rang, Khánh Vy cũng có ảnh OOTD đẹp \"lịm tim\" chỉ bằng công thức chụp siêu đơn giản",
description: "Tham khảo ngay loạt gợi ý hay ho này từ Khánh Vy, đảm bảo bạn ngồi ở nhà thở thôi cũng có ảnh đẹp!",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215106--10-316484.jpg',
alt: "star style,fashion,thời trang,làm đẹp,Makeup,sống ảo,selfie,chụp ảnh,Khánh Vy,show your best contest,Nhà Lên Đèn, Em Lên Đồ,Vũ trụ ở nhà không la cà,",
category: 'stars',
time: '09:51 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:51 PM',
link: '/o-nha-ranh-rang-khanh-vy-cung-co-anh-ootd-dep-lim-tim-chi-bang-cong-thuc-chup-sieu-don-gian',
component: 'page_20210629215106'},
{title: "\"Thánh ké fame\" Triệu Lộ Tư bùng nổ visual trên livestream: Biểu cảm, góc nghiêng đỉnh cao, bảo sao mọi lỗi lầm đều được tha thứ",
description: "Nhìn vào loạt khung hình xuất sắc của Triệu Lộ Tư, netizen xứ Trung càng phải công nhận cô nàng sở hữu nhan sắc ấn tượng.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210629215040--11-089240.gif',
alt: "Triệu Lộ Tư,sao Hoa ngữ,Mỹ nhân Hoa ngữ,nhan sắc mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:50 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:50 PM',
link: '/thanh-ke-fame-trieu-lo-tu-bung-no-visual-tren-livestream-bieu-cam-goc-nghieng-dinh-cao-bao-sao-moi-loi-lam-deu-duoc-tha-thu',
component: 'page_20210629215040'},
{title: "Mỹ nhân Hoa ngữ đóng phim tiên hiệp: Ai cũng đẹp ngoại trừ \"trùm cuối\"!",
description: "Phim thần tiên kỳ ảo hay còn gọi là tiên hiệp là thể loại vốn được ưa chuộng trên màn ảnh Hoa ngữ.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/6/20/2-1624124044817534968683.jpg',
alt: "châu đông vũ,triệu lệ dĩnh,Dương Mịch,Địch Lệ Nhiệt Ba,Dương Tử,nghê ni,Lưu Diệc Phi,viên băng nghiên,phim cổ trang,phim tiên hiệp,phim trung quốc,phim Hoa ngữ,Mỹ nhân Hoa ngữ,",
category: 'stars',
time: '09:43 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:43 PM',
link: '/my-nhan-hoa-ngu-dong-phim-tien-hiep-ai-cung-dep-ngoai-tru-trum-cuoi',
component: 'page_20210629214318'},
{title: "Đọ sắc trong tà áo dài trắng tinh khôi, các hot girl Esports hoá “nàng thơ\" trong sáng",
description: "Khác với hình ảnh trên sóng livestream, những cô nàng nổi tiếng giới Esports thướt tha trong tà áo dài trắng học đường.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210617140309--10-30061.jpeg',
alt: "Streamer, Esports, Hot Girl, Ao Dai, Cuoi Cap, ",
category: 'stars',
time: '02:03 PM',
date: '17/06/2021',
timestamp: '17/06/2021 02:03 PM',
link: '/do-sac-trong-ta-ao-dai-trang-tinh-khoi-cac-hot-girl-esports-hoa-nang-tho-trong-sang',
component: 'page_20210617140309'},
{title: "Cùng ngắm Triệu Lệ Dĩnh và 7749 tạo dáng \"giả trân\" khi quảng cáo trang sức",
description: "Là quảng cáo trang sức hạng sang hay trang sức đồ chơi đây thưa cô Triệu Lệ Dĩnh?",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616112833--10-651324.jpg',
alt: "Trieu Le Dinh, Trieu Lo Tu, Nayeon, Dep, Star Style, Style Cua Sao, ",
category: 'stars',
time: '11:28 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:28 AM',
link: '/cung-ngam-trieu-le-dinh-va-7749-tao-dang-gia-tran-khi-quang-cao-trang-suc',
component: 'page_20210616112833'},
{title: "Hóa ra Jennie từng có thời mặc đẹp đến mức antifan cũng phải \"câm nín\", không chê được điểm nào",
description: "Phải công nhận đây là giai đoạn bùng nổ của Jennie, hoàn hảo từ body cho tới phong cách thời trang, bảo sao ai cũng mê như điếu đổ.",
src: 'https://raw.githubusercontent.com/huyetnguyet/huyetnguyet.github.io/main/src/storages/images/content/2021/20210616112719--10-775735.jpg',
alt: "Jennie, Black Pink, Sao Han, Trang Phuc Trinh Dien, Outfit, Mac Dep, Idol, Kpop, ",
category: 'stars',
time: '11:27 AM',
date: '16/06/2021',
timestamp: '16/06/2021 11:27 AM',
link: '/hoa-ra-jennie-tung-co-thoi-mac-dep-den-muc-antifan-cung-phai-cam-nin-khong-che-duoc-diem-nao',
component: 'page_20210616112719'},
{title: "Dàn sao đổ bộ lễ trao giải Bạch Ngọc Lan: Cúc Tịnh Y đẹp như tiên tử, đè bẹp Nghê Ni - Quan Hiểu Đồng khoe vòng 1 hết cỡ",
description: "Sự kiện Bạch Ngọc Lan tổ chức ngày 10/6 quy tụ dàn sao cực hot như Nghê Ni, Cúc Tịnh Y, Quan Hiểu Đồng, Nhậm Mẫn,...",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/10/photo-1-1623318837570830234296.jpg',
alt: "Cuc Tinh Y, Nghe Ni, Nham Man, Quan Hieu Dong, Tran Triet Vien, Sao Hoa Ngu, My Nhan Hoa Ngu, Ton Thien, Dam Tung Van, Tran Ngoc Ky, Dong Tu Kien, ",
category: 'stars',
time: '01:17 PM',
date: '10/06/2021',
timestamp: '10/06/2021 01:17 PM',
link: '/dan-sao-do-bo-le-trao-giai-bach-ngoc-lan-cuc-tinh-y-dep-nhu-tien-tu-de-bep-nghe-ni-quan-hieu-dong-khoe-vong-1-het-co',
component: 'page_20210610131755'},
]
