import React from "react";

import Pages202106 from "storages/content/2021/06/routePages";
import Pages202107 from "storages/content/2021/07/routePages";
import Pages202108 from "storages/content/2021/08/routePages";
import Pages202109 from "storages/content/2021/09/routePages";
import Pages202110 from "storages/content/2021/10/routePages";
import Pages202111 from "storages/content/2021/11/routePages";
import Pages202112 from "storages/content/2021/12/routePages";

export default function Pages2021() {
  return (
    <>
      <Pages202106 />
      <Pages202107 />
      <Pages202108 />
      <Pages202109 />
      <Pages202110 />
      <Pages202111 />
      <Pages202112 />
    </>
  );
}
