export const data_news=[{title: "Người đàn ông nhặt được loại \"thần dược\" Tần Thủy Hoàng cất công tìm kiếm, quan chức địa phương lập tức đến nhà",
description: "Được mệnh danh là \"thịt cây\", loại dược thảo này được săn lùng với giá cao ngất ngưởng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/17/photo-1-16291914182371902590336.jpg',
alt: "vật thể lạ,trường sinh bất lão,",
category: 'news',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/nguoi-dan-ong-nhat-duoc-loai-than-duoc-tan-thuy-hoang-cat-cong-tim-kiem-quan-chuc-dia-phuong-lap-tuc-den-nha',
component: 'page_20210828085054'},
{title: "Bắt được “quái vật” không đầu không đuôi, ông lão bất ngờ vì lời chuyên gia: Đừng động tới, đó là bảo vật Tần Thủy Hoàng tìm kiếm!",
description: "“Quái vật” mà lão Dương bắt được có gì đáng sợ đến thế? Tại sao lại liên quan đến Tần Thủy Hoàng?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-16301428360372019310079.jpg',
alt: "Tần Thủy Hoàng,Sông Dương Tử,dây ruy băng,người hàng xóm,phát hiện ra,thời cổ đại,gốc tự do,tế bào gốc,phòng chống ung thư,",
category: 'news',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/bat-duoc-quai-vat-khong-dau-khong-duoi-ong-lao-bat-ngo-vi-loi-chuyen-gia-dung-dong-toi-do-la-bao-vat-tan-thuy-hoang-tim-kiem',
component: 'page_20210828085039'},
{title: "Thêm mì khô vị bò gà của Công ty Thiên Hương bị thu hồi ở Na Uy",
description: "Bộ Y tế vừa nhận được cảnh báo của Na Uy về sản phẩm mì khô vị bò gà của Công ty Thiên Hương bị thu hồi do có chứa chất ethylene oxide.",
src: 'https://afamilycdn.com/150157425591193600/2021/8/28/mi-an-lien-2-1630162263085167513131.jpg',
alt: "mì ăn liền,an toàn thực phẩm,tin nóng xã hội,",
category: 'news',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/them-mi-kho-vi-bo-ga-cua-cong-ty-thien-huong-bi-thu-hoi-o-na-uy',
component: 'page_20210828085030'},
{title: "Bộ Công thương xác minh thông tin mì Hảo Hảo bị Ireland thu hồi vì chứa chất cấm",
description: "Bộ Công thương cho hay, đang khẩn trương xác minh thông tin liên quan đến cảnh báo của Cơ quan An toàn thực phẩm Ireland về sản phẩm mì Hảo Hảo và miến Good của Acecook.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-16301136604021718582260.jpeg',
alt: "Bộ Công Thương,an toàn thực phẩm,mì ăn liền,Acecook Việt Nam,",
category: 'news',
time: '08:49 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:49 AM',
link: '/bo-cong-thuong-xac-minh-thong-tin-mi-hao-hao-bi-ireland-thu-hoi-vi-chua-chat-cam',
component: 'page_20210828084908'},
{title: "Mì Hảo Hảo bị Ireland thu hồi vì chứa chất có thể gây ung thư, Acecook Việt Nam nói gì?",
description: "Theo Cơ quan An toàn Thực phẩm Ireland, việc tiêu thụ các sản phẩm nhiễm Ethylene Oxide tuy không gây nguy hiểm cấp tính cho sức khỏe nhưng có thể gây ung thư nếu thường xuyên sử dụng trong thời gian dài. Do đó, người tiêu dùng cần hạn chế việc tiếp xúc với chất này.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/27/photo-1-16300763210751304648625.png',
alt: "tin nóng xã hội,Mì Hảo Hảo bị Ireland thu hồi,Mì Hảo Hảo chứa chất độc hại,",
category: 'news',
time: '08:48 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:48 AM',
link: '/mi-hao-hao-bi-ireland-thu-hoi-vi-chua-chat-co-the-gay-ung-thu-acecook-viet-nam-noi-gi',
component: 'page_20210828084852'},
{title: "Bức tranh ma quái “càng xem càng rợn” trong Bảo tàng Quốc gia Trung Quốc: Phóng to 3 lần hậu thế mới hiểu lý do!",
description: "Nhiều khách tham quan thú nhận họ cảm thấy rợn người khi nhìn thẳng vào bức họa “Nhòm cửa thấy quỷ” trong Bảo tàng Quốc gia Trung Quốc.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/24/photo-1-16297990658001810637179.png',
alt: "Quốc gia Trung Quốc,khách tham quan,Quảng trường Thiên An Môn,Thiên An Môn,tác phẩm nghệ thuật,Lịch sử Trung Quốc,Viện bảo tàng,thiếu nữ xinh đẹp,ô cửa sổ,người đàn ông,liêu trai chí dị,độc giả Việt Nam,thói hư tật xấu,bồ tùng linh,cô gái xinh đẹp,",
category: 'news',
time: '08:41 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:41 AM',
link: '/buc-tranh-ma-quai-cang-xem-cang-ron-trong-bao-tang-quoc-gia-trung-quoc-phong-to-3-lan-hau-the-moi-hieu-ly-do',
component: 'page_20210828084129'},
{title: "Ai là người đã cấm các Eternals không được giúp Avengers đánh bại Thanos?",
description: "Khi đến Trái Đất từ 7000 năm trước, Eternals đã được \"bề trên\" ra lệnh không được can thiệp vào những mâu thuẫn của nhân loại, trừ khi những mâu thuẫn đó có liên quan đến Deviants. Vậy ai đủ khả năng khiến cho các Eternal hùng mạnh phải nghe lời răm rắp như vậy?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/23/photo-1-16297279484901831118499.jpg',
alt: "ETERNALS,THANOS,AVENGERS: INFINITY WAR,AVENGERS: ENDGAME,DEVIANTS,MARVEL,",
category: 'news',
time: '08:38 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:38 AM',
link: '/ai-la-nguoi-da-cam-cac-eternals-khong-duoc-giup-avengers-danh-bai-thanos',
component: 'page_20210828083854'},
{title: "15 sự thật lịch sử thú vị và kỳ quặc tới mức bạn sẽ không tin rằng nó đã xảy ra",
description: "Đúng là người thật, việc thật bao giờ nghe cũng hấp dẫn hơn sách vở nhỉ!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/22/4-1629640004227509014396.jpg',
alt: "súng thần công,Hải quân Hoa Kỳ,Tây Ban Nha,Đô đốc hải quân,tai nạn hy hữu,lịch sử,relax,thú vị,",
category: 'news',
time: '08:33 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:33 AM',
link: '/15-su-that-lich-su-thu-vi-va-ky-quac-toi-muc-ban-se-khong-tin-rang-no-da-xay-ra',
component: 'page_20210828083352'},
{title: "Cận cảnh bếp Hoàng Cầm của bộ đội Việt Nam: Há hốc mồm vì độ sáng tạo, hiệu quả cực kỳ đáng kinh ngạc",
description: "Chắc hẳn chúng ta đã từng nhiều lần nghe nhắc tới bếp Hoàng Cầm cùng công dụng \"kỳ diệu\" của nó. Thế nhưng đã bao giờ bạn tận mắt chứng kiến sự hoạt động của công cụ đặc biệt này chưa?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/22/1-16296246911821388079108.jpeg',
alt: "bếp Hoàng Câm,bộ đội,nấu bằng bếp Hoàng Cầm,bộ đội Việt Nam,Bộ đội căng mình giúp dân chống dịch,",
category: 'news',
time: '08:33 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:33 AM',
link: '/can-canh-bep-hoang-cam-cua-bo-doi-viet-nam-ha-hoc-mom-vi-do-sang-tao-hieu-qua-cuc-ky-dang-kinh-ngac',
component: 'page_20210828083339'},
{title: "99 ngôi mộ của mỹ nhân trong lăng Tần Thủy Hoàng hé lộ sự thật kinh hoàng về tục tuẫn táng người sống",
description: "Tần Thủy Hoàng đương nhiên là người có trí tuệ anh minh nhưng cũng là người 10 phần mê tín. Ông sinh tiền đã đưa tất cả những mỹ nữ trong thiên hạ về lấp đầy hậu cung. Nhưng sau khi Tần Thủy Hoàng qua đời, những người này toàn bộ cũng biến mất. Sử sách ghi lại bọn họ đều bị tuẫn táng, sự thật là thế nào?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/20/-1629453055644601345863.jpg',
alt: "Tần Thủy Hoàng,Lăng mộ Tần Thủy Hoàng,tài liệu lịch sử,mộ tập thể,",
category: 'news',
time: '11:06 AM',
date: '21/08/2021',
timestamp: '21/08/2021 11:06 AM',
link: '/99-ngoi-mo-cua-my-nhan-trong-lang-tan-thuy-hoang-he-lo-su-that-kinh-hoang-ve-tuc-tuan-tang-nguoi-song',
component: 'page_20210821110607'},
{title: "6 sai lệch so với nguyên tác của Harry Potter: Cú twist ngoạn mục lại bị cắt bỏ, mối tình của Harry vốn dĩ \"nồng thắm\" hơn nhiều!",
description: "Nội dung Harry Potter thiếu sót như thế nào khi chuyển thể từ truyện lên phim?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/17/harrypotterwbf7harryandginnyabouttokissstill080615land-1629217062975667448956.jpg',
alt: "phim Harry Potter,harry potter and the sorcerer's stone (2001),Harry Potter và Hòn đá Phù thủy,Tiểu thuyết Harry Potter,Harry Potter và Chiếc cốc lửa,sao harry potter,phim Âu Mỹ,Phim chuyển thể,Phim chuyển thể từ tiểu thuyết,harry potter,",
category: 'news',
time: '09:22 AM',
date: '20/08/2021',
timestamp: '20/08/2021 09:22 AM',
link: '/6-sai-lech-so-voi-nguyen-tac-cua-harry-potter-cu-twist-ngoan-muc-lai-bi-cat-bo-moi-tinh-cua-harry-von-di-nong-tham-hon-nhieu',
component: 'page_20210820092237'},
{title: "Bộ sưu tập hình ảnh giúp bạn tiếp thu được những kiến thức kỳ quái chả ai biét",
description: "Không phải thứ gì quá phi thường nhưng các hình ảnh này đem lại rất nhiều sự thú vị.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/19/photo-1-16293792348581858519105.jpg',
alt: "HÌNH ẢNH,MÃN NHÃN,",
category: 'news',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/bo-suu-tap-hinh-anh-giup-ban-tiep-thu-duoc-nhung-kien-thuc-ky-quai-cha-ai-biet',
component: 'page_20210819130439'},
{title: "Cơ sở khoa học đằng sau Suicide Squad: Sao biển sở hữu toàn \"skill\" đỉnh thế này, bảo sao phản diện Starro lại bá đạo đến thế!",
description: "Sao biển có thị giác rất nhạy bén, sở hữu khả năng tái sinh và nhân bản ấn tượng và là một kẻ săn mồi chuyên nghiệp - tất cả đều là cảm hứng để tạo nên một quái thú Starro đáng sợ trong bom tấn The Suicide Squad.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/18/photo-1-16292938268231774715637.jpg',
alt: "SAO BIỂN,JAMES GUNN,SUICIDE SQUAD,DC,THE SUICIDE SQUAD,SIÊU ANH HÙNG,",
category: 'news',
time: '01:03 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:03 PM',
link: '/co-so-khoa-hoc-dang-sau-suicide-squad-sao-bien-so-huu-toan-skill-dinh-the-nay-bao-sao-phan-dien-starro-lai-ba-dao-den-the',
component: 'page_20210819130320'},
{title: "Được nhân viên McDonald’s cưu mang thuở nghèo đói, Cristiano Ronaldo tìm lại và trả ơn họ khi đã thành công",
description: "\"Cristiano Ronaldo là người tuyệt vời khi không quên những điều nhỏ bé trong quá khứ. Tôi vốn không tin vào nhân quả nhưng bây giờ tôi bắt đầu tin rằng nó có thật\", người phụ nữ chia sẻ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/19/photo-1-1629360509016983153142.png',
alt: "cristiano ronaldo,nhân viên McDonald’s,ronaldo,",
category: 'news',
time: '01:03 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:03 PM',
link: '/duoc-nhan-vien-mcdonalds-cuu-mang-thuo-ngheo-doi-cristiano-ronaldo-tim-lai-va-tra-on-ho-khi-da-thanh-cong',
component: 'page_20210819130303'},
{title: "7 phản diện của vũ trụ điện ảnh Marvel hóa ra lại là \"anh hùng\" trong các bộ phim khác",
description: "Những diễn viên này có thể là phản diện trong MCU, nhưng ở phim khác họ lại là anh hùng.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/17/photo-1-1629206055332634247423.jpg',
alt: "PHẢN DIỆN MCU,VŨ TRỤ ĐIỆN ẢNH MARVEL,ÁC NHÂN MARVEL,SIÊU ANH HÙNG,PHIM HAY,DIỄN VIÊN,ANH HÙNG TRONG PHIM,",
category: 'news',
time: '01:01 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:01 PM',
link: '/7-phan-dien-cua-vu-tru-dien-anh-marvel-hoa-ra-lai-la-anh-hung-trong-cac-bo-phim-khac',
component: 'page_20210819130127'},
{title: "Những điều không phải ai cũng biết về chiến binh Valkyrie trong thần thoại Bắc Âu",
description: "Trong thần thoại Bắc Âu, các nữ chiến binh Valkyrie có lẽ là những nhân vật được biết đến nhiều nhất",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/16/valkyries-shadows-1629101903187774463403.jpg',
alt: "VALKYRIE,KHÁM PHÁ,THẦN THOẠI BẮC ÂU,",
category: 'news',
time: '12:55 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:55 PM',
link: '/nhung-dieu-khong-phai-ai-cung-biet-ve-chien-binh-valkyrie-trong-than-thoai-bac-au',
component: 'page_20210819125554'},
{title: "Sự thật rùng rợn về 100 đứa trẻ nằm trong lăng mộ của Từ Hi Thái Hậu, hé lộ tội ác gây phẫn nộ",
description: "Từ Hi Thái Hậu lúc sinh thời lo sợ bản thân gặp báo ứng vì những tội ác mà mình gây ra nên đã có quyết định độc ác khiến người đời căm phẫn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/13/photo-1-16288431430061464206998.jpg',
alt: "Từ Hi thái hậu,người phụ nữ quyền lực,phụ nữ quyền lực,triều đại nhà Thanh,Tần Thủy Hoàng,trường sinh bất lão,lao động khổ sai,",
category: 'news',
time: '10:39 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:39 PM',
link: '/su-that-rung-ron-ve-100-dua-tre-nam-trong-lang-mo-cua-tu-hi-thai-hau-he-lo-toi-ac-gay-phan-no',
component: 'page_20210813223932'},
{title: "Người thổi sáo thành Hamelin – Câu chuyện rùng rợn về vụ mất tích của 130 đứa trẻ",
description: "Người thổi sáo thành Hamelin là câu chuyện cổ tích về cái giá phải trả cho sự vô ơn. Tuy nhiên, dường như rất ít người biết rằng câu chuyện trên có ẩn chứa những sự kiện có thật.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/12/oldest-known-picture-of-the-pied-piper-16287570134881806818927.jpg',
alt: "KHÁM PHÁ,CỔ TÍCH,NGƯỜI THỔI SÁO,",
category: 'news',
time: '10:38 PM',
date: '13/08/2021',
timestamp: '13/08/2021 10:38 PM',
link: '/nguoi-thoi-sao-thanh-hamelin-cau-chuyen-rung-ron-ve-vu-mat-tich-cua-130-dua-tre',
component: 'page_20210813223802'},
{title: "Thiếu niên thức trắng 11 đêm để \"phục vụ khoa học\", và đây là những gì đã xảy ra",
description: "Năm 1963, có 2 cậu thiếu niên quyết định bắt tay vào làm một dự án khoa học, và cuối cùng chẳng ngờ ghi danh vào Kỷ lục Guinness thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-16288545350811022662061.jpeg',
alt: "KHOA HỌC,",
category: 'news',
time: '09:29 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:29 AM',
link: '/thieu-nien-thuc-trang-11-dem-de-phuc-vu-khoa-hoc-va-day-la-nhung-gi-da-xay-ra',
component: 'page_20210813092956'},
{title: "Những nhân vật đã từng phá vỡ được chiếc khiên của Captain America là ai?",
description: "Bất chấp sức mạnh đáng kinh ngạc đến từ chiếc khiên của Captain America, một số người vẫn có thể phá vỡ nó. Dưới đây là những nhân vật mạnh nhất đã từng làm được điều đó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/13/photo-1-1628822349591989798565.jpg',
alt: "AVENGERS: ENDGAME,INFINITY GAUNTLET,CAPTAIN AMERICA,KHIÊN CỦA CAPTAIN AMERICA,KHIÊN CỦA CAPTAIN AMERICA BỊ VỠ,THANOS,",
category: 'news',
time: '09:28 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:28 AM',
link: '/nhung-nhan-vat-da-tung-pha-vo-duoc-chiec-khien-cua-captain-america-la-ai',
component: 'page_20210813092806'},
{title: "Kết cục của Tứ đại mỹ nhân: Chiêu Quân làm vợ 3 đời cha con, Điêu Thuyền bị Quan Vũ chê chẳng còn trong sạch, Tây Thi lại càng thảm",
description: "Có lẽ câu \"hồng nhan bạc phận\" đều ứng với số phận của Tứ đại mỹ nhân Trung Quốc cổ đại.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/24/-16245354592749449386.jpg',
alt: "tứ đại mỹ nhân,hồng nhan bạc phận,Tam Quốc Diễn Nghĩa,",
category: 'news',
time: '09:27 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:27 AM',
link: '/ket-cuc-cua-tu-dai-my-nhan-chieu-quan-lam-vo-3-doi-cha-con-dieu-thuyen-bi-quan-vu-che-chang-con-trong-sach-tay-thi-lai-cang-tham',
component: 'page_20210813092743'},
{title: "Đát Kỷ không phải \"hồ ly tinh\"! Viện nghiên cứu Trung Quốc khôi phục lại dung mạo của nàng với hình tượng nữ tướng",
description: "Đát Kỷ vẫn thường được người đời nhắc đến với hình ảnh của một hồ yêu mê hoặc Trụ Vương khiến nhà Thương diệt vong. Nàng trong lịch sử thực chất là người thế nào?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/12/dat-ky-16287337036721111533094.jpg',
alt: "Hồ ly tinh,Di tích lịch sử,Đát Kỷ,Lịch sử Trung Quốc,",
category: 'news',
time: '09:27 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:27 AM',
link: '/dat-ky-khong-phai-ho-ly-tinh-vien-nghien-cuu-trung-quoc-khoi-phuc-lai-dung-mao-cua-nang-voi-hinh-tuong-nu-tuong',
component: 'page_20210813092730'},
{title: "Những sự thật đen tối đằng sau câu chuyện Bạch Tuyết và bảy chú lùn",
description: "Câu chuyện cổ tích \"Nàng Bạch Tuyết và bảy chú lùn\" có thể là sản phẩm của rất nhiều câu chuyện ghép lại. Không ít trong số đó vô cùng đen tối.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/snow-white2-1628671589734535858386.jpg',
alt: "TRUYỀN THUYẾT,TRUYỆN CỔ TÍCH,NÀNG BẠCH TUYẾT VÀ BẢY CHÚ LÙN,",
category: 'news',
time: '09:21 AM',
date: '13/08/2021',
timestamp: '13/08/2021 09:21 AM',
link: '/nhung-su-that-den-toi-dang-sau-cau-chuyen-bach-tuyet-va-bay-chu-lun',
component: 'page_20210813092140'},
{title: "10 nhân vật MCU được xây dựng \"rất khác\" so với phiên bản truyện tranh",
description: "Không chỉ có một ngoại hình khác, mà những nhân vật này còn có một câu chuyện khác giữa phiên bản truyện tranh và MCU.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-16287766964011376795581.jpg',
alt: "MCU,COMIC SIÊU ANH HÙNG,MARVEL,NHÂN VẬT MARVEL BỊ THAY ĐỔI,ALDRICH KILLIAN,MORDO,",
category: 'news',
time: '05:33 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:33 PM',
link: '/10-nhan-vat-mcu-duoc-xay-dung-rat-khac-so-voi-phien-ban-truyen-tranh',
component: 'page_20210812173319'},
{title: "TOP 4 nhân vật có thể \"bán hành\" cho Đông Phương Bất Bại: Top 1 \"ngồi chơi xơi nước\" vẫn \"ez win\"",
description: "Không phải đánh hòa, mà là đánh bại.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/12/photo-1-16287608828061340195011.jpg',
alt: "TÂN MINH CHỦ,GAME MOBILE,GAME DI ĐỘNG,GAME ONLINE,KIM DUNG,GAME CHIẾN THUẬT,ĐÔNG PHƯƠNG BẤT BẠI,",
category: 'news',
time: '05:32 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:32 PM',
link: '/top-4-nhan-vat-co-the-ban-hanh-cho-dong-phuong-bat-bai-top-1-ngoi-choi-xoi-nuoc-van-ez-win',
component: 'page_20210812173230'},
{title: "Liều thuốc \"thần tiên\" khiến trái tim chẳng thể ngủ yên và câu chuyện phía sau những bộ phim ngôn tình \"ngọt sún răng\" của màn ảnh Hoa ngữ",
description: "Những năm gần đây, nhà sản xuất phim Trung Quốc ưa thích đem các tác phẩm ngôn tình \"ngọt sâu răng\" lên phim. Dù nội dung đơn giản, không có tình tiết tréo ngoe hay ân oán tình thù rối não, thế nhưng thể loại phim này vẫn thu hút sự quan tâm của đông đảo khán giả.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/12-16287686339931475075859.jpg',
alt: "Trung Quốc,phim ngôn tình Trung Quốc,sức khỏe tinh thần,phim chuyển thể ngôn tình,Phim tình cảm,giải tỏa stress,phim ngôn tình,phim ngôn tình Hoa ngữ,phim ngôn tình chuyển thể,ngọt sún răng,liều thuốc cho trái tim,",
category: 'news',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/phia-sau-nhung-bo-phim-ngon-tinh-ngot-sun-rang-cua-man-anh-hoa-ngu-lieu-thuoc-than-tien-khien-trai-tim-chang-the-ngu-yen',
component: 'page_20210812173141'},
{title: "Phim tài liệu rùng rợn về UFO vừa ra mắt đã leo top: Hàng loạt bằng chứng người ngoài hành tinh có thật, \"lật tẩy\" âm mưu che đậy trong quá khứ?",
description: "Chỉ vừa mới ra mắt, phim tài liệu về người ngoài hành tinh và UFO của Netflix đã nhanh chóng leo top thịnh hành thế giới.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/screen-shot-2021-08-12-at-153253-16287574352901294696728.png',
alt: "UFO,UFO & người ngoài hành tinh,phim tài liệu,thông tin về UFO,Người ngoài hành tinh,phim Âu Mỹ,Kỳ án rúng động thế giới lên phim,Top Secret UFO Projects: Declassified (2021),",
category: 'news',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/phim-tai-lieu-rung-ron-ve-ufo-vua-ra-mat-da-leo-top-hang-loat-bang-chung-nguoi-ngoai-hanh-tinh-co-that-lat-tay-am-muu-che-day-trong-qua-khu',
component: 'page_20210812173132'},
{title: "Đừng để phim ảnh đánh lừa: Phó Hằng có thật nhưng chỉ yêu một người con gái không phải Ngụy Anh Lạc, hạ sinh 6 đứa con",
description: "Cuộc đời thực sự của Phú Sát Phó Hằng - con trai dòng họ Phú Sát lừng lẫy thời Thanh thực sự là thế nào? Liệu có liên quan gì tới chuyện tình cùng Ngụy Anh Lạc giống như trong \"Diên Hy công lược\"?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/10-153250281195864899936-15330571393871248739778-16286556478321068590289.jpg',
alt: "phim cổ trang,Diên Hi Công Lược (2018),Phó Sát Phú Hằng,",
category: 'news',
time: '10:59 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:59 AM',
link: '/dung-de-phim-anh-danh-lua-phu-hang-co-that-nhung-chi-yeu-mot-nguoi-con-gai-khong-phai-nguy-anh-lac-ha-sinh-6-dua-con',
component: 'page_20210811105938'},
{title: "\"Bắt lỗi\" những hạt sạn rõ rành rành đầy khó hiểu trong phim Harry Potter",
description: "Dù là một bộ phim bom tấn của Hollywood và được làm trong nhiều năm, thế nhưng sai sót của Harry Potter là rất nhiều và rất dễ bị phát hiện ra.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-16286523770431525969444.png',
alt: "HARRY POTTER,",
category: 'news',
time: '10:58 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:58 AM',
link: '/bat-loi-nhung-hat-san-ro-ranh-ranh-day-kho-hieu-trong-phim-harry-potter',
component: 'page_20210811105812'},
{title: "Những chi tiết thú vị trong Black Widow đã hé lộ thông tin quan trọng về các mối quan hệ thuộc MCU",
description: "Bom tấn Black Widow của nhà Marvel đã cung cấp nhiều thông tin quan trọng mà các fan tò mò từ lâu.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/11/photo-1-1628646184462213925237.jpg',
alt: "MARVEL,BLACK WIDOW,MCU,DỊ NHÂN,EASTER EGG BLACK WIDOW,",
category: 'news',
time: '10:58 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:58 AM',
link: '/nhung-chi-tiet-thu-vi-trong-black-widow-da-he-lo-thong-tin-quan-trong-ve-cac-moi-quan-he-thuoc-mcu',
component: 'page_20210811105801'},
{title: "Số phận các thuyền viên trên tàu Mary Celeste, bí ẩn hơn 135 năm chưa có lời giải",
description: "Cho đến nay, vụ án các thuyền viên tàu Mary Celeste mất tích vấn là bí ẩn lớn của nhân loại",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/the-missing-crew-of-the-mary-celeste-16285962245971287547253.jpg',
alt: "CON TÀU MA,KHÁM PHÁ,RÙNG RỢN,MARY CELESTE,",
category: 'news',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/so-phan-cac-thuyen-vien-tren-tau-mary-celeste-bi-an-hon-135-nam-chua-co-loi-giai',
component: 'page_20210811105746'},
{title: "Muốn học theo thành công của MCU nhưng 6 vũ trụ điện ảnh này đã thất bại thảm hại",
description: "Không phải hãng phim nào cũng thành công trong việc xây dựng vũ trụ điện ảnh.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/10/photo-1-16285886879362063622559.jpg',
alt: "VŨ TRỤ ĐIỆN ẢNH,PHIM ĐIỆN ẢNH,X-MEN,DCEU,THE AMAZING SPIDER-MAN,MEN IN BLACK,",
category: 'news',
time: '10:57 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:57 AM',
link: '/muon-hoc-theo-thanh-cong-cua-mcu-day-la-6-vu-tru-dien-anh-da-that-bai-tham-hai',
component: 'page_20210811105720'},
{title: "Những xà thần nổi tiếng trong thần thoại: Hydra còn xếp sau Tương Liễu Thị của Trung Hoa",
description: "Hydra trong thần thoại Hy Lạp và Tương Liễu Thị trong thần thoại Trung Hoa, xà thần nào đáng sợ hơn?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/quainhan-10-1628517629913322917197.jpg',
alt: "THẦN THOẠI HY LẠP,THẦN THOẠI TRUNG HOA,HYDRA,TƯƠNG LIỄU THỊ,",
category: 'news',
time: '10:56 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:56 AM',
link: '/nhung-xa-than-noi-tieng-trong-than-thoai-hydra-con-xep-sau-tuong-lieu-thi-cua-trung-hoa',
component: 'page_20210811105619'},
{title: "Mỹ: Người dân Greenville tuyệt vọng trong thị trấn bị cháy rừng xóa sổ",
description: "Nước Mỹ đang chứng kiến đợt cháy rừng khủng khiếp với tổng số gần 40.000 đám cháy, nhiều hơn mức trung bình hàng năm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/photo-1-1628663110255394090395.jpg',
alt: "thế giới,Greenville,cháy rừng Mỹ,tin nóng xã hội,",
category: 'news',
time: '10:55 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:55 AM',
link: '/my-nguoi-dan-greenville-tuyet-vong-trong-thi-tran-bi-chay-rung-xoa-so',
component: 'page_20210811105511'},
{title: "Câu cửa miệng giúp founder TikTok khởi nghiệp thành công chỉ sau 6 năm ra trường: \"Trì hoãn sự thoả mãn lại ngay!\"",
description: "Bạn đã nghe đến thuật ngữ \"trì hoãn thỏa mãn\" bao giờ chưa? Càng biết chờ đợi, thành quả thu được sẽ càng lớn. Đây chính là biết nhịn cái lợi nhỏ để có thể thu được cái lợi lớn hơn. Để làm được, yêu cầu phải kiểm soát tốt được bản thân.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/11/photo-2-16286533700861518086712.jpg',
alt: "công ty sở hữu,người giàu nhất thế giới,tốt nghiệp đại học,công ty khởi nghiệp,",
category: 'news',
time: '10:54 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:54 AM',
link: '/cau-cua-mieng-giup-founder-tiktok-khoi-nghiep-thanh-cong-chi-sau-6-nam-ra-truong-tri-hoan-su-thoa-man-lai-ngay',
component: 'page_20210811105457'},
{title: "Hai anh em đem tranh Càn Long vẽ bán cho chuyên gia kiểm định, 1 năm sau mới \"điếng người\": Báo cảnh sát cũng không kịp nữa rồi!",
description: "Chứng kiến hành động của vị chuyên gia sau đó, hai anh em nhà họ Chu mới vội vàng đi báo cảnh sát nhưng cũng không kịp nữa rồi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285829258491147498617.jpg',
alt: "Đài truyền hình Trung Quốc,Hoàng đế Càn Long,",
category: 'news',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/hai-anh-em-dem-tranh-can-long-ve-ban-cho-chuyen-gia-kiem-dinh-1-nam-sau-moi-dieng-nguoi-bao-canh-sat-cung-khong-kip-nua-roi',
component: 'page_20210811105109'},
{title: "Shark Hưng học 1 đại học dễ quá nên học luôn 2 - 3 trường, 4 lần tốt nghiệp Thạc sĩ, còn gây sốt khi lọt top thế giới về mảng này",
description: "Đằng sau sự tinh thông kiến thức của Shark Hưng là cả quá trình đi học miệt mài cả vị Phó chủ tịch.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285906520021581419114.png',
alt: "chương trình thực tế,đại học quốc gia,shark tank,shark hưng,",
category: 'news',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/shark-hung-hoc-1-dai-hoc-de-qua-nen-hoc-luon-2-3-truong-4-lan-tot-nghiep-thac-si-con-gay-sot-khi-lot-top-the-gioi-ve-mang-nay',
component: 'page_20210811105041'},
{title: "10 bộ phim bom tấn đã được phát hành trong năm 2020 và 2021, The Suicide Squad đang khiến netizen \"bấn loạn\"",
description: "Mặc dù được phát hành trong tình hình đại dịch căng thẳng, những bộ phim này vẫn gặt hái được thành công nhất định.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/9/photo-1-1628477882307314683247.jpg',
alt: "BAD BOYS FOR LIFE,PHIM CHIẾU RẠP,PHIM ĐIỆN ẢNH,PHIM BOM TẤN 2021,TOP PHIM,BIRDS OF PREY,THE SUICIDE SQUAD,FAST 9,",
category: 'news',
time: '10:56 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:56 AM',
link: '/10-bo-phim-bom-tan-da-duoc-phat-hanh-trong-nam-2020-va-2021-the-suicide-squad-dang-khien-netizen-ban-loan',
component: 'page_20210809105618'},
{title: "3 hiểu lầm \"ăn sâu bám rễ\" trong tâm trí hội mê phim cổ trang Trung Quốc, đọc ngay để mở mang kiến thức",
description: "Có rất nhiều kiến thức về văn hoá, lịch sử được cài cắm trong những bộ phim cổ trang Trung Quốc. Tuy nhiên, có những chi tiết về văn hoá xưa trong phim chưa sát với thực tế và khiến cho khán giả xem phim hiểu lầm về sự thật lịch sử.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/3/21-1627962547508685262938.jpeg',
alt: "Trung Quốc,thời nhà thanh,triều đại nhà Thanh,Vua Càn Long,công chúa,Hoàn Châu cách cách,Lịch sử Trung Quốc,phim cổ trang Hoa Ngữ,phim cổ trang trung quốc,sự thật,sự thật thú vị,thâm cung bí sử,thâm cung bí sử Trung Quốc,có thể bạn chưa biết,phim cổ trang,",
category: 'news',
time: '10:55 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:55 AM',
link: '/nhung-hieu-lam-an-sau-bam-re-trong-tam-tri-cac-mot-phim-co-trang-trung-quoc-doc-ngay-de-mo-mang-kien-thuc',
component: 'page_20210809105506'},
{title: "Hoá ra con hàu được người ta nuôi như thế này, \"đột nhập\" vào làng bè ở Vũng Tàu mới được mở mang tầm mắt",
description: "Ăn đó giờ nhưng chắc nhiều người chưa biết hàu được nuôi như thế nào.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/9/anh-chup-man-hinh-2021-08-09-luc-190442-1628510926073641453141.png',
alt: "dinh dưỡng cao,Cư dân mạng,Động vật thân mềm,hàu,con hàu,nuôi hàu,cách nuôi hàu,đánh bắt hàu,phương pháp nuôi hàu,",
category: 'news',
time: '10:54 AM',
date: '09/08/2021',
timestamp: '09/08/2021 10:54 AM',
link: '/hoa-ra-con-hau-duoc-nguoi-ta-nuoi-nhu-the-nay-dot-nhap-vao-lang-be-o-vung-tau-moi-duoc-mo-mang-tam-mat',
component: 'page_20210809105435'},
{title: "12 chi tiết \"siêu ẩn\" trong bom tấn Gia Đình Siêu Nhân của Pixar cho fan cứng: Một cảnh quay còn được Avengers bắt chước!",
description: "Siêu phẩm hoạt hình The Incredibles (Gia Đình Siêu Nhân) khéo léo cài cắm loạt chi tiết ẩn khiến người hâm mộ khó có thể nhận ra nếu chỉ xem một lần.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/9/photo-14-1628475328550605475751.jpg',
alt: "GIA ĐÌNH SIÊU NHÂN,THE INCREDIBLES,PIXAR,PHIM HOẠT HÌNH,",
category: 'news',
time: '10:22 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:22 PM',
link: '/12-chi-tiet-sieu-an-trong-bom-tan-gia-dinh-sieu-nhan-cua-pixar-cho-fan-cung-mot-canh-quay-con-duoc-avengers-bat-chuoc',
component: 'page_20210808222213'},
{title: "Iron Man chính thức trở lại dù đã chết trong Endgame, hướng giải quyết của Marvel khiến ai cũng phải thán phục",
description: "Dù Iron Man đã hy sinh trong Avengers: Endgame, Marvel đã tìm cách để chính thức đưa nhân vật này quay trở lại.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/9/photo-1-16284760850321848111665.jpg',
alt: "TONY STARK,IRON MAN,AVENGERS: ENDGAME,MCU,WHAT IF...?,LOKI,ĐA VŨ TRỤ,",
category: 'news',
time: '10:22 PM',
date: '08/08/2021',
timestamp: '08/08/2021 10:22 PM',
link: '/iron-man-chinh-thuc-tro-lai-du-da-chet-trong-endgame-huong-giai-quyet-cua-marvel-khien-ai-cung-phai-than-phuc',
component: 'page_20210808222202'},
{title: "Những hình phạt tàn độc do Võ Tắc Thiên nghĩ ra, \"Ngọc nữ leo thang\" trở thành cơn ác mộng của bất cứ ai",
description: "Những hình phạt do Võ Tắc Thiên sáng tạo ra đến cùng là ngoan độc đến nhường nào mới có thể khiến vạn người thuần phục như vậy?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/8/20150204-020837-7041547560823743520x782-16283911549241546755802.jpg',
alt: "Võ Tắc Thiên,Lịch sử Trung Hoa,hành hình,",
category: 'news',
time: '03:21 PM',
date: '08/08/2021',
timestamp: '08/08/2021 03:21 PM',
link: '/nhung-hinh-phat-tan-doc-do-vo-tac-thien-nghi-ra-ngoc-nu-leo-thang-tro-thanh-con-ac-mong-cua-bat-cu-ai',
component: 'page_20210808152102'},
{title: "Thánh nữ trượt băng Nhật Bản cosplay Điệp Trụ trong Kimetsu no Yaiba",
description: "Bức ảnh của nữ vận động viên trượt băng Marin Honda đã nhận được hàng chục nghìn lượt like của fan hâm mộ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/photo-1-16283400307351407860644.jpg',
alt: "KIMETSU NO YAIBA,COSPLAY,TRƯỢT BĂNG,MARIN HONDA,",
category: 'news',
time: '03:46 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:46 PM',
link: '/thanh-nu-truot-bang-nhat-ban-cosplay-diep-tru-trong-kimetsu-no-yaiba',
component: 'page_20210807154638'},
{title: "Xếp hạng các bộ phim thuộc Fast and Furious saga, vẫn chưa cái tên nào vượt qua được phần 7 huyền thoại",
description: "\"Fast and Furious\" - Quá nhanh quá nguy hiểm là 1 trong những thương hiệu phim đắt khách nhất trên màn ảnh thế giới.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/7/photo-1-16283042338841885128623.jpg',
alt: "FAST AND FURIOUS,HOBBS AND SHAW,THE FAST SAGA,FAST & FURIOUS,THE FAST AND THE FURIOUS,QUÁ NHANH QUÁ NGUY HIỂM,",
category: 'news',
time: '03:45 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:45 PM',
link: '/xep-hang-cac-bo-phim-thuoc-fast-and-furious-saga-van-chua-cai-ten-nao-vuot-qua-duoc-phan-7-huyen-thoai',
component: 'page_20210807154540'},
{title: "Đừng ăn mì tôm trứng xúc xích nữa, đây là cách để làm mì gói trở nên dinh dưỡng hơn",
description: "Ăn mì tôm cùng với trứng và xúc xích chẳng khác nào dội bom lên hệ tim mạch của bạn.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/7/photo-1-1628314839916736337919.png',
alt: "MÌ TÔM,MÌ GÓI,MÌ TÔM TRỨNG XÚC XÍCH,KHOA HỌC,NGHIÊN CỨU,SỨC KHỎE,",
category: 'news',
time: '03:45 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:45 PM',
link: '/dung-an-mi-tom-trung-xuc-xich-nua-day-la-cach-de-lam-mi-goi-tro-nen-dinh-duong-hon',
component: 'page_20210807154530'},
{title: "Đừng tưởng còn trẻ thì không bị bệnh gút tấn công, hãy để ý đến 4 biểu hiện bất thường trên cơ thể để khám chữa kịp thời",
description: "Cách đây 10 đến 20 năm, bệnh gút được coi là bệnh của người trung niên và người già hoặc bệnh của người giàu, nhưng những năm gần đây, căn bệnh này đang có xu hướng tăng nhanh và trẻ hóa một cách chóng mặt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/7/-16283069036381614016449.jpg',
alt: "bệnh gút,thói quen ăn uống,trẻ hóa,",
category: 'news',
time: '03:43 PM',
date: '07/08/2021',
timestamp: '07/08/2021 03:43 PM',
link: '/dung-tuong-con-tre-thi-khong-bi-benh-gut-tan-cong-hay-de-y-den-4-bieu-hien-bat-thuong-tren-co-the-de-kham-chua-kip-thoi',
component: 'page_20210807154327'},
{title: "Tiết lộ sốc: Messi bị Barcelona \"lật kèo\" vào phút chót, cay đắng rời Nou Camp trong nỗi đau",
description: "Có vẻ như chính Messi cũng không thể ngờ được mình lại không thể tiếp tục khoác áo Barcelona.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-1628216103335911731197.jpeg',
alt: "messi,barcelona,Messi bị Barcelona lật kèo,messi rời Barca,",
category: 'news',
time: '11:10 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:10 PM',
link: '/tiet-lo-soc-messi-bi-barcelona-lat-keo-vao-phut-chot-cay-dang-roi-nou-camp-trong-noi-dau',
component: 'page_20210805231056'},
{title: "Cháy rừng kỷ lục hoành hành ở California, phần lớn thị trấn Greenville chỉ còn là đống tro tàn",
description: "Đám cháy rừng Dixie đã \"xé toạc\" thị trấn miền núi Greenville, bang California, Mỹ vào tối 4/8 (theo giờ địa phương), khiến phần lớn thị trấn trở thành đống tro tàn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-16282158059912060667640.jpg',
alt: "cháy rừng kỷ lục tại California,cháy rừng,Mỹ,đống tro tàn,thị trấn greenville,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/chay-rung-ky-luc-hoanh-hanh-o-california-phan-lon-thi-tran-greenville-chi-con-la-dong-tro-tan',
component: 'page_20210805230936'},
{title: "Chiều nay, lý do Messi rời Barca sẽ chính thức được làm sáng tỏ",
description: "Đích thân Chủ tịch Joan Laporta sẽ lý giải nguyên nhân vì sao Barcelona để siêu sao Lionel Messi ra đi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-1-16282219122042139531664.jpg',
alt: "Messi rời Barca,messi,barcelona,barca,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/chieu-nay-ly-do-messi-roi-barca-se-chinh-thuc-duoc-lam-sang-to',
component: 'page_20210805230925'},
{title: "Toàn bộ sự nghiệp vĩ đại của Messi tại Barcelona qua ảnh",
description: "Hãy cùng điểm lại top những khoảnh khắc ấn tượng nhất của Lionel Messi trước khi anh rời Barca.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/photo-14-1628201624024814025663.jpg',
alt: "messi,barcelona,la liga,messi rời Barca,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/toan-bo-su-nghiep-vi-dai-cua-messi-tai-barcelona-qua-anh',
component: 'page_20210805230916'},
{title: "Tuyên bố chia tay Messi chỉ là cái cớ, Barcelona sẵn sàng cho vụ \"lật kèo\" gây sốc?",
description: "Theo tiết lộ của nhà báo Tây Ban Nha - Toni Juanmarti, việc Barcelona tuyên bố chia tay Lionel Messi chỉ là cách để họ gây áp lực lên Ban tổ chức La Liga.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/6/1-16282213093631207320459.jpeg',
alt: "messi,barcelona,Messi rời Barca,",
category: 'news',
time: '11:09 PM',
date: '05/08/2021',
timestamp: '05/08/2021 11:09 PM',
link: '/tuyen-bo-chia-tay-messi-chi-la-cai-co-barcelona-san-sang-cho-vu-lat-keo-gay-soc',
component: 'page_20210805230908'},
{title: "Camazotz – Batman phiên bản độc ác trong thần thoại Mesoamerica",
description: "Thần thoại Mesoamerican kể rằng sâu trong hang động tăm tối, có quái vật dơi Camazotz sinh sống. Nó là một trong 4 sinh vật đem đến sự hủy diệt trong thời kỳ mặt trời đầu tiên.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/camazotz0-1628071372472655698067.jpg',
alt: "KHÁM PHÁ,DƠI MA CÀ RỒNG,SINH VẬT HUYỀN BÍ,THẦN THOẠI,BATMAN,",
category: 'news',
time: '08:49 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:49 AM',
link: '/camazotz-batman-phien-ban-doc-ac-trong-than-thoai-mesoamerica',
component: 'page_20210805084910'},
{title: "Những thử thách tưởng là vớ vẩn nhưng đố bạn làm được hoàn hảo",
description: "Mùa dịch rảnh rỗi thì thử làm những thứ như thế này xem, biết đâu bạn khám phá ra được tài năng của bản thân đó.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/5/photo-1-16281539793231764751337.jpg',
alt: "KỲ TÍCH,VỚ VẨN,",
category: 'news',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/nhung-thu-thach-tuong-la-vo-van-nhung-do-ban-lam-duoc-hoan-hao',
component: 'page_20210805084732'},
{title: "Sẽ ra sao nếu các loài động vật đều có tạo hình khối hộp vuông như Minecraft?",
description: "Đã bao giờ bạn hình dung về việc thế giới quanh ta bỗng nhiên trở thành những khối vuông như trong Minecraft chưa?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/5/photo-1-16281597059632071972653.jpg',
alt: "MINECRAFT,KHỐI HỘP VUÔNG,GAME 8-BIT,ĐỘNG VẬT,",
category: 'news',
time: '08:47 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:47 AM',
link: '/se-ra-sao-neu-cac-loai-dong-vat-deu-co-tao-hinh-khoi-hop-vuong-nhu-minecraft',
component: 'page_20210805084722'},
{title: "Những bộ phim \"thất lạc\" của tuổi thơ chúng ta: Một thời mất ăn mất ngủ, giờ muốn tìm lại cái tên cũng khó!",
description: "Liệu có ai mất cả thanh xuân để đi tìm lại những bộ phim này không?",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/1/g0hlezfqjp5drxmzkgzww9pup7n-1627798441341617348561.jpg',
alt: "Zathura: A Space Adventure,Kristen Stewart,josh hutcherson,taylor lautner,rachel mcadams,Anna Faris,phim Âu Mỹ,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/nhung-bo-phim-that-lac-cua-tuoi-tho-chung-ta-mot-thoi-mat-an-mat-ngu-gio-muon-tim-lai-cai-ten-cung-kho',
component: 'page_20210805084545'},
{title: "Tìm thấy “máy tính bảng” 3.700 năm tuổi của người Babylon: Minh chứng hùng hồn nhất của “xuyên không”?",
description: "Chiếc máy tính bảng Si.427 3.700 năm tuổi của người Babylon ẩn chứa điều kinh ngạc gì?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/photo-1-16281517391601033572813.jpg',
alt: "máy tính bảng,nhà toán học,thế kỷ 19,khảo cổ học,Thổ Nhĩ Kỳ,New South Wales,tam giác vuông,hình chữ nhật,sử dụng thiết bị,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/tim-thay-may-tinh-bang-3700-nam-tuoi-cua-nguoi-babylon-minh-chung-hung-hon-nhat-cua-xuyen-khong',
component: 'page_20210805084533'},
{title: "Avengers: Endgame từng có 1 đoạn rất ghê rợn và tàn nhẫn về Đội trưởng Mỹ, đạo diễn hé lộ lý do bắt buộc phải cắt bỏ",
description: "Trong cảnh phim này của Avengers: Endgame, Thanos đã thực sự chém đầu Đội trưởng Mỹ không khoan nhượng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/5/photo-1-1628139198044442413037.jpg',
alt: "Avengers: Endgame (2019),marvel,phim marvel,Captain America,Thanos,phim Âu Mỹ,vũ trụ điện ảnh marvel,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/avengers-endgame-tung-co-1-doan-rat-ghe-ron-va-tan-nhan-ve-doi-truong-my-dao-dien-he-lo-ly-do-bat-buoc-phai-cat-bo',
component: 'page_20210805084521'},
{title: "Bà xã nhà Messi ngày càng trở nên nóng bỏng thế này, bảo sao đến bạn gái Ronaldo cũng phải \"thả tim\" lia lịa",
description: "Antonella Roccuzzo ngày càng trở nên bạo dạn và quyến rũ hơn thông qua những shoot hình đăng tải lên mạng xã hội.",
src: 'https://sport5.mediacdn.vn/158855217956261888/2021/8/5/antonelaroccuzzo23148993836812276786435964785506388326497197n-1628156574716450839769.jpg',
alt: "messi,Bà xã Messi,Antonella Roccuzzo,bạn gái ronaldo,georgina,",
category: 'news',
time: '08:45 AM',
date: '05/08/2021',
timestamp: '05/08/2021 08:45 AM',
link: '/ba-xa-nha-messi-ngay-cang-tro-nen-nong-bong-the-nay-bao-sao-den-ban-gai-ronaldo-cung-phai-tha-tim-lia-lia',
component: 'page_20210805084510'},
{title: "Ngắm lại visual 5 nàng Tây Thi đẹp nhất: Mỹ nhân thập niên 80 - 90 đẹp mãn nhãn, có người U40 vẫn \"cưa sừng\" cực ngọt khi vào vai thiếu nữ 15",
description: "Đây chính là những nàng Tây Thi để lại dấu ấn sâu đậm trong lòng khán giả bởi tạo hình xuất thần.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/photo-1-1628042726013449816725.jpg',
alt: "Tây Thi,đồng lệ á,Tưởng Cần Cần,Tây Thi Bí Sử,star style,Makeup,tạo hình nhân vật,",
category: 'news',
time: '07:00 PM',
date: '04/08/2021',
timestamp: '04/08/2021 07:00 PM',
link: '/ngam-lai-visual-5-nang-tay-thi-dep-nhat-my-nhan-thap-nien-80-90-dep-man-nhan-co-nguoi-u40-van-cua-sung-cuc-ngot-khi-vao-vai-thieu-nu-15',
component: 'page_20210804190016'},
{title: "Từ Hulk xanh, Hulk đỏ, Hulk xám thì giờ đây chúng ta có cả Blue Hulk!",
description: "Hulk có màu xanh lá cây, đỏ, xám dường như đã quá quen thuộc với chúng ta, nhưng Blue Hulk lại là một điều khá mới mẻ là đây cũng là một trong những nhân vật mạnh mẽ nhất của Marvel.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/8/4/photo-1-1628050244150335388129.jpg',
alt: "HULK,",
category: 'news',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/tu-hulk-xanh-hulk-do-hulk-xam-thi-gio-day-chung-ta-co-ca-blue-hulk',
component: 'page_20210804185933'},
{title: "Tại sao khỉ đột, tinh tinh và chuột túi hầu như không cần phải tập luyện sức mạnh mà cơ bắp của chúng vẫn cực kỳ phát triển?",
description: "Chắc chắn chúng ta đã từng nhìn thấy những con chuột túi đực, tinh tinh hay khỉ đột sở hữu cơ bắp cuồn cuộn mà chẳng cần phải đến phòng tập gym, trong khi con người chúng ta phải trả qua tập luyện cực khổ mới có được cơ bắp như ý muốn, tại sao lại như vậy?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/4/photo-1-16280543932061707804782.jpg',
alt: "KHỈ ĐỘT,ĐỘNG VẬT,TINH TINH,CƠ BẮP,CỘNG ĐỒNG MẠNG,",
category: 'news',
time: '06:59 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:59 PM',
link: '/tai-sao-khi-dot-tinh-tinh-va-chuot-tui-hau-nhu-khong-can-phai-tap-luyen-suc-manh-ma-co-bap-cua-chung-van-cuc-ky-phat-trien',
component: 'page_20210804185909'},
{title: "Vụ án đào mộ chấn động: Xác chết cổ nhất Trung Quốc bị ném xuống mương, hung thủ bại lộ vì bức thư nặc danh!",
description: "Tháng 5/1994, thi hài cổ nhất Trung Quốc được phát hiện ở thành phố Kinh Môn, tỉnh Hồ Bắc. Đây là thi hài người phụ nữ sống cách đây gần 2.400 năm được bảo tồn hoàn chỉnh nhất về ngoại hình và xương cốt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/photo-6-1628067552764355170762.jpg',
alt: "tỉnh Hồ Bắc,người phụ nữ,quy mô lớn,cảnh sát trưởng,cỗ quan tài,tầng lớp quý tộc,Lịch sử đất nước,Thời Chiến Quốc,đào trộm mộ,thi thể phụ nữ,cao chạy xa bay,",
category: 'news',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/vu-an-dao-mo-chan-dong-xac-chet-co-nhat-trung-quoc-bi-nem-xuong-muong-hung-thu-bai-lo-vi-buc-thu-nac-danh',
component: 'page_20210804185659'},
{title: "Các phi tần tuẫn táng cùng hoàng đế khi được phát hiện chân luôn ở tư thế mở rộng, trước khi chết đã xảy ra chuyện gì?",
description: "Những gì mà các phi tần mỹ nữ phải tuẫn táng cùng hoàng đế phải chịu đựng trước khi chết sẽ được hé lộ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/2/-1627902134049205873274.jpg',
alt: "thời cổ đại,cung tần mỹ nữ,Trung Quốc,",
category: 'news',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/cac-phi-tan-tuan-tang-cung-hoang-de-chan-khi-duoc-phat-hien-chan-luon-o-tu-the-mo-rong-truoc-khi-chet-da-xay-ra-chuyen-gi',
component: 'page_20210804185649'},
{title: "\"Tuyển tập\" bê bối đạo nhái của Vu Chính: Chê bai Như Ý Truyện nhưng \"cướp\" hẳn cảnh quay cho vào phim mình?",
description: "Các bộ phim truyền hình do Vu Chính sản xuất không ít lần dính nghi án đạo nhái, vay mượn ý tưởng.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/3/photo-1-1628001417737353057620.jpg',
alt: "phim Hoa ngữ,Phim truyền hình,phim truyền hình Hoa ngữ,phim truyền hình trung quốc,phim trung quốc,Thâm cung nội chiến,Xa Thi Mạn,trương khả di,Hậu Cung Như Ý Truyện (2018),hậu cung chân hoàn truyện (2011),vu chính,",
category: 'news',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/tuyen-tap-be-boi-dao-nhai-cua-vu-chinh-che-bai-nhu-y-truyen-nhung-cuop-han-canh-quay-cho-vao-phim-minh',
component: 'page_20210804185635'},
{title: "Các nhà khoa học đã chụp được ảnh thời điểm trứng người chấp nhận tinh trùng, thực sự sẽ có một tia sáng cực lớn như pháo hoa",
description: "Cách đây vài ngày, khi một nhóm nghiên cứu từ Đại học Northwestern đang quan sát quá trình thụ tinh của một tế bào trứng, họ đã rất ngạc nhiên khi phát hiện ra rằng khi một tế bào trứng của con người chấp nhận tinh trùng, một ánh sáng chói lóa phát ra xung quanh tế bào trứng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/8/3/photo-1-1627924827274339787322.gif',
alt: "KHÁM PHÁ,KHOA HỌC,CON NGƯỜI,TINH TRÙNG,CỘNG ĐỒNG MẠNG,",
category: 'news',
time: '11:11 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:11 AM',
link: '/cac-nha-khoa-hoc-da-chup-duoc-anh-thoi-diem-trung-nguoi-chap-nhan-tinh-trung-thuc-su-se-co-mot-tia-sang-cuc-lon-nhu-phao-hoa',
component: 'page_20210803111136'},
{title: "Tiên nữ bê đĩa đào là ai? Truyền thuyết gắn liền với chiếc đĩa sứ cô tiên nhà nào cũng có",
description: "Những chiếc đĩa sứ với hình cô tiên bê đĩa đào hẳn đã là một phần tuổi thơ của rất nhiều người. Nhưng nhân vật này là ai? Tại sao lại được in hình trên đĩa?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/3/photo-1-1627958822484112425535.jpg',
alt: "Người Trung Hoa,đời nhà thanh,",
category: 'news',
time: '11:09 AM',
date: '03/08/2021',
timestamp: '03/08/2021 11:09 AM',
link: '/tien-nu-be-dia-dao-la-ai-truyen-thuyet-gan-lien-voi-chiec-dia-su-co-tien-nha-nao-cung-co',
component: 'page_20210803110933'},
{title: "Ngay trong đêm Khang Hy băng hà, Ung Chính lập tức xử tử thân tín đã theo tiên đế suốt 60 năm, ông đến cùng đã đắc tội với ai?",
description: "Lý do thực sự khiến Ung Chính xử tử Triệu Xương - một thái giám cận kề hơn 60 năm bên Khang Hy đế ngay trong đêm ông băng hà là gì?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/26/-16272921001512030615251.jpg',
alt: "nhà Thanh Trung Quốc,Uông Chính,khang hy,",
category: 'news',
time: '01:17 AM',
date: '44235',
timestamp: '02/08/2021 01:17 AM',
link: '/ngay-trong-dem-khang-hy-bang-ha-ung-chinh-lap-tuc-xu-tu-than-tin-da-theo-tien-de-suot-60-nam-ong-den-cung-da-dac-toi-voi-ai',
component: 'page_20210802011714'},
{title: "Khi quốc kỳ Việt Nam \"hóa người\", profile mê phở và giỏi xem bói khiến netizen thích thú",
description: "Nhìn thông tin của \"anh chàng Việt Nam\" netizen không khỏi cảm thán \"chất quá!\".",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/31/photo-1-1627707697606365323420.jpg',
alt: "QUỐC KỲ VIỆT NAM,TOKYO OLYMPIC 2020,WORLD FLAGS,QUỐC KỲ VIỆT NAM BIẾN THÀNH NGƯỜI,",
category: 'news',
time: '01:19 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:19 PM',
link: '/khi-quoc-ky-viet-nam-hoa-nguoi-profile-me-pho-va-gioi-xem-boi-khien-netizen-thich-thu',
component: 'page_20210731131929'},
{title: "Một ngày của hoàng đế xưa kia trải qua như thế nào? Càn Long dậy lúc 3h sáng, 7h tối lật thẻ bài, mỗi ngày lặp lại vô vị",
description: "Cuộc sống của hoàng đế có thực sự vui vẻ, cả ngày chỉ ăn chơi hưởng lạc như chúng ta vẫn nghĩ?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/29/-16275485979551090830879.jpg',
alt: "Trung Quốc cổ đại,Càn Long,",
category: 'news',
time: '01:18 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:18 PM',
link: '/mot-ngay-cua-hoang-de-xua-kia-trai-qua-nhu-the-nao-can-long-day-luc-3h-sang-7h-toi-lat-the-bai-moi-ngay-lap-lai-vo-vi',
component: 'page_20210731131813'},
{title: "“Cuộc chiến” gây tranh cãi nhất cõi mạng hiện nay: Phở hay cơm tấm mới xứng đáng là món ăn đại diện Việt Nam?",
description: "Xuất phát từ một bài đăng phản biện trong nhóm Facebook, chủ đề này hiện đang gây tranh cãi lớn trên MXH.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/31/ct1-1627745375211263119053.jpeg',
alt: "cuộc chiến ẩm thực,phở,quốc hồn quốc tuý,cơm tấm,sườn nướng,món ăn đại diện Việt Nam,",
category: 'news',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/cuoc-chien-gay-tranh-cai-nhat-coi-mang-hien-nay-pho-hay-com-tam-moi-xung-dang-la-mon-an-dai-dien-viet-nam',
component: 'page_20210731131727'},
{title: "Chỉ bằng 1 lời nói chân thành, Ronaldo đã thay đổi số phận của cậu bé Nhật Bản từng bị đám đông chế giễu: Đẳng cấp thực thụ của một ngôi sao lớn!",
description: "Được đích thân Cristiano Ronaldo bảo vệ và khuyên nhủ, cậu bé 12 tuổi từng bị đám đông chế giễu năm nào giờ đã trở thành nhà vô địch bóng đá quốc gia ở cấp độ trung học.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/31/photo-1-16277387120352048380142.jpg',
alt: "ronaldo,Nhật Bản,",
category: 'news',
time: '01:17 PM',
date: '31/07/2021',
timestamp: '31/07/2021 01:17 PM',
link: '/chi-bang-1-loi-noi-chan-thanh-ronaldo-da-thay-doi-so-phan-cua-cau-be-nhat-ban-tung-bi-dam-dong-che-gieu-dang-cap-thuc-thu-cua-mot-ngoi-sao-lon',
component: 'page_20210731131713'},
{title: "Những sinh vật kỳ bí, quỷ quyết và đầy mê hoặc trong thần thoại Bắc Âu",
description: "Trong thần thoại Bắc Âu không chỉ có các vị thần và người khổng lồ.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/30/to-make-my-small-elves-coats-1627635135792898967258.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,THẦN THOẠI BẮC ÂU,",
category: 'news',
time: '12:19 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:19 PM',
link: '/nhung-sinh-vat-ky-bi-quy-quyet-va-day-me-hoac-trong-than-thoai-bac-au',
component: 'page_20210730121944'},
{title: "Axie Infinity coin giảm giá chóng mặt, game thủ Việt hoang mang lo lắng",
description: "Axie Infinity coin đã bốc hơi 21% giá trị trong 2 ngày qua.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/29/untitled-1627574937832795816169.png',
alt: "AXIE INFINITY,AXS,AXS COIN,GAME THỦ VIỆT,GIỚI CÔNG NGHỆ,CÔNG NGHỆ VIỆT NAM,TIỀN ẢO,CRYPTO,",
category: 'news',
time: '12:19 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:19 PM',
link: '/axie-infinity-coin-giam-gia-chong-mat-game-thu-viet-hoang-mang-lo-lang',
component: 'page_20210730121904'},
{title: "4 xung đột giữa các diễn viên của Fast & Furious khiến người dọa bỏ vai kẻ thì bye bye thật",
description: "Mặc dù chủ đề chính của Fast & Furious là gia đình, nhưng điều đó không có nghĩa mọi người trong tập thể này lúc nào cũng gắn bó với nhau, dù là trong phim hay ngoài đời.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/29/photo-1-16275643825141972207601.jpg',
alt: "FAST & FURIOUS,SERIES FAST,THE FAST AND THE FURIOUS,THE FAST SAGA,VIN DIESEL,THE ROCK,TYRESE GIBSON,",
category: 'news',
time: '12:18 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:18 PM',
link: '/4-xung-dot-giua-cac-dien-vien-cua-fast-furious-khien-nguoi-doa-bo-vai-ke-thi-bye-bye-that',
component: 'page_20210730121837'},
{title: "Những bí mật ẩn giấu của các vận động viên Olympic",
description: "Qua cách họ tìm mọi cách vượt qua giới hạn bản thân, bạn có thể có cái nhìn mới về cơ thể con người.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/29/photo-1-16275335451592084135479.jpg',
alt: "VẬN ĐỘNG VIÊN,CƠ THỂ CON NGƯỜI,THỂ DỤC DỤNG CỤ,CHẠY NƯỚC RÚT,NHÀ VÔ ĐỊCH,OLYMPIC,",
category: 'news',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/nhung-bi-mat-an-giau-cua-cac-van-dong-vien-olympic',
component: 'page_20210729162657'},
{title: "Top 4 ninja huyền thoại đã trở thành nguồn cảm hứng nghệ thuật, xuất hiện nhiều trong anime/manga",
description: "Từ những chiến binh Tokugawa trung thành đến người sáng lập Kunoichi đều trở thành cảm hứng sáng tác cho các mangaka.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/26/photo-1-16272838335811881199361.jpg',
alt: "NINJA,KUNOICHI,NINJA HUYỀN THOẠI,HANZO HATTORI,KOTARO FUMA,SASUKE SARUTOBI,CHIYOME MOCHIZUKI,",
category: 'news',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/top-4-ninja-huyen-thoai-da-tro-thanh-nguon-cam-hung-nghe-thuat-xuat-hien-nhieu-trong-anime-manga',
component: 'page_20210729162559'},
{title: "Mãn nhãn chiêm ngưỡng loạt quốc kỳ các nước tham dự Tokyo Olympic 2020 được vẽ theo phong cách anime",
description: "Quốc kỳ Việt Nam đẹp quá anh em nhỉ?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/26/photo-1-16272808098881406095513.jpg',
alt: "QUỐC KỲ,QUỐC KỲ VẼ AIME,TOKYO OLYMPIC 2020,ANIME,",
category: 'news',
time: '04:25 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:25 PM',
link: '/man-nhan-chiem-nguong-loat-quoc-ky-cac-nuoc-tham-du-tokyo-olympic-2020-duoc-ve-theo-phong-cach-anime',
component: 'page_20210729162539'},
{title: "Những quái vật đáng sợ từng được đồn đại xuất hiện trong lịch sử loài người (P.2)",
description: "Thế giới tồn tại rất nhiều loài sinh vật kinh dị, gây nên sự sợ hãi đối với con người.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/24/photo-1-162713687304154013119.png',
alt: "QUÁI VẬT,TRUYỀN THUYẾT,",
category: 'news',
time: '04:23 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:23 PM',
link: '/nhung-quai-vat-dang-so-tung-duoc-don-dai-xuat-hien-trong-lich-su-loai-nguoi-p2',
component: 'page_20210729162344'},
{title: "Cái chết của Ung Chính sau cả trăm năm vẫn là bí ẩn nay được hé lộ qua một hồ sơ mật, chính sử không dám ghi lại",
description: "Nguyên nhân thực sự đằng sau cái chết của Ung Chính là gì?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/22/-16269520845031092644030.jpg',
alt: "Ung Chính,Lịch sử Trung Quốc,",
category: 'news',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/cai-chet-cua-ung-chinh-sau-ca-tram-nam-van-la-bi-an-nay-duoc-he-lo-qua-mot-ho-so-mat-chinh-su-khong-dam-ghi-lai',
component: 'page_20210729162250'},
{title: "Nguyên mẫu của Ngụy Anh Lạc: 10 năm sinh 6 đứa con, trăm năm sau khai quật lăng mộ mới phát hiện Càn Long ích kỷ tới mức nào",
description: "Ngụy Anh Lạc gây ấn tượng trong lòng khán giả với hình tượng là một người phụ nữ đầy lý trí và quyết đoán, nguyên mẫu của nhân vật này thực sự có số phận thế nào?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/25/-16246007872231961607847.jpg',
alt: "Ngụy Anh Lạc,Càn Long,",
category: 'news',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/nguyen-mau-cua-nguy-anh-lac-10-nam-sinh-6-dua-con-tram-nam-sau-khai-quat-lang-mo-moi-phat-hien-can-long-ich-ky-toi-muc-nao',
component: 'page_20210729162223'},
{title: "Tại sao Hòa Thân bốc cát bỏ vào cháo cứu nạn liền được Càn Long trọng thưởng, trăm năm sau người đời còn ngợi ca?",
description: "Hành động thể hiện sự thông minh, tài trí của Hòa Thân - một tên tham quan nổi tiếng trong lịch sử Trung Hoa.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/22/-16269489575971688054983.jpg',
alt: "Hòa Thân,Lịch sử Trung Hoa,Càn Long,",
category: 'news',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/tai-sao-hoa-than-boc-cat-bo-vao-chao-cuu-nan-lien-duoc-can-long-trong-thuong-tram-nam-sau-nguoi-doi-con-ngoi-ca',
component: 'page_20210729162215'},
{title: "Những con quái vật rắn nổi tiếng trong thần thoại Hy Lạp",
description: "Rắn thường được miêu tả với tư cách là các vị thần hoặc quái vật trong thần thoại của người Hy Lạp.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/20/photo-1-1626771905962962558871.jpg',
alt: "THẦN THOẠI,QUÁI VẬT,HY LẠP,HYDRA,MEDUSA,",
category: 'news',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/nhung-con-quai-vat-ran-noi-tieng-trong-than-thoai-hy-lap',
component: 'page_20210729162122'},
{title: "Những loài quái vật kỳ quái trong truyền thuyết châu Á",
description: "Với trí tưởng tượng độc đáo, người châu Á đã tưởng tượng ra rất nhiều con quái vật ma quái và có phần dị hợm vô cùng.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/19/-16266845034714681647.jpg',
alt: "QUÁI VẬT,YÊU QUÁI,",
category: 'news',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/nhung-loai-quai-vat-ky-quai-trong-truyen-thuyet-chau-a',
component: 'page_20210729162037'},
{title: "Đa vũ trụ Marvel là gì?",
description: "Cái kết của series Loki đã chính thức đưa MCU bước vào đa vũ trụ, nhưng rốt cuộc thì tình trạng này là gì mà lại có vẻ nghiêm trọng đến vậy?",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-16266197121021694927468.jpg',
alt: "MARVEL,ĐA VŨ TRỤ,SIÊU ANH HÙNG,MARVEL MULTIVERSE,TRUYỆN TRANH,ĐA VŨ TRỤ MARVEL,",
category: 'news',
time: '04:20 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:20 PM',
link: '/da-vu-tru-marvel-la-gi',
component: 'page_20210729162008'},
{title: "Top 10 công việc kỳ lạ mà siêu anh hùng đã làm để kiếm tiền trang trải cuộc sống trong comic (P.1)",
description: "Không phải ai cũng có nhiều tiền như Batman và Iron Man, một số siêu anh hùng phải làm việc để kiếm sống.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-1626603187021266884680.jpg',
alt: "MARVEL,GHOST RIDER,SIÊU ANH HÙNG,CÔNG VIỆC CỦA SIÊU ANH HÙNG,BLACK CANARY,DC,",
category: 'news',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/top-10-cong-viec-ky-la-ma-sieu-anh-hung-da-lam-de-kiem-tien-trang-trai-cuoc-song-trong-comic-p1',
component: 'page_20210729161949'},
{title: "Top 10 công việc kỳ lạ mà siêu anh hùng đã làm để kiếm tiền trang trải cuộc sống trong comic (P.2)",
description: "Không phải ai cũng có nhiều tiền như Batman và Iron Man, một số siêu anh hùng phải làm việc để kiếm sống.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/18/photo-1-16266035178331141843848.jpg',
alt: "MARVEL,DC,GREEN LANTERN,SIÊU ANH HÙNG,NGHỆ NGHIỆP CỦA SIÊU ANH HÙNG,",
category: 'news',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/top-10-cong-viec-ky-la-ma-sieu-anh-hung-da-lam-de-kiem-tien-trang-trai-cuoc-song-trong-comic-p2',
component: 'page_20210729161939'},
{title: "Chàng trai mang món \"pháp khí\" đi kiểm định khiến cả trường quay kinh hãi, chuyên gia lắc đầu: Cầm về đi, tôi không định giá!",
description: "Khi nghe tới vật liệu làm nên món \"pháp khí\", toàn bộ khán giả trong trường quay đều \"toát mồ hôi hột\".",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/19/photo-1-16266781826341961459693.jpeg',
alt: "toát mồ hôi,toát mồ hôi hột,người dân địa phương,giá trị vật chất,sức hấp dẫn,",
category: 'news',
time: '04:19 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:19 PM',
link: '/chang-trai-mang-mon-phap-khi-di-kiem-dinh-khien-ca-truong-quay-kinh-hai-chuyen-gia-lac-dau-cam-ve-di-toi-khong-dinh-gia',
component: 'page_20210729161909'},
{title: "20 chú động vật béo tròn núc ních, có thể vẽ lại hoàn toàn bằng 1 chiếc compa",
description: "Trông dễ thương vậy thôi chứ béo phì khổ sở ghê lắm đó nghe.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/19/round-animals22-16266923346001459291269.jpg',
alt: "động vật béo,đáng yêu,tròn vo,relax,",
category: 'news',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/20-chu-dong-vat-beo-tron-nuc-nich-co-the-ve-lai-hoan-toan-bang-1-chiec-compa',
component: 'page_20210729161859'},
{title: "Hy-Brasil: Hòn đảo ma trong truyền thuyết và điểm tọa độ UFO kỳ bí được viết bằng mã nhị phân",
description: "Câu chuyện về hòn đảo được lưu truyền khắp châu Âu trong nhiều thế kỷ. Người ta nói rằng hòn đảo là nơi sinh sống của các vị thánh hoặc là một thiên đường nơi có nền văn minh tiên tiến.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/15/binary-code-showing-the-coordinates-of-hy-brasil-1626340762537741265047.jpg',
alt: "KHÁM PHÁ,BÍ ẨN,TRUYỀN THUYẾT,",
category: 'news',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/hy-brasil-hon-dao-ma-trong-truyen-thuyet-va-diem-toa-do-ufo-ky-bi-duoc-viet-bang-ma-nhi-phan',
component: 'page_20210729161836'},
{title: "18 ví dụ chứng minh những bộ phim càng bom tấn lại càng có nhiều lỗi rất ngớ ngẩn",
description: "Có rất nhiều bộ phim bom tấn quá hay, khiến người xem không còn tinh tế để nhận rằng chúng luôn có những hạt sạn.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/16/photo-1-16264291359491681184130.jpg',
alt: "THE HOBBIT VÀ THE LORD OF THE RINGS,HARRY POTTER,HARRY POTTER VÀ HỘI PHƯỢNG HOÀNG,SPIDER-MAN,STAR TREK,TWILIGHT,PIRATES OF THE CARIBBEAN,",
category: 'news',
time: '04:18 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:18 PM',
link: '/18-vi-du-chung-minh-nhung-bo-phim-cang-bom-tan-lai-cang-co-nhieu-loi-rat-ngo-ngan',
component: 'page_20210729161807'},
{title: "Địa ngục Tartarus trong thần thoại Hy Lạp nằm ở đâu?",
description: "Tartarus là vực thẳm địa ngục trong thần thoại Hy Lạp, nơi đem lại nỗi đau khổ cho các Titan bị giam giữ. Tartarus cũng là tên của một vị thần, cổ xưa và nguyên thủy, tồn tại trước cả Titan và Olympus.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/16/aion-and-gaia-162642442147914292086.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,THẦN THOẠI HY LẠP,CUỘC SỐNG,",
category: 'news',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/dia-nguc-tartarus-trong-than-thoai-hy-lap-nam-o-dau',
component: 'page_20210729161758'},
{title: "Đường Tăng đẹp nhất Tây Du Ký tức giận bỏ vai chỉ vì 18 nghìn đồng, dối lừa khán giả rồi bị Trư Bát Giới \"vạch mặt\"?",
description: "Trong Tây Du Ký, mối quan hệ giữa các thành viên không phải lúc nào cũng hòa nhã.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/17/photo-1-16264908640182071786208.jpeg',
alt: "TRƯ BÁT GIỚI,ĐƯỜNG TĂNG,TÂY DU KÝ,TỪ THIẾU HOA,ĐƯỜNG TĂNG ĐẸP NHẤT,",
category: 'news',
time: '04:17 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:17 PM',
link: '/duong-tang-dep-nhat-tay-du-ky-tuc-gian-bo-vai-chi-vi-18-nghin-dong-doi-lua-khan-gia-roi-bi-tru-bat-gioi-vach-mat',
component: 'page_20210729161728'},
{title: "13 báu vật ma thuật của đảo Anh, những truyền thuyết bị lãng quên (P.1)",
description: "Các báu vật này được nhắc đến trong văn bản cổ của xứ Welsh với tiêu đề \"Tri Thlws ar Ddeg Ynys Prydain\", dịch ra có nghĩa là \"Tên của 13 báu vật ở phía bắc đảo Anh\".",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/9/sword-of-rhydderch-the-generous0-16258376823891587814041.jpg',
alt: "KHÁM PHÁ,ĐỜI SỐNG,TRUYỀN THUYẾT,LỊCH SỬ,THẦN THOẠI,",
category: 'news',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/13-bau-vat-ma-thuat-cua-dao-anh-nhung-truyen-thuyet-bi-lang-quen-p1',
component: 'page_20210729161642'},
{title: "Tại sao bánh quy thường có viền răng cưa? Chính nhà sản xuất lên tiếng giải đáp bí ẩn bao năm mà chưa ai nghĩ ra nổi",
description: "Viền răng cưa ở những chiếc bánh quy không phải để cho đẹp mà đáp án thực sự khiến hầu hết mọi người, nhất là hội mê bánh quy nghe xong đều sững sờ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/8/newfile-8-16257365816362135614612.jpg',
alt: "bánh quy,giải đáp thắc mắc,bánh quy Ritz,",
category: 'news',
time: '04:16 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:16 PM',
link: '/tai-sao-banh-quy-thuong-co-vien-rang-cua-chinh-nha-san-xuat-len-tieng-giai-dap-bi-an-bao-nam-khien-dan-mang-soc-vi-chua-ai-nghi-ra-noi',
component: 'page_20210729161613'},
{title: "Quái vật người tuyết Yeti có thực sự tồn tại, hay chỉ là tưởng tượng của loài người?",
description: "Yeti, dịch theo ngôn ngữ địa phương có nghĩa là \"Người tuyết\", là một sinh vật huyền bí, khổng lồ và được cho là sống tại khu vực dãy núi Himalaya lạnh giá và một vài vùng núi phương Bắc.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-1625736015746304669013.jpg',
alt: "QUÁI VẬT,YETI,TRUYỀN THUYẾT,",
category: 'news',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/quai-vat-nguoi-tuyet-yeti-co-thuc-su-ton-tai-hay-chi-la-tuong-tuong-cua-loai-nguoi',
component: 'page_20210729161540'},
{title: "9 điểm \"phi logic\" từ Harry Potter: Voldemort có thể bị hạ gục đơn giản, nhà ngục Azkaban chắc chắn có vấn đề!",
description: "Có những thắc mắc lạ lùng mà ngay cả fan cứng Harry Potter cũng phải lắc đầu không giải thích được.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/8/photo-1-16257387231192055409347.jpg',
alt: "AZKABAN,NHÀ NGỤC AZKABAN,HARRY POTTER,VOLDEMORT,CHI TIẾT PHI LÝ TRONG HARRY POTTER,",
category: 'news',
time: '04:15 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:15 PM',
link: '/9-diem-phi-logic-tu-harry-potter-voldemort-co-the-bi-ha-guc-don-gian-nha-nguc-azkaban-chac-chan-co-van-de',
component: 'page_20210729161530'},
{title: "Truyền thuyết nàng tiên cá siêu dị trong thần thoại Nhật Bản",
description: "Thần thoại Nhật Bản cũng có nàng tiên cá. Tuy nhiên, nàng tiên cá này không xinh đẹp, gợi cảm như thần thoại Hy Lạp mà vô cùng kì dị.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/7/magical-mermaids-16256640999882054863144.jpg',
alt: "KHÁM PHÁ,TIÊN CÁ,THẦN THOẠI,THẦN THOẠI NHẬT BẢN,",
category: 'news',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/truyen-thuyet-nang-tien-ca-sieu-di-trong-than-thoai-nhat-ban',
component: 'page_20210729161444'},
{title: "Pháp thuật \"vẽ vòng\" Tôn Ngộ Không để bảo vệ Đường Tăng có gì đặc biệt?",
description: "Trong tập phim \"Ba lần đánh bại Bạch Cốt Tinh\", Tôn Ngộ Không đã dùng phép này để bảo vệ Đường Tăng đang ở một mình.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/6/photo-1-16255712177881351405509.jpg',
alt: "TÔN NGỘ KHÔNG,TÂY DU KÝ,ĐƯỜNG TĂNG,",
category: 'news',
time: '04:14 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:14 PM',
link: '/phap-thuat-ve-vong-ton-ngo-khong-de-bao-ve-duong-tang-co-gi-dac-biet',
component: 'page_20210729161409'},
{title: "Khám phá \"bảo tàng quỷ ám\" nhà Warren cùng những giai thoại \"nổi da gà\"",
description: "Trong các phần phim thuộc vũ trụ điện ảnh Conjuring, khán giả nhiều lần \"chạm trán\" với những món đồ quỷ ám và cũng là chứng tích cho hành trình trừ ma của gia đình Warren.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/6/photo-1-1625567801393958335029.jpg',
alt: "BÚP BÊ ANNABELLE,VŨ TRỤ ĐIỆN ẢNH CONJURING,BẢO TÀNG WARREN,MÓN ĐỒ QUỶ ÁM,PHIM KINH DỊ,CHIẾC GƯƠNG TRIỆU HỒN,",
category: 'news',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/kham-pha-bao-tang-quy-am-nha-warren-cung-nhung-giai-thoai-noi-da-ga',
component: 'page_20210729161351'},
{title: "8 sinh vật siêu dị bị lãng quên trong thần thoại Hy Lạp mà không phải ai cũng biết (P.1)",
description: "Bên cạnh những anh hùng, thần thánh và các chuyến phiêu lưu thú vị, thần thoại Hy Lạp còn là kho tàng giới thiệu vô số sinh vật hoặc quái vật cực dị như Dracaenae, Onocentaur hãy mãng xà Cerastes...",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/5/campebyhighdarktemplardd3kenh-fullview-16254955842141325519901.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,LỊCH SỬ,THẦN THOẠI HY LẠP,ĐỜI SỐNG,",
category: 'news',
time: '04:13 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:13 PM',
link: '/8-sinh-vat-sieu-di-bi-lang-quen-trong-than-thoai-hy-lap-ma-khong-phai-ai-cung-biet-p1',
component: 'page_20210729161320'},
{title: "Ngọc Trinh đi bơi tại biệt thự 1800m2, Vũ Khắc Tiệp \"tóm gọn\" bằng clip quay vội nhưng không dìm nổi body chuẩn chỉnh",
description: "Người hâm mộ một lần nữa \"mắt tròn mắt dẹt\" với chiếc clip \"sương sương\" ghi lại khoảnh khắc quyến rũ không lối thoát của Ngọc Trinh.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/4/photo-1-16253595832721777633316.png',
alt: "Ngọc Trinh,nữ hoàng nội y Ngọc Trinh,Body Ngọc Trinh,nữ hoàng nội y,Vũ Khắc Tiệp,ông bầu Vũ Khắc Tiệp,ông bầu Venus,sao Việt,đại chiến bikini,Sao diện bikini,",
category: 'news',
time: '04:10 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:10 PM',
link: '/ngoc-trinh-hot-hai-di-boi-tai-biet-thu-1800m2-vu-khac-tiep-tom-gon-bang-chiec-clip-quay-voi-nhung-khong-dim-noi-body-chuan-tung-centimet',
component: 'page_20210729161028'},
{title: "Monopod – Truyền thuyết về người lùn chỉ có một chân giữa đầy bí ẩn trong sách cổ",
description: "Giống người chỉ có một chân giữa Monopod được nhắc đến nhiều trong sách cổ, vậy giống người này có thật hay không?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/2/sciapode-16252243335561791656975.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,CUỘC SỐNG,LỊCH SỬ,SINH VẬT THẦN THOẠI,",
category: 'news',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/monopod-truyen-thuyet-ve-nguoi-lun-chi-co-mot-chan-giua-day-bi-an-trong-sach-co',
component: 'page_20210729160956'},
{title: "Số phận những đứa con của thần Loki trong thần thoại Bắc Âu",
description: "Trong thần thoại Bắc Âu, Loki là nhân vật độc đáo và khó hiểu khi vừa giúp đỡ vừa phá hoại các vị thần. Cuộc chiến Ragnarok cũng bắt nguồn từ Loki và những đứa con.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/30/the-children-of-loki-e28094-norse-mythology-explained-16250469800041162828921.jpg',
alt: "KHÁM PHÁ,THẦN THOẠI,THẦN THOẠI BẮC ÂU,THOR,LOKI,",
category: 'news',
time: '04:09 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:09 PM',
link: '/so-phan-nhung-dua-con-cua-than-loki-trong-than-thoai-bac-au',
component: 'page_20210729160904'},
{title: "Ly kỳ bí ẩn chưa từng biết về Shapeshifter, những kẻ đáng sợ có khả năng biến hình người",
description: "Shapeshifter là những con quái vật duy nhất trải qua nhiều quá trình biến đổi dữ dội tới thế.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/7/1/photo-1-16251299149081972291856.jpg',
alt: "QUÁI VẬT,BIẾN HÌNH,SHAPESHIFTER,",
category: 'news',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/ly-ky-bi-an-chua-tung-biet-ve-shapeshifter-nhung-ke-dang-so-co-kha-nang-bien-hinh-nguoi',
component: 'page_20210729160826'},
{title: "Bốn người Việt Nam bất ngờ bị Facebook đệ đơn kiện vì lý do khiến nhiều người cảm thấy hả hê",
description: "Vào ngày 29/6 vừa qua, Facebook đưa ra thông báo kiện hai nhóm lừa đảo trực tuyến, đáng chú ý có một nhóm người Việt trong đó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/30/photo-1-1625052441581230996678.jpeg',
alt: "FACEBOOK,MXH,MẠNG XÃ HỘI,SCAM,CỘNG ĐỒNG MẠNG,CĐM,LỪA ĐẢO,",
category: 'news',
time: '04:08 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:08 PM',
link: '/bon-nguoi-viet-nam-bat-ngo-bi-facebook-de-don-kien-vi-ly-do-khien-nhieu-nguoi-cam-thay-ha-he',
component: 'page_20210729160809'},
{title: "Những điều chưa biết về Kappa, loài thủy quái kỳ quặc trong truyền thuyết Nhật Bản",
description: "Câu chuyện về nguồn gốc của Kappa tuy rất đáng sợ nhưng cũng rất thú vị.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/7/17/photo-1-162651927099224274309.jpg',
alt: "THỦY QUÁI,KAPPA,YÊU QUÁI,",
category: 'news',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/nhung-dieu-chua-biet-ve-kappa-loai-thuy-quai-ky-quac-trong-truyen-thuyet-nhat-ban',
component: 'page_20210729160636'},
{title: "Sinh viên mới ra trường: Kiếm mức lương 20 - 30 triệu/tháng thế nào?",
description: "Hóa ra để kiếm hàng chục triệu/tháng khi mới ra trường không khó như bạn nghĩ.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/20/photo-1472220625704-91e1462799b2-162417118059132874823.jpg',
alt: "đáp ứng nhu cầu,chia sẻ câu chuyện,Tuyển dụng,bí quyết xin việc,Công việc,sinh viên,sinh viên mới ra trường,bí quyết thu nhập cao,",
category: 'news',
time: '09:51 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:51 PM',
link: '/sinh-vien-moi-ra-truong-kiem-muc-luong-20-30-trieu-thang-the-nao',
component: 'page_20210629215134'},
{title: "7 bí ẩn nổi tiếng thường xuyên bị hiểu nhầm do thế lực thần bí gây ra, nhưng lời giải hóa ra lại khá đơn giản",
description: "Lời giải cho những bí ẩn này hóa ra lại đơn giản hơn chúng ta tưởng!",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/20/photo-1-1624173966024705477776.jpg',
alt: "VĂN MINH MAYA,LỊCH SỬ LOÀI NGƯỜI,NHÀ KHOA HỌC,NGƯỜI NGOÀI HÀNH TINH,THUNG LŨNG CHẾT,PHẢN ỨNG HÓA HỌC,",
category: 'news',
time: '09:44 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:44 PM',
link: '/7-bi-an-noi-tieng-thuong-xuyen-bi-hieu-nham-do-the-luc-than-bi-gay-ra-nhung-loi-giai-hoa-ra-lai-kha-don-gian',
component: 'page_20210629214425'},
{title: "Thất vọng vì thua Đức tan nát, Ronaldo bèn cởi trần khiến dân tình náo loạn: 6 múi sầu riêng đều tăm tắp nhờ nói không với Coca",
description: "Ronaldo show body cơ bắp cho dân tình chiêm ngưỡng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/20/photo-1-1624146308081764237574.jpeg',
alt: "ronaldo,Bồ Đào Nha,đức,Euro 2020,tiêu điểm euro,Đồng hành cùng Euro,tin nóng Euro,",
category: 'news',
time: '09:43 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:43 PM',
link: '/that-vong-vi-thua-duc-tan-nat-ronaldo-ben-coi-tran-khien-dan-tinh-nao-loan-6-mui-sau-rieng-deu-tam-tap',
component: 'page_20210629214330'},
{title: "Ronaldo cởi trần khoe body như tượng tạc sau khi đội nhà thua tan tác trước Đức nhưng vậy thì chiếc áo của CR7 đã ở đâu?",
description: "Sau khi trận đấu với Đức khép lại, chiếc áo của Ronaldo đã bỗng dưng biến mất. Vậy nó đã đi đâu?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/20/photo-3-1624200538417983513447.jpg',
alt: "ronaldo,Euro 2020,tin nóng Euro,",
category: 'news',
time: '09:43 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:43 PM',
link: '/ronaldo-coi-tran-khoe-body-nhu-tuong-tac-sau-khi-doi-nha-thua-tan-tac-truoc-duc-nhung-vay-thi-chiec-ao-cua-cr7-da-o-dau',
component: 'page_20210629214307'},
{title: "HLV Park Hang-seo bị cấm chỉ đạo trận UAE",
description: "Sau một pha tranh cãi nảy lửa với cầu thủ Malaysia trong trận đấu rạng sáng 12/6, ông Park Hang-seo đã phải nhận một thẻ vàng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/12/photo-2-1623441592144528997364.jpg',
alt: "Viet Nam Vs Malaysia Tai Vong Loai World Cup 2022, Tuyen Viet Nam, Viet Nam Vs Malaysia, Park Hang Seo, ",
category: 'news',
time: '09:30 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:30 AM',
link: '/hlv-park-hang-seo-bi-cam-chi-dao-tran-uae',
component: 'page_20210614093003'},
{title: "Cộng đồng mạng phát cuồng với màn trình diễn đỉnh cao của \"Manuel David de Tấn Trường\"",
description: "Ngay sau đó, trên mạng xã hội, các CĐV Việt Nam đã so sánh Tấn Trường với những thủ môn nổi tiếng thế giới như David De Gea hay Manuel Neuer bằng cách ghép tên khá hài hước. Sau đó, dù phải nhận bàn thua trên chấm 11m, Tấn Trường vẫn vững vàng. ĐT Việt Nam có những sự thay đổi người kịp thời và có được bàn vươn lên dẫn trước 2-1 nhờ công của Quế Ngọc Hải.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/12/photo-1-1623437890849206762956.png',
alt: "Viet Nam, Viet Nam Vs Malaysia, Viet Nam Dau Malaysia, Bui Tan Truong, Tan Truong, Thu Mon Tan Truong, Vong Loai World Cup 2022, World Cup 2022, Tan Truong Cuu Thua, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, ",
category: 'news',
time: '09:29 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:29 AM',
link: '/cong-dong-mang-phat-cuong-voi-man-trinh-dien-dinh-cao-cua-manuel-david-de-tan-truong',
component: 'page_20210614092946'},
{title: "Lee Kwang Soo liên tục xin lỗi trong đoạn thư chia tay gửi đồng đội Running Man",
description: "Tâm thư của Kwang Soo trong tập cuối cùng khiến fan không thể kìm được nước mắt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/photo-1-16235849348172030459849.jpg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man Sau 11 Nam, Running Man, ",
category: 'news',
time: '09:24 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:24 AM',
link: '/kwang-soo-viet-thu-tay-gui-cac-thanh-vien-va-fan-moi-nguoi-bay-gio-hay-quen-em-di',
component: 'page_20210614092438'},
{title: "Se Chan khóc gục xuống bàn, So Min và PD nhòe lệ khi chia tay Kwang Soo",
description: "Giây phút chia ly Kwang Soo, hai thành viên ít tuổi nhất Se Chan và So Min đã khóc hết nước mắt.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/photo-1-16235842431201126222547.jpg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man Sau 11 Nam, Yang Se Chan, Jeon So Min, Running Man, ",
category: 'news',
time: '09:20 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:20 AM',
link: '/se-chan-khoc-guc-xuong-ban-so-min-va-pd-nhoe-le-khi-chia-tay-kwang-soo',
component: 'page_20210614092039'},
{title: "Chủ tịch Văn Toàn dạy bạn “làm giàu”: Bị fan Malaysia công kích, chớp cơ hội ra luôn mẫu áo in hình cú ngã penalty",
description: "Thời đại nào rồi mà còn suy sụp khi bị anti làm phiền, phải chớp lấy cơ hội buôn bán như Văn Toàn đi nhé!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/a8-125931-16235717463561143548443.jpg',
alt: "Van Toan, Nguyen Van Toan, Relax, Cau Thu Van Toan, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, ",
category: 'news',
time: '09:20 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:20 AM',
link: '/chu-tich-van-toan-day-ban-lam-giau-bi-fan-malaysia-cong-kich-chop-co-hoi-ra-luon-mau-ao-in-hinh-cu-nga-penalty',
component: 'page_20210614092027'},
{title: "Lee Kwang Soo trong mắt NSX Running Man trước giờ chia tay: \"Cậu ấy không phải là đồ ngốc\"",
description: "Mọi thông tin mới nhất của Lee Kwang Soo trước giờ chính thức chia tay Running Man đều khiến cư dân mạng hết sức quan tâm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/13/anh-1-1623559384920800773602.jpeg',
alt: "Lee Kwang Soo, Sao Han, Idol, Kpop, Lee Kwang Soo Roi Running Man, Running Man, ",
category: 'news',
time: '09:20 AM',
date: '14/06/2021',
timestamp: '14/06/2021 09:20 AM',
link: '/lee-kwang-soo-trong-mat-nsx-running-man-truoc-gio-chia-tay-cau-ay-khong-phai-la-do-ngoc',
component: 'page_20210614092016'},
{title: "Thần thú Niên từng bị Hồng Quân Lão Tổ đánh bại trong thần thoại Trung Hoa quyền lực ra sao?",
description: "Theo truyền thuyết Trung Hoa, Niên đã bị Hồng Quân Lão Tổ đánh bại và chấp nhận trở thành vật cưỡi của ông.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/11/thelegendarynian0-16233835237231846806261.jpg',
alt: "Kham Pha, Than Thoai, Van Hoa, Nien, Truyen Thuyet Dan Gian, ",
category: 'news',
time: '01:11 AM',
date: '11/06/2021',
timestamp: '11/06/2021 01:11 AM',
link: '/than-thu-nien-tung-bi-hong-quan-lao-to-danh-bai-trong-than-thoai-trung-hoa-quyen-luc-ra-sao',
component: 'page_20210611011104'},
{title: "[Trực tiếp từ UAE] Buổi tập đầu tiên của tuyển Việt Nam sau trận gặp Indonesia: Tuấn Anh, Văn Toàn cùng có mặt",
description: "Vào lúc 22h ngày 8/6, đội tuyển Việt Nam sẽ có buổi tập trên sân Shabab Al-ahli.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/untitled-1623165495924715520315.png',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Viet Nam, Tuyen Viet Nam, Tuan Anh, Van Toan, Viet Nam Nghien Nat Indonesia 4 0, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/truc-tiep-tu-uae-hinh-anh-buoi-tap-dau-tien-cua-tuyen-viet-nam-sau-tran-gap-indonesia-nin-tho-huong-ve-tuan-anh-van-toan',
component: 'page_20210608140647'},
{title: "Top 5 cầu thủ Việt có lượng người theo dõi khủng nhất trên Facebook, cái tên \"mlem mlem\" Đoàn Văn Hậu xếp thứ mấy?",
description: "Sau trận thắng 4-0 trước đội tuyển Indonesia, Facebook các cầu thủ Việt Nam được nhiều người chú ý đến, thấp nhất cũng được cả triệu người follow!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-16231527937981291522408.jpg',
alt: "Doi Tuyen Viet Nam, Cau Thu Viet Nam, Vong Loai World Cup, World Cup 2022, Nguyen Quang Hai, Luong Xuan Truong, Doan Van Hau, Cong Phuong, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/top-5-cau-thu-viet-co-luong-nguoi-theo-doi-khung-nhat-tren-facebook-cai-ten-mlem-mlem-doan-van-hau-xep-thu-may',
component: 'page_20210608140634'},
{title: "Dàn trai đẹp U23 sau 3 năm: Đồng loạt lấy vợ có con nhưng độ \"mlem mlem\" không hề suy giảm!",
description: "Bạn đã sẵn sàng nhìn lại sự thay đổi của những cầu thủ từng được cả Việt Nam hâm mộ cuồng nhiệt sau 3 năm chưa!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/1-16231658569281054746604.png',
alt: "Doi Tuyen Bong Da, Doi Tuyen Bong Da Viet Nam, World Cup 2022, U23 Viet Nam, Doi Tuyen U23 Viet Nam, Quang Hai, Doan Van Hau, Cong Phuong, Vong Loai World Cup 2022, Bui Tien Dung, Hong Duy, Duy Manh, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/dan-trai-dep-u23-sau-3-nam-dong-loat-lay-vo-co-con-nhung-do-mlem-mlem-khong-he-suy-giam',
component: 'page_20210608140622'},
{title: "Báo Thái Lan bất ngờ “oán trách”, cho rằng tuyển Việt Nam quá mạnh khiến đội nhà bị loại",
description: "Truyền thông Thái Lan cho rằng chính sự xuất sắc của tuyển Việt Nam đã góp phần khiến đội nhà phải sớm chấm dứt giấc mơ World Cup.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-162314340016446582388.jpg',
alt: "Thai Lan, Viet Nam, Vong Loai World Cup 2022, ",
category: 'news',
time: '2:06 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:06 PM',
link: '/bao-thai-lan-bat-ngo-oan-trach-cho-rang-tuyen-viet-nam-qua-manh-khien-doi-nha-bi-loai',
component: 'page_20210608140609'},
{title: "Báo Indonesia quay sang oán trách đội nhà, điểm mặt 6 cái tên đá tồi nhất",
description: "Tờ Bola chỉ mặt điểm tên 6 cầu thủ chơi dưới kỳ vọng trong trận Indonesia thua Việt Nam 0-4.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/9/photo-5-16231748625421736336327.png',
alt: "Indonesia, Viet Nam, Dt Viet Nam, Vong Loai World Cup 2022, ",
category: 'news',
time: '2:05 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:05 PM',
link: '/bao-indonesia-quay-sang-oan-trach-doi-nha-diem-mat-6-cai-ten-da-toi-nhat',
component: 'page_20210608140554'},
{title: "6 bí kíp siêu hữu ích mà ai cũng cần học, vì chúng có thể cứu lấy bạn vào một ngày nào đó",
description: "Bạn có thể rơi vào những hoàn cảnh hiểm nghèo, hoặc đơn giản là các tình huống khiến một ngày trở nên khó khăn hơn. Làm thế nào lúc đó nhỉ?",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/8/photo-1-1623131702795545224846.jpg',
alt: "Bi Kip, Chua Chay, Tinh Huong Xau Nhat, ",
category: 'news',
time: '2:05 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:05 PM',
link: '/6-bi-kip-sieu-huu-ich-ma-ai-cung-can-hoc-vi-chung-co-the-cuu-lay-ban-vao-mot-ngay-nao-do',
component: 'page_20210608140543'},
{title: "Bố tiền vệ Tuấn Anh: Con tôi nhiều lần chấn thương rồi, nhìn Indonesia đá thế thấy sợ quá",
description: "Chỉ sau 35 phút thi đấu, Nguyễn Tuấn Anh đã phải rời sân. Trước đó, tiền vệ này liên tục phải chịu những pha vào bóng ác ý từ phía các cầu thủ Indonesia.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-16230977964252088751721.png',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Tuyen Viet Nam, Park Hang Seo, ",
category: 'news',
time: '06:39 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:39 PM',
link: '/bo-tien-ve-tuan-anh-con-toi-nhieu-lan-chan-thuong-roi-nhin-indonesia-da-the-thay-so-qua',
component: 'page_20210607183934'},
{title: "Cả MXH không ngủ để cổ vũ ĐT Việt Nam đá bóng: Gay cấn quá rồi",
description: "Có đang coi đá bóng \"khum\" mọi người ơi!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/base64-16230865264841352253184.png',
alt: "Dt Viet Nam, Viet Nam Da Bong, Indonesia, ",
category: 'news',
time: '06:39 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:39 PM',
link: '/ca-mxh-khong-ngu-de-co-vu-dt-viet-nam-da-bong-gay-can-qua-roi',
component: 'page_20210607183917'},
{title: "Cầu thủ Indonesia chắp tay xin lỗi Tuấn Anh trong đường hầm vì phạm lỗi nguy hiểm",
description: "Nguyễn Tuấn Anh bị Pratama Arhan Alif phạm lỗi nguy hiểm là tình huống đáng chú ý nhất hiệp 1 trận tuyển Việt Nam gặp Indonesia vào tối 7/6.",
src: 'https://sport5.mediacdn.vn/thumb_w/700/158855217956261888/2021/6/8/2021-6-7-doi-tuyen-viet-nam-doi-tuyen-indonesia-hieu-luong-21-1623109628124627525171.jpg',
alt: "Cau Thu Indonesia, Tuan Anh, Vong Loai World Cup, Tuyen Viet Nam, Uae, Viet Nam Thang Indonesia, ",
category: 'news',
time: '06:39 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:39 PM',
link: '/cau-thu-indonesia-chap-tay-xin-loi-tuan-anh-trong-duong-ham-vi-pham-loi-nguy-hiem',
component: 'page_20210607183906'},
{title: "Chùm ảnh: Ối giời ơi Văn Hậu đẹp trai quá!",
description: "Càng ngắm càng mê, hihi!",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/6/8/2021-5-27-doi-tuyen-viet-nam-tap-luyen-uae-sport5-hieu-luong-40-16221656002181077713714-162309236235551010895.jpg',
alt: "Van Hau, Doan Van Hau, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Nam Than, Dan Nam Than Doi Tuyen Viet Nam, ",
category: 'news',
time: '06:38 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:38 PM',
link: '/chum-anh-oi-gioi-oi-van-hau-dep-trai-qua',
component: 'page_20210607183855'},
{title: "Chứng kiến cầu thủ Indonesia liên tục vào bóng triệt hạ, \"Nam vương\" boxing Trương Đình Hoàng hiến kế cho thầy trò HLV Park Hang-seo",
description: "Nhà vô địch WBA châu Á Trương Đình Hoàng muốn các cầu thủ của tuyển Việt Nam học qua một khóa boxing để không còn ngại những đối thủ chơi bóng thô bạo.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-16230975822461769638760.png',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Vong Loai World Cup 2022, Tuyen Viet Nam, ",
category: 'news',
time: '06:38 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:38 PM',
link: '/chung-kien-cau-thu-indonesia-lien-tuc-vao-bong-triet-ha-nam-vuong-boxing-truong-dinh-hoang-hien-ke-cho-thay-tro-hlv-park-hang-seo',
component: 'page_20210607183844'},
{title: "Duy Mạnh bóp cổ cầu thủ Indonesia sau pha phạm lỗi nguy hiểm với Tuấn Anh",
description: "Các cầu thủ Indonesia liên tục phạm lỗi bằng những pha vào bóng quyết liệt quá mức cần thiết đối với các tuyển thủ Việt Nam.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-3-16230894305991210492944.jpg',
alt: "Duy Manh, Quang Haii, Doi Tuyen Viet Nam, Cau Thu Indonesia, ",
category: 'news',
time: '06:36 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:36 PM',
link: '/duy-manh-bop-co-cau-thu-indonesia-sau-pha-pham-loi-nguy-hiem-voi-tuan-anh',
component: 'page_20210607183631'},
{title: "Fan Việt Nam chế loạt ảnh hài hước sau trận thắng Indonesia",
description: "Đội tuyển Việt Nam giành chiến thắng dễ dàng 4-0 trước đội tuyển Indonesia. Fan Việt Nam được dịp chế ảnh \"cà khịa\" lối đá thô bạo của thầy trò HLV Shin Tae-yong.",
src: 'https://sport5.mediacdn.vn/thumb_w/700/158855217956261888/2021/6/8/19348880914978784438780196833412912387709210n-1623093367726564978067.jpg',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, World Cup 2022, Tuyen Viet Nam Indonesia, Bong Da, ",
category: 'news',
time: '06:36 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:36 PM',
link: '/fan-viet-nam-che-loat-anh-hai-huoc-sau-tran-thang-indonesia',
component: 'page_20210607183618'},
{title: "Ngã ngửa nhan sắc thật của dàn sao ở sự kiện Thập Quang: Nhiệt Ba gây sốc vì da chảy xệ, Lưu Diệc Phi gây thất vọng tràn trề",
description: "Xuất hiện lộng lẫy tại sự kiện Thập Quang Thịnh Điển ngày 7/6, dàn sao Hoa ngữ như Địch Lệ Nhiệt Ba, Dương Mịch, Lưu Diệc Phi, Ngô Lỗi, Trần Hiểu,... bị dân tình soi nhan sắc nhiệt tình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/-1623061473587678019572.jpg',
alt: "Dich Le Nhiet Ba, Luu Diec Phi, Ngo Loi, La Tan, Truong Vu Ky, Tran Hieu, Tieu Chien, Phan Viet Minh, Truong Hue Van, Bach Bach Ha, Sao Hoa Ngu, My Nhan Hoa Ngu, Nhan Sac My Nhan Hoa Ngu, Mao Hieu Dong, Duong Mich, Boc Tran Nhan Sac Cua Sao, Nhan Sac That Cua Sao, ",
category: 'news',
time: '06:36 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:36 PM',
link: '/nga-ngua-nhan-sac-that-cua-dan-sao-o-su-kien-thap-quang-nhiet-ba-gay-soc-vi-da-chay-xe-luu-diec-phi-gay-that-vong-tran-tre',
component: 'page_20210607183602'},
{title: "Những khoảnh khắc tuyệt đẹp của tuyển Việt Nam khiến người hâm mộ \"đổ rần rần\"",
description: "Một chiến thắng ngọt ngào đầy bản lĩnh 4-0 trước Indonesia minh chứng cho sự nỗ lực không ngừng nghỉ của tuyển Việt Nam. Thầy trò HLV Park Hang-seo đã mạnh mẽ vượt qua thử thách đầu tiên trên đất UAE.",
src: 'https://sport5.mediacdn.vn/158855217956261888/2021/6/8/6789cf399f7b6b25326a-16230908905311280246809.jpg',
alt: "Nhan Hieu Danh Cho Nam, Dong Hanh Cung Dtqgvn, Mensbiore, ",
category: 'news',
time: '06:34 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:34 PM',
link: '/nhung-khoanh-khac-tuyet-dep-cua-tuyen-viet-nam-khien-nguoi-ham-mo-do-ran-ran',
component: 'page_20210607183435'},
{title: "Nổi cáu trước trọng tài, nhưng HLV Park Hang-seo vô cùng tình cảm ôm học trò sau trận thắng Indonesia",
description: "HLV Park Hang-seo luôn có những khoảnh khắc đầy thú vị từ việc nổi cáu với trọng tài đến cử chỉ tình cảm, ấm áp dành cho học trò.",
src: 'https://sport5.mediacdn.vn/158855217956261888/2021/6/8/3a7829fa76b882e6dba9-16230937312841517797883.jpg',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Tuyen Viet Nam, Park Hang Seo, Bong Da, ",
category: 'news',
time: '06:34 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:34 PM',
link: '/noi-cau-truoc-trong-tai-nhung-hlv-park-hang-seo-vo-cung-tinh-cam-om-hoc-tro-sau-tran-thang-indonesia',
component: 'page_20210607183422'},
{title: "TIẾN LINH - Cầu thủ \"mlem mlem\" chưa có bồ hot nhất hôm nay là ai?",
description: "Với pha ghi bàn mở màn cho đội tuyển Việt Nam, cái tên Tiến Linh đang chiếm trọn spotlight trên MXH.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/anh-chup-man-hinh-2021-06-08-luc-005815-16230888291721087952933.png',
alt: "Cau Thu Dep Trai, Doi Tuyen Viet Nam, Tien Linh, Tien Linh Ghi Ban, Tien Linh Ghi Man Mo Man, Viet Nam Vs Indonesia, Viet Nam Vs Indonesia Tai Vong Loai World Cup, Viet Nam Vs Indonesia Vong Loai World Cup 2022, ",
category: 'news',
time: '06:34 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:34 PM',
link: '/tien-linh-cau-thu-mlem-mlem-chua-co-bo-hot-nhat-hom-nay-la-ai',
component: 'page_20210607183406'},
{title: "Top 10 Hoa hậu Việt Nam thức cả đêm cổ vũ Đoàn Văn Hậu thi đấu, như này không yêu phí quá",
description: "Trên Instagram cá nhân, Doãn Hải My đã đăng story khoảnh khắc về Đoàn Văn Hậu và đồng đội đồng thời bày tỏ niềm tự hào.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/1-1623093543733411031890.png',
alt: "Doan Hai My, Doan Van Hau, Dt Viet Nam, Hen Ho, Ban Gai Tin Don, Top 10 Hoa Hau Viet Nam, ",
category: 'news',
time: '06:33 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:33 PM',
link: '/top-10-hoa-hau-thuc-ca-dem-co-vu-doan-van-hau-nhu-nay-khong-yeu-phi-qua',
component: 'page_20210607183356'},
{title: "Trận bóng đi qua, BLV Biên Cương để lại \"rổ\" quote: Đây không phải bóng đá, đây là võ thuật!",
description: "Những phát biểu của BLV Biên Cương luôn thu hút sự chú ý của khán giả.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/10-1623091570504298968086.jpg',
alt: "Blv Bien Cuong, Dt Viet Nam, Da Bong, Bien Cuong, Dua Con Cua Than Gio, ",
category: 'news',
time: '06:33 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:33 PM',
link: '/tran-bong-qua-di-btv-bien-cuong-de-lai-ro-quote-day-khong-phai-bong-da-day-la-vo-thuat',
component: 'page_20210607183344'},
{title: "Văn Toàn được đồng đội cõng lên xe trở về khách sạn sau trận thắng Indonesia",
description: "Hình ảnh Văn Toàn được Văn Toản cõng lên xe buýt của đội tuyển Việt Nam sau trận thắng Indonesia đang khiến fan vô cùng lo lắng.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-1623097159194116242689.jpg',
alt: "Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, Tuyen Viet Nam, Van Toan, Cong Phuong, ",
category: 'news',
time: '06:33 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:33 PM',
link: '/van-toan-duoc-dong-doi-cong-len-xe-tro-ve-khach-san-sau-tran-thang-indonesia',
component: 'page_20210607183313'},
{title: "Xúc động hình ảnh tuyển Việt Nam đặt tay lên ngực trái, thực hiện lễ chào cờ sau gần 2 năm không thi đấu quốc tế",
description: "Đã gần 2 năm, đội tuyển Việt Nam mới lại có một trận đấu quốc tế chính thức mang lại nhiều cảm xúc như vậy.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/8/photo-1-1623088048557732454516.jpg',
alt: "Indonesia, Viet Nam, Viet Nam Vs Indonesia, Tuyen Viet Nam Tham Du Vong Loai World Cup 2022, ",
category: 'news',
time: '06:32 PM',
date: '07/06/2021',
timestamp: '07/06/2021 06:32 PM',
link: '/xuc-dong-hinh-anh-tuyen-viet-nam-dat-tay-len-nguc-trai-thuc-hien-le-chao-co-sau-gan-2-nam-khong-thi-dau-quoc-te',
component: 'page_20210607183256'},
{title: "Fan xúc động khi biết ý nghĩa dãy số trên áo Lee Kwang Soo trong tập cuối ghi hình Running Man",
description: "Ê-kíp Running Man khiến người hâm mộ rất cảm động khi tinh tế đặt con số ý nghĩa này trong tập cuối chia tay Lee Kwang Soo.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-2-1622994189590992603592-16230504527311881116045.jpeg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man Sau 11 Nam, Running Man, ",
category: 'news',
time: '10:39 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:39 AM',
link: '/fan-xuc-dong-khi-biet-y-nghia-day-so-tren-ao-lee-kwang-soo-trong-tap-cuoi-ghi-hinh-running-man',
component: 'page_20210607103948'},
{title: "Diện váy xẻ ngực sâu táo bạo, Dương Mịch khiến \"người tình\" Hứa Khải bối rối, không dám nhìn ngay trên sân khấu",
description: "Rõ ràng rất thân thiết với nhau trên phim trường nhưng Hứa Khải tỏ ra xa cách với Dương Mịch tại sự kiện hôm nay.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-1-16230572341301297509824.jpg',
alt: "Duong Mich, Sao Hoa Ngu, My Nhan Hoa Ngu, Nhan Sac My Nhan Hoa Ngu, Voc Dang My Nhan Hoa Ngu, ",
category: 'news',
time: '10:39 AM',
date: '07/06/2021',
timestamp: '07/06/2021 10:39 AM',
link: '/dien-vay-xe-nguc-sau-tao-bao-duong-mich-khien-nguoi-tinh-hua-khai-boi-roi-khong-dam-nhin-ngay-tren-san-khau',
component: 'page_20210607103935'},
{title: "Song Ji Hyo bất ngờ nhảy bổ ôm chặt Jong Kook, \"thuyền SpartAce\" lần nữa ra khơi!",
description: "Hành động thân mật giữa Song Ji Hyo - Kim Jong Kook khiến fan cực kỳ thích thú!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/7/photo-1-16230057709232088822568.jpg',
alt: "Running Man, Song Ji Hyo, Kim Jong Kook, Lee Kwang Soo, Yoo Jae Suk, Spartace, ",
category: 'news',
time: '12:37 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:37 AM',
link: '/song-ji-hyo-bat-ngo-nhay-bo-om-chat-jong-kook-thuyen-spartace-lan-nua-ra-khoi',
component: 'page_20210607003730'},
{title: "Những mẩu chuyện cảm động khiến fan cứng Doraemon phải bật khóc",
description: "Những câu chuyện tuy ngắn nhưng được tác giả xây dựng rất tinh tế và đáng suy ngẫm trong Doraemon",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/7/19019348518284753639987308299421896979616123n-16230411840921566000971.jpg',
alt: "Kham Pha, Manga, Anime, Doraemon, Giai Tri, Xuat Ban, ",
category: 'news',
time: '12:35 AM',
date: '07/06/2021',
timestamp: '07/06/2021 12:35 AM',
link: '/nhung-mau-chuyen-cam-dong-khien-fan-cung-doraemon-phai-bat-khoc',
component: 'page_20210607003540'},
{title: "Cảnh nóng bị nghi thật 100% làm Thang Duy bị \"phong sát\": Vợ chồng Lương Triều Vỹ lục đục, nữ chính có chia sẻ gây sốc",
description: "Cho đến hiện tại, cảnh nóng của Thang Duy và Lương Triều Vỹ trong Sắc, Giới vẫn khiến khán giả tranh cãi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/6/lust-caution-547b68e25e0a628-07-202004g33-13-1622995935799375128902.jpg',
alt: "Sac Gioi, Sac Gioi, Sac Gioi 2008, Luong Trieu Vy, Thang Duy, Luu Gia Linh, Ly An, Canh Nong, Canh 18, Phim Hoa Ngu, Kim Ma, Phim Trung Quoc, Phim Dien Anh, Phim Chieu Rap, ",
category: 'news',
time: '07:43 PM',
date: '06/06/2021',
timestamp: '06/06/2021 7:43 PM',
link: '/canh-nong-bi-nghi-that-100-lam-thang-duy-bi-phong-sat-vo-chong-luong-trieu-vy-luc-duc-nu-chinh-co-chia-se-gay-soc',
component: 'page_20210606194311'},
{title: "Lee Kwang Soo và hành trình ở Running Man: Khởi đầu bằng nước mưa, kết thúc đẫm nước mắt",
description: "Sau 11 năm đồng hành cùng Running Man, Lee Kwang Soo cuối cùng cũng phải nói lời chia tay ngôi nhà thứ 2 của mình.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/6/photo-2-1622994189590992603592.jpg',
alt: "Lee Kwang Soo, Lee Kwang Soo Roi Running Man, Sao Han, Kpop, Running Man, ",
category: 'news',
time: '07:42 PM',
date: '06/06/2021',
timestamp: '06/06/2021 7:42 PM',
link: '/lee-kwang-soo-va-hanh-trinh-o-running-man-khoi-dau-bang-nuoc-mua-ket-thuc-dam-nuoc-mat',
component: 'page_20210606194256'},
{title: "Apple cập nhật AirTags sau những lo ngại rình rập",
description: "Apple đã phát hành một bản cập nhật phần mềm cho AirTags sau những lo ngại rằng chúng có thể được sử dụng để theo dõi mọi người một cách bí mật.",
src: 'https://ichef.bbci.co.uk/news/800/cpsprodpb/6A4A/production/_118801272_gettyimages-1315307818.jpg',
alt: "Apple, Airtags",
category: 'news',
time: '11:29 AM',
date: '06/06/2021',
timestamp: '06/06/2021 11:29 AM',
link: '/apple-cap-nhat-airstags-sau-nhung-lo-ngai-rinh-rap',
component: 'page_20210606112900'},
{title: "9 con số cho thấy thế giới này còn rất nhiều sự thật siêu thú vị mà bạn chưa bao giờ biết tới",
description: "Chẳng hạn, bạn có biết mỗi ngày nước Mỹ có bao nhiêu triệu phú xuất hiện không? Nó lớn hơn bạn tưởng nhiều đấy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/6/6/photo-1-16229557224491540876688.jpg',
alt: "Su That, Thu Vi, ",
category: 'news',
time: '08:47 AM',
date: '06/06/2021',
timestamp: '06/06/2021 8:47 AM',
link: '/9-con-so-cho-thay-the-gioi-nay-con-rat-nhieu-su-that-sieu-thu-vi-ma-ban-chua-bao-gio-biet-toi',
component: 'page_20210606084757'},
{title: "Khám phá thị trường quảng cáo game nửa đầu năm 2021",
description: "Game di động vẫn là lựa chọn hàng đầu của nhiều người bởi vì tính tiện lợi của nó.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/3/-1622697635625506697225.jpg',
alt: "Game, Game Thu, Tin Tuc Game, ",
category: 'news',
time: '04:43 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:43:00',
link: '/kham-pha-thi-truong-quang-cao-game-nua-dau-nam-2021',
component: 'page_20210604044355'},
{title: "Ghim ngay những câu nói truyền cảm hứng trong Haikyuu!! để \"đếch sợ gì hết\" mà tiến lên",
description: "Dưới đây là một số câu nói đã tạo nên tiếng vang của anime/manga Haikyuu!!.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/4/photo-1-16227860348861596575077.jpg',
alt: "Haikyuu, Chang Khong Lo Ti Hon, Cau Noi Hay Haikyuu, Ukai Keishin, ",
category: 'news',
time: '04:43 PM',
date: '04/06/2021',
timestamp: '04/06/2021 16:43:00',
link: '/ghim-ngay-nhung-cau-noi-truyen-cam-hung-trong-haikyuu-de-dech-so-gi-het-ma-tien-len',
component: 'page_20210604044318'},
{title: "Ken Shimizu chỉ ra \"đối tác\" lợi hại nhất từng gặp, khiến anh chàng phải đầu hàng chỉ sau 3 phút, không dám đóng cùng lần hai",
description: "Tới cả Ken Shimizu cũng phải nể phục cô nàng này thì có lẽ chúng ta cũng đủ hiểu độ \"bá đạo\" rồi đấy.",
src: 'https://gamek.mediacdn.vn/133514250583805952/2021/5/31/photo-1-16224524093421609918764.png',
alt: "Gai Xinh",
category: 'news',
time: '05:45 PM',
date: '03/06/2021',
timestamp: '03/06/2021 17:45:00',
link: '/ken-shimizu-chi-ra-doi-tac-loi-hai-nhat-tung-gap-khien-anh-chang-phai-dau-hang-chi-sau-3-phut-khong-dam-dong-cung-lan-hai',
component: 'page_20210603054509'},
{title: "Gấu Kami, nữ streamer gần 7 triệu lượt follow TikTok bị giả mạo tài khoản hàng loạt, thậm chí bị lợi dụng hình ảnh để lừa đảo",
description: "Gấm Kami - nữ streamer/TikToker cực hot hiện nay đang bị nhiều kẻ gian giả mạo tài khoản TikTok và mục đích không chỉ là câu view",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/2/photo-1-1622620397947621513281.jpg',
alt: "Gấu Kami",
category: 'news',
time: '03:05 AM',
date: '03/06/2021',
timestamp: '03/06/2021 03:05:00',
link: '/gau-kami-nu-streamer-gan-7-trieu-luot-follow-tiktok-bi-gia-mao-tai-khoan-hang-loat-tham-chi-bi-loi-dung-hinh-anh-de-lua-dao',
component: 'page_202106030305'},
{title: "One Piece: Dù đã chết nhưng sức mạnh của 6 nhân vật huyền thoại này cho đến nay vẫn chưa ai vượt qua nổi",
description: "Thế hệ hải tặc mới vẫn chưa ai có thể vượt qua được những tượng đài sức mạnh trong One Piece này.",
src: 'https://gamek.mediacdn.vn/thumb_w/640/133514250583805952/2021/6/2/photo-1-16226084298312007960851.jpg',
alt: "One Piece",
category: 'news',
time: '12:23 PM',
date: '02/06/2021',
timestamp: '02/06/2021 12:23:00',
link: '/one-piece-du-da-chet-nhung-suc-manh-cua-6-nhan-vat-huyen-thoai-nay-cho-den-nay-van-chua-ai-vuot-qua-noi',
component: 'page_202106021223'},
]
