export const data_life=[{title: "8 ý tưởng biến ban công nhàm chán thành góc chill mùa dịch, cái nào cũng chất khỏi bàn",
description: "Đảm bảo tân trang xong bạn sẽ muốn \"làm tổ\" ở ban công mãi cho mà xem.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/28/photo-1-1630139239385988586212.jpeg',
alt: "ban công,decor nhà,",
category: 'life',
time: '08:50 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:50 AM',
link: '/8-y-tuong-bien-ban-cong-nham-chan-thanh-goc-chill-mua-dich-cai-nao-cung-chat-khoi-ban',
component: 'page_20210828085014'},
{title: "Ngôi biệt thự đẹp tựa như bước ra từ thước phim điện ảnh khiến dân mạng trầm trồ không ngớt",
description: "Căn nhà được xây với tổng diện tích 250m2, gồm 3 phòng ngủ, với kiến trúc mang phong cách Bắc Mỹ: Phóng khoáng, mạnh mẽ và hoang dã khiến ai nhìn vào cũng phải xuýt xoa.",
src: 'https://kenh14cdn.com/thumb_w/800/pr/2021/collage-1629710248346833549437-0-0-599-958-crop-1629710256340-63765334214461.jpg',
alt: "Biệt thự đẹp,phim điện ảnh,đáp ứng nhu cầu,",
category: 'life',
time: '08:36 AM',
date: '28/08/2021',
timestamp: '28/08/2021 08:36 AM',
link: '/ngoi-biet-thu-dep-tua-nhu-buoc-ra-tu-thuoc-phim-dien-anh-khien-dan-mang-tram-tro-khong-ngot',
component: 'page_20210828083638'},
{title: "4 đặc điểm thường có ở những cô nàng rất dễ thụ thai ngay trong lần \"thả\" đầu tiên, xem thử bạn có thuộc nhóm này hay không",
description: "Khi hội tụ đủ 4 yếu tố sau đây thì khả năng có thai sau khi quan hệ là rất cao đó bạn nhé!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/19/photo-1-16293916769601099805699.jpg',
alt: "Sức khỏe sinh sản,sức khỏe thể chất,thời điểm rụng trứng,nguy cơ vô sinh,tế bào mỡ,",
category: 'life',
time: '01:04 PM',
date: '19/08/2021',
timestamp: '19/08/2021 01:04 PM',
link: '/4-dac-diem-thuong-co-o-nhung-co-nang-rat-de-thu-thai-ngay-trong-lan-tha-dau-tien-xem-thu-ban-co-thuoc-nhom-nay-hay-khong',
component: 'page_20210819130430'},
{title: "Hình mẫu “cha mẹ trực thăng”: Tưởng tốt nhưng thực chất đang huỷ hoại tương lai và cuộc sống của con cái mình",
description: "Cha mẹ nào cũng muốn con mình hạnh phúc và làm điều tốt cho bản thân nhưng việc trở thành \"cha mẹ trực thăng\" đôi khi có thể phản tác dụng và gây hại nhiều hơn lợi.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/17/photo-1-16291622629061133102157.jpg',
alt: "phản tác dụng,kỳ thi tuyển sinh,thi tuyển sinh đại học,nhà tâm lý học,bậc cha mẹ,Phương pháp giáo dục con cái,Bài học dạy con,",
category: 'life',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/hinh-mau-cha-me-truc-thang-tuong-tot-nhung-thuc-chat-dang-huy-hoai-tuong-lai-va-cuoc-song-cua-con-cai-minh',
component: 'page_20210819125622'},
{title: "\"Giải ngố\" 18+: 4 thời điểm không nên làm chuyện ấy vì rất dễ gây tổn hại tới sức khỏe, nhất là đối với phái nữ",
description: "Việc thường xuyên quan hệ trong những khoảng thời gian sau đây đều không hề tốt cho sức khỏe chút nào. Do đó, bạn cần chú ý để tránh mắc phải nhé!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/16/photo-1-16291329638351675332416.jpg',
alt: "Giải ngố 18+,đồng hồ sinh học,bạn có biết?,kỳ kinh nguyệt,",
category: 'life',
time: '12:56 PM',
date: '19/08/2021',
timestamp: '19/08/2021 12:56 PM',
link: '/giai-ngo-18-4-thoi-diem-khong-nen-lam-chuyen-ay-vi-rat-de-gay-ton-hai-toi-suc-khoe-nhat-la-doi-voi-phai-nu',
component: 'page_20210819125614'},
{title: "Bây giờ chả ai gửi tiết kiệm nữa, thế họ làm gì để tiền đẻ ra tiền?",
description: "Không một tài sản nào trên đời sẽ đến một cách dễ dàng: Đầu tư không phải là con đường tắt đến với sự giàu có.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/1-16290387435451827783682.png',
alt: "Có tiền thì làm gì,đầu tư,tiết kiệm,người trẻ,giới trẻ,đế chế sổ tiết kiệm,ma trận đầu tư,bài học tài chính,tài chính,quản lý tài chính,",
category: 'life',
time: '04:07 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:07 PM',
link: '/bay-gio-cha-ai-gui-tiet-kiem-nua-the-ho-lam-gi-de-tien-de-ra-tien',
component: 'page_20210815160712'},
{title: "Người gan kém thường có 3 biểu hiện đặc trưng khi đi ngủ, mong rằng bạn không xuất hiện bất kỳ cái nào",
description: "Gan khỏe cơ thể mới khỏe, còn gan xấu thì bạn sẽ rất dễ bị trằn trọc, khó ngủ vào ban đêm.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/15/photo-1-16290464450851819138154.jpg',
alt: "chịu trách nhiệm,máu lưu thông,",
category: 'life',
time: '04:06 PM',
date: '15/08/2021',
timestamp: '15/08/2021 04:06 PM',
link: '/nguoi-gan-kem-thuong-co-3-bieu-hien-dac-trung-khi-di-ngu-mong-rang-ban-khong-xuat-hien-bat-ky-cai-nao',
component: 'page_20210815160656'},
{title: "Cải tạo căn hộ 67m2 với chi phí 230 triệu: Thiết kế toàn đường cong mềm mại, khu bếp - bàn ăn nối liền cực hay ho",
description: "Căn hộ được cải tạo theo phong cách hiện đại, tươi trẻ với mức chi phí hợp lý.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/14/z267484202555272d45d3b8d7c1833ee2c4c0f56e0da67-16289359953141728239480.jpg',
alt: "Căn hộ chung cư,phong cách hiện đại,tranh treo tường,Trang trí nội thất,gam màu trắng,nhà vệ sinh,không gian sinh hoạt,Đồ nội thất,bàn đa năng,Tủ quần áo,bàn trang điểm,Cải tạo nhà,house n home,",
category: 'life',
time: '11:39 AM',
date: '14/08/2021',
timestamp: '14/08/2021 11:39 AM',
link: '/cai-tao-can-ho-67m2-voi-chi-phi-230-trieu-thiet-ke-toan-duong-cong-mem-mai-khu-bep-ban-an-noi-lien-cuc-hay-ho',
component: 'page_20210814113959'},
{title: "Vì sao những người ưu tú luôn đặt báo thức lúc 5:57 sáng?",
description: "Lên kế hoạch chuẩn bị trước không nằm ở chỗ bạn đã làm bao nhiêu việc, mà là sau khi bạn nhận thức được việc này, bạn sẽ có được trạng thái năng lượng cao để làm việc. Cuộc sống của bạn cũng từ đó mà có một diện mạo hoàn toàn mới.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/12/photo-1-1628772521685332629050.jpg',
alt: "hãng truyền thông,doanh nhân nổi tiếng,thời gian làm việc,quản lý cấp cao,năng lượng cơ thể,",
category: 'life',
time: '05:31 PM',
date: '12/08/2021',
timestamp: '12/08/2021 05:31 PM',
link: '/vi-sao-nhung-nguoi-uu-tu-luon-dat-bao-thuc-luc-557-sang',
component: 'page_20210812173151'},
{title: "Lệ Quyên đáp trả khi loạt \"ảnh cưới\" chụp với Lâm Bảo Châu bị mỉa mai \"như hai mẹ con\"",
description: "Trên trang cá nhân, Lệ Quyên chia sẻ đầy ẩn ý về loạt khoảnh khắc được dân tình xôn xao là ảnh cưới.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/11/3-16278737249272036986130-16286560802871304075358-1628683934743661208012.png',
alt: "lệ quyên,Lệ Quyên và Lâm Bảo Châu,Lệ Quyên và Lâm Bảo Châu hẹn hò,Lệ Quyên ly hôn,con trai lệ quyên,ca sĩ lệ quyên,Lâm Bảo Châu,sao hẹn hò,sao Việt,showbiz Việt,",
category: 'life',
time: '11:00 AM',
date: '11/08/2021',
timestamp: '11/08/2021 11:00 AM',
link: '/le-quyen-dap-tra-khi-loat-anh-cuoi-chup-voi-lam-bao-chau-bi-mia-mai-nhu-hai-me-con',
component: 'page_20210811110028'},
{title: "Trốn Sài Gòn, vợ chồng về quê Phú Yên xây nhà style Địa Trung Hải, dành hẳn 40m2 làm hồ bơi cho con",
description: "Ngôi nhà gây ấn tượng với khoảng sân rộng thênh thang, hồ bơi mát lạnh cùng đất trồng hoa và rau trái xung quanh.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/8/9/21376005811750267596689093140582484154896113n-16284837570881616624042.jpeg',
alt: "khoe nhà đẹp,house n home,phong cách địa trung hải,thiết kế nhà,xây nhà,làm nhà,kiến trúc sư,Phú Yên,",
category: 'life',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/tron-sai-gon-vo-chong-ve-que-phu-yen-xay-nha-style-dia-trung-hai-danh-han-40m2-lam-ho-boi-cho-con',
component: 'page_20210811105154'},
{title: "Vì sao trong lễ cưới, các cô dâu thường đeo khăn trùm đầu?",
description: "Hơn cả một món phụ kiện, chiếc khăn trùm đầu của cô dâu còn có ý nghĩa vô cùng lớn lao.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/8/10/elora1-16285882056331409566607.jpg',
alt: "khăn trùm đầu,lễ cưới,ý nghĩa,cô dâu,phụ kiện,",
category: 'life',
time: '10:51 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:51 AM',
link: '/vi-sao-trong-le-cuoi-cac-co-dau-thuong-deo-khan-trum-dau',
component: 'page_20210811105100'},
{title: "Ăn gì để ngăn ngừa nguy cơ mắc bệnh gút gây đau sưng tứ chi? Ăn cơm là đủ rồi, cần chi cao sang",
description: "Ngày càng có nhiều người trẻ mắc bệnh gút, căn bệnh gây ra những cơn đau, sưng viêm tứ chi khiến bạn phải \"sống dở chết dở\". Để ngăn ngừa nguy cơ đối mặt với nó, chúng ta đều biết cần giữ cho mình 1 chế độ ăn lành mạnh nhưng liệu bạn có biết rằng chỉ cần... ăn cơm là đủ \"kháng\" gút?",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285955955311001452446.jpg',
alt: "bệnh gút,gạo ong biển,",
category: 'life',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/an-gi-de-ngan-ngua-nguy-co-mac-benh-gut-gay-dau-sung-tu-chi-an-com-la-du-roi-can-chi-cao-sang',
component: 'page_20210811105031'},
{title: "3 bộ phận trên cơ thể nam giới càng cọ rửa sạch sẽ khi tắm thì thận càng khỏe mạnh, sức khỏe dồi dào",
description: "Tắm là việc mà nhiều người làm hàng ngày, đặc biệt là trong trường hợp ra nhiều mồ hôi. Bên cạnh đó, nếu nam giới chú ý cọ rửa kĩ ở 1 số bộ phận khi tắm thì rất có lợi cho sức khỏe.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/10/photo-1-16285841882251407951264.jpeg',
alt: "tắm,thận,cọ rửa,Sức khỏe sinh sản,",
category: 'life',
time: '10:50 AM',
date: '11/08/2021',
timestamp: '11/08/2021 10:50 AM',
link: '/3-bo-phan-tren-co-the-nam-gioi-cang-co-rua-sach-se-khi-tam-thi-than-cang-khoe-manh-suc-khoe-doi-dao',
component: 'page_20210811105021'},
{title: "Mua căn hộ Vinhomes Ocean Park, chủ nhà \"đắp\" thêm 350 triệu thiết kế theo style Indochine làm nơi nghỉ dưỡng",
description: "Căn hộ được thiết kế tỉ mỉ đến từng chi tiết, đặc biệt là phần sàn gạch và bức tranh tường.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/8/6/8-3-1628240517469390285723.jpg',
alt: "khoe nhà đẹp,căn hộ,màu chủ đạo,thiết kế đẹp,gỗ tự nhiên,Đồ nội thất,giấy dán tường,Vinhomes Ocean Park,house n home,",
category: 'life',
time: '04:24 PM',
date: '06/08/2021',
timestamp: '06/08/2021 04:24 PM',
link: '/mua-can-ho-vinhomes-ocean-park-chu-nha-dap-them-350-trieu-thiet-ke-theo-style-indochine-lam-noi-nghi-duong',
component: 'page_20210806162406'},
{title: "\"Làm tổ\" trên giường cả ngày mà không biết 7 cách nâng \"mood\" phòng ngủ thì khó mà thư giãn",
description: "Phòng ngủ của bạn sẽ chill hơn nếu biết 7 mẹo nhỏ mà có võ này.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/photo-1-16280664391501236439911.jpg',
alt: "phòng ngủ,bóng đèn led,nến thơm,máy lọc không khí,vỏ gối,house n home,",
category: 'life',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/lam-to-tren-giuong-ca-ngay-ma-khong-biet-7-cach-nang-mood-phong-ngu-thi-kho-ma-thu-gian',
component: 'page_20210804185624'},
{title: "Gợi ý thực phẩm cần thiết để phát triển cơ bắp cho người tập thể hình",
description: "Để phát triển cơ bắp, bạn có thể lựa chọn một số thực phẩm giúp tăng cơ dưới đây!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/8/4/nhung-dieu-it-biet-ve-trung-ga-xong-khoi-han-quoc-dang-gay-sot-o-viet-nam-324992124719-1500777418-width600height380-16280716646241504815593.jpg',
alt: "phát triển cơ bắp,đảm bảo dinh dưỡng,chế độ ăn uống lành mạnh,chế độ tập luyện,ăn uống khoa học,tăng cơ bắp,",
category: 'life',
time: '06:56 PM',
date: '04/08/2021',
timestamp: '04/08/2021 06:56 PM',
link: '/goi-y-thuc-pham-can-thiet-de-phat-trien-co-bap-cho-nguoi-tap-the-hinh',
component: 'page_20210804185614'},
{title: "Vợ chồng trẻ mua căn hộ 76m2, chọn màu bê tông sắc lạnh nhìn \"chất\" như phim",
description: "Ở khu vực phòng ngủ của căn hộ sử dụng mảng tường kính liên thông với WC thông qua 1 hệ rèm chống nước.",
src: 'https://kenh14cdn.com/thumb_w/500/203336854389633024/2021/7/30/p33-16276390927461692195962.jpg',
alt: "phong cách hiện đại,phòng vệ sinh,kiến trúc sư,Phòng ngủ nhỏ,không gian sinh hoạt,Không gian bếp,Tủ quần áo,bàn trang điểm,bàn làm việc,khoe nhà đẹp,house n home,căn hộ,",
category: 'life',
time: '12:16 PM',
date: '30/07/2021',
timestamp: '30/07/2021 12:16 PM',
link: '/vo-chong-tre-mua-can-ho-76m2-chon-mau-be-tong-sac-lanh-nhin-chat-nhu-phim',
component: 'page_20210730121631'},
{title: "Căn villa 400m2 có khoảng sân vườn ngập nắng gió, thiết kế đẹp tinh tế, giản đơn đề cao sự \"không hoàn hảo\"",
description: "Căn villa được thiết kế theo phong cách Wabi Sabi, trên tinh thần tôn trọng tự nhiên và hướng tới sự đơn giản, bình yên.",
src: 'https://kenh14cdn.com/zoom/90_60/203336854389633024/2021/7/27/photo1627382400143-1627382400338253477531.jpg',
alt: "khoe nhà đẹp,khu đô thị,kiến trúc sư,không gian sinh hoạt,Đồ nội thất,nội thất gỗ,Đồ trang trí,không gian làm việc,phòng chiếu phim,bồn tắm,house n home,villa,biệt thự,",
category: 'life',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/can-villa-400m2-co-khoang-san-vuon-ngap-nang-gio-thiet-ke-dep-tinh-te-gian-don-de-cao-su-khong-hoan-hao',
component: 'page_20210729162639'},
{title: "Mua căn hộ tập thể 40m2, trai độc thân đắp thêm 420 triệu để \"thay máu\" toàn bộ, đơn giản nhưng đẹp hết chỗ chê",
description: "Căn hộ được cải tạo với style trẻ trung, hiện đại, phù hợp với cuộc sống độc thân.",
src: 'https://kenh14cdn.com/thumb_w/1000/203336854389633024/2021/7/27/hi-10-16274015583131737337430.jpg',
alt: "Cải tạo nhà,Đồ nội thất,nhà vệ sinh,kiến trúc sư,phòng làm việc,Phòng ngủ nhỏ,phong cách Nhật Bản,căn hộ nhỏ,house n home,",
category: 'life',
time: '04:26 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:26 PM',
link: '/mua-can-ho-tap-the-40m2-trai-doc-than-dap-them-420-trieu-de-thay-mau-toan-bo-don-gian-nhung-dep-het-cho-che',
component: 'page_20210729162620'},
{title: "Đập bỏ tường, phòng khách của vợ chồng trẻ rộng đến mức chứa được 20 người, còn có góc làm việc chill hết nấc",
description: "Bộ sofa cực lớn trong phòng khách cũng là chi tiết khiến hội \"sâu lười\" thích mê đấy.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/26/d8f1c7616dc340199051e9ca7eb39097-16272931491721888550257.jpeg',
alt: "phòng làm việc,không gian sinh hoạt,bàn làm việc,phong cách Nhật Bản,Căn hộ chung cư,phòng ngủ,khoe nhà đẹp,house n home,",
category: 'life',
time: '04:24 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:24 PM',
link: '/dap-bo-tuong-phong-khach-cua-vo-chong-tre-rong-den-muc-chua-duoc-20-nguoi-con-co-goc-lam-viec-chill-het-nac',
component: 'page_20210729162434'},
{title: "Mua căn hộ 58m2, vợ chồng trẻ tự thiết kế siêu ưng nhưng nể nhất là chi phí siêu \"hạt dẻ\"",
description: "Để tiết kiệm, vợ chồng chị Dung không thuê kiến trúc sư mà tự mày mò lên ý tưởng cho từng góc trong nhà.",
src: 'https://kenh14cdn.com/zoom/90_60/203336854389633024/2021/7/27/photo1627382400143-1627382400338253477531.jpg',
alt: "Mua nhà,thiết kế nhà,căn hộ nhỏ,Đồ nội thất,đồ decor,phong cách japandi,trồng cây,khoe nhà đẹp,house n home,",
category: 'life',
time: '04:22 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:22 PM',
link: '/mua-can-ho-58m2-vo-chong-tre-tu-thiet-ke-sieu-ung-nhung-ne-nhat-la-chi-phi-sieu-hat-de',
component: 'page_20210729162241'},
{title: "Người gan kém thường có 3 thay đổi bất thường ở bàn tay, đi khám ngay vì rất dễ là dấu hiệu của ung thư gan",
description: "Ung thư gan có thể nhen nhóm xuất hiện trong cơ thể bạn bất cứ lúc nào và ngay khi thấy 3 hiện tượng ở bàn tay dưới đây là lúc bạn cần đi kiểm tra gan ngay.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/20/photo-1-16267430533472025884574.jpg',
alt: "thay đổi bất thường,mu bàn tay,tế bào gan,",
category: 'life',
time: '04:21 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:21 PM',
link: '/nguoi-gan-kem-thuong-co-3-thay-doi-bat-thuong-o-ban-tay-di-kham-ngay-vi-rat-de-la-dau-hieu-cua-ung-thu-gan',
component: 'page_20210729162112'},
{title: "Buổi tối Hà Nội vắng hơn cả Tết: Phố xá nơi đâu cũng \"thinh lặng\", người dân ở nhà đóng cửa chống dịch",
description: "Trong buổi tối đầu tiên thực hiện theo Công điện số 15/CĐ-UBND, Hà Nội đã trở nên vắng vẻ hơn bao giờ hết.",
src: 'https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/7/20/dsc4445-16267155740041692750510.jpg',
alt: "Hà Nội,quang cảnh Hà Nội,hà nội vắng vẻ,Nhiều chuỗi lây nhiễm Covid-19 tại Hà Nội,",
category: 'life',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/buoi-toi-ha-noi-vang-hon-ca-tet-pho-xa-noi-dau-cung-thinh-lang-nguoi-dan-o-nha-dong-cua-chong-dich',
component: 'page_20210729160716'},
{title: "Trai phố cổ chi 150 triệu cải tạo sân thượng 26m2 thành \"mini penthouse\", góc nào cũng sang xịn nhưng mê nhất là bồn tắm cực chill",
description: "Đây vốn là sân thượng tầng 6 của một căn nhà trong khu phố cổ Hà Nội.",
src: 'https://kenh14cdn.com/zoom/90_60/203336854389633024/2021/7/19/photo1626628125797-16266281259421044606843.png',
alt: "hệ thống điện nước,nhà vệ sinh,trang trí bếp,Cải tạo nhà,thiết kế nhà,Sân thượng,bồn tắm,house n home,",
category: 'life',
time: '04:07 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:07 PM',
link: '/trai-pho-co-chi-150-trieu-cai-tao-san-thuong-26m2-thanh-mini-penthouse-goc-nao-cung-sang-xin-nhung-me-nhat-la-bon-tam-cuc-chill',
component: 'page_20210729160707'},
{title: "Giải mã: Tại sao nhiều khách sạn lại lắp phòng tắm kính trong suốt?",
description: "Thiết kế này chắc chắn được các cặp đôi ưa thích. Tuy nhiên, những lí do khác cũng vô cùng thuyết phục.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/7/19/k2-16266627360011266503501.jpeg',
alt: "khách sạn,kính trong suốt,phòng tắm,phòng tắm khách sạn,Hổng ngờ luôn á!,",
category: 'life',
time: '04:06 PM',
date: '29/07/2021',
timestamp: '29/07/2021 04:06 PM',
link: '/giai-ma-tai-sao-nhieu-khach-san-lai-lap-phong-tam-kinh-trong-suot',
component: 'page_20210729160657'},
{title: "Tự sự của một người làm ngành F&B: \"Sạt nghiệp\" vì nhiều khách vô ý thức, ra quán cà phê gọi cốc nước vài chục ngàn đồng rồi thản nhiên \"đổ bê tông\" tới nửa ngày trời",
description: "Nếu coi quán cà phê là nơi làm việc miễn phí với wifi \"căng đét\" và điều hoà mát lạnh, gọi ly nước vài chục ngàn rồi \"cắm rễ\" tại quán thì hàng quán chúng tôi chỉ có nước... sạt nghiệp!",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/6/29/a3-1624976235560991831279.jpeg',
alt: "quán cà phê,văn hoá,đi cà phê,văn hoá cà phê,chủ quán,kinh doanh,ngành F&B,",
category: 'life',
time: '09:56 PM',
date: '29/06/2021',
timestamp: '29/06/2021 09:56 PM',
link: '/tu-su-cua-mot-nguoi-lam-nganh-fb-sat-nghiep-vi-nhieu-khach-vo-y-thuc-ra-quan-ca-phe-goi-coc-nuoc-vai-chuc-ngan-dong-roi-than-nhien-do-be-tong-toi-nua-ngay-troi',
component: 'page_20210629215613'},
{title: "Mua chung cư 72m2, vợ chồng mới cưới tự tay thiết kế từng góc theo phong cách Hàn Quốc, chơi thêm cả bể cá ban công",
description: "Vì diện tích căn hộ không được rộng nên vợ chồng chị Nguyệt Út ưu tiên chọn tông màu nâu trắng để nhà sáng sủa và tạo cảm giác rộng rãi hơn.",
src: 'https://kenh14cdn.com/thumb_w/620/203336854389633024/2021/5/26/18650845737681714566265132333997200191415436n-162204531648783806752.jpeg',
alt: "Hai Vo Chong, Do Noi That, Tu Thiet Ke, Be Ca, House N Home, Design Nha, ",
category: 'life',
time: '2:05 PM',
date: '08/06/2021',
timestamp: '08/06/2021 2:05 PM',
link: '/mua-chung-cu-72m2-vo-chong-moi-cuoi-tu-tay-thiet-ke-tung-goc-theo-phong-cach-han-quoc-choi-them-ca-be-ca-ban-cong',
component: 'page_20210608140529'},
]
